import React, { useState } from "react";
import Autocomplete from "../../../../Elements/Autocomplete";
import {
	Avatar,
	Box,
	Chip,
	Paper,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { emailPattern } from "../../../../../utils/validation";
import { Tooltip } from "../../../../../styles/twozo";

export default function EmailRecipientInput(props) {
	const {
		recipients,
		value,
		handleRecipientsChange,
		renderTags,
		disabled = false,
	} = props;

	const theme = useTheme();
	const [focussed, setFocussed] = useState(false);
	const [recipientInputValue, setRecipientInputValue] = useState("");
	const [invalidMailIndex, setInvalidMailIndex] = useState("");

	const CustomizedPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					width: "260px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					display: recipientInputValue.trim() ? "block" : "none",
				}}
			/>
		);
	};

	const getNewEmailValue = (emailValue) => {
		let newEmailAddress = {
			name: "",
			value: -1,
			email: {
				id: -1,
				value: emailValue,
			},
		};
		return newEmailAddress;
	};

	const isExistingRecipient = (inputValue) => {
		return value?.some((option) => inputValue === option.email.value);
	};

	const filterOptions = (options, params) => {
		const { inputValue } = params;
		const filteredOption = options.filter(
			(option) =>
				option.email.value
					.toLowerCase()
					.startsWith(inputValue.toLowerCase()) ||
				option.name.toLowerCase().startsWith(inputValue.toLowerCase())
		);

		if (inputValue.trim() && !isExistingRecipient(inputValue)) {
			let newEmail = getNewEmailValue(inputValue);
			filteredOption.push(newEmail);
		}
		return filteredOption;
	};

	const onChangeRecipient = (event, emailValues) => {
		const newEmailValue = event.target.value;

		if (!isExistingRecipient(newEmailValue)) {
			if (newEmailValue) {
				let newEmail = getNewEmailValue(newEmailValue);
				handleRecipientsChange([...value, newEmail]);
			} else {
				handleRecipientsChange(emailValues);
			}
		}
		setRecipientInputValue("");
	};

	const onBlurRecipientInput = (event) => {
		const inputValue = event.target.value;

		if (!isExistingRecipient(inputValue) && inputValue.trim()) {
			let newEmail = getNewEmailValue(inputValue);
			filterOptions(recipients, { inputValue: inputValue });
			handleRecipientsChange([...value, newEmail]);
		}
		setRecipientInputValue("");
		setFocussed(false);
	};

	const onMouseOverRenderTags = (option, index) => {
		if (!emailPattern.test(option?.email?.value)) {
			setInvalidMailIndex(index);
		}
	};

	const onMouseOutRenderTags = () => {
		setInvalidMailIndex("");
	};

	return (
		<React.Fragment>
			<Autocomplete
				options={recipients}
				value={value}
				limitTags={2}
				disabled={disabled}
				onFocus={() => {
					setFocussed(true);
				}}
				filterOptions={filterOptions}
				paperComponent={CustomizedPaper}
				onChange={onChangeRecipient}
				renderTags={
					renderTags ||
					((value, getTagProps) =>
						value.map((option, index) => (
							<Tooltip
								key={index}
								placement="top"
								open={invalidMailIndex === index}
								title="Please provide a valid email address before sending it."
							>
								<Chip
									key={index}
									size="small"
									color="secondary"
									deleteIcon={CloseIcon(16, 16, "#000")}
									onMouseOver={() =>
										onMouseOverRenderTags(option, index)
									}
									onMouseOut={() => onMouseOutRenderTags()}
									sx={{
										borderRadius: "8px",
										backgroundColor: emailPattern.test(
											option?.email?.value
										)
											? theme.palette.secondary.main
											: "rgba(235, 84, 84, 0.12)",
									}}
									avatar={
										<Avatar style={{ color: "#fff" }}>
											{option.name
												?.charAt(0)
												.toUpperCase() ||
												option?.email?.value
													?.charAt(0)
													.toUpperCase()}
										</Avatar>
									}
									label={
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
											justifyContent="center"
										>
											{option?.email?.value ? (
												<>
													<Typography
														fontSize={14}
														color="#000"
														hidden={
															option.value === -1
														}
													>
														{option?.name}
													</Typography>

													<Typography
														color="#000"
														hidden={
															option.value === -1
														}
													>
														-
													</Typography>

													<Typography
														fontSize={14}
														color="rgba(0, 0, 0, 0.6)"
													>
														{option.email.value}
													</Typography>
												</>
											) : null}
										</Stack>
									}
									{...getTagProps({ index })}
								/>
							</Tooltip>
						)))
				}
				renderOption={(props, option) => (
					<Box key={option?.emailId} {...props}>
						{option.name ? (
							<Stack>
								<Typography fontSize={13} fontWeight={400}>
									{option?.name}
								</Typography>

								<Typography
									fontSize={13}
									fontWeight={400}
									color="rgba(0, 0, 0, 0.6)"
								>
									{option?.email?.value}
								</Typography>
							</Stack>
						) : (
							<Box py={1}>
								<Typography fontSize={13} fontWeight={400}>
									{option.email.value}
								</Typography>
							</Box>
						)}
					</Box>
				)}
				getOptionLabel={(option) => {
					return option.name;
				}}
				inputValue={recipientInputValue}
				renderInput={(params) => (
					<TextField
						{...params}
						onChange={(event) =>
							setRecipientInputValue(event.target.value)
						}
						placeholder={focussed ? "Enter recipients" : ""}
						onBlur={onBlurRecipientInput}
						sx={{
							"& .MuiInputBase-root": {
								minHeight: "42px",
							},
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									border: "none",
								},
								"&:hover fieldset": {
									border: "none",
								},
								"&.Mui-focused fieldset": {
									border: "none",
								},
							},
						}}
						inputProps={{
							...params.inputProps,
							style: {
								...params.inputProps?.style,
								fontSize: "14px",
							},
						}}
					/>
				)}
			/>
		</React.Fragment>
	);
}
