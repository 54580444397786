const dealStateList = [
	{
		id: 1,
		name: "Open Deals First",
	},
	{
		id: 2,
		name: "Won Deals First",
	},
];

export default dealStateList;
