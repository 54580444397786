import React from "react";
import { Typography } from "@mui/material";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";

export default function ActivityAssigned(props) {
	const { notificationData, textColor = "#000" } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				component="span"
				style={{ fontSize: "13px", color: textColor, opacity: "0.6" }}
			>
				{getFormattedDateMonthYearAndTime(
					notificationData?.data?.startTime
				)}{" "}
				-{" "}
				{getFormattedDateMonthYearAndTime(
					notificationData?.data?.endTime
				)}
			</Typography>
		</React.Fragment>
	);
}
