import React, { useEffect, useRef, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import SwipeableFilter from "../SwipeableFilter";
import { useFilterDataContext } from "../Context/FilterDataContext";

export default function FilterDrawer(props) {
	const { moduleName, hasShareViewPermissions } = props;

	const { filterState, openSwipeableFilter, closeSwipeableFilter } =
		useFilterDataContext();

	//Position the filter Drawer
	const menuBarRef = useRef(null);
	const [topBarEndingPosition, setTopBarEndingPosition] = useState(0);
	const [navBarEndingPosition, setNavBarEndingPosition] = useState(0);

	useEffect(() => {
		if (menuBarRef) {
			setTopBarEndingPosition(
				menuBarRef.current.getBoundingClientRect().top
			);
			setNavBarEndingPosition(
				menuBarRef.current.getBoundingClientRect().left
			);
		}
	}, [menuBarRef]);

	return (
		<React.Fragment>
			<SwipeableDrawer
				sx={{
					"& .MuiDrawer-paper": {
						marginTop: `${topBarEndingPosition}px`,
						marginLeft: `${navBarEndingPosition}px`,
						zIndex: 2,
					},
				}}
				variant="persistent"
				anchor="left"
				open={filterState.isSwipeableFilterOpened}
				onOpen={openSwipeableFilter}
				onClose={closeSwipeableFilter}
				ref={menuBarRef}
			>
				<Box
					style={{
						height: `calc(100vh - ${topBarEndingPosition}px)`,
					}}
				>
					<SwipeableFilter
						moduleName={moduleName}
						hasShareViewPermissions={hasShareViewPermissions}
					/>
				</Box>
			</SwipeableDrawer>
		</React.Fragment>
	);
}
