import React from "react";
import Field from "../Field";

export default function SummaryCard(props) {
	const { summaryFirstField, summarySecondField, summarySubFields } = props;

	return (
		<React.Fragment>
			<tbody>
				<tr>
					<Field
						summaryField={summaryFirstField}
						subFields={
							summarySubFields[summaryFirstField?.fieldType]
						}
					/>
				</tr>
				<tr>
					<Field
						summaryField={summarySecondField}
						subFields={
							summarySubFields[summarySecondField?.fieldType]
						}
					/>
				</tr>
			</tbody>
		</React.Fragment>
	);
}
