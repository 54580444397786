import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import DateTimeRangeFieldBaseComponent from "./DateTimeRangeFieldBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function DateTimeRangeFieldComponent({ field, subFields }) {
	const {
		control,
		formState: { errors },
		clearErrors,
	} = useFormContext();

	const [dateTimeRangeValue, setDateTimeRangeValue] = useState({});

	const onClearError = () => {
		clearErrors(field.id);
	};

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromField = getSubFieldObject("FROM");
	const toField = getSubFieldObject("TO");

	const handleChangeTimeRangeValueChange = (
		newDateTimeRangeValue,
		onChange
	) => {
		setDateTimeRangeValue({ ...newDateTimeRangeValue });
		let dateTimeRangeValueObjectWithId = {};
		dateTimeRangeValueObjectWithId[fromField.id] =
			newDateTimeRangeValue.from;
		dateTimeRangeValueObjectWithId[toField.id] = newDateTimeRangeValue.to;

		let getDateTimeRangeValue = () => {
			if (
				dateTimeRangeValueObjectWithId[fromField.id] &&
				dateTimeRangeValueObjectWithId[toField.id]
			) {
				return dateTimeRangeValueObjectWithId;
			}

			return "";
		};

		onChange(getDateTimeRangeValue());
	};

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({ field: { onChange, ...renderField } }) => (
					<DateTimeRangeFieldBaseComponent
						{...renderField}
						value={dateTimeRangeValue}
						onChange={(data) =>
							handleChangeTimeRangeValueChange(data, onChange)
						}
						error={errors[field.id]}
						fromTextFieldSx={{ width: "100%" }}
						toTextFieldSx={{ width: "100%" }}
						onClearError={onClearError}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
