export const groupOfContactsList = [
	{
		name: "All Contacts",
		value: 1,
	},
	{
		name: "Starred",
		value: 2,
	},
];

export const syncDirections = {
	oneWay: "ONE_WAY",
	twoWay: "TWO_WAY",
};

export const contactSyncState = {
	syncDisabled: "SYNC_DISABLED",
	syncBridgeEstablished: "SYNC_BRIDGE_ESTABLISHED",
	syncEnabled: "SYNC_ENABLED",
};

export const contactSyncStatus = {
	ACTIVE: "Active",
	AUTHORIZED: "Authorized",
	SYNCING: "Syncing",
};
