import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../Elements/Menu";
import { default as DropdownRightIcon } from "../../../assets/icons/dropDownRight";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import { default as FillCloseIcon } from "../../../assets/icons/fillClose";
import { default as TickIcon } from "../../../assets/icons/roundCheckBoxOn";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { getActionListKey } from "../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getActionData } from "../../../api/workflow/workflowApi";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { twozoStyles } from "../../../styles/twozo";
import Nodes from "../../Mail/EmailComposer/EmailComposerUI/Nodes";
import DefaultFields from "./DefaultFields";
import EmailFields from "./EmailFields";
import { getFormattedDateMonthYearAndTime } from "../../../utils/DateUtils";

export default function Action(props) {
	const {
		styles,
		readOnly,
		historyData,
		setAction,
		setFieldMappings,
		triggerSourceId,
		source,
		deleteAction,
		event,
		fieldMapping,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const [selectedAction, setSelectedAction] = useState(null);
	const [selectedSubAction, setSelectedSubAction] = useState(null);
	const [hoveredActionMenu, setHoveredActionMenu] = useState(null);
	const [actionMenuElement, setActionMenuElement] = useState(null);
	const isActionMenuOpened = Boolean(actionMenuElement);
	const [subActionMenuElement, setSubActionMenuElement] = useState(null);
	const isSubActionMenuOpened = Boolean(subActionMenuElement);
	const [
		isActionFieldMappingDialogOpened,
		setIsActionFieldMappingDialogOpened,
	] = useState(false);
	const [actions, setActions] = useState([]);

	const actionListKey = getActionListKey(triggerSourceId);
	const { data: actionListData } = useQuery(
		actionListKey,
		() => getActionData(triggerSourceId),
		{
			enabled: !!triggerSourceId,
		}
	);

	const isActionStatusSuccess = historyData?.status === "Success";
	const color = readOnly
		? isActionStatusSuccess
			? "rgba(0, 0, 0, 0.12)"
			: theme.palette.error.main
		: "rgba(0, 0, 0, 0.12)";

	useEffect(() => {
		if (actionListData && actionListData.list) {
			setActions(actionListData.list);
			if (source && source.id) {
				const selectedAction = actionListData.list.find(
					(action) => action.id === source.id
				);
				if (selectedAction) {
					setSelectedAction(selectedAction);
					if (
						event &&
						event.id &&
						selectedAction.event &&
						selectedAction.event.length > 0
					) {
						const selectedSubAction = selectedAction.event.find(
							(subAction) => subAction.id === event.id
						);
						if (selectedSubAction) {
							setSelectedSubAction(selectedSubAction);
						}
					}
				}
			}
		}
	}, [actionListData, source, event]);

	const OpenActionMenu = (event) => {
		setActionMenuElement(event.currentTarget);
	};

	const CloseActionMenu = () => {
		CloseSubActionMenu();
		setActionMenuElement(null);
	};

	const OpenSubActionMenu = (event) => {
		setSubActionMenuElement(event.currentTarget);
	};

	const CloseSubActionMenu = () => {
		setSubActionMenuElement(null);
	};

	const changeAction = (event, action) => {
		setSelectedAction(action);
		if (action.event?.length > 0) {
			OpenSubActionMenu(event);
		} else {
			setSelectedSubAction(null);
			setAction(action);
			CloseActionMenu();
		}
	};

	const changeSubAction = (action) => {
		if (
			!(
				source?.id &&
				source.id === selectedAction.id &&
				event?.id &&
				event.id === action.id
			)
		) {
			setFieldMappings([]);
		}
		setSelectedSubAction(action);
		setAction(selectedAction, action);
		CloseActionMenu();
		toggleActionFieldMappingDialog();
	};

	const toggleActionFieldMappingDialog = () => {
		setIsActionFieldMappingDialogOpened(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const handleFieldMappingsChange = (fieldMappings) => {
		toggleActionFieldMappingDialog();
		setFieldMappings(fieldMappings);
	};

	const getActionIcon = () => {
		return isActionStatusSuccess
			? TickIcon(18, 18, theme.palette.primary.main)
			: FillCloseIcon(16, 16, theme.palette.error.main);
	};

	const getActionState = () => {
		return isActionStatusSuccess
			? historyData?.state
			: historyData?.reason || "";
	};

	const onDeleteClick = () => {
		deleteAction();
	};

	const resetFieldActionAndMapping = () => {
		setFieldMappings([]);
		setSelectedAction(null);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isActionFieldMappingDialogOpened}
				onOpen={toggleActionFieldMappingDialog}
				onClose={toggleActionFieldMappingDialog}
				ModalProps={{
					keepMounted: false,
				}}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				{selectedAction?.name === "Email" ? (
					<LexicalComposer
						initialConfig={{
							theme: {
								paragraph: classes.email_paragraph,
								text: {
									bold: classes.email_font_bold,
									italic: classes.email_italic,
									underline: classes.email_underline,
									strikethrough: classes.email_strikethrough,
								},
								quote: classes.email_quote,
							},
							nodes: [...Nodes],
							onError(error) {
								throw error;
							},
						}}
					>
						<EmailFields
							triggerSourceId={triggerSourceId}
							actionSource={selectedAction}
							actionEvent={selectedSubAction?.id}
							formName={selectedSubAction?.name}
							fieldMapping={fieldMapping}
							resetAction={resetFieldActionAndMapping}
							setFieldMappings={handleFieldMappingsChange}
							toggleAddActivityDialog={
								toggleActionFieldMappingDialog
							}
						/>
					</LexicalComposer>
				) : (
					<DefaultFields
						triggerSourceId={triggerSourceId}
						actionSource={selectedAction}
						actionEvent={selectedSubAction?.id}
						formName={selectedSubAction?.name}
						fieldMapping={fieldMapping}
						resetAction={resetFieldActionAndMapping}
						setFieldMappings={handleFieldMappingsChange}
						toggleAddActivityDialog={toggleActionFieldMappingDialog}
					/>
				)}
			</CustomSwipeableDrawer>

			<Menu
				anchorEl={actionMenuElement}
				open={isActionMenuOpened}
				onClose={CloseActionMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="250px"
				style={{ marginTop: "-8px" }}
			>
				{actions.map((action) => (
					<MenuItem
						key={action.id}
						style={{ minHeight: "40px" }}
						selected={action.id === selectedAction?.id}
						onClick={(event) => changeAction(event, action)}
						onMouseEnter={() => setHoveredActionMenu(action.id)}
						onMouseLeave={() => setHoveredActionMenu(null)}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography fontSize={13}>{action.name}</Typography>
							{action.event?.length > 0 &&
								(hoveredActionMenu === action.id ||
									(isSubActionMenuOpened &&
										action.name ===
											selectedAction?.name)) &&
								DropdownRightIcon(16, 16, "#000")}
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Menu
				anchorEl={subActionMenuElement}
				open={isSubActionMenuOpened}
				onClose={CloseSubActionMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="250px"
				style={{ marginLeft: "6px" }}
			>
				{selectedAction?.event?.map((action) => (
					<MenuItem
						key={action.id}
						style={{ minHeight: "40px" }}
						selected={action.id === selectedSubAction?.id}
						onClick={() => changeSubAction(action)}
						disabled={!action.isEnabled}
					>
						<Typography fontSize={13}>{action.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Stack
				direction="row"
				position="relative"
				sx={{ pointerEvents: readOnly ? "none" : "auto" }}
			>
				<Box
					style={{
						border: `1px solid ${color}`,
						borderRadius: "8px",
						minWidth: "500px",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						py={1.5}
						px={2}
					>
						<Typography style={styles.title}>
							Action{" "}
							<span style={{ color: theme.palette.error.main }}>
								*
							</span>
						</Typography>

						{readOnly ? (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
							>
								{getActionIcon()}

								<Typography fontSize={14} fontWeight={400}>
									{getFormattedDateMonthYearAndTime(
										historyData?.executedTime || ""
									)}
								</Typography>
							</Stack>
						) : (
							<IconButton
								size="small"
								style={{
									padding: 0,
								}}
								onClick={onDeleteClick}
							>
								{DeleteIcon(20, 20, theme.palette.error.main)}
							</IconButton>
						)}
					</Stack>

					<Divider style={{ borderColor: color }} />

					<Stack
						direction="row"
						alignItems="center"
						spacing={2}
						p={2}
					>
						<Typography
							style={
								selectedAction
									? styles.textButton
									: styles.selectTextButton
							}
							onClick={OpenActionMenu}
						>
							{selectedAction
								? selectedSubAction
									? selectedSubAction.name
									: selectedAction.name
								: "Select"}
						</Typography>
					</Stack>
				</Box>

				{readOnly ? (
					<Box>
						<Typography
							pl={1}
							fontSize={14}
							fontWeight={400}
							color={
								isActionStatusSuccess
									? "rgba(0, 0, 0, 0.6)"
									: theme.palette.error.main
							}
							style={{
								position: "absolute",
								width: "400px",
								wordWrap: "break-word",
							}}
						>
							{getActionState()}
						</Typography>
					</Box>
				) : (
					""
				)}
			</Stack>
		</React.Fragment>
	);
}
