import React from "react";

export default function ListBullet(width = 16, height = 16, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.6">
				<path
					d="M6 12.668L14 12.668"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6 8L14 8"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6 3.33398L14 3.33398"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<ellipse cx="3" cy="3.33398" rx="1" ry="1" fill={color} />
				<circle cx="3" cy="8" r="1" fill={color} />
				<circle cx="3" cy="12.668" r="1" fill={color} />
			</g>
		</svg>
	);
}
