import React from "react";

function Meeting(width = 24, height = 24, color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
		>
			<path
				d="M6.21 18.4201C3.05 18.4201 2 16.8189 2 15.2101V8.79008C2 6.38067 3.05 5.58008 6.21 5.58008H12.53C15.69 5.58008 16.74 6.38067 16.74 8.79008V15.2101C16.74 17.6195 15.68 18.4201 12.53 18.4201H6.21Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.74 14.0173V9.97501L19.52 8.72581C20.88 8.11723 22 8.48879 22 9.55861V14.4401C22 15.5099 20.88 15.8814 19.52 15.2665L16.74 14.0173Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.5 12C10.6716 12 10 11.3284 10 10.5C10 9.67157 10.6716 9 11.5 9C12.3284 9 13 9.67157 13 10.5C13 11.3284 12.3284 12 11.5 12Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Meeting;
