import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ToolBar from "../../../../../../Mail/EmailComposer/EmailComposerUI/ToolBar";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalAutoLinkPlugin from "../../../../../../Mail/EmailComposer/EmailComposerUI/Plugin/AutoLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { $generateHtmlFromNodes } from "@lexical/html";
import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";
import { twozoStyles } from "../../../../../../../styles/twozo";
import { parseHTML } from "../../../../../../../utils/EmailUtils/parseHTML";

export default function EmailSignatureInput(props) {
	const { emailSignatureInputChange, initialSignatureContent } = props;
	const classes = twozoStyles();
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!initialSignatureContent) return;

		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(
				initialSignatureContent,
				"text/html"
			);
			const nodes = $generateNodesFromDOM(editor, dom);
			$getRoot().clear();
			$insertNodes(nodes);
		});
		editor._editable = false;
	}, [initialSignatureContent, editor]);

	const onChange = (editorState) => {
		editor._editable = true;
		let htmlBodyContent = editorState.read(() => {
			return $getRoot().getTextContent()
				? parseHTML($generateHtmlFromNodes(editor), classes)
				: "";
		});
		emailSignatureInputChange(htmlBodyContent);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid #E0E0E0",
					borderRadius: "8px",
				}}
			>
				<ToolBar
					hiddenTemplateMenu={true}
					hiddenPlaceHolderMenu={true}
				/>

				<RichTextPlugin
					contentEditable={
						<ContentEditable
							style={{
								height: "120px",
								padding: "12px",
								overflowY: "auto",
								outline: "none",
								fontSize: "14px",
								fontFamily: "Lexend",
							}}
						/>
					}
					ErrorBoundary={LexicalErrorBoundary}
				/>
				<TabIndentationPlugin />
				<HistoryPlugin />
				<OnChangePlugin onChange={onChange} />
				<LexicalAutoLinkPlugin />
				<LexicalClickableLinkPlugin />
			</Box>
		</React.Fragment>
	);
}
