import { apiClient } from "..";
import { getSettingsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";

export const getCurrencyList = async () => {
	const requestData = getCurrencyListRequest();
	return await postFieldsApi(requestData);
};

export const getCurrencyData = async () => {
	const requestData = getCurrencyDataRequest();
	return await postCurrencyManagement(requestData);
};

export const updateBaseCurrency = async (data) => {
	const requestData = updateBaseCurrencyRequest(data);
	return await postCurrencyManagement(requestData);
};

export const addCurrency = async (currencyId) => {
	const requestData = getAddCurrencyRequest(currencyId);
	return await postCurrencyManagement(requestData);
};

export const updateCurrencyStatus = async ({ id, isEnabled }) => {
	const requestData = getUpdateCurrencyStatusRequest(id, isEnabled);
	return await postCurrencyManagement(requestData);
};

const getCurrencyListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "currencyList",
		},
	};
};

const getCurrencyDataRequest = () => {
	return {
		type: "list",
		data: {},
	};
};

const updateBaseCurrencyRequest = (data) => {
	return {
		type: "updateBaseCurrency",
		data: data,
	};
};

const getAddCurrencyRequest = (currencyId) => {
	return {
		type: "create",
		data: {
			currencyId: currencyId,
		},
	};
};

const getUpdateCurrencyStatusRequest = (id, isEnabled) => {
	return {
		type: "updateStatus",
		data: {
			id: id,
			isEnabled: isEnabled,
		},
	};
};

const postCurrencyManagement = async (requestData) => {
	let currencyManagementUrl = getCurrencyManagementUrl();
	let response = await apiClient.post(currencyManagementUrl, requestData);
	assertError(response, currencyManagementUrl);
	return response.data.data;
};

const getCurrencyManagementUrl = () => {
	return getSettingsUrl() + "/currency/mgmt";
};
