import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { TableCellText, TableHeaderLabel } from "../../../../../styles/twozo";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";
import { default as SendIcon } from "../../../../../assets/icons/send";
import { default as MoreIcon } from "../../../../../assets/icons/more";
import Menu from "../../../../Elements/Menu";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { getDateMonthAndYearFormat } from "../../../../../utils/DateUtils";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import ConfirmDialog from "../../../../Elements/ConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useDeleteMutation,
	useUpdateWebFormListMutation,
} from "../../../../../hooks/services/webform";
import FormInfo from "./FormInfo";
import { useAuth } from "../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../utils/Auth";

export default function TableList(props) {
	const { webFormList, isActive } = props;
	const theme = useTheme();
	const successNotificationTitle = "Success";

	const deleteMutation = useDeleteMutation();
	const deactivateMutation = useUpdateWebFormListMutation();
	const activateMutation = useUpdateWebFormListMutation();

	const [infoMenuElement, setInfoMenuElement] = useState(null);
	const isInfoMenuOpened = Boolean(infoMenuElement);

	const [hoveredTableRowId, setHoveredTableRowId] = useState(null);
	const [hoveredRowId, setHoveredRowId] = useState(null);

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
	const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);

	const [isPreviewDialogOpened, setIsPreviewDialogOpened] = useState(false);
	const [clickedRowId, setClickedRowId] = useState(null);

	const [selectedWebForm, setSelectedWebForm] = useState("");

	const { isUserAllowedFor } = useAuth();
	const displayMoreIcon =
		isUserAllowedFor(PERMISSIONS.webFormTracking.edit) ||
		isUserAllowedFor(PERMISSIONS.webFormTracking.delete);

	const onMouseOverTableRow = (_, id) => {
		setHoveredTableRowId(id);
	};
	const onMouseOutTableRow = () => setHoveredTableRowId();

	const OpenInfoMenu = (event, webFormData) => {
		event.stopPropagation();
		setInfoMenuElement(event.currentTarget);
		setHoveredRowId(webFormData.id);
		setSelectedWebForm(webFormData.name);
	};

	const CloseInfoMenu = () => {
		setInfoMenuElement(null);
		setHoveredRowId(null);
	};

	const cancelDeleteDialog = () => {
		setIsDeleteDialogOpen(false);
		setHoveredRowId(null);
	};

	const cancelDeactivateDialog = () => {
		setIsDeactivateDialogOpen(false);
		setHoveredRowId(null);
	};

	const cancelActivateDialog = () => {
		setIsActivateDialogOpen(false);
		setHoveredRowId(null);
	};

	const performDeactivate = () => {
		const deactivatedWebFormRequestData = {
			id: hoveredRowId,
			isActive: false,
		};
		setInfoMenuElement(null);
		deactivateMutation.mutate(deactivatedWebFormRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					title: successNotificationTitle,
					message: notificationMessage.webFormDeactivated,
				});
				setIsDeactivateDialogOpen(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const performDeleteWebForm = () => {
		deleteMutation.mutate(hoveredRowId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					title: successNotificationTitle,
					message: notificationMessage.webFormDeleted,
				});
				setInfoMenuElement(null);
				setIsDeleteDialogOpen(false);
				setHoveredRowId(null);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const performActivateWebForm = () => {
		const activatedWebFormRequestData = {
			id: hoveredRowId,
			isActive: true,
		};
		activateMutation.mutate(activatedWebFormRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					title: successNotificationTitle,
					message: notificationMessage.webFormActivated,
				});
				setIsActivateDialogOpen(false);
				setInfoMenuElement(null);
				setHoveredRowId(null);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const deleteWebFormListData = () => {
		setIsDeleteDialogOpen(true);
	};

	const deactivateWebFormListData = () => {
		setIsDeactivateDialogOpen(true);
	};

	const activateWebFormListData = () => {
		setIsActivateDialogOpen(true);
	};

	const filteredInfoMenuOptions = useMemo(() => {
		const activateFormInfoList = [
			{
				id: "duplicate",
				name: "Duplicate",
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.webFormTracking.edit
				),
			},
			{
				id: "deactivate",
				name: "Deactivate",
				action: deactivateWebFormListData,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.webFormTracking.edit
				),
			},
			{
				id: "delete",
				name: "Delete",
				action: deleteWebFormListData,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.webFormTracking.delete
				),
			},
		];

		const deactivateFormInfoList = [
			{
				id: "duplicate",
				name: "Duplicate",
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.webFormTracking.edit
				),
			},
			{
				id: "activate",
				name: "Activate",
				action: activateWebFormListData,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.webFormTracking.edit
				),
			},
			{
				id: "delete",
				name: "Delete",
				action: deleteWebFormListData,
				hasPermissionToAccess: isUserAllowedFor(
					PERMISSIONS.webFormTracking.delete
				),
			},
		];
		if (isActive) {
			return activateFormInfoList.filter(
				(activateFormInfo) => activateFormInfo.hasPermissionToAccess
			);
		} else {
			return deactivateFormInfoList.filter(
				(activateFormInfo) => activateFormInfo.hasPermissionToAccess
			);
		}
	}, [isActive, isUserAllowedFor]);

	const togglePreviewDialog = () => {
		setIsPreviewDialogOpened(
			(isPreviewDialogOpened) => !isPreviewDialogOpened
		);
	};

	const OpenPreviewDrawer = (event, id) => {
		event.stopPropagation();
		setClickedRowId(id);
		togglePreviewDialog();
	};

	const styles = {
		menuContent: {
			fontSize: 13,
			fontWeight: 500,
			color: theme.palette.primary.main,
		},
		editDrawer: {
			width: "30vw",
			minHeight: "100vh",
			backgroundColor: "#fff",
			borderRadius: "10px 0px 0px 10px",
		},
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isPreviewDialogOpened}
				onOpen={togglePreviewDialog}
				onClose={togglePreviewDialog}
				disableSwipeToOpen
				disableBackdropClick={true}
			>
				<Box style={styles.editDrawer}>
					<FormInfo
						onClose={togglePreviewDialog}
						webFormId={clickedRowId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={infoMenuElement}
				open={isInfoMenuOpened}
				onClose={CloseInfoMenu}
				style={{ marginTop: "8px" }}
			>
				{filteredInfoMenuOptions?.map((info) => (
					<MenuItem key={info.id} onClick={info.action}>
						<Typography style={styles.menuContent}>
							{info.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			{/*To Delete a WebForm*/}
			<DeleteDialog
				title={`Are you sure you want to delete “${selectedWebForm}”?`}
				subtitle="Deleting this will affect the published link and it will become invalid."
				open={isDeleteDialogOpen}
				onCancel={cancelDeleteDialog}
				onDelete={performDeleteWebForm}
			></DeleteDialog>

			{/*To Deactivate a WebForm*/}
			<ConfirmDialog
				title={`Are you sure you want to deactivate “${selectedWebForm}”?`}
				subtitle="Deactivating this will affect the published link and it will become invalid."
				open={isDeactivateDialogOpen}
				onCancel={cancelDeactivateDialog}
				onConfirm={performDeactivate}
				confirmButtonText="Deactivate"
			></ConfirmDialog>

			{/*To Activate a WebForm*/}
			<ConfirmDialog
				title={`Are you sure you want to activate “${selectedWebForm}”?`}
				subtitle="Activating this will affect the published link and it will become valid."
				open={isActivateDialogOpen}
				onCancel={cancelActivateDialog}
				onConfirm={performActivateWebForm}
				confirmButtonText="Activate"
			></ConfirmDialog>

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small">
						{isActive && (
							<TableHead>
								<TableRow>
									<TableCell width="35%">
										<Stack
											pl={1}
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Name
											</TableHeaderLabel>

											{DropdownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Visits
											</TableHeaderLabel>

											{DropdownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Submissions
											</TableHeaderLabel>

											{DropdownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Conversions
											</TableHeaderLabel>

											{DropdownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="10%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Created On
											</TableHeaderLabel>

											{DropdownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="10%"></TableCell>
								</TableRow>
							</TableHead>
						)}

						<TableBody>
							{webFormList?.map((webForm) => (
								<TableRow
									key={webForm.id}
									onMouseOver={(event) =>
										onMouseOverTableRow(event, webForm.id)
									}
									onMouseOut={onMouseOutTableRow}
									sx={{ opacity: isActive ? 1 : 0.6 }}
									onClick={(event) =>
										OpenPreviewDrawer(event, webForm.id)
									}
								>
									<TableCell width="35%">
										<TableCellText
											sx={{
												pl: 1,
												color: theme.palette.primary
													.main,
											}}
										>
											{webForm.name}
										</TableCellText>
									</TableCell>

									<TableCell width="15%">
										<TableCellText>
											{webForm.visits}
										</TableCellText>
									</TableCell>

									<TableCell width="15%">
										<TableCellText>
											{webForm.submissions}
										</TableCellText>
									</TableCell>

									<TableCell width="15%">
										<TableCellText>
											{webForm.conversions}
										</TableCellText>
									</TableCell>

									<TableCell width="10%">
										<TableCellText>
											{getDateMonthAndYearFormat(
												webForm?.createdTime
											)}
										</TableCellText>
									</TableCell>

									<TableCell width="10%" sx={{ py: 0 }}>
										<Stack alignItems="flex-end">
											<Box
												style={{
													width: "fit-content",
													height: "37px",
													border: `1px solid ${theme.palette.primary.main}`,
													borderRadius: "8px",
													padding: "8px 8px",
													backgroundColor: "#fff",
												}}
												hidden={
													webForm.id !==
														hoveredTableRowId &&
													webForm.id !== hoveredRowId
												}
											>
												<Stack
													direction="row"
													justifyContent="center"
													alignItems="center"
													style={{ height: "100%" }}
													spacing={1.5}
												>
													{isActive && (
														<>
															<IconButton
																size="small"
																sx={{
																	transform:
																		"rotate(-40deg)",
																}}
															>
																{SendIcon(
																	20,
																	20,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>

															{displayMoreIcon ? (
																<Divider
																	orientation="vertical"
																	flexItem
																/>
															) : null}
														</>
													)}

													{displayMoreIcon ? (
														<IconButton
															size="small"
															onClick={(event) =>
																OpenInfoMenu(
																	event,
																	webForm
																)
															}
														>
															{MoreIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													) : null}
												</Stack>
											</Box>
										</Stack>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</React.Fragment>
	);
}
