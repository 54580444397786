import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as ThumbsUpIcon } from "../../../assets/icons/thumbsUp";
import { default as ThumbsDownIcon } from "../../../assets/icons/thumbsDown";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import { Droppable } from "react-beautiful-dnd";

const DealActionCard = (props) => {
	const { children, isDropping, backgroundColor, ...other } = props;

	return (
		<Box {...other}>
			<Stack direction="row">
				<Box
					style={{
						backgroundColor: backgroundColor,
						height: "70px",
						width: "250px",
						borderRadius: "8px",
						border: `1px ${isDropping ? "solid" : "dashed"} ${
							isDropping ? backgroundColor : "#000"
						}`,
					}}
				>
					<Stack
						alignItems="center"
						justifyContent="center"
						px={5}
						py={2}
					>
						{children}
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default function DealAction() {
	const theme = useTheme();
	const blackColor = "#000000";

	const dealActions = {
		WON: "wonAction",
		LOST: "lostAction",
		DELETE: "deleteAction",
	};

	const dealActionsList = [
		{
			id: dealActions.WON,
			title: "Won",
			subTitle: "Drop here to mark as won",
			styles: {
				backgroundColor: theme.palette.primary.main,
				fontColor: theme.palette.primary.main,
				draggedFontColor: theme.palette.primary.contrastText,
			},
			icon: ThumbsUpIcon,
		},
		{
			id: dealActions.LOST,
			title: "Lost",
			subTitle: "Drop here to mark as lost",
			styles: {
				backgroundColor: theme.palette.error.main,
				fontColor: theme.palette.error.main,
				draggedFontColor: theme.palette.primary.contrastText,
			},
			icon: ThumbsDownIcon,
		},
		{
			id: dealActions.DELETE,
			title: "Delete",
			subTitle: "Drop here to delete",
			styles: {
				backgroundColor: "#00000033",
				fontColor: blackColor,
				draggedFontColor: blackColor,
			},
			icon: DeleteIcon,
		},
	];

	const getDealTitleColor = (snapshot, action) => {
		return snapshot.isDraggingOver
			? action.styles.draggedFontColor
			: action.styles.fontColor;
	};

	const getDescriptionColor = (snapshot, action) => {
		return snapshot.isDraggingOver
			? action.styles.draggedFontColor
			: blackColor;
	};

	const getBackgroundColor = (snapshot, action) => {
		return snapshot.isDraggingOver
			? action.styles.backgroundColor
			: theme.palette.primary.contrastText;
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				justifyContent="center"
				width="100%"
			>
				{dealActionsList.map((action, index) => (
					<Droppable droppableId={action.id.toString()} key={index}>
						{(droppableProvided, snapshot) => (
							<div
								ref={droppableProvided.innerRef}
								{...droppableProvided.droppableProps}
							>
								<DealActionCard
									isDropping={snapshot.isDraggingOver}
									fontColor={action.styles.fontColor}
									backgroundColor={getBackgroundColor(
										snapshot,
										action
									)}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
									>
										{action.icon(
											20,
											20,
											getDealTitleColor(snapshot, action)
										)}

										<Typography
											style={{ opacity: 1 }}
											fontWeight={600}
											color={getDealTitleColor(
												snapshot,
												action
											)}
										>
											{action.title}
										</Typography>
									</Stack>

									<Typography
										style={{
											opacity: snapshot.isDraggingOver
												? 1
												: 0.5,
										}}
										fontSize={13}
										color={getDescriptionColor(
											snapshot,
											action
										)}
									>
										{action.subTitle}
									</Typography>
									{droppableProvided.placeholder}
								</DealActionCard>
							</div>
						)}
					</Droppable>
				))}
			</Stack>
		</React.Fragment>
	);
}
