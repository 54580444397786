import React from "react";
import ShortNotes from "../../../Summary/ShortNotes";
import { modules } from "../../../../utils/common/ModulesName";

export default function DealNotes({ dealId }) {
	return (
		<React.Fragment>
			<ShortNotes source={modules.DEAL} entityId={dealId} />
		</React.Fragment>
	);
}
