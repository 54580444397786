import React from "react";
import { Stack } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { useSingleConversationData } from "../../../hooks/services/mail";
import ViewEmail from "../ViewEmail";

export default function SingleConversationViewer(props) {
	const { onClose, conversationId } = props;

	const { data: singleConversationData, isLoading: isLoadingMailData } =
		useSingleConversationData(conversationId);

	return (
		<React.Fragment>
			{isLoadingMailData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<ViewEmail
					mailData={{ emails: [singleConversationData] }}
					onClose={onClose}
				/>
			)}
		</React.Fragment>
	);
}
