import { Box, IconButton, Stack, useTheme } from "@mui/material";
import React from "react";
import { default as EditIcon } from "../../../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../../../assets/icons/delete";
import { useFieldContext } from "../../../FieldContext";

export default function EditDeleteIconButtons(props) {
	const { field, handleDeleteClick } = props;
	const theme = useTheme();

	// field context
	const { onFieldNameInEditMode } = useFieldContext(field);

	const handleEditField = () => {
		onFieldNameInEditMode();
	};

	return (
		<React.Fragment>
			<Box>
				<Stack direction="row" spacing={0.5}>
					<IconButton onClick={handleEditField}>
						{EditIcon(20, 20, theme.palette.secondary.contrastText)}
					</IconButton>

					<IconButton onClick={handleDeleteClick}>
						{DeleteIcon(
							20,
							20,
							theme.palette.secondary.contrastText
						)}
					</IconButton>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
