import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import AssignOwner from "../../Elements/AssignOwner";
import { useAssignOwnerToProducts } from "../../../hooks/services/product";

export default function ProductAssignOwner(props) {
	const { open, onClose, productIds, onOwnerUpdated } = props;
	const [selectedUserId, setSelectedUserId] = useState("");
	const assignOwnerMutation = useAssignOwnerToProducts();

	const handleSelectUser = (event) => {
		const { value } = event.target;
		setSelectedUserId(value);
	};

	const handleUpdateOwner = () => {
		assignOwnerMutation.mutate(
			{
				productIds: productIds,
				userId: selectedUserId,
			},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.productUpdated,
					});
					onClose();
					onOwnerUpdated();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.genericErrorMessage,
					});
				},
			}
		);
	};

	const handleCloseAssignOwnerDialog = () => {
		setSelectedUserId("");
		onClose();
	};

	return (
		<React.Fragment>
			<AssignOwner
				open={open}
				onClose={handleCloseAssignOwnerDialog}
				value={selectedUserId}
				handleSelectUser={handleSelectUser}
				onSave={handleUpdateOwner}
				disableCancelButton={assignOwnerMutation.isLoading}
				disableSaveButton={
					assignOwnerMutation.isLoading || !selectedUserId
				}
			/>
		</React.Fragment>
	);
}
