import React from "react";
import ShortNotes from "../../../Summary/ShortNotes";
import { modules } from "../../../../utils/common/ModulesName";

export default function ContactNotes({ contactId }) {
	return (
		<React.Fragment>
			<ShortNotes source={modules.CONTACT} entityId={contactId} />
		</React.Fragment>
	);
}
