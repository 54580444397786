import React from "react";
import RenderContactOnboardFieldValue from "./ContactOnboardComponent";
import RenderContactsOnboardFieldValue from "./ContactsOnboardComponent";
import RenderCompanyOnboardFieldValue from "./CompanyOnboardComponent";
import RenderTagsFieldValue from "./TagsComponent";
import RenderDateFieldValue from "./DateComponent";
import RenderSingleStringFieldValue from "./TextComponent";

export default function DealMergeTableCell(props) {
	const { fieldType, fieldValue } = props;

	return (
		<React.Fragment>
			{(function () {
				switch (fieldType) {
					case "CONTACT_ONBOARD":
						return (
							<RenderContactOnboardFieldValue
								fieldValue={fieldValue}
							/>
						);

					case "CONTACTS_ONBOARD":
						return (
							<RenderContactsOnboardFieldValue
								fieldValue={fieldValue}
							/>
						);

					case "COMPANY_ONBOARD":
						return (
							<RenderCompanyOnboardFieldValue
								fieldValue={fieldValue}
							/>
						);

					case "TAGS":
						return <RenderTagsFieldValue fieldValue={fieldValue} />;

					case "DATE":
						return <RenderDateFieldValue fieldValue={fieldValue} />;

					case "SINGLE_STRING":
						return (
							<RenderSingleStringFieldValue
								fieldValue={fieldValue}
							/>
						);

					default:
						return (
							<RenderSingleStringFieldValue
								fieldValue={fieldValue}
							/>
						);
				}
			})()}
		</React.Fragment>
	);
}
