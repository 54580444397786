import React, { useState } from "react";
import {
	Box,
	Button,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as MoreIcon } from "../../../assets/icons/more";
import { twozoStyles } from "../../../styles/twozo";
import AddCompany from "../AddCompany";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import Menu from "../../Elements/Menu";
import { useNavigate } from "react-router-dom";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";

export default function CompaniesMenu(props) {
	const { onImportClicked } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const { getModuleName } = useModuleName();
	const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
	const [companyMenuElement, setCompanyMenuElement] = useState(null);
	const isCompanyMenuOpened = Boolean(companyMenuElement);
	const viewName = getModuleName(modules.COMPANY);

	const toggleAddCompanyDialog = () => {
		setOpenAddCompanyDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const openCompanyMenu = (event) => {
		setCompanyMenuElement(event.currentTarget);
	};

	const closeCompanyMenu = () => {
		setCompanyMenuElement(null);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddCompanyDialog}
				onOpen={toggleAddCompanyDialog}
				onClose={toggleAddCompanyDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddCompany onClose={toggleAddCompanyDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={companyMenuElement}
				open={isCompanyMenuOpened}
				onClose={closeCompanyMenu}
				style={{
					marginTop: "8px",
				}}
			>
				<MenuItem
					onClick={() => navigate("duplicate")}
					style={{ height: "40px" }}
				>
					<Typography
						fontSize={13}
						color={theme.palette.secondary.contrastText}
					>
						Manage Duplicates
					</Typography>
				</MenuItem>
			</Menu>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				height="100%"
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Can permission={PERMISSIONS.company.importRecords}>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onImportClicked}
						>
							{`Import ${viewName}`}
						</Button>
					</Can>

					<Can permission={PERMISSIONS.company.create}>
						<Button
							variant="contained"
							onClick={toggleAddCompanyDialog}
							startIcon={AddIcon(20, 20, "#fff")}
							disableElevation
						>
							{viewName}
						</Button>
					</Can>
				</Stack>

				<Can permission={PERMISSIONS.company.mergeRecords}>
					<IconButton onClick={openCompanyMenu} size="small">
						{MoreIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				</Can>
			</Stack>
		</React.Fragment>
	);
}
