import { SearchContext } from "..";
import { useSearchReducer } from "../../SearchReducer";

export const SearchProvider = (props) => {
	const { children } = props;
	const { searchState, openSearchBar, closeSearchBar } = useSearchReducer();

	return (
		<SearchContext.Provider
			value={{
				searchState,
				openSearchBar,
				closeSearchBar,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};
