import { useQuery } from "@tanstack/react-query";
import {
	getDealFieldListKey,
	getDealForecastViewDataKey,
} from "../../../../utils/queryKeys";
import {
	getDealFieldList,
	getDealForecastViewData,
} from "../../../../api/deal/dealForecast/dealForecastApi";

const useDealForecastViewlistData = (
	pipelineId,
	startDate,
	colomn,
	interval,
	dealState,
	dealField,
	criteria
) => {
	return useQuery(
		getDealForecastViewDataKey(
			pipelineId,
			startDate,
			colomn,
			interval,
			dealState,
			dealField,
			criteria
		),
		() =>
			getDealForecastViewData(
				pipelineId,
				startDate,
				colomn,
				interval,
				dealState,
				dealField,
				criteria
			),
		{
			select: (data) => data.forecast,
			enabled: !!dealField,
		}
	);
};

const useDateFieldList = () => {
	return useQuery(getDealFieldListKey(), () => getDealFieldList(), {
		select: (data) => data.options,
	});
};

export { useDealForecastViewlistData, useDateFieldList };
