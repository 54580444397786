import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { FormFieldName } from "../../../../styles/twozo";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import ExportData from "./ExportData";
import ConfirmDialog from "../../../Elements/ConfirmDialog";
import ExportHistory from "./ExportHistory";
import FullScreenDialog from "../../../Elements/FullScreenDialog";
import {
	useAccountAdminDetails,
	useUpdateAccountAdminDetails,
} from "../../../../hooks/services/accountSettings/account";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useForm } from "react-hook-form";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";

const accountAdminDetails = {
	name: "name",
	company: "organizationName",
	mobile: "mobile",
	email: "email",
};
const errorMessage = "This field is required";

export default function Account() {
	const theme = useTheme();

	// query call
	const { data: accountAdminDetailsData } = useAccountAdminDetails();

	// mutation call
	const updateAccountAdminMutation = useUpdateAccountAdminDetails();

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({ values: accountAdminDetailsData });

	const [isExportDataDialogOpened, setIsExportDataDialogOpened] =
		useState(false);
	const [isRequestCancellationOpened, setIsRequestCancellationOpened] =
		useState(false);
	const [isExportHistoryDialogOpened, setIsExportHistoryDialogOpened] =
		useState(false);

	const openExportDataDialog = () => {
		setIsExportDataDialogOpened(true);
	};

	const closeExportDataDialog = () => {
		setIsExportDataDialogOpened(false);
	};

	const openRequestCancellationDialog = () => {
		setIsRequestCancellationOpened(true);
	};

	const closeRequestCancellationDialog = () => {
		setIsRequestCancellationOpened(false);
	};

	const openExportHistoryDialog = () => {
		setIsExportHistoryDialogOpened(true);
	};

	const closeExportHistoryDialog = () => {
		setIsExportHistoryDialogOpened(false);
	};

	const handleSaveClick = (adminDetails) => {
		removeFieldsWithEmptyValues(adminDetails);
		updateAccountAdminMutation.mutate(adminDetails, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.accountSettingsAccountUpdated,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isExportDataDialogOpened}
				onOpen={openExportDataDialog}
				onClose={closeExportDataDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<ExportData onClose={closeExportDataDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<ConfirmDialog
				open={isRequestCancellationOpened}
				title="Are you sure want to cancel the account?"
				subtitle="We will permanently delete your account and all related data. Go ahead with your request?"
				onCancel={closeRequestCancellationDialog}
				onConfirm={closeRequestCancellationDialog}
				confirmButtonText="Confirm Request"
				confirmButtonColor="primary"
			/>

			<FullScreenDialog
				open={isExportHistoryDialogOpened}
				onClose={closeExportHistoryDialog}
				title="Export History"
			>
				<ExportHistory />
			</FullScreenDialog>

			<Box px={2} pb={0.5}>
				<Typography
					fontSize={14}
					fontWeight={400}
					sx={{ opacity: 0.6 }}
				>
					This person will receive invoices and all other
					account-related information.
				</Typography>
			</Box>

			<Box
				style={{
					border: "1px solid rgb(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				mx={1}
				mt={1}
				mb={2}
			>
				<Box px={2} pt={3}>
					<Typography fontSize={16} fontWeight={600}>
						Point of Contact for your Twozo Account
					</Typography>

					<Stack spacing={2} py={2}>
						<Stack>
							<Stack direction="row">
								<FormFieldName
									color={
										errors[accountAdminDetails.company] &&
										theme.palette.error.main
									}
								>
									Company Name
								</FormFieldName>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>

							<TextField
								{...register(accountAdminDetails.company, {
									required: true,
								})}
								sx={{ width: "35%" }}
								variant="outlined"
								FormHelperTextProps={{
									sx: {
										marginLeft: "8px",
										fontSize: 13,
										fontWeight: 500,
									},
								}}
								onChange={() => {
									clearErrors(accountAdminDetails.company);
								}}
								error={errors[accountAdminDetails.company]}
								helperText={
									errors[accountAdminDetails.company] &&
									errorMessage
								}
							/>
						</Stack>

						<Stack>
							<Stack direction="row">
								<FormFieldName
									color={
										errors[accountAdminDetails.name] &&
										theme.palette.error.main
									}
								>
									Name
								</FormFieldName>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>

							<TextField
								{...register(accountAdminDetails.name, {
									required: true,
								})}
								sx={{ width: "35%" }}
								FormHelperTextProps={{
									sx: {
										marginLeft: "8px",
										fontSize: 13,
										fontWeight: 500,
									},
								}}
								onChange={() => {
									clearErrors(accountAdminDetails.name);
								}}
								variant="outlined"
								error={errors[accountAdminDetails.name]}
								helperText={
									errors[accountAdminDetails.name] &&
									errorMessage
								}
							/>
						</Stack>

						<Stack sx={{ opacity: 0.5 }}>
							<Stack direction="row">
								<FormFieldName>Email</FormFieldName>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>

							<TextField
								{...register(accountAdminDetails.email)}
								sx={{ width: "35%" }}
								variant="outlined"
								focused={false}
								InputProps={{
									readOnly: true,
								}}
							/>
						</Stack>

						<Stack spacing={0.5}>
							<FormFieldName>Phone</FormFieldName>

							<TextField
								{...register(accountAdminDetails.mobile)}
								placeholder="9876543210"
								type="number"
								sx={{
									width: "35%",
									"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
										{
											"-webkit-appearance": "none",
										},
								}}
							/>
						</Stack>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={1}
						pt={1}
						pb={3}
					>
						<Button
							variant="contained"
							disableElevation
							disabled={updateAccountAdminMutation.isLoading}
							onClick={handleSubmit(handleSaveClick)}
						>
							Save
						</Button>

						<Button
							variant="contained"
							color="secondary"
							disableElevation
							disabled={updateAccountAdminMutation.isLoading}
							onClick={() => reset()}
						>
							Cancel
						</Button>
					</Stack>

					<Divider />
				</Box>

				<Box px={2} pt={3}>
					<Stack
						pb={3}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack>
							<Typography fontSize={18} fontWeight={600}>
								Export data
							</Typography>
							<Typography
								fontSize={14}
								fontWeight={400}
								sx={{ opacity: 0.6 }}
							>
								You can export your Twozo data up to 5 times per
								day. You’ll get an email with a link to download
								the data as a ZIP file.{" "}
							</Typography>
						</Stack>

						<Stack
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={openExportHistoryDialog}
							>
								Export History
							</Button>

							<Button
								variant="contained"
								disableElevation
								onClick={openExportDataDialog}
							>
								Export Data
							</Button>
						</Stack>
					</Stack>

					<Divider />
				</Box>

				<Box px={2} pt={3} pb={5}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack>
							<Typography fontSize={18} fontWeight={600}>
								Account cancellation request
							</Typography>
							<Typography
								fontSize={14}
								fontWeight={400}
								sx={{ opacity: 0.6 }}
							>
								Your request goes to our customer success team,
								who’ll get in touch with you to take this
								forward.
							</Typography>
						</Stack>

						<Button
							variant="contained"
							disableElevation
							style={{
								boxShadow:
									"0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
							}}
							onClick={openRequestCancellationDialog}
						>
							Request Cancellation
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
