import {
	Box,
	Checkbox,
	Grid,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Field from "../../../Field";
import React from "react";

export default function DefaultField(params) {
	const { setActionFields, actionFields, metadata, fieldError } = params;
	const theme = useTheme();

	const toggleEnableField = (fieldEnabled, fieldIndex) => {
		setActionFields((actionFields) => {
			if (!actionFields[fieldIndex][0]["config"]) {
				actionFields[fieldIndex][0]["config"] = {};
			}
			actionFields[fieldIndex][0]["config"]["isEnabled"] = fieldEnabled;
			return [...actionFields];
		});
	};

	return (
		<>
			<Box
				style={{
					height: "100%",
					overflowY: "auto",
					backgroundColor: theme.palette.secondary.main,
					borderRadius: "8px",
					border: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				p={1}
			>
				<Grid container spacing={2} rowSpacing={1}>
					<Grid item xs={12} sm={6}>
						<Typography fontSize={14} fontWeight={600}>
							Fields
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Typography fontSize={14} fontWeight={600}>
							Values
						</Typography>
					</Grid>

					{actionFields?.map((actionField, index) => (
						<React.Fragment
							key={
								metadata.actionSource +
								"-" +
								metadata.actionEvent +
								"-" +
								index
							}
						>
							<Grid item xs={12} sm={6}>
								<Stack
									direction="row"
									alignItems="center"
									spacing={0.5}
									px={1}
									style={{
										borderRadius: "8px",
										border: "1px solid rgba(0, 0, 0, 0.1)",
										backgroundColor: "#fff",
										minHeight: 42,
									}}
								>
									<Checkbox
										onChange={(event) =>
											toggleEnableField(
												event.target.checked,
												index
											)
										}
										disabled={
											actionField[0]?.config?.isRequired
										}
										checked={
											actionField[0]?.config
												?.isRequired ||
											actionField[0]?.config?.isEnabled
										}
									/>

									<Typography
										fontSize={14}
										maxWidth="300px"
										noWrap
									>
										{actionField[0].displayName}
									</Typography>
								</Stack>
							</Grid>

							<Grid item xs={12} sm={6}>
								<Field
									field={actionField[0]}
									index={index}
									hasError={fieldError?.[index]?.[0]}
									metadata={metadata}
								/>
							</Grid>
						</React.Fragment>
					))}
				</Grid>
			</Box>
		</>
	);
}
