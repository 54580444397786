export default function CloudConnection(
	width = 16,
	height = 16,
	color = "#000"
) {
	return (
		<svg
			width={width}
			height={height}
			color={color}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.30859 7.92531C1.90859 8.16697 1.91693 13.092 5.30859 13.3336L13.3586 13.3336C14.3336 13.342 15.2753 12.9753 16.0003 12.317C18.3836 10.2337 17.1086 6.06696 13.9669 5.66696C12.8419 -1.11638 3.01694 1.45864 5.34194 7.92531"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 13.334V15.834"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.9987 19.1673C9.07822 19.1673 8.33203 18.4211 8.33203 17.5007C8.33203 16.5802 9.07822 15.834 9.9987 15.834C10.9192 15.834 11.6654 16.5802 11.6654 17.5007C11.6654 18.4211 10.9192 19.1673 9.9987 19.1673Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.0013 17.5H11.668"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.33333 17.5H5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
