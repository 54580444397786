import ErrorNotification from "../../components/Notification/ErrorNotification";
import ReminderNotification from "../../components/Notification/ReminderNotification";
import SuccessNotification from "../../components/Notification/SuccessNotification";

// variants
export const notificationVariants = {
	success: "success",
	error: "error",
	reminder: "reminder",
};

// components
export const components = {
	[notificationVariants.success]: SuccessNotification,
	[notificationVariants.error]: ErrorNotification,
	[notificationVariants.reminder]: ReminderNotification,
};

export const autoHideDuration = 3000;
