import React, { useState } from "react";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import {
	useChangePassword,
	useCurrentUserDetails,
} from "../../../hooks/services/userProfile";
import { Tooltip } from "../../../styles/twozo";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";

export default function ChangePassword(props) {
	const { closeChangePasswordDialog } = props;
	const theme = useTheme();
	const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] =
		useState(false);
	const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(false);
	const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] =
		useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isNewAndConfirmPasswordSame, setIsNewAndConfirmPasswordSame] =
		useState(true);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const isChangePasswordButtonDisabled =
		currentPassword === "" || newPassword === "" || confirmPassword === "";

	const passwordConstraints = [
		"Atleast 1 Lower-case",
		"Atleast 1 Upper-case",
		"Atleast 1 Number",
		"Atleast 1 Special character",
		"8-16 Characters",
	];

	const { data: userDetails } = useCurrentUserDetails();
	const email = userDetails?.user.email;
	const useChangePasswordMutation = useChangePassword();

	const handleClickShowCurrentPassword = () =>
		setIsCurrentPasswordHidden((show) => !show);

	const handleClickShowNewPassword = () =>
		setIsNewPasswordHidden((show) => !show);

	const handleClickShowConfirmPassword = () =>
		setIsConfirmPasswordHidden((show) => !show);

	const handleCurrentPassword = (event) => {
		setCurrentPassword(event.target.value);
	};

	const handleNewPassword = (event) => {
		setNewPassword(event.target.value);
	};

	const handleConfirmPassword = (event) => {
		const value = event.target.value;
		setConfirmPassword(value);
		setIsNewAndConfirmPasswordSame(true);
	};

	const handleChangePassword = () => {
		if (newPassword === confirmPassword) {
			changeCurrentPassword();
		} else {
			setIsNewAndConfirmPasswordSame(false);
		}
	};

	const changeCurrentPassword = () => {
		const changePasswordRequest = {
			email: email,
			oldPassword: currentPassword,
			newPassword: confirmPassword,
		};
		useChangePasswordMutation.mutate(changePasswordRequest, {
			onSuccess: () => {
				closeChangePasswordDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Stack p={2} spacing={2}>
				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Current password
					</Typography>

					<TextField
						placeholder="Enter password"
						type={isCurrentPasswordHidden ? "password" : "text"}
						FormHelperTextProps={{
							sx: {
								marginLeft: 0,
								fontSize: 13,
							},
						}}
						onChange={handleCurrentPassword}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowCurrentPassword}
										edge="end"
									>
										{isCurrentPasswordHidden
											? EyeIcon(20, 20, "#999999")
											: EyeOffIcon(20, 20, "#999999")}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						New Password
					</Typography>

					<Tooltip
						open={isTooltipOpen}
						PopperProps={{
							modifiers: [
								{
									name: "offset",
									options: {
										offset: [30, -3],
									},
								},
							],
						}}
						placement="right"
						title={
							<List
								style={{
									fontSize: "14px",
									fontWeight: 300,
									minWidth: "223px",
									padding: "4px",
								}}
							>
								Password must contain:
								{passwordConstraints.map(
									(validation, index) => (
										<ListItem
											key={index}
											sx={{
												"::before": {
													content:
														// eslint-disable-next-line quotes
														'"\\2022"' /* Unicode bullet character */,
													marginRight: 1,
												},
												p: "0px 0px 0px 7px",
											}}
										>
											{validation}
										</ListItem>
									)
								)}
							</List>
						}
					>
						<TextField
							placeholder="Enter password"
							type={isNewPasswordHidden ? "password" : "text"}
							FormHelperTextProps={{
								sx: {
									marginLeft: 0,
									fontSize: 13,
								},
							}}
							onChange={handleNewPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowNewPassword}
											edge="end"
										>
											{isNewPasswordHidden
												? EyeIcon(20, 20, "#999999")
												: EyeOffIcon(20, 20, "#999999")}
										</IconButton>
									</InputAdornment>
								),
							}}
							onFocus={() => {
								setIsTooltipOpen(true); // open tooltip on focus
							}}
							onBlur={() => {
								setIsTooltipOpen(false); // Close tooltip on blur
							}}
						/>
					</Tooltip>
				</Stack>

				<Stack spacing={0.5}>
					<Typography
						fontSize={13}
						fontWeight={500}
						color={
							!isNewAndConfirmPasswordSame &&
							theme.palette.error.main
						}
					>
						Confirm password
					</Typography>

					<TextField
						placeholder="Enter password"
						type={isConfirmPasswordHidden ? "password" : "text"}
						FormHelperTextProps={{
							sx: {
								marginLeft: 0,
								fontSize: 13,
							},
						}}
						onChange={handleConfirmPassword}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowConfirmPassword}
										edge="end"
									>
										{isConfirmPasswordHidden
											? EyeIcon(20, 20, "#999999")
											: EyeOffIcon(20, 20, "#999999")}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={!isNewAndConfirmPasswordSame}
						helperText={
							!isNewAndConfirmPasswordSame &&
							"Does not match the new password."
						}
					/>
				</Stack>

				<Box p={1}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={closeChangePasswordDialog}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							disableElevation
							disabled={isChangePasswordButtonDisabled}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255,255,255, 0.6)",
								},
							}}
							onClick={handleChangePassword}
						>
							Change Password
						</Button>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
