import React from "react";

function Monitor(width = 20, height = 20, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.6248 1.6665C17.5915 1.6665 18.3332 2.40817 18.3332 5.3665V10.6415C18.3332 13.6082 17.5915 14.3416 14.6332 14.3416H5.3665C2.40817 14.3498 1.6665 13.6082 1.6665 10.6498V5.3665C1.6665 2.40817 2.40817 1.6665 5.3665 1.6665H14.6248Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 14.3496V18.3329"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.6665 10.833H18.3332"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.25 18.333H13.75"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Monitor;
