import React from "react";
import CompanyComponent from "../../../../AddForm/Field/FieldComponents/CompanyComponent";

export default function CompanyFieldComponent({ field }) {
	return (
		<React.Fragment>
			<CompanyComponent
				field={field}
				fieldSpecificProps={{
					isIconNeeded: false,
				}}
			/>
		</React.Fragment>
	);
}
