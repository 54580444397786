import React, { useState } from "react";
import { Box, Paper, Radio, Stack, Typography, useTheme } from "@mui/material";
import ContactLogo from "../../../../../../assets/images/contact/unknownContact.png";
import { default as RadioOffIcon } from "../../../../../../assets/icons/radioOff";
import { default as RadioTickedIcon } from "../../../../../../assets/icons/radioTicked";

const styles = {
	personName: {
		fontSize: 14,
		fontWeight: 500,
	},
	details: {
		fontSize: 14,
	},
	text: {
		fontSize: 13,
	},
};

export default function ContactCardList(props) {
	const { contactData, handleSelectContact, isSelected } = props;
	const theme = useTheme();
	const [hoveredCardId, setHoveredCardId] = useState(null);

	const onMouseOverCard = (id) => setHoveredCardId(id);
	const onMouseOutCard = () => setHoveredCardId(null);

	return (
		<Box>
			<Paper
				key={contactData.id}
				elevation={0}
				onMouseOver={() => onMouseOverCard(contactData.id)}
				onMouseOut={() => onMouseOutCard()}
				onClick={() => handleSelectContact(contactData)}
				sx={{
					boxShadow:
						contactData.id === hoveredCardId
							? "0px 2px 4px rgba(0, 0, 0, 0.1)"
							: "none",
					border: isSelected
						? "1px solid #33BC7E"
						: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					cursor: "pointer",
				}}
			>
				<Box>
					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="space-between"
						width="100%"
						p={1}
					>
						<Stack
							direction="row"
							spacing={2}
							p={1}
							alignItems="center"
						>
							<img
								src={ContactLogo}
								alt="contact_logo"
								width="24px"
								height="24px"
							/>
							<Typography
								onClick={() =>
									window.open(
										`/contacts/${contactData?.id?.toString()}`,
										"_blank"
									)
								}
								style={styles.personName}
								color={theme.palette.secondary.contrastText}
								noWrap
								maxWidth="400px"
							>
								{contactData?.fullName}
							</Typography>
						</Stack>
						<Radio
							icon={RadioOffIcon(
								22,
								22,
								theme.palette.primary.main
							)}
							checkedIcon={RadioTickedIcon(
								22,
								22,
								theme.palette.primary.main
							)}
							value={contactData.id}
							checked={isSelected}
						/>
					</Stack>

					<Box p={2} pt={0}>
						<table>
							<tbody>
								<tr>
									<td
										style={{
											minWidth: "70px",
											display: "flex",
										}}
									>
										<Typography
											style={styles.details}
											pr={1}
										>
											Email:
										</Typography>
									</td>
									<td>
										{contactData?.emails?.length > 0
											? contactData?.emails?.map(
													(email, index) => (
														<Typography
															noWrap
															maxWidth="410px"
															style={
																styles.details
															}
															key={index}
														>
															{email}
														</Typography>
													)
												)
											: "-"}
									</td>
								</tr>
								<tr>
									<td style={{ display: "flex" }}>
										<Typography style={styles.details}>
											Phone:
										</Typography>
									</td>
									<td>
										{contactData?.phones?.length > 0
											? contactData?.phones?.map(
													(phone, index) => (
														<Typography
															style={
																styles.details
															}
															key={index}
														>
															{phone}
														</Typography>
													)
												)
											: "-"}
									</td>
								</tr>
							</tbody>
						</table>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
}
