import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	emailTemplateAllFolderListKey,
	getEmailTemplateFolderListKey,
	getEmailTemplateListKey,
	getTemplateFolderSettingsDataKey,
} from "../../../../utils/queryKeys/email/emailTemplate";
import {
	createTemplateFolder,
	deleteTemplateFolder,
	getEmailTemplateAllFolderList,
	getEmailTemplateFolderList,
	getEmailTemplates,
	getTemplateFolderSettingsData,
	updateTemplateFolderName,
	updateTemplateFolderVisibility,
} from "../../../../api/email/emailTemplateFolder/emailTemplateFolderApi";

const useEmailTemplateFolderList = (templateViewType, searchValue) => {
	return useQuery(
		getEmailTemplateFolderListKey(templateViewType, searchValue),
		() => getEmailTemplateFolderList(templateViewType, searchValue),
		{
			select: (data) => data.list,
		}
	);
};

const useEmailTemplates = (folderId) => {
	return useQuery(
		getEmailTemplateListKey(folderId),
		() => getEmailTemplates(folderId),
		{
			enabled: !!folderId,
			select: (data) => data.templates,
		}
	);
};

const useEmailTemplateAllFolderList = () => {
	return useQuery(
		emailTemplateAllFolderListKey,
		() => getEmailTemplateAllFolderList(),
		{
			select: (data) => data.list,
		}
	);
};

const useTemplateFolderSettingsData = (folderId) => {
	return useQuery(
		getTemplateFolderSettingsDataKey(folderId),
		() => getTemplateFolderSettingsData(folderId),
		{
			enabled: !!folderId,
			select: (data) => data.visibleTo[0],
		}
	);
};

const useInvalidateEmailTemplateFolderList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(emailTemplateAllFolderListKey);
	};
};

const useInvalidateEmailTemplates = (folderId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getEmailTemplateListKey(folderId));
	};
};

const useInvalidateTemplateFolderSettingsData = (folderId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(
			getTemplateFolderSettingsDataKey(folderId)
		);
	};
};

const useCreateTemplateFolder = () => {
	let invalidateEmailTemplateFolderList =
		useInvalidateEmailTemplateFolderList();
	return useMutation(createTemplateFolder, {
		onSuccess: () => {
			invalidateEmailTemplateFolderList();
		},
	});
};

const useUpdateTemplateFolderName = () => {
	let invalidateEmailTemplateFolderList =
		useInvalidateEmailTemplateFolderList();
	return useMutation(updateTemplateFolderName, {
		onSuccess: () => {
			invalidateEmailTemplateFolderList();
		},
	});
};

const useUpdateFolderVisibility = (folderId) => {
	let invalidateTemplateFolderSettingsData =
		useInvalidateTemplateFolderSettingsData(folderId);
	return useMutation(updateTemplateFolderVisibility, {
		onSuccess: () => {
			invalidateTemplateFolderSettingsData();
		},
	});
};

const useDeleteTemplateFolder = () => {
	let invalidateEmailTemplateFolderList =
		useInvalidateEmailTemplateFolderList();
	return useMutation(deleteTemplateFolder, {
		onSuccess: () => {
			invalidateEmailTemplateFolderList();
		},
	});
};

export {
	useEmailTemplateFolderList,
	useCreateTemplateFolder,
	useUpdateTemplateFolderName,
	useUpdateFolderVisibility,
	useDeleteTemplateFolder,
	useEmailTemplates,
	useInvalidateEmailTemplates,
	useEmailTemplateAllFolderList,
	useTemplateFolderSettingsData,
};
