import React from "react";
import DynamicDropdown from "./DynamicDropdown";

export default function DropdownBaseComponent({
	onChange,
	value,
	field,
	inputRef,
	values,
	error,
	clearErrors,
}) {
	return (
		<React.Fragment>
			<DynamicDropdown
				error={error}
				clearErrors={clearErrors}
				inputRef={inputRef}
				field={field}
				value={value}
				onChange={onChange}
				values={values}
			/>
		</React.Fragment>
	);
}
