import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { Typography, useTheme } from "@mui/material";
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";
import TimeRange from "../../TimeRangePicker";

const textFieldStyle = {
	borderRight: "1px solid rgba(0, 0, 0, 0.12)",
};

const TimeRangeFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose, subFields }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [timeRangeValue, setTimeRangeValue] = useState({});

		const getSubFieldObject = (subFieldName) => {
			let subFieldObj;
			if (!!subFields && Array.isArray(subFields)) {
				subFields.forEach((field) => {
					if (field.name === subFieldName) {
						subFieldObj = field;
					}
				});
			}

			return subFieldObj;
		};

		const fromField = getSubFieldObject("FROM");
		const toField = getSubFieldObject("TO");

		useEffect(() => {
			if (value) {
				let timeRangeValueObj = {};
				timeRangeValueObj.from = value[fromField.id];
				timeRangeValueObj.to = value[toField.id];
				setTimeRangeValue(timeRangeValueObj);
			}
		}, [value, fromField, toField]);

		const handleTimeRangeValueChange = (newTimeRangeValue, onChange) => {
			setTimeRangeValue({ ...newTimeRangeValue });
			let timeRangeValueObjectWithId = {};
			timeRangeValueObjectWithId[fromField.id] = newTimeRangeValue.from;
			timeRangeValueObjectWithId[toField.id] = newTimeRangeValue.to;
			onChange(timeRangeValueObjectWithId);
			clearErrors(fieldId + "");
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const onClearError = () => {
			onError(null);
			clearErrors(fieldId + "");
		};

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<TimeRange
							{...fieldData}
							value={timeRangeValue}
							removeBorder={true}
							onClearError={onClearError}
							textFieldStyle={textFieldStyle}
							onChange={(data) =>
								handleTimeRangeValueChange(data, onChange)
							}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

TimeRangeFieldEditForm.displayName = "TimeRangeFieldEditForm";

export default TimeRangeFieldEditForm;
