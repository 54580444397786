import React, { useState } from "react";
import { MenuItem, Typography, useTheme } from "@mui/material";
import Menu from "../../Menu";
import { useNavigate } from "react-router-dom";

export default function ContactAssigned(props) {
	const {
		notificationData,
		onClose,
		isNotificationActionEnabled = true,
	} = props;

	const theme = useTheme();
	const navigate = useNavigate();
	const [contactMenuElement, setContactMenuElement] = useState(null);
	const isContactMenuOpened = Boolean(contactMenuElement);

	const openContactMenu = (event) => {
		event?.stopPropagation();
		if (isNotificationActionEnabled) {
			setContactMenuElement(event.currentTarget);
		}
	};

	const closeContactMenu = (event) => {
		event?.stopPropagation();
		setContactMenuElement(null);
	};

	const navigateToContact = (event, contactId) => {
		event?.stopPropagation();
		if (contactId) {
			navigate(`/contacts/${contactId?.toString()}`);
			setContactMenuElement(null);
			onClose();
		}
	};

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={contactMenuElement}
				open={isContactMenuOpened}
				onClose={closeContactMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{notificationData?.data?.contacts?.map((contact) => (
					<MenuItem
						key={contact?.id}
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateToContact(event, contact?.id)
						}
					>
						<Typography
							fontSize={13}
							color={theme.palette.secondary.contrastText}
						>
							{contact?.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Typography
				component="span"
				fontSize={13}
				color={theme.palette.secondary.contrastText}
				onClick={(event) =>
					navigateToContact(
						event,
						notificationData?.data?.contacts?.[0]?.id
					)
				}
			>
				{notificationData?.data?.contacts?.[0]?.name}
			</Typography>

			{notificationData?.data?.contacts?.length > 1 ? (
				<Typography
					component="span"
					fontSize={13}
					onClick={openContactMenu}
					color={theme.palette.secondary.contrastText}
				>
					&nbsp;
					{`+${notificationData.data.contacts.length - 1}`}
				</Typography>
			) : null}
		</React.Fragment>
	);
}
