import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../auth";

//Product field permissions
const useProductFieldPermissions = () => {
	const { isUserAllowedFor } = useAuth();

	return {
		phone: {
			edit: isUserAllowedFor(PERMISSIONS.product.edit),
		},
		email: {
			edit: isUserAllowedFor(PERMISSIONS.product.edit),
		},
		owner: {
			enabled: isUserAllowedFor(PERMISSIONS.product.assignRecords),
		},
	};
};

export { useProductFieldPermissions };
