import { apiClient } from "..";
import { getSearchUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getSearchedResults = async ({ searchText, filter }) => {
	let requestData = getSearchedResultsRequest(searchText, filter);
	return await postSearchApi(requestData);
};

export const updateSearchedAndViewedData = async ({
	index,
	entryId,
	searchText,
}) => {
	let requestData = getUpdateViewedRequestData(index, entryId, searchText);
	return await postSearchApi(requestData);
};

export const getSearchedAndViewedData = async () => {
	let requestData = getSearchedAndViewedDataRequest();
	return await postSearchApi(requestData);
};

const getSearchedResultsRequest = (searchText, filter) => {
	return {
		type: "search",
		data: {
			index: filter,
			searchText: searchText,
			range: {
				from: 1,
				count: 100,
			},
		},
	};
};

const getUpdateViewedRequestData = (index, entryId, searchText) => {
	return {
		type: "retrieveEntry",
		data: {
			index: index,
			entryId: entryId,
			searchText: searchText,
		},
	};
};

const getSearchedAndViewedDataRequest = () => {
	return {
		type: "getViewedEntries",
		data: {},
	};
};

const postSearchApi = async (requestData) => {
	let searchApi = getSearchUrl();
	let response = await apiClient.post(searchApi, requestData);
	assertError(response, searchApi);
	return response.data.data;
};
