import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import { activityGoalsIds } from "../../../../../../../utils/PermissionUtils";

export default function ActivityGoals(props) {
	const { permissions, isEditable } = props;

	const {
		permissionState,
		updateActivityGoalsList,
		initializeActivityGoalsListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeActivityGoalsListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		if (id === activityGoalsIds.viewGoals) {
			//Without enabling the view access, we can’t enable the create, edit, delete, and recalculating access
			if (!event.target.checked) {
				const updatedActivityGoalsList =
					permissionState.activityGoalsList.map((list) => {
						if (list.id === id) {
							return {
								...list,
								access: event.target.checked,
							};
						} else {
							return {
								...list,
								access: event.target.checked,
								accessDisable: !event.target.checked,
							};
						}
					});
				updateActivityGoalsList(updatedActivityGoalsList);
			} else {
				const updatedActivityGoalsList =
					permissionState.activityGoalsList.map((list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
							};

							return updatedList;
						}
						const updatedList = {
							...list,
							accessDisable: !event.target.checked,
						};

						return updatedList;
					});
				updateActivityGoalsList(updatedActivityGoalsList);
			}
		} else {
			const updatedActivityGoalsList =
				permissionState.activityGoalsList.map((list) => {
					if (list.id === id) {
						return {
							...list,
							access: event.target.checked,
						};
					} else {
						return list;
					}
				});
			updateActivityGoalsList(updatedActivityGoalsList);
		}
	};

	const handleChange = (event, id) => {
		const updatedActivityGoalsList = permissionState.activityGoalsList.map(
			(list) => {
				if (list.id === id) {
					return {
						...list,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) =>
									permission.name === event.target.value
										? { ...permission, value: true }
										: { ...permission, value: false }
							),
						},
					};
				} else {
					return list;
				}
			}
		);
		updateActivityGoalsList(updatedActivityGoalsList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Activity Goals"
				lists={permissionState.activityGoalsList}
				handleAccessChange={handleAccessChange}
				handleChange={handleChange}
				isEditable={isEditable}
			/>
		</React.Fragment>
	);
}
