import { useWorkflowFilterUserList } from "../../../../../../hooks/services/workflow";
import FilterBaseComponent from "../FilterBaseComponent";

export default function CreatedByFilter({
	styles,
	criteria,
	handleUpdateFilter,
}) {
	const { data: userList } = useWorkflowFilterUserList();

	return (
		<>
			<FilterBaseComponent
				styles={styles}
				criteria={criteria}
				filterName="Created by"
				handleUpdateFilter={handleUpdateFilter}
				filterValuesList={userList || []}
			/>
		</>
	);
}
