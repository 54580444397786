import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getNotificationSettingsDataKey } from "../../../../utils/queryKeys/appNotification";
import {
	getNotificationSettingsData,
	resetNotificationSettingsData,
	updateNotificationSettingsData,
} from "../../../../api/notification/notificationSettings/notificationSettings";

const useNotificationSettingsData = () => {
	return useQuery(
		getNotificationSettingsDataKey(),
		() => getNotificationSettingsData(),
		{
			select: (data) => data?.data?.settings,
		}
	);
};

const useInvalidateNotificationSettingsData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getNotificationSettingsDataKey);
	};
};

const useUpdateNotificationSettings = () => {
	let invalidateNotificationSettingData =
		useInvalidateNotificationSettingsData();
	return useMutation(updateNotificationSettingsData, {
		onSuccess: () => {
			invalidateNotificationSettingData();
		},
	});
};

const useResetNotificationSettings = () => {
	let invalidateNotificationSettingData =
		useInvalidateNotificationSettingsData();
	return useMutation(resetNotificationSettingsData, {
		onSuccess: () => {
			invalidateNotificationSettingData();
		},
	});
};

export {
	useNotificationSettingsData,
	useUpdateNotificationSettings,
	useResetNotificationSettings,
};
