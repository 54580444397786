import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { searchedAndViewedKey } from "../../../utils/queryKeys";
import {
	updateSearchedAndViewedData,
	getSearchedAndViewedData,
	getSearchedResults,
} from "../../../api/commonSearch/commonSearchApi";

const useSearchedAndViewedData = (isSearchedAndViewedEnabled) => {
	return useQuery(searchedAndViewedKey, () => getSearchedAndViewedData(), {
		enabled: isSearchedAndViewedEnabled,
		refetchOnWindowFocus: false,
	});
};

const useUpdateSearchedAndViewed = () => {
	let invalidateSearchedAndViewed = useInvalidateSearchedAndViewed();
	return useMutation(updateSearchedAndViewedData, {
		onSuccess: () => {
			invalidateSearchedAndViewed();
		},
	});
};

const useSearchResultsMutation = () => {
	return useMutation(getSearchedResults);
};

export const useInvalidateSearchedAndViewed = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(searchedAndViewedKey);
	};
};

export {
	useSearchedAndViewedData,
	useUpdateSearchedAndViewed,
	useSearchResultsMutation,
};
