import { permissionTypes, scope, sourceIds, type } from "..";

export const getSourceConfigForPermission = (
	permissions,
	isCreateRole,
	access
) => {
	return [
		{
			id: sourceIds.contacts,
			name: "Contacts",
			access: permissions.contacts.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.contacts.view,
						disabled: !permissions.contacts.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.contacts.create,
						disabled: !permissions.contacts.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.contacts.edit,
						disabled: !permissions.contacts.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.contacts.delete,
						disabled: !permissions.contacts.access,
					},
					{
						id: scope.scope,
						selectedAccess: isCreateRole
							? access
							: permissions.contacts.scope,
					},
				],
			},
		},
		{
			id: sourceIds.companies,
			name: "Companies",
			access: permissions.companies.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.companies.view,
						disabled: !permissions.companies.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.companies.create,
						disabled: !permissions.companies.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.companies.edit,
						disabled: !permissions.companies.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.companies.delete,
						disabled: !permissions.companies.access,
					},
					{
						id: scope.scope,
						selectedAccess: isCreateRole
							? access
							: permissions.companies.scope,
					},
				],
			},
		},
		{
			id: sourceIds.deals,
			name: "Deals",
			access: permissions.deals.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.deals.view,
						disabled: !permissions.deals.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.deals.create,
						disabled: !permissions.deals.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.deals.edit,
						disabled: !permissions.deals.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.deals.delete,
						disabled: !permissions.deals.access,
					},
					{
						id: scope.scope,
						selectedAccess: isCreateRole
							? access
							: permissions.deals.scope,
					},
				],
			},
		},
		{
			id: sourceIds.products,
			name: "Products",
			access: permissions.products.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.products.view,
						disabled: !permissions.products.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.products.create,
						disabled: !permissions.products.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.products.edit,
						disabled: !permissions.products.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.products.delete,
						disabled: !permissions.products.access,
					},
					{
						id: scope.scope,
						selectedAccess:
							isCreateRole && access !== scope.territoryAccess
								? access
								: permissions.products.scope,
					},
				],
			},
		},
	];
};
