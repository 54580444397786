import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { emailPattern, urlRegex } from "../../../../../../utils/validation";

const MATCHERS = [
	(text) => {
		const urlMatch = urlRegex.exec(text);

		if (urlMatch === null) {
			return null;
		}
		const urlMatchText = urlMatch[0];

		return {
			index: urlMatch.index,
			length: urlMatchText.length,
			text: urlMatchText,
			url: urlMatchText.startsWith("http")
				? urlMatchText
				: `https://${urlMatchText}`,
			attributes: { target: "_blank", rel: "noreferrer noopener" },
		};
	},
	(text) => {
		const mailMatch = emailPattern.exec(text);

		if (mailMatch === null) {
			return null;
		}
		const mailMatchText = mailMatch[0];

		return {
			index: mailMatch.index,
			length: mailMatchText.length,
			text: mailMatchText,
			url: `mailto:${mailMatchText}`,
		};
	},
];

export default function LexicalAutoLinkPlugin() {
	return <AutoLinkPlugin matchers={MATCHERS} />;
}
