import React from "react";
import twozoLogo from "../../../assets/images/logo/twozo-full.png";
import { Box, Stack, Typography } from "@mui/material";

export default function PageNotFound() {
	return (
		<React.Fragment>
			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					minHeight: "100vh",
					backgroundColor: "#FAFAFA",
				}}
			>
				<Box
					style={{
						width: "450px",
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
				>
					<Stack spacing={4} p={5} pb={3}>
						<Box>
							<img
								alt="twozo"
								src={twozoLogo}
								width="108px"
								height="34px"
							/>
						</Box>
						<Stack spacing={1}>
							<Typography fontSize={18} fontWeight={600}>
								Page Not Found!
							</Typography>
							<Typography fontSize={13} fontWeight={400}>
								The page you were looking was not found.
							</Typography>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
