import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SettingsMenu(props) {
	const { name, settings, hasSubSettings, columnCount = 3 } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const [hovered, setHovered] = useState(null);

	function getBorderBottom(index) {
		if (hovered === index || hovered === index + columnCount) {
			return "1px solid transparent";
		}
		return settings.length <= columnCount ||
			index <
				settings.length - (((settings.length - 1) % columnCount) + 1)
			? "1px solid #E0E0E0"
			: "1px solid transparent";
	}

	function getBorderRight(index) {
		if (hovered === index || hovered === index + 1) {
			return "1px solid transparent";
		}
		return (index + 1) % columnCount !== 0 &&
			!(settings.length < columnCount && index === settings.length - 1)
			? "1px solid #E0E0E0"
			: "1px solid transparent";
	}

	return (
		<React.Fragment>
			<Box>
				{!!name && (
					<Typography
						fontSize={hasSubSettings ? 18 : 16}
						fontWeight={500}
						pb={hasSubSettings ? 2 : 1}
					>
						{name}
					</Typography>
				)}

				<Box
					style={{
						display: "grid",
						gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
					}}
				>
					{settings?.map((setting, index) => (
						<Box
							key={index}
							onMouseEnter={() => {
								!hasSubSettings && setHovered(index);
							}}
							onMouseLeave={() => {
								!hasSubSettings && setHovered(null);
							}}
							style={{
								borderBottom: getBorderBottom(index),
								borderRight: getBorderRight(index),
								// minHeight: "56px",
							}}
						>
							<Stack
								key={index}
								direction="row"
								alignItems="center"
								spacing={2}
								style={{
									borderRadius: "8px",
									cursor: !hasSubSettings
										? "pointer"
										: "auto",
									backgroundColor:
										!hasSubSettings && hovered === index
											? theme.palette.secondary.main
											: "transparent",
								}}
								onClick={() => {
									!hasSubSettings && navigate(setting.link);
								}}
								p={setting.icon ? 2 : 1.5}
							>
								{!!setting.icon && (
									<Box display="flex">
										{setting.icon(
											24,
											24,
											theme.palette.primary.main
										)}
									</Box>
								)}

								<Typography
									color={
										!hasSubSettings && hovered === index
											? theme.palette.primary.main
											: "#000"
									}
								>
									{setting.name}
								</Typography>
							</Stack>

							{!!setting.subSettings && (
								<Box
									pl={(index + 1) % columnCount !== 1 ? 2 : 0}
									pr={(index + 1) % columnCount !== 0 ? 2 : 0}
									pb={2}
								>
									<SettingsMenu
										{...setting.subSettings}
										columnCount={1}
									/>
								</Box>
							)}
						</Box>
					))}
				</Box>
			</Box>
		</React.Fragment>
	);
}
