import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getContactTimelineKey } from "../../../../utils/queryKeys";
import { getContactTimelineData } from "../../../../api/timeline/timelineAPI";
import { timelinePageSize } from "../../../../utils/queryConstants/summary/timeline";

const useContactTimelineData = (contactId, timelineIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getContactTimelineKey(contactId, timelineIdsToFilter),
		queryFn: ({ pageParam }) => {
			return getContactTimelineData(contactId, timelineIdsToFilter, {
				start: pageParam ? (pageParam - 1) * timelinePageSize + 1 : 1,
				limit: timelinePageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		refetchOnWindowFocus: false,
	});
};

//Invalidate Contact Timeline
const useInvalidateContactTimeline = (contactId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getContactTimelineKey(contactId));
	};
};

export { useContactTimelineData, useInvalidateContactTimeline };
