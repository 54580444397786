import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	contactSyncSettingsDataKey,
	getContactSyncStatusKey,
} from "../../../utils/queryKeys/contactSync";
import {
	getContactSyncSettingsData,
	getContactSyncStatus,
	startContactSync,
	stopContactSync,
} from "../../../api/contactSync/contactSyncApi";

// query call
const useContactSyncSettingsData = () => {
	return useQuery(
		contactSyncSettingsDataKey,
		() => getContactSyncSettingsData(),
		{
			select: (syncedData) => syncedData,
			refetchOnWindowFocus: false,
		}
	);
};

// This hook is called only when the sync state is "syncing" and it refetch the query
const useContactSyncStatus = (id, isSyncStatusEnabled = false) => {
	let contactSyncStatusKey = getContactSyncStatusKey(id);
	return useQuery(contactSyncStatusKey, () => getContactSyncStatus(id), {
		enabled: isSyncStatusEnabled,
		select: (syncState) => syncState?.status,
		refetchInterval: () => syncStatusRefetchInterval(),
	});
};

// At regular intervals (every 1000 milliseconds) while the sync state is "syncing".
const syncStatusRefetchInterval = () => {
	return 1000;
};

// query invalidation call
const useInvalidateSyncSettingsData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(contactSyncSettingsDataKey);
	};
};

// mutation call
const useStartContactSync = () => {
	return useMutation(startContactSync);
};

const useStopContactSync = () => {
	let invalidateSyncSettingsData = useInvalidateSyncSettingsData();
	return useMutation(stopContactSync, {
		onSuccess: () => {
			invalidateSyncSettingsData();
		},
	});
};

export {
	useStartContactSync,
	useStopContactSync,
	useContactSyncStatus,
	useContactSyncSettingsData,
	useInvalidateSyncSettingsData,
};
