import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import Contacts from "./Contacts";
import Companies from "./Companies";
import Deals from "./Deals";
import Menu from "../../Elements/Menu";
import { twozoStyles } from "../../../styles/twozo";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import DropDownRight from "../../../assets/icons/dropDownRight";
import { useNavigate, useSearchParams } from "react-router-dom";
import RenameModuleDialogBox from "../RenameModuleDialogbox";
import Products from "./Products";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { useModuleList } from "../../../hooks/services/common";
import { useModuleName } from "../../../hooks/modules";
import {
	useBulkUpdate,
	useFieldList,
	useFieldType,
} from "../../../hooks/services/dataFields";
import { modules } from "../../../utils/common/ModulesName";
import { useFieldListContext } from "./FieldListContext";

export default function DataFieldsSettings() {
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [searchParams, setSearchParams] = useSearchParams();
	const [type, setType] = useState(searchParams.get("type"));
	const needGroupView = true;

	// mutation call
	const bulkUpdateMutation = useBulkUpdate(type);

	// query call
	const { data: modulesList } = useModuleList();
	const { getModuleName } = useModuleName();
	const { status: fieldTypeStatus, data: fieldTypeData } = useFieldType();
	const { data: fieldListData, status: fieldListStatus } = useFieldList(
		type,
		needGroupView
	);

	const {
		fieldListState,
		updateCreateModeOnFieldCreation,
		errorOnCreate,
		errorOnUpdate,
		resetCreateMode,
		resetUpdateMode,
	} = useFieldListContext();

	const [addSystemFieldsElement, setAddSystemFieldsElement] = useState(null);
	const isSystemFieldsMenuOpened = Boolean(addSystemFieldsElement);

	const [systemFields, setSystemFields] = useState([]);
	const [newFieldCreated, setNewFieldCreated] = useState([]);
	const [fieldTypeOption, setFieldTypeOption] = useState([]);
	const [systemFieldHovered, setSystemFieldHovered] = useState(null);
	const [visibilityChangedSystemFields, setVisibilityChangedSystemFields] =
		useState([]);
	const isAddSystemFieldButtonEnabled =
		visibilityChangedSystemFields.length > 0;

	const hasFieldError =
		fieldListState.hasCreationError || fieldListState.hasUpdateError;

	const getModule = (moduleName) => {
		let module = modulesList?.find(
			(module) =>
				module.sourceName.toLowerCase() === moduleName.toLowerCase()
		);
		if (module) {
			return module;
		}
	};
	const isFieldEnabled = getModule(type)?.isEnabled;

	const isFieldCreationEnabled = () => {
		return newFieldCreated.length < 1;
	};

	const addNewFieldToFieldList = () => {
		if (!getModule(type)) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});

			// eslint-disable-next-line no-undef
			if (process.env.NODE_ENV === "development") {
				console.error(
					"Cannot add new custom field as module list is unavailable"
				);
			}
			return;
		}

		if (isFieldCreationEnabled()) {
			setNewFieldCreated((newFieldCreated) => [
				...newFieldCreated,
				{
					sourceId: getModule(type).sourceId,
					fieldName: "",
					id: "new_field" + (newFieldCreated.length + 1),
					type: `${fieldTypeOption[0]?.name}`,
					isFieldNotSaved: true,
					config: {
						isDefaultField: false,
						default: null,
					},
				},
			]);
		}
	};

	const handleFieldValidation = () => {
		if (
			hasFieldError ||
			fieldListState.isAnyFieldInEditMode ||
			!isFieldEnabled
		) {
			return;
		}

		if (fieldListState.isAnyFieldInCreateMode) {
			errorOnCreate();
		} else {
			updateCreateModeOnFieldCreation();
			addNewFieldToFieldList();
		}
	};

	useEffect(() => {
		if (fieldListStatus === "success") {
			if (fieldListData) {
				let systemFields = [];

				fieldListData.map((fields) => {
					return fields.forEach((fieldElement) => {
						if (
							fieldElement.config &&
							fieldElement.config.isDefaultField
						) {
							systemFields.push(fieldElement);
						}
					});
				});
				setSystemFields(systemFields);
				setSystemFieldList(systemFields);
			}
		}
	}, [fieldListData, fieldListStatus]);

	useEffect(() => {
		if (fieldTypeStatus === "success") {
			setFieldTypeOption(fieldTypeData);
		}
	}, [fieldTypeStatus, fieldTypeData]);

	const onMouseOverSystemField = (id) => setSystemFieldHovered(id);
	const onMouseOutSystemField = () => setSystemFieldHovered(null);

	const handleToggleButtonGroup = (_, type) => {
		if (
			fieldListState.isAnyFieldInCreateMode ||
			fieldListState.isAnyFieldInEditMode
		) {
			if (fieldListState.isAnyFieldInCreateMode) {
				errorOnCreate();
			}
			if (fieldListState.isAnyFieldInEditMode) {
				errorOnUpdate();
			}
		} else {
			if (type) {
				setType(type);
				setNewFieldCreated([]);
				setSearchParams({ type: type });
			}
			resetCreateMode();
			resetUpdateMode();
		}
	};

	const OpenAddSystemFieldsMenu = (event) => {
		if (
			fieldListState.isAnyFieldInCreateMode ||
			fieldListState.isAnyFieldInEditMode ||
			!isFieldEnabled ||
			hasFieldError
		) {
			return;
		}

		setAddSystemFieldsElement(event.currentTarget);
		setSystemFieldList(systemFields);
		setSearch("");
	};

	const CloseAddSystemFieldsMenu = () => {
		setAddSystemFieldsElement(null);
	};

	const setSystemFieldChecked = (value, field) => {
		if (isSystemFieldEnableRestricted(field)) {
			return;
		}

		let updatedSystemFieldList = systemFieldList?.map((systemField) => {
			if (systemField.id === field.id) {
				return {
					...systemField,
					config: {
						...systemField.config,
						isHidden: !value,
					},
				};
			}

			return systemField;
		});
		setSystemFieldList(updatedSystemFieldList);

		let filteredVisibilityChangedSystemFields =
			filterVisibilityChangedSystemFields(updatedSystemFieldList);
		setVisibilityChangedSystemFields(filteredVisibilityChangedSystemFields);
	};

	const filterVisibilityChangedSystemFields = (updatedSystemFields) => {
		return systemFields.filter(
			(field, index) =>
				field.config.isHidden !==
				updatedSystemFields[index].config.isHidden
		);
	};

	const onAddSystemFieldsClicked = () => {
		if (!isAddSystemFieldButtonEnabled) {
			return;
		}

		let requestData = getFieldVisibilityRequestData();
		bulkUpdateMutation.mutate(requestData, {
			onSuccess: () => {
				CloseAddSystemFieldsMenu();
				setVisibilityChangedSystemFields([]);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldUpdated,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const getFieldVisibilityRequestData = () => {
		let requestData = visibilityChangedSystemFields.map((data) => {
			return {
				...data,
				config: {
					isHidden: !data.config.isHidden,
				},
			};
		});

		return requestData;
	};

	const [search, setSearch] = useState("");
	const [systemFieldList, setSystemFieldList] = useState(systemFields);

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearch(value);
	};

	const isDefaultFieldEnabled = (systemField) => {
		return !systemField.config.isHidden;
	};

	const clearCreatedField = () => {
		setNewFieldCreated([]);
	};

	const isSystemFieldEnableRestricted = (systemField) => {
		return systemField.config.isDefaultSystemField;
	};

	const getFilteredSystemFields = () => {
		if (search.trim()) {
			return systemFieldList?.filter((field) =>
				//filter for search
				field.fieldName.toLowerCase().startsWith(search.toLowerCase())
			);
		}
		return systemFieldList;
	};

	const [topBarStartingPosition, setTopBarStartingPosition] = useState(0);
	const [topBarEndingPosition, setTopBarEndingPosition] = useState(0);
	const topBarRef = useRef();
	const toggleRef = useRef();

	useEffect(() => {
		if (topBarRef) {
			setTopBarStartingPosition(
				topBarRef.current.getBoundingClientRect().top
			);
		}
		if (toggleRef) {
			setTopBarEndingPosition(
				toggleRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	return (
		<React.Fragment>
			<Box className={classes.menuBar}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack
						height="100%"
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Box
							pl={1}
							style={{ cursor: "pointer" }}
							onClick={() => navigate("/settings")}
						>
							<Typography
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Admin Settings
							</Typography>
						</Box>

						{DropDownRight(16, 16)}

						<Typography fontWeight={500}>Data Fields</Typography>
					</Stack>

					<Box>
						<RenameModuleDialogBox />
					</Box>
				</Stack>
			</Box>

			<Divider />

			<Box
				sx={{
					height: `calc(100vh - ${topBarStartingPosition}px)`,
					overflowY: "hidden",
				}}
			>
				<Box
					ref={topBarRef}
					sx={{
						position: "sticky",
						backgroundColor: "#fff",
						zIndex: 1,
					}}
				>
					<Menu
						minWidth="260px"
						anchorEl={addSystemFieldsElement}
						open={isSystemFieldsMenuOpened}
						onClose={CloseAddSystemFieldsMenu}
						style={{
							marginTop: "8px",
						}}
						sx={{
							"& .MuiMenu-list": {
								paddingTop: 0,
							},
						}}
					>
						<Box p={0.5}>
							<MenuSearchBar
								value={search}
								onChange={handleSearch}
							/>
						</Box>

						<Box sx={{ maxHeight: "320px", overflowY: "auto" }}>
							{getFilteredSystemFields()?.length > 0 ? (
								getFilteredSystemFields().map((systemField) => (
									<Box
										pr={2}
										key={systemField.id}
										style={{
											backgroundColor:
												systemFieldHovered ===
												systemField.id
													? "#F4F5F5"
													: "#fff",
										}}
										onMouseOver={() =>
											onMouseOverSystemField(
												systemField.id
											)
										}
										onMouseOut={() =>
											onMouseOutSystemField()
										}
									>
										<Stack
											direction="row"
											alignItems="center"
											style={{
												minHeight: "40px",
											}}
										>
											<Stack
												pl={1}
												direction="row"
												spacing={1}
												alignItems="center"
											>
												<Checkbox
													checked={isDefaultFieldEnabled(
														systemField
													)}
													onChange={(_, value) =>
														setSystemFieldChecked(
															value,
															systemField
														)
													}
													disableRipple
													sx={{
														opacity:
															isSystemFieldEnableRestricted(
																systemField
															)
																? 0.5
																: 1,
														cursor: isSystemFieldEnableRestricted(
															systemField
														)
															? "not-allowed"
															: "pointer",
													}}
												/>
												<Box>
													<Typography fontSize={13}>
														{systemField.fieldName}
													</Typography>
												</Box>
											</Stack>
										</Stack>
									</Box>
								))
							) : (
								<Stack
									p={2}
									justifyContent="center"
									alignItems="center"
								>
									<Typography fontSize={13}>
										No Results
									</Typography>
								</Stack>
							)}
						</Box>

						<Box pt={1}>
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
							>
								<Button
									variant="contained"
									disableElevation
									disabled={bulkUpdateMutation.isLoading}
									style={{
										opacity: isAddSystemFieldButtonEnabled
											? 1
											: 0.6,
									}}
									onClick={onAddSystemFieldsClicked}
								>
									Add Selected Fields
								</Button>
							</Stack>
						</Box>
					</Menu>
				</Box>

				<Stack spacing={2}>
					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						px={2}
						pt={2}
					>
						<ToggleButtonGroup
							value={type}
							onChange={handleToggleButtonGroup}
						>
							{modulesList?.map((module) => (
								<ToggleButton
									key={module?.sourceId}
									value={module?.sourceName}
								>
									{getModuleName(module?.sourceName)}
								</ToggleButton>
							))}
						</ToggleButtonGroup>

						<Box>
							<Button
								variant="contained"
								color="secondary"
								startIcon={AddIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								onClick={OpenAddSystemFieldsMenu}
								disableElevation
							>
								System Fields
							</Button>
						</Box>

						<Box>
							<Button
								variant="contained"
								color="secondary"
								startIcon={AddIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								disableElevation
								onClick={handleFieldValidation}
							>
								Custom Field
							</Button>
						</Box>
					</Stack>

					<Box
						sx={{
							height: `calc(100vh - ${topBarEndingPosition}px)`,
							overflowY: "auto",
							px: 2,
						}}
					>
						<Box
							ref={toggleRef}
							sx={{
								position: "sticky",
								backgroundColor: "#fff",
								zIndex: 1,
							}}
						>
							{(function () {
								switch (type) {
									case modules.CONTACT:
										return (
											<Contacts
												clearCreatedField={
													clearCreatedField
												}
												moduleName={type}
												newFieldCreated={
													newFieldCreated
												}
												fieldTypeOption={
													fieldTypeOption
												}
											/>
										);
									case modules.COMPANY:
										return (
											<Companies
												clearCreatedField={
													clearCreatedField
												}
												moduleName={type}
												newFieldCreated={
													newFieldCreated
												}
												fieldTypeOption={
													fieldTypeOption
												}
											/>
										);
									case modules.DEAL:
										return (
											<Deals
												clearCreatedField={
													clearCreatedField
												}
												moduleName={type}
												newFieldCreated={
													newFieldCreated
												}
												fieldTypeOption={
													fieldTypeOption
												}
											/>
										);
									case modules.PRODUCT:
										return (
											<Products
												clearCreatedField={
													clearCreatedField
												}
												moduleName={type}
												newFieldCreated={
													newFieldCreated
												}
												fieldTypeOption={
													fieldTypeOption
												}
											/>
										);
									default:
										return null;
								}
							})()}
						</Box>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
