import { createContext, useContext } from "react";
import { useFilterSaveViewReducer } from "../../Reducers/FilterSaveViewReducer";

const FilterSaveViewContext = createContext(null);

const useFilterSaveViewContext = () => {
	return useContext(FilterSaveViewContext);
};

const FilterSaveViewProvider = (props) => {
	const { children } = props;

	const {
		saveViewState,
		showSaveViewAsButton,
		hideSaveViewAsButton,
		handleOpenSaveViewAsDialog,
		handleCloseSaveViewAsDialog,
	} = useFilterSaveViewReducer();

	return (
		<FilterSaveViewContext.Provider
			value={{
				saveViewState,
				showSaveViewAsButton,
				hideSaveViewAsButton,
				handleOpenSaveViewAsDialog,
				handleCloseSaveViewAsDialog,
			}}
		>
			{children}
		</FilterSaveViewContext.Provider>
	);
};

export { useFilterSaveViewContext, FilterSaveViewProvider };
