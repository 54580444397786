import React from "react";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { default as AddIcon } from "../../../assets/icons/add";

export default function NavigationBar(props) {
	const theme = useTheme();
	const { menus } = props;
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<React.Fragment>
			<Box
				sx={{
					minHeight: "100%",
					px: 1,
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					pt={2}
					pl={2}
				>
					<Typography fontWeight={500}>Analytics</Typography>

					<IconButton>
						{AddIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				</Stack>

				<Box>
					{menus.map((menu, index) => (
						<React.Fragment key={index}>
							{!!menu.name && (
								<Box pt={2} pl={2}>
									<Typography fontSize={13} fontWeight={500}>
										{menu.name}
									</Typography>
								</Box>
							)}

							<List>
								{menu.menu.map((subMenu, index) => (
									<ListItem key={index} disablePadding>
										<ListItemButton
											style={{
												height: "40px",
												backgroundColor:
													location.pathname.startsWith(
														`/reports/${subMenu.path}`
													)
														? "#E7F7F0"
														: "transparent",
												borderRadius: "8px",
											}}
											onClick={() =>
												navigate(subMenu.path)
											}
										>
											<Typography fontSize={13}>
												{subMenu.name}
											</Typography>
										</ListItemButton>
									</ListItem>
								))}
							</List>

							<Box>
								<Divider />
							</Box>
						</React.Fragment>
					))}
				</Box>
			</Box>
		</React.Fragment>
	);
}
