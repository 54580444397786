import React, { useEffect, useState } from "react";
import ToggleButtonGroup from "../../../Elements/ToggleButtonGroup/index";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../../assets/icons/add";
import { TextButton, hexToRgba } from "../../../../styles/twozo";
import { getAmount } from "../../../../utils/amountUtils";
import { useProductList } from "../../../../hooks/services/product";
import ProductTableRow from "../../../Products/AddProductTable/ProductTableRow";
import AddNewProduct from "./AddNewProduct";

export default function AddDealProduct(props) {
	const {
		relatedFieldId,
		handleChangeDealValueWithProduct,
		productField,
		productWithDealValue,
		currencyValue,
	} = props;
	const theme = useTheme();

	const styles = {
		tableContainer: {
			backgroundColor: hexToRgba(theme.palette.primary.main, 0.12),
			borderRadius: "8px",
			p: 1,
		},
		tableHead: {
			fontSize: 13,
			fontWeight: 500,
			color: "#000",
			opacity: "0.6",
		},
		tableCell: {
			fontSize: 14,
		},
		editableTableCell: {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
	};

	const taxTypes = {
		inclusive: "tax_inclusive",
		exclusive: "no_tax",
	};

	const getObjectForNewProduct = () => {
		return {
			product: "",
			price: "",
			quantity: "",
			tax: "",
			discount: "",
		};
	};

	const initializeTaxType = () => {
		if (productWithDealValue?.[productField?.id]?.taxInclusive) {
			return taxTypes.inclusive;
		}

		return taxTypes.exclusive;
	};

	const initializeProductsData = () => {
		if (productWithDealValue?.[productField?.id]?.items) {
			return productWithDealValue?.[productField?.id]?.items;
		}

		return [getObjectForNewProduct()];
	};

	const [taxType, setTaxType] = useState(initializeTaxType());
	const [selectedTableCellId, setSelectedTableCellId] = useState("");
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [totalTaxAmount, setTotalTaxAmount] = useState(0);
	const [productsData, setProductsData] = useState(initializeProductsData());
	const [totalProductAmount, setTotalProductAmount] = useState(0);
	const [isAddNewProductDialogOpened, setIsAddNewProductDialogOpened] =
		useState(false);
	const [newProductDetails, setNewProductDetails] = useState({});

	const { data: productList } = useProductList();

	const isRemoveActionEnabled = productsData[0]?.product;
	const isTaxInclusive = taxType === taxTypes.inclusive;

	const onProductMenuInputChange = (product, index) => {
		const modifiedProductPriceList = productsData.map(
			(priceData, priceDataIndex) => {
				if (index === priceDataIndex) {
					return { ...priceData, product: product };
				} else {
					return priceData;
				}
			}
		);
		setProductsData(modifiedProductPriceList);

		const modifiedSelectedProduct = [
			...selectedProducts.slice(0, index),
			product,
			...selectedProducts.slice(index),
		];
		const filteredDuplicateSelectedProduct = [
			...new Set(modifiedSelectedProduct),
		];
		setSelectedProducts(filteredDuplicateSelectedProduct);
	};

	const getCalculatedProductAmount = (productsData = []) => {
		let taxTotal = 0;
		let totalProductAmount = 0;
		let selectedProducts = [];
		let product = null;
		for (let index = 0; index < productsData.length; index++) {
			let price = productsData[index].price;
			let discount = productsData[index].discount;
			let quantity = productsData[index].quantity;
			let tax = productsData[index].tax ? productsData[index].tax : 0;
			let amount = (price - price * (discount / 100)) * quantity;
			taxTotal += (amount * tax) / 100;
			product = productsData[index].product;
			selectedProducts.push(product);
			totalProductAmount += amount;
		}

		return { taxTotal, totalProductAmount, selectedProducts };
	};

	useEffect(() => {
		let calculatedProductListData =
			getCalculatedProductAmount(productsData);
		setTotalTaxAmount(calculatedProductListData.taxTotal);
		setTotalProductAmount(calculatedProductListData.totalProductAmount);
		setSelectedProducts(calculatedProductListData.selectedProducts);
	}, [productsData]);

	const isAddingMode = () => {
		return productsData?.some((productData) => !productData?.product);
	};

	const handleAddProduct = () => {
		if (!isAddingMode()) {
			let newProductInitialData = getObjectForNewProduct();
			setProductsData([...productsData, newProductInitialData]);

			setSelectedTableCellId(`item_${productsData.length}`);
		}
	};

	const handleRemoveProduct = (index) => {
		if (productsData.length === 1) {
			setProductsData([getObjectForNewProduct()]);
			onChangeDealValueWithProduct([getObjectForNewProduct()]);
			setSelectedProducts([]);
		} else {
			let updatedProductsData = productsData.filter(
				(_, productIndex) => productIndex !== index
			);
			setProductsData(updatedProductsData);
			onChangeDealValueWithProduct(updatedProductsData);
			setSelectedProducts((selectedProducts) =>
				selectedProducts.filter(
					(_, productIndex) => productIndex !== index
				)
			);
		}
	};

	const onChangeDealValueWithProduct = (modifiedProductPriceList) => {
		let product = {
			taxInclusive: isTaxInclusive,
			items: modifiedProductPriceList,
		};
		let calculatedProductListData = getCalculatedProductAmount(
			modifiedProductPriceList
		);
		let totalProductAmount = getTotalProductAmountWithOrWithoutTax(
			calculatedProductListData.totalProductAmount,
			calculatedProductListData.taxTotal
		);
		handleChangeDealValueWithProduct(
			product,
			totalProductAmount,
			calculatedProductListData.selectedProducts
		);
	};

	const onProductDetailsChange = (event, index) => {
		const { value, name } = event.target;

		const modifiedProductPriceList = productsData.map(
			(productPriceData, productPriceIndex) => {
				if (index === productPriceIndex) {
					return { ...productPriceData, [name]: value };
				}

				return productPriceData;
			}
		);
		setProductsData(modifiedProductPriceList);

		onChangeDealValueWithProduct(modifiedProductPriceList);
	};

	const handleProductData = (updatedProductData, index) => {
		let updatedProductsData = productsData.map(
			(productData, productIndex) => {
				if (productIndex === index) {
					return updatedProductData;
				}
				return productData;
			}
		);
		setProductsData(updatedProductsData);

		onChangeDealValueWithProduct(updatedProductsData);
	};

	const handleProductTableCell = (event, index) => {
		setSelectedTableCellId(`${event.currentTarget.id}_${index}`);
	};

	const isTableCellSelected = (id, index) => {
		return selectedTableCellId === `${id}_${index}`;
	};

	const handleTax = (_, taxType) => {
		if (taxType) {
			setTaxType(taxType);
			const modifiedProductPriceList = [...productsData];
			let product = {
				taxInclusive: isTaxInclusive,
				items: modifiedProductPriceList,
			};

			let totalProductAmount = getTotalProductAmount();
			handleChangeDealValueWithProduct(
				product,
				totalProductAmount,
				selectedProducts
			);
		}
	};

	const getTotalProductAmount = () => {
		if (isTaxInclusive) {
			return getTotalProductAmountWithOrWithoutTax(
				totalProductAmount,
				totalTaxAmount
			);
		}

		return getTotalProductAmountWithOrWithoutTax(totalProductAmount);
	};

	const getTotalProductAmountWithOrWithoutTax = (
		totalProductAmount = 0,
		totalTaxAmount = 0
	) => {
		if (isTaxInclusive) {
			return getAmount(totalProductAmount + totalTaxAmount);
		}

		return getAmount(totalProductAmount);
	};

	const openAddNewProductDialog = () => {
		setIsAddNewProductDialogOpened(true);
	};

	const closeAddNewProductDialog = () => {
		setIsAddNewProductDialogOpened(false);
	};

	const onSelectNewProduct = (newProductName, index) => {
		setNewProductDetails({
			formData: { [relatedFieldId]: newProductName },
			productIndex: index,
		});
		openAddNewProductDialog();
	};

	const handleSubmit = (formData) => {
		let modifiedProductsData = productsData?.map((product, index) => {
			if (index === newProductDetails?.productIndex) {
				return {
					...product,
					product: formData,
					price: product?.price || 0,
					quantity: product?.quantity || 1,
					tax: product?.tax || 0,
					discount: product?.discount || 0,
				};
			}
			return product;
		});
		setProductsData(modifiedProductsData);
		onChangeDealValueWithProduct(modifiedProductsData);
		closeAddNewProductDialog();
	};

	const getProductName = (product) => {
		if (typeof product === "object") {
			return product?.[relatedFieldId] ? product[relatedFieldId] : "";
		}
		let productName = productList?.find(
			(productData) => productData.value === product
		)?.name;

		return productName ? productName : "";
	};

	return (
		<React.Fragment>
			{/* Add New Product Dialog */}
			<AddNewProduct
				open={isAddNewProductDialogOpened}
				onClose={closeAddNewProductDialog}
				onSave={handleSubmit}
				productFormData={newProductDetails?.formData}
			/>

			<Stack spacing={2}>
				<Box>
					<ToggleButtonGroup value={taxType} onChange={handleTax}>
						<ToggleButton value={taxTypes.inclusive}>
							Tax Inclusive
						</ToggleButton>

						<ToggleButton value={taxTypes.exclusive}>
							No tax
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
				<Box sx={styles.tableContainer}>
					<TableContainer>
						<Table sx={{ width: "100%" }} size="small">
							<TableHead>
								<TableRow>
									<TableCell
										style={{
											width: "30%",
										}}
									>
										<Typography sx={styles.tableHead}>
											Item
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "16%" }}
									>
										<Typography sx={styles.tableHead}>
											Price
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{
											width:
												taxType === taxTypes.exclusive
													? "10%"
													: "11%",
										}}
									>
										<Typography sx={styles.tableHead}>
											Qty
										</Typography>
									</TableCell>

									{taxType === taxTypes.inclusive && (
										<TableCell
											align="right"
											style={{ width: "11%" }}
										>
											<Typography
												sx={styles.tableHead}
												noWrap
											>
												Tax %
											</Typography>
										</TableCell>
									)}

									<TableCell
										align="right"
										style={{
											width:
												taxType === taxTypes.exclusive
													? "12%"
													: "11%",
										}}
									>
										<Typography
											sx={styles.tableHead}
											noWrap
										>
											Discount %
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "16%" }}
									>
										<Typography sx={styles.tableHead}>
											Amount
										</Typography>
									</TableCell>

									<TableCell
										style={{
											width: "5%",
										}}
									></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{productsData?.map((productData, index) => (
									<ProductTableRow
										key={index}
										productData={productData}
										productList={productList}
										currencyValue={currencyValue}
										isTaxInclusive={isTaxInclusive}
										productName={getProductName(
											productData?.product
										)}
										onProductDetailsChange={(event) =>
											onProductDetailsChange(event, index)
										}
										onProductMenuInputChange={(value) =>
											onProductMenuInputChange(
												value,
												index
											)
										}
										isRemoveActionEnabled={
											isRemoveActionEnabled
										}
										handleRemoveProduct={() =>
											handleRemoveProduct(index)
										}
										handleProductData={(productDetails) =>
											handleProductData(
												productDetails,
												index
											)
										}
										selectedProductIds={selectedProducts}
										handleProductTableCell={(event) =>
											handleProductTableCell(event, index)
										}
										isTableCellSelected={(id) =>
											isTableCellSelected(id, index)
										}
										onSelectNewProduct={(newProductName) =>
											onSelectNewProduct(
												newProductName,
												index
											)
										}
									/>
								))}

								<TableRow style={{ height: "auto" }}>
									<TableCell style={{ borderBottom: "none" }}>
										<TextButton
											sx={{
												opacity: isAddingMode()
													? "0.6"
													: "1",
												width: "100px",
											}}
											size="small"
											startIcon={AddIcon(
												13,
												13,
												theme.palette.secondary
													.contrastText
											)}
											onClick={handleAddProduct}
										>
											Add More
										</TextButton>
									</TableCell>
								</TableRow>

								<TableRow style={{ height: "auto" }}>
									<TableCell
										rowSpan={4}
										style={{ borderBottom: "none" }}
									/>
									<TableCell
										rowSpan={4}
										style={{ borderBottom: "none" }}
									/>
									<TableCell
										colSpan={
											taxType === taxTypes.exclusive
												? 2
												: 3
										}
										style={{
											borderBottom:
												taxType === taxTypes.exclusive
													? null
													: "none",
										}}
									>
										<Typography sx={styles.tableCell}>
											Subtotal
										</Typography>
									</TableCell>
									<TableCell
										align="right"
										style={{
											borderBottom:
												taxType === taxTypes.exclusive
													? null
													: "none",
										}}
									>
										<Typography
											sx={styles.tableCell}
											noWrap
										>
											{currencyValue?.symbol}
											{getTotalProductAmountWithOrWithoutTax(
												totalProductAmount
											)}
										</Typography>
									</TableCell>
								</TableRow>

								{taxType === taxTypes.inclusive && (
									<TableRow style={{ height: "auto" }}>
										<TableCell
											colSpan={
												taxType === taxTypes.exclusive
													? 2
													: 3
											}
										>
											<Typography sx={styles.tableCell}>
												Tax
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography
												sx={styles.tableCell}
												noWrap
											>
												{currencyValue?.symbol}
												{getAmount(totalTaxAmount)}
											</Typography>
										</TableCell>
									</TableRow>
								)}

								<TableRow>
									<TableCell
										colSpan={
											taxType === taxTypes.exclusive
												? 2
												: 3
										}
										style={{ borderBottom: "none" }}
									>
										<Typography sx={styles.tableCell}>
											Total
										</Typography>
									</TableCell>
									<TableCell
										align="right"
										style={{ borderBottom: "none" }}
									>
										<Typography
											sx={styles.tableCell}
											noWrap
										>
											{currencyValue?.symbol}
											{getTotalProductAmountWithOrWithoutTax(
												totalProductAmount,
												totalTaxAmount
											)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
