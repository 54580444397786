import { useReducer } from "react";

export const selectActionTypes = {
	create: "CREATE",
	errorOnCreate: "ERROR_ON_CREATE",
	errorOnDuplicate: "ERROR_ON_DUPLICATE",
	errorOnCharactersLimitExceeded: "ERROR_ON_CHARACTERS_LIMIT_EXCEEDED",
	reset: "RESET",
};

const emptyOptionErrorMessage = "Can’t be empty";
const duplicateOptionErrorMessage = "This choice already exists";
const characterLimitExceededErrorMessage = "Max. of 125 characters are allowed";

export const selectReducer = (state, action) => {
	switch (action.type) {
		case selectActionTypes.create:
			return {
				...state,
				isCreateMode: true,
			};
		case selectActionTypes.errorOnCreate:
			return {
				...state,
				isCreateMode: false,
				hasCreationError: true,
				errorMessage: emptyOptionErrorMessage,
			};
		case selectActionTypes.errorOnDuplicate:
			return {
				...state,
				isCreateMode: false,
				hasDuplicateError: true,
				errorMessage: duplicateOptionErrorMessage,
			};
		case selectActionTypes.errorOnCharactersLimitExceeded:
			return {
				...state,
				isCreateMode: false,
				isLimitExceeded: true,
				errorMessage: characterLimitExceededErrorMessage,
			};
		case selectActionTypes.reset:
			return {
				...state,
				isCreateMode: false,
				isLimitExceeded: false,
				hasDuplicateError: false,
				hasCreationError: false,
				errorMessage: "",
			};

		default:
			return state;
	}
};

const initialDropdownState = {
	isCreateMode: false,
	hasDuplicateError: false,
	hasCreationError: false,
	isLimitExceeded: false,
	errorMessage: "",
};

export const useSelectReducer = () => {
	const [selectState, selectStateDispatcher] = useReducer(
		selectReducer,
		initialDropdownState
	);

	const onCreateSelectChoices = () => {
		selectStateDispatcher({ type: selectActionTypes.create });
	};

	const onResetSelectChoices = () => {
		selectStateDispatcher({ type: selectActionTypes.reset });
	};

	const onChoiceCreationError = () => {
		selectStateDispatcher({ type: selectActionTypes.errorOnCreate });
	};

	const onDuplicateChoiceError = () => {
		selectStateDispatcher({ type: selectActionTypes.errorOnDuplicate });
	};

	const errorOnCharactersLimitExceeded = () => {
		selectStateDispatcher({
			type: selectActionTypes.errorOnCharactersLimitExceeded,
		});
	};

	return {
		selectState,
		onCreateSelectChoices,
		onResetSelectChoices,
		onChoiceCreationError,
		onDuplicateChoiceError,
		errorOnCharactersLimitExceeded,
	};
};
