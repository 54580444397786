import React from "react";
import Table from "../../Elements/Table";
import { sourceType } from "../../../utils/queryKeys";
import ActivityMenu from "../Menu";
import SelectedMenu from "../SelectedMenu";
import ActivityFilters from "../ActivityFilters";
import { activityView } from "../../../utils/ActivitiesUtils";
import { modules } from "../../../utils/common/ModulesName";
import { FilterSaveViewProvider } from "../../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../../Elements/Table/Filter/Context/FilterDataContext";

export default function ListView(props) {
	const { handleViewToggleButtonGroup, openActivitySummaryDialog } = props;
	const tableName = sourceType.ACTIVITY;

	const noResultsMessage = {
		title: "No Activities Found",
		message: "Try resetting your filters or create new activity",
	};

	const openActivitySummary = (row) => {
		openActivitySummaryDialog(row.id);
	};

	return (
		<React.Fragment>
			<FilterDataProvider>
				<FilterSaveViewProvider>
					<Table
						table={tableName}
						moduleName={modules.ACTIVITY}
						viewName="All Activities"
						menu={
							<ActivityMenu
								view={activityView.list}
								handleViewToggleButtonGroup={
									handleViewToggleButtonGroup
								}
							/>
						}
						selectedMenu={<SelectedMenu />}
						enhancedMenu={<ActivityFilters />}
						onRowClick={openActivitySummary}
						isActionsVisible={false}
						noResultsMessage={noResultsMessage}
						hasShareViewPermissions={true}
					/>
				</FilterSaveViewProvider>
			</FilterDataProvider>
		</React.Fragment>
	);
}
