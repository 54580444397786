import React, { useState } from "react";
import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signUp } from "../../../../api/auth/authApi";
import { default as twozoLogo } from "../../../../assets/images/logo/twozo-full.png";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";

export default function CreateCompany(props) {
	const { userDetails, resetUserDetails } = props;
	const navigate = useNavigate();
	const theme = useTheme();
	const [company, setCompany] = useState("");
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm();

	const onSubmit = (data) => {
		let signUpData = {
			user: userDetails,
			organization: data,
		};

		signUpMutation.mutate(signUpData);
	};

	const signUpMutation = useMutation(
		async (signUpData) => signUp(signUpData),
		{
			onSuccess: () => {
				localStorage.setItem("isFirstSignIn", true);
				navigate("/deals");
				resetUserDetails();
			},
			onError: (error) => {
				let errorMessage = error?.response?.data?.data?.message;
				enqueueSnackbar({
					title: "Failed!",
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: "Error While Sign Up",
				});
			},
		}
	);

	return (
		<React.Fragment>
			<Stack spacing={4} p={5} pb={3}>
				<Box>
					<img
						alt="twozo"
						src={twozoLogo}
						width="108px"
						height="34px"
					/>
				</Box>

				<Stack spacing={3}>
					<Stack spacing={0.5}>
						<Typography fontSize={18} fontWeight={600}>
							Hello Hendry, Nice to meet you!
						</Typography>

						<Typography fontSize={13}>
							We’d like to know more about your company to tailor
							the experience.
						</Typography>
					</Stack>

					<Stack spacing={2}>
						<Stack spacing={0.5}>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={
									!!errors.name && theme.palette.error.main
								}
							>
								Your Company Name
							</Typography>

							<TextField
								placeholder="Enter your company name"
								{...register("name", {
									required: "Name is required",
								})}
								onChange={() => clearErrors("name")} // clear name validation error
								FormHelperTextProps={{
									sx: {
										marginLeft: 0,
										fontSize: 13,
									},
								}}
								error={!!errors.name}
								helperText={errors.name?.message}
							/>
						</Stack>

						<Stack spacing={0.5}>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={
									errors.jobRole && theme.palette.error.main
								}
							>
								Your job role
							</Typography>

							<Select
								{...register("jobRole", {
									required: "Select your job role",
								})}
								value={company}
								onChange={(event) => {
									setCompany(event.target.value);
									clearErrors("jobRole"); // Clear job role validation error
								}}
								renderValue={
									company !== ""
										? undefined
										: () => (
												<Typography
													fontSize={14}
													color={
														errors.jobRole
															? theme.palette
																	.error.main
															: "rgba(0, 0, 0, 0.4)"
													}
												>
													Select
												</Typography>
											)
								}
								displayEmpty
								error={!!errors.jobRole}
							>
								<MenuItem value="SalesManager">
									<Typography fontSize={14}>
										Sales Manager
									</Typography>
								</MenuItem>
								<MenuItem value="Admin">
									<Typography fontSize={14}>Admin</Typography>
								</MenuItem>
								<MenuItem value="Marketing">
									<Typography fontSize={14}>
										Marketing
									</Typography>
								</MenuItem>
								<MenuItem value="CEO">
									<Typography fontSize={14}>
										CEO/Co-Founder
									</Typography>
								</MenuItem>
								<MenuItem value="Finance">
									<Typography fontSize={14}>
										Finance
									</Typography>
								</MenuItem>
								<MenuItem value="Support">
									<Typography fontSize={14}>
										Support
									</Typography>
								</MenuItem>
							</Select>
							{!!errors.jobRole && (
								<Typography
									style={{
										color: theme.palette.error.main,
										fontSize: 13,
									}}
								>
									{errors.jobRole.message}
								</Typography>
							)}
						</Stack>
					</Stack>

					<Box pb={1}>
						<Button
							variant="contained"
							onClick={handleSubmit(onSubmit)}
							disabled={signUpMutation.isLoading}
							disableElevation
						>
							Complete Signup
						</Button>
					</Box>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
