import {
	Box,
	Checkbox,
	Divider,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as TickCircle } from "../../../../../../assets/icons/tickCircle";
import { default as RoundCheckBoxOffIcon } from "../../../../../../assets/icons/roundCheckBoxOff";
import Menu from "../../../../../Elements/Menu";
import { useMemo, useState } from "react";

export default function FilterBaseComponent({
	styles,
	criteria,
	handleUpdateFilter,
	filterValuesList = [],
	filterName,
}) {
	const theme = useTheme();
	const allFilter = filterValuesList?.[0];

	const [filterMenuElement, setFilterMenuElement] = useState(null);
	const [selectedFilters, setSelectedFilters] = useState(
		criteria || [allFilter?.value]
	);
	const isFilterMenuOpened = Boolean(filterMenuElement);

	const closeFilterMenu = () => setFilterMenuElement(null);
	const openFilterMenu = (event) => setFilterMenuElement(event.currentTarget);

	const handleSelectFilter = (filter) => {
		if (filter.value === allFilter?.value) {
			setSelectedFilters([filter.value]);
			handleUpdateFilter([filter.value]);
		} else {
			setSelectedFilters((prevSelected) => {
				const isSelected = prevSelected.includes(filter.value);
				const updatedSelected = isSelected
					? prevSelected.filter((item) => item !== filter.value)
							?.length > 0
						? prevSelected.filter((item) => item !== filter.value)
						: [allFilter?.value]
					: [
							...prevSelected.filter(
								(item) => item !== allFilter?.value
							),
							filter.value,
						];

				handleUpdateFilter(updatedSelected);
				return updatedSelected;
			});
		}
	};

	const isFilterSelected = (filterValue) => {
		return selectedFilters.includes(filterValue);
	};

	const selectedFilter = useMemo(() => {
		let filteredList = filterValuesList?.filter((filter) =>
			selectedFilters.includes(filter?.value)
		);
		return selectedFilters.includes(allFilter?.value)
			? allFilter?.name
			: filteredList?.length > 1
				? `${filteredList?.length} ${
						filterName === "Created by" ? "Users" : filterName
					} selected`
				: filterValuesList?.filter((filter) =>
						selectedFilters.includes(filter?.value)
					)?.[0]?.name;
	}, [selectedFilters, allFilter, filterValuesList, filterName]);

	return (
		<>
			<Menu
				minWidth="200px"
				anchorEl={filterMenuElement}
				open={isFilterMenuOpened}
				onClose={closeFilterMenu}
				style={{ marginTop: "4px" }}
			>
				<Box display="flex" flexDirection="column">
					<MenuItem
						style={{ height: "40px", padding: 0 }}
						onClick={() => handleSelectFilter(allFilter)}
					>
						<Stack
							direction="row"
							alignItems="center"
							height="40px"
							spacing={1}
							p={1}
						>
							<Checkbox
								icon={RoundCheckBoxOffIcon(19, 19)}
								checkedIcon={TickCircle(
									19,
									19,
									theme.palette.primary
								)}
								checked={isFilterSelected(allFilter?.value)}
							/>

							<Typography fontSize={13} fontWeight={400}>
								{allFilter?.name}
							</Typography>
						</Stack>
					</MenuItem>

					<Divider style={{ margin: 0 }} />

					<Box
						flex="1 1 auto"
						minHeight={0}
						maxHeight="200px"
						overflow="auto"
					>
						{filterValuesList?.slice(1)?.map((filter) => (
							<MenuItem
								key={filter.value}
								style={{ height: "40px", padding: 0 }}
								onClick={() => handleSelectFilter(filter)}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									p={1}
								>
									<Checkbox
										checked={isFilterSelected(filter.value)}
									/>

									<Typography fontSize={13} fontWeight={400}>
										{filter.name}
									</Typography>
								</Stack>
							</MenuItem>
						))}
					</Box>
				</Box>
			</Menu>

			<Stack style={styles.boxStyle} onClick={openFilterMenu}>
				<Typography style={styles.headerStyle}>{filterName}</Typography>

				<Typography style={styles.contentStyle}>
					{selectedFilter}
				</Typography>
			</Stack>
		</>
	);
}
