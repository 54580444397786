import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getEmailSyncSettingsKey,
	getEmailSyncStatusKey,
} from "../../../utils/queryKeys/emailSync";
import {
	disconnectEmail,
	getEmailSyncSettingsData,
	getEmailSyncStatus,
	syncEmailAccount,
} from "../../../api/emailSync";

const useEmailSyncSettingsData = () => {
	let emailSyncSettingsKey = getEmailSyncSettingsKey();
	return useQuery(emailSyncSettingsKey, () => getEmailSyncSettingsData(), {
		select: (data) => data?.settings,
	});
};

const useEmailSyncStatus = (id, isSyncStatusEnabled = false) => {
	let emailSyncStatusKey = getEmailSyncStatusKey(id);
	return useQuery(emailSyncStatusKey, () => getEmailSyncStatus(id), {
		enabled: isSyncStatusEnabled,
		select: (syncState) => syncState?.state,
		refetchInterval: () => syncStatusRefetchInterval(),
	});
};

// At regular intervals (every 1000 milliseconds) while the sync state is "syncing".
const syncStatusRefetchInterval = () => {
	return 1000;
};

const useStopEmailSync = () => {
	let invalidateEmailSyncSettingsData = useInvalidateEmailSyncSettingsData();
	return useMutation(disconnectEmail, {
		onSuccess: () => {
			invalidateEmailSyncSettingsData();
		},
	});
};

const useEmailSyncMutation = () => {
	let invalidateEmailSyncSettingsData = useInvalidateEmailSyncSettingsData();
	return useMutation(syncEmailAccount, {
		onError: () => {
			invalidateEmailSyncSettingsData();
		},
	});
};

const useInvalidateEmailSyncSettingsData = () => {
	let queryClient = useQueryClient();
	let emailSyncSettingsKey = getEmailSyncSettingsKey();
	return () => {
		queryClient.invalidateQueries(emailSyncSettingsKey);
	};
};

export {
	useEmailSyncSettingsData,
	useEmailSyncStatus,
	useInvalidateEmailSyncSettingsData,
	useStopEmailSync,
	useEmailSyncMutation,
};
