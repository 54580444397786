import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import Menu from "../../../Elements/Menu";
import { useDealContext } from "../../DealContext";
import { useDateFieldList } from "../../../../hooks/services/deal/forecast";

export default function ForecastViewFilter() {
	const theme = useTheme();

	const {
		updateDealColumn,
		dealColumn,
		updateDealInterval,
		interval,
		updateDealState,
		dealState,
		updateDealField,
		dealField,
	} = useDealContext();

	const { data: dealDateFieldList } = useDateFieldList();
	const [fieldMenuElement, setFieldMenuElement] = useState(null);
	const isFieldMenuIsOpened = Boolean(fieldMenuElement);

	const dealStateList = getDropdownDataByName("DEAL_STATE_LIST");
	const [dealStateMenuElement, setDealStateMenuElement] = useState(null);
	const isDealStateMenuIsOpened = Boolean(dealStateMenuElement);

	const intervalList = getDropdownDataByName("INTERVAL_LIST");
	const [intervalMenuElement, setIntervalMenuElement] = useState(null);
	const isIntervalMenuopened = Boolean(intervalMenuElement);

	const dealStageColumns = getDropdownDataByName("COLUMNS");
	const [columnMenuElement, setColumnMenuElement] = useState(null);
	const isColumnMenuOpened = Boolean(columnMenuElement);

	const openDealStateMenu = (event) => {
		setDealStateMenuElement(event.currentTarget);
	};

	const closeDealStateMenu = () => {
		setDealStateMenuElement(null);
	};

	const handleDealState = (dealStateItem) => {
		updateDealState(dealStateItem);
		setDealStateMenuElement(null);
	};

	const openIntervalMenu = (event) => {
		setIntervalMenuElement(event.currentTarget);
	};

	const closeIntervalMenu = () => {
		setIntervalMenuElement(null);
	};

	const handleInterval = (interval) => {
		updateDealInterval(interval);
		setIntervalMenuElement(null);
	};

	const openColumnMenu = (event) => {
		setColumnMenuElement(event.currentTarget);
	};

	const closeColumnMenu = () => {
		setColumnMenuElement(null);
	};

	const handleColumn = (column) => {
		updateDealColumn(column);
		setColumnMenuElement(null);
	};

	const openFieldMenu = (event) => {
		setFieldMenuElement(event.currentTarget);
	};

	const closeFieldMenu = () => {
		setFieldMenuElement(null);
	};

	const handleDealField = (field) => {
		updateDealField(field);
		setFieldMenuElement(null);
	};

	const styles = {
		boxStyle: {
			padding: "8px 16px",
			borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
		},
		headerStyle: {
			fontSize: 13,
		},
		contentStyle: {
			fontSize: 13,
			fontWeight: 500,
			color: theme.palette.primary.main,
			cursor: "pointer",
		},
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="200px"
				anchorEl={fieldMenuElement}
				open={isFieldMenuIsOpened}
				onClose={closeFieldMenu}
				style={{ marginTop: "4px" }}
			>
				{dealDateFieldList?.map((dealFieldItem) => (
					<MenuItem
						key={dealFieldItem.id}
						style={{
							height: "40px",
							backgroundColor:
								dealFieldItem.name === dealField?.name
									? theme.palette.secondary.main
									: "#fff",
						}}
						onClick={() => handleDealField(dealFieldItem)}
					>
						<Typography style={styles.headerStyle}>
							{dealFieldItem.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={dealStateMenuElement}
				open={isDealStateMenuIsOpened}
				onClose={closeDealStateMenu}
				style={{ marginTop: "4px" }}
			>
				{dealStateList.map((dealStateItem) => (
					<MenuItem
						key={dealStateItem.id}
						style={{
							height: "40px",
							backgroundColor:
								dealStateItem.name === dealState.name
									? theme.palette.secondary.main
									: "#fff",
						}}
						onClick={() => handleDealState(dealStateItem)}
					>
						<Typography style={styles.headerStyle}>
							{dealStateItem.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={intervalMenuElement}
				open={isIntervalMenuopened}
				onClose={closeIntervalMenu}
				style={{ marginTop: "4px" }}
			>
				{intervalList.map((intervalItem) => (
					<MenuItem
						key={intervalItem.id}
						style={{
							height: "40px",
							backgroundColor:
								intervalItem.name === interval.name
									? theme.palette.secondary.main
									: "#fff",
						}}
						onClick={() => handleInterval(intervalItem)}
					>
						<Typography style={styles.headerStyle}>
							{intervalItem.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={columnMenuElement}
				open={isColumnMenuOpened}
				onClose={closeColumnMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{dealStageColumns.map((column) => (
					<MenuItem
						key={column.id}
						style={{
							height: "40px",
							backgroundColor:
								dealColumn.name === column.name
									? theme.palette.secondary.main
									: "#fff",
						}}
						onClick={() => handleColumn(column)}
					>
						<Typography style={styles.headerStyle}>
							{column.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Box sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
				<Stack style={styles.boxStyle}>
					<Typography style={styles.headerStyle}>Show By</Typography>
					<Typography
						style={styles.contentStyle}
						onClick={openFieldMenu}
					>
						{dealField?.name}
					</Typography>
				</Stack>
				<Stack style={styles.boxStyle}>
					<Typography style={styles.headerStyle}>
						Arrange By
					</Typography>
					<Typography
						style={styles.contentStyle}
						onClick={openDealStateMenu}
					>
						{dealState.name}
					</Typography>
				</Stack>
				<Stack style={styles.boxStyle}>
					<Typography style={styles.headerStyle}>
						Change Interval
					</Typography>
					<Typography
						style={styles.contentStyle}
						onClick={openIntervalMenu}
					>
						{interval.name}
					</Typography>
				</Stack>
				<Stack style={styles.boxStyle}>
					<Typography style={styles.headerStyle}>
						Number of Columns
					</Typography>
					<Typography
						style={styles.contentStyle}
						onClick={openColumnMenu}
					>
						{dealColumn.name}
					</Typography>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
