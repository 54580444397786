export default function TickCircle(width = 20, height = 20, opacity = 1) {
	return (
		<svg
			width={width}
			height={height}
			opacity={opacity}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.3332 10.0003C18.3332 5.41699 14.5832 1.66699 9.99984 1.66699C5.4165 1.66699 1.6665 5.41699 1.6665 10.0003C1.6665 14.5837 5.4165 18.3337 9.99984 18.3337C14.5832 18.3337 18.3332 14.5837 18.3332 10.0003Z"
				stroke="#33BC7E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.45801 9.99993L8.81634 12.3583L13.5413 7.6416"
				stroke="#33BC7E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
