import React from "react";

function Bold(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M7 3.5H12.12C14.74 3.5 16.87 5.40579 16.87 7.75C16.87 10.0942 14.74 12 12.12 12H5V5.28947C5 4.30526 5.9 3.5 7 3.5Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.5 12C17.12 12 19.25 13.9058 19.25 16.25C19.25 18.5942 17.12 20.5 14.5 20.5H7C5.9 20.5 5 19.6947 5 18.7105V12H14.5Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Bold;
