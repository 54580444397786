import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";

export default function UserSettings(props) {
	const { permissions, isEditable } = props;

	const {
		permissionState,
		updateUserSettingsList,
		initializeUserSettingsListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeUserSettingsListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		const updatedUserSettingsList = permissionState.userSettingsList.map(
			(list) => {
				if (list.id === id) {
					const updatedList = {
						...list,
						access: event.target.checked,
					};

					return updatedList;
				} else {
					return list;
				}
			}
		);
		updateUserSettingsList(updatedUserSettingsList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="User Settings"
				lists={permissionState.userSettingsList}
				handleAccessChange={handleAccessChange}
				isEditable={isEditable}
			/>
		</React.Fragment>
	);
}
