import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as NextIcon } from "../../../../../../assets/icons/leftArrow";
import { useNavigate } from "react-router-dom";
import { useTemplateList } from "../../../../../../hooks/services/webform";

export default function Create(props) {
	const { currentFormData, updateFormData, goNextStep } = props;

	const theme = useTheme();
	const navigate = useNavigate();

	const [hoveredCardId, setHoveredCardId] = useState(null);
	const [templateList, setTemplateList] = useState([]);

	const { status: templateApiStatus, data: templateApiData } =
		useTemplateList();

	useEffect(() => {
		if (templateApiStatus === "success") {
			if (templateApiData.data) {
				setTemplateList(templateApiData.data);
				if (!currentFormData?.id) {
					updateFormData(templateApiData.data[0]);
				}
			}
		}
	}, [templateApiStatus, templateApiData, currentFormData, updateFormData]);

	const onMouseOverTemplateCard = (id) => {
		setHoveredCardId(id);
	};

	const onMouseOutTemplateCard = () => {
		setHoveredCardId(null);
	};

	const handleSelectedCard = (templateData) => {
		updateFormData(templateData);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					height: "100%",
				}}
			>
				<Box
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
						minHeight: "0px",
					}}
				>
					<Stack>
						<Stack alignItems="center" justifyContent="center">
							<Stack pt={5} width="60%">
								<Typography
									pl={1}
									align="left"
									gutterBottom
									fontSize={13}
									fontWeight={500}
								>
									Pick from Templates
								</Typography>
							</Stack>
						</Stack>

						<Stack sx={{ alignItems: "center", cursor: "pointer" }}>
							<Stack spacing={1} width="60%">
								{templateList?.map((template, index) => (
									<Stack key={template.id}>
										<Box
											hidden={
												index !==
												templateList.length - 1
											}
											pl={1}
											pt={2}
											pb={0.4}
										>
											<Typography
												fontSize={13}
												fontWeight={500}
											>
												or Create from
											</Typography>
										</Box>
										<Stack
											key={template.id}
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											sx={{
												borderRadius: "8px",
												border:
													hoveredCardId ===
														template?.id ||
													currentFormData?.id ===
														template?.id
														? `1px solid ${theme.palette.primary.main}`
														: "1px solid rgba(0, 0, 0, 0.1)",
												p: 2,
											}}
											onMouseOver={() =>
												onMouseOverTemplateCard(
													template.id
												)
											}
											onMouseOut={() =>
												onMouseOutTemplateCard()
											}
											onClick={() =>
												handleSelectedCard(template)
											}
										>
											<Stack>
												<Typography
													fontSize={14}
													fontWeight={500}
													color={
														(hoveredCardId ===
															template?.id ||
															currentFormData?.id ===
																template?.id) &&
														theme.palette.secondary
															.contrastText
													}
												>
													{template?.formName}
												</Typography>
												<Typography
													fontSize={13}
													color="rgba(0, 0, 0, 0.6)"
												>
													{template?.description}
												</Typography>
											</Stack>
											{hoveredCardId === template?.id && (
												<IconButton
													sx={{
														transform:
															"rotate(180deg)",
													}}
													onClick={(event) =>
														goNextStep(
															event,
															template
														)
													}
												>
													{NextIcon(
														20,
														20,
														theme.palette.primary
															.main
													)}
												</IconButton>
											)}
										</Stack>
									</Stack>
								))}
							</Stack>
						</Stack>
					</Stack>
				</Box>

				<Divider />
				<Box p={2}>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						onClick={() => navigate("/settings/webform")}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
