import ActivityAssigned from "../components/Elements/AppNotification/NotificationTypes/activityAssigned";
import ActivityReminder from "../components/Elements/AppNotification/NotificationTypes/activityReminder";
import AddedToTeam from "../components/Elements/AppNotification/NotificationTypes/addedToTeam";
import CalendarSynced from "../components/Elements/AppNotification/NotificationTypes/calendarSynced";
import CollaboratorAdded from "../components/Elements/AppNotification/NotificationTypes/collaboratorAdded";
import CompanyAssigned from "../components/Elements/AppNotification/NotificationTypes/companyAssigned";
import ContactAssigned from "../components/Elements/AppNotification/NotificationTypes/contactAssigned";
import ContactSynced from "../components/Elements/AppNotification/NotificationTypes/contactSynced";
import DealAssigned from "../components/Elements/AppNotification/NotificationTypes/dealAssigned";
import EmailClicked from "../components/Elements/AppNotification/NotificationTypes/emailClicked";
import EmailOpened from "../components/Elements/AppNotification/NotificationTypes/emailOpened";
import EmailReceived from "../components/Elements/AppNotification/NotificationTypes/emailReceived";
import EmailReplied from "../components/Elements/AppNotification/NotificationTypes/emailReplied";
import ProductAssigned from "../components/Elements/AppNotification/NotificationTypes/productAssigned";
import ProductEnabled from "../components/Elements/AppNotification/NotificationTypes/productEnabled";
import RoleChanged from "../components/Elements/AppNotification/NotificationTypes/roleChanged";

export const notificationTypes = {
	emailReceived: "EMAIL_RECEIVED",

	emailReplied: "EMAIL_REPLIED",

	emailClicked: "EMAIL_CLICKED",

	emailOpened: "EMAIL_OPENED",

	productEnabled: "PRODUCTS_MODULE_ENABLED",

	activityAssigned: "NEW_ACTIVITY",

	activityReminder: "ACTIVITY_REMINDER",

	contactAssigned: "CONTACT_ASSIGNED",

	companyAssigned: "COMPANY_ASSIGNED",

	dealAssigned: "DEAL_ASSIGNED",

	productAssigned: "PRODUCT_ASSIGNED",

	collaboratorAdded: "COLLABORATOR_ADDED",

	contactSynced: "CONTACT_SYNCED",

	calendarSynced: "CALENDAR_SYNCED",

	addedToTeam: "ADDED_TO_TEAM",

	roleChanged: "ROLE_CHANGED",
};

export const notificationsTitle = {
	contactAssigned: "Contact Assigned",

	companyAssigned: "Company Assigned",

	dealAssigned: "Deal Assigned",

	productAssigned: "Product Assigned",

	emailReceived: "Email Received",

	emailReplied: "Email Replied",

	emailClicked: "Email Clicked",

	emailOpened: "Email Opened",

	activityReminder: "Activity Reminder",

	productEnabled: "Products module enabled",

	calendarSynced: "Calendar Synced",

	contactSynced: "Contact Synced",

	roleChanged: "Role Changed",

	addedToTeam: "Added to Team",

	collaboratorAdded: "Added as a Collaborator",
};

export const notificationMessageComponents = {
	CONTACT_ASSIGNED: {
		title: notificationsTitle.contactAssigned,
		content: ContactAssigned,
	},
	COMPANY_ASSIGNED: {
		title: notificationsTitle.companyAssigned,
		content: CompanyAssigned,
	},
	DEAL_ASSIGNED: {
		title: notificationsTitle.dealAssigned,
		content: DealAssigned,
	},
	PRODUCT_ASSIGNED: {
		title: notificationsTitle.productAssigned,
		content: ProductAssigned,
	},
	NEW_ACTIVITY: {
		content: ActivityAssigned,
	},
	ACTIVITY_REMINDER: {
		title: notificationsTitle.activityReminder,
		content: ActivityReminder,
	},
	CONTACT_SYNCED: {
		title: notificationsTitle.contactSynced,
		content: ContactSynced,
	},
	CALENDAR_SYNCED: {
		title: notificationsTitle.calendarSynced,
		content: CalendarSynced,
	},
	PRODUCTS_MODULE_ENABLED: {
		title: notificationsTitle.productEnabled,
		content: ProductEnabled,
	},
	ADDED_TO_TEAM: {
		title: notificationsTitle.addedToTeam,
		content: AddedToTeam,
	},
	ROLE_CHANGED: {
		title: notificationsTitle.roleChanged,
		content: RoleChanged,
	},
	COLLABORATOR_ADDED: {
		title: notificationsTitle.collaboratorAdded,
		content: CollaboratorAdded,
	},
	EMAIL_RECEIVED: {
		title: notificationsTitle.emailReceived,
		content: EmailReceived,
	},
	EMAIL_REPLIED: {
		title: notificationsTitle.emailReplied,
		content: EmailReplied,
	},
	EMAIL_CLICKED: {
		title: notificationsTitle.emailClicked,
		content: EmailClicked,
	},
	EMAIL_OPENED: {
		title: notificationsTitle.emailOpened,
		content: EmailOpened,
	},
};
