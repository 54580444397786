import { TextField } from "@mui/material";
import React from "react";

export default function Subject(props) {
	const { onChange, value } = props;
	return (
		<React.Fragment>
			<TextField
				value={value}
				onChange={onChange}
				fullWidth
				sx={{
					"& .MuiInputBase-root": {
						minHeight: "42px",
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							border: "none",
						},
						"&:hover fieldset": {
							border: "none",
						},
						"&.Mui-focused fieldset": {
							border: "none",
						},
					},
				}}
			/>
		</React.Fragment>
	);
}
