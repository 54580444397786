import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { OverflowNode } from "@lexical/overflow";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { QuoteNode } from "@lexical/rich-text";
import { LineBreakNode } from "lexical";
import { ExtentedTextNode } from "./ExtendedNode";
import { InlineImageNode } from "./InlineImageNode";

const Nodes = [
	ExtentedTextNode,
	ListNode,
	ListItemNode,
	QuoteNode,
	LinkNode,
	AutoLinkNode,
	LineBreakNode,
	MarkNode,
	CodeHighlightNode,
	CodeNode,
	OverflowNode,
	HorizontalRuleNode,
	InlineImageNode,
];

export default Nodes;
