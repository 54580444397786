import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { getIconByName } from "../../../../utils";
import {
	getDateAndMonthFormat,
	getDateDifference,
} from "../../../../utils/DateUtils";
import { useNavigate } from "react-router-dom";

export default function DealCard(props) {
	const { dealDetails } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Stack
				border="1px solid rgba(0, 0, 0, 0.10)"
				borderRadius="8px"
				p={2}
				spacing={1}
			>
				<Stack>
					<Typography
						fontSize={14}
						fontWeight={500}
						noWrap
						color={theme.palette.secondary.contrastText}
						onClick={() => navigate(`/deals/${dealDetails.id}`)}
						style={{
							cursor: "pointer",
						}}
					>
						{dealDetails?.title}
					</Typography>
					<Typography fontSize={12} color="rgba(0, 0, 0, 0.6)" noWrap>
						{dealDetails?.pipelineStage.name}
					</Typography>
				</Stack>

				{dealDetails?.dealValue?.value ||
				dealDetails?.expectedCloseDate ? (
					<Stack
						direction="row"
						justifyContent={
							dealDetails?.expectedCloseDate
								? "space-between"
								: "flex-start"
						}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							{dealDetails?.expectedCloseDate ? (
								<>
									{getIconByName("time")(
										16,
										16,
										"rgba(0, 0, 0, 0.6)"
									)}

									<Typography
										fontSize={13}
										color="rgba(0, 0, 0, 0.6)"
									>
										{`${getDateAndMonthFormat(
											dealDetails?.expectedCloseDate
										)} ${getDateDifference(
											dealDetails?.expectedCloseDate,
											true
										)}`}
									</Typography>
								</>
							) : null}
						</Stack>

						{dealDetails?.dealValue?.value ? (
							<Typography fontSize={13} fontWeight={500}>
								{dealDetails?.dealValue?.symbol}{" "}
								{dealDetails?.dealValue?.value}
							</Typography>
						) : null}
					</Stack>
				) : null}
			</Stack>
		</React.Fragment>
	);
}
