import React from "react";
import { Typography } from "@mui/material";

export default function CalendarSynced(props) {
	const { notificationData, textColor = "#000" } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				fontSize={13}
				color={textColor}
				style={{ opacity: "0.6" }}
			>
				{`You synced your ${notificationData.data?.provider} Calendar with your CRM.`}
			</Typography>
		</React.Fragment>
	);
}
