import { Box, useTheme } from "@mui/material";
import { useAutomationFilterList } from "../../../../../hooks/services/workflow";
import TriggerFilter from "./TriggerFilter";
import ActionFilter from "./ActionFilter";
import StatusFilter from "./StatusFilter";
import CreatedByFilter from "./CreatedByFilter";
import { useEffect, useState } from "react";

export default function AutomationFilter({ updateFilter }) {
	const theme = useTheme();

	// query call:-
	const { data: filters } = useAutomationFilterList();

	const [filtersList, setFiltersList] = useState([]);

	useEffect(() => {
		if (filters && Array.isArray(filters)) {
			setFiltersList(filters);

			// This is used to apply the previously selected filters when the page is reloaded.
			const getPreviousFilterData = () => {
				return filters.map((filter) => {
					return {
						filterGroup: filter.filterGroup,
						values: getFilterValues(filter.criteria),
					};
				});
			};
			updateFilter(getPreviousFilterData());
		}
	}, [filters, updateFilter]);

	const styles = {
		boxStyle: {
			padding: "8px 16px",
			borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
		},
		headerStyle: {
			fontSize: 13,
		},
		contentStyle: {
			fontSize: 13,
			fontWeight: 500,
			color: theme.palette.primary.main,
			cursor: "pointer",
		},
	};

	const filterComponents = {
		1: TriggerFilter,
		2: ActionFilter,
		3: StatusFilter,
		4: CreatedByFilter,
	};

	const getFilterValues = (filterValues) => {
		return filterValues.map((filterValue) => {
			return { id: filterValue };
		});
	};

	const getFilterRequestData = (updatedFilter) => {
		return updatedFilter.map((filter) => {
			return {
				filterGroup: filter.filterGroup,
				values: getFilterValues(filter.criteria),
			};
		});
	};

	const handleUpdateFilter = (filterGroup, filterValues) => {
		let clonedFilters = [...filtersList];
		let updatedFilter = clonedFilters.map((filter) => {
			if (filter.filterGroup === filterGroup) {
				return { ...filter, criteria: [...filterValues] };
			}
			return { ...filter };
		});

		setFiltersList(updatedFilter);
		let requestData = getFilterRequestData(updatedFilter);
		updateFilter(requestData);
	};

	return (
		<>
			<Box sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
				{filtersList?.map((filter, index) => {
					const FilterComponent =
						filterComponents[filter.filterGroup];

					return FilterComponent ? (
						<FilterComponent
							key={index}
							styles={styles}
							criteria={filter.criteria}
							handleUpdateFilter={(filterValues) =>
								handleUpdateFilter(
									filter.filterGroup,
									filterValues
								)
							}
						/>
					) : null;
				})}
			</Box>
		</>
	);
}
