import React from "react";
import PhoneComponent from "../../../../AddForm/Field/FieldComponents/PhoneComponent";

export default function PhoneFieldComponent({ field, subFields }) {
	return (
		<React.Fragment>
			<PhoneComponent
				field={field}
				fieldSpecificProps={{
					valueSx: { width: "60%" },
					typeSx: { width: "40%" },
				}}
				subField={subFields}
			/>
		</React.Fragment>
	);
}
