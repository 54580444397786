import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import {
	dismissDuplicateCompany,
	getCompanyDuplicateList,
	getReviewDuplicateData,
	mergeCompanyData,
} from "../../../../api/company/CompanyDuplicates/companyDuplicatesApi";
import {
	companyDuplicateListKey,
	getCompanyReviewDuplicateKey,
} from "../../../../utils/queryKeys";
import {
	manageDuplicatesListPageSize,
	reviewDuplicatesPageSize,
} from "../../../../utils/queryConstants/company";

//Duplicate Company List
const useCompanyDuplicateList = () => {
	return useInfiniteQuery({
		queryKey: companyDuplicateListKey,
		queryFn: ({ pageParam }) => {
			return getCompanyDuplicateList({
				start: pageParam
					? (pageParam - 1) * manageDuplicatesListPageSize + 1
					: 1,
				limit: manageDuplicatesListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
	});
};

//Merge Duplicate Companies
const useMergeDuplicateCompany = () => {
	let invalidateCompanyDuplicatesList = useInvalidateCompanyDuplicatelist();
	return useMutation(mergeCompanyData, {
		onSuccess: () => {
			invalidateCompanyDuplicatesList();
		},
	});
};

//Invalidate Duplicate company List
const useInvalidateCompanyDuplicatelist = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(companyDuplicateListKey);
	};
};

//Review Duplicates - Company
const useReviewCompanyDuplicateList = (id) => {
	return useInfiniteQuery({
		queryKey: getCompanyReviewDuplicateKey(id),
		queryFn: ({ pageParam }) => {
			return getReviewDuplicateData(id, {
				start: pageParam
					? (pageParam - 1) * reviewDuplicatesPageSize + 1
					: 1,
				limit: reviewDuplicatesPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
};

//Dismiss comapany dupliacte list
const useDismissDuplicateCompany = () => {
	let invalidateCompanyDuplicatesList = useInvalidateCompanyDuplicatelist();
	return useMutation(dismissDuplicateCompany, {
		onSuccess: () => {
			invalidateCompanyDuplicatesList();
		},
	});
};

export {
	useCompanyDuplicateList,
	useMergeDuplicateCompany,
	useReviewCompanyDuplicateList,
	useDismissDuplicateCompany,
};
