export const searchFilterConstraints = {
	all: "all",
	contact: "contact",
	company: "company",
	deal: "deal",
	product: "product",
	note: "note",
	file: "file",
	activity: "activity",
};
