import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { useRolesList } from "../../../../../hooks/services/userManagement/roles";
import { scope } from "../../../../../utils/PermissionUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";

export default function CreateRole(props) {
	const { closeRoleDialog, onNextClick } = props;
	const theme = useTheme();

	const [selectedRoleToClone, setSelectedRoleToClone] = useState("");
	const [roleName, setRoleName] = useState("");
	const [selectedAccess, setSelectedAccess] = useState(scope.globalAccess);

	const { data: rolesList } = useRolesList();

	const [errors, setErrors] = useState({
		roleToClone: false,
		roleName: false,
	});

	const validateForm = () => {
		let valid = true;
		const newErrors = { roleToClone: false, roleName: false };

		if (!selectedRoleToClone) {
			newErrors.roleToClone = true;
			valid = false;
		}
		if (!roleName) {
			newErrors.roleName = true;
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};

	const handleNextClick = () => {
		const roleNameExists = rolesList.some(
			(roleDetails) =>
				roleDetails.name.toLowerCase() === roleName.toLowerCase()
		);
		if (roleNameExists) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.roleNameValidation,
			});
		} else if (validateForm()) {
			onNextClick(selectedRoleToClone, roleName, selectedAccess);
		}
	};

	const handleSelectRole = (event) => {
		const { value } = event.target;
		setSelectedRoleToClone(value);
		if (event.target.value) {
			setErrors((prev) => ({ ...prev, roleToClone: false }));
		}
	};

	const handleRoleName = (event) => {
		const { value } = event.target;
		setRoleName(value);
		if (event.target.value) {
			setErrors((prev) => ({ ...prev, roleName: false }));
		}
	};

	const handleScopeChange = (event) => {
		setSelectedAccess(event.target.value);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={16} fontWeight={600}>
							Create Role
						</Typography>

						<IconButton
							onClick={closeRoleDialog}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					<Stack spacing={1.5}>
						<Stack>
							<Stack direction="row" alignItems="center">
								<Typography
									fontSize={13}
									fontWeight={500}
									padding="5px"
									color={
										errors.roleToClone &&
										theme.palette.error.main
									}
								>
									Role to Clone
								</Typography>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>

							<Select
								displayEmpty
								size="small"
								value={selectedRoleToClone}
								renderValue={
									selectedRoleToClone !== ""
										? undefined
										: () => (
												<Typography
													fontSize={14}
													color="rgba(0, 0, 0, 0.6)"
												>
													Select
												</Typography>
											)
								}
								onChange={handleSelectRole}
								error={errors.roleToClone}
							>
								{rolesList?.map((role) => (
									<MenuItem
										value={role.value}
										key={role.value}
									>
										<Typography fontSize={14}>
											{role.name}
										</Typography>
									</MenuItem>
								))}
							</Select>
							{errors.roleToClone && (
								<Typography
									color="error"
									fontSize={13}
									fontWeight={500}
									style={{
										paddingLeft: "5px",
									}}
								>
									This field is required.
								</Typography>
							)}
						</Stack>

						<Stack>
							<Stack direction="row" alignItems="center">
								<Typography
									fontSize={13}
									fontWeight={500}
									padding="5px"
									color={
										errors.roleName &&
										theme.palette.error.main
									}
								>
									Role Name
								</Typography>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>
							<TextField
								placeholder="Eg: Marketing Manager"
								onChange={handleRoleName}
								error={errors.roleName}
							/>
							{errors.roleName && (
								<Typography
									color="error"
									fontSize={13}
									fontWeight={500}
									style={{
										paddingLeft: "5px",
									}}
								>
									This field is required.
								</Typography>
							)}
						</Stack>

						<Stack>
							<Typography
								fontSize={13}
								fontWeight={500}
								padding="5px"
							>
								Set the default scope for this Role
							</Typography>

							<FormControl>
								<RadioGroup
									value={selectedAccess}
									onChange={handleScopeChange}
								>
									<Stack pt={1}>
										<FormControlLabel
											value={scope.globalAccess}
											control={<Radio />}
											label={
												<Stack>
													<Typography fontSize={14}>
														Global Access
													</Typography>
													<Typography
														fontSize={13}
														color="rgba(0, 0, 0, 0.6)"
													>
														Can access all records
													</Typography>
												</Stack>
											}
										/>
									</Stack>

									<Stack pt={1}>
										<FormControlLabel
											value={scope.territoryAccess}
											control={<Radio />}
											label={
												<Stack>
													<Typography fontSize={14}>
														Territory Access
													</Typography>
													<Typography
														fontSize={13}
														color="rgba(0, 0, 0, 0.6)"
													>
														Access to record in
														their territory and
														assigned records.
													</Typography>
												</Stack>
											}
										/>
									</Stack>

									<Stack pt={1}>
										<FormControlLabel
											value={scope.restrictedAccess}
											control={<Radio />}
											label={
												<Stack>
													<Typography fontSize={14}>
														Restricted Access
													</Typography>
													<Typography
														fontSize={13}
														color="rgba(0, 0, 0, 0.6)"
													>
														Access only to assigned
														records
													</Typography>
												</Stack>
											}
										/>
									</Stack>
								</RadioGroup>
							</FormControl>
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						color="primary"
						disableElevation
						onClick={() => handleNextClick()}
					>
						Next
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
