const activityStatus = [
	{
		name: "Pending",
		value: 1,
	},
	{
		name: "Completed",
		value: 2,
	},
	{
		name: "Overdue",
		value: 3,
	},
];
export default activityStatus;
