import { apiClient } from "..";
import { getWebformUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getWebformList = async (isActive) => {
	let requestData = getWebFormListRequest(isActive);
	return await postWebformManagementApi(requestData);
};

export const getTemplateList = async () => {
	let requestData = getTemplateListRequest();
	return await postWebformTemplateManagementApi(requestData);
};

export const createWebform = async (data) => {
	let requestData = createWebformRequest(data);
	return await postWebformManagementApi(requestData);
};

export const getWebFormData = async (id) => {
	let requestData = getWebFormDataRequest(id);
	return await postWebformManagementApi(requestData);
};

export const updateWebFormData = async (data) => {
	let requestData = getUpdateWebFormRequest(data);
	return await postWebformManagementApi(requestData);
};

export const deleteWebForm = async (id) => {
	let requestData = getDeleteWebFormRequest(id);
	return await postWebformManagementApi(requestData);
};

export const updateWebFormList = async ({ id, isActive }) => {
	let requestData = getUpdateWebFormListRequest(id, isActive);
	return await postWebformManagementApi(requestData);
};

export const getWebFormDropdownOptionsById = async (id) => {
	let requestData = getWebFormDropdownOptionsByIdRequest(id);
	return await postWebformManagementApi(requestData);
};

const getWebFormListRequest = (isActive) => {
	return {
		type: "list",
		data: {
			isActive: isActive,
		},
	};
};

const getTemplateListRequest = () => {
	return {
		type: "list",
		data: {},
	};
};

const createWebformRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getWebFormDataRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const getUpdateWebFormRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getDeleteWebFormRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getUpdateWebFormListRequest = (id, isActive) => {
	return {
		type: "pUpdate",
		data: {
			id: id,
			isActive: isActive,
		},
	};
};

const getWebFormDropdownOptionsByIdRequest = (id) => {
	return {
		type: "dropdownData",
		data: {
			id: id,
		},
	};
};

const postWebformManagementApi = async (requestData) => {
	let webformManagementUrl = getWebformManagementUrl();
	let response = await apiClient.post(webformManagementUrl, requestData);
	assertError(response, webformManagementUrl);
	return response.data.data;
};

const postWebformTemplateManagementApi = async (requestData) => {
	let webformTemplateManagementUrl = getWebformTemplateManagementUrl();
	let response = await apiClient.post(
		webformTemplateManagementUrl,
		requestData
	);
	assertError(response, webformTemplateManagementUrl);
	return response.data.data;
};

const getWebformManagementUrl = () => {
	return getWebformUrl() + "/mgmt";
};

const getWebformTemplateManagementUrl = () => {
	return getWebformUrl() + "/template/mgmt";
};
