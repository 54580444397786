import { useState, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from "@lexical/html";
import { $insertNodes } from "lexical";

const HtmlPlugin = ({ initialHtml }) => {
	const [editor] = useLexicalComposerContext();
	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		if (!initialHtml || !isFirstRender) return;

		setIsFirstRender(false);

		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(initialHtml, "text/html");
			const nodes = $generateNodesFromDOM(editor, dom);
			$insertNodes(nodes);
		});
	}, [initialHtml]);
};

export default HtmlPlugin;
