import { getRootKey } from "../rootKey";

const notificationKey = "notification";

// notification query key
const getNotificationKey = () => {
	return [...getRootKey(), notificationKey];
};

export const getNotificationsDataKey = () => {
	return [...getNotificationKey(), "notificationsData"];
};

export const getNotificationAlertStateKey = () => {
	return [...getNotificationKey(), "alertState"];
};

export const getPendingNotificationKey = () => {
	return [...getNotificationKey(), "pendingNotification"];
};

export const getTopNotificationsKey = () => {
	return [...getNotificationKey(), "topNotification"];
};

export const getReminderNotificationKey = () => {
	return [...getNotificationKey(), "reminderNotification"];
};

export const getNotificationSettingsDataKey = () => {
	return [...getNotificationKey(), "notificationSettingsData"];
};
