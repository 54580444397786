import React, { useEffect, useState } from "react";
import DateTimeRangePicker from "../../../../../../Elements/DateTimeRangePicker";

export default function DateTimeRangeBaseComponent({
	onChange,
	value,
	subField,
	id,
	error,
	inputRef,
	clearErrors,
}) {
	const [dateTimeRangeValue, setDateTimeRangeValue] = useState({});

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromField = getSubFieldObject("FROM");
	const toField = getSubFieldObject("TO");

	useEffect(() => {
		if (value) {
			let dateTimeRangeValueObj = {};
			dateTimeRangeValueObj.from = value[fromField.id];
			dateTimeRangeValueObj.to = value[toField.id];
			setDateTimeRangeValue(dateTimeRangeValueObj);
		}
	}, [value, fromField, toField]);

	const handledateTimeRangeValueChange = (newDateTimeRangeValue) => {
		setDateTimeRangeValue({ ...newDateTimeRangeValue });
		let dateTimeRangeValueObjectWithId = {};
		dateTimeRangeValueObjectWithId[fromField.id] =
			newDateTimeRangeValue.from;
		dateTimeRangeValueObjectWithId[toField.id] = newDateTimeRangeValue.to;

		let getDateTimeRangeValue = () => {
			if (
				dateTimeRangeValueObjectWithId[fromField.id] &&
				dateTimeRangeValueObjectWithId[toField.id]
			) {
				return dateTimeRangeValueObjectWithId;
			}

			return "";
		};

		onChange(getDateTimeRangeValue());
		clearErrors(id);
	};

	return (
		<React.Fragment>
			<DateTimeRangePicker
				value={dateTimeRangeValue}
				onChange={handledateTimeRangeValueChange}
				id={id}
				error={error}
				inputRef={inputRef}
			/>
		</React.Fragment>
	);
}
