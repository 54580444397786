import { Box, Stack, Typography } from "@mui/material";
import { default as DeniedIcon } from "../../../assets/icons/denied";

export default function UnAuthorized() {
	return (
		<Stack alignItems="center" justifyContent="center" height="100%">
			<Box>{DeniedIcon(30, 30, "#000", "0.6")}</Box>

			<Typography
				fontSize={18}
				fontWeight={500}
				color="rgba(0, 0, 0, 0.6)"
			>
				You are not authorized to perform this operation.
			</Typography>
			<Typography
				fontSize={14}
				fontWeight={400}
				color="rgba(0, 0, 0, 0.6)"
			>
				You do not have permissions to view this page!
			</Typography>
		</Stack>
	);
}
