import React, { useEffect, useState } from "react";
import {
	Box,
	TextField,
	ToggleButton,
	Paper,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { StyledDwarfToggleButtonGroup } from "../../../styles/twozo";
import { default as TimeIcon } from "../../../assets/icons/time";

const styles = {
	timeTextField: {
		width: "25px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: "8px",
		},
		"& .MuiOutlinedInput-root": {
			height: "auto",
			minHeight: "20px",
			padding: "0px 0px",
			"& input": {
				padding: "0px",
			},
			"& fieldset": {
				border: "none",
			},
			"&:hover fieldset": {
				border: "none",
			},
			"&.Mui-focused fieldset": {
				border: "none",
			},
		},
		input: {
			"&[type=number]": {
				MozAppearance: "textfield",
			},
			"&::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
			},
			"&::-webkit-inner-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
			},
		},
	},
};
export default function SingleTimePicker(props) {
	const {
		style,
		reverse,
		value,
		onChange,
		id,
		removeBorder = false,
		inputRef,
		error,
		onClearError,
		hiddenTimeIcon,
		toggleButtonStyle,
	} = props;

	const theme = useTheme();
	const [meridiem, setMeridiem] = useState("am");
	const [hour, setHour] = useState("");
	const [minute, setMinute] = useState("");

	const handleMeridiem = (_, meridiem) => {
		if (meridiem) {
			setMeridiem(meridiem);
			computeValueAndTriggerOnChange({ meridiemValue: meridiem });
		}
	};

	const handleHour = (event) => {
		const { value } = event.target;
		if (value <= 12) {
			setHour(value);
			computeValueAndTriggerOnChange({ hourValue: value });
		}
	};

	const handleMinute = (event) => {
		const { value } = event.target;
		if (value < 60) {
			setMinute(value);
			computeValueAndTriggerOnChange({ minuteValue: value });
		}
	};

	useEffect(() => {
		//update the inputs
		if (value || value === 0) {
			let hourValue = Math.floor(value / 60) % 12;
			let minuteValue = value % 60;
			let meridiemValue = value / 60 < 12 ? "am" : "pm";

			hourValue = hourValue === 0 ? 12 : hourValue;

			setHour(hourValue);
			setMinute(minuteValue);
			setMeridiem(meridiemValue);
		}
	}, [value]);

	const computeValueAndTriggerOnChange = ({
		hourValue,
		minuteValue,
		meridiemValue,
	}) => {
		hourValue = hourValue !== undefined ? hourValue : hour;
		minuteValue = minuteValue !== undefined ? minuteValue : minute;
		meridiemValue = meridiemValue ? meridiemValue : meridiem;

		if (onChange) {
			let computedValue = getComputedValue(
				hourValue,
				minuteValue,
				meridiemValue
			);
			onChange(computedValue);
		}
		onClearError && onClearError();
	};

	const getComputedValue = (hourValue, minuteValue, meridiemValue) => {
		if (!isNaN(parseInt(hourValue)) && !isNaN(parseInt(minuteValue))) {
			hourValue = parseInt(hourValue);
			minuteValue = parseInt(minuteValue);

			if (hourValue > 12) {
				hourValue = 12;
			}

			if (minuteValue > 59) {
				minuteValue = 59;
			}

			setHour(hourValue);
			setMinute(minuteValue);

			if (hourValue === 12) {
				hourValue = 0;
			}

			let hoursIn24HoursFormat =
				meridiemValue === "pm" ? hourValue + 12 : hourValue;
			let totalMinutesFromStartOfDay =
				hoursIn24HoursFormat * 60 + minuteValue;
			return totalMinutesFromStartOfDay;
		}
	};

	return (
		<React.Fragment>
			<Stack
				direction={reverse ? "row-reverse" : "row"}
				alignItems="center"
				style={{
					border: error
						? `1px solid ${theme.palette.error.main}`
						: removeBorder
							? "none"
							: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					minHeight: "42px",
					padding: "0px 8px",
					width: "100%",
					...style,
				}}
				spacing={1}
			>
				{!hiddenTimeIcon ? (
					<Box display="flex">
						{TimeIcon(
							20,
							20,
							error ? theme.palette.error.main : "#666666"
						)}
					</Box>
				) : null}

				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					style={{ marginLeft: "0px", width: "100%" }}
				>
					<TextField
						inputRef={inputRef}
						sx={styles.timeTextField}
						placeholder="HH"
						id={id}
						type="number"
						inputProps={{
							style: {
								textAlign: "right",
								fontSize: "14px",
							},
							min: 1,
							max: 12,
						}}
						value={hour}
						onChange={handleHour}
						error={!!error}
					/>

					<Typography fontSize={12}>:</Typography>

					<TextField
						sx={styles.timeTextField}
						placeholder="MM"
						type="number"
						inputProps={{
							style: {
								fontSize: "14px",
							},
							min: 0,
							max: 59,
						}}
						value={minute}
						onChange={handleMinute}
						error={!!error}
					/>

					<Paper
						elevation={0}
						sx={{
							display: "flex",
							flexWrap: "wrap",
							backgroundColor: "secondary.main",
							borderRadius: "8px !important",
							width: "auto",
							// Position unset !important is necessary to use this element in dialog, because of css overlaps between paper component in dialog.
							position: "unset !important",
						}}
						style={{
							...toggleButtonStyle,
						}}
					>
						<StyledDwarfToggleButtonGroup
							value={meridiem}
							onChange={handleMeridiem}
							exclusive
						>
							<ToggleButton value="am">AM</ToggleButton>
							<ToggleButton value="pm">PM</ToggleButton>
						</StyledDwarfToggleButtonGroup>
					</Paper>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
