import React, { useEffect, useState } from "react";
import {
	Box,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../../assets/icons/add";
import { getAllTemplateListKey } from "../../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getAllTemplateData } from "../../../../api/workflow/workflowApi";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";

const templateCategory = [
	{
		name: "All",
		type: "all",
		categoryId: 0,
	},
	{
		name: "Email",
		type: "email",
		categoryId: 1,
	},
	{
		name: "Activity",
		type: "activity",
		categoryId: 2,
	},
	{
		name: "Deal",
		type: "deal",
		categoryId: 3,
	},
];

export default function Templates() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [templates, setTemplates] = useState([]);
	const [templateCount, setTemplateCount] = useState({
		0: 0,
		1: 0,
		2: 0,
		3: 0,
	});
	const [type, setType] = useState("all");
	const templateListKey = getAllTemplateListKey();

	const { data: allTemplateData } = useQuery(
		templateListKey,
		() => getAllTemplateData(),
		{ staleTime: 600000 }
	);

	useEffect(() => {
		if (allTemplateData && allTemplateData.list) {
			const allTemplate = allTemplateData.list;
			setTemplates(allTemplate);
			let templateCount = {
				0: 0,
				1: 0,
				2: 0,
				3: 0,
			};
			allTemplate.forEach((template) => {
				templateCount[0] += 1;
				templateCount[template.categoryId] += 1;
			});
			setTemplateCount(templateCount);
			setType("all");
		}
	}, [allTemplateData]);

	const changeType = (type, categoryId) => {
		setType(type);

		if (type === "all") {
			setTemplates(allTemplateData.list);
		} else {
			let templates = allTemplateData.list.filter(
				(template) => template.categoryId === categoryId
			);
			setTemplates(templates);
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					display: "grid",
					gridTemplateColumns: "234px auto",
					gap: "8px",
					height: "100%",
				}}
				px={1}
			>
				<Box
					style={{
						overflowY: "auto",
					}}
				>
					{templateCategory.map((category) => (
						<MenuItem
							key={category.type}
							style={{ minHeight: "40px", borderRadius: "8px" }}
							selected={category.type === type}
							sx={{
								"&.Mui-selected": {
									backgroundColor:
										theme.palette.secondary.main,
								},
							}}
							onClick={() =>
								changeType(category.type, category.categoryId)
							}
						>
							<Typography
								fontSize={13}
								fontWeight={category.type === type ? 500 : 400}
							>
								{category.name} (
								{templateCount[category.categoryId]})
							</Typography>
						</MenuItem>
					))}
				</Box>

				<Box
					style={{
						overflowY: "auto",
					}}
				>
					<Box
						style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr",
							gap: "8px",
							overflow: "scroll",
							maxHeight: "100%",
						}}
					>
						{templates.map((template, index) => (
							<Box
								key={index}
								style={{
									border: "1px solid rgba(0,0,0,0.1)",
									borderRadius: "8px",
									minHeight: "180px",
								}}
								p={3}
								pt={2.5}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
								>
									<Box pt={0.5}>
										<Typography fontWeight={500}>
											{template.name}
										</Typography>

										<Typography
											fontSize={14}
											fontWeight={500}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{template.actionCount} Action
										</Typography>
									</Box>

									<Box>
										<IconButton
											onClick={() => {
												template.isActionEnabled
													? navigate(
															"/settings/workflow/-1?templateId=" +
																template.id
														)
													: enqueueSnackbar({
															variant:
																notificationVariants.error,
															title: "Error!",
															message:
																"The Action specified in this template is not enabled.",
														});
											}}
										>
											{AddIcon(
												18,
												18,
												theme.palette.primary.main
											)}
										</IconButton>
									</Box>
								</Stack>

								<Typography
									pt={1}
									fontSize={14}
									color="rgba(0, 0, 0, 0.6)"
								>
									{template.description}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
