import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
import { default as ProductIcon } from "../../../../../assets/icons/product";
import CustomField from "../Fields/CustomField";

export default function Product(props) {
	const { matchedProducts, highlightText } = props;

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				spacing={3}
				width="95%"
			>
				<Box alignItems="start">
					<IconButton
						style={{
							padding: 0,
						}}
					>
						{ProductIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
					</IconButton>
				</Box>

				<Box width="100%">
					<Typography fontSize={14}>
						{highlightText(matchedProducts.name)}
					</Typography>

					{!!matchedProducts.productCode && (
						<Typography style={styles.subText}>
							Product Code:{" "}
							{highlightText(matchedProducts.productCode)}
						</Typography>
					)}

					{matchedProducts.customFields && (
						<CustomField
							highlightText={highlightText}
							customFields={matchedProducts.customFields}
						/>
					)}
				</Box>
			</Stack>
		</React.Fragment>
	);
}
