import React from "react";

function RoundCheckBoxOn(width, height, color = "#33BC7E") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 1.66667C14.5834 1.66667 18.3334 5.41667 18.3334 10C18.3334 14.5833 14.5834 18.3333 10 18.3333C5.41669 18.3333 1.66669 14.5833 1.66669 10C1.66669 5.41667 5.41669 1.66667 10 1.66667ZM13.9832 8.08404C14.2275 7.84018 14.2279 7.44445 13.984 7.20016C13.7402 6.95586 13.3444 6.95551 13.1001 7.19938L8.81708 11.4749L6.9003 9.5581C6.65622 9.31402 6.26049 9.31402 6.01641 9.5581C5.77233 9.80218 5.77233 10.1979 6.01641 10.442L8.37475 12.8003C8.61867 13.0442 9.0141 13.0444 9.25824 12.8007L13.9832 8.08404Z"
				fill={color}
			/>
		</svg>
	);
}

export default RoundCheckBoxOn;
