import { useAuth } from "../../../hooks/auth";

export default function CanNot(params) {
	const { permission, children } = params;
	const { isUserAllowedFor } = useAuth();

	if (!isUserAllowedFor(permission)) {
		return children;
	}
	return <></>;
}
