import { FieldPermissionContext } from "..";

export default function FieldPermissionContextProvider({ children, value }) {
	return (
		<>
			<FieldPermissionContext.Provider value={value}>
				{children}
			</FieldPermissionContext.Provider>
		</>
	);
}
