import React, { useState } from "react";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import {
	Box,
	Chip,
	Paper,
	Popper,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { emailPattern } from "../../../../../utils/validation";
import { Tooltip } from "../../../../../styles/twozo";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function GuestComponent(props) {
	const {
		getOptionLabel,
		filterOptions,
		updateSelectedGuest,
		onGuestChange,
		...other
	} = props;
	const theme = useTheme();
	const [invalidMailIndex, setInvalidMailIndex] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const tooltipTitle =
		"Invalid address, Verify the email address before saving the activity.";

	const isValidEmail = (option) => {
		if (option.isNewOption) {
			return emailPattern.test(option.email);
		} else {
			return emailPattern.test(option.email.value);
		}
	};

	const guestRequestData = (values) => {
		const requestData = {
			contacts: [],
			emails: [],
		};

		values.forEach((guest) => {
			if (guest.email.value) {
				requestData.contacts.push({
					id: guest.value,
					emailId: guest.email.id,
				});
			} else {
				const guestEmail = {
					email: guest.email,
				};

				if (guest.id) {
					guestEmail.id = guest.id;
				}

				requestData.emails.push(guestEmail);
			}
		});

		if (requestData.contacts.length === 0) {
			delete requestData.contacts;
		}

		if (requestData.emails.length === 0) {
			delete requestData.emails;
		}

		return requestData;
	};

	const handleGuestChange = (values) => {
		updateSelectedGuest(values);
		const requestData = guestRequestData(values);
		onGuestChange(requestData);
		clearSearchValue();
	};

	const clearSearchValue = () => {
		setSearchValue("");
	};

	const isDropdownMenuOpen = () => {
		return searchValue.length > 0;
	};

	return (
		<MuiAutocomplete
			{...other}
			size="small"
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			open={isDropdownMenuOpen()}
			onBlur={clearSearchValue}
			onChange={(_, values) => handleGuestChange(values)}
			forcePopupIcon={false}
			multiple
			style={{
				width: "100%",
			}}
			disableClearable
			getOptionLabel={getOptionLabel}
			filterSelectedOptions={(options, { inputValue }) =>
				options.filter((option) =>
					option.email
						.toLowerCase()
						.includes(inputValue.toLowerCase())
				)
			}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Tooltip
						key={index}
						placement="top"
						open={invalidMailIndex === index}
						title={tooltipTitle}
					>
						<Chip
							key={index}
							size="small"
							label={
								<Stack direction="row" spacing={0.5}>
									{!option?.email?.value ? (
										<Typography fontSize={14} color="#000">
											{option?.email}
										</Typography>
									) : (
										<>
											<Typography
												fontSize={14}
												color="#000"
											>
												{option?.name}
											</Typography>

											<Typography color="#000">
												-
											</Typography>

											<Typography
												fontSize={14}
												color="rgba(0, 0, 0, 0.6)"
											>
												{option?.email?.value}
											</Typography>
										</>
									)}
								</Stack>
							}
							color="secondary"
							deleteIcon={CloseIcon(16, 16, "#000")}
							sx={{
								borderRadius: "8px",
								backgroundColor:
									!isValidEmail(option) &&
									theme.palette.warning.main,
							}}
							onMouseOver={() =>
								!isValidEmail(option)
									? setInvalidMailIndex(index)
									: null
							}
							onMouseOut={() => setInvalidMailIndex("")}
							{...getTagProps({
								index,
							})}
						/>
					</Tooltip>
				))
			}
			renderOption={(props, option) => (
				<Box
					key={option?.emailId}
					{...props}
					style={{
						minHeight: option.isNewOption ? "40px" : "60px",
					}}
				>
					{option.isNewOption ? (
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width="100%"
						>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								{option?.email}
							</Typography>

							<Box display="flex">
								{AddIcon(20, 20, theme.palette.primary.main)}
							</Box>
						</Stack>
					) : (
						<Stack>
							<Typography fontSize={13} fontWeight={400}>
								{option?.name}
							</Typography>
							<Typography
								fontSize={13}
								fontWeight={400}
								color="rgba(0, 0, 0, 0.6)"
							>
								{option?.email?.value}
							</Typography>
						</Stack>
					)}
				</Box>
			)}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder="Select"
					onChange={(event) => setSearchValue(event.target.value)}
					inputProps={{
						...params.inputProps,
						style: {
							fontSize: "14px",
						},
					}}
				/>
			)}
		/>
	);
}
