import {
	Box,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as DealIcon } from "../../../assets/icons/deals";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as ProductIcon } from "../../../assets/icons/product";
import Menu from "../../Elements/Menu";
import { twozoStyles } from "../../../styles/twozo";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import CheckDuplicates from "../../Contacts/CheckDuplicates";
import AddContact from "../../Contacts/AddContact";
import AddNewDeals from "../../Deals/AddNewDeals";
import AddCompany from "../../Companies/AddCompany";
import AddProduct from "../../Products/AddProduct";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import { useModuleList } from "../../../hooks/services/common";
import { modules } from "../../../utils/common/ModulesName";
import { useModuleName } from "../../../hooks/modules";

export default function GlobalAdd() {
	const theme = useTheme();
	const classes = twozoStyles();

	// query call :-
	const { isUserAllowedFor } = useAuth();

	const { getModuleName } = useModuleName();
	const { data: moduleList } = useModuleList();

	const [globalAddMenuElement, setGlobalAddMenuElement] = useState(null);
	const isGlobalAddMenuOpened = Boolean(globalAddMenuElement);

	const [isAddContactDialogOpened, setIsAddContactDialogOpened] =
		useState(false);
	const [isDuplicateCheckEnabled, setIsDuplicateCheckEnabled] =
		useState(false);
	const [contactDetails, setContactDetails] = useState({});

	const [openAddNewDealsDialog, setOpenAddNewDealsDialog] = useState(false);

	const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);

	const [openAddProductDialog, setOpenAddProductDialog] = useState(false);

	const openGlobalAddMenu = (event) => {
		setGlobalAddMenuElement(event.currentTarget);
	};

	const closeGlobalAddMenu = () => {
		setGlobalAddMenuElement(null);
	};

	const toggleAddContactDialog = () => {
		closeGlobalAddMenu();
		setIsAddContactDialogOpened(
			(isAddContactDialogOpened) => !isAddContactDialogOpened
		);
	};

	const checkContactDuplicates = (contactData) => {
		removeFieldsWithEmptyValues(contactData);
		setContactDetails(contactData);
	};

	const toggleCheckDuplicatesDialog = () => {
		setIsDuplicateCheckEnabled(
			(isDuplicateCheckEnabled) => !isDuplicateCheckEnabled
		);
	};

	const onDuplicateMergeSuccess = () => {
		toggleAddContactDialog();
		toggleCheckDuplicatesDialog();
	};

	const toggleAddNewDealsDialog = () => {
		closeGlobalAddMenu();
		setOpenAddNewDealsDialog(
			(openAddNewDealsDialog) => !openAddNewDealsDialog
		);
	};

	const toggleAddCompanyDialog = () => {
		closeGlobalAddMenu();
		setOpenAddCompanyDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const toggleAddProductDialog = () => {
		closeGlobalAddMenu();
		setOpenAddProductDialog(
			(openAddProductDialog) => !openAddProductDialog
		);
	};

	const isProductModuleEnabled = useMemo(() => {
		if (Array.isArray(moduleList)) {
			let moduleData = moduleList.find(
				(module) => module.sourceName === modules.PRODUCT
			);
			if (moduleData) {
				return (
					moduleData.isEnabled &&
					isUserAllowedFor(PERMISSIONS.product.create)
				);
			}
		}
	}, [moduleList]);

	const globalAddMenuList = [
		{
			id: 1,
			name: `Add ${getModuleName(modules.DEAL)}`,
			icon: DealIcon,
			action: toggleAddNewDealsDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.create),
		},
		{
			id: 2,
			name: `Add ${getModuleName(modules.CONTACT)}`,
			icon: ContactIcon,
			action: toggleAddContactDialog,
			permission: isUserAllowedFor(PERMISSIONS.contact.create),
		},
		{
			id: 3,
			name: `Add ${getModuleName(modules.COMPANY)}`,
			icon: CompanyIcon,
			action: toggleAddCompanyDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.create),
		},
		{
			id: 4,
			name: `Add ${getModuleName(modules.PRODUCT)}`,
			icon: ProductIcon,
			action: toggleAddProductDialog,
			permission: isProductModuleEnabled,
		},
	];

	const getGlobalAddMenuList = () => {
		return globalAddMenuList.filter((menu) => menu.permission);
	};

	return (
		<React.Fragment>
			{/* Add Contact Drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddContactDialogOpened}
				onOpen={toggleAddContactDialog}
				onClose={toggleAddContactDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					hidden={isDuplicateCheckEnabled}
				>
					<AddContact
						setIsDuplicateCheckEnabled={setIsDuplicateCheckEnabled}
						onClose={toggleAddContactDialog}
						checkContactDuplicates={checkContactDuplicates}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/* Check for duplicates Drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isDuplicateCheckEnabled}
				onOpen={toggleCheckDuplicatesDialog}
				onClose={toggleCheckDuplicatesDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
				BackdropProps={{ invisible: true }}
			>
				<Box
					style={{ height: "100vh" }}
					className={classes.addDialogContainer}
				>
					<CheckDuplicates
						onClose={toggleCheckDuplicatesDialog}
						contactDetails={contactDetails}
						onDuplicateMergeSuccess={onDuplicateMergeSuccess}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/* Add Deal Drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddNewDealsDialog}
				onOpen={toggleAddNewDealsDialog}
				onClose={toggleAddNewDealsDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddNewDeals onClose={toggleAddNewDealsDialog} />
				</Box>
			</CustomSwipeableDrawer>

			{/* Add Company Drawer*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddCompanyDialog}
				onOpen={toggleAddCompanyDialog}
				onClose={toggleAddCompanyDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddCompany onClose={toggleAddCompanyDialog} />
				</Box>
			</CustomSwipeableDrawer>

			{/* Add new Product Drawer*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddProductDialog}
				onOpen={toggleAddProductDialog}
				onClose={toggleAddProductDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddProduct onClose={toggleAddProductDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={globalAddMenuElement}
				open={isGlobalAddMenuOpened}
				onClose={closeGlobalAddMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{
					marginTop: "4px",
				}}
			>
				{getGlobalAddMenuList()?.map((menuItem) => (
					<MenuItem
						key={menuItem.id}
						style={{ height: "40px" }}
						onClick={() => menuItem.action()}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							{menuItem.icon(
								"20",
								"20",
								theme.palette.primary.main
							)}
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								{menuItem.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{getGlobalAddMenuList()?.length > 0 ? (
				<Box
					display="flex"
					style={{
						backgroundColor: "#33BC7E1F",
						borderRadius: "8px",
						cursor: "pointer",
						padding: 10,
					}}
					onClick={openGlobalAddMenu}
				>
					<IconButton style={{ padding: 0 }}>
						{AddIcon(22, 22, theme.palette.primary.main)}
					</IconButton>
				</Box>
			) : null}
		</React.Fragment>
	);
}
