import { Typography, useTheme } from "@mui/material";
import SingleTimePicker from "../../SingleTimePicker";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { forwardRef, useCallback, useImperativeHandle } from "react";

const TimeFieldEditComponent = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			control,
			handleSubmit,
			formState: { errors },
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const onClearError = () => {
			onError(null);
			clearErrors(fieldId + "");
		};

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, value } }) => (
						<SingleTimePicker
							removeBorder={true}
							onChange={onChange}
							value={value}
							onClearError={onClearError}
						/>
					)}
					defaultValue=""
				/>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

TimeFieldEditComponent.displayName = "TimeFieldEditComponent";

export default TimeFieldEditComponent;
