import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getAccountAdminDetails,
	updateAccountAdminDetails,
} from "../../../../api/accountSettings/account";
import { accountAdminDetailsKey } from "../../../../utils/queryKeys/accountSettings/account";

const useAccountAdminDetails = () => {
	return useQuery(accountAdminDetailsKey, () => getAccountAdminDetails());
};

const useUpdateAccountAdminDetails = () => {
	let invalidateAccountSettingsAdminDetails =
		useInvalidateAccountSettingsAdminDetails();
	return useMutation(updateAccountAdminDetails, {
		onSuccess: () => {
			invalidateAccountSettingsAdminDetails();
		},
	});
};

const useInvalidateAccountSettingsAdminDetails = () => {
	let queryClient = useQueryClient();

	return () => {
		queryClient.invalidateQueries(accountAdminDetailsKey);
	};
};

export { useAccountAdminDetails, useUpdateAccountAdminDetails };
