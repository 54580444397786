import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../auth";

// Contact field permissions
const useContactFieldPermissions = () => {
	const { isUserAllowedFor } = useAuth();

	return {
		phone: {
			edit: isUserAllowedFor(PERMISSIONS.contact.edit),
		},
		phones: {
			edit: isUserAllowedFor(PERMISSIONS.contact.edit),
		},
		email: {
			edit: isUserAllowedFor(PERMISSIONS.contact.edit),
		},
		emails: {
			edit: isUserAllowedFor(PERMISSIONS.contact.edit),
		},
		owner: {
			enabled: isUserAllowedFor(PERMISSIONS.contact.assignRecords),
		},
	};
};

export { useContactFieldPermissions };
