import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	addCurrency,
	getCurrencyData,
	getCurrencyList,
	updateBaseCurrency,
	updateCurrencyStatus,
} from "../../../api/currency";
import {
	getCurrencyDataKey,
	getCurrencyListKey,
} from "../../../utils/queryKeys/currency";

const useCurrencyList = () => {
	return useQuery(getCurrencyListKey(), () => getCurrencyList(), {
		select: (currency) => currency?.options,
	});
};

const useCurrencyData = () => {
	return useQuery(getCurrencyDataKey(), () => getCurrencyData());
};

const useInvalidateCurrencyList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCurrencyListKey());
	};
};

const useInvalidateCurrencyData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCurrencyDataKey());
	};
};

const useUpdateBaseCurrencyMutation = () => {
	const invalidateCurrencyData = useInvalidateCurrencyData();
	const invalidateCurrencyList = useInvalidateCurrencyList();
	return useMutation(updateBaseCurrency, {
		onSuccess: () => {
			invalidateCurrencyData();
			invalidateCurrencyList();
		},
	});
};

const useAddCurrencyMutation = () => {
	const invalidateCurrencyData = useInvalidateCurrencyData();
	const invalidateCurrencyList = useInvalidateCurrencyList();
	return useMutation(addCurrency, {
		onSuccess: () => {
			invalidateCurrencyData();
			invalidateCurrencyList();
		},
	});
};

const useUpdateCurrencyStatus = () => {
	const invalidateCurrencyData = useInvalidateCurrencyData();
	return useMutation(updateCurrencyStatus, {
		onSuccess: () => {
			invalidateCurrencyData();
		},
	});
};

export {
	useCurrencyData,
	useUpdateBaseCurrencyMutation,
	useAddCurrencyMutation,
	useUpdateCurrencyStatus,
	useCurrencyList,
};
