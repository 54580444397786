import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import DateRangeFieldBaseComponent from "./DateRangeFieldBaseComponent";

export default function DateRangeFieldComponent({ field, subFields }) {
	const { control, clearErrors } = useFormContext();

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromDateField = getSubFieldObject("FROM");
	const toDateField = getSubFieldObject("TO");

	const handleDateRangeValueChange = (newDateRangeValue, onChange) => {
		let dateRangeValueObjectWithId = {};
		dateRangeValueObjectWithId[fromDateField.id] = newDateRangeValue.from;
		dateRangeValueObjectWithId[toDateField.id] = newDateRangeValue.to;

		let getDateRangeValue = () => {
			if (
				dateRangeValueObjectWithId[fromDateField.id] &&
				dateRangeValueObjectWithId[toDateField.id]
			) {
				return dateRangeValueObjectWithId;
			}

			return "";
		};

		onChange(getDateRangeValue());
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({ field: { onChange }, fieldState: { error } }) => (
					<DateRangeFieldBaseComponent
						placeholder={field?.config?.placeHolder}
						onChange={(value) =>
							handleDateRangeValueChange(value, onChange)
						}
						subField={subFields}
						field={field}
						error={error}
						clearErrors={clearErrors}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
