import React from "react";
import { Typography } from "@mui/material";

export default function CollaboratorAdded(props) {
	const { notificationData, textColor = "#000" } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				style={{
					fontSize: "13px",
					color: textColor,
					opacity: "0.6",
					wordBreak: "break-word",
				}}
			>
				{`You’re added as a Collaborator in the activity “${notificationData?.data?.activityTitle}”`}
			</Typography>
		</React.Fragment>
	);
}
