const currencyName = [
	{
		code: "AED",
		name: "United Arab Emirates dirham",
		value: 1,
	},
	{
		code: "AFN",
		name: "Afghan afghani",
		value: 2,
	},
	{
		code: "ALL",
		name: "Albanian lek",
		value: 3,
	},
	{
		code: "AMD",
		name: "Armenian dram",
		value: 4,
	},
	{
		code: "AOA",
		name: "Angolan kwanza",
		value: 5,
	},
	{
		code: "AUD",
		name: "Australian dollar",
		value: 6,
	},
	{
		code: "AZN",
		name: "Azerbaijani manat",
		value: 7,
	},
	{
		code: "BAM",
		name: "Bosnia and Herzegovina convertible mark",
		value: 8,
	},
	{
		code: "BBD",
		name: "Barbadian dollar",
		value: 9,
	},
	{
		code: "BDT",
		name: "Bangladeshi taka",
		value: 10,
	},
	{
		code: "BGN",
		name: "Bulgarian lev",
		value: 11,
	},
	{
		code: "BHD",
		name: "Bahraini dinar",
		value: 12,
	},
	{
		code: "BIF",
		name: "Burundian franc",
		value: 13,
	},
	{
		code: "BND",
		name: "Brunei dollar",
		value: 14,
	},
	{
		code: "BOB",
		name: "Bolivian boliviano",
		value: 15,
	},
	{
		code: "BRL",
		name: "Brazilian real",
		value: 16,
	},
	{
		code: "BSD",
		name: "Bahamian dollar",
		value: 17,
	},
	{
		code: "BTN",
		name: "Bhutanese ngultrum",
		value: 18,
	},
	{
		code: "BWP",
		name: "Botswana pula",
		value: 19,
	},
	{
		code: "BYN",
		name: "Belarusian ruble",
		value: 20,
	},
	{
		code: "BZD",
		name: "Belize dollar",
		value: 21,
	},
	{
		code: "CAD",
		name: "Canadian dollar",
		value: 22,
	},
	{
		code: "CDF",
		name: "Congolese franc",
		value: 23,
	},
	{
		code: "CHF",
		name: "Swiss franc",
		value: 24,
	},
	{
		code: "CLP",
		name: "Chilean peso",
		value: 25,
	},
	{
		code: "CNY",
		name: "Chinese yuan",
		value: 26,
	},
	{
		code: "COP",
		name: "Colombian peso",
		value: 27,
	},
	{
		code: "CRC",
		name: "Costa Rican colón",
		value: 28,
	},
	{
		code: "CUP",
		name: "Cuban peso",
		value: 29,
	},
	{
		code: "CVE",
		name: "Cape Verdean escudo",
		value: 30,
	},
	{
		code: "CZK",
		name: "Czech koruna",
		value: 31,
	},
	{
		code: "DJF",
		name: "Djiboutian franc",
		value: 32,
	},
	{
		code: "DKK",
		name: "Danish krone",
		value: 33,
	},
	{
		code: "DOP",
		name: "Dominican peso",
		value: 34,
	},
	{
		code: "DZD",
		name: "Algerian dinar",
		value: 35,
	},
	{
		code: "EGP",
		name: "Egyptian pound",
		value: 36,
	},
	{
		code: "ERN",
		name: "Eritrean nakfa",
		value: 37,
	},
	{
		code: "ETB",
		name: "Ethiopian birr",
		value: 38,
	},
	{
		code: "EUR",
		name: "Euro",
		value: 39,
	},
	{
		code: "FJD",
		name: "Fijian dollar",
		value: 40,
	},
	{
		code: "GBP",
		name: "British pound",
		value: 41,
	},
	{
		code: "GEL",
		name: "Georgian lari",
		value: 42,
	},
	{
		code: "GHS",
		name: "Ghanaian cedi",
		value: 43,
	},
	{
		code: "GMD",
		name: "Gambian dalasi",
		value: 44,
	},
	{
		code: "GNF",
		name: "Guinean franc",
		value: 45,
	},
	{
		code: "GTQ",
		name: "Guatemalan quetzal",
		value: 46,
	},
	{
		code: "GYD",
		name: "Guyanese dollar",
		value: 47,
	},
	{
		code: "HNL",
		name: "Honduran lempira",
		value: 48,
	},
	{
		code: "HRK",
		name: "Croatian kuna",
		value: 49,
	},
	{
		code: "HTG",
		name: "Haitian gourde",
		value: 50,
	},
	{
		code: "HUF",
		name: "Hungarian forint",
		value: 51,
	},
	{
		code: "valueR",
		name: "Indonesian rupiah",
		value: 52,
	},
	{
		code: "ILS",
		name: "Israeli new shekel",
		value: 53,
	},
	{
		code: "INR",
		name: "Indian rupee",
		value: 54,
	},
	{
		code: "IQD",
		name: "Iraqi dinar",
		value: 55,
	},
	{
		code: "IRR",
		name: "Iranian rial",
		value: 56,
	},
	{
		code: "ISK",
		name: "Icelandic króna",
		value: 57,
	},
	{
		code: "JMD",
		name: "Jamaican dollar",
		value: 58,
	},
	{
		code: "JOD",
		name: "Jordanian dinar",
		value: 59,
	},
	{
		code: "JPY",
		name: "Japanese yen",
		value: 60,
	},
	{
		code: "KES",
		name: "Kenyan shilling",
		value: 61,
	},
	{
		code: "KGS",
		name: "Kyrgyzstani som",
		value: 62,
	},
	{
		code: "KHR",
		name: "Cambodian riel",
		value: 63,
	},
	{
		code: "KMF",
		name: "Comorian franc",
		value: 64,
	},
	{
		code: "KPW",
		name: "North Korean won",
		value: 65,
	},
	{
		code: "KRW",
		name: "South Korean won",
		value: 66,
	},
	{
		code: "KWD",
		name: "Kuwaiti dinar",
		value: 67,
	},
	{
		code: "KZT",
		name: "Kazakhstani tenge",
		value: 68,
	},
	{
		code: "LAK",
		name: "Lao kip",
		value: 69,
	},
	{
		code: "LBP",
		name: "Lebanese pound",
		value: 70,
	},
	{
		code: "LKR",
		name: "Sri Lankan rupee",
		value: 71,
	},
	{
		code: "LRD",
		name: "Liberian dollar",
		value: 72,
	},
	{
		code: "LSL",
		name: "Lesotho loti",
		value: 73,
	},
	{
		code: "LYD",
		name: "Libyan dinar",
		value: 74,
	},
	{
		code: "MAD",
		name: "Moroccan dirham",
		value: 75,
	},
	{
		code: "MDL",
		name: "Moldovan leu",
		value: 76,
	},
	{
		code: "MGA",
		name: "Malagasy ariary",
		value: 77,
	},
	{
		code: "MKD",
		name: "Macedonian denar",
		value: 78,
	},
	{
		code: "MMK",
		name: "Burmese kyat",
		value: 79,
	},
	{
		code: "MNT",
		name: "Mongolian tögrög",
		value: 80,
	},
	{
		code: "MRO",
		name: "Mauritanian ouguiya",
		value: 81,
	},
	{
		code: "MUR",
		name: "Mauritian rupee",
		value: 82,
	},
	{
		code: "MVR",
		name: "Maldivian rufiyaa",
		value: 83,
	},
	{
		code: "MWK",
		name: "Malawian kwacha",
		value: 84,
	},
	{
		code: "MXN",
		name: "Mexican peso",
		value: 85,
	},
	{
		code: "MYR",
		name: "Malaysian ringgit",
		value: 86,
	},
	{
		code: "MZN",
		name: "Mozambican metical",
		value: 87,
	},
	{
		code: "NAD",
		name: "Namibian dollar",
		value: 88,
	},
	{
		code: "NGN",
		name: "Nigerian naira",
		value: 89,
	},
	{
		code: "NIO",
		name: "Nicaraguan córdoba",
		value: 90,
	},
	{
		code: "NOK",
		name: "Norwegian krone",
		value: 91,
	},
	{
		code: "NPR",
		name: "Nepalese rupee",
		value: 92,
	},
	{
		code: "NZD",
		name: "New Zealand dollar",
		value: 93,
	},
	{
		code: "OMR",
		name: "Omani rial",
		value: 94,
	},
	{
		code: "PAB",
		name: "Panamanian balboa",
		value: 95,
	},
	{
		code: "PEN",
		name: "Peruvian sol",
		value: 96,
	},
	{
		code: "PGK",
		name: "Papua New Guinean kina",
		value: 97,
	},
	{
		code: "PHP",
		name: "Philippine peso",
		value: 98,
	},
	{
		code: "PKR",
		name: "Pakistani rupee",
		value: 99,
	},
	{
		code: "PLN",
		name: "Polish zloty",
		value: 100,
	},
	{
		code: "PYG",
		name: "Paraguayan Guarani",
		value: 101,
	},
	{
		code: "QAR",
		name: "Qatari Riyal",
		value: 102,
	},
	{
		code: "RON",
		name: "Romanian Leu",
		value: 103,
	},
	{
		code: "RSD",
		name: "Serbian Dinar",
		value: 104,
	},
	{
		code: "RUB",
		name: "Russian Ruble",
		value: 105,
	},
	{
		code: "RWF",
		name: "Rwandan Franc",
		value: 106,
	},
	{
		code: "SAR",
		name: "Saudi Riyal",
		value: 107,
	},
	{
		code: "SBD",
		name: "Solomon Islands Dollar",
		value: 108,
	},
	{
		code: "SCR",
		name: "Seychellois Rupee",
		value: 109,
	},
	{
		code: "SDG",
		name: "Sudanese Pound",
		value: 110,
	},
	{
		code: "SEK",
		name: "Swedish Krona",
		value: 111,
	},
	{
		code: "SGD",
		name: "Singapore Dollar",
		value: 112,
	},
	{
		code: "SLL",
		name: "Sierra Leonean Leone",
		value: 113,
	},
	{
		code: "SOS",
		name: "Somali Shilling",
		value: 114,
	},
	{
		code: "SRD",
		name: "Surinamese Dollar",
		value: 115,
	},
	{
		code: "SSP",
		name: "South Sudanese Pound",
		value: 116,
	},
	{
		code: "STD",
		name: "Sao Tome And Principe Dobra",
		value: 117,
	},
	{
		code: "SYP",
		name: "Syrian Pound",
		value: 118,
	},
	{
		code: "SZL",
		name: "Swazi Lilangeni",
		value: 119,
	},
	{
		code: "THB",
		name: "Thai Baht",
		value: 120,
	},
	{
		code: "TJS",
		name: "Tajikistani Somoni",
		value: 121,
	},
	{
		code: "TMT",
		name: "Turkmenistan Manat",
		value: 122,
	},
	{
		code: "TND",
		name: "Tunisian Dinar",
		value: 123,
	},
	{
		code: "TOP",
		name: "Tongan Pa'anga",
		value: 124,
	},
	{
		code: "TRY",
		name: "Turkish Lira",
		value: 125,
	},
	{
		code: "TTD",
		name: "Trinvaluead And Tobago Dollar",
		value: 126,
	},
	{
		code: "TWD",
		name: "New Taiwan Dollar",
		value: 127,
	},
	{
		code: "TZS",
		name: "Tanzanian Shilling",
		value: 128,
	},
	{
		code: "UAH",
		name: "Ukrainian Hryvnia",
		value: 129,
	},
	{
		code: "UGX",
		name: "Ugandan Shilling",
		value: 130,
	},
	{
		code: "USD",
		name: "United States Dollar",
		value: 131,
	},
	{
		code: "UYU",
		name: "Uruguayan Peso",
		value: 132,
	},
	{
		code: "UZS",
		name: "Uzbekistani Som",
		value: 133,
	},
	{
		code: "VEF",
		name: "Venezuelan Bolívar",
		value: 134,
	},
	{
		code: "VND",
		name: "Vietnamese Dong",
		value: 135,
	},
	{
		code: "VUV",
		name: "Vanuatu Vatu",
		value: 136,
	},
	{
		code: "WST",
		name: "Samoan Tala",
		value: 137,
	},
	{
		code: "XAF",
		name: "Central African CFA Franc",
		value: 138,
	},
	{
		code: "XCD",
		name: "East Caribbean Dollar",
		value: 139,
	},
	{
		code: "XOF",
		name: "West African CFA Franc",
		value: 140,
	},
	{
		code: "YER",
		name: "Yemeni Rial",
		value: 141,
	},
	{
		code: "ZAR",
		name: "southAfricanRand",
		value: 142,
	},
	{
		code: "ZMW",
		name: "Zambian Kwacha",
		value: 143,
	},
];

export default currencyName;
