import React from "react";
import { Box } from "@mui/material";
import NotificationTitle from "./NotificationTitle";
import NotificationContent from "./NotificationContent";

export default function NotificationMessage(props) {
	const {
		notificationData,
		onClose,
		notificationContentColor,
		isNotificationActionEnabled,
	} = props;

	return (
		<React.Fragment>
			<Box>
				<NotificationTitle
					notificationData={notificationData}
					onClose={onClose}
					isNotificationActionEnabled={isNotificationActionEnabled}
				/>

				<NotificationContent
					notificationData={notificationData}
					onClose={onClose}
					notificationContentColor={notificationContentColor}
					isNotificationActionEnabled={isNotificationActionEnabled}
				/>
			</Box>
		</React.Fragment>
	);
}
