import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getGroupedFormFieldsKey,
	getDropdownKeyByFieldId,
	getFormFieldsKey,
	getUpdateFieldListKey,
	getGroupedFormFieldsDataKey,
	getAllGroupFormFieldsDataKey,
} from "../../../../utils/queryKeys";
import {
	getGroupedFormFields,
	getFormFields,
	getUpdateFieldList,
	getGroupedFormFieldsData,
} from "../../../../api/fields/form/formApi";
import { getFieldDropdownData } from "../../../../api/fields/config/configApi";

const useFormFieldsData = (source, view) => {
	const formFieldsKey = getFormFieldsKey(source, view);
	return useQuery(formFieldsKey, () => getFormFields(source, view));
};

const useGroupedFormFields = (moduleName, fieldId) => {
	const dependableFieldKey = getGroupedFormFieldsKey(moduleName, fieldId);
	return useQuery(dependableFieldKey, () =>
		getGroupedFormFields({ source: moduleName, id: fieldId })
	);
};

const useUpdateFieldList = (moduleName) => {
	const updateFieldListKey = getUpdateFieldListKey(moduleName);
	return useQuery(updateFieldListKey, () => getUpdateFieldList(moduleName));
};

const useGroupedFormFieldsData = (source, entityId, fieldId) => {
	const groupFieldDataKey = getGroupedFormFieldsDataKey(
		source,
		entityId,
		fieldId
	);
	return useQuery(groupFieldDataKey, () =>
		getGroupedFormFieldsData(source, entityId, fieldId)
	);
};

const useFieldDropdownList = (fieldId) => {
	const dropdownKey = getDropdownKeyByFieldId(fieldId);
	return useQuery(dropdownKey, () => getFieldDropdownData({ id: fieldId }), {
		enabled: !!fieldId,
		select: (data) => data?.options,
	});
};

const useInvalidateGroupFieldData = (source, entityId) => {
	let queryClient = useQueryClient();
	let dependableKey = getAllGroupFormFieldsDataKey(source, entityId);
	return () => {
		queryClient.invalidateQueries(dependableKey);
	};
};

export {
	useFormFieldsData,
	useFieldDropdownList,
	useGroupedFormFields,
	useUpdateFieldList,
	useGroupedFormFieldsData,
	useInvalidateGroupFieldData,
};
