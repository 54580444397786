import React from "react";

export default function FavouriteOff(
	width = 24,
	height = 24,
	color = "#000",
	opacity = "1"
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
		>
			<path
				opacity={opacity}
				d="M16.5354 8.94025L16.5358 8.94031L19.5649 9.44358C19.565 9.4436 19.5651 9.44362 19.5653 9.44364C20.4043 9.58356 20.6648 9.91157 20.7228 10.0919C20.7802 10.2706 20.761 10.6865 20.1568 11.2866L20.155 11.2884L17.7998 13.6436C17.488 13.9554 17.2805 14.3711 17.1663 14.77C17.052 15.1693 17.0088 15.6299 17.1045 16.0569L17.1045 16.0569L17.1056 16.0618L17.7797 18.9768C18.0311 20.0674 17.7728 20.4396 17.6663 20.5172C17.5586 20.5957 17.1226 20.7272 16.1576 20.1573C16.1574 20.1572 16.1572 20.1571 16.157 20.1569L13.3183 18.4765C13.3182 18.4765 13.3181 18.4764 13.318 18.4764C12.9177 18.2393 12.4374 18.1438 11.9993 18.1438C11.5605 18.1438 11.0806 18.2395 10.6784 18.4735L10.6784 18.4735L10.6735 18.4764L7.834 20.1573L7.83329 20.1577C6.87626 20.7257 6.43917 20.5941 6.32904 20.5138C6.21951 20.4339 5.96134 20.0593 6.21167 18.9775L6.21169 18.9774L6.88597 16.0618L6.88598 16.0619L6.88709 16.0569C6.98279 15.6299 6.93954 15.1693 6.82525 14.77C6.71105 14.3711 6.50358 13.9554 6.19175 13.6436L3.83654 11.2884C3.23628 10.6881 3.21518 10.271 3.2728 10.0905C3.33019 9.9108 3.58811 9.58334 4.42668 9.44358L7.45578 8.94031L7.45842 8.93987C7.85719 8.87215 8.24296 8.67619 8.55236 8.44678C8.86081 8.21807 9.16263 7.90476 9.34393 7.53629L11.0135 4.19723C11.0136 4.19692 11.0138 4.19661 11.0139 4.1963C11.4154 3.398 11.8137 3.25 11.9946 3.25C12.1743 3.25 12.5703 3.39645 12.9672 4.19442L12.9679 4.19583L14.6381 7.53629C14.8196 7.90499 15.1217 8.21864 15.4323 8.44782C15.7442 8.67799 16.1326 8.87312 16.5354 8.94025Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
