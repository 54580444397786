import { apiClient } from "../..";
import { getNotificationUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getNotificationSettingsData = async () => {
	let requestData = getNotificationSettingsRequestData();
	return await postNotificationSettingsApi(requestData);
};

export const updateNotificationSettingsData = async (updatedSettingsData) => {
	let requestData =
		getUpdateNotificationSettingsRequestData(updatedSettingsData);
	return await postNotificationSettingsApi(requestData);
};

export const resetNotificationSettingsData = async () => {
	let requestData = getResetNotificationSettingsRequestData();
	return await postNotificationSettingsApi(requestData);
};

const getNotificationSettingsRequestData = () => {
	return {
		type: "get",
		data: {},
	};
};

const getUpdateNotificationSettingsRequestData = (updatedSettingsData) => {
	return {
		type: "update",
		data: {
			...updatedSettingsData,
		},
	};
};

const getResetNotificationSettingsRequestData = () => {
	return {
		type: "reset",
		data: {},
	};
};

const postNotificationSettingsApi = async (requestData) => {
	let notificationSettingsUrl = getNotificationSettingsUrl();
	let response = await apiClient.post(notificationSettingsUrl, requestData);
	assertError(response, notificationSettingsUrl);
	return response.data.data;
};

const getNotificationSettingsUrl = () => {
	return getNotificationUrl() + "/settings";
};
