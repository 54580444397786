export const metricTypes = {
	sent: "sent",

	opened: "opened",

	clicked: "clicked",

	replied: "replied",

	openedNotClicked: "openedNotClicked",

	openedNotReplied: "openedNotReplied",

	notOpened: "notOpened",

	notSent: "notSent",
};
