import { useInfiniteQuery } from "@tanstack/react-query";
import { getProductDealList } from "../../../../api/product/deals/dealsApi";
import { getProductDealListKey } from "../../../../utils/queryKeys";
import { dealListPageSize } from "../../../../utils/queryConstants/product";

const useProductDealList = (productId, dealStateIds) => {
	return useInfiniteQuery({
		queryKey: getProductDealListKey(productId, dealStateIds),
		queryFn: ({ pageParam }) => {
			return getProductDealList(productId, dealStateIds, {
				start: pageParam ? (pageParam - 1) * dealListPageSize + 1 : 1,
				limit: dealListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
	});
};

export { useProductDealList };
