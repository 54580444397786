import DataFieldsSettings from "../DataFields";
import FieldListProvider from "../DataFields/FieldListContext/FieldListProvider";

export default function DataFieldsLanding() {
	return (
		<>
			<FieldListProvider>
				<DataFieldsSettings />
			</FieldListProvider>
		</>
	);
}
