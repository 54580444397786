import React, { useMemo, useState } from "react";
import Menu from "../../../Elements/Menu";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as AddIcon } from "../../../../assets/icons/add";

export default function ProductMenu(props) {
	const {
		productList,
		value,
		onProductInputChange,
		selectedProducts,
		isNewProductsAllowed = true,
		onSelectNewProduct,
	} = props;
	const theme = useTheme();

	const [productMenuElement, setProductMenuElement] = useState(null);
	const isProductMenuOpened = Boolean(productMenuElement);
	const [searchValue, setSearchValue] = useState("");

	const openProductMenu = (event) => {
		setProductMenuElement(event.currentTarget);
	};

	const closeProductMenu = () => {
		setProductMenuElement(null);
		setSearchValue("");
	};

	const handleProductChange = (product) => {
		onProductInputChange(product);
		setProductMenuElement(null);
	};

	const handleSearchProduct = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const handleSelectNewProduct = () => {
		onSelectNewProduct(searchValue);
		closeProductMenu();
	};

	const filteredProductList = useMemo(() => {
		if (searchValue.trim()) {
			return productList?.filter((product) =>
				//filter for search
				product.name.toLowerCase().startsWith(searchValue.toLowerCase())
			);
		}
		return productList;
	}, [productList, searchValue]);

	const renderEmptyView = () => {
		if (!isNewProductsAllowed) {
			return (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="40px"
				>
					<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
						No Products Found
					</Typography>
				</Stack>
			);
		}
		return "";
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="300px"
				sx={{ "& .MuiMenu-list": { paddingTop: 0 }, marginTop: "15px" }}
				open={isProductMenuOpened}
				anchorEl={productMenuElement}
				onClose={closeProductMenu}
			>
				<Box p={0.5}>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchProduct}
					/>
				</Box>
				{filteredProductList?.length > 0
					? filteredProductList?.map((product, index) => (
							<MenuItem
								sx={{
									backgroundColor: selectedProducts.includes(
										product.value
									)
										? "rgba(51, 188, 126, 0.12)"
										: null,
								}}
								style={{ minHeight: "40px" }}
								value={product.value}
								key={index}
								onClick={() =>
									handleProductChange(product.value)
								}
							>
								<Typography fontSize={13} fontWeight={400}>
									{product.name}
								</Typography>
							</MenuItem>
						))
					: renderEmptyView()}

				{searchValue && isNewProductsAllowed ? (
					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={handleSelectNewProduct}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography
								fontSize={13}
								fontWeight={400}
								color={theme.palette.secondary.contrastText}
							>
								{searchValue}
							</Typography>

							{AddIcon(
								16,
								16,
								theme.palette.secondary.contrastText
							)}
						</Stack>
					</MenuItem>
				) : null}
			</Menu>

			<Stack sx={{ justifyContent: "center" }} onClick={openProductMenu}>
				{value ? (
					<Typography
						sx={{
							fontSize: "14px",
							fontWeight: 500,
							color: `${theme.palette.secondary.contrastText}`,
						}}
					>
						{value}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							fontWeight: 500,
							opacity: 0.6,
							color: `${theme.palette.secondary.contrastText}`,
						}}
					>
						{isNewProductsAllowed ? "Search" : "Select"}
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
