import React from "react";

function DropDown(width = "24", height = "24", color = "#000", opacity = 1) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			opacity={opacity}
			viewBox="0 0 32 32"
			style={{ fill: color }}
		>
			<path d="M6.046 13.914a1 1 0 011.414-.042l8.541 8.047 8.541-8.047a1 1 0 111.372 1.456l-9.227 8.693a1 1 0 01-1.371 0l-9.227-8.693a1 1 0 01-.042-1.414z"></path>
		</svg>
	);
}

export default DropDown;
