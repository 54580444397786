import React, { useEffect, useState } from "react";
import DealAutocomplete from "../../../../../DealAutoComplete";
import AddNewCompany from "../../../../../../Deals/AddNewDeals/AddNewCompany";
import { default as CompanyIcon } from "../../../../../../../assets/icons/company";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";
import { useCompanyList } from "../../../../../../../hooks/services/company";
import { useAuth } from "../../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../../utils/Auth";

export function CompanyOnBoardBaseComponent(props) {
	const {
		field,
		onCompanyInputChange,
		inputRef,
		value,
		error,
		clearErrors,
		onChangeFieldValueLabel,
	} = props;

	const { isUserAllowedFor } = useAuth();
	const [isAddNewCompany, setIsAddNewCompany] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState({ name: "" });

	const relatedFieldId = field?.relatedField?.id;

	const companyFormData = {
		[relatedFieldId]: selectedCompany?.name,
	};

	const isCompanyListEnabled = isUserAllowedFor(PERMISSIONS.company.view);
	const { data: companies } = useCompanyList(isCompanyListEnabled);

	useEffect(() => {
		const getSelectedCompany = (value) => {
			const selectedCompany = companies?.find(
				(company) => company.value === value
			);
			if (selectedCompany) {
				return selectedCompany;
			}
		};

		if (getSelectedCompany(value)) {
			setSelectedCompany(getSelectedCompany(value));
		}
	}, [value, companies]);

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	const updateCompanyValue = (companyValue) => {
		setSelectedCompany(companyValue ? companyValue : { name: "" });
		onCompanyInputChange(companyValue?.value ?? "");
		onChangeFieldValueLabel
			? onChangeFieldValueLabel({
					[field?.id]: companyValue?.name ?? "",
				})
			: null;
	};

	const handleSelectedCompany = (value) => {
		if (!value) {
			return;
		}
		let selectedCompany = { ...value };

		if (selectedCompany?.isNewOption) {
			delete selectedCompany?.isNewOption;
			setSelectedCompany(selectedCompany);
			setIsAddNewCompany(true);
		} else {
			updateCompanyValue(selectedCompany);
		}
		onClearErrors();
	};

	const getInputAdornmentIcon = () => {
		return CompanyIcon;
	};

	const handleRemoveCompanyInput = () => {
		updateCompanyValue();
	};

	const onAddNewCompany = (formSubmitData) => {
		removeFieldsWithEmptyValues(formSubmitData);
		onCompanyInputChange(formSubmitData);
		setIsAddNewCompany(false);

		let companyValue = {
			...selectedCompany,
			name: formSubmitData[relatedFieldId],
		};
		onChangeFieldValueLabel({
			[field?.id]: formSubmitData[relatedFieldId],
		});
		setSelectedCompany(companyValue);
		onClearErrors();
	};

	const onCloseAddNewCompanyForm = () => {
		setIsAddNewCompany(false);
		setSelectedCompany({ name: "" });
	};

	return (
		<React.Fragment>
			{isAddNewCompany ? (
				<AddNewCompany
					onSubmit={onAddNewCompany}
					onClose={onCloseAddNewCompanyForm}
					companyFormData={companyFormData}
				/>
			) : (
				<DealAutocomplete
					inputRef={inputRef}
					error={error}
					options={companies || []}
					value={selectedCompany}
					onClearErrors={onClearErrors}
					isAllowNewOption={isUserAllowedFor(
						PERMISSIONS.company.create
					)}
					onChange={(_, value) => handleSelectedCompany(value)}
					getInputAdornmentIcon={getInputAdornmentIcon}
					handleRemoveInput={handleRemoveCompanyInput}
					placeHolder={field?.placeHolder}
					noOptionsText="No Results Found"
				/>
			)}
		</React.Fragment>
	);
}
