import { Divider, IconButton, Stack, useTheme } from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as TickIcon } from "../../../assets/icons/tick";

export default function FieldEditActions(params) {
	const { error, onClose, onSave, isSaving } = params;
	const theme = useTheme();

	return (
		<>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				p={0}
				sx={{
					width: "73px%",
					height: "37px",
					borderRadius: "0px 8px 8px 0px",
					marginLeft: "-2px",
					zIndex: 1000,
					marginTop: "4px",
					borderLeftWidth: 0,
					border: `1px solid ${
						error
							? theme.palette.error.main
							: theme.palette.primary.main
					}`,
					borderLeft: "none",
					backgroundColor: "#fff",
				}}
			>
				<IconButton
					disabled={isSaving}
					style={{ opacity: 0.6 }}
					onClick={onClose}
				>
					{CloseIcon(20, 20)}
				</IconButton>

				<Divider orientation="vertical" sx={{ height: "21px" }} />

				<IconButton disabled={isSaving} onClick={onSave}>
					{TickIcon(20, 20, theme.palette.primary.main)}
				</IconButton>
			</Stack>
		</>
	);
}
