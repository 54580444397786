import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getDealCompletedActivityKey,
	getDealToDoActivityKey,
} from "../../../../utils/queryKeys";
import {
	deleteActivity,
	getActivitiesData,
	partialUpdateActivity,
} from "../../../../api/activity/activityApi";
import { modules } from "../../../../utils/common/ModulesName";
import {
	completedActivityListPageSize,
	todoActivityListPageSize,
} from "../../../../utils/queryConstants/summary/activity";

let dealSource = modules.DEAL;

const activitiesSelect = (todoActivityData) => {
	return todoActivityData.pages.flatMap((page) => page?.activities);
};
const useDealToDoActivities = (dealId, activityTypeIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getDealToDoActivityKey(dealId, activityTypeIdsToFilter),
		queryFn: ({ pageParam }) => {
			return getActivitiesData(
				dealId,
				false,
				dealSource,
				activityTypeIdsToFilter,
				{
					start: pageParam
						? (pageParam - 1) * todoActivityListPageSize + 1
						: 1,
					limit: todoActivityListPageSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

const useDealCompletedActivities = (dealId, activityTypeIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getDealCompletedActivityKey(dealId, activityTypeIdsToFilter),
		queryFn: ({ pageParam }) => {
			return getActivitiesData(
				dealId,
				true,
				dealSource,
				activityTypeIdsToFilter,
				{
					start: pageParam
						? (pageParam - 1) * completedActivityListPageSize + 1
						: 1,
					limit: completedActivityListPageSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

//Invalidate DealToDoActivities List
const useInvalidateDealToDoActivities = (dealId) => {
	let queryClient = useQueryClient();
	const dealToDoActivityKey = getDealToDoActivityKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealToDoActivityKey);
	};
};

//Invalidate DealCompletedActivities List
const useInvalidateDealCompletedActivities = (dealId) => {
	let queryClient = useQueryClient();
	const dealCompletedActivityKey = getDealCompletedActivityKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealCompletedActivityKey);
	};
};

//delete Activity mutation
const useDeleteActivity = (dealId) => {
	let invalidateToDoActivities = useInvalidateDealToDoActivities(dealId);
	let invalidateCompletedActivities =
		useInvalidateDealCompletedActivities(dealId);
	return useMutation(deleteActivity, {
		onSuccess: () => {
			invalidateCompletedActivities();
			invalidateToDoActivities();
		},
	});
};

//update Activity Status
const useUpdateActivityStatus = (dealId) => {
	let invalidateToDoActivities = useInvalidateDealToDoActivities(dealId);
	let invalidateCompletedActivities =
		useInvalidateDealCompletedActivities(dealId);
	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateCompletedActivities();
			invalidateToDoActivities();
		},
	});
};

export {
	useDealToDoActivities,
	useDealCompletedActivities,
	useInvalidateDealToDoActivities,
	useInvalidateDealCompletedActivities,
	useDeleteActivity,
	useUpdateActivityStatus,
};
