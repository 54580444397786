import React from "react";

function Book(width = 20, height = 20, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.3332 2.89174C18.3332 1.89174 17.5165 1.15008 16.5248 1.23341H16.4748C14.7248 1.38341 12.0665 2.27508 10.5832 3.20841L10.4415 3.30008C10.1998 3.45008 9.79984 3.45008 9.55817 3.30008L9.34984 3.17508C7.8665 2.25008 5.2165 1.36674 3.4665 1.22508C2.47484 1.14174 1.6665 1.89174 1.6665 2.88341V12.9501C1.6665 13.7501 2.3165 14.5001 3.1165 14.6001L3.35817 14.6334C5.1665 14.8751 7.95817 15.7917 9.55817 16.6667L9.5915 16.6834C9.8165 16.8084 10.1748 16.8084 10.3915 16.6834C11.9915 15.8001 14.7915 14.8751 16.6082 14.6334L16.8832 14.6001C17.6832 14.5001 18.3332 13.7501 18.3332 12.9501V2.89174Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 3.5752V16.0752"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.45801 6.0752H4.58301"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.08301 8.5752H4.58301"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Book;
