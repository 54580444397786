import React, { useState } from "react";
import {
	Avatar,
	Box,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as MailIcon } from "../../../assets/icons/mail";
import LinkedinLogo from "../../../assets/images/socialMedia/linkedin.png";
import FacebookLogo from "../../../assets/images/socialMedia/facebook.png";
import ContactImage from "../../../assets/images/contact/unknownContact.png";
import Menu from "../Menu";

const styles = {
	socialMediaLogoContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50px",
		width: 20,
		height: 20,
		cursor: "pointer",
	},
};

export default function ContactCard(props) {
	const { contactDetails } = props;
	const navigate = useNavigate();
	const theme = useTheme();
	const [emailMenuElement, setEmailMenuElement] = useState(null);
	const isEmailMenuOpened = Boolean(emailMenuElement);
	const [phoneMenuElement, setPhoneMenuElement] = useState(null);
	const isPhoneMenuOpened = Boolean(phoneMenuElement);

	const openEmailMenu = (event) => {
		setEmailMenuElement(event.currentTarget);
	};

	const closeEmailMenu = () => {
		setEmailMenuElement(null);
	};

	const openPhoneMenu = (event) => {
		setPhoneMenuElement(event.currentTarget);
	};

	const closePhoneMenu = () => {
		setPhoneMenuElement(null);
	};

	const navigateToContactDetails = () => {
		navigate(
			`/contacts/${contactDetails?.contactId || contactDetails?.id}`
		);
	};

	const navigateToCompanyDetails = () => {
		navigate(`/companies/${contactDetails?.company?.id}`);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="150px"
				anchorEl={emailMenuElement}
				open={isEmailMenuOpened}
				onClose={closeEmailMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{contactDetails?.emails?.map((email) => (
					<MenuItem key={email?.id}>
						<Typography
							fontSize={14}
							fontWeight={500}
							color={theme.palette.primary.main}
						>
							{email?.value}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="150px"
				anchorEl={phoneMenuElement}
				open={isPhoneMenuOpened}
				onClose={closePhoneMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{contactDetails?.phones?.map((phone) => (
					<MenuItem key={phone?.id}>
						<Typography
							fontSize={14}
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							{phone?.number}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Box px={2} py={1} sx={{ cursor: "pointer" }}>
				<Stack direction="row" spacing={2}>
					<Avatar
						alt="contact"
						src={ContactImage}
						sx={{ width: 45, height: 45 }}
					/>

					<Stack>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Box onClick={navigateToContactDetails}>
								<Typography
									fontSize={18}
									fontWeight={600}
									color={theme.palette.secondary.contrastText}
								>
									{contactDetails?.fullName}
								</Typography>
							</Box>

							<Box
								style={{
									...styles.socialMediaLogoContainer,
									backgroundColor: "#225982",
									opacity: contactDetails?.linkedIn ? 1 : 0.6,
								}}
							>
								<img
									src={LinkedinLogo}
									alt={"linkedin"}
									width="12px"
									height="12px"
								/>
							</Box>

							<Box
								style={{
									...styles.socialMediaLogoContainer,
									backgroundColor: "#4267B2",
									opacity: contactDetails?.facebook ? 1 : 0.6,
								}}
							>
								<img
									src={FacebookLogo}
									alt={"facebook"}
									width="12px"
									height="12px"
								/>
							</Box>
						</Stack>

						{contactDetails?.company ? (
							<Box onClick={navigateToCompanyDetails}>
								<Typography
									fontSize={14}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									{contactDetails?.company?.name}
								</Typography>
							</Box>
						) : null}

						{contactDetails?.phones?.length > 0 ? (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								pt={1}
								onClick={
									contactDetails?.phones?.length > 1
										? openPhoneMenu
										: null
								}
							>
								{CallIcon(18, 18, theme.palette.primary.main)}

								<Typography
									fontSize={14}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									{contactDetails?.phones?.[0]?.number} &nbsp;
									{contactDetails?.phones?.length > 1 &&
										`+${
											contactDetails?.phones?.length - 1
										}`}
								</Typography>
							</Stack>
						) : null}

						{contactDetails?.emails?.length > 0 ? (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								pt={0.5}
								onClick={
									contactDetails?.emails?.length > 1
										? openEmailMenu
										: null
								}
							>
								{MailIcon(18, 18, theme.palette.primary.main)}

								<Typography
									fontSize={14}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									{contactDetails?.emails?.[0]?.value} &nbsp;
									{contactDetails?.emails?.length > 1 &&
										`+${
											contactDetails?.emails?.length - 1
										}`}
								</Typography>
							</Stack>
						) : null}
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
