import React from "react";
import EmailList from "../EmailList";
import { mailListName } from "../../../utils/EmailUtils/mailListName";

export default function Drafts() {
	const menuConfig = {
		isDiscardDraftsEnabled: true,
		isDeleteEnabled: true,
	};

	const filterConfig = {
		isLinkingFilterEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Drafts"
				mailListName={mailListName.DRAFT}
				filterConfig={filterConfig}
				menuConfig={menuConfig}
			/>
		</React.Fragment>
	);
}
