import React, { useState } from "react";
import {
	Badge,
	Box,
	Divider,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as sortIcon } from "../../../assets/icons/sort";
import Menu from "../../Elements/Menu";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import TooltipComponent from "../../Elements/TooltipComponent";

export default function BoardHeading(props) {
	const {
		forecastView,
		name,
		amount,
		dealSize,
		symbol,
		isSummaryLoading = false,
	} = props;
	const theme = useTheme();
	const [hoveredSortOption, setHoveredSortOption] = useState(null);
	const [sortIconElement, setSortIconElement] = useState(null);
	const sortMenu = Boolean(sortIconElement);
	const [selectedSortOption, setSelectedSortOption] = useState({});

	const { isUserAllowedFor } = useAuth();

	const sortOptions = [
		{
			id: "deal_title",
			name: "Deal Title",
			hasPermissionToAccess: true,
		},
		{
			id: "contact_name",
			name: "Contact Name",
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			id: "company_name",
			name: "Company Name",
			hasPermissionToAccess: true,
		},
		{
			id: "deal_value",
			name: "Deal Value",
			hasPermissionToAccess: true,
		},
		{
			id: "sales_owner",
			name: "Sales Owner",
			hasPermissionToAccess: true,
		},
		{
			id: "closing_date",
			name: "Closing Date",
			hasPermissionToAccess: true,
		},
	];

	const onMouseOverSortOption = (id) => setHoveredSortOption(id);
	const onMouseOutSortOption = () => setHoveredSortOption(null);

	const OpenSortMenu = (event) => {
		setSortIconElement(event.currentTarget);
	};

	const CloseSortMenu = () => {
		setSortIconElement(null);
	};

	const handleSortOption = (sortOption, value) => {
		setSelectedSortOption((selectedSortOption) => {
			if (
				!!selectedSortOption[sortOption] &&
				selectedSortOption[sortOption] === value
			) {
				delete selectedSortOption[sortOption];
			} else {
				selectedSortOption[sortOption] = value;
			}
			return { ...selectedSortOption };
		});
	};

	const getSortOptions = () => {
		return sortOptions.filter(
			(sortOption) => sortOption.hasPermissionToAccess
		);
	};

	return (
		<React.Fragment>
			<Box p={1.5} width="100%">
				<Stack alignItems={forecastView && "center"}>
					<Stack direction="row" justifyContent="space-between">
						<TooltipComponent
							title={name}
							placement="top"
							width="350px"
						>
							<Typography
								fontSize="14px"
								fontWeight={500}
								noWrap
								maxWidth="350px"
								sx={{ cursor: "pointer" }}
							>
								{name}
							</Typography>
						</TooltipComponent>

						{!forecastView && (
							<Box
								style={{
									display: "flex",
									cursor: "pointer",
								}}
								onClick={OpenSortMenu}
							>
								<Badge
									color="primary"
									badgeContent={
										Object.keys(selectedSortOption).length
									}
								>
									{sortIcon(
										22,
										22,
										Object.keys(selectedSortOption).length >
											0
											? theme.palette.primary.main
											: "rgba(0, 0, 0, 0.6)"
									)}
								</Badge>
							</Box>
						)}
					</Stack>

					{isSummaryLoading ? (
						<Skeleton width="45%" height="19.5px" />
					) : (
						<Stack direction="row" spacing={1}>
							<Typography fontSize="13px">
								{symbol}
								{amount % 1 === 0
									? amount?.toLocaleString()
									: amount}
							</Typography>
							<Typography fontSize="13px">&#8226;</Typography>
							<Typography
								fontSize="13px"
								style={{ color: "rgba(0, 0, 0, 0.6)" }}
							>
								{dealSize} {dealSize > 1 ? "Deals" : "Deal"}
							</Typography>
						</Stack>
					)}
				</Stack>
			</Box>

			<Box>
				<Menu
					width="290px"
					anchorEl={sortIconElement}
					open={sortMenu}
					onClose={CloseSortMenu}
					transformOrigin={{
						vertical: "top",
						horizontal: 264,
					}}
				>
					<Box p={1} style={{ textAlign: "center" }}>
						<Typography fontSize={14} fontWeight={500}>
							Sort By
						</Typography>
					</Box>

					<Box p={0.5}>
						<MenuSearchBar />
					</Box>

					{getSortOptions().map((sortOption) => (
						<Box
							key={sortOption.id}
							px={2}
							py={0.5}
							style={{
								height: "40px",
								cursor: "pointer",
								backgroundColor:
									hoveredSortOption === sortOption.id ||
									!!selectedSortOption[sortOption.id]
										? "#F4F5F5"
										: "inherit",
							}}
							onMouseOver={() =>
								onMouseOverSortOption(sortOption.id)
							}
							onMouseOut={() => onMouseOutSortOption()}
						>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								width="100%"
								height="100%"
							>
								<Typography fontSize={13}>
									{sortOption.name}
								</Typography>

								{(hoveredSortOption === sortOption.id ||
									!!selectedSortOption[sortOption.id]) && (
									<Stack direction="row" spacing={1}>
										<Box
											onClick={() =>
												handleSortOption(
													sortOption.id,
													"asc"
												)
											}
										>
											<Typography
												fontSize={12}
												fontWeight={500}
												color={
													selectedSortOption[
														sortOption.id
													] === "asc"
														? "#000"
														: "rgba(0, 0, 0, 0.4)"
												}
											>
												Asc
											</Typography>
										</Box>

										<Divider
											orientation="vertical"
											style={{ height: "20px" }}
										/>

										<Box
											onClick={() =>
												handleSortOption(
													sortOption.id,
													"desc"
												)
											}
										>
											<Typography
												fontSize={12}
												fontWeight={500}
												color={
													selectedSortOption[
														sortOption.id
													] === "desc"
														? "#000"
														: "rgba(0, 0, 0, 0.4)"
												}
											>
												Desc
											</Typography>
										</Box>
									</Stack>
								)}
							</Stack>
						</Box>
					))}
				</Menu>
			</Box>
		</React.Fragment>
	);
}
