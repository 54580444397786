import React from "react";
import AddForm from "../../Elements/AddForm";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import { useCreateMutation } from "../../../hooks/services/company";
import { useCompanyFieldPermissions } from "../../../hooks/modules/auth/company";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../hooks/modules";

export default function AddCompany(props) {
	const { onClose, formData } = props;

	const { getModuleName } = useModuleName();
	const moduleName = modules.COMPANY;
	const formHeaderLabel = `Add ${getModuleName(moduleName)}`;

	const createMutation = useCreateMutation();

	const companyFieldPermissions = useCompanyFieldPermissions();

	const createNewCompany = (formSubmitData, shouldCloseForm) => {
		removeFieldsWithEmptyValues(formSubmitData);
		createMutation.mutate(formSubmitData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.companyAdded,
				});
				shouldCloseForm && onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={companyFieldPermissions}>
				<AddForm
					moduleName={moduleName}
					onClose={onClose}
					formData={formData}
					isSaving={createMutation.isLoading}
					onSave={createNewCompany}
					formHeaderLabel={formHeaderLabel}
				></AddForm>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
