import React, { useEffect, useMemo, useState } from "react";
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	IconButton,
	Paper,
	Popper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import {
	TableCellText,
	TableHeaderLabel,
	hexToRgba,
} from "../../../../../styles/twozo";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import team from "../../../../../assets/images/contact/multipleContact.png";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import { useTeamUsersAndUsersList } from "../../../../../hooks/services/userManagement/territory";

const PopperComponent = (props) => {
	return <Popper {...props} sx={{ paddingTop: "10px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
				width: "300px",
			}}
		/>
	);
};

export default function AddTerritoryDialog(props) {
	const {
		title,
		onClose,
		onSave,
		onUpdate,
		isSaving,
		isLoadingTerritoryData,
		territoryData,
		isEditMode,
	} = props;

	const theme = useTheme();
	const [description, setDescription] = useState("");
	const [territoryName, setTerritoryName] = useState("");

	const [searchValue, setSearchValue] = useState("");

	// query call
	const { data: userList, isLoading: isLoadingUserList } =
		useTeamUsersAndUsersList();

	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [territoryUsersList, setTerritoryUsersList] = useState([]);
	const [selectedTerritoryId, setSelectedTerritoryId] = useState(null);
	const [isAllUserSelected, setIsAllUserSelected] = useState(false);
	const territoryUsers = territoryUsersList.filter(
		(list) => !list?.isUserNotAdded
	);

	const filteredUserListData = useMemo(() => {
		const usersAndTeamUsersList = [
			...(userList?.users?.map((user) => ({ ...user, group: "Users" })) ??
				[]),
			...(userList?.teams?.map((team) => ({ ...team, group: "Teams" })) ??
				[]),
		];

		let selectedUsersId = territoryUsersList.map((user) => user.id);

		let filteredUserList = usersAndTeamUsersList.filter(
			(user) => !selectedUsersId.includes(user.id)
		);

		return filteredUserList;
	}, [territoryUsersList, userList]);

	const filterUnaddedUsers = useMemo(() => {
		return territoryUsersList.filter((list) => list?.isUserNotAdded);
	}, [territoryUsersList]);

	const isUserAdditionDisabled =
		filterUnaddedUsers.length > 0 || filteredUserListData.length === 0;

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const handleTableCell = (territory) => {
		setSelectedTerritoryId(territory.id);
	};

	useEffect(() => {
		if (territoryData) {
			setTerritoryName(territoryData.name);
			setDescription(territoryData.description);
			setTerritoryUsersList(territoryData.users);
		}
	}, [territoryData]);

	const handleChangeTerritoryName = (event) => {
		const { value } = event.target;
		setTerritoryName(value);
	};

	const handleChangeDescription = (event) => {
		const { value } = event.target;
		setDescription(value);
	};

	const handleChangeAddUser = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const addTerritoryUser = () => {
		const territoryUsersListLength = territoryUsersList.length;

		const territoryUser = {
			id: `id ${territoryUsersListLength + 1}`,
			name: "",
			email: "-",
			role: {
				name: "-",
			},
			isUserNotAdded: true,
		};

		setTerritoryUsersList([...territoryUsersList, territoryUser]);
	};

	const deleteTerritoryMember = (index) => {
		setTerritoryUsersList((territoryUsersList) => {
			let updatedTerritoryUsersList = [...territoryUsersList];
			updatedTerritoryUsersList.splice(index, 1);
			return updatedTerritoryUsersList;
		});
	};

	const updateSelectedUsersId = (userList) => {
		let newSelectedUserIds = userList
			.filter((user) => !user.isUserNotAdded)
			.map((user) => user.id);
		setSelectedUserIds(newSelectedUserIds);
	};

	const selectUser = (data, selectedIndex) => {
		if (data?.group === "Teams") {
			let selectedUsersId = territoryUsersList.map((user) => user.id);

			const filteredTeamsUserList = data.users.filter(
				(user) => !selectedUsersId.includes(user.id)
			);

			const filteredEmptyUserList = territoryUsersList.filter(
				(user) => user.name !== ""
			);

			let filteredUserList = [
				...filteredEmptyUserList,
				...filteredTeamsUserList,
			];

			setTerritoryUsersList(filteredUserList);

			if (isAllUserSelected) {
				updateSelectedUsersId(filteredUserList);
			}
		} else {
			let updatedTerritoryUserList = territoryUsersList.map(
				(member, index) => {
					if (index === selectedIndex) {
						return {
							...member,
							name: data.name,
							email: data.email,
							role: {
								...member.role,
								name: data.role.name,
								id: data.role.id,
							},
							id: data.id || data.value,
							isUserNotAdded: false,
						};
					}

					return member;
				}
			);
			setTerritoryUsersList(updatedTerritoryUserList);

			if (isAllUserSelected) {
				updateSelectedUsersId(updatedTerritoryUserList);
			}
		}
		setSearchValue("");
		setSelectedTerritoryId(null);
	};

	const handleSelectAllUsers = (event) => {
		if (event.target.checked) {
			const newSelected = territoryUsersList
				.filter((territoryMember) => !territoryMember.isUserNotAdded)
				.map((territoryMember) => territoryMember.id);
			setSelectedUserIds(newSelected);
			setIsAllUserSelected(true);
		} else {
			setSelectedUserIds([]);
			setIsAllUserSelected(false);
		}
	};

	const isUserSelected = (id) => selectedUserIds.indexOf(id) !== -1;

	const handleSelectUser = (event, id) => {
		event.stopPropagation();
		const selectedIndex = selectedUserIds?.indexOf(id);
		let newSelectedIds = [];

		if (selectedIndex === -1) {
			newSelectedIds = newSelectedIds.concat(selectedUserIds, id);
		} else {
			newSelectedIds = [
				...selectedUserIds.slice(0, selectedIndex),
				...selectedUserIds.slice(selectedIndex + 1),
			];
		}
		setSelectedUserIds(newSelectedIds);
	};

	const removeMultipleUserFromTerritory = () => {
		let remainingUsers = territoryUsersList.filter(
			(list) => !selectedUserIds.includes(list.id)
		);
		setTerritoryUsersList(remainingUsers);
		setSelectedUserIds([]);
	};

	const getCountOfTerritoryUsers = (territoryUsers) => {
		let singleDigitCount = 9;
		if (
			territoryUsers.length > singleDigitCount ||
			territoryUsers.length === 0
		) {
			return territoryUsers.length;
		}
		return `0${territoryUsers.length}`;
	};

	const getFilteredTerritoryUsers = () => {
		let filteredUsersList = territoryUsersList
			.filter((user) => user.name !== "")
			.map((user) => user.id);
		return filteredUsersList;
	};

	const getCreateRequestData = () => {
		let requestData = {};
		requestData.name = territoryName;
		requestData.description = description;
		requestData.users = getFilteredTerritoryUsers();
		return requestData;
	};

	const getUpdateRequestData = () => {
		let requestData = { ...territoryData };
		requestData.name = territoryName;
		requestData.description = description;
		requestData.users = getFilteredTerritoryUsers();
		return requestData;
	};

	const handleSave = () => {
		if (isValidTerritory()) {
			let requestData = getCreateRequestData();
			onSave(requestData);
		}
	};

	const handleUpdate = () => {
		if (isValidTerritory()) {
			let requestData = getUpdateRequestData();
			onUpdate(requestData);
		}
	};

	const isValidTerritory = () => {
		let filteredTeamMemberList = territoryUsersList.filter(
			(teamMember) => teamMember.name !== ""
		);
		return territoryName.trim() !== "" && filteredTeamMemberList.length > 0;
	};

	const renderGroup = (params) => {
		return (
			<Box key={params.key}>
				<Box fontSize={13} fontWeight={500} pl={2} pt={1} py={1}>
					{params.group}
				</Box>

				{params.children.map((child, index) => (
					<React.Fragment key={`child-${params.key}-${index}`}>
						<Box>{child}</Box>
					</React.Fragment>
				))}

				<Box pt={0.5}>
					<Divider
						sx={{
							display:
								params.group === "Users" ? "block" : "none",
						}}
					/>
				</Box>
			</Box>
		);
	};

	const renderInput = (params) => {
		return (
			<TextField
				{...params}
				variant="standard"
				InputProps={{
					...params.InputProps,
					disableUnderline: true,
					style: {
						fontSize: "14px",
						fontWeight: 400,
					},
				}}
				placeholder="Search"
				value={searchValue}
				onBlur={() => setSearchValue("")}
				onChange={handleChangeAddUser}
			/>
		);
	};

	const renderOptions = (props, option, index) => {
		return (
			<Box>
				<Box
					{...props}
					sx={{
						height: "40px",
					}}
					key={index}
					onClick={() => selectUser(option, index)}
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						{option?.group === "Teams" ? (
							<img
								src={team}
								alt="img"
								width="26px"
								height="26px"
							/>
						) : (
							<img
								src={UnKnownImage}
								alt="img"
								width="26px"
								height="26px"
							/>
						)}
						<Typography fontSize={13} fontWeight={400}>
							{option.name}
						</Typography>
					</Stack>
				</Box>{" "}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={16} fontWeight={600}>
							{title}
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				{isLoadingTerritoryData ? (
					<Stack
						height="90vh"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress />
					</Stack>
				) : (
					<Box
						px={2}
						py={2}
						style={{
							flex: "1 1 auto",
							minHeight: 0,
						}}
					>
						<Box
							height="100%"
							style={{
								display: "flex",
								flex: "1 1 auto",
								flexDirection: "column",
							}}
						>
							<Box pb={selectedUserIds.length >= 1 ? 2 : 3}>
								<Stack pb={1}>
									<Typography
										fontSize={13}
										fontWeight={500}
										padding="5px"
									>
										Territory Name{" "}
										<span
											style={{
												color: theme.palette.error.main,
											}}
										>
											*
										</span>
									</Typography>

									<TextField
										value={territoryName}
										onChange={handleChangeTerritoryName}
										placeholder="Eg: USA"
									/>
								</Stack>

								<Stack>
									<Typography
										fontSize={13}
										fontWeight={500}
										padding="5px"
									>
										Description
									</Typography>

									<TextField
										placeholder="Details like location and scope for business"
										multiline
										value={description}
										onChange={handleChangeDescription}
										rows={3}
									/>
								</Stack>
							</Box>

							{selectedUserIds.length > 0 ? (
								<Box pb={2}>
									<Stack
										alignItems="flex-end"
										justifyContent="center"
									>
										<Typography
											fontSize={13}
											fontWeight={600}
											color={theme.palette.primary.main}
											borderRadius="8px"
											bgcolor={
												theme.palette.secondary.main
											}
											style={{
												padding: "4px 8px",
												cursor: "pointer",
											}}
											onClick={
												removeMultipleUserFromTerritory
											}
										>
											Remove from Territory (
											{getCountOfTerritoryUsers(
												selectedUserIds
											)}
											)
										</Typography>
									</Stack>
								</Box>
							) : null}

							<Box
								sx={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									overflowY: "auto",
									flex: "1 1 auto",
								}}
							>
								<TableContainer>
									<Table size="small">
										<TableHead>
											<TableRow style={{ width: "100%" }}>
												<TableCell
													padding="checkbox"
													style={{ width: "10%" }}
												>
													<Stack
														alignItems="center"
														justifyContent="center"
													>
														<Checkbox
															indeterminate={
																selectedUserIds.length >
																	0 &&
																selectedUserIds.length <
																	territoryUsers.length
															}
															checked={
																territoryUsers.length >
																	0 &&
																selectedUserIds.length ===
																	territoryUsers.length
															}
															onChange={
																handleSelectAllUsers
															}
														/>
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "30%" }}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															User
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "30%" }}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															Email
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "20%" }}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<TableHeaderLabel>
															Role
														</TableHeaderLabel>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.5)"
														)}
													</Stack>
												</TableCell>

												<TableCell
													style={{ width: "10%" }}
												></TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											<TableRow style={{ width: "100%" }}>
												<TableCell
													style={{ width: "10%" }}
												></TableCell>

												<TableCell colSpan={4}>
													<Typography
														fontWeight={500}
														fontSize={13}
														color={
															theme.palette
																.secondary
																.contrastText
														}
														onClick={
															addTerritoryUser
														}
														style={{
															pointerEvents:
																isUserAdditionDisabled
																	? "none"
																	: "auto",
															cursor: isUserAdditionDisabled
																? "not-allowed"
																: "pointer",
															opacity:
																isUserAdditionDisabled
																	? 0.6
																	: 1,
														}}
													>
														+ Add Users or Teams
													</Typography>
												</TableCell>
											</TableRow>

											{Array.isArray(
												territoryUsersList
											) &&
												territoryUsersList.map(
													(
														territoryMember,
														index
													) => (
														<TableRow
															key={index}
															selected={isUserSelected(
																territoryMember.id
															)}
															onMouseOver={() =>
																onMouseOverTableRow(
																	territoryMember.id
																)
															}
															onMouseOut={
																onMouseOutTableRow
															}
														>
															<TableCell
																padding="checkbox"
																style={{
																	width: "10%",
																}}
															>
																<Stack
																	alignItems="center"
																	justifyContent="center"
																>
																	{territoryMember.isUserNotAdded ? (
																		<Stack
																			alignItems="center"
																			onClick={() =>
																				deleteTerritoryMember(
																					index
																				)
																			}
																		>
																			{CloseIcon(
																				20,
																				20,
																				"rgba(0, 0, 0, 0.6)"
																			)}
																		</Stack>
																	) : (
																		<Checkbox
																			checked={isUserSelected(
																				territoryMember.id
																			)}
																			onClick={(
																				event
																			) =>
																				handleSelectUser(
																					event,
																					territoryMember.id
																				)
																			}
																		/>
																	)}
																</Stack>
															</TableCell>

															<TableCell
																style={{
																	borderBottom:
																		selectedTerritoryId ===
																		territoryMember.id
																			? `2px solid ${theme.palette.primary.main}`
																			: null,
																	width: "30%",
																}}
																onClick={() =>
																	handleTableCell(
																		territoryMember
																	)
																}
															>
																{territoryMember.isUserNotAdded ? (
																	<Box>
																		<Autocomplete
																			open={
																				searchValue.trim()
																					.length >
																				0
																			}
																			loading={
																				isLoadingUserList
																			}
																			PopperComponent={
																				PopperComponent
																			}
																			PaperComponent={
																				CustomPaper
																			}
																			forcePopupIcon={
																				false
																			}
																			disableClearable
																			autoHighlight={
																				true
																			}
																			options={
																				filteredUserListData ||
																				[]
																			}
																			groupBy={(
																				option
																			) =>
																				option
																					.users
																					?.length >
																				0
																					? "Teams"
																					: "Users"
																			}
																			renderGroup={
																				renderGroup
																			}
																			getOptionLabel={(
																				option
																			) =>
																				option.name
																			}
																			loadingText={
																				<Stack
																					justifyContent="center"
																					alignItems="center"
																				>
																					<CircularProgress size="20px" />
																				</Stack>
																			}
																			renderOption={(
																				props,
																				option
																			) =>
																				renderOptions(
																					props,
																					option,
																					index
																				)
																			}
																			renderInput={(
																				params
																			) =>
																				renderInput(
																					params,
																					territoryMember.name
																				)
																			}
																		/>
																	</Box>
																) : (
																	<Stack
																		direction="row"
																		alignItems="center"
																		spacing={
																			1
																		}
																	>
																		<img
																			src={
																				UnKnownImage
																			}
																			alt="img"
																			width="26px"
																			height="26px"
																		/>
																		<TableCellText>
																			{
																				territoryMember.name
																			}
																		</TableCellText>
																	</Stack>
																)}
															</TableCell>

															<TableCell
																style={{
																	width: "30%",
																}}
															>
																<TableCellText>
																	{
																		territoryMember.email
																	}
																</TableCellText>
															</TableCell>

															<TableCell
																style={{
																	width: "20%",
																}}
															>
																<TableCellText>
																	{
																		territoryMember
																			.role
																			.name
																	}
																</TableCellText>
															</TableCell>

															<TableCell
																sx={{
																	py: 0,
																	pl: 0,
																	pr: 1,
																	width: "10%",
																}}
															>
																{!territoryMember.isUserNotAdded &&
																	!selectedUserIds.includes(
																		tableRowHovered
																	) &&
																	tableRowHovered ===
																		territoryMember.id && (
																		<Stack alignItems="flex-end">
																			<Box
																				style={{
																					width: "fit-content",
																					border: `1px solid ${theme.palette.primary.main}`,
																					borderRadius:
																						"8px",
																					padding:
																						"2px 8px",
																				}}
																			>
																				<IconButton
																					size="small"
																					onClick={() =>
																						deleteTerritoryMember(
																							index
																						)
																					}
																				>
																					{DeleteIcon(
																						20,
																						20,
																						theme
																							.palette
																							.error
																							.main
																					)}
																				</IconButton>
																			</Box>
																		</Stack>
																	)}
															</TableCell>
														</TableRow>
													)
												)}
										</TableBody>
									</Table>

									<Stack
										direction="row"
										justifyContent="flex-end"
										py={1}
										px={2}
										sx={{
											position: "fixed",
											bottom: "108px",
											right: "40px",
											backgroundColor:
												theme.palette.secondary.main,
											borderRadius: "8px",
										}}
									>
										<Typography
											style={{
												fontSize: "14px",
												fontWeight: "500",
												color: "rgba(0, 0, 0, 0.6)",
											}}
										>
											Total :{" "}
											{getCountOfTerritoryUsers(
												territoryUsers
											)}
										</Typography>
									</Stack>
								</TableContainer>
							</Box>
						</Box>
					</Box>
				)}

				<Divider />

				{isEditMode ? (
					<Box p={2}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							disabled={isSaving}
							onClick={handleUpdate}
							style={{
								color: isValidTerritory()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Update
						</Button>
					</Box>
				) : (
					<Box p={2}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleSave}
							disabled={isSaving}
							style={{
								color: isValidTerritory()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Save
						</Button>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
