import React from "react";

export default function LeftArrow(
	width = "24",
	height = "24",
	color = "#000",
	opacity = 1
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
			opacity={opacity}
		>
			<path
				d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.5 12H3.67004"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
