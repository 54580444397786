import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
	Checkbox,
	TableBody,
	CircularProgress,
} from "@mui/material";
import { FormFieldName } from "../../../../styles/twozo";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { StageList } from "./StageList";
import {
	useCreatePipeline,
	useUpdatePipeline,
} from "../../../../hooks/services/deal/pipeline";
import { removeFieldsWithIdKey } from "../../../../utils/common";

export default function AddPipeline(props) {
	const {
		isPipelineInEditMode,
		editedPipelineData,
		onClose,
		isShowSaveAndNewButton,
	} = props;
	const theme = useTheme();

	// Mutation call
	const createMutation = useCreatePipeline();
	const updateMutation = useUpdatePipeline();

	const defaultRottenDaysValue = 30;
	const initialPipeline = {
		name: "",
		rottenDays: "",
		isDefault: false,
		stages: [
			{
				name: "New",
				probability: 100,
				id: "id 0",
				isEditable: true,
				isDefault: true,
			},
			{
				name: "Won",
				probability: 100,
				isEditable: false,
				isDefault: false,
			},
			{
				name: "Lost",
				probability: 0,
				isEditable: false,
				isDefault: false,
			},
		],
	};

	const getInitialPipelineData = () => {
		if (isPipelineInEditMode) {
			return editedPipelineData;
		}

		return initialPipeline;
	};

	const [pipeline, setPipeline] = useState(getInitialPipelineData());

	const styles = {
		tableContainer: {
			backgroundColor: theme.palette.primary.main + "20",
			borderRadius: "8px",
			p: 1,
		},
		tableHead: {
			fontSize: 13,
			fontWeight: 500,
			color: "#000",
			opacity: "0.6",
		},
		tableCell: {
			fontSize: 14,
		},
	};

	const handleChangePipelineName = (event) => {
		const { value } = event.target;
		setPipeline((pipeline) => {
			let updatedPipeline = { ...pipeline, name: value };
			return updatedPipeline;
		});
	};

	const handleChangeRottenDays = (event) => {
		const { value } = event.target;
		const numericValue = value.replace(/[^0-9]/g, "");
		setPipeline((pipeline) => {
			let updatedPipeline = {
				...pipeline,
				rottenDays: numericValue ? numericValue + "" : "",
			};
			return updatedPipeline;
		});
	};

	const handleChangeDefaultPipeline = (event) => {
		const { checked } = event.target;
		setPipeline((pipeline) => {
			let updatedPipeline = { ...pipeline, isDefault: checked };
			return updatedPipeline;
		});
	};

	const handleCreatePipeline = () => {
		if (pipeline.name !== "") {
			let requestData = getCreatePipelineRequest();
			createMutation.mutate(requestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.pipelineCreated,
					});
					onClose();
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ?? notificationMessage.errorMessage,
					});
				},
			});
		} else {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.pipelineErrorMessage,
			});
		}
	};

	const handleCreateAndAddNewPipeline = () => {
		if (pipeline.name !== "") {
			let requestData = getCreatePipelineRequest();
			createMutation.mutate(requestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.pipelineCreated,
					});
					setPipeline(initialPipeline);
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ?? notificationMessage.errorMessage,
					});
				},
			});
		} else {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.pipelineErrorMessage,
			});
		}
	};

	const getUpdatePipelineRequest = () => {
		let requestData = { ...pipeline };

		if (!requestData.rottenDays) {
			requestData.rottenDays = defaultRottenDaysValue;
		}

		// filter the empty stage
		const filteredPipelineStages = pipeline.stages.filter(
			(stage) => stage.name !== ""
		);
		requestData.stages = filteredPipelineStages;

		// removed new stage id
		for (let i = 0; i < requestData.stages.length; i++) {
			if (requestData.stages[i].isNewStage) {
				delete requestData.stages[i].id;
			}
		}

		return requestData;
	};

	const handleUpdatePipeline = () => {
		let requestData = getUpdatePipelineRequest();
		updateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.pipelineUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const getCreatePipelineRequest = () => {
		let requestData = { ...pipeline };

		if (!requestData.rottenDays) {
			requestData.rottenDays = defaultRottenDaysValue;
		}

		// filter the empty stage
		const filteredPipelineStages = pipeline.stages.filter(
			(stage) => stage.name !== ""
		);
		requestData.stages = filteredPipelineStages;
		removeFieldsWithIdKey(requestData.stages);

		return requestData;
	};

	const updateStages = (updatedPipelineStages) => {
		let updatedPipeline = {
			...pipeline,
			stages: updatedPipelineStages,
		};
		setPipeline(updatedPipeline);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontWeight={600}>
							{isPipelineInEditMode
								? "Edit Pipeline"
								: "Add New Pipeline"}
						</Typography>

						<IconButton
							onClick={props.onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				{createMutation.isLoading ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="90vh"
					>
						<CircularProgress />
					</Stack>
				) : (
					<Box
						p={2}
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={2}
						>
							<Grid item xs={12}>
								<Stack spacing={0.5}>
									<FormFieldName>
										Pipeline Name
										<span
											style={{
												color: theme.palette.error.main,
												marginLeft: "5px",
											}}
										>
											*
										</span>
									</FormFieldName>

									<TextField
										placeholder="Eg: Standard"
										autoFocus={isPipelineInEditMode}
										defaultValue={
											isPipelineInEditMode
												? editedPipelineData.name
												: ""
										}
										name="pipelineName"
										onChange={handleChangePipelineName}
									/>
								</Stack>
							</Grid>

							<Grid item xs={12}>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<Checkbox
										checked={pipeline.isDefault}
										onChange={handleChangeDefaultPipeline}
										value={pipeline.isDefault}
										disabled={editedPipelineData?.isDefault}
										sx={{
											opacity:
												editedPipelineData?.isDefault
													? 0.6
													: 1,
										}}
									/>
									<Typography fontSize={14} fontWeight={500}>
										Mark as Default Pipeline
									</Typography>
								</Stack>
							</Grid>

							<Grid item xs={12}>
								<Stack spacing={0.5}>
									<FormFieldName>
										Deals rot after
									</FormFieldName>

									<TextField
										placeholder="Eg: 30"
										name="pipelineName"
										defaultValue={
											isPipelineInEditMode
												? editedPipelineData.rottenDays
												: ""
										}
										value={pipeline?.rottenDays}
										onChange={handleChangeRottenDays}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Typography
														fontSize={14}
														color="#000"
													>
														Days
													</Typography>
												</InputAdornment>
											),
										}}
									/>
								</Stack>
							</Grid>

							<Grid item xs={12}>
								<Box sx={styles.tableContainer}>
									<TableContainer>
										<Table
											sx={{ width: "100%" }}
											size="small"
										>
											<TableHead>
												<TableRow>
													<TableCell
														sx={{ width: "5%" }}
													/>

													<TableCell align="left">
														<Typography
															sx={
																styles.tableHead
															}
														>
															Deal Stage
														</Typography>
													</TableCell>

													<TableCell align="right">
														<Typography
															sx={
																styles.tableHead
															}
															align="right"
														>
															Stage Probability %
														</Typography>
													</TableCell>

													<TableCell
														sx={{ width: "5%" }}
													/>
												</TableRow>
											</TableHead>
											<TableBody>
												<StageList
													stages={pipeline.stages}
													updateStages={updateStages}
												/>
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
							</Grid>
						</Grid>
					</Box>
				)}
				<Divider />

				<Box p={2}>
					{isPipelineInEditMode ? (
						<Box>
							<Button
								variant="contained"
								disableElevation
								onClick={handleUpdatePipeline}
								disabled={updateMutation.isLoading}
							>
								Update
							</Button>
						</Box>
					) : (
						<Stack direction="row" spacing={2}>
							<Box>
								<Button
									onClick={handleCreatePipeline}
									variant="contained"
									disableElevation
									disabled={createMutation.isLoading}
								>
									Save
								</Button>
							</Box>

							{isShowSaveAndNewButton && (
								<Box>
									<Button
										variant="contained"
										disableElevation
										onClick={handleCreateAndAddNewPipeline}
										disabled={createMutation.isLoading}
									>
										Save & New
									</Button>
								</Box>
							)}
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
