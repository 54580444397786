import { getProductsUrl } from "../apiConfig";
import { getTableData } from "../table/tableApi";
import { assertError } from "../apiHelper";
import { apiClient } from "..";
import postFieldsApi from "../fields/postFieldsApi";

export const getProductsTableData = async (fieldData) => {
	let listUrl = getProductsListUrl();
	let config = {
		url: listUrl,
		provider: "PRODUCT_LIST",
		fields: parseSelectFieldsForApi(fieldData),
	};

	let response = await getTableData(config);
	return response;
};

export const createProduct = async (data) => {
	let requestData = getCreateProductRequest(data);
	return await postProductManagementApi(requestData);
};

export const getProductSummaryData = async (id, hideEmptyFields) => {
	let request = getProductSummaryRequest(id, hideEmptyFields);
	return await postProductManagementApi(request);
};

export const getProductData = async (id) => {
	let request = getProductDataRequest(id);
	return await postProductManagementApi(request);
};

export const updateProductSummaryData = async (data) => {
	let request = updateProductSummaryRequest(data);
	return await postProductManagementApi(request);
};

export const deleteMultipleProducts = async (productsToDelete) => {
	let requestData = getDeleteMultipleProductsRequest(productsToDelete);
	return await postProductManagementApi(requestData);
};

export const updateProduct = async (data) => {
	let requestData = updateProductFieldRequest(data);
	return await postProductManagementApi(requestData);
};

export const deleteProduct = async (id) => {
	let requestData = getDeleteProductRequest(id);
	return await postProductManagementApi(requestData);
};

export const getProductDropdownList = async () => {
	let requestData = getProductDropdownListRequest();
	return await postFieldsApi(requestData);
};

export const assignOwnerToProducts = async ({ productIds, userId }) => {
	let requestData = assignOwnerRequest(productIds, userId);
	return await postProductManagementApi(requestData);
};

export const partialUpdateProduct = async (data) => {
	let requestData = partialUpdateProductRequest(data);
	return await postProductManagementApi(requestData);
};

const postProductManagementApi = async (requestData) => {
	let productsManagementUrl = getProductManagementUrl();
	let response = await apiClient.post(productsManagementUrl, requestData);
	assertError(response, productsManagementUrl);
	return response.data.data;
};

const getDeleteMultipleProductsRequest = (productsToDelete) => {
	return {
		type: "bDelete",
		data: {
			ids: productsToDelete,
		},
	};
};

const getDeleteProductRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const partialUpdateProductRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getProductSummaryRequest = (id, hideEmptyFields) => {
	return {
		type: "summary",
		data: {
			id: id,
			isHidden: hideEmptyFields,
		},
	};
};

const getProductDataRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const updateProductSummaryRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getCreateProductRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const updateProductFieldRequest = (data) => {
	return {
		type: "pBulkUpdate",
		data: {
			list: data,
		},
	};
};

const parseSelectFieldsForApi = () => {
	// let fields = [];

	// if (fieldData) {
	//     fieldData.forEach((field) => {
	//         fields.push(field.id);
	//     });
	// }

	//For now we aren't using this fieldData, But we will be using the same in future once the server side changes are done

	let fields = ["name", "skuNumber", "productCode", "type", "id"];

	return fields;
};

const getProductDropdownListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "productList",
		},
	};
};

const assignOwnerRequest = (productIds, userId) => {
	return {
		type: "bAssignOwner",
		data: {
			ids: productIds,
			userId: userId,
		},
	};
};

const getProductsListUrl = () => {
	return getProductsUrl() + "/list";
};

const getProductManagementUrl = () => {
	return getProductsUrl() + "/mgmt";
};
