import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DateTimeRangeBaseComponent from "./DateTimeRangeBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function DateTimeRangeComponent({
	field,
	subField,
	inputRef,
	...rest
}) {
	const {
		control,
		formState: { errors },
		clearErrors,
	} = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({ field: { ...renderField } }) => (
					<DateTimeRangeBaseComponent
						placeholder={field?.config?.placeHolder}
						{...renderField}
						error={errors[field.id]}
						clearErrors={clearErrors}
						{...rest}
						subField={subField}
						field={field}
						id={field.id + ""}
						inputRef={inputRef}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
