export const emailsSyncTypes = {
	email: "email",
	calendar: "calendar",
	contact: "contact",
};

export const emailsSyncStatus = {
	ACTIVE: "Active",
	AUTHORIZED: "Authorized",
	SYNCING: "Syncing",
};
