const permissionList = [
	{
		name: "Can View",
		value: 1,
	},
	{
		name: "Can View and Edit",
		value: 2,
	},
];

export default permissionList;
