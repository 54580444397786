import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { TableCellText } from "../../../../styles/twozo";
import Menu from "../../Menu";

export default function Email(props) {
	const { data } = props;
	const theme = useTheme();
	const [emailMenuElement, setEmailMenuElement] = useState(null);
	const openEmailMenu = Boolean(emailMenuElement);

	const OpenEmailMenu = (event) => {
		event.stopPropagation();
		setEmailMenuElement(event.currentTarget);
	};

	const CloseEmailMenu = (event) => {
		event.stopPropagation();
		setEmailMenuElement(null);
	};

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="400px"
				anchorEl={emailMenuElement}
				open={openEmailMenu}
				onClose={(e) => CloseEmailMenu(e)}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px",
				}}
			>
				<Box>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={3}
						pl={3}
						pr={2}
						py={1.5}
					>
						<TableCellText
							style={{
								color: theme.palette.primary.main,
							}}
						>
							{data?.emailValue}
						</TableCellText>

						<Typography fontSize={12} color="rgba(0, 0, 0, 0.6)">
							{data?.emailType}
						</Typography>
					</Stack>
				</Box>
			</Menu>

			<Box onClick={(e) => OpenEmailMenu(e)}>
				<TableCellText
					style={{
						color: theme.palette.primary.main,
						whiteSpace: "nowrap",
					}}
				>
					{data?.emailValue}
				</TableCellText>
			</Box>
		</React.Fragment>
	);
}
