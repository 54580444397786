import React from "react";
import { DwarfButton } from "../../../styles/twozo";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import DeleteDialog from "../../Elements/DeleteDialog";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useDeleteActivities,
	useUpdateActivityStatusFromList,
} from "../../../hooks/services/activities";
import ExportDialog from "../../Elements/Export/ExportDialog";
import CancelExportDialog from "../../Elements/Export/CancelDialog";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";

export default function SelectedMenu(props) {
	const { selected, data, deSelectAll } = props;
	const successNotificationTitle = "Success!";
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isExportDialogOpened, setIsExportDialogOpened] = useState(false);
	const [isCancelExportingDialogOpened, setIsCancelExportingDialogOpened] =
		useState(false);
	const deleteActivitiesMutation = useDeleteActivities();
	const updateActivityStatusMutation = useUpdateActivityStatusFromList();

	const handleUnSelectActivities = () => {
		deSelectAll();
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const openExportDialog = () => {
		setIsExportDialogOpened(true);
	};

	const closeExportDialog = () => {
		setIsExportDialogOpened(false);
	};

	const openCancelDialog = () => {
		setIsCancelExportingDialogOpened(true);
		closeExportDialog();
	};

	const cancelExporting = () => {
		setIsExportDialogOpened(false);
		setIsCancelExportingDialogOpened(false);
	};

	const exportFile = () => {
		openCancelDialog();
	};

	const performDeleteConfirm = () => {
		deleteActivitiesMutation.mutate(selected, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message:
						selected.length > 1
							? `${selected.length} activities deleted.`
							: notificationMessage.activityDeleted,
				});
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
		closeDeleteDialog();
	};

	const updateActivityStatus = () => {
		const updateActivityRequest = selected.map((id) => ({
			id: id,
			isDone: true,
		}));

		updateActivityStatusMutation.mutate(updateActivityRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.activityUpdated,
				});
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title="Are you sure you want to delete the selected activities?"
				subtitle="It will delete all the selected activities and the data associated with it"
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={performDeleteConfirm}
			/>

			<ExportDialog
				open={isExportDialogOpened}
				onClose={closeExportDialog}
				exportFile={exportFile}
			/>

			<CancelExportDialog
				open={isCancelExportingDialogOpened}
				onCancel={cancelExporting}
			/>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				height="100%"
			>
				<Stack direction="row" spacing={0.5} alignItems="center">
					<Typography fontSize={14} fontWeight={500}>
						{selected.length} Selected
					</Typography>

					<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
						of {data.length}
					</Typography>

					<IconButton
						disableRipple
						style={{ cursor: "pointer", padding: 0 }}
						onClick={handleUnSelectActivities}
					>
						{CloseIcon(18, 18, "rgba(0,0,0,0.6)")}
					</IconButton>
				</Stack>

				<Divider orientation="vertical" style={{ height: "20px" }} />

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
				>
					Update Field
				</DwarfButton>

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={updateActivityStatus}
				>
					Mark As Completed
				</DwarfButton>

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={openDeleteDialog}
				>
					Delete
				</DwarfButton>

				<Can permission={PERMISSIONS.activity.exportRecords}>
					<DwarfButton
						variant="contained"
						color="secondary"
						disableElevation
						onClick={openExportDialog}
					>
						Export
					</DwarfButton>
				</Can>
			</Stack>
		</React.Fragment>
	);
}
