import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
	alpha,
} from "@mui/material";
import { default as AddIcon } from "../../../../assets/icons/add";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import CreateTeam from "./CreateTeam";
import TeamsList from "./TeamsList";
import { useUserManagementContext } from "../UserManagementContext";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";
import { Tooltip } from "../../../../styles/twozo";

export default function Teams(props) {
	const {
		openCreateTeamDialog,
		closeCreateTeamDialog,
		isCreateTeamDialogOpened,
	} = props;

	const tableRef = useRef();
	const [tableStartingPosition, setTableStartingPosition] = useState(0);

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	const {
		listData: teamsListData,
		isLoadingListData: isLoadingTeamsListData,
	} = useUserManagementContext();

	const hasTeamsData =
		teamsListData &&
		teamsListData?.pages?.length > 0 &&
		teamsListData?.pages[0]?.teams &&
		teamsListData?.pages[0]?.teams?.length > 0;

	const { isUserAllowedFor } = useAuth();

	const isAddTeamButtonDisabled = !isUserAllowedFor(PERMISSIONS.team.access);
	const isLoadingTeamList =
		isLoadingTeamsListData && isUserAllowedFor(PERMISSIONS.team.access);

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isCreateTeamDialogOpened}
				onOpen={openCreateTeamDialog}
				onClose={closeCreateTeamDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<CreateTeam onClose={closeCreateTeamDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Box style={{ height: "100%" }}>
				<Box pl={1} pb={1.5}>
					<Typography color="rgba(0, 0, 0, 0.6)" fontSize={14}>
						It helps you bring together different kinds of sales
						personnel, like sales reps and account executives. This
						makes collaboration a lot easier: you can assign
						territories and share the views you create.
					</Typography>
				</Box>

				<Box
					ref={tableRef}
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: `calc(100vh - ${tableStartingPosition + 8}px)`,
						overflowY: "auto",
					}}
				>
					{isLoadingTeamList ? (
						<Stack
							height="60vh"
							justifyContent="center"
							alignItems="center"
						>
							<CircularProgress />
						</Stack>
					) : hasTeamsData ? (
						<TeamsList />
					) : (
						<Stack alignItems="center" pt={3}>
							<Typography fontSize={16}>
								Facilitate seamless collaboration and
								productivity by creating sales teams, enabling
								users to share reports, assign territories,
							</Typography>

							<Typography pb={3}>
								and exchange views efficiently.
							</Typography>

							<Tooltip
								title={
									isAddTeamButtonDisabled
										? "You don't have access to create team"
										: ""
								}
								placement="top"
							>
								<Button
									variant="contained"
									startIcon={AddIcon(
										20,
										20,
										isAddTeamButtonDisabled
											? alpha("#FFFFFF", 0.6)
											: "#fff"
									)}
									disableElevation
									onClick={openCreateTeamDialog}
									disabled={isAddTeamButtonDisabled}
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												"rgba(51, 188, 126)",
											color: alpha("#FFFFFF", 0.6),
										},
									}}
									style={{
										pointerEvents: "auto",
									}}
								>
									Create Team
								</Button>
							</Tooltip>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
