import React, { useMemo, useState } from "react";
import Dialog from "../../Elements/Dialog";
import {
	Box,
	Button,
	CircularProgress,
	// FormControl,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useUsers } from "../../../hooks/account/user";
import UserImage from "../../../assets/images/contact/unknownContact.png";
import MenuSearchBar from "../MenuSearchBar";

const selectMenuProps = {
	autoFocus: false,
	PaperProps: {
		style: {
			maxHeight: "240px",
			borderRadius: "8px",
			marginTop: "6px",
		},
		sx: {
			"& .MuiMenu-list": {
				padding: "0px 0px 4px 0px",
			},
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

export default function AssignOwner(props) {
	const {
		open,
		onClose,
		value,
		handleSelectUser,
		disableCancelButton,
		disableSaveButton,
		onSave,
	} = props;
	const theme = useTheme();

	const [searchedValue, setSearchValue] = useState();
	const title = "Assign Owner";

	const { activeUserList, isLoadingUserList, getUserDataById } =
		useUsers(searchedValue);

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const openUserListMenu = () => {
		setSearchValue("");
	};

	const selectedUserName = useMemo(() => {
		const selectedUserData = getUserDataById(value);

		if (selectedUserData?.name) {
			return selectedUserData.name;
		}
		return (
			<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
				Select
			</Typography>
		);
	}, [value, getUserDataById]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="50px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					Match not found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Dialog open={open} title={title}>
				<Box p={2}>
					<Stack spacing={1}>
						<Typography fontSize={13}>Assign to</Typography>

						<Select
							size="small"
							displayEmpty
							value={value}
							renderValue={() => selectedUserName}
							MenuProps={selectMenuProps}
							onChange={handleSelectUser}
							onOpen={openUserListMenu}
						>
							<Box
								p={0.5}
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchedValue}
									onChange={handleSearch}
								/>
							</Box>

							<MenuItem
								value=""
								sx={{ display: "none" }}
							></MenuItem>

							{isLoadingUserList ? (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="150px"
								>
									<CircularProgress size={18} />
								</Stack>
							) : activeUserList?.length === 0 ? (
								renderEmptyView()
							) : (
								activeUserList?.map((user) => (
									<MenuItem
										key={user.value}
										value={user.value}
										style={{
											minHeight: "40px",
										}}
										autoFocus={false}
									>
										<Stack
											direction="row"
											spacing={2}
											alignItems="center"
										>
											<img
												src={UserImage}
												alt="contact_image"
												height="24px"
												width="24px"
											/>

											<Typography fontSize={13}>
												{user.name}
											</Typography>
										</Stack>
									</MenuItem>
								))
							)}
						</Select>

						<Box p={1}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
							>
								<Button
									onClick={onClose}
									variant="contained"
									color="secondary"
									disableElevation
									disabled={disableCancelButton}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									disableElevation
									sx={{
										"&.Mui-disabled": {
											backgroundColor: `${theme.palette.primary.main}`,
											color: "rgba(255,255,255, 0.6)",
										},
									}}
									disabled={disableSaveButton}
									onClick={onSave}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
