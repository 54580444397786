import { $isTextNode, TextNode } from "lexical";

export class ExtentedTextNode extends TextNode {
	constructor(text, key) {
		super(text, key);
	}

	static getType() {
		return "extended-text";
	}

	static clone(node) {
		return new ExtentedTextNode(node.__text, node.__key);
	}

	static importDOM() {
		const importers = TextNode.importDOM();

		return {
			...importers,
			code: () => ({
				conversion: patchStyleConversion(importers?.code),
				priority: 1,
			}),
			em: () => ({
				conversion: patchStyleConversion(importers?.em),
				priority: 1,
			}),
			span: () => ({
				conversion: patchStyleConversion(importers?.span),
				priority: 1,
			}),
			strong: () => ({
				conversion: patchStyleConversion(importers?.strong),
				priority: 1,
			}),
			sub: () => ({
				conversion: patchStyleConversion(importers?.sub),
				priority: 1,
			}),
			sup: () => ({
				conversion: patchStyleConversion(importers?.sup),
				priority: 1,
			}),
			b: () => ({
				conversion: patchStyleConversion(importers?.b),
				priority: 1,
			}),
			i: () => ({
				conversion: patchStyleConversion(importers?.i),
				priority: 1,
			}),
			s: () => ({
				conversion: patchStyleConversion(importers?.s),
				priority: 1,
			}),
			u: () => ({
				conversion: patchStyleConversion(importers?.u),
				priority: 1,
			}),
		};
	}

	static importJSON(serializedNode) {
		return TextNode.importJSON(serializedNode);
	}

	exportJSON() {
		return super.exportJSON();
	}
}

function patchStyleConversion(originalDOMConverter) {
	return (node) => {
		const original = originalDOMConverter?.(node);
		if (!original) {
			return null;
		}
		const originalOutput = original.conversion(node);

		if (!originalOutput) {
			return originalOutput;
		}

		const stylePropertyKeys = [];

		const camelCase = (string) =>
			string
				.toLowerCase()
				.trim()
				.split(/[.\-_\s]/g)
				.reduce(
					(string, word) =>
						string + word[0].toUpperCase() + word.slice(1)
				);

		if (node.style) {
			for (let stylePropertyKey in node.style) {
				if (!isNaN(stylePropertyKey)) {
					stylePropertyKeys.push(node.style[stylePropertyKey]);
				}
			}
		}

		let styles = [];

		for (let property of stylePropertyKeys) {
			styles.push(`${[property]}:${node.style[camelCase(property)]}`);
		}

		return {
			...originalOutput,
			forChild: (lexicalNode, parent) => {
				const originalForChild = originalOutput?.forChild ?? ((x) => x);
				const result = originalForChild(lexicalNode, parent);
				if ($isTextNode(result)) {
					const style = [...styles]
						.filter((value) => value != null)
						.join("; ");
					if (style.length) {
						return result.setStyle(style);
					}
				}
				return result;
			},
		};
	};
}
