import React from "react";

export default function Tick(
	width = "24",
	height = "24",
	color = "#000",
	opacity = 1
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
			opacity={opacity}
		>
			<path
				d="M4.5 12L9.49412 17L19.5 7"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
