import React from "react";
import MonetaryComponent from "../../../../AddForm/Field/FieldComponents/MonetaryComponent";

export default function MonetaryFieldComponent({ field, subFields }) {
	return (
		<React.Fragment>
			<MonetaryComponent
				field={field}
				fieldSpecificProps={{
					valueSx: { width: "60%" },
					typeSx: { width: "40%" },
				}}
				subField={subFields}
			/>
		</React.Fragment>
	);
}
