import { Box, Stack, Typography } from "@mui/material";
import TextComponent from "../../../Field/FieldComponent/TextComponent";
import RichTextEditor from "../../../../Mail/EmailComposer/EmailComposerUI/RichTextEditor";
import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $isDecoratorNode, $isElementNode } from "lexical";
import CheckboxComponent from "../../../Field/FieldComponent/CheckboxComponent";
import MultipleEmailComponent from "../../../Field/FieldComponent/MultipleEmailComponent";

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

export default function EmailField(params) {
	const { actionFields = {}, metadata } = params;
	const [editor] = useLexicalComposerContext();

	const {
		to: toField = {},
		cc: ccField = {},
		bcc: bccField = {},
		subject: subjectField = {},
		body: bodyField = {},
		trackEmails: trackMailField = {},
	} = actionFields || {};

	useEffect(() => {
		if (bodyField && Array.isArray(bodyField?.values)) {
			editor.update(() => {
				const parser = new DOMParser();
				const dom = parser.parseFromString(
					bodyField?.values?.[0]?.value,
					"text/html"
				);
				const nodes = $generateNodesFromDOM(editor, dom);
				const root = $getRoot();
				$getRoot().clear();

				nodes.forEach((node) => {
					if ($isElementNode(node) || $isDecoratorNode(node)) {
						root.append(node);
					}
				});
			});
		}
	}, [bodyField, editor]);

	return (
		<>
			<Stack
				direction="column"
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					height: "100%",
					borderRadius: "8px",
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					pl={2}
					borderBottom="1px solid rgba(0, 0, 0, 0.1)"
				>
					<Typography fontSize={13} width="4%">
						{toField?.displayName} :
					</Typography>

					<Box width="96%">
						<MultipleEmailComponent
							field={toField}
							metadata={metadata}
							style={textFieldSx}
							placeholder={""}
						/>
					</Box>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					pl={2}
					borderBottom="1px solid rgba(0, 0, 0, 0.1)"
				>
					<Typography fontSize={13} width="5%">
						{ccField?.displayName} :
					</Typography>

					<Box width="95%">
						<MultipleEmailComponent
							field={ccField}
							metadata={metadata}
							style={textFieldSx}
							placeholder={""}
						/>
					</Box>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					pl={2}
					borderBottom="1px solid rgba(0, 0, 0, 0.1)"
				>
					<Typography fontSize={13} width="6%">
						{bccField?.displayName} :
					</Typography>

					<Box width="94%">
						<MultipleEmailComponent
							field={bccField}
							metadata={metadata}
							style={textFieldSx}
							placeholder={""}
						/>
					</Box>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					pl={2}
					borderBottom="1px solid rgba(0, 0, 0, 0.1)"
				>
					<Typography fontSize={13}>
						{subjectField?.displayName} :
					</Typography>

					<Box width="89%">
						<TextComponent
							field={subjectField}
							style={textFieldSx}
							placeholder={""}
						/>
					</Box>
				</Stack>

				<Box height="400px" overflow="auto">
					<RichTextEditor hiddenPlaceHolderMenu={true} />
				</Box>

				<Box
					display="flex"
					p={2}
					alignItems="center"
					justifyContent="end"
					width="100%"
				>
					<CheckboxComponent field={trackMailField} />
				</Box>
			</Stack>
		</>
	);
}
