import { SwipeableDrawer } from "@mui/material";
import React from "react";

const CustomSwipeableDrawer = ({
	open,
	onClose,
	onOpen,
	children,
	disableBackdropClick,
	...otherProps
}) => {
	const closeCustomDrawer = (_, reason) => {
		if (disableBackdropClick) {
			if (reason !== "backdropClick") {
				onClose();
			}
		} else {
			onClose();
		}
	};

	return (
		<SwipeableDrawer
			open={open}
			onClose={closeCustomDrawer}
			onOpen={onOpen}
			ModalProps={{
				keepMounted: false,
			}}
			{...otherProps}
		>
			{children}
		</SwipeableDrawer>
	);
};

export default CustomSwipeableDrawer;
