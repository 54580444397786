const timeList = [
	{
		name: "12:00 AM",
		value: 1,
	},
	{
		name: "12:15 AM",
		value: 2,
	},
	{
		name: "12:30 AM",
		value: 3,
	},
	{
		name: "12:45 AM",
		value: 4,
	},
	{
		name: "1:00 AM",
		value: 5,
	},
	{
		name: "1:15 AM",
		value: 6,
	},
	{
		name: "1:30 AM",
		value: 7,
	},
	{
		name: "1:45 AM",
		value: 8,
	},
	{
		name: "2:00 AM",
		value: 9,
	},
	{
		name: "2:15 AM",
		value: 10,
	},
	{
		name: "2:30 AM",
		value: 11,
	},
	{
		name: "2:45 AM",
		value: 12,
	},
	{
		name: "3:00 AM",
		value: 13,
	},
	{
		name: "3:15 AM",
		value: 14,
	},
	{
		name: "3:30 AM",
		value: 15,
	},
	{
		name: "3:45 AM",
		value: 16,
	},
	{
		name: "4:00 AM",
		value: 17,
	},
	{
		name: "4:15 AM",
		value: 18,
	},
	{
		name: "4:30 AM",
		value: 19,
	},
	{
		name: "4:45 AM",
		value: 20,
	},
	{
		name: "5:00 AM",
		value: 21,
	},
	{
		name: "5:15 AM",
		value: 22,
	},
	{
		name: "5:30 AM",
		value: 23,
	},
	{
		name: "5:45 AM",
		value: 24,
	},
	{
		name: "6:00 AM",
		value: 25,
	},
	{
		name: "6:15 AM",
		value: 26,
	},
	{
		name: "6:30 AM",
		value: 27,
	},
	{
		name: "6:45 AM",
		value: 28,
	},
	{
		name: "7:00 AM",
		value: 29,
	},
	{
		name: "7:15 AM",
		value: 30,
	},
	{
		name: "7:30 AM",
		value: 31,
	},
	{
		name: "7:45 AM",
		value: 32,
	},
	{
		name: "8:00 AM",
		value: 33,
	},
	{
		name: "8:15 AM",
		value: 34,
	},
	{
		name: "8:30 AM",
		value: 35,
	},
	{
		name: "8:45 AM",
		value: 36,
	},
	{
		name: "9:00 AM",
		value: 37,
	},
	{
		name: "9:15 AM",
		value: 38,
	},
	{
		name: "9:30 AM",
		value: 39,
	},
	{
		name: "9:45 AM",
		value: 40,
	},
	{
		name: "10:00 AM",
		value: 41,
	},
	{
		name: "10:15 AM",
		value: 42,
	},
	{
		name: "10:30 AM",
		value: 43,
	},
	{
		name: "10:45 AM",
		value: 44,
	},
	{
		name: "11:00 AM",
		value: 45,
	},
	{
		name: "11:15 AM",
		value: 46,
	},
	{
		name: "11:30 AM",
		value: 47,
	},
	{
		name: "11:45 AM",
		value: 48,
	},
	{
		name: "12:00 PM",
		value: 49,
	},
	{
		name: "12:15 PM",
		value: 50,
	},
	{
		name: "12:30 PM",
		value: 51,
	},
	{
		name: "12:45 PM",
		value: 52,
	},
	{
		name: "1:00 PM",
		value: 53,
	},
	{
		name: "1:15 PM",
		value: 54,
	},
	{
		name: "1:30 PM",
		value: 55,
	},
	{
		name: "1:45 PM",
		value: 56,
	},
	{
		name: "2:00 PM",
		value: 57,
	},
	{
		name: "2:15 PM",
		value: 58,
	},
	{
		name: "2:30 PM",
		value: 59,
	},
	{
		name: "2:45 AM",
		value: 60,
	},
	{
		name: "3:00 PM",
		value: 61,
	},
	{
		name: "3:15 PM",
		value: 62,
	},
	{
		name: "3:30 PM",
		value: 63,
	},
	{
		name: "3:45 PM",
		value: 64,
	},
	{
		name: "4:00 PM",
		value: 65,
	},
	{
		name: "4:15 PM",
		value: 66,
	},
	{
		name: "4:30 PM",
		value: 67,
	},
	{
		name: "4:45 PM",
		value: 68,
	},
	{
		name: "5:00 PM",
		value: 69,
	},
	{
		name: "5:15 PM",
		value: 70,
	},
	{
		name: "5:30 PM",
		value: 71,
	},
	{
		name: "5:45 PM",
		value: 72,
	},
	{
		name: "6:00 PM",
		value: 73,
	},
	{
		name: "6:15 PM",
		value: 74,
	},
	{
		name: "6:30 PM",
		value: 75,
	},
	{
		name: "6:45 PM",
		value: 76,
	},
	{
		name: "7:00 PM",
		value: 77,
	},
	{
		name: "7:15 PM",
		value: 78,
	},
	{
		name: "7:30 PM",
		value: 79,
	},
	{
		name: "7:45 PM",
		value: 80,
	},
	{
		name: "8:00 PM",
		value: 81,
	},
	{
		name: "8:15 PM",
		value: 82,
	},
	{
		name: "8:30 PM",
		value: 83,
	},
	{
		name: "8:45 PM",
		value: 84,
	},
	{
		name: "9:00 PM",
		value: 85,
	},
	{
		name: "9:15 PM",
		value: 86,
	},
	{
		name: "9:30 PM",
		value: 87,
	},
	{
		name: "9:45 PM",
		value: 88,
	},
	{
		name: "10:00 PM",
		value: 89,
	},
	{
		name: "10:15 PM",
		value: 90,
	},
	{
		name: "10:30 PM",
		value: 91,
	},
	{
		name: "10:45 PM",
		value: 92,
	},
	{
		name: "11:00 PM",
		value: 93,
	},
	{
		name: "11:15 PM",
		value: 94,
	},
	{
		name: "11:30 PM",
		value: 95,
	},
	{
		name: "11:45 PM",
		value: 96,
	},
];

export default timeList;
