import React, { useState } from "react";
import {
	Box,
	MenuItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../.././assets/icons/dropDown";
import { default as TickIcon } from "../../../../../.././assets/icons/tick";
import { default as CloseIcon } from "../../../../../.././assets/icons/close";
import Menu from "../../../../.././Elements/Menu";
import DropdownField from "./DropdownField";
import { MultiselectField } from "./MultiSelectField";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";

export default function ImportField(props) {
	const {
		index,
		field,
		fieldTypeData,
		newFieldsCreated,
		handleCreateNewField,
		updateNewFieldsCreated,
	} = props;

	const theme = useTheme();
	// field type
	const type = {
		dropdown: "DROPDOWN",
		multiselect: "MULTI_SELECT",
	};

	const [fieldTypeOptionMenuElement, setFieldTypeOptionMenuElement] =
		useState(null);
	const [fieldTypeOptionSelectedIndex, setFieldTypeOptionSelectedIndex] =
		useState(0);
	const openFieldTypeOptionMenu = Boolean(fieldTypeOptionMenuElement);

	const [multiselectChoices, setMultiselectChoices] = useState([]);
	const [dropdownChoices, setDropdownChoices] = useState([]);

	const [fieldName, setFieldName] = useState("");
	const [fieldType, setFieldType] = useState(field.type);

	const handleOpenFieldTypeOptionMenu = (event) => {
		setFieldTypeOptionMenuElement(event.currentTarget);
	};

	const handleCloseFieldTypeOptionMenu = () => {
		setFieldTypeOptionMenuElement(null);
	};

	const handleFieldTypeOptionSelect = (fieldTypeIndex, option) => {
		setFieldTypeOptionSelectedIndex(fieldTypeIndex);
		setFieldTypeOptionMenuElement(null);
		setFieldType(option.name);

		let modifiedNewFieldsCreated = newFieldsCreated.map(
			(newField, newFieldIndex) => {
				if (index === newFieldIndex) {
					return {
						...newField,
						type: option.name,
					};
				}

				return newField;
			}
		);
		updateNewFieldsCreated(modifiedNewFieldsCreated);
	};

	const handleChangeFieldName = (event) => {
		const { value } = event.target;
		event.stopPropagation();

		let modifiedNewFieldsCreated = newFieldsCreated.map(
			(newField, newFieldIndex) => {
				if (index === newFieldIndex) {
					return {
						...newField,
						fieldName: value,
					};
				}

				return newField;
			}
		);
		updateNewFieldsCreated(modifiedNewFieldsCreated);
		setFieldName(value);
	};

	const handleAddClick = () => {
		let choiceLimitCount = 2;
		if (fieldType === type.dropdown) {
			if (dropdownChoices.length < choiceLimitCount) {
				return enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.selectChoiceLimitError,
				});
			}
		}
		if (fieldType === type.multiselect) {
			if (multiselectChoices.length < choiceLimitCount) {
				return enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.selectChoiceLimitError,
				});
			}
		}

		if (fieldName.trim() !== "") {
			const newFields = createFieldProperties();
			handleCreateNewField(newFields[index]);
		} else {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.fieldCreatedError,
			});
		}
	};

	const handleRemoveClick = () => {
		let modifiedNewFieldsCreated = [...newFieldsCreated];
		modifiedNewFieldsCreated.splice(index, 1);
		updateNewFieldsCreated(modifiedNewFieldsCreated);
	};

	const createFieldProperties = () => {
		let dropdownChoicesOrMultiselectChoices =
			getDropdownOrMultiselectChoices();

		let modifiedNewFieldsCreated = newFieldsCreated.map(
			(newField, newFieldIndex) => {
				if (index === newFieldIndex) {
					return {
						...newField,
						config: {
							...newField.config,
							options: dropdownChoicesOrMultiselectChoices,
						},
					};
				}

				return newField;
			}
		);
		updateNewFieldsCreated(modifiedNewFieldsCreated);

		return modifiedNewFieldsCreated;
	};

	const getDropdownOrMultiselectChoices = () => {
		if (fieldType === type.dropdown) {
			return dropdownChoices;
		} else if (fieldType === type.multiselect) {
			return multiselectChoices;
		}

		return [];
	};

	const getFieldTypeValue = (type) => {
		const fieldType = fieldTypeData.find((option) => option.name === type);
		return fieldType?.displayName;
	};

	const updateDropdownChoices = (dropdownChoices) => {
		setDropdownChoices(dropdownChoices);
	};

	const updateMultiselectChoices = (multiselectChoices) => {
		setMultiselectChoices(multiselectChoices);
	};

	return (
		<React.Fragment>
			<Box sx={{ pr: 1, pl: 1 }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
					onKeyDown={(event) => event.stopPropagation()}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						px={1}
						height="100%"
						width="100%"
					>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={2}
							width="100%"
						>
							<Stack
								direction="row"
								width="100%"
								spacing={1.5}
								alignItems="center"
								sx={{ whiteSpace: "nowrap" }}
								onKeyDown={(event) => event.stopPropagation()}
							>
								<TextField
									variant="standard"
									value={fieldName}
									autoFocus
									onChange={handleChangeFieldName}
								/>
								<Box>
									<Box
										pl={1}
										pr={0.5}
										py={0.2}
										sx={{
											backgroundColor: (theme) =>
												theme.palette.secondary.main,
											borderRadius: "3px",
											cursor: "pointer",
										}}
										onClick={handleOpenFieldTypeOptionMenu}
									>
										<Stack direction="row" spacing={0.5}>
											<Typography fontSize={12}>
												{getFieldTypeValue(fieldType)}
											</Typography>

											{DropDownIcon(
												18,
												18,
												theme.palette.secondary
													.contrastText
											)}
										</Stack>
									</Box>

									<Menu
										sx={{
											maxHeight: "208px",
										}}
										anchorEl={fieldTypeOptionMenuElement}
										open={openFieldTypeOptionMenu}
										onClose={handleCloseFieldTypeOptionMenu}
									>
										{fieldTypeData
											.filter(
												(fieldType) =>
													!fieldType.isSourceDependent
											)
											.map((option, index) => (
												<MenuItem
													key={option.id}
													selected={
														index ===
														fieldTypeOptionSelectedIndex
													}
													onClick={() =>
														handleFieldTypeOptionSelect(
															index,
															option
														)
													}
													style={{
														minHeight: "40px",
													}}
												>
													<Typography fontSize={13}>
														{option.displayName}
													</Typography>
												</MenuItem>
											))}
									</Menu>
								</Box>

								{(function () {
									switch (fieldType) {
										case type.dropdown:
											return (
												<DropdownField
													dropdownChoices={
														dropdownChoices
													}
													updateDropdownChoices={
														updateDropdownChoices
													}
												/>
											);
										case type.multiselect:
											return (
												<MultiselectField
													multiselectChoices={
														multiselectChoices
													}
													updateMultiselectChoices={
														updateMultiselectChoices
													}
												/>
											);
										default:
											null;
									}
								})()}
							</Stack>
						</Stack>
					</Stack>
					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
						sx={{ width: "30%" }}
					>
						<Box
							style={{ cursor: "pointer" }}
							onClick={handleRemoveClick}
						>
							{CloseIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
						</Box>
						<Box
							style={{ cursor: "pointer" }}
							onClick={handleAddClick}
							sx={{
								opacity: fieldName === "" ? 0.6 : 1,
							}}
						>
							{TickIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
						</Box>
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
