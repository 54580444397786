import React from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as CloseIcon } from "../../assets/icons/close";
import { getIconByName } from "../../utils";
import NotificationMessage from "../Elements/AppNotification/NotificationMessage";

const ReminderNotification = (props, ref) => {
	const { id, reminderData, onClickReminder, ...other } = props;
	const theme = useTheme();
	const { closeSnackbar } = useSnackbar();
	let notificationContentColor = "#fff";
	let isNotificationActionEnabled = false;

	return (
		<React.Fragment>
			<SnackbarContent ref={ref} role="alert" {...other}>
				<Box
					style={{
						width: "450px",
						backgroundColor: theme.palette.primary.main,
						color: "#fff",
						borderRadius: "10px 0px 0px 10px",
						paddingLeft: "8px",
					}}
				>
					<Box
						style={{
							borderRadius: "10px 0px 0px 10px",
							backgroundColor: "#272937",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="flex-end"
							style={{
								backgroundColor: "#272937",
								borderRadius: "10px 0px 0px 0px",
							}}
							spacing={1.2}
							pt={1.2}
							pr={1.2}
						>
							<IconButton
								style={{
									borderRadius: "6px",
									backgroundColor: "#323441",
									padding: "4px",
								}}
							>
								<Typography
									fontSize={13}
									fontWeight={500}
									color="#2EA871"
									onClick={() => {
										onClickReminder(reminderData);
										closeSnackbar(id);
									}}
								>
									Reminder
								</Typography>
							</IconButton>

							<IconButton
								size="small"
								onClick={() => closeSnackbar(id)}
								style={{ padding: 0 }}
							>
								{CloseIcon(22, 22, "#fff")}
							</IconButton>
						</Stack>

						<Stack
							direction="row"
							alignItems="center"
							spacing={2}
							pb={3}
							pl={3}
							mt={-2}
						>
							<Box display="flex">
								{getIconByName(reminderData?.icon?.name)(
									24,
									24,
									"#fff"
								)}
							</Box>

							<NotificationMessage
								notificationData={reminderData}
								onClose={closeSnackbar}
								notificationContentColor={
									notificationContentColor
								}
								isNotificationActionEnabled={
									isNotificationActionEnabled
								}
							/>
						</Stack>
					</Box>
				</Box>
			</SnackbarContent>
		</React.Fragment>
	);
};

export default React.forwardRef(ReminderNotification);
