export const assertError = (response, requestName = "") => {
	if (response.data.code !== 0) {
		console.error(
			"Error from " + requestName,
			"\n",
			JSON.stringify(response.data)
		);
		if (response.data?.data?.error) {
			throw new Error(response.data?.data?.error);
		}
		throw new Error(JSON.stringify(response.data?.data));
	}
};
