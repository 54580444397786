import React from "react";

function Caution(width = "20", height = "20", color = "#D7C526") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 20 20"
		>
			<path
				d="M10 7.5V10.8333"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.99609 13.3333H10.0036"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.30219 3.56495C9.08475 2.30633 10.9166 2.30633 11.6991 3.56495L18.2042 14.0272C19.0325 15.3595 18.0745 17.0833 16.5057 17.0833H3.49561C1.92683 17.0833 0.968804 15.3595 1.79715 14.0272L8.30219 3.56495Z"
				stroke="#D7C526"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Caution;
