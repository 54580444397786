import React from "react";
import ShortFiles from "../../../Summary/ShortFiles";
import { modules } from "../../../../utils/common/ModulesName";

export default function CompanyFiles({ companyId }) {
	return (
		<React.Fragment>
			<ShortFiles entityId={companyId} sourceName={modules.COMPANY} />
		</React.Fragment>
	);
}
