const priority = [
	{
		name: "High",
		value: 1,
	},
	{
		name: "Medium",
		value: 2,
	},
	{
		name: "Low",
		value: 3,
	},
];

export default priority;
