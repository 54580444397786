import { apiClient } from "..";
import { getCompanyUrl, getContactsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";
import { getTableData } from "../table/tableApi";

export const getCompanySummaryData = async (id, hideEmptyFields) => {
	let request = getCompanySummaryRequest(id, hideEmptyFields);
	return await postCompanyManagementApi(request);
};

export const createCompany = async (data) => {
	let request = getCreateCompanyRequest(data);
	return await postCompanyManagementApi(request);
};

export const getSummaryData = async (id) => {
	let request = getSummaryDataRequest(id);
	return await postCompanyManagementApi(request);
};

export const updateCompanySummary = async (data) => {
	let request = updateCompanySummaryRequest(data);
	return await postCompanyManagementApi(request);
};

export const deleteCompany = async (id) => {
	let requestData = getDeleteCompanyRequest(id);
	return await postCompanyManagementApi(requestData);
};

export const getRelatedContactsList = async (companyId, pageParam) => {
	let requestData = getRelatedContactsListRequest(companyId, pageParam);
	return await postCompanyManagementApi(requestData);
};

export const getCompanyTableData = async (fieldData) => {
	let listUrl = getCompanyListUrl();
	let config = {
		url: listUrl,
		provider: "COMPANY_LIST",
		fields: parseSelectFieldsForApi(fieldData),
	};

	let response = await getTableData(config);
	return response;
};

export const deleteMultipleCompany = async (companyIds) => {
	let request = getDeleteMutipleCompanyRequest(companyIds);
	let response = await postCompanyManagementApi(request);
	return response;
};

export const updateCompany = async (data) => {
	let requestData = updateCompanyFieldRequest(data);
	return await postCompanyManagementApi(requestData);
};

export const getCompanyList = async () => {
	let requestData = getCompanyListRequestData();
	return await postFieldsApi(requestData);
};

export const assignOwnerToCompanies = async ({ companyIds, userId }) => {
	let requestData = assignOwnerRequest(companyIds, userId);
	return await postCompanyManagementApi(requestData);
};

export const partialUpdateCompany = async (data) => {
	let requestData = partialUpdateCompanyRequest(data);
	return await postCompanyManagementApi(requestData);
};

const postCompanyManagementApi = async (requestData) => {
	let companyManagementUrl = getCompanyManagementUrl();
	let response = await apiClient.post(companyManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const parseSelectFieldsForApi = () => {
	// let fields = [];

	// if (fieldData) {
	//     fieldData.forEach((field) => {
	//         fields.push(field.id);
	//     });
	// }

	//For now we aren't using this fieldData, But we will be using the same in future once the server side changes are done

	let fields = ["name", "id"];
	return fields;
};

const getCompanyListUrl = () => {
	return getContactsUrl() + "/list";
};

const getCompanyManagementUrl = () => {
	return getCompanyUrl() + "/mgmt";
};

const getRelatedContactsListRequest = (companyId, pageParam) => {
	return {
		type: "getRelatedContacts",
		data: {
			id: companyId,
			...pageParam,
		},
	};
};

const partialUpdateCompanyRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getCompanySummaryRequest = (id, hideEmptyFields) => {
	return {
		type: "summary",
		data: {
			id: id,
			isHidden: hideEmptyFields,
		},
	};
};

const getSummaryDataRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const getCreateCompanyRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const updateCompanySummaryRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getDeleteMutipleCompanyRequest = (companyIds) => {
	return {
		type: "bDelete",
		data: {
			ids: companyIds,
		},
	};
};

const updateCompanyFieldRequest = (data) => {
	return {
		type: "pBulkUpdate",
		data: {
			list: data,
		},
	};
};

const getDeleteCompanyRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getCompanyListRequestData = () => {
	return {
		type: "dropdownData",
		data: {
			name: "companyList",
		},
	};
};

const assignOwnerRequest = (companyIds, userId) => {
	return {
		type: "bAssignOwner",
		data: {
			ids: companyIds,
			userId: userId,
		},
	};
};
