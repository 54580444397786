import React from "react";

export default function Data(width = 18, height = 18, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.6068 4.45581C14.7401 4.93845 14.7401 5.73642 13.6068 6.21906L9.67343 7.90508C9.22676 8.09814 8.49343 8.09814 8.04676 7.90508L4.11343 6.21906C2.98009 5.73642 2.98009 4.93845 4.11343 4.45581L8.04676 2.76979C8.49343 2.57674 9.22676 2.57674 9.67343 2.76979L13.6068 4.45581Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 8.25C3 8.79056 3.42 9.41477 3.93333 9.63357L8.46 11.577C8.80667 11.725 9.2 11.725 9.54 11.577L14.0667 9.63357C14.58 9.41477 15 8.79056 15 8.25"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 12C3 12.5985 3.36667 13.139 3.93333 13.3836L8.46 15.327C8.80667 15.475 9.2 15.475 9.54 15.327L14.0667 13.3836C14.6333 13.139 15 12.5985 15 12"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
