const columns = [
	{
		id: 1,
		name: "3 Columns",
	},
	{
		id: 2,
		name: "4 Columns",
	},
	{
		id: 3,
		name: "5 Columns",
	},
];

export default columns;
