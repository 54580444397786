import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { DealContext } from "../DealContext";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import { useDateFieldList } from "../../../hooks/services/deal/forecast";

const DealProvider = ({ children }) => {
	const ref = useRef();
	const currentDate = dayjs;
	const [startDate, setStartDate] = useState(currentDate);

	const dealStageColumns = getDropdownDataByName("COLUMNS");
	const [dealColumn, setDealColumn] = useState(dealStageColumns[1]);

	const intervalList = getDropdownDataByName("INTERVAL_LIST");
	const defaultInterval = intervalList[0];
	const [interval, setInterval] = useState(defaultInterval);

	const dealStateList = getDropdownDataByName("DEAL_STATE_LIST");
	const defaultDealState = dealStateList[0];
	const [dealState, setDealState] = useState(defaultDealState);

	const { status: dateFieldListStatus, data: dealDateFieldList } =
		useDateFieldList();
	const [dealField, setDealField] = useState({});

	useEffect(() => {
		if (dateFieldListStatus === "success") {
			if (dealDateFieldList && !ref.current) {
				setDealField(dealDateFieldList[0]);
				ref.current = true;
			}
		}
	}, [dealDateFieldList, dateFieldListStatus]);

	const updateStartDate = (startDate) => {
		setStartDate(startDate);
	};

	const updateDealColumn = (colomn) => {
		setDealColumn(colomn);
	};

	const updateDealInterval = (interval) => {
		setInterval(interval);
	};

	const updateDealState = (dealState) => {
		setDealState(dealState);
	};

	const updateDealField = (dealField) => {
		setDealField(dealField);
	};

	return (
		<DealContext.Provider
			value={{
				startDate,
				updateStartDate,
				dealColumn,
				updateDealColumn,
				interval,
				updateDealInterval,
				dealState,
				updateDealState,
				dealField,
				updateDealField,
			}}
		>
			{children}
		</DealContext.Provider>
	);
};

export { DealProvider };
