import React, { useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { default as CloseIcon } from "../../../../../assets/icons/close";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function ActivityAutocomplete(props) {
	const {
		inputAdornmentIcon,
		getOptionLabel,
		handleRemoveValue,
		placeholder,
		...other
	} = props;
	const theme = useTheme();

	const [isOptionsDisplayed, setIsOptionsDisplayed] = useState(false);

	const handleInputChange = (event) => {
		const { value } = event.target;
		setIsOptionsDisplayed(!!value);
	};

	return (
		<MuiAutocomplete
			{...other}
			size="small"
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			getOptionLabel={getOptionLabel}
			open={isOptionsDisplayed}
			onClose={() => setIsOptionsDisplayed(false)}
			readOnly={!!other.value?.value || !!other.value?.id}
			noOptionsText="No Results Found"
			renderOption={(props, option) => (
				<Box {...props}>
					<Typography fontSize={14}>
						{option?.name
							? option.name
							: option?.title
								? option?.title
								: option}
					</Typography>
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					onChange={handleInputChange}
					inputProps={{
						...params.inputProps,
						style: {
							fontSize: "14px",
						},
					}}
					placeholder={placeholder}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment
								position="start"
								sx={{ paddingLeft: "10px" }}
							>
								{inputAdornmentIcon(20, 20, "#666666")}
							</InputAdornment>
						),

						endAdornment: (
							<InputAdornment position="end">
								{(!!other.value?.id ||
									!!other.value?.value) && (
									<IconButton onClick={handleRemoveValue}>
										{CloseIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
}
