import React from "react";
import TimeComponent from "../../../../AddForm/Field/FieldComponents/TimeComponent";

export default function TimeFieldComponent({ field, _subField, _isHalfField }) {
	return (
		<React.Fragment>
			<TimeComponent
				field={field}
				reverse={true}
				toggleButtonStyle={{ width: "80px" }}
			/>
		</React.Fragment>
	);
}
