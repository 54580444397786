import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { default as ThumbsUpIcon } from "../../../../../assets/icons/thumbsUp";
import { default as ThumbsDownIcon } from "../../../../../assets/icons/thumbsDown";

const dealStates = {
	won: "won",
	lost: "lost",
};

export default function DealState(props) {
	const { state } = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			{!!state && (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{
						backgroundColor:
							state === dealStates.won
								? theme.palette.primary.main
								: state === dealStates.lost
									? theme.palette.error.main
									: null,
						borderRadius: "6px",
						p: 0.5,
					}}
					spacing={0.5}
				>
					{state === dealStates.won
						? ThumbsUpIcon(16, 16, "#fff")
						: state === dealStates.lost
							? ThumbsDownIcon(16, 16, "#fff")
							: null}

					<Typography fontSize={13} fontWeight={500} color="#fff">
						{state === dealStates.won
							? dealStates.won
							: state === dealStates.lost
								? dealStates.lost
								: null}
					</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}
