import { useCallback, forwardRef, useImperativeHandle } from "react";
import { TextField, Typography, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";

const TextFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose, ...rest }, ref) => {
		const fieldId = field?.fieldId;
		const theme = useTheme();

		const {
			control,
			handleSubmit,
			formState: { errors },
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const onChangeInterceptor = (event, onChange) => {
			let value = event.target.value.trimStart();

			if (rest.type === "number") {
				let parsed = parseInt(value);
				value = isNaN(parsed) ? "" : parsed;
			}
			onChange(value);
			clearErrors(fieldId);
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...renderField } }) => (
						<TextField
							placeholder={"Enter"}
							id={fieldId + ""}
							{...renderField}
							{...rest}
							sx={{
								"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
									{
										"-webkit-appearance": "none",
									},
								width: "100%",
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										border: "none",
									},
									"&:hover fieldset": {
										border: "none",
									},
									"&.Mui-focused fieldset": {
										border: "none",
									},
								},
							}}
							onChange={(event) =>
								onChangeInterceptor(event, onChange)
							}
						/>
					)}
					defaultValue=""
				/>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

TextFieldEditForm.displayName = "TextFieldEditForm";

export default TextFieldEditForm;
