import React, { useState } from "react";
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import Menu from "../../../../../../Elements/Menu";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import { scope, sourceIds } from "../../../../../../../utils/PermissionUtils";

export default function Sources(props) {
	const { onChange, handleAccessChange, isEditable } = props;

	const { permissionState, updateSourceList } = usePermissionContext();

	const [accessMenuElement, setAccessMenuElement] = useState(null);
	const isAccessMenuOpened = Boolean(accessMenuElement);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedAccess, setSelectedAccess] = useState(scope.globalAccess);

	const shouldShowTerritoryAccess = selectedId !== sourceIds.products;

	const openAccessMenu = (event, value, id, hasPermissionToAccess) => {
		if (hasPermissionToAccess) {
			setAccessMenuElement(event.currentTarget);
		} else {
			setAccessMenuElement(null);
		}
		setSelectedAccess(value);
		setSelectedId(id);
	};

	const closeAccessMenu = () => {
		setAccessMenuElement(null);
	};

	const handleScopeChange = (event) => {
		setSelectedAccess(event.target.value);

		const updatedSourceList = permissionState.sourceList.map((list) => {
			if (list.id === selectedId) {
				return {
					...list,
					permissions: {
						...list.permissions,
						permission: list.permissions.permission.map(
							(permission) =>
								permission.id === scope.scope
									? {
											...permission,
											selectedAccess: event.target.value,
										}
									: permission
						),
					},
				};
			} else {
				return list;
			}
		});
		updateSourceList(updatedSourceList);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="400px"
				anchorEl={accessMenuElement}
				open={isAccessMenuOpened}
				onClose={closeAccessMenu}
				style={{
					marginTop: "6px",
				}}
			>
				<Stack p={1.5}>
					<Typography fontSize={13} fontWeight={500}>
						Scope
					</Typography>

					<FormControl>
						<RadioGroup
							value={selectedAccess}
							onChange={handleScopeChange}
						>
							<Stack pt={1}>
								<FormControlLabel
									value={scope.globalAccess}
									control={<Radio />}
									label={
										<Stack>
											<Typography fontSize={14}>
												Global Access
											</Typography>
											<Typography
												fontSize={13}
												color="rgba(0, 0, 0, 0.6)"
											>
												Can access all records
											</Typography>
										</Stack>
									}
								/>
							</Stack>

							{shouldShowTerritoryAccess ? (
								<Stack pt={1}>
									<FormControlLabel
										value={scope.territoryAccess}
										control={<Radio />}
										label={
											<Stack>
												<Typography fontSize={14}>
													Territory Access
												</Typography>
												<Typography
													fontSize={13}
													color="rgba(0, 0, 0, 0.6)"
												>
													Access to record in their
													territory and assigned
													records.
												</Typography>
											</Stack>
										}
									/>
								</Stack>
							) : null}

							<Stack pt={1}>
								<FormControlLabel
									value={scope.restrictedAccess}
									control={<Radio />}
									label={
										<Stack>
											<Typography fontSize={14}>
												Restricted Access
											</Typography>
											<Typography
												fontSize={13}
												color="rgba(0, 0, 0, 0.6)"
											>
												Access only to assigned records
											</Typography>
										</Stack>
									}
								/>
							</Stack>
						</RadioGroup>
					</FormControl>
				</Stack>
			</Menu>

			<PermissionList
				isViewName="Sources"
				lists={permissionState.sourceList}
				openAccessMenu={openAccessMenu}
				onChange={onChange}
				handleAccessChange={handleAccessChange}
				isEditable={isEditable}
			/>
		</React.Fragment>
	);
}
