import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import FormComponent from "./FormComponent";

export default function WebFormPreview(props) {
	const { formToPreview, activeStep, type } = props;

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					pointerEvents: activeStep === 0 ? "none" : null,
				}}
			>
				<Box>
					<Stack p={2.5} pl={3} height="68px">
						<Typography fontSize="16px" fontWeight={500}>
							Preview
						</Typography>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: "0px",
						overflowY: "auto",
					}}
				>
					<FormComponent formToPreview={formToPreview} type={type} />
				</Box>
			</Box>
		</React.Fragment>
	);
}
