import { useLayoutEffect, useState } from "react";

const useTruncatedElement = (ref) => {
	const [isTruncated, setIsTruncated] = useState(false);

	useLayoutEffect(() => {
		const { offsetHeight, scrollHeight } = ref?.current || {};

		if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
			setIsTruncated(true);
		} else {
			setIsTruncated(false);
		}
	}, [ref]);

	return {
		isTruncated,
	};
};

export { useTruncatedElement };
