import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import TooltipComponent from "../../TooltipComponent";

export default function Address(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TooltipComponent
				title={data?.displayAddress}
				placement="top"
				width="572px"
				verticalDisplacement={-3}
			>
				<TableCellText noWrap maxWidth="552px">
					{data?.displayAddress}
				</TableCellText>
			</TooltipComponent>
		</React.Fragment>
	);
}
