import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getDefaultRolePermissionKey } from "../../../../../utils/queryKeys/userManagement/roles/permission";
import {
	getPermission,
	updatePermission,
} from "../../../../../api/userManagement/roles/permissionset/permissionsetApi";

const usePermission = (id, enabled) => {
	return useQuery(getDefaultRolePermissionKey(id), () => getPermission(id), {
		enabled: enabled,
	});
};

const useInvalidateDefaultPermission = (id) => {
	let queryClient = useQueryClient();
	let defaultRolePermissionKey = getDefaultRolePermissionKey(id);
	return () => {
		queryClient.invalidateQueries(defaultRolePermissionKey);
	};
};

const useUpdatePermissionSet = (id) => {
	let invalidateDefaultPermission = useInvalidateDefaultPermission(id);
	return useMutation(updatePermission, {
		onSuccess: () => {
			invalidateDefaultPermission(id);
		},
	});
};

export { usePermission, useUpdatePermissionSet };
