const phoneType = [
	{
		value: 1,
		name: "Work",
	},
	{
		value: 2,
		name: "Home",
	},
	{
		value: 3,
		name: "Mobile",
	},
	{
		value: 4,
		name: "Whatsapp",
	},
	{
		value: 5,
		name: "Telegram",
	},
	{
		value: 6,
		name: "Personal",
	},
	{
		value: 7,
		name: "Other",
	},
];

export default phoneType;
