import SingleTimePicker from "../../../../Elements/SingleTimePicker";

export default function TimeComponent(params) {
	const { field = {}, hasError, onChange } = params;

	const handleChange = (timeValue) => {
		if (!field.values || !Array.isArray(field.values)) {
			field.values = [{ value: "" }];
		}
		field.values[0].value = timeValue;
		if (onChange) {
			onChange(field.values);
		}
	};

	return (
		<>
			<SingleTimePicker
				style={{
					backgroundColor: "#fff",
					borderRadius: 8,
					Width: "100%",
				}}
				error={hasError}
				value={
					field.values && field.values.length > 0
						? field.values[0].value
						: ""
				}
				onChange={handleChange}
			/>
		</>
	);
}
