import React, { useMemo } from "react";
import { Box, Button, Stack, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { modules } from "../../../../../utils/common/ModulesName";
import { useFormFieldsData } from "../../../../../hooks/services/common/field";
import Row from "../../../../Elements/AddForm/Row";
import Dialog from "../../../../Elements/Dialog";

export default function AddNewProduct(props) {
	const { open, onClose, onSave, productFormData } = props;

	const moduleName = modules.PRODUCT;
	const formMethods = useForm({
		values: productFormData,
		reValidateMode: false, // validation triggered only when the form Submit
	});

	const { status: formFieldsStatus, data: formFieldsData } =
		useFormFieldsData(moduleName);

	const productSubFields = formFieldsData?.subFields || [];
	const productSourceData = formFieldsData?.source || {};

	const requiredFields = useMemo(() => {
		if (formFieldsData?.fields?.length > 0) {
			let requiredFields = [];
			for (let index = 0; index < formFieldsData.fields.length; index++) {
				for (let field of formFieldsData.fields[index]) {
					if (field?.config?.required) {
						requiredFields = [...requiredFields, [field]];
					}
				}
			}
			return requiredFields;
		}
		return [];
	}, [formFieldsData, formFieldsStatus]);

	return (
		<React.Fragment>
			<Dialog open={open} title="Add Product">
				<Box>
					<Box
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<FormProvider {...formMethods}>
							<Box
								style={{
									flex: "1 1 auto",
									minHeight: 0,
									overflowY: "auto",
								}}
								p={2}
							>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={2}
								>
									{requiredFields?.map((field, index) => (
										<Row
											field={field}
											subFields={productSubFields}
											key={index}
											source={productSourceData}
										/>
									))}
								</Grid>
							</Box>
						</FormProvider>
					</Box>

					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
						p={3}
						pt={1}
					>
						<Button
							onClick={onClose}
							variant="contained"
							color="secondary"
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							disableElevation
							onClick={formMethods.handleSubmit(onSave)}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
