import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import UserManagementDialog from "../../../../Elements/UserManagementDialog";
import { useTransferUserRecordMutation } from "../../../../../hooks/services/userManagement/user";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { default as UserProfileImage } from "../../../../../assets/images/contact/unknownContact.png";
import { useUsers } from "../../../../../hooks/account/user";

export default function TransferRecordDialog(props) {
	const { open, onClose, sourceUserIdForTransfer, title } = props;

	const { activeUserList, getUserDataById } = useUsers();

	const transferUserRecordMutation = useTransferUserRecordMutation();

	const [targetUserIdForTransfer, setTargetUserIdForTransfer] = useState();

	useEffect(() => {
		setTargetUserIdForTransfer(sourceUserIdForTransfer);
	}, [sourceUserIdForTransfer]);

	const onChange = (event) => {
		setTargetUserIdForTransfer(event.target.value);
	};

	const transferUserRecords = (
		sourceUserIdForTransfer,
		targetUserIdForTransfer
	) => {
		transferUserRecordMutation.mutate(
			{
				sourceUserId: sourceUserIdForTransfer,
				targetUserId: targetUserIdForTransfer,
			},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.recordsTransfered,
					});
					onClose();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const filteredUserList = useMemo(() => {
		let sourceUserData = getUserDataById(sourceUserIdForTransfer);
		if (Array.isArray(activeUserList) && sourceUserData) {
			return [...new Set([...activeUserList, sourceUserData])];
		}
		return [];
	}, [activeUserList, sourceUserIdForTransfer, getUserDataById]);

	return (
		<React.Fragment>
			<UserManagementDialog open={open} onClose={onClose} title={title}>
				<Box px={3} pb={3}>
					<Typography fontSize={13} fontWeight={500} pb={0.5}>
						Transfer to
					</Typography>

					<Box>
						<Select
							size="small"
							sx={{
								width: "100%",
							}}
							onChange={onChange}
							value={targetUserIdForTransfer}
						>
							{filteredUserList?.map((user) => (
								<MenuItem
									value={user.value}
									key={user.value}
									style={{
										minHeight: "40px",
									}}
								>
									<Stack
										direction="row"
										spacing={2}
										alignItems="center"
									>
										{sourceUserIdForTransfer !==
											user.value && (
											<img
												alt="user"
												src={UserProfileImage}
												width="20px"
												height="20px"
											/>
										)}

										<Typography fontSize={14}>
											{user.value ===
											sourceUserIdForTransfer
												? "Same user (Leave as is)"
												: user.name}
										</Typography>
									</Stack>
								</MenuItem>
							))}
						</Select>
					</Box>

					<Box pt={2}>
						<Stack
							direction="row"
							spacing={2}
							justifyContent="flex-end"
						>
							<Button
								onClick={onClose}
								variant="contained"
								color="secondary"
								disableElevation
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="primary"
								disableElevation
								onClick={() =>
									transferUserRecords(
										sourceUserIdForTransfer,
										targetUserIdForTransfer
									)
								}
							>
								Transfer
							</Button>
						</Stack>
					</Box>
				</Box>
			</UserManagementDialog>
		</React.Fragment>
	);
}
