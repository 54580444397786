import React from "react";
import { DealProvider } from "../Deals/DealProvider";
import { FilterSaveViewProvider } from "../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../Elements/Table/Filter/Context/FilterDataContext";
import DealDashboard from "./DealDashboard";

export default function Deals() {
	return (
		<React.Fragment>
			<DealProvider>
				<FilterDataProvider>
					<FilterSaveViewProvider>
						<DealDashboard />
					</FilterSaveViewProvider>
				</FilterDataProvider>
			</DealProvider>
		</React.Fragment>
	);
}
