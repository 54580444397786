import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import TerritoryBaseComponent from "./TerritoryBaseComponent";

export default function TerritoryComponent({ field, inputRef }) {
	const {
		control,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	return (
		<React.Fragment>
			<Controller
				control={control}
				name={field.id + ""}
				defaultValue=""
				rules={handleFieldValidation(field)}
				render={({ field: { onChange, value } }) => (
					<TerritoryBaseComponent
						onChange={onChange}
						value={value}
						field={field}
						inputRef={inputRef}
						clearErrors={clearErrors}
						errors={errors}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}
