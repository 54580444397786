import {
	Box,
	Button,
	Divider,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { hexToRgba } from "../../../../styles/twozo";
import {
	useAddCurrencyMutation,
	useCurrencyList,
} from "../../../../hooks/services/currency";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import MenuSearchBar from "../../../Elements/MenuSearchBar";

export default function AddCurrency(props) {
	const { onClose, styles } = props;
	const theme = useTheme();

	const [selectedCurrencyId, setSelectedCurrencyId] = useState("");
	const [currencyRate, setCurrencyRate] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	const addCurrencyMutation = useAddCurrencyMutation();
	const { data: currencyList, status: currencyListStatus } =
		useCurrencyList();

	const filteredCurrency = currencyList?.filter(
		(currencyDetails) =>
			currencyDetails.currency.name
				.toLowerCase()
				.startsWith(searchValue.toLowerCase()) ||
			currencyDetails.currency.code
				.toLowerCase()
				.includes(searchValue.toLowerCase())
	);

	useEffect(() => {
		if (currencyListStatus === "success" && currencyList.length > 0) {
			setSelectedCurrencyId(currencyList[0].currency.id);
			setCurrencyRate(currencyList[0].exchangeRate);
		}
	}, [currencyListStatus, currencyList]);

	const handleChangeCurrency = (event) => {
		const { value } = event.target;
		setSelectedCurrencyId(value);

		const selectedCurrencyExchangeRate = currencyList.find(
			(currencyDetails) => currencyDetails.currency.id === value
		).exchangeRate;

		setCurrencyRate(selectedCurrencyExchangeRate);
	};

	const handleAddCurrency = (selectedCurrencyId) => {
		addCurrencyMutation.mutate(selectedCurrencyId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.currencyAdded,
				});
				onClose();
				setSelectedCurrencyId("");
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography fontSize={16} fontWeight={600}>
							Add Currency
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflow: "auto",
					}}
				>
					<Box pb={3}>
						<Typography style={styles.subText} pl={1} pb={0.5}>
							Select Currency
						</Typography>

						<Select
							value={selectedCurrencyId}
							sx={{ width: "100%" }}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 290,
									},
								},
								autoFocus: false,
							}}
							renderValue={(selectedCurrencyId) => {
								const selected = currencyList.find(
									(currencyDetails) =>
										currencyDetails.currency.id ===
										selectedCurrencyId
								)?.currency;
								return selected
									? `${selected.name} (${selected.code})`
									: "";
							}}
							onOpen={() => setSearchValue("")}
							onChange={handleChangeCurrency}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchValue}
									onChange={handleSearchValue}
								/>
							</Box>

							{filteredCurrency?.length === 0 ? (
								<Typography
									style={{
										textAlign: "center",
										padding: 10,
										fontSize: "13px",
										color: "rgba(0, 0, 0, 0.6)",
									}}
								>
									No Results Found
								</Typography>
							) : (
								filteredCurrency?.map((currencyDetails) => (
									<MenuItem
										key={currencyDetails.currency.id}
										value={currencyDetails.currency.id}
										style={{
											height: "40px",
											backgroundColor:
												selectedCurrencyId ===
												currencyDetails.currency.id
													? theme.palette.secondary
															.main
													: "transparent",
										}}
									>
										<Stack
											width="100%"
											direction="row"
											alignItems="center"
											justifyContent="space-between"
										>
											<Typography
												style={{
													...styles.menuText,
													paddingLeft: "8px",
												}}
											>
												{currencyDetails.currency.name}
											</Typography>

											<Typography
												style={{
													...styles.menuText,
													paddingLeft: "8px",
												}}
											>
												{currencyDetails.currency.code}
											</Typography>
										</Stack>
									</MenuItem>
								))
							)}
						</Select>
					</Box>

					<Box pb={3}>
						<Typography style={styles.subText}>
							This is how your currency will look:
						</Typography>

						<Box pl={1} pt={1}>
							<Box
								sx={{
									backgroundColor: hexToRgba(
										theme.palette.primary.main,
										0.12
									),
									borderRadius: "8px",
									px: 2,
									py: 1,
									width: "fit-content",
								}}
							>
								<Typography
									fontSize={18}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
									style={{ cursor: "pointer" }}
								>
									{currencyRate?.targetCurrency.symbol}{" "}
									{currencyRate?.targetCurrency.value}
								</Typography>
							</Box>
						</Box>
					</Box>

					<Box>
						<Typography style={styles.subText}>
							Current conversion Rate:
						</Typography>

						<Box pl={1} pt={1}>
							<Typography style={styles.contrastText}>
								{currencyRate?.baseCurrency.value}{" "}
								{currencyRate?.baseCurrency.currencyCode} ={" "}
								{currencyRate?.targetCurrency.value}{" "}
								{currencyRate?.targetCurrency.currencyCode}
							</Typography>

							<Typography
								style={{
									fontSize: "13px",
									fontWeight: 400,
									opacity: 0.6,
								}}
							>
								Updated every day at 12:01 AM
							</Typography>
						</Box>
					</Box>
				</Box>

				<Divider />

				<Box p={2}>
					<Box display="flex">
						<Button
							variant="contained"
							disableElevation
							onClick={() =>
								handleAddCurrency(selectedCurrencyId)
							}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
