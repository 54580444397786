import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import MultiPhoneBaseComponent from "./MultiPhoneBaseComponent";

export default function MultiPhoneComponent({ field, subField, inputRef }) {
	const {
		control,
		formState: { errors },
		clearErrors,
		register,
	} = useFormContext();
	const {
		append: addPhone,
		remove: handleRemovePhone,
		fields: phoneFields,
	} = useFieldArray({
		control,
		name: field.id + "",
	});

	return (
		<React.Fragment>
			<MultiPhoneBaseComponent
				field={field}
				errors={errors}
				addPhone={addPhone}
				subField={subField}
				inputRef={inputRef}
				register={register}
				phoneFields={phoneFields}
				clearErrors={clearErrors}
				handleRemovePhone={handleRemovePhone}
			/>
		</React.Fragment>
	);
}
