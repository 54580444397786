import React from "react";

export default function ContactSync(
	width = "24",
	height = "24",
	color = "#000"
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M10.4851 10.87C8.10509 10.79 6.21509 8.84 6.21509 6.44C6.21509 3.99 8.19509 2 10.6551 2C13.1051 2 15.0951 3.99 15.0951 6.44C15.0851 8.84 13.1951 10.79 10.8151 10.87C10.7151 10.86 10.5951 10.86 10.4851 10.87Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 13.3449C7.83182 13.5498 6.72772 13.9548 5.815 14.56C3.395 16.18 3.395 18.82 5.815 20.43C6.72614 21.0396 7.83048 21.4473 9 21.6529"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13 19L11 17L21 17L19 19"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 17L13 15"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 17L19 15"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
