import { useCallback, useMemo } from "react";
import { useCurrenyList } from "../../services/common/currency";

const useCurrencies = (searchedValue = "") => {
	const { data: currencyList, isLoading } = useCurrenyList();

	const activeCurrencyList = useMemo(() => {
		if (Array.isArray(currencyList)) {
			let activeCurrencyList = currencyList.filter(
				(currency) => currency?.isActive
			);
			if (searchedValue.trim()) {
				return activeCurrencyList.filter(
					(currency) =>
						//filter for search
						currency.name
							.toLowerCase()
							.includes(searchedValue.toLowerCase()) ||
						currency.code
							.toLowerCase()
							.includes(searchedValue.toLowerCase())
				);
			}
			return activeCurrencyList;
		}
		return [];
	}, [currencyList, searchedValue]);

	const getCurrencyDataById = useCallback(
		(currencyId) => {
			const selectedCurrencyData = currencyList?.find(
				(currency) => currency?.id === currencyId
			);

			if (selectedCurrencyData) {
				return selectedCurrencyData;
			}
			return {};
		},
		[currencyList]
	);

	const baseCurrency = useMemo(() => {
		let baseCurrency = currencyList?.find((currency) => currency?.isBase);
		return baseCurrency;
	}, [currencyList]);

	return {
		activeCurrencyList,
		isLoading,
		getCurrencyDataById,
		baseCurrency,
	};
};

export { useCurrencies };
