import React from "react";
import DateComponent from "../../../../AddForm/Field/FieldComponents/DateComponent";

const textFieldProps = {
	sx: {
		width: "100%",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: "8px",
		},
	},
	inputProps: {
		style: { textAlign: "left", fontSize: "14px" },
	},
};
const slotProps = { inputAdornment: { position: "end" } };
const popperProps = { placement: "bottom-end" };

export default function DateFieldComponent({ field }) {
	return (
		<React.Fragment>
			<DateComponent
				field={field}
				popperProps={popperProps}
				slotProps={slotProps}
				textFieldProps={textFieldProps}
			/>
		</React.Fragment>
	);
}
