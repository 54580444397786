import {
	Box,
	Checkbox,
	Chip,
	Collapse,
	MenuItem,
	Select,
	Stack,
	ToggleButton,
	Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../assets/icons/dropDown";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import ToggleButtonGroup from "../../../../../Elements/ToggleButtonGroup";

const StepOptionList = ({
	option,
	selected,
	onFieldChange,
	hasSelectedValue,
}) => {
	const [listOpened, setListOpened] = useState(hasSelectedValue);

	const handleValueChange = (field) => {
		onFieldChange([...selected, field]);
	};

	return (
		<>
			<MenuItem
				style={{ minHeight: "40px" }}
				onClick={() => setListOpened((listOpened) => !listOpened)}
			>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize={14}>{option.source}</Typography>
					<Box pt={0.5}>{DropdownIcon(14, 14, "#000")}</Box>
				</Stack>
			</MenuItem>

			<Collapse in={listOpened} timeout="auto" unmountOnExit>
				{option.fields.map((field, index) => {
					const updatedField = {
						...field,
						value: field.name,
					};

					return (
						<MenuItem
							key={index}
							selected={selected.includes(updatedField.id)}
							style={{ minHeight: "40px" }}
							onClick={() => {
								handleValueChange(updatedField);
							}}
						>
							<Typography fontSize={14} pl={4}>
								{updatedField.value}
							</Typography>
						</MenuItem>
					);
				})}
			</Collapse>
		</>
	);
};

export default function MultiSelectBaseComponent(params) {
	const {
		options,
		selectedValues,
		onFieldChange,
		hasStepOptions,
		stepOptions,
		style,
		hasError,
	} = params;

	const [searchValue, setSearchValue] = useState("");
	const [optionType, setOptionType] = useState("field_options");

	const handleChangeSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const isSelectedValue = (value) => {
		return value?.length > 0;
	};

	const filteredList = useMemo(() => {
		if (Array.isArray(options)) {
			if (searchValue.trim()) {
				return options.filter((value) =>
					value?.value?.name
						.toLowerCase()
						.startsWith(searchValue.toLowerCase())
				);
			}
			return options;
		}
		return [];
	}, [options, searchValue]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const unSelectValue = (value, valueIds) => {
		const updatedValues = valueIds.filter(
			(values) => values.value.id !== value.value.id
		);
		onFieldChange(updatedValues);
	};

	const onChangeFieldValues = (event) => {
		const { value } = event.target;
		onFieldChange(value);
	};

	const handleOptionsType = (_, type) => {
		if (type) {
			setOptionType(type);
			setSearchValue("");
		}
	};

	const getCheckedValue = (value) => {
		if (Array.isArray(selectedValues)) {
			let selectedValueIds = selectedValues.map(
				(value) => value.value.id
			);
			return selectedValueIds.some(
				(selectedId) => selectedId === value.value.id
			);
		}
		return false;
	};

	const renderValue = () => {
		return isSelectedValue(selectedValues)
			? (selectedValue) => (
					<Box>
						{selectedValue.map((value, index) => (
							<Chip
								size="small"
								key={index}
								label={
									<Typography fontSize={14} color="#000">
										{value?.value?.name || ""}
									</Typography>
								}
								color={"secondary"}
								deleteIcon={
									<Stack
										direction="row"
										alignItems="center"
										onMouseDown={(event) =>
											event.stopPropagation()
										}
									>
										{CloseIcon(16, 16, "grey")}
									</Stack>
								}
								onDelete={() =>
									unSelectValue(value, selectedValues)
								}
								sx={{
									borderRadius: "8px",
									marginRight: "4px",
								}}
							/>
						))}
					</Box>
				)
			: () => (
					<Typography fontSize={14} color="rgba(0, 0, 0, 0.4)">
						-Select-
					</Typography>
				);
	};

	return (
		<>
			<Select
				displayEmpty
				value={selectedValues || []}
				multiple
				fullWidth
				MenuProps={{
					style: {
						maxHeight: "340px",
					},
				}}
				onChange={onChangeFieldValues}
				onClose={() => setSearchValue("")}
				sx={{
					backgroundColor: "#fff",
					...style,
				}}
				renderValue={renderValue()}
				error={hasError}
			>
				{hasStepOptions && (
					<Stack direction="row" justifyContent="center" pb={0.5}>
						<ToggleButtonGroup
							value={optionType}
							onChange={handleOptionsType}
						>
							<ToggleButton value="field_options">
								Field Options
							</ToggleButton>

							<ToggleButton value="step_options">
								Step Options
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				)}

				{optionType === "field_options" && (
					<Box p={0.5}>
						<MenuSearchBar
							value={searchValue}
							onChange={handleChangeSearchValue}
						/>
					</Box>
				)}

				{optionType === "field_options" &&
					(filteredList?.length > 0
						? filteredList.map((value, index) => (
								<MenuItem
									key={index}
									value={value}
									style={{
										height: "40px",
									}}
								>
									<Stack
										style={{
											width: "300px",
										}}
										direction="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<Typography
											fontSize={14}
											noWrap
											width="90%"
										>
											{value.value?.name}
										</Typography>

										<Box width="10%">
											<Checkbox
												checked={getCheckedValue(value)}
											/>
										</Box>
									</Stack>
								</MenuItem>
							))
						: renderEmptyView())}

				{optionType === "step_options" &&
					(stepOptions.length > 0
						? stepOptions.map((option, index) => {
								const hasSelectedValue =
									selectedValues &&
									Array.isArray(option.fields) &&
									option.fields.some((field) =>
										selectedValues.includes(field.value.id)
									);
								return (
									<StepOptionList
										key={index}
										option={option}
										hasSelectedValue={hasSelectedValue}
										selected={selectedValues}
										onFieldChange={onFieldChange}
									/>
								);
							})
						: renderEmptyView(true))}
			</Select>
		</>
	);
}
