import {
	Box,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as CopyIcon } from "../../../../assets/icons/copy";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as ExportIcon } from "../../../../assets/icons/export";
import { default as MoreIcon } from "../../../../assets/icons/more";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../styles/twozo";
import { useNavigate } from "react-router-dom";
import CloneProduct from "../CloneProduct";
import Menu from "../../../Elements/Menu";
import { useDeleteProductMutation } from "../../../../hooks/services/product";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";
import { modules } from "../../../../utils/common/ModulesName";
import { useModuleName } from "../../../../hooks/modules";

export default function ProductMenu(props) {
	const { toggleEditFormDialog, productId } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const deleteMutation = useDeleteProductMutation();

	const [productMenuElement, setProductMenuElement] = useState(null);
	const isProductMenuOpened = Boolean(productMenuElement);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isCloneFormOpened, setIsCloneFormOpened] = useState(false);

	const { getModuleName } = useModuleName();

	const { isUserAllowedFor } = useAuth();

	const openProductMenu = (event) => {
		setProductMenuElement(event.currentTarget);
	};

	const closeProductMenu = () => {
		setProductMenuElement(null);
	};

	const handleEditProduct = () => {
		toggleEditFormDialog();
		setProductMenuElement(null);
	};

	const handleCloneProduct = () => {
		toggleCloneForm();
		setProductMenuElement(null);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		setProductMenuElement(null);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
		setProductMenuElement(null);
	};

	const handleDeleteProduct = () => {
		deleteMutation.mutate(productId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.productDeleted,
				});
				navigate("/products");
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const toggleCloneForm = () => {
		setIsCloneFormOpened((isCloneFormOpened) => !isCloneFormOpened);
	};

	const productMenu = [
		{
			id: "edit",
			name: "Edit",
			icon: EditIcon,
			action: handleEditProduct,
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.product.edit),
		},
		{
			id: "clone",
			name: "Clone",
			icon: CopyIcon,
			action: handleCloneProduct,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.product.cloneRecords
			),
		},
		{
			id: "delete",
			name: "Delete",
			icon: DeleteIcon,
			action: openDeleteDialog,
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.product.delete),
		},
		{
			id: "export",
			name: "Export",
			icon: ExportIcon,
			action: () => {},
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.product.exportRecords
			),
		},
	];

	const getProductMenuOptions = () => {
		return productMenu.filter(
			(menuOption) => menuOption.hasPermissionToAccess
		);
	};

	let moduleName = getModuleName(modules.PRODUCT).toLowerCase();

	return (
		<React.Fragment>
			<Menu
				minWidth="200px"
				anchorEl={productMenuElement}
				open={isProductMenuOpened}
				onClose={closeProductMenu}
				style={{
					marginTop: "4px",
					transform: "translateX(-10px)",
				}}
			>
				{getProductMenuOptions().map((menuItem) => (
					<MenuItem
						key={menuItem.id}
						style={{ height: "40px" }}
						onClick={menuItem.action}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							<IconButton size="small">
								{menuItem.icon(
									"20",
									"20",
									theme.palette.primary.main
								)}
							</IconButton>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								{menuItem.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isCloneFormOpened}
				onOpen={toggleCloneForm}
				onClose={toggleCloneForm}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<CloneProduct
						productId={productId}
						onClose={toggleCloneForm}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteProduct}
			></DeleteDialog>

			{getProductMenuOptions().length > 0 ? (
				<IconButton size="small" onClick={openProductMenu}>
					{MoreIcon(20, 20, theme.palette.primary.main)}
				</IconButton>
			) : null}
		</React.Fragment>
	);
}
