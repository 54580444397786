import React from "react";

export default function User(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M11.4851 10.87C9.10509 10.79 7.21509 8.84 7.21509 6.44C7.21509 3.99 9.19509 2 11.6551 2C14.1051 2 16.0951 3.99 16.0951 6.44C16.0851 8.84 14.1951 10.79 11.8151 10.87C11.7151 10.86 11.5951 10.86 11.4851 10.87Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.815 20.43C9.565 22.27 14.075 22.27 16.825 20.43C19.245 18.81 19.245 16.17 16.825 14.56C14.085 12.73 9.575 12.73 6.815 14.56C4.395 16.18 4.395 18.82 6.815 20.43Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
