import React from "react";

function Edit(width = "24", height = "24", color = "white") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 32 32"
			style={{ fill: `${color}` }}
		>
			<path d="M26.756 4.977l.002.002c1.605 1.517 2.335 3.057 2.225 4.625-.106 1.515-.985 2.798-1.986 3.856L13.388 27.709c-.308.335-.723.619-1.115.829-.399.213-.86.396-1.293.473l-4.298.734c-1.041.179-2.039-.081-2.75-.755-.71-.673-1.024-1.655-.907-2.701l.496-4.342c.058-.432.218-.899.407-1.304.188-.404.445-.832.749-1.155l10.315-10.8c.08-.139.193-.258.33-.346l2.972-3.112c1.002-1.058 2.232-2.002 3.734-2.194 1.556-.199 3.13.437 4.729 1.942zm-11.395 6.218l-9.23 9.664a2.59 2.59 0 00-.392.629 2.796 2.796 0 00-.236.715l-.492 4.307c-.057.513.103.844.296 1.028s.528.322 1.034.235l4.291-.733c.167-.03.425-.121.698-.267.277-.148.485-.308.589-.422l.016-.017 9.235-9.669a9.172 9.172 0 01-5.808-5.471zm7.403 3.801l2.782-2.913c.891-.941 1.385-1.798 1.442-2.619.054-.769-.262-1.762-1.603-3.031-1.334-1.255-2.34-1.512-3.104-1.414-.817.104-1.646.646-2.537 1.587l-2.8 2.932a7.168 7.168 0 005.821 5.458z"></path>
		</svg>
	);
}

export default Edit;
