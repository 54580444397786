import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import WebFormPreview from "./WebFormPreview";
import WebFormEditor from "./WebFormEditor";

export default function WebFormBuilder() {
	const menuBarRef = useRef(null);
	const [formToPreview, setFormToPreview] = useState({});
	const [activeStep, setActiveStep] = useState(0);
	const [type, setType] = useState("");
	const [menuBarRowEndingPoistion, setMenuBarRowEndingPoistion] = useState(0);

	const toggleSubmitOptions = (type) => {
		setType(type);
	};

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarRowEndingPoistion(
				menuBarRef.current.getBoundingClientRect().top
			);
		}
	}, [menuBarRef]);

	return (
		<React.Fragment>
			<Box
				ref={menuBarRef}
				height={`calc(100vh - ${menuBarRowEndingPoistion}px)`}
			>
				<Stack direction="row" height="100%">
					<Box width="70%" overflow="auto">
						<WebFormEditor
							activeStep={activeStep}
							setActiveStep={setActiveStep}
							toggleSubmitOptions={toggleSubmitOptions}
							setFormToPreview={setFormToPreview}
						/>
					</Box>

					<Divider orientation="vertical" flexItem />

					<Box width="30%" overflow="auto">
						<WebFormPreview
							formToPreview={formToPreview}
							activeStep={activeStep}
							type={type}
						/>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
