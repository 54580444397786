import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useContactList } from "../../../../../../../hooks/services/contact";
import RelatedContactInput from "./RelatedContactInput";
import AddNewContact from "../../../../../../Deals/AddNewDeals/AddNewContact";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";
import { useAuth } from "../../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../../utils/Auth";

export function ContactsOnBoardBaseComponent(props) {
	const { field, formValues, value, onChange, error, clearErrors } = props;

	const [contactList, setContactList] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [selectedNewContact, setSelectedNewContact] = useState({});
	const [isAddNewContact, setIsAddNewContact] = useState(false);

	const relatedFieldId = field?.relatedField?.id;
	const primaryContactId = formValues?.[field?.config?.associatedWith];

	const contactFormData = {
		[relatedFieldId]: selectedNewContact?.name,
	};

	const { isUserAllowedFor } = useAuth();
	const isContactListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);

	const { data: contacts, status: contactsStatus } =
		useContactList(isContactListEnabled);

	useEffect(() => {
		if (contactsStatus === "success" && contacts?.length > 0) {
			setContactList(
				contacts.filter(
					(contact) => contact?.value !== primaryContactId
				)
			);
		}
	}, [contacts, contactsStatus, primaryContactId]);

	useEffect(() => {
		const getContactValue = (contactId) => {
			let selectedContact = contacts.find(
				(contact) => contact.value === contactId
			);
			return selectedContact;
		};

		if (contactsStatus === "success" && Array.isArray(value)) {
			let updatedContactValue = [];
			for (let index = 0; index < value.length; index++) {
				if (typeof value[index] === "object") {
					updatedContactValue.push({
						name: value[index][relatedFieldId],
					});
				} else {
					const contactValue = getContactValue(value[index]);
					if (contactValue) {
						updatedContactValue.push(contactValue);
					}
				}
			}
			setSelectedContacts(updatedContactValue);
		}
	}, [contacts, contactsStatus, value]);

	const onContactInputChange = (values) => {
		const selectedContacts = [...values];
		const lastContact = selectedContacts[selectedContacts?.length - 1];
		const isNewContact = lastContact?.isNewOption;

		if (isNewContact) {
			setSelectedNewContact(lastContact);
			setIsAddNewContact(true);
		}
		setSelectedContacts(
			selectedContacts.filter((contact) => !contact.isNewOption)
		);

		let contactIdsForRequest = selectedContacts
			.filter((contact) => contact?.value)
			.map((contact) => contact.value);
		onChange([...new Set([...value, ...contactIdsForRequest])]);
	};

	const onAddNewContact = (formSubmitData) => {
		removeFieldsWithEmptyValues(formSubmitData);

		const contactValue = {
			name: formSubmitData[relatedFieldId],
		};
		setSelectedContacts([...selectedContacts, contactValue]);
		setSelectedNewContact({});
		setIsAddNewContact(false);
		onChange([...value, formSubmitData]);
	};

	const onCloseNewContactForm = () => {
		setSelectedNewContact({});
		setIsAddNewContact(false);
	};

	const handleRemoveContact = (removedIndex) => {
		setSelectedContacts((selectedContacts) =>
			selectedContacts.filter((_, index) => index !== removedIndex)
		);
		let contactIdsForRequest = [...value].filter(
			(_, index) => index !== removedIndex
		);
		onChange(contactIdsForRequest);
	};

	return (
		<React.Fragment>
			<Stack spacing={1}>
				{isAddNewContact ? (
					<AddNewContact
						onSubmit={onAddNewContact}
						onClose={onCloseNewContactForm}
						contactFormData={contactFormData}
					/>
				) : (
					<RelatedContactInput
						options={contactList || []}
						value={selectedContacts || []}
						onChange={onContactInputChange}
						onDelete={handleRemoveContact}
						clearErrors={clearErrors}
						error={error}
						placeHolder={field?.placeHolder}
						noOptionsText="No Results Found"
					/>
				)}
			</Stack>
		</React.Fragment>
	);
}
