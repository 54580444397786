import {
	Autocomplete,
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/auth";
import {
	useEffect,
	useState,
	forwardRef,
	useCallback,
	useImperativeHandle,
} from "react";
import { PERMISSIONS } from "../../../../utils/Auth";
import CloseIcon from "../../../../assets/icons/close";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { useContactList } from "../../../../hooks/services/contact";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const DateFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		// permission:-
		const { isUserAllowedFor } = useAuth();
		const isContactListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);

		// query call:-
		const { data: contacts } = useContactList(isContactListEnabled);

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [contactInputValue, setContactInputValue] = useState("");
		const [selectedContact, setSelectedContact] = useState("");

		const CustomPaper = function (props) {
			return (
				<Paper
					{...props}
					style={{
						...props.style,
						borderRadius: "8px",
						boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
						display: contactInputValue ? "block" : "none",
					}}
				/>
			);
		};

		useEffect(() => {
			if (value && !!contacts) {
				let selectedContact = contacts?.find(
					(contact) => contact?.value === value
				);
				setSelectedContact(selectedContact);
			}
		}, [value, contacts]);

		const handleSelectContact = (_, selectedContact, onChange) => {
			setSelectedContact(selectedContact);
			onChange(selectedContact?.value);
			clearErrors(fieldId + "");
			onError(null);
		};

		const handleContactInputChange = (event) => {
			const { value } = event.target;
			setContactInputValue(value);
		};

		const removeContactValue = (onChange) => {
			onChange("");
			setSelectedContact("");
			setContactInputValue("");
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<Autocomplete
							{...fieldData}
							size="small"
							PopperComponent={CustomPopper}
							PaperComponent={CustomPaper}
							filterSelectedOptions
							readOnly={!!selectedContact}
							noOptionsText="No results found"
							options={contacts || []}
							forcePopupIcon={false}
							onChange={(event, value) =>
								handleSelectContact(event, value, onChange)
							}
							value={selectedContact}
							renderOption={(props, option) => (
								<Box
									component="li"
									key={option.value}
									{...props}
									style={{ minHeight: "40px" }}
								>
									<Typography
										fontSize={13}
										key={option.value}
									>
										{option.name}
									</Typography>
								</Box>
							)}
							getOptionLabel={(option) => {
								if (option.name) {
									return option.name;
								}
								return option;
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={field?.placeHolder || "Enter"}
									value={contactInputValue}
									onChange={handleContactInputChange}
									inputProps={{
										...params.inputProps,
										style: {
											fontSize: "14px",
											paddingLeft: 0,
										},
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<InputAdornment position="end">
												{!!selectedContact && (
													<IconButton
														onClick={() =>
															removeContactValue(
																onChange
															)
														}
													>
														{CloseIcon(
															20,
															20,
															theme.palette
																.primary.main
														)}
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											borderRadius: "8px",
										},
										paddingLeft: "10px",
										...textFieldSx,
									}}
								/>
							)}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

DateFieldEditForm.displayName = "DateFieldEditForm";

export default DateFieldEditForm;
