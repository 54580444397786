import React, { useMemo, useState } from "react";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import Menu from "../../Elements/Menu";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { useCurrencies } from "../../../hooks/settings/currency";

export default function CurrencyMenu(props) {
	const {
		selectedCurrency,
		currencyOptions,
		onCurrencyInputChange,
		isProductDetailTable,
		index,
		error,
	} = props;
	const theme = useTheme();

	const [currencyMenu, setCurrencyMenu] = useState(null);
	const openCurrencyMenu = Boolean(currencyMenu);
	const [searchValue, setSearchValue] = useState("");

	const { getCurrencyDataById } = useCurrencies();

	const CloseCurrencyMenu = () => {
		setCurrencyMenu(null);
	};

	const handleCurrencyMenu = (event) => {
		setCurrencyMenu(event.currentTarget);
	};

	const handleCurrencyChange = (currency) => {
		onCurrencyInputChange(currency, index);
		setCurrencyMenu(null);
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const selectedCurrencyCode = useMemo(() => {
		let currencyData = getCurrencyDataById(selectedCurrency);
		return currencyData?.code ?? "";
	}, [selectedCurrency, getCurrencyDataById]);

	const filteredCurrencyList = useMemo(() => {
		if (Array.isArray(currencyOptions)) {
			if (searchValue.trim()) {
				return currencyOptions?.filter(
					(currency) =>
						//filter for search
						currency.name
							.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						currency.code
							.toLowerCase()
							.includes(searchValue.toLowerCase())
				);
			}
			return currencyOptions;
		}
		return [];
	}, [currencyOptions, searchValue]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="300px"
				anchorEl={currencyMenu}
				open={openCurrencyMenu}
				onClose={CloseCurrencyMenu}
				sx={{
					"& .MuiMenu-list": { paddingTop: 0 },
					marginTop: "15px",
					height: "330px",
				}}
			>
				<Box
					p={0.5}
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearch}
					/>
				</Box>
				<Box sx={{ maxHeight: "240px", overflowY: "auto" }}>
					{filteredCurrencyList?.length > 0
						? filteredCurrencyList.map((currency) => (
								<MenuItem
									key={currency.id}
									onClick={() =>
										handleCurrencyChange(currency)
									}
									sx={{
										height: "40px",
										backgroundColor:
											selectedCurrency === currency.id &&
											"rgba(51, 188, 126, 0.12)",
									}}
									value={currency.id}
									onError={error}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										width="100%"
									>
										<Typography fontSize="13px">
											{currency.name}
										</Typography>

										<Typography fontSize="13px">
											{currency.code}
										</Typography>
									</Stack>
								</MenuItem>
							))
						: renderEmptyView()}
				</Box>
			</Menu>

			<Stack
				sx={{ justifyContent: "center", paddingLeft: "16px" }}
				onClick={(event) => handleCurrencyMenu(event)}
			>
				{selectedCurrency ? (
					<Typography
						sx={{
							fontSize: "14px",
							fontWeight: isProductDetailTable ? 400 : 500,
							color: isProductDetailTable
								? "#000"
								: `${theme.palette.secondary.contrastText}`,
						}}
					>
						{selectedCurrencyCode}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							opacity: 0.6,
							color: isProductDetailTable
								? "#000"
								: `${theme.palette.secondary.contrastText}`,
						}}
					>
						Select
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
