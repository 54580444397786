import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Stack, useTheme } from "@mui/material";
import ColumnList from "./ColumnList";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../.././utils/notification/notificationMessages";
import { notificationVariants } from "../../../.././utils/notification/notificationConfig";
import {
	useMappingList,
	useUpdateFieldsMapping,
} from "../../../.././hooks/services/import";
import { styles } from "../Styles";
import ConfirmDialog from "../../.././Elements/ConfirmDialog";
import MappedFileList from "./mappedFileList";
import { hexToRgba } from "../../../../styles/twozo";

export default function Mapping(props) {
	const {
		uploadedFileId,
		goNextStep,
		assignIdOnUpdateFieldsMapping,
		goPreviousStep,
		importedFileStateId,
	} = props;

	const theme = useTheme();
	const [headers, setHeaders] = useState([]);
	const [rows, setRows] = useState([]);
	const [uploadedFileName, setUploadedFileName] = useState("");
	const [fieldsMappingData, setFieldsMappingData] = useState([]);
	const [isUpdateMappingMenuOpen, setIsUpdateMappingMenuOpen] =
		useState(false);

	const getUploadedFileIdOrReadFileId = () => {
		if (uploadedFileId) {
			return uploadedFileId;
		}

		return importedFileStateId;
	};
	const uploadedOrReadFileId = getUploadedFileIdOrReadFileId();

	const updateMappingMutation = useUpdateFieldsMapping();
	const {
		status: uploadedFileStatus,
		data: uploadedFileData,
		isLoading,
		isFetching,
	} = useMappingList(uploadedOrReadFileId);

	useEffect(() => {
		if (uploadedFileStatus === "success") {
			handleReadFileData(uploadedFileData);
		}
	}, [uploadedFileStatus, uploadedFileData]);

	const handleReadFileData = (uploadedFileData) => {
		if (uploadedFileData.headers) {
			setHeaders(uploadedFileData.headers);
		}
		if (uploadedFileData.rows) {
			setRows(uploadedFileData.rows);
		}
		if (uploadedFileData.fieldsMap) {
			setFieldsMappingData(uploadedFileData.fieldsMap);
		}
		if (uploadedFileData?.attachment?.attachment.fileName) {
			setUploadedFileName(
				uploadedFileData.attachment.attachment.fileName
			);
		}
	};

	const handleUpdateFieldsMapping = () => {
		let requestData = getUpdateFieldsMappingRequestData();
		updateMappingMutation.mutate(requestData, {
			onSuccess: (data) => {
				// TODO: notify user that the change is done successfully
				if (data.id) {
					assignIdOnUpdateFieldsMapping(data.id);
					goNextStep();
				}
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const getUpdateFieldsMappingRequestData = () => {
		// deep copy the fields mapping data
		let parsedFieldsMappingData = JSON.parse(
			JSON.stringify(fieldsMappingData)
		);

		let requestData = {};
		let fieldsMap = [];
		for (let index = 0; parsedFieldsMappingData.length > index; index++) {
			if (
				parsedFieldsMappingData[index]?.isMapped &&
				parsedFieldsMappingData[index]?.field
			) {
				delete parsedFieldsMappingData[index].name;
				delete parsedFieldsMappingData[index].isMapped;

				fieldsMap.push(parsedFieldsMappingData[index]);
			}
		}
		requestData.id = uploadedOrReadFileId;
		requestData.fieldsMap = fieldsMap;

		return requestData;
	};

	const hasValidFieldsMappingData = () => {
		return !!fieldsMappingData && fieldsMappingData.length > 0;
	};

	const isEnabledToNextOption = () => {
		if (hasValidFieldsMappingData()) {
			let filteredFieldsMappingData = getFilteredMappedFields();
			return filteredFieldsMappingData.length > 0;
		}
	};

	const isOpenUpdateMappingDialog = () => {
		if (hasValidFieldsMappingData()) {
			let filteredFieldsMappingData = getFilteredMappedFields();
			let filteredUnMappedFields = filteredFieldsMappingData.filter(
				(field) => !field.field
			);

			return filteredUnMappedFields.length > 0;
		}
	};

	const getFilteredMappedFields = () => {
		if (hasValidFieldsMappingData()) {
			let filteredFieldsMappingData = fieldsMappingData.filter(
				(fieldData) => fieldData.isMapped
			);
			return filteredFieldsMappingData;
		}
	};

	const handleSaveAndNext = () => {
		if (!isEnabledToNextOption()) {
			return;
		}

		if (isOpenUpdateMappingDialog()) {
			handleOpenUpdateMappingDialog();
		} else {
			handleUpdateFieldsMapping();
		}
	};

	const handleOpenUpdateMappingDialog = () => {
		setIsUpdateMappingMenuOpen(true);
	};

	const handleCloseUpdateMappingDialog = () => {
		setIsUpdateMappingMenuOpen(false);
	};

	const updateFieldsMapping = (updatedFieldsMappingData) => {
		setFieldsMappingData(updatedFieldsMappingData);
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={isUpdateMappingMenuOpen}
				onCancel={handleCloseUpdateMappingDialog}
				onConfirm={handleUpdateFieldsMapping}
				disableConfirmButton={updateMappingMutation.isLoading}
				title="	Are you sure you want to proceed with columns with empty fields?"
				subtitle="Columns with no values will not be imported, and those columns will remain empty."
				confirmButtonText="Continue importing"
				confirmButtonColor="primary"
			/>

			{isLoading || isFetching || uploadedFileData?.isInProgress ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
					spacing={1}
				>
					<CircularProgress />
				</Stack>
			) : (
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
					}}
				>
					<Box
						style={{
							flex: "1 1 auto",
							overflowY: "auto",
							minHeight: "0px",
						}}
					>
						<Stack direction="row" spacing={1} height="100%">
							<Box
								style={styles.boxContainer}
								width="70%"
								overflow="auto"
							>
								<MappedFileList
									rows={rows}
									headers={headers}
									uploadedFileName={uploadedFileName}
								/>
							</Box>

							<Box
								style={styles.boxContainer}
								width="30%"
								overflow="auto"
								backgroundColor={theme.palette.secondary.main}
								p={1}
								pt={2}
							>
								<ColumnList
									headers={headers}
									fieldsMappingData={fieldsMappingData}
									updateFieldsMapping={updateFieldsMapping}
								/>
							</Box>
						</Stack>
					</Box>

					<Box>
						<Stack
							direction="row"
							justifyContent="space-between"
							p={2}
						>
							<Button
								variant="contained"
								color="secondary"
								onClick={goPreviousStep}
								disableElevation
							>
								Back
							</Button>

							<Button
								variant="contained"
								style={{
									color: isEnabledToNextOption()
										? "#FFFFFF"
										: hexToRgba("#FFFFFF", 0.6),
								}}
								onClick={handleSaveAndNext}
								disableElevation
								disabled={updateMappingMutation.isLoading}
							>
								Save & Next
							</Button>
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
