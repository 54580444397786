import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldName } from "../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../api/fields/config/configApi";

const useCurrenyList = () => {
	const currencyKey = getDropdownKeyByFieldName("permittedCurrencies");
	return useQuery(
		currencyKey,
		() => getFieldDropdownData({ name: "permittedCurrencies" }),
		{
			select: (data) => data.options,
		}
	);
};

export { useCurrenyList };
