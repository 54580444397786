import React from "react";
import { Box } from "@mui/material";
import { StyledToggleButtonGroup } from "../../../styles/twozo";

export default function ToggleButtonGroup(props) {
	const { value, onChange, children } = props;

	return (
		<React.Fragment>
			<Box
				display="inline-block"
				sx={{
					backgroundColor: "secondary.main",
					borderRadius: "8px",
				}}
			>
				<StyledToggleButtonGroup
					value={value}
					onChange={onChange}
					size="small"
					exclusive
				>
					{children}
				</StyledToggleButtonGroup>
			</Box>
		</React.Fragment>
	);
}
