import React from "react";

function Denied(width = "30", height = "30", color = "black", opacity = "1") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 0C6.71625 0 0 6.71625 0 15C0 23.2838 6.71625 30 15 30C23.2838 30 30 23.2838 30 15C30 6.71625 23.2838 0 15 0ZM15 3.75C17.4281 3.75 19.6622 4.54125 21.5006 5.85188L5.84812 21.4969C4.5375 19.6584 3.75 17.4244 3.75 15C3.75 8.79656 8.79656 3.75 15 3.75ZM15 26.25C12.5719 26.25 10.3378 25.4587 8.49938 24.1481L24.1519 8.50312C25.4625 10.3416 26.25 12.5756 26.25 15C26.25 21.2034 21.2034 26.25 15 26.25Z"
				fill={color}
				fillOpacity={opacity}
			/>
		</svg>
	);
}

export default Denied;
