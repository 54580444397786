import Menu from "../Menu";

export default function FieldEditMenu(params) {
	const { open, onClose, children, anchorEl, disableBackdropClick, ...rest } =
		params;

	const handleClose = (_, reason) => {
		if (disableBackdropClick) {
			if (reason !== "backdropClick") {
				onClose();
			}
		} else {
			onClose();
		}
	};

	return (
		<Menu
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			sx={{
				"& .MuiMenu-list": {
					paddingTop: "0px",
					paddingBottom: "0px",
					paddingRight: "3px",
				},
			}}
			anchorOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "none",
				},
			}}
			{...rest}
		>
			{children}
		</Menu>
	);
}
