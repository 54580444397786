import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createActivityType,
	getActivityTypes,
	updateActivityType,
	updateActivityTypeStatus,
	updateActivityTypesData,
} from "../../../api/activityType/activityTypeApi";
import {
	getActivityTypeActiveStatusKey,
	getActivityTypeListKey,
} from "../../../utils/queryKeys/activity";
import { activityTypeIconKey } from "../../../utils/queryKeys";
import { getIconList } from "../../../api/iconList/iconListApi";

//Icon List
const useIconList = () => {
	return useQuery(activityTypeIconKey, () => getIconList("activity"), {
		select: (data) => data.iconList,
	});
};

//Activity Type List
const useActivityTypeList = (isActive) => {
	return useQuery(
		getActivityTypeActiveStatusKey(isActive),
		() => getActivityTypes(isActive),
		{
			retry: false,
			refetchOnWindowFocus: false,
			select: (activityTaskType) => activityTaskType?.activityTypes,
		}
	);
};

//Create Activity Type
const useCreateActivityType = () => {
	const invalidateActivityTypeList = useInvalidateActivityTypeList();
	return useMutation(createActivityType, {
		onSuccess: () => {
			invalidateActivityTypeList();
		},
	});
};

//Update Activity Type
const useUpdateActivityType = () => {
	const invalidateActivityTypeList = useInvalidateActivityTypeList();
	return useMutation(updateActivityType, {
		onSuccess: () => {
			invalidateActivityTypeList();
		},
	});
};

//Update order of activity type
const useUpdateOrderActivityTypeList = () => {
	const invalidateActivityTypeList = useInvalidateActivityTypeList();
	return useMutation(updateActivityTypesData, {
		onSuccess: () => {
			invalidateActivityTypeList();
		},
	});
};

//Update the activity status from enable to disable or vice versa
const useUpdateActivityTypeStatus = () => {
	const invalidateActivityTypeList = useInvalidateActivityTypeList();
	return useMutation(updateActivityTypeStatus, {
		onSuccess: () => {
			invalidateActivityTypeList();
		},
	});
};

//Invalidate activity type list
const useInvalidateActivityTypeList = () => {
	const activityTypeKey = getActivityTypeListKey();
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(activityTypeKey);
	};
};

export {
	useIconList,
	useActivityTypeList,
	useUpdateOrderActivityTypeList,
	useUpdateActivityTypeStatus,
	useCreateActivityType,
	useUpdateActivityType,
};
