import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

export default function TextComponent(props) {
	const {
		style = {},
		field = {},
		onChange,
		hasError,
		placeholder = "-Enter-",
		...rest
	} = props;

	const [fieldValue, setFieldValue] = useState("");

	useEffect(() => {
		if (field?.values && Array.isArray(field.values)) {
			setFieldValue(field.values?.[0]?.value);
		}
	}, [field]);

	const handleChange = (e) => {
		const { value } = e.target;
		if (!field.values || !Array.isArray(field.values)) {
			field.values = [{ value: "" }];
		}
		setFieldValue(value);
		field.values[0].value = value;
		if (onChange) {
			onChange(field.values);
		}
	};

	return (
		<React.Fragment>
			<TextField
				{...rest}
				placeholder={placeholder}
				value={fieldValue}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
						{
							"-webkit-appearance": "none",
						},
					...style,
				}}
				onChange={handleChange}
				error={hasError}
				fullWidth
			/>
		</React.Fragment>
	);
}
