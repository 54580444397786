import { apiClient } from ".";

const LOGIN_URL = "/accounts/crm/login";
const PERMISSION_URL = "/accounts/currentUser/permissions";

export const setupInterceptors = () => {
	// setupRequestInterceptors();
	setupResponseInterceptors();
};

const setupResponseInterceptors = () => {
	// Response interceptor for API calls
	apiClient.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (error) {
			if (error.response.status === 401) {
				// localStorage.setItem("isUserSignedIn", false);
				if (error.config.url === LOGIN_URL) {
					var customError = new Error("Invalid Username / Password");
					return Promise.reject(customError);
				}
				if (error.config.url === PERMISSION_URL) {
					return Promise.reject(error);
				}
				window.location.replace("/login");
			}

			return Promise.reject(error);
		}
	);
};
