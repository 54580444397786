import { useInvalidateContactTableData } from "../../contact";
import { useInvalidateCompanyTableData } from "../../company";
import { useInvalidateProductTableData } from "../../product";
import { useInvalidateDealTableData } from "../../deal";
import { useInvalidateActivityList } from "../../activities";
import { modules } from "../../../../utils/common/ModulesName";

export const useInvalidateAllModuleTables = () => {
	let contactTableList = useInvalidateContactTableData();
	let companyTableList = useInvalidateCompanyTableData();
	let productTableList = useInvalidateProductTableData();
	let dealTableList = useInvalidateDealTableData();
	let activityTableList = useInvalidateActivityList();

	return () => {
		contactTableList();
		companyTableList();
		productTableList();
		dealTableList();
		activityTableList();
	};
};

export const useInvalidateTableDataBySourceName = (sourceName) => {
	let invalidateContactTableData = useInvalidateContactTableData();
	let invalidateCompanyTableData = useInvalidateCompanyTableData();
	let invalidateDealTableData = useInvalidateDealTableData();
	if (sourceName === modules.DEAL) {
		return invalidateDealTableData;
	} else if (sourceName === modules.CONTACT) {
		return invalidateContactTableData;
	} else if (sourceName === modules.COMPANY) {
		return invalidateCompanyTableData;
	}
};
