import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import { TagsBaseComponent } from "./TagsBaseComponent";

export default function TagsComponent({ field, inputRef }) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { value, onChange },
					fieldState: { error },
				}) => (
					<TagsBaseComponent
						value={value}
						field={field}
						inputRef={inputRef}
						error={error}
						clearErrors={clearErrors}
						onChange={onChange}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}
