import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import SingleConversationViewer from "../../Mail/SingleConversationView";
import ActivitySummary from "../../Activities/ActivitySummary";
import {
	useMarkAsReadMutation,
	useReminderNotification,
	useTopNotifications,
} from "../../../hooks/services/notification/appNotification";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationTypes } from "../../../utils/notificationUtils";
import { twozoStyles } from "../../../styles/twozo";

export default function ReminderNotification() {
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [isMailViewerOpened, setIsMailViewerOpened] = useState(false);
	const [openedConversationId, setOpenedConversationId] = useState("");
	const [isActivitySummaryOpened, setIsActivitySummaryOpened] =
		useState(false);
	const [openedActivityId, setOpenedActivityId] = useState(null);

	const { data: topNotifications } = useTopNotifications();
	const { data: reminderData } = useReminderNotification();
	const markAsReadMutation = useMarkAsReadMutation();

	// last three unread notifications
	useEffect(() => {
		if (Array.isArray(topNotifications?.notifications)) {
			for (let notification of topNotifications.notifications) {
				enqueueSnackbar({
					variant: notificationVariants.reminder,
					reminderData: notification,
					onClickReminder: handleReminder,
				});
			}
		}
	}, [topNotifications]);

	// Activity reminder Notifications
	useEffect(() => {
		if (Array.isArray(reminderData?.notifications)) {
			for (let notification of reminderData.notifications) {
				enqueueSnackbar({
					variant: notificationVariants.reminder,
					reminderData: notification,
					onClickReminder: handleReminder,
				});
			}
		}
	}, [reminderData]);

	const handleUpdateMarkAsRead = (notificationId) => {
		markAsReadMutation.mutate(notificationId);
	};

	const handleReminder = (notificationData) => {
		switch (notificationData?.type) {
			case notificationTypes.emailReceived: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.emailReplied: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.emailClicked: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.emailOpened: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.activityReminder: {
				return openActivitySummary(notificationData);
			}
			case notificationTypes.calendarSynced: {
				return handleCalendarSyncNotification(notificationData);
			}
			default:
				return handleUpdateMarkAsRead(notificationData?.id);
		}
	};

	const openMailViewer = (notificationData) => {
		setIsMailViewerOpened(true);
		setOpenedConversationId(notificationData?.data?.id);
		handleUpdateMarkAsRead(notificationData?.id);
	};

	const closeMailViewer = () => {
		setIsMailViewerOpened(false);
		setOpenedConversationId("");
	};

	const openActivitySummary = (notificationData) => {
		setIsActivitySummaryOpened(true);
		setOpenedActivityId(notificationData.data.id);
		handleUpdateMarkAsRead(notificationData?.id);
	};

	const closeActivitySummary = () => {
		setIsActivitySummaryOpened(false);
		setOpenedActivityId("");
	};

	const handleCalendarSyncNotification = (notificationData) => {
		navigate("/settings/email?type=calendar_sync");
		handleUpdateMarkAsRead(notificationData?.id);
	};

	return (
		<React.Fragment>
			{/*Mail Viewer*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMailViewerOpened}
				onOpen={openMailViewer}
				onClose={closeMailViewer}
				disableBackdropClick={true}
			>
				<Box className={classes.addDialogContainer}>
					<SingleConversationViewer
						onClose={closeMailViewer}
						conversationId={openedConversationId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/*Activity Summary*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						backgroundColor: "transparent",
					},
				}}
				open={isActivitySummaryOpened}
				onOpen={openActivitySummary}
				onClose={closeActivitySummary}
				disableBackdropClick={true}
				hideBackdrop
			>
				<Box className={classes.addDialogContainer}>
					<ActivitySummary
						onClose={closeActivitySummary}
						activityId={openedActivityId}
					/>
				</Box>
			</CustomSwipeableDrawer>
		</React.Fragment>
	);
}
