import { modules } from "../../../../utils/common/ModulesName";
import { useInvalidateCompanyTimeline } from "../../company/summary/timeline";
import { useInvalidateContactTimeline } from "../../contact/summary/timeline";
import { useInvalidateDealTimelineData } from "../../deal/summary/timeline";

const useInvalidateTimeline = (sourceName, entityId) => {
	const invalidateDealTimelineData = useInvalidateDealTimelineData(entityId);
	const invalidateContactTimeline = useInvalidateContactTimeline(entityId);
	const invalidateCompanyTimeline = useInvalidateCompanyTimeline(entityId);
	if (sourceName === modules.DEAL) {
		return invalidateDealTimelineData;
	} else if (sourceName === modules.CONTACT) {
		return invalidateContactTimeline;
	} else if (sourceName === modules.COMPANY) {
		return invalidateCompanyTimeline;
	}
};

export { useInvalidateTimeline };
