import { apiClient } from "../..";
import { getDealUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getProductDealList = async (id, dealStateIds, pageParams) => {
	let requestData = getProductDealListRequest(id, dealStateIds, pageParams);
	return await postDealManagementApi(requestData);
};

const getProductDealListRequest = (id, dealStateIds, pageParams) => {
	return {
		type: "getDealsByProduct",
		data: {
			productId: id,
			stateIds: dealStateIds,
			...pageParams,
		},
	};
};

const postDealManagementApi = async (requestData) => {
	let dealManagementUrl = getDealManagementUrl();
	let response = await apiClient.post(dealManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getDealManagementUrl = () => {
	return getDealUrl() + "/mgmt";
};
