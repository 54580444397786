import { useReducer } from "react";

export const emailTemplateActions = {
	newFolderCreated: "NEW_FOLDER_CREATED",
	newFolderCreationError: "NEW_FOLDER_CREATION_ERROR",
	enableFolderNameEdit: "ENABLE_FOLDER_NAME_EDIT",
	disableFolderNameEdit: "DISABLE_FOLDER_NAME_EDIT",
	folderNameChanged: "FOLDER_NAME_CHANGED",
	updateExpandedFolderIds: "UPDATE_EXPANDEND_FOLDER_IDS",
};

export const initialState = {
	isNewFolderCreated: false,
	hasNewFolderCreationError: false,
	isFolderNameEditMode: false,
	isFolderNameChanged: false,
	expandedFolderIds: [],
};

export const reducer = (state, action) => {
	switch (action.type) {
		case emailTemplateActions.newFolderCreated:
			return {
				...state,
				isNewFolderCreated: action.payload,
			};
		case emailTemplateActions.newFolderCreationError:
			return {
				...state,
				hasNewFolderCreationError: action.payload,
			};
		case emailTemplateActions.enableFolderNameEdit:
			return {
				...state,
				isFolderNameEditMode: true,
			};
		case emailTemplateActions.disableFolderNameEdit:
			return {
				...state,
				isFolderNameEditMode: false,
			};
		case emailTemplateActions.folderNameChanged:
			return {
				...state,
				isFolderNameChanged: action.payload,
			};
		case emailTemplateActions.updateExpandedFolderIds:
			return {
				...state,
				expandedFolderIds: action.payload,
			};
		default:
			return state;
	}
};

export const useEmailTemplateReducer = () => {
	const [emailTemplateState, emailTemplateStateDispatcher] = useReducer(
		reducer,
		initialState
	);

	const onNewFolderCreation = (isNewFolderCreated) => {
		emailTemplateStateDispatcher({
			type: emailTemplateActions.newFolderCreated,
			payload: isNewFolderCreated,
		});
	};

	const onNewFolderCreationError = (hasNewFolderCreationError) => {
		emailTemplateStateDispatcher({
			type: emailTemplateActions.newFolderCreationError,
			payload: hasNewFolderCreationError,
		});
	};

	const enableTemplateFolderEditMode = () => {
		emailTemplateStateDispatcher({
			type: emailTemplateActions.enableFolderNameEdit,
		});
	};

	const disableTemplateFolderEditMode = () => {
		emailTemplateStateDispatcher({
			type: emailTemplateActions.disableFolderNameEdit,
		});
	};

	const onFolderNameChanged = (isFolderNameChanged) => {
		emailTemplateStateDispatcher({
			type: emailTemplateActions.folderNameChanged,
			payload: isFolderNameChanged,
		});
	};

	const updateExpandedFolderIds = (folderIds) => {
		emailTemplateStateDispatcher({
			type: emailTemplateActions.updateExpandedFolderIds,
			payload: folderIds,
		});
	};

	return {
		emailTemplateState,
		onNewFolderCreation,
		onNewFolderCreationError,
		enableTemplateFolderEditMode,
		disableTemplateFolderEditMode,
		onFolderNameChanged,
		updateExpandedFolderIds,
	};
};
