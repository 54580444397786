import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { default as NotesIcon } from "../../../../../assets/icons/note";

export default function Notes(props) {
	const { highlightText, matchedNotes, displayLinkedModule } = props;
	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				width="100%"
			>
				<Stack
					direction="row"
					alignItems="center"
					spacing={3}
					width="80%"
				>
					<Box alignItems="start">
						<IconButton
							style={{
								padding: 0,
							}}
						>
							{NotesIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
						</IconButton>
					</Box>

					<Box width="85%">
						<Typography fontSize={14} noWrap>
							{highlightText(matchedNotes.name)}
						</Typography>
					</Box>
				</Stack>

				<Box>{displayLinkedModule()}</Box>
			</Stack>
		</React.Fragment>
	);
}
