import { useCallback, useMemo } from "react";
import { useUserList } from "../../services/userManagement/user";

const useUsers = (searchedValue = "") => {
	const { data: userList, isLoading } = useUserList();

	const activeUserList = useMemo(() => {
		if (Array.isArray(userList)) {
			let activeUsers = userList.filter((user) => user?.config?.isActive);
			if (searchedValue.trim()) {
				return activeUsers.filter((user) =>
					//filter for search
					user.name
						.toLowerCase()
						.startsWith(searchedValue.toLowerCase())
				);
			}
			return activeUsers;
		}
		return [];
	}, [userList, searchedValue]);

	const getUserDataById = useCallback(
		(userId) => {
			const selectedUserData = userList?.find(
				(user) => user?.value === userId
			);

			if (selectedUserData) {
				return selectedUserData;
			}
			return {};
		},
		[userList]
	);

	const loggedInUser = useMemo(() => {
		let loggedInUserData = userList?.find(
			(user) => user?.config?.isLoggedInUser
		);
		return loggedInUserData;
	}, [userList]);

	return { activeUserList, isLoading, getUserDataById, loggedInUser };
};

export { useUsers };
