import { apiClient } from "../..";
import { getMailUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getEmailTemplateFolderList = async (
	templateViewType,
	searchValue
) => {
	let requestData = getEmailTemplateFolderListRequest(
		templateViewType,
		searchValue
	);
	return await postEmailTemplateFolderApi(requestData);
};

export const getEmailTemplateAllFolderList = async () => {
	let requestData = getEmailTemplateAllFolderListRequest();
	return await postEmailTemplateFolderApi(requestData);
};

export const createTemplateFolder = async (folderName) => {
	let requestData = getCreateTemplateFolderRequest(folderName);
	return await postEmailTemplateFolderApi(requestData);
};

export const updateTemplateFolderName = async ({ folderId, folderName }) => {
	let requestData = getUpdateTemplateFolderNameRequest(folderId, folderName);
	return await postEmailTemplateFolderApi(requestData);
};

export const updateTemplateFolderVisibility = async (visibilityData) => {
	let requestData = getUpdateTemplateFolderVisibilityRequest(visibilityData);
	return await postEmailTemplateFolderApi(requestData);
};

export const deleteTemplateFolder = async (folderId) => {
	let requestData = getDeleteTemplateFolderRequest(folderId);
	return await postEmailTemplateFolderApi(requestData);
};

export const getEmailTemplates = async (folderId) => {
	let requestData = getEmailTemplatesRequest(folderId);
	return await postEmailTemplateFolderApi(requestData);
};

export const getTemplateFolderSettingsData = async (folderId) => {
	let requestData = getTemplateFolderSettingsDataRequest(folderId);
	return await postEmailTemplateFolderApi(requestData);
};

const getEmailTemplateFolderListRequest = (templateViewType, searchValue) => {
	return {
		type: "list",
		data: {
			filterType: templateViewType,
			searchText: searchValue,
		},
	};
};

const getEmailTemplateAllFolderListRequest = () => {
	return {
		type: "list",
		data: {
			filterType: 1,
		},
	};
};

const getCreateTemplateFolderRequest = (folderName) => {
	return {
		type: "create",
		data: {
			name: folderName,
		},
	};
};

const getUpdateTemplateFolderNameRequest = (folderId, folderName) => {
	return {
		type: "update",
		data: {
			id: folderId,
			name: folderName,
		},
	};
};

const getUpdateTemplateFolderVisibilityRequest = (visibilityData) => {
	return {
		type: "settings",
		data: visibilityData,
	};
};

const getDeleteTemplateFolderRequest = (folderId) => {
	return {
		type: "delete",
		data: {
			id: folderId,
		},
	};
};

const getEmailTemplatesRequest = (folderId) => {
	return {
		type: "get",
		data: {
			id: folderId,
		},
	};
};

const getTemplateFolderSettingsDataRequest = (folderId) => {
	return {
		type: "getSettings",
		data: {
			id: folderId,
		},
	};
};

const postEmailTemplateFolderApi = async (requestData) => {
	let emailTemplateFolderUrl = getEmailTemplateFolderUrl();
	let response = await apiClient.post(emailTemplateFolderUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getEmailTemplateFolderUrl = () => {
	return getMailUrl() + "/template/folder/mgmt";
};
