import React from "react";

export default function Time(
	width = "24",
	height = "24",
	color = "#000",
	opacity = "1"
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
			opacity={opacity}
		>
			<path
				d="M12 21C10.7632 21 9.59749 20.766 8.50279 20.298C7.40808 19.8301 6.45543 19.1908 5.64485 18.3802C4.83426 17.5696 4.19081 16.617 3.71448 15.5223C3.23816 14.4276 3 13.2535 3 12C3 10.7632 3.23816 9.59749 3.71448 8.50279C4.19081 7.40808 4.83426 6.45125 5.64485 5.63231C6.45543 4.81337 7.40808 4.16992 8.50279 3.70195C9.59749 3.23398 10.7632 3 12 3C13.2368 3 14.4025 3.23398 15.4972 3.70195C16.5919 4.16992 17.5487 4.81337 18.3677 5.63231C19.1866 6.45125 19.8301 7.40808 20.298 8.50279C20.766 9.59749 21 10.7632 21 12C21 13.2535 20.766 14.4276 20.298 15.5223C19.8301 16.617 19.1866 17.5696 18.3677 18.3802C17.5487 19.1908 16.5919 19.8301 15.4972 20.298C14.4025 20.766 13.2368 21 12 21ZM11.3231 7.76323V12.0752C11.3231 12.1755 11.3398 12.2674 11.3733 12.351C11.4067 12.4345 11.4652 12.5181 11.5487 12.6017L14.532 15.585C14.6657 15.7187 14.8329 15.7855 15.0334 15.7855C15.234 15.7855 15.4095 15.7103 15.5599 15.5599C15.7103 15.4095 15.7855 15.234 15.7855 15.0334C15.7855 14.8329 15.7103 14.6574 15.5599 14.507L12.8273 11.7744V7.73816C12.8273 7.5376 12.7563 7.36629 12.6142 7.22423C12.4721 7.08217 12.2925 7.01114 12.0752 7.01114C11.8579 7.01114 11.6783 7.08217 11.5362 7.22423C11.3942 7.36629 11.3231 7.54596 11.3231 7.76323ZM12 19.4958C14.0891 19.4958 15.8607 18.7688 17.3148 17.3148C18.7688 15.8607 19.4958 14.0891 19.4958 12C19.4958 9.91086 18.7688 8.13928 17.3148 6.68524C15.8607 5.2312 14.0891 4.50418 12 4.50418C9.91086 4.50418 8.13928 5.2312 6.68524 6.68524C5.2312 8.13928 4.50418 9.91086 4.50418 12C4.50418 14.0891 5.2312 15.8607 6.68524 17.3148C8.13928 18.7688 9.91086 19.4958 12 19.4958Z"
				fill={color}
			/>
		</svg>
	);
}
