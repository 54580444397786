import {
	Box,
	Button,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import MenuSearchBar from "../../../Elements/MenuSearchBar";

const styles = {
	heading: {
		fontSize: "16px",
		fontWeight: 600,
	},
	subHeading: {
		fontSize: "13px",
		fontWeight: 500,
	},
	menuText: {
		fontSize: "13px",
		fontWeight: 400,
	},
};

const timeZoneList = [
	"(+00:00) UTC - Worldwide",
	"(+00:00) GMT - United Kingdom, Ireland",
	"(+01:00) BST - United Kingdom, Ireland",
	"(+01:00) CET - Most of Western Europe",
	"(+02:00) EET - Eastern Europe, Finland, Greece",
];

const languageList = [
	"English (US)",
	"Spanish (ESP)",
	"French (FRA)",
	"Mandarin Chinese (CHN)",
	"Arabic (EGY)",
];

const dateFormatList = ["Relative Date", "Month/Day/Year", "Day/Month/Year"];

const timeFormatList = ["24 Hours", "12 Hours"];

export default function CrmSettings() {
	const [searchTimeZoneValue, setSearchTimeZoneValue] = useState("");
	const [searchLanguageValue, setSearchLanguageValue] = useState("");

	const [selectedTimeZone, setSelectedTimeZone] = useState(timeZoneList[0]);
	const [selectedLanguage, setSelectedLanguage] = useState(languageList[0]);
	const [selectedDateFormat, setSelectedDateFormat] = useState(
		dateFormatList[0]
	);
	const [selectedTimeFormat, setSelectedTimeFormat] = useState(
		timeFormatList[0]
	);

	const handleSearchTimeZoneValue = (event) => {
		const { value } = event.target;
		setSearchTimeZoneValue(value);
	};

	const handleSearchLanguageValue = (event) => {
		const { value } = event.target;
		setSearchLanguageValue(value);
	};

	const handleChangeTimeZone = (event) => {
		const { value } = event.target;
		setSelectedTimeZone(value);
	};

	const handleChangeLanguage = (event) => {
		const { value } = event.target;
		setSelectedLanguage(value);
	};

	const handleChangeDateFormat = (event) => {
		const { value } = event.target;
		setSelectedDateFormat(value);
	};

	const handleChangeTimeFormat = (event) => {
		const { value } = event.target;
		setSelectedTimeFormat(value);
	};

	return (
		<React.Fragment>
			<Box px={2} pb={0.5}>
				<Typography
					fontSize={14}
					fontWeight={400}
					sx={{ opacity: 0.6 }}
				>
					The date and time formats you select here will apply to all
					users in your CRM account (existing users plus users you add
					in future). Your users can change the language and time zone
					in their personal settings.
				</Typography>
			</Box>

			<Box
				style={{
					border: "1px solid rgb(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				m={1}
			>
				<Box px={2} pt={3}>
					<Typography style={styles.heading}>
						Locale Settings
					</Typography>

					<Stack spacing={2} py={2}>
						<Stack spacing={0.8}>
							<Typography style={styles.subHeading} pl={1}>
								Time Zone
							</Typography>

							<Select
								sx={{ width: "528px" }}
								value={selectedTimeZone}
								onChange={handleChangeTimeZone}
								onOpen={() => setSearchTimeZoneValue("")}
								renderValue={(selectedTimeZone) => {
									let selectedOption = timeZoneList.find(
										(timeZone) =>
											timeZone === selectedTimeZone
									);
									return selectedOption;
								}}
							>
								<Box
									p={0.5}
									marginTop="-8px"
									sx={{
										position: "sticky",
										top: 0,
										zIndex: 1,
										backgroundColor: "#fff",
									}}
								>
									<MenuSearchBar
										value={searchTimeZoneValue}
										onChange={handleSearchTimeZoneValue}
									/>
								</Box>

								{timeZoneList
									.filter((timeZone) =>
										timeZone
											.toLowerCase()
											.includes(
												searchTimeZoneValue.toLowerCase()
											)
									)
									.map((timeZone, index) => (
										<MenuItem key={index} value={timeZone}>
											<Typography
												style={styles.menuText}
												pl={2}
											>
												{timeZone}
											</Typography>
										</MenuItem>
									))}
							</Select>

							<Typography
								fontSize={13}
								fontWeight={400}
								sx={{ opacity: 0.6 }}
								pl={1}
							>
								Timezone is updated automatically to match your
								computer timezone
							</Typography>
						</Stack>

						<Box>
							<Typography
								style={styles.subHeading}
								pb={0.8}
								pl={1}
							>
								Language
							</Typography>

							<Select
								value={selectedLanguage}
								onChange={handleChangeLanguage}
								sx={{ width: "528px" }}
								onOpen={() => setSearchLanguageValue("")}
								renderValue={(selectedLanguage) => {
									let selectedOption = languageList.find(
										(language) =>
											language === selectedLanguage
									);
									return selectedOption;
								}}
							>
								<Box
									p={0.5}
									marginTop="-8px"
									sx={{
										position: "sticky",
										top: 0,
										zIndex: 1,
										backgroundColor: "#fff",
									}}
								>
									<MenuSearchBar
										value={searchLanguageValue}
										onChange={handleSearchLanguageValue}
									/>
								</Box>

								{languageList
									.filter((language) =>
										language
											.toLowerCase()
											.startsWith(
												searchLanguageValue.toLowerCase()
											)
									)
									.map((language, index) => (
										<MenuItem key={index} value={language}>
											<Typography
												style={styles.menuText}
												pl={2}
											>
												{language}
											</Typography>
										</MenuItem>
									))}
							</Select>
						</Box>
					</Stack>

					<Divider />
				</Box>

				<Box px={2} pt={3}>
					<Typography style={styles.heading}>
						Date and Time Formats
					</Typography>

					<Stack spacing={2} py={2}>
						<Box>
							<Typography
								style={styles.subHeading}
								pl={1}
								pb={0.8}
							>
								Date Format
							</Typography>

							<Select
								value={selectedDateFormat}
								onChange={handleChangeDateFormat}
								sx={{ width: "528px" }}
								renderValue={(selectedDateFormat) => {
									let selectedOption = dateFormatList.find(
										(date) => date === selectedDateFormat
									);
									return selectedOption;
								}}
							>
								{dateFormatList.map((date, index) => (
									<MenuItem key={index} value={date}>
										<Typography
											style={styles.menuText}
											pl={2}
										>
											{date}
										</Typography>
									</MenuItem>
								))}
							</Select>
						</Box>

						<Box>
							<Typography
								style={styles.subHeading}
								pb={0.8}
								pl={1}
							>
								Time Format
							</Typography>

							<Select
								value={selectedTimeFormat}
								onChange={handleChangeTimeFormat}
								sx={{ width: "528px" }}
								renderValue={(selectedTimeFormat) => {
									let selectedOption = timeFormatList.find(
										(time) => time === selectedTimeFormat
									);
									return selectedOption;
								}}
							>
								{timeFormatList.map((time, index) => (
									<MenuItem key={index} value={time}>
										<Typography
											style={styles.menuText}
											pl={2}
										>
											{time}
										</Typography>
									</MenuItem>
								))}
							</Select>
						</Box>
					</Stack>

					<Divider />
				</Box>

				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					px={2}
					py={3}
				>
					<Box display="flex">
						<Button variant="contained" disableElevation>
							Save
						</Button>
					</Box>

					<Box display="flex">
						<Button
							variant="contained"
							color="secondary"
							disableElevation
						>
							Cancel
						</Button>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
