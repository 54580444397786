export default function DropdownParser(field) {
	return field.values.map((value) => {
		const result = {
			value: value.value.id || value.value.value,
		};
		if (value?.id) {
			result.id = value.id;
		}
		if (value?.dependsOn) {
			result.dependsOn = value.dependsOn;
		}

		return result;
	});
}
