import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { getNoteListKey } from "../../../../utils/queryKeys/summary/notes";
import {
	createNote,
	deleteNote,
	getNotes,
	updateNote,
} from "../../../../api/note/noteApi";
import { notePageSize } from "../../../../utils/queryConstants/summary/notes";
import { useInvalidateTimeline } from "../timeline";
import { useInvalidateDealSummaryData } from "../../deal";
import { modules } from "../../../../utils/common/ModulesName";

const noteListSelect = (notesData) => {
	return notesData.pages.flatMap((page) =>
		page?.notes?.length > 0 ? page?.notes : []
	);
};

const useNoteList = (sourceName, entityId) => {
	return useInfiniteQuery({
		queryKey: getNoteListKey(sourceName, entityId),
		queryFn: ({ pageParam }) => {
			return getNotes(sourceName, entityId, {
				start: pageParam ? (pageParam - 1) * notePageSize + 1 : 1,
				limit: notePageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: noteListSelect,
	});
};

const useInvalidateNoteList = (sourceName, entityId) => {
	let queryClient = useQueryClient();
	let noteListKey = getNoteListKey(sourceName, entityId);
	return () => {
		queryClient.invalidateQueries(noteListKey);
	};
};

const useCreateNote = (sourceName, entityId) => {
	let invalidateNoteList = useInvalidateNoteList(sourceName, entityId);
	let invalidateTimeline = useInvalidateTimeline(sourceName, entityId);
	let invalidateDealSummary = useInvalidateDealSummaryData(entityId);
	return useMutation(createNote, {
		onSuccess: () => {
			invalidateNoteList();
			invalidateTimeline();
			if (sourceName === modules.DEAL) {
				invalidateDealSummary();
			}
		},
	});
};

const useUpdateNote = (sourceName, entityId) => {
	let invalidateNoteList = useInvalidateNoteList(sourceName, entityId);
	let invalidateTimelineData = useInvalidateTimeline(sourceName, entityId);
	let invalidateDealSummary = useInvalidateDealSummaryData(entityId);
	return useMutation(updateNote, {
		onSuccess: () => {
			invalidateNoteList();
			invalidateTimelineData();
			if (sourceName === modules.DEAL) {
				invalidateDealSummary();
			}
		},
	});
};

const useDeleteNote = (sourceName, entityId) => {
	let invalidateNoteList = useInvalidateNoteList(sourceName, entityId);
	let invalidateTimelineData = useInvalidateTimeline(sourceName, entityId);
	let invalidateDealSummary = useInvalidateDealSummaryData(entityId);
	return useMutation(deleteNote, {
		onSuccess: () => {
			invalidateNoteList();
			invalidateTimelineData();
			if (sourceName === modules.DEAL) {
				invalidateDealSummary();
			}
		},
	});
};

export {
	useNoteList,
	useInvalidateNoteList,
	useCreateNote,
	useUpdateNote,
	useDeleteNote,
};
