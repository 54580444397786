import postFieldsApi from "../fields/postFieldsApi";

export const getUserTeamTerritoryList = async () => {
	let requestData = getUserTeamTerritoryListRequest();
	return await postFieldsApi(requestData);
};

const getUserTeamTerritoryListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "userTeamTerritoryList",
		},
	};
};
