const fieldAlignments = [
	{
		value: 1,
		name: "Left",
	},
	{
		value: 2,
		name: "Right",
	},
];

export default fieldAlignments;
