import React from "react";

function TodoList(
	width = "20",
	height = "20",
	color = "#33BC7E",
	opacity = "1"
) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			style={{ fill: "none" }}
			xmlns="http://www.w3.org/2000/svg"
			opacity={opacity}
		>
			<path
				d="M9.16602 13.3333H14.9993"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.16602 7.5H17.4993"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.5 7.49992L3.33333 8.33325L5.83333 5.83325"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.5 13.3332L3.33333 14.1665L5.83333 11.6665"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default TodoList;
