import { apiClient } from "../..";
import { getAccountSettingsUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getAccountAdminDetails = async () => {
	let requestData = getAccountAdminDetailsRequestData();
	return await postAccountSettingsManagement(requestData);
};

export const updateAccountAdminDetails = async ({
	name,
	mobile,
	organizationName,
}) => {
	let requestData = updateAccountAdminDetailsRequestData(
		name,
		mobile,
		organizationName
	);
	return await postAccountSettingsManagement(requestData);
};

const getAccountAdminDetailsRequestData = () => {
	return {
		type: "get",
		data: {},
	};
};

const updateAccountAdminDetailsRequestData = (
	name,
	mobile,
	organizationName
) => {
	return {
		type: "update",
		data: {
			name: name,
			mobile: mobile,
			organizationName: organizationName,
		},
	};
};

const postAccountSettingsManagement = async (requestData) => {
	let accountSettingsManagementUrl = getAccountSettingsManagementUrl();
	let response = await apiClient.post(
		accountSettingsManagementUrl,
		requestData
	);
	assertError(response, accountSettingsManagementUrl);

	return response.data.data;
};

const getAccountSettingsManagementUrl = () => {
	return getAccountSettingsUrl() + "/mgmt";
};
