import React, { useEffect, useRef } from "react";
import {
	Box,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableContainer,
	Typography,
	useTheme,
} from "@mui/material";
import { useState } from "react";
import Sources from "./Sources";
import Actions from "./Actions";
import QuotasAndForecasting from "./QuotasAndForecasting";
import ActivityGoals from "./ActivityGoals";
import Email from "./Emails";
import Phone from "./Phone";
import LeadGeneration from "./LeadGeneration";
import Sms from "./Sms";
import UserSettings from "./UserSettings";
import AdminAccess from "./AdminAccess";
import { usePermission } from "../../../../../../hooks/services/userManagement/roles/permission";
import { usePermissionContext } from "../../PermissionContext";
import {
	actionsIds,
	permissionTypes,
	scope,
	sourceIds,
} from "../../../../../../utils/PermissionUtils";
import { useRoleDetails } from "../../../../../../hooks/services/userManagement/roles";

export default function Permissions(props) {
	const { roleId, isEditable, isCreateRole, defaultSelectedAccess } = props;
	const theme = useTheme();
	const sourceRef = useRef(null);
	const actionRef = useRef(null);
	const quotasRef = useRef(null);
	const activityRef = useRef(null);
	const emailRef = useRef(null);
	const phoneRef = useRef(null);
	const leadGenerationRef = useRef(null);
	const smsRef = useRef(null);
	const userSettingRef = useRef(null);
	const adminAccessRef = useRef(null);

	const [permissions, setPermissions] = useState({});
	const {
		permissionState,
		updateSourceList,
		updateActionsList,
		initializeSourceListForPermission,
	} = usePermissionContext();
	const {
		data: rolePermissionData,
		isLoading: rolePermissionLoading,
		status: rolePermissionStatus,
	} = usePermission(roleId, isCreateRole);

	const {
		data: roleDetails,
		isLoading: roleDetailsLoading,
		status: roleDetailsStatus,
	} = useRoleDetails(roleId, !isCreateRole);

	const isPermissionDataLoading = isCreateRole
		? rolePermissionLoading
		: roleDetailsLoading;
	const permissionData = isCreateRole
		? rolePermissionData
		: roleDetails?.permissions;
	const permissionDataStatus = isCreateRole
		? rolePermissionStatus
		: roleDetailsStatus;

	useEffect(() => {
		if (permissionDataStatus === "success") {
			setPermissions(permissionData);
		}
	}, [permissionDataStatus, permissionData]);

	const moduleList = [
		{
			id: 1,
			name: "Sources",
			ref: sourceRef,
		},
		{
			id: 2,
			name: "Actions",
			ref: actionRef,
		},
		{
			id: 3,
			name: "Quotes and Forecasting",
			ref: quotasRef,
		},
		{
			id: 4,
			name: "Activity Goals",
			ref: activityRef,
		},
		{
			id: 5,
			name: "Emails",
			ref: emailRef,
		},
		{
			id: 6,
			name: "Phone",
			ref: phoneRef,
		},
		{
			id: 7,
			name: "Lead Generation",
			ref: leadGenerationRef,
		},
		{
			id: 8,
			name: "SMS",
			ref: smsRef,
		},
		{
			id: 9,
			name: "User Settings",
			ref: userSettingRef,
		},
		{
			id: 10,
			name: "Admin Access",
			ref: adminAccessRef,
		},
	];

	const [seletedModuleId, setSelectedModuleId] = useState(moduleList[0].id);

	const handleScroll = (module) => {
		setSelectedModuleId(module.id);
		module.ref.current.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		if (permissions?.sources) {
			initializeSourceListForPermission(
				permissions?.sources,
				isCreateRole,
				defaultSelectedAccess
			);
		}
	}, [permissions]);

	const updateSourcePermission = (
		entityList,
		accessPredicate,
		valuePredicate,
		disabledPredicate
	) => {
		return entityList?.map((entity) => {
			return {
				...entity,
				access: accessPredicate(entity),
				permissions: {
					...entity.permissions,
					permission: entity.permissions.permission.map(
						(permission) => {
							return {
								...permission,
								value: valuePredicate(entity, permission),
								disabled: disabledPredicate(entity, permission),
							};
						}
					),
				},
			};
		});
	};

	const updateActionPermission = (
		entityList,
		valuePredicate,
		disabledPredicate
	) => {
		return entityList?.map((action) => {
			if (action.permissions?.permission) {
				const updatedPermissions = action.permissions.permission.map(
					(permission) => ({
						...permission,
						value: valuePredicate(action, permission),
						disabled: disabledPredicate(action, permission),
					})
				);

				const hasNoPermission = updatedPermissions.some(
					(permission) => !permission.disabled
				);

				if (!hasNoPermission) {
					// If no permission is true, disable the access
					action.access = false;
					action.accessDisable = true;
				} else {
					action.accessDisable = false;
				}

				return {
					...action,
					permissions: {
						...action.permissions,
						permission: updatedPermissions,
					},
				};
			}
			return action;
		});
	};

	const handleActionBasedOnViewAccess = (sourceId) => {
		const actionIdsToCheck = [
			actionsIds.shareView,
			actionsIds.accessView,
			actionsIds.exportRecords,
		];
		const isAccessViewCheckedForSelectedActions = true;

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return entity.id === actionsIds.accessView;
				} else return !isAccessViewCheckedForSelectedActions;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionBasedOnViewAndCreateAccess = (sourceId) => {
		const isAccessViewCheckedForSelectedActions = true;
		const actionIdsToCheck = [
			actionsIds.shareView,
			actionsIds.accessView,
			actionsIds.exportRecords,
			actionsIds.cloneRecords,
		];

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return entity.id === actionsIds.accessView;
				} else return !isAccessViewCheckedForSelectedActions;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionBasedOnViewAndEditAccess = (sourceId) => {
		const isAccessViewCheckedForSelectedActions = true;
		const actionIdsToCheck = [
			actionsIds.shareView,
			actionsIds.accessView,
			actionsIds.exportRecords,
			actionsIds.assignRecords,
			actionsIds.bulkAssignRecords,
			actionsIds.bulkUpdateRecords,
			actionsIds.mergeRecords,
		];

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return entity.id === actionsIds.accessView;
				} else return !isAccessViewCheckedForSelectedActions;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionIfDeleteAccessRestricted = (sourceId) => {
		const isAccessViewCheckedForSelectedActions = false;
		const actionIdsToCheck = [
			actionsIds.restoreRecords,
			actionsIds.accessRecycleBin,
		];

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return false;
				} else return entity.id === actionsIds.accessView;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionBasedOnViewAndDeleteAccess = (sourceId) => {
		const isAccessViewCheckedForSelectedActions = true;
		const actionIdsToCheck = [
			actionsIds.shareView,
			actionsIds.accessView,
			actionsIds.exportRecords,
			actionsIds.accessRecycleBin,
		];

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return entity.id === actionsIds.accessView;
				} else return !isAccessViewCheckedForSelectedActions;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionIfEditAccessRestricted = (sourceId) => {
		const isAccessViewCheckedForSelectedActions = true;
		const actionIdsToCheck = [
			actionsIds.shareView,
			actionsIds.accessView,
			actionsIds.exportRecords,
			actionsIds.accessRecycleBin,
			actionsIds.cloneRecords,
		];

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return isAccessViewCheckedForSelectedActions
						? entity.id === actionsIds.accessView
						: false;
				} else return !isAccessViewCheckedForSelectedActions;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionIfCreateAccessRestricted = (sourceId) => {
		const isAccessViewCheckedForSelectedActions = false;
		const actionIdsToCheck = [
			actionsIds.cloneRecords,
			actionsIds.restoreRecords,
			actionsIds.importRecords,
		];

		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return false;
				} else return entity.id === actionsIds.accessView;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(entity.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	const handleActionBasedOnAllAccess = (sourceId) => {
		const valuePredicateForActions = (entity, permission) => {
			if (permission.id === sourceId) {
				return entity.id === actionsIds.accessView;
			}
			return permission.value;
		};

		const disabledPredicateForActions = (_, permission) => {
			if (permission.id === sourceId) {
				return false;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	// Check the permissions assigned to source
	const hasOnlyViewAccess = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view
					? permission.value
					: !permission.value;
			})
		);
	};

	const hasViewAndCreateAccess = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view ||
					permission.id === permissionTypes.create
					? permission.value
					: !permission.value;
			})
		);
	};

	const hasViewAndEditAccess = (source) => {
		return (
			source &&
			source.access &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view
					? permission.value
					: permission.id === permissionTypes.edit
						? permission.value
						: !permission.value;
			})
		);
	};

	const isDeleteAccessRestricted = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view ||
					permission.id === permissionTypes.create ||
					permission.id === permissionTypes.edit
					? permission.value
					: !permission.value;
			})
		);
	};

	const hasViewAndDeleteAccess = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view ||
					permission.id === permissionTypes.delete
					? permission.value
					: !permission.value;
			})
		);
	};

	const isEditAccessRestricted = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view ||
					permission.id === permissionTypes.create ||
					permission.id === permissionTypes.delete
					? permission.value
					: !permission.value;
			})
		);
	};

	const isCreateAccessRestricted = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view ||
					permission.id === permissionTypes.edit ||
					permission.id === permissionTypes.delete
					? permission.value
					: !permission.value;
			})
		);
	};

	const hasAllAccess = (source) => {
		return (
			source &&
			source.permissions.permission.every((permission) => {
				if (permission.id === scope.scope) {
					return true;
				}
				return permission.id === permissionTypes.view ||
					permission.id === permissionTypes.create ||
					permission.id === permissionTypes.edit ||
					permission.id === permissionTypes.delete
					? permission.value
					: !permission.value;
			})
		);
	};

	const disableActions = (actionsList, disabledPredicate) => {
		const list = actionsList?.map((action) => {
			if (action.permissions?.permission) {
				const updatedPermissions = action.permissions.permission.map(
					(permission) => ({
						...permission,
						disabled: disabledPredicate(action, permission),
					})
				);

				const hasNoPermission = updatedPermissions.some(
					(permission) => !permission.disabled
				);

				if (!hasNoPermission) {
					// If no permission is true, disable the access
					action.access = false;
					action.accessDisable = true;
				} else {
					action.accessDisable = false;
				}

				return {
					...action,
					permissions: {
						...action.permissions,
						permission: updatedPermissions,
					},
				};
			}
			return action;
		});
		return list;
	};

	useEffect(() => {
		let updatedActionList = [...permissionState.actionsList];

		const disabledPredicateForActions = (
			action,
			permission,
			sourceId,
			actionIdsToCheck,
			isAccessViewCheckedForSelectedActions
		) => {
			if (permission.id === sourceId) {
				if (actionIdsToCheck.includes(action.id)) {
					return !isAccessViewCheckedForSelectedActions;
				} else return isAccessViewCheckedForSelectedActions;
			}
			return permission.disabled ? permission.disabled : false;
		};

		const updateActionsBasedOnSourcePermissions = (
			source,
			actionIdsToCheck,
			isAccessViewCheckedForSelectedActions
		) => {
			const sourceId = source?.id;
			const disabledPredicate = (action, permission) =>
				disabledPredicateForActions(
					action,
					permission,
					sourceId,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);

			updatedActionList = disableActions(
				updatedActionList,
				disabledPredicate
			);
		};

		permissionState.sourceList.forEach((source) => {
			const sourceId = source?.id;
			if (hasOnlyViewAccess(source)) {
				const isAccessViewCheckedForSelectedActions = true;
				const actionIdsToCheck = [
					actionsIds.shareView,
					actionsIds.accessView,
					actionsIds.exportRecords,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (hasViewAndCreateAccess(source)) {
				const isAccessViewCheckedForSelectedActions = true;
				const actionIdsToCheck = [
					actionsIds.shareView,
					actionsIds.accessView,
					actionsIds.exportRecords,
					actionsIds.cloneRecords,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (hasViewAndEditAccess(source)) {
				const isAccessViewCheckedForSelectedActions = true;
				const actionIdsToCheck = [
					actionsIds.shareView,
					actionsIds.accessView,
					actionsIds.exportRecords,
					actionsIds.assignRecords,
					actionsIds.bulkAssignRecords,
					actionsIds.bulkUpdateRecords,
					actionsIds.mergeRecords,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (isDeleteAccessRestricted(source)) {
				const isAccessViewCheckedForSelectedActions = false;
				const actionIdsToCheck = [
					actionsIds.restoreRecords,
					actionsIds.accessRecycleBin,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (hasViewAndDeleteAccess(source)) {
				const isAccessViewCheckedForSelectedActions = true;
				const actionIdsToCheck = [
					actionsIds.shareView,
					actionsIds.accessView,
					actionsIds.exportRecords,
					actionsIds.accessRecycleBin,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (isEditAccessRestricted(source)) {
				const isAccessViewCheckedForSelectedActions = true;
				const actionIdsToCheck = [
					actionsIds.shareView,
					actionsIds.accessView,
					actionsIds.exportRecords,
					actionsIds.accessRecycleBin,
					actionsIds.cloneRecords,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (isCreateAccessRestricted(source)) {
				const isAccessViewCheckedForSelectedActions = false;
				const actionIdsToCheck = [
					actionsIds.cloneRecords,
					actionsIds.restoreRecords,
					actionsIds.importRecords,
				];

				updateActionsBasedOnSourcePermissions(
					source,
					actionIdsToCheck,
					isAccessViewCheckedForSelectedActions
				);
			} else if (hasAllAccess(source)) {
				const disabledPredicate = (_, permission) => {
					if (permission.id === sourceId) {
						return false;
					}
					return permission.disabled ? permission.disabled : false;
				};

				updatedActionList = disableActions(
					updatedActionList,
					disabledPredicate
				);
			} else if (source.access === false) {
				const disabledPredicate = (_, permission) => {
					if (permission.id === sourceId) {
						return true;
					}
					return permission.disabled ? permission.disabled : false;
				};

				updatedActionList = disableActions(
					updatedActionList,
					disabledPredicate
				);
			}
			updateActionsList(updatedActionList);
		});
	}, [permissionState.sourceList]);

	const handleActionBasedOnSource = (sourceId, sourceList) => {
		const source = sourceList.find((source) => source.id === sourceId);

		if (hasOnlyViewAccess(source)) {
			handleActionBasedOnViewAccess(sourceId);
		} else if (hasViewAndCreateAccess(source)) {
			handleActionBasedOnViewAndCreateAccess(sourceId);
		} else if (hasViewAndEditAccess(source)) {
			handleActionBasedOnViewAndEditAccess(sourceId);
		} else if (isDeleteAccessRestricted(source)) {
			handleActionIfDeleteAccessRestricted(sourceId);
		} else if (hasViewAndDeleteAccess(source)) {
			handleActionBasedOnViewAndDeleteAccess(sourceId);
		} else if (isEditAccessRestricted(source)) {
			handleActionIfEditAccessRestricted(sourceId);
		} else if (isCreateAccessRestricted(source)) {
			handleActionIfCreateAccessRestricted(sourceId);
		} else if (hasAllAccess(source)) {
			handleActionBasedOnAllAccess(sourceId);
		}
	};

	const handleSourcePermissionChange = (
		event,
		selectedPermission,
		sourceId
	) => {
		//Source
		const accessPredicate = (entity) => {
			if (entity.id === sourceId) {
				if (selectedPermission.id === permissionTypes.view) {
					return event.target.checked;
				}
				return entity.access;
			}
			return entity.access;
		};

		const valuePredicate = (entity, permission) => {
			if (entity.id === sourceId) {
				if (
					selectedPermission.id === permissionTypes.view ||
					selectedPermission.id === permission.id
				) {
					return event.target.checked;
				}
				return permission.value;
			}
			return permission.value;
		};

		const disabledPredicate = (entity, permission) => {
			if (
				selectedPermission.id === permissionTypes.view &&
				entity.id === sourceId
			) {
				return !event.target.checked;
			}
			return permission.disabled;
		};

		const updatedSourceList = updateSourcePermission(
			permissionState.sourceList,
			accessPredicate,
			valuePredicate,
			disabledPredicate
		);
		updateSourceList(updatedSourceList);

		//Actions
		const valuePredicateForActions = (_, permission) => {
			if (
				selectedPermission.id === permissionTypes.view &&
				permission.id === sourceId
			) {
				if (event.target.checked) {
					return permission.value;
				} else {
					return event.target.checked;
				}
			}
			return permission.value;
		};

		const disabledPredicateForActions = (_, permission) => {
			if (
				selectedPermission.id === permissionTypes.view &&
				permission.id === sourceId
			) {
				if (event.target.checked) {
					return permission.value;
				} else {
					return !event.target.checked;
				}
			}
			return permission.disabled;
		};

		if (selectedPermission.id === permissionTypes.view) {
			const updatedActionList = updateActionPermission(
				permissionState.actionsList,
				valuePredicateForActions,
				disabledPredicateForActions
			);
			updateActionsList(updatedActionList);
		} else {
			//To Restrict some access in actions based on source
			handleActionBasedOnSource(sourceId, updatedSourceList);
		}
	};

	const handleSourceAccessChange = (event, sourceId) => {
		const accessPredicate = (entity) => {
			if (entity.id === sourceId) {
				return event.target.checked;
			}
			return entity.access;
		};

		const valuePredicate = (entity, permission) => {
			// If contact no access, restrict the permission to create deals as the contact is mandatory for deal creation
			if (sourceId === sourceIds.contacts && !event.target.checked) {
				if (
					entity.id === sourceIds.deals &&
					permission.id === permissionTypes.create
				) {
					return event.target.checked;
				}
			}

			if (entity.id === sourceId) {
				return event.target.checked;
			}
			return permission.value;
		};

		const disabledPredicate = (entity, permission) => {
			if (sourceId === sourceIds.contacts) {
				if (
					entity.id === sourceIds.deals &&
					permission.id === permissionTypes.create
				) {
					return !event.target.checked;
				}
			}

			if (entity.id === sourceId) {
				return !event.target.checked;
			}
			return permission.disabled;
		};

		const updatedSourceList = updateSourcePermission(
			permissionState.sourceList,
			accessPredicate,
			valuePredicate,
			disabledPredicate
		);
		updateSourceList(updatedSourceList);

		//To disable the respective module in action
		const actionIdsToCheck = [
			actionsIds.cloneRecords,
			actionsIds.restoreRecords,
			actionsIds.importRecords,
		];

		const valuePredicateForActions = (action, permission) => {
			if (
				sourceId === sourceIds.contacts &&
				permission.id === sourceIds.deals &&
				!event.target.checked
			) {
				//If deal create access is restricted need to restrict deal actions
				return actionIdsToCheck.includes(action.id)
					? false
					: action.id === actionsIds.accessView;
			}
			if (permission.id === sourceId) {
				if (event.target.checked) {
					return action.id === actionsIds.accessView
						? event.target.checked
						: !event.target.checked;
				} else {
					return event.target.checked;
				}
			}
			return permission.value;
		};

		const disabledPredicateForActions = (_, permission) => {
			if (permission.id === sourceId) {
				return !event.target.checked;
			}
			return permission.disabled;
		};

		const updatedActionList = updateActionPermission(
			permissionState.actionsList,
			valuePredicateForActions,
			disabledPredicateForActions
		);
		updateActionsList(updatedActionList);
	};

	return (
		<React.Fragment>
			<Stack direction="row" spacing={1} height="100%">
				<Box width="15%" overflow="auto">
					{moduleList.map((module) => (
						<Stack
							key={module.id}
							style={{
								cursor: "pointer",
							}}
							onClick={() => handleScroll(module)}
						>
							<Typography
								fontSize={13}
								p={1}
								style={{
									backgroundColor:
										module.id === seletedModuleId
											? theme.palette.secondary.main
											: "transparent",
									borderRadius: "8px",
								}}
							>
								{module.name}
							</Typography>
						</Stack>
					))}
				</Box>

				{isPermissionDataLoading ? (
					<Box
						width="85%"
						border="1px solid rgba(0, 0, 0, 0.10)"
						borderRadius="8px"
					>
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100%"
						>
							<CircularProgress />
						</Stack>
					</Box>
				) : (
					<Box
						width="85%"
						border="1px solid rgba(0, 0, 0, 0.10)"
						borderRadius="8px"
						overflow="auto"
					>
						<TableContainer>
							<Table size="small">
								<TableBody ref={sourceRef}>
									<Sources
										handleAccessChange={
											handleSourceAccessChange
										}
										onChange={handleSourcePermissionChange}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={actionRef}>
									<Actions
										permissions={permissions?.actions}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={quotasRef}>
									<QuotasAndForecasting
										permissions={
											permissions?.quotasAndForecasting
										}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={activityRef}>
									<ActivityGoals
										permissions={permissions?.activityGoals}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={emailRef}>
									<Email
										permissions={permissions?.emails}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={phoneRef}>
									<Phone
										permissions={permissions?.phone}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={leadGenerationRef}>
									<LeadGeneration
										permissions={
											permissions?.leadGeneration
										}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={smsRef}>
									<Sms
										permissions={permissions?.sms}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={userSettingRef}>
									<UserSettings
										permissions={permissions?.userSettings}
										isEditable={isEditable}
									/>
								</TableBody>
								<TableBody ref={adminAccessRef}>
									<AdminAccess
										permissions={permissions?.adminAccess}
										isEditable={isEditable}
									/>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</Stack>
		</React.Fragment>
	);
}
