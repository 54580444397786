import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import {
	emailsIds,
	shareEmailTemplatesFolderPermissions,
} from "../../../../../../../utils/PermissionUtils";

export default function Email(props) {
	const { permissions, isEditable } = props;

	const {
		permissionState,
		updateEmailList,
		initializeEmailListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeEmailListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		if (id === emailsIds.shareEmailTemplatesFolder) {
			if (!event.target.checked) {
				const updatedEmailList = permissionState.emailList.map(
					(list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
							};

							if (list.permissions.permission) {
								updatedList.permissions = {
									...list.permissions,
									permission: list.permissions.permission.map(
										(permission) => ({
											...permission,
											value:
												!event.target.checked &&
												event.target.checked,
											disabled: !event.target.checked,
										})
									),
								};
							}

							return updatedList;
						} else {
							return list;
						}
					}
				);
				updateEmailList(updatedEmailList);
			} else {
				const updatedEmailList = permissionState.emailList.map(
					(list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
							};

							if (list.permissions.permission) {
								updatedList.permissions = {
									...list.permissions,
									permission: list.permissions.permission.map(
										(permission, index) => ({
											...permission,
											value: index === 0 ? true : false,
											disabled: !event.target.checked,
										})
									),
								};
							}

							return updatedList;
						} else {
							return list;
						}
					}
				);
				updateEmailList(updatedEmailList);
			}
		} else if (id === emailsIds.viewEmailConversation) {
			const updatedEmailList = permissionState.emailList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						access: event.target.checked,
					};
				} else {
					return list;
				}
			});
			updateEmailList(updatedEmailList);
		} else {
			const updatedEmailList = permissionState.emailList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						access: event.target.checked,
					};
				} else {
					return list;
				}
			});
			updateEmailList(updatedEmailList);
		}
	};

	const handleInputChange = (event, id) => {
		const updatedEmailList = permissionState.emailList.map((list) => {
			if (list.id === id) {
				const updatedList = {
					...list,
					permissions: {
						...list.permissions,
						value:
							event.target.value !== ""
								? parseInt(event.target.value, 10)
								: event.target.value,
					},
				};

				return updatedList;
			} else {
				return list;
			}
		});
		updateEmailList(updatedEmailList);
	};

	const onChange = (event, selectedPermission, id) => {
		if (
			selectedPermission.id ===
				shareEmailTemplatesFolderPermissions.myTeams ||
			selectedPermission.id ===
				shareEmailTemplatesFolderPermissions.myTerritories
		) {
			const updatedEmailList = permissionState.emailList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) =>
									permission.id === selectedPermission.id
										? {
												...permission,
												value: event.target.checked,
											}
										: permission.id ===
											  shareEmailTemplatesFolderPermissions.everyone
											? {
													...permission,
													value: false,
												}
											: permission
							),
						},
					};
				} else {
					return list;
				}
			});
			updateEmailList(updatedEmailList);
		} else {
			const updatedEmailList = permissionState.emailList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) =>
									permission.id === selectedPermission.id
										? {
												...permission,
												value: event.target.checked,
											}
										: {
												...permission,
												value: false,
											}
							),
						},
					};
				} else {
					return list;
				}
			});
			updateEmailList(updatedEmailList);
		}
	};

	const handleChange = (event, id) => {
		const updatedEmailList = permissionState.emailList.map((list) => {
			if (list.id === id) {
				return {
					...list,
					permissions: {
						...list.permissions,
						permission: list.permissions.permission.map(
							(permission) =>
								permission.name === event.target.value
									? { ...permission, value: true }
									: { ...permission, value: false }
						),
					},
				};
			} else {
				return list;
			}
		});
		updateEmailList(updatedEmailList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Emails"
				lists={permissionState.emailList}
				handleAccessChange={handleAccessChange}
				onChange={onChange}
				handleChange={handleChange}
				isEditable={isEditable}
				handleInputChange={handleInputChange}
			/>
		</React.Fragment>
	);
}
