import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import twozoLogo from "../../assets/images/logo/twozo-full.png";
import { default as EyeIcon } from "../../assets/icons/eye";
import { default as EyeOffIcon } from "../../assets/icons/eyeOff";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "../../api/userManagement/user/userApi";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";
import { notificationMessage } from "../../utils/notification/notificationMessages";
import PageNotFound from "./PageNotFound";

export default function SetPassword() {
	const [queryParams] = useSearchParams();
	const token = queryParams.get("token");
	const email = queryParams.get("email");
	const navigate = useNavigate();
	const theme = useTheme();

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm();

	useEffect(() => {
		setValue("email", email);
	}, [email]);

	const [isPasswordHidden, setIsPasswordHidden] = useState(false);

	const handleClickShowPassword = () => setIsPasswordHidden((show) => !show);

	const handleSetPassword = (data) => {
		setPasswordMutation.mutate(data);
	};

	const setPasswordMutation = useMutation(
		async (data) => resetPassword(data.email, data.password, token),
		{
			onSuccess: () => {
				navigate("/login");
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	if (!token) {
		return <PageNotFound />;
	}

	return (
		<React.Fragment>
			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					minHeight: "100vh",
					backgroundColor: "#FAFAFA",
				}}
			>
				<Box
					style={{
						width: "450px",
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
				>
					<Stack spacing={4} p={5} pb={3}>
						<Box>
							<img
								alt="twozo"
								src={twozoLogo}
								width="108px"
								height="34px"
							/>
						</Box>

						<Stack spacing={2}>
							<Typography fontSize={18} fontWeight={600}>
								Set your password
							</Typography>

							<Stack spacing={0.5}>
								<Typography
									fontSize={13}
									fontWeight={500}
									style={{ opacity: "0.6" }}
								>
									Email
								</Typography>

								<TextField
									placeholder="Enter your work email id"
									{...register("email")}
									value={email}
									FormHelperTextProps={{
										sx: {
											marginLeft: 0,
											fontSize: 13,
										},
									}}
									sx={{
										opacity: 0.6,
										pointerEvents: "none",
									}}
								/>
							</Stack>

							<Stack spacing={0.5}>
								<Typography
									fontSize={13}
									fontWeight={500}
									color={
										!!errors.password &&
										theme.palette.error.main
									}
								>
									New Password
								</Typography>

								<TextField
									placeholder="Enter password"
									type={
										isPasswordHidden ? "password" : "text"
									}
									FormHelperTextProps={{
										sx: {
											marginLeft: 0,
											fontSize: 13,
										},
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowPassword
													}
													edge="end"
												>
													{isPasswordHidden
														? EyeIcon(
																20,
																20,
																"#999999"
															)
														: EyeOffIcon(
																20,
																20,
																"#999999"
															)}
												</IconButton>
											</InputAdornment>
										),
									}}
									{...register("password", {
										required:
											"Please enter your new password",
										pattern: {
											value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=()-]).{8,20}$/,
											message: "Invalid password",
										},
										validate: (value) => !/\s/.test(value), // removed the white space
									})}
									onChange={(event) => {
										setValue(
											"password",
											event.target.value
										);
										clearErrors("password");
									}}
									error={!!errors.password}
									helperText={
										!!errors.password &&
										errors.password?.message
									}
								/>
							</Stack>

							<Box py={1}>
								<Button
									variant="contained"
									style={{
										width: "96px",
									}}
									onClick={handleSubmit(handleSetPassword)}
								>
									Save
								</Button>
							</Box>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
