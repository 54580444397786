import React from "react";
import ShortFiles from "../../../Summary/ShortFiles";
import { modules } from "../../../../utils/common/ModulesName";

export default function ContactFiles({ contactId }) {
	return (
		<React.Fragment>
			<ShortFiles entityId={contactId} sourceName={modules.CONTACT} />
		</React.Fragment>
	);
}
