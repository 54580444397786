import { apiClient } from "../..";
import { getDealUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import postFieldsApi from "../../fields/postFieldsApi";

export const getDealForecastViewData = async (
	pipelineId,
	startDate,
	colomn,
	interval,
	dealState,
	dealField,
	criteria
) => {
	let requestData = getDealForecastViewDataRequest(
		pipelineId,
		startDate,
		colomn,
		interval,
		dealState,
		dealField,
		criteria
	);
	return await postdealForecastManagementApi(requestData);
};

export const getDealFieldList = async () => {
	let requestData = getDealFieldListRequest();
	return await postFieldsApi(requestData);
};

const getDealForecastViewDataRequest = (
	pipelineId,
	startDate,
	colomn,
	interval,
	dealState,
	dealField,
	criteria
) => {
	return {
		type: "get",
		data: {
			pipelineId: pipelineId,
			arrangeBy: dealState,
			showBy: dealField,
			interval: interval,
			columns: colomn,
			startDate: startDate,
			...criteria,
		},
	};
};

const getDealFieldListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "forecastFieldList",
		},
	};
};

const postdealForecastManagementApi = async (requestData) => {
	let dealForecastManagementUrl = getDealForecastManagementUrl();
	let response = await apiClient.post(dealForecastManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getDealForecastManagementUrl = () => {
	return getDealUrl() + "/forecast";
};
