import React, { useMemo } from "react";
import { modules } from "../../../../utils/common/ModulesName";
import EmailComposer from "../../EmailComposer";
import {
	useDraftMailDataByConversationId,
	useDraftMailDataByThreadId,
} from "../../../../hooks/email";

export default function DraftComposer(props) {
	const {
		onClose,
		threadId,
		conversationId,
		sourceName,
		entityId,
		emailContext,
	} = props;

	const { draftMailThreadData, isFetchingThreadData } =
		useDraftMailDataByThreadId(threadId);
	const { draftMailConversationData, isFetchingConversationData } =
		useDraftMailDataByConversationId(conversationId);

	const defaultComposerData = useMemo(() => {
		if (draftMailConversationData) {
			return draftMailConversationData;
		}
		if (draftMailThreadData) {
			return draftMailThreadData;
		}
	}, [draftMailConversationData, draftMailThreadData]);

	return (
		<React.Fragment>
			<EmailComposer
				onClose={onClose}
				composerValues={defaultComposerData}
				sourceName={sourceName}
				entityId={entityId}
				emailContext={{
					conversationId: conversationId,
					threadId: threadId,
					...emailContext,
				}}
				linkConversationToContact={sourceName === modules.CONTACT}
				linkConversationToDeal={sourceName === modules.DEAL}
				hasDraftMail={true}
				isloadingComposerValues={
					isFetchingConversationData || isFetchingThreadData
				}
			/>
		</React.Fragment>
	);
}
