import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import SingleTimePicker from "../../../../../SingleTimePicker";
const toggleButtonStyle = { width: "80px" };

export default function TimeRangeFieldBaseComponent({
	value,
	onChange,
	removeBorder,
	textFieldStyle,
	onClearError,
	error,
}) {
	const [timeRangeValue, setTimeRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			setTimeRangeValue(value);
		}
	}, [value]);

	const handleTimeRangeValueChange = (timeRangeValue) => {
		if (onChange) {
			onChange(timeRangeValue);
		}
	};

	const updateFromTime = (value) => {
		let newValue = { ...timeRangeValue, from: value };
		setTimeRangeValue(newValue);
		handleTimeRangeValueChange(newValue);
		onClearError();
	};

	const updateToTime = (value) => {
		let newValue = { ...timeRangeValue, to: value };
		setTimeRangeValue(newValue);
		handleTimeRangeValueChange(newValue);
		onClearError();
	};

	return (
		<>
			<Stack direction="column" width="100%" spacing={0.5}>
				<SingleTimePicker
					style={{
						width: "50%",
						borderRadius: "8px",
						...textFieldStyle,
					}}
					reverse={true}
					error={error}
					value={timeRangeValue.from}
					onChange={updateFromTime}
					removeBorder={removeBorder}
					onClearError={onClearError}
					toggleButtonStyle={toggleButtonStyle}
				/>

				<SingleTimePicker
					reverse={true}
					error={error}
					style={{
						width: "50%",
						borderRadius: "8px",
						...textFieldStyle,
					}}
					value={timeRangeValue.to}
					onChange={updateToTime}
					removeBorder={removeBorder}
					onClearError={onClearError}
					toggleButtonStyle={toggleButtonStyle}
				/>
			</Stack>
		</>
	);
}
