import React, { useEffect } from "react";
import { useState } from "react";
import DateRangePicker from "../../../../../DateRangePicker";

export default function DateRangeBaseComponent({
	onChange,
	value,
	subField,
	id,
	inputRef,
	error,
	clearErrors,
	field,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromDateField = getSubFieldObject("FROM");
	const toDateField = getSubFieldObject("TO");

	const [dateRangeValue, setDateRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			let dateRangeValueObj = {};
			dateRangeValueObj.from = value[fromDateField.id];
			dateRangeValueObj.to = value[toDateField.id];
			setDateRangeValue(dateRangeValueObj);
		}
	}, [value, fromDateField, toDateField]);

	const handleDateRangeValueChange = (newDateRangeValue) => {
		setDateRangeValue({ ...newDateRangeValue });
		let dateRangeValueObjectWithId = {};
		dateRangeValueObjectWithId[fromDateField.id] = newDateRangeValue.from;
		dateRangeValueObjectWithId[toDateField.id] = newDateRangeValue.to;

		let getDateRangeValue = () => {
			if (
				dateRangeValueObjectWithId[fromDateField.id] &&
				dateRangeValueObjectWithId[toDateField.id]
			) {
				return dateRangeValueObjectWithId;
			}

			return "";
		};

		onChange(getDateRangeValue());
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<DateRangePicker
				value={dateRangeValue}
				onChange={handleDateRangeValueChange}
				id={id}
				inputRef={inputRef}
				error={error}
			></DateRangePicker>
		</React.Fragment>
	);
}
