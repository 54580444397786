import { FormAuxDataContext } from "..";
import { useFormAuxDataReducer } from "../FormAuxDataReducer";

const FormAuxDataProvider = (props) => {
	const { children } = props;

	const { auxiliaryFormData, onChangeFieldValueLabel } =
		useFormAuxDataReducer();

	return (
		<FormAuxDataContext.Provider
			value={{
				auxiliaryFormData,
				onChangeFieldValueLabel,
			}}
		>
			{children}
		</FormAuxDataContext.Provider>
	);
};

export { FormAuxDataProvider };
