import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import { useTheme } from "@mui/material";
import TooltipComponent from "../../TooltipComponent";

export default function Note(props) {
	const { data, rowState } = props;
	const theme = useTheme();

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TooltipComponent
				title={data?.name}
				placement="top"
				verticalDisplacement={-3}
				width="572px"
			>
				<TableCellText
					maxWidth="552px"
					noWrap
					color={getStatusProperties(rowState, theme).textColor}
					style={{
						textDecorationLine:
							getStatusProperties(rowState).textDecorationLine,
					}}
				>
					{data?.name}
				</TableCellText>
			</TooltipComponent>
		</React.Fragment>
	);
}
