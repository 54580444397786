import { apiClient } from "../../..";
import { getRolesUrl } from "../../../apiConfig";
import { assertError } from "../../../apiHelper";

export const getPermission = async (id) => {
	const requestData = getPermissionRequest(id);
	return await postPermissionManagementApi(requestData);
};

export const updatePermission = async (data) => {
	const requestData = getUpdatePermissionRequest(data);
	return await postPermissionManagementApi(requestData);
};

const getPermissionRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const getUpdatePermissionRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const postPermissionManagementApi = async (requestData) => {
	const permissionUrl = getPermissionUrl(requestData);
	const response = await apiClient.post(permissionUrl, requestData);
	assertError(response, permissionUrl);
	return response.data.data;
};

export const getPermissionUrl = () => {
	return getRolesUrl() + "/permission/mgmt";
};
