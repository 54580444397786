import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { getConversationListKey } from "../../../utils/queryKeys/conversations";
import {
	getConversationsData,
	linkMailToSource,
	unlinkConversation,
} from "../../../api/conversations";
import { deleteSingleMail } from "../../../api/email";
import { conversationPageSize } from "../../../utils/queryConstants/summary/conversation";
import { useInvalidateRelatedContactsAndDeal } from "../mail/relatedContactsAndDeal";
import { useInvalidateMailData, useInvalidateMailList } from "../mail";

const conversationSelect = (conversationData) => {
	return conversationData.pages.flatMap((page) => page?.conversations);
};

const useConversationList = (sourceName, entityId) => {
	return useInfiniteQuery({
		queryKey: getConversationListKey(sourceName, entityId),
		queryFn: ({ pageParam }) => {
			return getConversationsData(sourceName, entityId, {
				start: pageParam
					? (pageParam - 1) * conversationPageSize + 1
					: 1,
				limit: conversationPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: conversationSelect,
	});
};

const useInvalidateConversationList = (sourceName, entityId) => {
	let queryClient = useQueryClient();
	const conversationListKey = getConversationListKey(sourceName, entityId);
	return () => {
		queryClient.invalidateQueries(conversationListKey);
	};
};

const useDeleteMailForConversation = (sourceName, entityId) => {
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(deleteSingleMail, {
		onSuccess: () => {
			invalidateConversationList();
		},
	});
};

const useLinkMailToSource = (threadId, mailListName) => {
	let invalidateRelatedContactsAndDeal =
		useInvalidateRelatedContactsAndDeal(threadId);
	let invalidateMailList = useInvalidateMailList(mailListName);
	let invalidateMailData = useInvalidateMailData(threadId);
	return useMutation(linkMailToSource, {
		onSuccess: () => {
			invalidateRelatedContactsAndDeal();
			invalidateMailList();
			invalidateMailData();
		},
	});
};

const useUnlinkConversation = (
	sourceName,
	entityId,
	threadId,
	mailListName
) => {
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	let invalidateRelatedContactsAndDeal =
		useInvalidateRelatedContactsAndDeal(threadId);
	let invalidateMailData = useInvalidateMailData(threadId);
	let invalidateMailList = useInvalidateMailList(mailListName);
	return useMutation(unlinkConversation, {
		onSuccess: () => {
			invalidateConversationList();
			invalidateRelatedContactsAndDeal();
			invalidateMailData();
			invalidateMailList();
		},
	});
};

export {
	useConversationList,
	useInvalidateConversationList,
	useDeleteMailForConversation,
	useLinkMailToSource,
	useUnlinkConversation,
};
