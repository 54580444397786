import React from "react";
import AddForm from "../../Elements/AddForm";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import { useCreateProductMutation } from "../../../hooks/services/product";
import { useProductFieldPermissions } from "../../../hooks/modules/auth/product";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../hooks/modules";

export default function AddProduct(props) {
	const { onClose } = props;

	const createMutation = useCreateProductMutation();

	const productFieldPermissions = useProductFieldPermissions();

	const { getModuleName } = useModuleName();
	const moduleName = modules.PRODUCT;
	const formHeaderLabel = `Add ${getModuleName(moduleName)}`;

	const createNewProduct = (formSubmitData, isFormShouldClose) => {
		removeFieldsWithEmptyValues(formSubmitData);
		createMutation.mutate(formSubmitData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.productAdded,
				});
				isFormShouldClose && onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={productFieldPermissions}>
				<AddForm
					moduleName={modules.PRODUCT}
					onClose={onClose}
					isSaving={createMutation.isLoading}
					onSave={createNewProduct}
					formHeaderLabel={formHeaderLabel}
				/>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
