import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import { actionsIds } from "../../../../../../../utils/PermissionUtils";

export default function Actions(props) {
	const { permissions, isEditable } = props;

	const {
		permissionState,
		updateActionsList,
		initializeActionsListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeActionsListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		if (id === actionsIds.importRecords) {
			const updatedActionList = permissionState.actionsList.map(
				(list) => {
					let updatedList = list;

					if (list.id === id) {
						updatedList = {
							...list,
							access: event.target.checked,
						};

						if (list.permissions?.permission) {
							updatedList.permissions = {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => {
										if (permission.disabled) {
											return permission;
										} else {
											return {
												...permission,
												value: event.target.checked,
											};
										}
									}
								),
							};
						}
					} else if (
						list.id ===
							actionsIds.limitForCreatingRecordsThroughImport ||
						list.id ===
							actionsIds.limitForUpdatingRecordsThroughImport
					) {
						updatedList = {
							...list,
							access: event.target.checked,
							accessDisable: !event.target.checked,
						};
					}

					return updatedList;
				}
			);

			updateActionsList(updatedActionList);
		} else {
			const updatedActionList = permissionState.actionsList.map(
				(list) => {
					if (list.id === id) {
						const updatedList = {
							...list,
							access: event.target.checked,
						};

						if (list.permissions?.permission) {
							updatedList.permissions = {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => {
										if (permission.disabled) {
											return permission;
										} else {
											return {
												...permission,
												value: event.target.checked,
											};
										}
									}
								),
							};
						}

						return updatedList;
					} else {
						return list;
					}
				}
			);
			updateActionsList(updatedActionList);
		}
	};

	const onChange = (event, selectedPermission, id) => {
		const updatedActionList = permissionState.actionsList.map((list) => {
			if (list.id === id) {
				const updatedPermissions = list.permissions.permission.map(
					(permission) =>
						permission.id === selectedPermission.id
							? {
									...permission,
									value: event.target.checked,
								}
							: permission
				);

				// Check if any of the access is true
				const hasNoPermission = updatedPermissions.some(
					(permission) => permission.value && !permission.disabled
				);

				return {
					...list,
					access: hasNoPermission,
					permissions: {
						...list.permissions,
						permission: updatedPermissions,
					},
				};
			} else {
				return list;
			}
		});

		updateActionsList(updatedActionList);
	};

	const handleInputChange = (event, id) => {
		const updatedActionList = permissionState.actionsList.map((list) => {
			if (list.id === id) {
				const updatedList = {
					...list,
					permissions: {
						...list.permissions,
						value:
							event.target.value !== ""
								? parseInt(event.target.value, 10)
								: event.target.value,
					},
				};

				return updatedList;
			} else {
				return list;
			}
		});
		updateActionsList(updatedActionList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Actions"
				lists={permissionState.actionsList}
				handleAccessChange={handleAccessChange}
				onChange={onChange}
				isEditable={isEditable}
				handleInputChange={handleInputChange}
			/>
		</React.Fragment>
	);
}
