const emailFolderList = [
	{
		value: 1,
		name: "Inbox",
	},
	{
		value: 2,
		name: "Sent",
	},
	{
		value: 3,
		name: "Archive",
	},
	{
		value: 4,
		name: "Draft",
	},
];

export default emailFolderList;
