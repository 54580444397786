import moment from "moment-timezone";

export const getDateAndMonthFormat = (unixTimestampDate) => {
	// Eg: 04 Jul
	return new Date(unixTimestampDate).toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "short",
	});
};

export const getFormattedDateMonthYearAndTime = (unixTime) => {
	// Eg: May 19 2023 11:29 AM
	return new Date(unixTime)
		.toLocaleDateString(undefined, {
			day: "2-digit",
			month: "short",
			year: "numeric",
			hour: "2-digit",
			minute: "numeric",
		})
		.replace(",", "");
};

export const getDateMonthAndYearFormat = (unixTimestampDate) => {
	// Eg: 19 Oct 2021
	return new Date(unixTimestampDate).toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "short",
		year: "numeric",
	});
};

export const getHourAndMinuteFormat = (unixTimestampTime) => {
	// Eg: 10:20 AM
	return new Date(unixTimestampTime).toLocaleTimeString("en-us", {
		hour: "numeric",
		minute: "numeric",
	});
};

export const getMonthAndDateFormat = (unixTimestampTime) => {
	// Eg: Mon, Sep 4
	return new Date(unixTimestampTime).toLocaleDateString("en-us", {
		weekday: "short",
		month: "short",
		day: "numeric",
	});
};

export const getDayMonthDateAndTimeFormat = (unixTimestampTime) => {
	return new Date(unixTimestampTime).toLocaleDateString("en-us", {
		weekday: "short",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	});
};

export const getDayMonthTimeAndMinuteFormat = (unixTimestamp) => {
	// Fri, Feb 23, 12:45 PM
	return new Date(unixTimestamp).toLocaleString("en-US", {
		weekday: "short",
		month: "short",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});
};

export const getMonthDateAndTimeFormat = (unixTimestampTime) => {
	// Eg: Wed, May 2, 09.32 AM
	return new Date(unixTimestampTime).toLocaleDateString("en-us", {
		weekday: "short",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	});
};

export const convertMinutesToHourFormat = (data) => {
	let hours = Math.floor(data / 60);
	let minutes = data % 60;

	const hourIsPM = hours >= 12;
	hours = hourIsPM ? hours - 12 : hours;
	const AMorPM = hourIsPM ? "PM" : "AM";

	if (hours === 0) {
		hours = 12;
	}

	hours = hours < 10 ? "0" + hours : hours;
	minutes = minutes < 10 ? "0" + minutes : minutes;

	return `${hours} : ${minutes} ${AMorPM}`;
};

export const getDateDifference = (
	unixTimestamp,
	isShortFormat = false,
	surroundWithParenthesis = true
) => {
	const currentDate = new Date().getTime() / 1000;
	const expectedClosedDate = new Date(unixTimestamp).getTime() / 1000;

	let timeDifference = expectedClosedDate - currentDate;

	//seconds calculation
	let secondsPerMinute = 60;
	let secondsPerHour = secondsPerMinute * 60;
	let secondsPerDay = secondsPerHour * 24;
	let secondsPerYear = secondsPerDay * 365;

	//Year Difference
	let yearDifference = Math.round(timeDifference / secondsPerYear);

	//Month Difference
	timeDifference = timeDifference % secondsPerYear;
	let monthDifference = Math.round(timeDifference / (secondsPerDay * 30));

	//Day Difference
	timeDifference = timeDifference % (secondsPerDay * 30);
	let dayDifference = Math.round(timeDifference / secondsPerDay);

	let differenceString = "";

	if (yearDifference === 0 && monthDifference === 0 && dayDifference === 0) {
		differenceString = "Today";
	} else if (yearDifference !== 0) {
		differenceString = isShortFormat
			? `${Math.abs(yearDifference)}y`
			: Math.abs(yearDifference) > 1
				? `${Math.abs(yearDifference)} years ago`
				: `${Math.abs(yearDifference)} year ago`;
	} else if (monthDifference !== 0) {
		differenceString = isShortFormat
			? `${Math.abs(monthDifference)}m`
			: Math.abs(monthDifference) > 1
				? `${Math.abs(monthDifference)} months ago`
				: `${Math.abs(monthDifference)} month ago`;
	} else {
		differenceString = isShortFormat
			? `${Math.abs(dayDifference)}d`
			: Math.abs(dayDifference) > 1
				? `${Math.abs(dayDifference)} days ago`
				: `${Math.abs(dayDifference)} day ago`;
	}

	return surroundWithParenthesis ? `(${differenceString})` : differenceString;
};

export const getDateDifferenceWithHourAndMinute = (timestamp) => {
	if (!timestamp) return "";

	let currentDate = moment(new Date());
	let destinationDate = moment(new Date(timestamp));

	let monthDifference = currentDate.diff(destinationDate, "months");
	let dayDifference = currentDate.diff(destinationDate, "days");
	let hourDifference = currentDate.diff(destinationDate, "hours");
	let minuteDifference = currentDate.diff(destinationDate, "minutes");

	if (monthDifference) {
		return monthDifference > 1
			? `${monthDifference} Months Ago`
			: "1 Month Ago";
	} else if (dayDifference) {
		return dayDifference > 1 ? `${dayDifference} Days Ago` : "1 Day Ago";
	} else if (hourDifference) {
		return hourDifference > 1
			? `${hourDifference} Hours Ago`
			: "1 Hour Ago";
	} else if (minuteDifference) {
		return minuteDifference > 1
			? `${minuteDifference} Minutes Ago`
			: "1 Minute Ago";
	} else {
		return "Just Now";
	}
};
