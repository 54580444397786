import { removeFieldsWithEmptyValues } from "../../../utils/common";
import UpdateFieldForm from "../UpdateFieldForm";

export default function UpdateField({
	selected,
	onSave,
	moduleName,
	onClose,
	isSaving,
	open,
}) {
	const getUpdateFieldRequestData = (data) => {
		return selected?.map((id) => {
			return { id: id, ...data };
		});
	};

	const handleSave = (formData) => {
		removeFieldsWithEmptyValues(formData);
		let requestData = getUpdateFieldRequestData(formData);
		onSave(requestData);
	};

	return (
		<UpdateFieldForm
			open={open}
			onClose={onClose}
			onSave={handleSave}
			isSaving={isSaving}
			moduleName={moduleName}
		/>
	);
}
