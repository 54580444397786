import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { redirectRefQueryParamName } from "../utils/Auth";

export default function ProtectedRoute(props) {
	const { children, permission } = props;
	const { isUserAuthenticated, isUserAllowedFor } = useAuth();
	const location = useLocation();

	if (!isUserAuthenticated()) {
		let loginRoute = `/login?${redirectRefQueryParamName}=${location.pathname}`;
		return <Navigate to={loginRoute} />;
	}

	if (!isUserAllowedFor(permission)) {
		return <Navigate to="/UnAuthorized" />;
	}

	return children;
}
