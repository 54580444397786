import React from "react";
import Dialog from "../Dialog";
import { Button, Stack, Typography } from "@mui/material";

export default function ConfirmDialog(props) {
	const {
		open,
		title,
		subtitle,
		onCancel,
		onConfirm,
		confirmButtonText,
		cancelButtonText,
		disableConfirmButton = false,
		confirmButtonColor = "error",
	} = props;

	return (
		<React.Fragment>
			<Dialog open={open} onClose={onCancel}>
				<Stack p={3} spacing={1.5}>
					{!!title && (
						<Typography fontWeight={600}>{title}</Typography>
					)}

					{!!subtitle && <Typography>{subtitle}</Typography>}

					<Stack direction="row" spacing={2} justifyContent="end">
						<Button
							variant="contained"
							color="secondary"
							onClick={onCancel}
							disableElevation
						>
							{cancelButtonText ? cancelButtonText : "Cancel"}
						</Button>

						<Button
							variant="contained"
							color={confirmButtonColor}
							onClick={onConfirm}
							disableElevation
							disabled={disableConfirmButton}
						>
							{confirmButtonText ? confirmButtonText : "Confirm"}
						</Button>
					</Stack>
				</Stack>
			</Dialog>
		</React.Fragment>
	);
}
