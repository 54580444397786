import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import AddProduct from "../AddProduct";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";

export default function ProductsMenu(props) {
	const { onImportClicked } = props;
	const classes = twozoStyles();
	const { getModuleName } = useModuleName();
	const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
	const viewName = getModuleName(modules.PRODUCT);

	const toggleAddProductDialog = () => {
		setOpenAddProductDialog(
			(openAddProductDialog) => !openAddProductDialog
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddProductDialog}
				onOpen={toggleAddProductDialog}
				onClose={toggleAddProductDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddProduct onClose={toggleAddProductDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				height="100%"
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={1.5}
					height="100%"
				>
					<Can permission={PERMISSIONS.product.importRecords}>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onImportClicked}
						>
							{`Import ${viewName}`}
						</Button>
					</Can>

					<Can permission={PERMISSIONS.product.create}>
						<Button
							variant="contained"
							startIcon={AddIcon(20, 20, "#fff")}
							onClick={toggleAddProductDialog}
							disableElevation
						>
							{viewName}
						</Button>
					</Can>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
