import React from "react";

function Lunch(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
		>
			<path
				d="M3.6533 20.4616C3.48788 20.2962 3.40517 20.0977 3.40517 19.8661C3.40517 19.6345 3.48788 19.436 3.6533 19.2706L13.5041 9.41979C13.1567 8.59269 13.0906 7.74904 13.3056 6.88885C13.5206 6.02866 13.9921 5.23464 14.7199 4.50679C15.4643 3.77894 16.3824 3.30336 17.4742 3.08004C18.566 2.85672 19.4675 3.10072 20.1788 3.81203C20.9067 4.53988 21.1507 5.44142 20.9108 6.51666C20.671 7.59189 20.1623 8.52652 19.3848 9.32054C18.7231 9.99876 17.9705 10.4537 17.1268 10.6853C16.2832 10.9168 15.4809 10.8838 14.7199 10.586L13.1815 12.1244L20.3277 19.2706C20.4931 19.436 20.5758 19.6345 20.5758 19.8661C20.5758 20.0977 20.4931 20.2962 20.3277 20.4616C20.1623 20.627 19.9638 20.7098 19.7322 20.7098C19.5006 20.7098 19.3021 20.627 19.1367 20.4616L11.9905 13.3154L4.84433 20.4616C4.67891 20.627 4.4804 20.7098 4.24881 20.7098C4.01722 20.7098 3.81872 20.627 3.6533 20.4616ZM7.2512 12.2485L4.32325 9.32054C3.54578 8.52652 3.11155 7.63325 3.02056 6.64072C2.92958 5.6482 3.14049 4.7053 3.6533 3.81203C3.78564 3.59698 3.98 3.48118 4.23641 3.46464C4.49281 3.4481 4.72026 3.53081 4.91877 3.71277L10.3528 9.14685L7.2512 12.2485Z"
				fill={color}
			/>
		</svg>
	);
}

export default Lunch;
