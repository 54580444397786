import { assertError } from "../apiHelper";
import { getIconUrl } from "../apiConfig";
import { apiClient } from "..";

export const getIconList = async (data) => {
	let requestData = getListIconRequest(data);
	return await postIconManagementApi(requestData);
};

const getListIconRequest = (data) => {
	return {
		type: "list",
		data: {
			name: data,
		},
	};
};

const postIconManagementApi = async (requestData) => {
	let iconUrl = getIconUrl();
	let response = await apiClient.post(iconUrl, requestData);

	assertError(response, iconUrl);

	return response.data.data;
};
