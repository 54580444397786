import React, { useState } from "react";
import { Stack } from "@mui/material";
import ActivityTaskType from "./ActivityTaskType";
import ActivityStatus from "./ActivityStatus";
import ActivityDueDate from "./ActivityDueDate";

export default function ActivityFilters(props) {
	const { filterListByCondition } = props;

	//Apply Filter Request
	const [criteriaList, setCriteriaList] = useState([]);

	const findExistingFilterIndex = (criteriaList, key) => {
		return criteriaList.findIndex((criteria) => criteria.field === key);
	};

	const addFilter = (key, criteria) => {
		const existingFilterIndex = findExistingFilterIndex(criteriaList, key);

		const updatedCriteriaList =
			existingFilterIndex !== -1
				? [
						...criteriaList.slice(0, existingFilterIndex),
						criteria,
						...criteriaList.slice(existingFilterIndex + 1),
					]
				: criteriaList.concat(criteria);

		setCriteriaList(updatedCriteriaList);
		filterListByCondition({ criteria: updatedCriteriaList });
	};

	const removeFilter = (key) => {
		const existingFilterIndex = findExistingFilterIndex(criteriaList, key);

		const updatedCriteriaList = [
			...criteriaList.slice(0, existingFilterIndex),
			...criteriaList.slice(existingFilterIndex + 1),
		];

		setCriteriaList(updatedCriteriaList);
		filterListByCondition({ criteria: updatedCriteriaList });
	};

	return (
		<React.Fragment>
			<Stack direction="row" spacing={1.5}>
				<ActivityTaskType
					handleSelectOption={addFilter}
					handleUnselectOption={removeFilter}
				/>

				<ActivityDueDate
					handleSelectOption={addFilter}
					handleUnselectOption={removeFilter}
				/>

				<ActivityStatus
					handleSelectOption={addFilter}
					handleUnselectOption={removeFilter}
				/>
			</Stack>
		</React.Fragment>
	);
}
