import React from "react";
import TextComponent from "../TextComponent";

export default function TextAreaComponent(props) {
	const { maxRows, ...rest } = props;

	return (
		<React.Fragment>
			<TextComponent
				{...rest}
				multiline
				maxRows={maxRows ? maxRows : 7}
			></TextComponent>
		</React.Fragment>
	);
}
