import { useQuery } from "@tanstack/react-query";
import { getUserTeamTerritoryListKey } from "../../../utils/queryKeys/userManagement";
import { getUserTeamTerritoryList } from "../../../api/userManagement";

//User Team Territory List
const useUserTeamTerritoryList = () => {
	return useQuery(
		getUserTeamTerritoryListKey(),
		() => getUserTeamTerritoryList(),
		{
			select: (data) => data?.options,
		}
	);
};

export { useUserTeamTerritoryList };
