import React, { useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import {
	default as MuiAutocomplete,
	createFilterOptions,
} from "@mui/material/Autocomplete";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CloseIcon } from "../../../assets/icons/close";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

export default function DealAutocomplete(props) {
	const {
		value,
		getInputAdornmentIcon,
		inputRef,
		handleRemoveInput,
		error,
		onClearErrors,
		placeHolder,
		isAllowNewOption = true,
		...others
	} = props;
	const theme = useTheme();
	const [inputData, setInputData] = useState("");

	const handleInputChange = (event) => {
		const { value } = event.target;
		setInputData(value.trim());
		onClearErrors();
	};

	const CustomPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					display: inputData ? "block" : "none",
				}}
			/>
		);
	};

	const filteredValues = createFilterOptions({
		matchFrom: "start",
		stringify: (option) => option.name,
	});

	const handleRemove = () => {
		handleRemoveInput();
		setInputData("");
	};

	const filterOptions = (options, params) => {
		const filteredOption = filteredValues(options, params);
		const { inputValue } = params;
		const isExistingOption = options.some(
			(option) => inputValue?.toLowerCase() === option.name?.toLowerCase()
		);
		if (inputValue !== "" && !isExistingOption && isAllowNewOption) {
			filteredOption.push({
				name: inputValue,
				isNewOption: true,
			});
		}
		return filteredOption;
	};

	const renderOption = (option) => {
		if (!option) {
			return (
				<Typography
					fontSize={13}
					fontWeight={400}
					style={{ opacity: 0.6 }}
				>
					No Results Found
				</Typography>
			);
		}

		if (isAllowNewOption && option.isNewOption) {
			return (
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
				>
					<Typography
						fontSize="13px"
						fontWeight={500}
						color={theme.palette.primary.main}
					>
						{option?.name}
					</Typography>

					<Box display="flex">
						{AddIcon(20, 20, theme.palette.primary.main)}
					</Box>
				</Stack>
			);
		}

		return <Typography fontSize={13}>{option?.name}</Typography>;
	};

	return (
		<MuiAutocomplete
			{...others}
			size="small"
			forcePopupIcon={false}
			value={value}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			renderOption={(props, option) => (
				<Box
					key={option?.value}
					{...props}
					style={{
						minHeight: "40px",
						width: "100%",
					}}
				>
					{renderOption(option)}
				</Box>
			)}
			readOnly={!!value?.name}
			filterOptions={filterOptions}
			getOptionLabel={(option) => {
				if (typeof option === "string") {
					return option;
				}

				return option.name;
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={inputRef}
					value={inputData}
					placeholder={placeHolder || "Enter"}
					onChange={handleInputChange}
					inputProps={{
						...params.inputProps,
						onKeyDown: (event) => {
							if (event.key === "Enter") {
								event.stopPropagation();
							}
						},
						style: {
							...params.inputProps?.style,
							fontSize: "14px",
						},
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment
								position="start"
								sx={{ paddingLeft: "10px" }}
							>
								{getInputAdornmentIcon()(
									20,
									20,
									error ? theme.palette.error.main : "#666666"
								)}
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								{(!!value?.name || !!inputData) && (
									<IconButton onClick={handleRemove}>
										{CloseIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
					error={!!error}
				/>
			)}
		/>
	);
}
