import React from "react";
import { Line } from "react-chartjs-2";

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "bottom",
			display: false,
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			title: {
				display: true,
				text: "First Contacted",
				padding: {
					top: 12,
				},
			},
			grid: {
				display: false,
			},
		},
		y: {
			title: {
				display: true,
				text: "Total Contacts",
				padding: {
					bottom: 12,
				},
			},
			grid: {
				display: false,
			},
			ticks: {
				stepSize: 20,
			},
			beginAtZero: true,
		},
	},
};

const labels = ["", "21-11-2023", "25-11-2023", "26-11-2023", "28-11-2023"];
const lineColors = [
	"rgb(255, 255, 255)",
	"rgb(211, 114, 225)",
	"rgb(244, 190, 56)",
	"rgb(63, 255, 163)",
	"rgb(233, 139, 201)",
];

export const data = {
	labels,
	datasets: [
		{
			label: "Contact Count",
			data: [null, 68, 84, 43, 100],
			segment: {
				borderColor: (ctx) => {
					return lineColors[ctx.p1DataIndex];
				},
			},
			backgroundColor: lineColors,
			pointBorderWidth: 0,
			pointRadius: 8,
			pointHoverRadius: 6,
			borderWidth: 3,
		},
	],
};

export default function LineChart() {
	return (
		<React.Fragment>
			<Line options={options} data={data} />
		</React.Fragment>
	);
}
