import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import EmailRecipientInput from "../EmailRecipientInput";

export default function CCAddress(props) {
	const { recipients, value, handleRecipientsChange } = props;

	return (
		<React.Fragment>
			<Stack direction="row" alignItems="flex-start" px={2} spacing={2}>
				<Box pt={1.5}>
					<Typography fontSize={13} noWrap>
						Cc :
					</Typography>
				</Box>

				<Box flexGrow={1}>
					<EmailRecipientInput
						recipients={recipients || []}
						value={value}
						handleRecipientsChange={handleRecipientsChange}
					/>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
