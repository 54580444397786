const reminder = [
	{
		name: "No Reminder",
		value: 1,
	},
	{
		name: "5 Minutes",
		value: 2,
	},
	{
		name: "10 Minutes",
		value: 3,
	},
	{
		name: "15 Minutes",
		value: 4,
	},
	{
		name: "30 Minutes",
		value: 5,
	},
];

export default reminder;
