import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getHourAndMinuteFormat } from "../../../../utils/DateUtils";

export default function TimeRange(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	const formattedTime = (timeValue) => {
		const hours = Math.floor(timeValue / 60);
		const minutes = timeValue % 60;
		const newDate = new Date();
		newDate.setHours(hours, minutes);
		const time = getHourAndMinuteFormat(newDate.valueOf());
		return time;
	};

	return (
		<React.Fragment>
			<TableCellText>
				{formattedTime(data.from)} - {formattedTime(data.to)}
			</TableCellText>
		</React.Fragment>
	);
}
