import { default as FilterIcon } from "../../../../../../assets/icons/filter";
import React, { useEffect, useState } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import CustomSwipeableDrawer from "../../../../../Elements/CustomSwipeableDrawer";
import WorkflowNameFilter from "./WorkflowNameFilter";

export default function WorkflowNameFilterDrawer(params) {
	const { listContainerRef, handleUpdateFilter, updateFilterMenuOpen } =
		params;

	const theme = useTheme();

	const [isFilterDrawerOpened, setIsFilterDrawerOpened] = useState(false);
	const [topBarStartingPosition, setTopBarStartingPosition] = useState(0);

	useEffect(() => {
		if (listContainerRef?.current) {
			setTopBarStartingPosition(
				listContainerRef.current.getBoundingClientRect().top
			);
		}
	}, [listContainerRef]);

	const toggleFilterDrawer = () => {
		setIsFilterDrawerOpened((isFilterDrawerOpened) => {
			updateFilterMenuOpen(!isFilterDrawerOpened);
			return !isFilterDrawerOpened;
		});
	};

	return (
		<>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { zIndex: 3 },
				}}
				variant="persistent"
				open={isFilterDrawerOpened}
				onOpen={toggleFilterDrawer}
				onClose={toggleFilterDrawer}
				disableBackdropClick={true}
				sx={{
					"& .MuiDrawer-paper": {
						height: `calc(100vh - ${topBarStartingPosition + 8}px)`,
						width: "20%",
						marginTop: `${topBarStartingPosition}px`,
						borderBottom: "1px solid rgb(0 0 0 / 10%)",
					},
				}}
			>
				<Box
					style={{
						backgroundColor: "#fff",
					}}
				>
					<WorkflowNameFilter updateFilter={handleUpdateFilter} />
				</Box>
			</CustomSwipeableDrawer>

			<IconButton onClick={toggleFilterDrawer} sx={{ pr: 2 }}>
				{FilterIcon(20, 20, theme.palette.primary.main)}
			</IconButton>
		</>
	);
}
