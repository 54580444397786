import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SingleDatePicker from "../SingleDatePicker";
import dayjs from "dayjs";

/**
 * Refer mui Date Picker documents for arguments and customization
 * https://mui.com/x/react-date-pickers/base-concepts/
 * https://mui.com/x/react-date-pickers/date-picker/
 *
 * Expected value format => {from : <unixTimeStamp> , to : <unixTimeStamp>}
 **/

export default function DateRangePicker({
	value,
	onChange,
	id,
	inputRef,
	fromTextFieldSx,
	toTextFieldSx,
	error,
}) {
	const [dateRangeValue, setDateRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			setDateRangeValue(value);
		}
	}, [value]);

	const handleDateRangeValueChange = (dateRangeValue) => {
		if (onChange) {
			onChange(dateRangeValue);
		}
	};

	const onStartDateChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateRangeValue, from: value.valueOf() };
		setDateRangeValue(newValue);
		handleDateRangeValueChange(newValue);
	};

	const onEndDateChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateRangeValue, to: value.valueOf() };
		setDateRangeValue(newValue);
		handleDateRangeValueChange(newValue);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const handleDisableToField = (date) => {
		return date < getParsedValue(dateRangeValue.from);
	};

	const handleDisableFromField = (date) => {
		if (dateRangeValue.to) {
			return date > getParsedValue(dateRangeValue.to);
		}
	};

	return (
		<React.Fragment>
			<Box width="100%" position="relative">
				<SingleDatePicker
					textFieldProps={{
						inputRef: inputRef,
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px 0px 0px 8px",
							},
							...fromTextFieldSx,
						},
					}}
					onChange={(value) => {
						onStartDateChange(value);
					}}
					value={getParsedValue(dateRangeValue.from)}
					id={id}
					error={!!error}
					shouldDisableDate={handleDisableFromField}
					shouldDisableMonth={handleDisableFromField}
					shouldDisableYear={handleDisableFromField}
				/>
				<Box
					style={{
						display: "inline",
						position: "absolute",
						marginTop: "10px",
						marginLeft: "-8px",
						backgroundColor: "#fff",
						zIndex: 2,
					}}
				>
					<Typography fontSize={14} color={"rgba(0, 0, 0, 0.6)"}>
						To
					</Typography>
				</Box>
				<SingleDatePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "0px 8px 8px 0px",
								marginLeft: "-1px",
							},
							...toTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "right", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onEndDateChange(value);
					}}
					value={getParsedValue(dateRangeValue.to)}
					error={!!error}
					shouldDisableDate={handleDisableToField}
					shouldDisableMonth={handleDisableToField}
					shouldDisableYear={handleDisableToField}
				/>
			</Box>
		</React.Fragment>
	);
}
