import React from "react";
import EmailList from "../EmailList";
import { mailListName } from "../../../utils/EmailUtils/mailListName";

export default function AwaitingResponse() {
	const menuConfig = {
		isMarkAsDoneEnabled: true,
		isDeleteEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Awaiting Response"
				mailListName={mailListName.AWAITING_RESPONSE}
				menuConfig={menuConfig}
				hiddenFavoriteAction={true}
			/>
		</React.Fragment>
	);
}
