import { userSettingsIds } from "..";

export const getUserSettingsConfigForPermission = (permissions) => {
	return [
		{
			id: userSettingsIds.manageSalesTeam,
			name: "Manage Sales Team",
			access: permissions.manageSalesTeam.access,
		},
	];
};
