import React from "react";

export default function AddActivity(width = 20, height = 22, color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 20 22"
			style={{ fill: "none" }}
		>
			<g id="note-add">
				<path
					id="Vector"
					d="M7.56006 17V12"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M10 14.5H5"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_3"
					d="M6 1V4"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_4"
					d="M14 1V4"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_5"
					d="M18.9957 8.62636L19.018 14.7977C19.0261 18.9185 18.0401 20.9716 13.0389 20.9943L7.03775 21.0095C2.03677 21.0223 1.03253 18.9644 1.02427 14.8537L0.992148 8.67216C0.97419 3.97113 2.64663 2.70012 5.97048 2.50811L13.9721 2.48775C17.3095 2.66602 18.9777 3.92533 18.9957 8.62636Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
