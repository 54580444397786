import React, { useEffect, useState } from "react";
import Dialog from "../../../../Elements/Dialog";
import {
	Box,
	Button,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import { usePipelineList } from "../../../../../hooks/services/deal/pipeline";
import { useUserPartialBulkUpdate } from "../../../../../hooks/services/userManagement/user";
import { useRolesList } from "../../../../../hooks/services/userManagement/roles";
import { useUsers } from "../../../../../hooks/account/user";

export default function UpdateField(props) {
	const { open, onClose, selectedUser, handleClearSelectedMenu } = props;
	const theme = useTheme();

	const userFields = getDropdownDataByName("USER_FIELDS");

	const [isSaveDisabled, setIsSaveDisabled] = useState(true);
	const [selectedFields, setSelectedFields] = useState([]);

	const [fieldToUpdate, setFieldToUpdate] = useState([
		{
			fieldName: "",
			value: {},
		},
	]);

	const { data: pipelineList } = usePipelineList();
	const { activeUserList } = useUsers();
	const { data: rolesList } = useRolesList();
	const updateFieldMutation = useUserPartialBulkUpdate();

	const handleAddFields = () => {
		setFieldToUpdate([
			...fieldToUpdate,
			{
				fieldName: "",
				value: {},
			},
		]);
	};

	const getMenuItems = (index) => {
		if (selectedFields[index]?.isReportingManger) {
			return activeUserList;
		} else if (selectedFields[index]?.isPipeline) {
			return pipelineList;
		} else if (selectedFields[index]?.isRole) {
			return rolesList;
		}
	};

	const handleSelectField = (event, index) => {
		const { value } = event.target;
		const fieldDetails = userFields.find((field) => field.name === value);

		setFieldToUpdate((fields) => {
			const updatedFieldToUpdate = [...fields];
			updatedFieldToUpdate[index] = {
				...updatedFieldToUpdate[index],
				fieldName: value,
				value: {},
			};
			return updatedFieldToUpdate;
		});

		setSelectedFields((fields) => {
			const updatedselectedFields = [...fields];
			updatedselectedFields[index] = fieldDetails;
			return updatedselectedFields;
		});
	};

	const removeFields = (selectedIndex) => {
		setFieldToUpdate((fields) =>
			fields.filter((_, index) => index !== selectedIndex)
		);

		setSelectedFields((fields) =>
			fields.filter((_, index) => index !== selectedIndex)
		);
	};

	const closeUpdateFieldDialog = () => {
		onClose();
		setFieldToUpdate([
			{
				fieldName: "",
				value: {},
			},
		]);
		setSelectedFields([]);
	};

	const handleSelectFieldValue = (event, selectedIndex) => {
		const { value } = event.target;

		if (selectedFields[selectedIndex]?.isReportingManger) {
			setFieldToUpdate((fields) =>
				fields.map((field, index) => {
					if (index === selectedIndex) {
						const fieldId = activeUserList?.find(
							(item) => item.name === value
						)?.value;
						return {
							...field,
							value: { id: fieldId, name: value },
						};
					}
					return field;
				})
			);
		} else if (selectedFields[selectedIndex]?.isPipeline) {
			setFieldToUpdate((fields) =>
				fields.map((field, index) => {
					if (index === selectedIndex) {
						const fieldId = pipelineList.find(
							(item) => item.name === value
						)?.id;
						return {
							...field,
							value: { id: fieldId, name: value },
						};
					}
					return field;
				})
			);
		} else if (selectedFields[selectedIndex]?.isRole) {
			setFieldToUpdate((fields) =>
				fields.map((field, index) => {
					if (index === selectedIndex) {
						const fieldId = rolesList.find(
							(item) => item.name === value
						)?.value;
						return {
							...field,
							value: { id: fieldId, name: value },
						};
					}
					return field;
				})
			);
		}
	};

	// ToDisable Save button
	useEffect(() => {
		if (fieldToUpdate.length >= 1) {
			const isFieldHasValues = fieldToUpdate.every(
				(field) =>
					field.fieldName !== "" &&
					Object.keys(field.value).length > 0
			);

			setIsSaveDisabled(!isFieldHasValues);
		} else {
			setIsSaveDisabled(true);
		}
	}, [fieldToUpdate]);

	const getUpdateFieldDetails = () => {
		let requestFieldData = [];

		for (let index = 0; index < selectedUser.length; index++) {
			let requestData = {};
			for (const fieldDataElementKey in fieldToUpdate) {
				if (
					Object.hasOwnProperty.call(
						fieldToUpdate,
						fieldDataElementKey
					)
				) {
					const fieldElement = fieldToUpdate[fieldDataElementKey];
					requestData.id = selectedUser[index].id;

					if (fieldElement.fieldName === "Reporting Manager") {
						requestData.reportingManagerId = fieldElement.value.id;
					} else if (fieldElement.fieldName === "Pipeline") {
						requestData.pipelineId = fieldElement.value.id;
					} else if (fieldElement.fieldName === "Role") {
						requestData.roleId = fieldElement.value.id;
					}
				}
			}
			requestFieldData.push(requestData);
		}
		return requestFieldData;
	};

	const onSave = () => {
		const updateFieldDetails = getUpdateFieldDetails();
		updateFieldMutation.mutate(updateFieldDetails, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userUpdated,
				});
				closeUpdateFieldDialog();
				handleClearSelectedMenu();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const getUserFields = (fieldName) => {
		return userFields?.filter(
			(field) =>
				field.name === fieldName ||
				!selectedFields.some(
					(selectedField) => selectedField.name === field.name
				)
		);
	};

	return (
		<React.Fragment>
			<Dialog open={open} title="Update Field">
				<Box p={2}>
					<Stack spacing={1}>
						<table>
							<thead>
								<tr>
									<th
										style={{
											textAlign: "left",
											width: "47%",
										}}
									>
										<Typography
											fontSize={13}
											fontWeight={500}
										>
											Select Field
										</Typography>
									</th>

									<th style={{ width: "6%" }}></th>

									<th
										style={{
											textAlign: "left",
											width: "47%",
										}}
									>
										<Typography
											fontSize={13}
											fontWeight={500}
										>
											Value
										</Typography>
									</th>
								</tr>
							</thead>

							<tbody>
								{fieldToUpdate.map((field, index) => (
									<tr key={index}>
										<td>
											<Select
												size="small"
												displayEmpty
												fullWidth
												value={field.fieldName || ""}
												onChange={(event) =>
													handleSelectField(
														event,
														index
													)
												}
												renderValue={(value) => {
													if (
														value === "" ||
														value === undefined
													) {
														return (
															<Typography
																color={
																	"rgba(0, 0, 0, 0.5)"
																}
																fontSize={14}
															>
																Select
															</Typography>
														);
													} else {
														return value;
													}
												}}
											>
												{getUserFields(
													field.fieldName
												)?.map((fieldData) => (
													<MenuItem
														key={fieldData.id}
														value={fieldData.name}
														sx={{
															height: "40px",
														}}
													>
														<Typography
															fontSize={14}
														>
															{fieldData.name}
														</Typography>
													</MenuItem>
												))}
											</Select>
										</td>

										<td>
											<Box px={0.5}>
												<Divider />
											</Box>
										</td>

										<td>
											<Select
												size="small"
												displayEmpty
												fullWidth
												value={field.value.name || ""}
												disabled={!field.fieldName}
												sx={{
													"& .MuiOutlinedInput-notchedOutline":
														{
															opacity:
																!field.fieldName
																	? 0.4
																	: 1,
														},
													"& .MuiSelect-icon": {
														opacity:
															!field.fieldName
																? 0.4
																: 1,
													},
													"& .MuiSelect-select": {
														opacity:
															!field.fieldName
																? 0.4
																: 1,
													},
												}}
												onChange={(event) =>
													handleSelectFieldValue(
														event,
														index
													)
												}
												renderValue={(value) => {
													if (
														value === "" ||
														value === undefined
													) {
														return (
															<Typography
																color={
																	"rgba(0, 0, 0, 0.5)"
																}
																fontSize={14}
															>
																Select
															</Typography>
														);
													} else {
														return value;
													}
												}}
											>
												{(
													getMenuItems(index) || []
												)?.map((fieldData) => (
													<MenuItem
														key={
															fieldData.id ||
															fieldData.value
														}
														value={fieldData.name}
														sx={{
															height: "40px",
														}}
													>
														<Typography
															fontSize={14}
														>
															{fieldData.name}
														</Typography>
													</MenuItem>
												))}
											</Select>
										</td>

										<td>
											<Box
												hidden={
													fieldToUpdate.length <= 1
												}
												style={{
													cursor: "pointer",
												}}
												onClick={() =>
													removeFields(index)
												}
											>
												<Stack
													direction="row"
													alignItems="center"
												>
													{CloseIcon(20, 20, "grey")}
												</Stack>
											</Box>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<Box
							hidden={
								fieldToUpdate?.length === userFields?.length
							}
						>
							<Button
								startIcon={AddIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								size="small"
								onClick={handleAddFields}
							>
								Add More Field
							</Button>
						</Box>

						<Stack
							direction="row"
							justifyContent="flex-end"
							spacing={2}
							pt={1}
						>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={closeUpdateFieldDialog}
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								disableElevation
								onClick={onSave}
								disabled={isSaveDisabled}
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.primary.main,
										color: "rgba(255,255,255, 0.6)",
									},
								}}
							>
								Save
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
