import React, { useEffect, useState } from "react";
import AddTeamDialog from "../AddTeamDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useTeamData,
	useUpdateTeam,
} from "../../../../../hooks/services/userManagement/teams";

export default function EditTeam(props) {
	const { onClose, teamId } = props;

	const updateTeamMutation = useUpdateTeam(teamId);
	const {
		data: teamData,
		isLoading: isLoadingTeamData,
		status: teamDataStatus,
	} = useTeamData(teamId);

	const [teamDetail, setTeamDetail] = useState([]);

	useEffect(() => {
		if (teamDataStatus === "success") {
			if (teamData) {
				setTeamDetail(teamData);
			}
		}
	}, [teamDataStatus, teamData]);

	const updateTeam = (teamData) => {
		updateTeamMutation.mutate(teamData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.teamUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<AddTeamDialog
				title="Edit Team"
				onClose={onClose}
				isEditMode={true}
				onUpdate={updateTeam}
				teamDetail={teamDetail}
				isLoadingTeamData={isLoadingTeamData}
				isSaving={updateTeamMutation.isLoading}
			/>
		</React.Fragment>
	);
}
