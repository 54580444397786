import React from "react";
import AddressComponent from "./FieldValueComponents/AddressComponent";
import CompanyComponent from "./FieldValueComponents/CompanyComponent";
import ContactComponent from "./FieldValueComponents/ContactComponent";
import DateTimeRangeComponent from "./FieldValueComponents/DateTimeRangeComponents";
import DropDownComponent from "./FieldValueComponents/DropdownComponent";
import EmailComponent from "./FieldValueComponents/EmailComponent";
import MonetaryComponent from "./FieldValueComponents/MonetaryComponent";
import MultiSelectComponent from "./FieldValueComponents/MultiSelectComponent";
import PhoneComponent from "./FieldValueComponents/PhoneComponent";
import TextComponent from "./FieldValueComponents/TextComponent";
import { MultiEmailComponent } from "./FieldValueComponents/MultiEmailComponent";
import MultiPhoneComponent from "./FieldValueComponents/MultiPhoneComponent";
import DealComponent from "./FieldValueComponents/DealComponent";
import SalesOwnerComponent from "./FieldValueComponents/SalesOwnerComponent";
import TagComponent from "./FieldValueComponents/TagComponent";
import TerritoryComponent from "./FieldValueComponents/TerritoryComponent";
import ProductComponent from "./FieldValueComponents/ProductComponent";
import TimeComponent from "./FieldValueComponents/TimeComponent";
import DateComponent from "./FieldValueComponents/DateComponent";
import TextAreaComponent from "./FieldValueComponents/TextAreaComponent";
import TimeRangeComponent from "./FieldValueComponents/TimeRangeComponent";
import DateRangeComponent from "./FieldValueComponents/DateRangeComponent";
import SummaryCardTitle from "../SummaryCard/SummaryCardTitle";
import NumberComponent from "./FieldValueComponents/NumberComponent";

const fieldComponents = {
	TEXT: TextAreaComponent,
	SINGLE_STRING: TextComponent,
	ADDRESS: AddressComponent,
	MONETARY: MonetaryComponent,
	EMAIL: EmailComponent,
	EMAILS: MultiEmailComponent,
	SINGLE_NUMBER: NumberComponent,
	PHONES: MultiPhoneComponent,
	PHONE: PhoneComponent,
	TIME: TimeComponent,
	DROPDOWN: DropDownComponent,
	MULTI_SELECT: MultiSelectComponent,
	COMPANY: CompanyComponent,
	DATE: DateComponent,
	TIME_RANGE: TimeRangeComponent,
	DATE_RANGE: DateRangeComponent,
	DATE_TIME_RANGE: DateTimeRangeComponent,
	CONTACT: ContactComponent,
	DEAL: DealComponent,
	OWNER: SalesOwnerComponent,
	TAGS: TagComponent,
	TERRITORY: TerritoryComponent,
	PRODUCT: ProductComponent,
};

export default function Field(props) {
	const { summaryField, subFields } = props;
	const FieldComponent = fieldComponents[summaryField?.fieldType];

	return FieldComponent ? (
		<>
			<td>
				<SummaryCardTitle summaryField={summaryField} />
			</td>

			<td
				style={{
					padding: "1px 0px 0px 0px",
					display: "flex",
					alignItems: "center",
					cursor: "pointer",
				}}
			>
				<FieldComponent
					summaryField={summaryField}
					subFields={subFields}
				/>
			</td>
		</>
	) : null;
}
