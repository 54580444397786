import React from "react";
import { summaryContext } from "..";

const SummaryContextProvider = ({
	children,
	source,
	entityId,
	isSaving,
	hasEditPermission,
	updateSummaryField,
}) => {
	return (
		<React.Fragment>
			<summaryContext.Provider
				value={{
					source: source,
					entityId: entityId,
					isSaving: isSaving,
					hasEditPermission: hasEditPermission,
					updateSummaryField: updateSummaryField,
				}}
			>
				{children}
			</summaryContext.Provider>
		</React.Fragment>
	);
};

export { SummaryContextProvider };
