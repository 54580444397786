const alignments = [
	{
		value: 1,
		name: "Left",
	},
	{
		value: 2,
		name: "Center",
	},
	{
		value: 3,
		name: "Right",
	},
];

export default alignments;
