import { getTimelineUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const getContactTimelineData = async (contactId, filter, pageParams) => {
	const request = getContactTimelineRequest(contactId, filter, pageParams);
	return await postTimelineManagement(request);
};

export const getCompanyTimelineData = async (companyId, filter, pageParams) => {
	const request = getCompanyTimelineRequest(companyId, filter, pageParams);
	return await postTimelineManagement(request);
};

export const getDealTimelineData = async (dealId, filter, pageParams) => {
	const request = getDealTimelineRequest(dealId, filter, pageParams);
	return await postTimelineManagement(request);
};

const getContactTimelineRequest = (contactId, filter, pageParams) => {
	let contactTimelineRequestData = {
		type: "contactTimeline",
		data: {
			contactId: contactId,
			...pageParams,
		},
	};

	if (Array.isArray(filter) && filter.length > 0) {
		contactTimelineRequestData.data.filter = {
			category: filter,
		};
	}

	return contactTimelineRequestData;
};

const getCompanyTimelineRequest = (companyId, filter, pageParams) => {
	let companyTimelineRequestData = {
		type: "companyTimeline",
		data: {
			companyId: companyId,
			...pageParams,
		},
	};

	if (Array.isArray(filter) && filter.length > 0) {
		companyTimelineRequestData.data.filter = {
			category: filter,
		};
	}

	return companyTimelineRequestData;
};

const getDealTimelineRequest = (dealId, filter, pageParams) => {
	let dealTimelineRequestData = {
		type: "dealTimeline",
		data: {
			dealId: dealId,
			...pageParams,
		},
	};

	if (Array.isArray(filter) && filter.length > 0) {
		dealTimelineRequestData.data.filter = {
			category: filter,
		};
	}

	return dealTimelineRequestData;
};

const postTimelineManagement = async (requestData) => {
	const timelineManagementUrl = getTimelineManagementUrl();
	const response = await apiClient.post(timelineManagementUrl, requestData);
	assertError(response, timelineManagementUrl);
	return response.data.data;
};

export const getTimelineManagementUrl = () => {
	return getTimelineUrl() + "/mgmt";
};
