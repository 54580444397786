import React, { useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as EditIcon } from "../../../assets/icons/edit";
import { getIconByName } from "../../../utils";
import { default as RoundCheckBoxOffIcon } from "../../../assets/icons/roundCheckBoxOff";
import { default as RoundCheckBoxOnIcon } from "../../../assets/icons/roundCheckBoxOn";
import UnKnownImage from "../../../assets/images/contact/unknownContact.png";
import CompanyProfile from "../../../assets/images/company/companyProfile.png";
import Collaborators from "./Collaborators";
import DealCard from "./DealCard";
import { getDateMonthAndYearFormat } from "../../../utils/DateUtils";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useActivitySummary,
	useUpdateActivityStatus,
} from "../../../hooks/services/activities";
import { getStatusProperties } from "../../../utils/ActivitiesUtils";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import EditActivity from "../../Summary/Activities/EditActivity";
import { Tooltip, twozoStyles } from "../../../styles/twozo";
import CompanyCard from "./CompanyCard";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import TooltipComponent from "../../Elements/TooltipComponent";

export default function ActivitySummary(props) {
	const { onClose, activityId } = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const remainderList = getDropdownDataByName("REMINDER");
	const priorityList = getDropdownDataByName("PRIORITY");
	const statusList = getDropdownDataByName("ACTIVITY_STATUS");

	const { isLoading: isLoadingSummaryData, data: activitySummaryData } =
		useActivitySummary(activityId);

	const remainder = activitySummaryData?.reminder
		? remainderList.filter(
				(list) => list.value === activitySummaryData?.reminder
			)[0]
		: "";
	const priority = activitySummaryData?.priority
		? priorityList.filter(
				(list) => list.value === activitySummaryData?.priority
			)[0]
		: "";
	const status = activitySummaryData?.status
		? statusList.filter(
				(list) => list.value === activitySummaryData?.status
			)[0]
		: "";

	const updateActivityStatus = (status, id) => {
		updateActivityStatusMutation.mutate(
			{
				id: id,
				isDone: getStatusProperties(status.name).isDone,
			},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.activityUpdated,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const updateActivityStatusMutation = useUpdateActivityStatus(activityId);

	const [isEditActivityDialogOpened, setIsEditActivityDialogOpened] =
		useState(false);

	const toggleEditActivityDialog = () => {
		setIsEditActivityDialogOpened(
			(isEditActivityDialogOpened) => !isEditActivityDialogOpened
		);
	};

	//Company Card
	const [companyCardMenuElement, setCompanyCardMenuElement] = useState(null);
	const isCompanyCardDisplayed = Boolean(companyCardMenuElement);

	const showCompanyCard = (event) => {
		setCompanyCardMenuElement(event.currentTarget);
	};

	const hideCompanyCard = () => {
		setCompanyCardMenuElement(null);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditActivityDialogOpened}
				onOpen={toggleEditActivityDialog}
				onClose={toggleEditActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditActivity
						onClose={toggleEditActivityDialog}
						activityId={activityId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CompanyCard
				companyDetails={activitySummaryData?.company}
				companyCardMenuElement={companyCardMenuElement}
				isCompanyCardDisplayed={isCompanyCardDisplayed}
				hideCompanyCard={hideCompanyCard}
			/>

			{isLoadingSummaryData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600}>
								Activity Summary
							</Typography>

							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={toggleEditActivityDialog}
								>
									{EditIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<Divider orientation="vertical" flexItem />

								<IconButton
									onClick={onClose}
									size="small"
									style={{ padding: 0 }}
								>
									{CloseIcon(
										24,
										24,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>
						</Stack>
					</Box>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box px={3} py={2}>
							<Stack
								direction="row"
								justifyContent="space-between"
								pb={2}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={2}
								>
									{getIconByName(
										activitySummaryData.activityType.icon
											.name
									)(
										22,
										22,
										getStatusProperties(status.name, theme)
											.iconColor
									)}

									<Typography
										fontSize={16}
										fontWeight={500}
										color={
											getStatusProperties(
												status.name,
												theme
											).textColor
										}
										style={{
											textDecorationLine:
												getStatusProperties(status.name)
													.textDecorationLine,
										}}
									>
										{activitySummaryData.title}
									</Typography>
								</Stack>

								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									border="1px solid rgba(0, 0, 0, 0.1)"
									px={1}
									py={0.5}
									borderRadius="8px"
								>
									<Checkbox
										icon={RoundCheckBoxOffIcon(20, 20)}
										checkedIcon={RoundCheckBoxOnIcon(
											20,
											20,
											theme.palette.primary.main
										)}
										checked={
											getStatusProperties(status.name)
												.checked
										}
										onClick={() =>
											updateActivityStatus(
												status,
												activityId
											)
										}
									/>

									<Typography
										fontSize={13}
										fontWeight={600}
										color={
											getStatusProperties(
												status.name,
												theme
											).textColor
										}
									>
										{status?.name}
									</Typography>
								</Stack>
							</Stack>

							<Box>
								<table style={{ borderSpacing: "0px 8px" }}>
									<tbody>
										<tr>
											<td style={{ width: "180px" }}>
												<Typography fontSize={14}>
													Due Date :
												</Typography>
											</td>

											<td>
												<Typography
													fontSize={14}
													color={
														getStatusProperties(
															status.name,
															theme
														).textColor
													}
													style={{
														textDecorationLine:
															getStatusProperties(
																status.name
															)
																.textDecorationLine,
													}}
												>
													{getDateMonthAndYearFormat(
														activitySummaryData?.endTime
													)}
												</Typography>
											</td>
										</tr>

										<tr>
											<td>
												<Typography fontSize={14}>
													Priority :
												</Typography>
											</td>

											<td>
												<Typography fontSize={14}>
													{priority?.value
														? priority.name
														: "-"}
												</Typography>
											</td>
										</tr>

										<tr>
											<td>
												<Typography fontSize={14}>
													Reminder :
												</Typography>
											</td>

											<td>
												<Typography fontSize={14}>
													{remainder?.value
														? remainder.name
														: "-"}
												</Typography>
											</td>
										</tr>

										<tr>
											<td
												style={{ verticalAlign: "top" }}
											>
												<Typography fontSize={14}>
													Private Note :
												</Typography>
											</td>

											<td>
												<Typography fontSize={14}>
													{activitySummaryData?.note
														? activitySummaryData
																?.note.note
														: "-"}
												</Typography>
											</td>
										</tr>

										<tr>
											<td>
												<Typography fontSize={14}>
													Sales Owner :
												</Typography>
											</td>

											<td>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
												>
													<img
														src={UnKnownImage}
														alt="img"
														width={26}
														height={26}
														style={{
															borderRadius:
																"100%",
														}}
													/>

													<Typography fontSize={14}>
														{
															activitySummaryData
																?.owner?.name
														}
													</Typography>
												</Stack>
											</td>
										</tr>

										<Can
											permission={
												PERMISSIONS.contact.view
											}
										>
											<tr>
												<td>
													<Typography fontSize={14}>
														Contacts :
													</Typography>
												</td>

												<td>
													{activitySummaryData?.contacts ? (
														<Stack
															direction="row"
															spacing={1}
															alignItems="center"
														>
															{activitySummaryData
																?.contacts[0] && (
																<Typography
																	fontSize={
																		13
																	}
																	bgcolor={
																		theme
																			.palette
																			.secondary
																			.main
																	}
																	px={1}
																	py={0.2}
																	borderRadius="6px"
																>
																	{
																		activitySummaryData
																			?.contacts[0]
																			.fullName
																	}
																</Typography>
															)}
															{activitySummaryData
																?.contacts[1] && (
																<Typography
																	fontSize={
																		13
																	}
																	bgcolor={
																		theme
																			.palette
																			.secondary
																			.main
																	}
																	px={1}
																	py={0.2}
																	borderRadius="6px"
																>
																	{
																		activitySummaryData
																			?.contacts[1]
																			.fullName
																	}
																</Typography>
															)}
															{activitySummaryData
																?.contacts
																.length > 2 && (
																<Tooltip
																	title={activitySummaryData?.contacts
																		.slice(
																			2
																		)
																		.map(
																			(
																				contact,
																				index
																			) => (
																				<div
																					key={
																						index
																					}
																				>
																					{
																						contact.fullName
																					}
																				</div>
																			)
																		)}
																	placement="top"
																>
																	<Typography
																		fontSize={
																			14
																		}
																		fontWeight={
																			500
																		}
																		color={
																			theme
																				.palette
																				.primary
																				.main
																		}
																		style={{
																			cursor: "pointer",
																		}}
																	>
																		+
																		{activitySummaryData
																			?.contacts
																			.length -
																			2}
																	</Typography>
																</Tooltip>
															)}
														</Stack>
													) : (
														<Typography>
															-
														</Typography>
													)}
												</td>
											</tr>
										</Can>

										<Can
											permission={
												PERMISSIONS.company.view
											}
										>
											<tr>
												<td>
													<Typography fontSize={14}>
														Company :
													</Typography>
												</td>

												<td>
													{activitySummaryData?.company ? (
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
															style={{
																cursor: "pointer",
															}}
															onClick={(event) =>
																showCompanyCard(
																	event
																)
															}
														>
															<img
																src={
																	CompanyProfile
																}
																alt="img"
																width={26}
																height={26}
															/>
															<Typography
																fontSize={14}
																color={
																	theme
																		.palette
																		.secondary
																		.contrastText
																}
															>
																{
																	activitySummaryData
																		?.company
																		.name
																}
															</Typography>
														</Stack>
													) : (
														<Typography>
															-
														</Typography>
													)}
												</td>
											</tr>
										</Can>

										<tr>
											<td>
												<Typography fontSize={14}>
													Guests :
												</Typography>
											</td>

											<td>
												{activitySummaryData.guests ? (
													<Stack
														direction="row"
														spacing={1}
														alignItems="center"
													>
														{activitySummaryData
															.guests[0] && (
															<Typography
																fontSize={13}
																bgcolor={
																	theme
																		.palette
																		.secondary
																		.main
																}
																py={0.2}
																px={0.5}
																borderRadius="6px"
															>
																{
																	activitySummaryData
																		.guests[0]
																		.email
																		.value
																}
															</Typography>
														)}
														{activitySummaryData
															.guests.length >
															1 && (
															<Tooltip
																title={activitySummaryData.guests
																	.slice(1)
																	.map(
																		(
																			email,
																			index
																		) => (
																			<div
																				key={
																					index
																				}
																			>
																				{
																					email
																						.email
																						.value
																				}
																			</div>
																		)
																	)}
																placement="top"
															>
																<Typography
																	fontSize={
																		14
																	}
																	fontWeight={
																		500
																	}
																	color={
																		theme
																			.palette
																			.primary
																			.main
																	}
																	style={{
																		cursor: "pointer",
																	}}
																>
																	+
																	{activitySummaryData
																		.guests
																		.length -
																		1}
																</Typography>
															</Tooltip>
														)}
													</Stack>
												) : (
													"-"
												)}
											</td>
										</tr>

										<tr>
											<td
												style={{ verticalAlign: "top" }}
											>
												<Typography fontSize={14}>
													Location :
												</Typography>
											</td>

											<td>
												<Typography
													fontSize={14}
													sx={{
														wordBreak: "break-word",
													}}
												>
													{activitySummaryData.location
														? activitySummaryData.location
														: "-"}
												</Typography>
											</td>
										</tr>

										<tr>
											<td
												style={{ verticalAlign: "top" }}
											>
												<Typography fontSize={14}>
													Description :
												</Typography>
											</td>

											<td>
												<TooltipComponent
													title={
														activitySummaryData?.description
													}
													placement="top"
													horizontalDisplacement={-30}
												>
													<Typography
														fontSize={14}
														noWrap
														maxWidth={"333px"}
													>
														{activitySummaryData?.description
															? activitySummaryData.description
															: "-"}
													</Typography>
												</TooltipComponent>
											</td>
										</tr>
									</tbody>
								</table>
							</Box>

							{activitySummaryData?.deal ? (
								<Can permission={PERMISSIONS.deal.view}>
									<Box pt={2}>
										<DealCard
											dealDetails={
												activitySummaryData?.deal
											}
										/>
									</Box>
								</Can>
							) : null}

							<Box pt={2}>
								<Collaborators activityId={activityId} />
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
