import React from "react";
import AddForm from "../AddForm";

export default function AddUser(props) {
	const { onClose } = props;

	return (
		<React.Fragment>
			<AddForm onClose={onClose} isEditable={true} />
		</React.Fragment>
	);
}
