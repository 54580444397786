import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { default as twozoLogo } from "../../../../assets/images/logo/twozo-full.png";
import CustomHomeComponent from "../../../Elements/CustomHomeComponent";
import { useAuth } from "../../../../hooks/auth";
import { Navigate } from "react-router-dom";

export default function CheckInbox() {
	const { isUserAuthenticated } = useAuth();

	if (isUserAuthenticated()) {
		return <Navigate to="/deals" />;
	}

	return (
		<React.Fragment>
			<CustomHomeComponent>
				<Stack spacing={4} p={5} pb={3}>
					<Box>
						<img
							alt="twozo"
							src={twozoLogo}
							width="108px"
							height="34px"
						/>
					</Box>

					<Stack spacing={0.5} pb={2}>
						<Typography fontSize={18} fontWeight={600}>
							Check your inbox
						</Typography>
						<Typography fontSize={13}>
							We have sent an email containing instructions on how
							to change your password.
						</Typography>
					</Stack>
				</Stack>
			</CustomHomeComponent>
		</React.Fragment>
	);
}
