import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../styles/twozo";
import { useMemo, useState } from "react";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";
import MultiSelectFieldEditForm from "../../../../FieldEditFormComponents/MultiSelectEditForm";
import FieldEdit from "../../../../FieldEdit";

const fieldEditStyles = {
	fieldEditContainer: {
		width: "371px",
	},
	fieldEditForm: {
		width: "300px",
	},
};

export default function MultiSelectComponent(params) {
	const { summaryField } = params;

	// Summary Context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const theme = useTheme();
	const classes = twozoStyles();
	const [isMultiSelectHovered, setIsMultiSelectHovered] = useState(false);

	const multiSelectValues = summaryField?.value
		?.map((multiSelectValue) => multiSelectValue?.name)
		.join(", ");

	const getMultiSelectRequestData = (multiSelectFieldValue) => {
		let fieldValue = multiSelectFieldValue[summaryField?.fieldId];
		let modifiedMultiSelectFieldValue = {
			[summaryField?.fieldId]: fieldValue ? fieldValue : null,
		};
		return {
			...modifiedMultiSelectFieldValue,
			id: entityId,
		};
	};

	const arraysAreEqual = (arrayOne = [], arrayTwo = []) => {
		if (arrayOne.length !== arrayTwo.length) {
			return false;
		}

		let sortedArrayOne = [...arrayOne].sort();
		let sortedArrayTwo = [...arrayTwo].sort();

		for (let i = 0; i < sortedArrayOne.length; i++) {
			if (sortedArrayOne[i] !== sortedArrayTwo[i]) {
				return false;
			}
		}

		return true;
	};

	const hasSelectedOrMultiSelectValue = (multiSelectFieldValue) => {
		return (
			defaultSelectedValue?.length > 0 ||
			multiSelectFieldValue[summaryField?.fieldId]?.length > 0
		);
	};

	const hasValueOrIsChanged = (multiSelectFieldValue) => {
		return (
			!arraysAreEqual(
				multiSelectFieldValue[summaryField?.fieldId],
				defaultSelectedValue
			) && hasSelectedOrMultiSelectValue(multiSelectFieldValue)
		);
	};

	const handleSaveMultiSelectField = (multiSelectFieldValue, onClose) => {
		if (hasValueOrIsChanged(multiSelectFieldValue)) {
			let requestData = getMultiSelectRequestData(multiSelectFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const getMultiSelectFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value?.[0]?.name ? (
					<Stack
						direction="row"
						alignItems="center"
						spacing={0.5}
						width="150px"
					>
						<Box
							py={0.3}
							px={1}
							style={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "6px",
								overflow: "hidden",
							}}
						>
							<Typography
								fontSize={13}
								fontWeight={500}
								width="fit-content"
								className={classes.summaryData}
								sx={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									maxWidth: "100%",
								}}
							>
								{summaryField?.value?.[0]?.name}
							</Typography>
						</Box>

						<Tooltip title={multiSelectValues} placement="top">
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								{summaryField?.value?.length > 1 &&
									`+${summaryField.value.length - 1}`}
							</Typography>
						</Tooltip>
					</Stack>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Stack
						onMouseOver={() => setIsMultiSelectHovered(true)}
						onMouseLeave={() => setIsMultiSelectHovered(false)}
						padding={0}
						sx={{
							borderBottom: isMultiSelectHovered
								? "1px solid rgba(0, 0, 0, 0.2)"
								: "none",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={0.5}
							width="150px"
						>
							<Typography
								fontSize="14px"
								fontWeight={500}
								style={{
									color: theme.palette.secondary.contrastText,
									opacity: "0.4",
								}}
							>
								Click to Add
							</Typography>
							{isMultiSelectHovered ? (
								<Box display="flex">
									{DropDownIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
								</Box>
							) : null}
						</Stack>
					</Stack>
				)}
			</Box>
		);
	};

	const defaultSelectedValue = useMemo(() => {
		if (Array.isArray(summaryField.value)) {
			return summaryField.value.map((fieldValue) => fieldValue.id);
		}
		return [];
	}, [summaryField.value]);

	return (
		<>
			<FieldEdit
				field={summaryField}
				styles={fieldEditStyles}
				fieldLabel={getMultiSelectFieldLabel}
			>
				<MultiSelectFieldEditForm
					onSave={handleSaveMultiSelectField}
					value={defaultSelectedValue}
					field={summaryField}
				/>
			</FieldEdit>
		</>
	);
}
