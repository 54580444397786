import React, { useMemo, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useEmailTemplateAllFolderList } from "../../../../hooks/services/mail/emailTemplateFolder";
import Dialog from "../../../Elements/Dialog";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import TemplateTagsInput from "../AddTemplate/TemplateTagsInput";
import { useTemplateTagsDropdownList } from "../../../../hooks/services/mail/templateTags";
import { useCreateEmailTemplate } from "../../../../hooks/services/mail/emailTemplate";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { twozoStyles } from "../../../../styles/twozo";
import { parseHTML } from "../../../../utils/EmailUtils/parseHTML";
import { $generateHtmlFromNodes } from "@lexical/html";
import { enqueueSnackbar } from "notistack";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const selectMenuProps = {
	autoFocus: false,
	PaperProps: {
		style: {
			maxHeight: "240px",
			borderRadius: "8px",
			marginTop: "6px",
		},
		sx: {
			"& .MuiMenu-list": {
				padding: "0px 0px 4px 0px",
			},
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

export default function AddTemplateDialog(props) {
	const { open, onCloseAddTemplateDialog, subject } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [editor] = useLexicalComposerContext();

	const [searchedFolderValue, setSearchedFolderValue] = useState();
	const [selectedFolderId, setSelectedFolderId] = useState("");
	const [templateName, setTemplateName] = useState("");
	const [selectedTemplateTags, setSelectedTemplateTags] = useState([]);

	const title = "Save as Template";

	const { data: templateFolderList, isLoading: isTemplateFoldersLoading } =
		useEmailTemplateAllFolderList();
	const { data: templateTags } = useTemplateTagsDropdownList();
	const createTemplateMutation = useCreateEmailTemplate();

	const filteredFolderList = useMemo(() => {
		if (templateFolderList?.length > 0) {
			if (searchedFolderValue) {
				return templateFolderList.filter((folder) =>
					folder.name
						.toLowerCase()
						.startsWith(searchedFolderValue.toLowerCase())
				);
			}
			return templateFolderList;
		}
		return [];
	}, [templateFolderList, searchedFolderValue]);

	const isFolderListEmpty = filteredFolderList?.length === 0;

	const handleSearchFolder = (event) => {
		const { value } = event.target;
		setSearchedFolderValue(value);
	};

	const openUserListMenu = () => {
		setSearchedFolderValue("");
	};

	const handleSelectFolder = (event) => {
		const { value } = event.target;
		setSelectedFolderId(value);
	};

	const handleTemplateNameChange = (event) => {
		const { value } = event.target;
		setTemplateName(value.trimStart());
	};

	const handleSelectTags = (values) => {
		setSelectedTemplateTags(values);
	};

	const onClose = () => {
		onCloseAddTemplateDialog();
		setTemplateName("");
		setSelectedFolderId("");
		setSelectedTemplateTags([]);
	};

	const getTagIdByName = (tagName) => {
		let tagData = templateTags?.find(
			(templateTag) => templateTag?.name === tagName
		);
		if (tagData) {
			return tagData.id;
		}
	};

	const getFormatedTemplateTags = () => {
		let formatedTemplateTags = [];
		for (let templateTag of selectedTemplateTags) {
			let templateTagId = getTagIdByName(templateTag);
			if (templateTagId) {
				formatedTemplateTags.push({ id: templateTagId });
			} else {
				formatedTemplateTags.push({ name: templateTag });
			}
		}
		return formatedTemplateTags;
	};

	const getTemplateContent = () => {
		let editorState = editor.getEditorState();
		let htmlBodyContent = editorState.read(() => {
			return parseHTML($generateHtmlFromNodes(editor), classes);
		});

		return htmlBodyContent;
	};

	const onSaveTemplate = () => {
		let templateFormData = {
			name: templateName,
			folderId: selectedFolderId,
			tags: getFormatedTemplateTags(),
			content: getTemplateContent(),
			subject: subject,
		};
		createTemplateMutation.mutate(templateFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.emailTemplateCreated,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const isSaveButtonDisabled = () => {
		return (
			!templateName ||
			!selectedFolderId ||
			createTemplateMutation.isLoading
		);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="50px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					Match not found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Dialog open={open} title={title}>
				<Stack spacing={2} p={2}>
					<Stack spacing={2}>
						<Stack spacing={0.5}>
							<Typography fontSize={13} fontWeight={500} pl={0.8}>
								Select Folder
							</Typography>

							<Select
								size="small"
								displayEmpty
								value={selectedFolderId}
								renderValue={
									selectedFolderId !== ""
										? undefined
										: () => (
												<Typography
													fontSize={14}
													color="rgba(0, 0, 0, 0.6)"
												>
													Select
												</Typography>
											)
								}
								MenuProps={selectMenuProps}
								onOpen={openUserListMenu}
								onChange={handleSelectFolder}
							>
								<Box
									p={0.5}
									sx={{
										position: "sticky",
										top: 0,
										zIndex: 1,
										backgroundColor: "#fff",
									}}
								>
									<MenuSearchBar
										value={searchedFolderValue}
										onChange={handleSearchFolder}
									/>
								</Box>

								<MenuItem
									value=""
									sx={{ display: "none" }}
								></MenuItem>

								{isTemplateFoldersLoading ? (
									<Stack
										alignItems="center"
										justifyContent="center"
										height="150px"
									>
										<CircularProgress size={18} />
									</Stack>
								) : isFolderListEmpty ? (
									renderEmptyView()
								) : (
									filteredFolderList?.map((folder) => (
										<MenuItem
											key={folder.id}
											value={folder.id}
											style={{ minHeight: "40px" }}
											autoFocus={false}
										>
											<Typography fontSize={13}>
												{folder.name}
											</Typography>
										</MenuItem>
									))
								)}
							</Select>
						</Stack>

						<Stack spacing={0.5}>
							<Typography fontSize={13} fontWeight={500} pl={0.8}>
								Email Template Name
							</Typography>

							<TextField
								value={templateName}
								onChange={handleTemplateNameChange}
							/>
						</Stack>

						<Stack spacing={0.5}>
							<Typography fontSize={13} fontWeight={500} pl={0.8}>
								Tags
							</Typography>

							<TemplateTagsInput
								tagsList={templateTags || []}
								handleSelectTags={handleSelectTags}
								selectedTags={selectedTemplateTags}
							/>
						</Stack>
					</Stack>

					<Box p={1}>
						<Stack
							direction="row"
							justifyContent="flex-end"
							spacing={2}
						>
							<Button
								onClick={onClose}
								variant="contained"
								color="secondary"
								disableElevation
								disabled={createTemplateMutation.isLoading}
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								disableElevation
								sx={{
									"&.Mui-disabled": {
										backgroundColor: `${theme.palette.primary.main}`,
										color: "rgba(255,255,255, 0.6)",
									},
								}}
								disabled={isSaveButtonDisabled()}
								onClick={onSaveTemplate}
							>
								Save
							</Button>
						</Stack>
					</Box>
				</Stack>
			</Dialog>
		</React.Fragment>
	);
}
