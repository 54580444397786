import { Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";

export default function Email(props) {
	const { highlightText, emails } = props;

	return (
		<React.Fragment>
			<Stack flexDirection="row" alignItems="center" width="100%">
				<Typography style={styles.subText} pr={1} noWrap>
					Email Id: {highlightText(emails?.value)}
				</Typography>

				{emails?.count && (
					<Typography style={styles.moreContent} width="20%">
						+{emails?.count} more matches
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
