import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCurrentUserKey } from "../../../utils/queryKeys/userProfile";
import {
	changePassword,
	getCurrentUserDetails,
} from "../../../api/auth/authApi";
import { updateUser } from "../../../api/userManagement/user/userApi";

const useCurrentUserDetails = () => {
	return useQuery(getCurrentUserKey(), () => getCurrentUserDetails(), {
		select: (userdetails) => userdetails.data,
	});
};

const useChangePassword = () => {
	return useMutation(changePassword);
};

const useInvalidateCurrentUserDetails = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCurrentUserKey);
	};
};

const useUpdateCurrentUserMutation = () => {
	let invalidateCurrentUserDetails = useInvalidateCurrentUserDetails();
	return useMutation(updateUser, {
		onSuccess: () => {
			invalidateCurrentUserDetails();
		},
	});
};

export {
	useCurrentUserDetails,
	useChangePassword,
	useUpdateCurrentUserMutation,
};
