import React, { useMemo, useState } from "react";
import {
	Box,
	Chip,
	CircularProgress,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import UserImage from "../../../../../assets/images/contact/unknownContact.png";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { useUsers } from "../../../../../hooks/account/user";

export default function Collaborator(props) {
	const { value, onChange } = props;
	const [searchedValue, setSearchValue] = useState("");
	const [colloborators, setColloborators] = useState([]);

	const {
		activeUserList,
		isLoading: isLoadingUserList,
		getUserDataById,
	} = useUsers(searchedValue);

	const filteredUserList = useMemo(() => {
		if (Array.isArray(activeUserList)) {
			return activeUserList?.filter(
				(user) => !colloborators.includes(user.value)
			);
		}
		return [];
	}, [activeUserList, colloborators]);

	const getSelectedCollaboratorName = (collaboratorId) => {
		const selectedCollaborator = getUserDataById(collaboratorId);
		return selectedCollaborator?.name ?? "";
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const handleCollaboratorChange = (event) => {
		const { value } = event.target;
		onChange(value);
		setColloborators(value);
	};

	const unSelectCollaborator = (value, collaboratorIds) => {
		const updatedCollaboratorIds = collaboratorIds.filter(
			(id) => id !== value
		);
		onChange(updatedCollaboratorIds);
		setColloborators(updatedCollaboratorIds);
	};

	const isCollaboratorSelected = (value) => {
		return value?.length > 0;
	};

	return (
		<Select
			displayEmpty
			multiple
			value={value || []}
			onChange={handleCollaboratorChange}
			renderValue={
				isCollaboratorSelected(value)
					? (selectedCollaborator) => (
							<Box>
								{selectedCollaborator.map((collaborator) => (
									<Chip
										size="small"
										key={collaborator}
										label={
											<Typography
												fontSize={14}
												color="#000"
											>
												{getSelectedCollaboratorName(
													collaborator
												)}
											</Typography>
										}
										color="secondary"
										deleteIcon={
											<Stack
												direction="row"
												alignItems="center"
												onMouseDown={(event) =>
													event.stopPropagation()
												}
											>
												{CloseIcon(16, 16, "grey")}
											</Stack>
										}
										onDelete={() =>
											unSelectCollaborator(
												collaborator,
												value
											)
										}
										sx={{
											borderRadius: "8px",
											marginRight: "4px",
										}}
									/>
								))}
							</Box>
						)
					: () => (
							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								Select
							</Typography>
						)
			}
			onOpen={() => setSearchValue("")}
		>
			<Box
				p={0.5}
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: "#fff",
				}}
			>
				<MenuSearchBar value={searchedValue} onChange={handleSearch} />
			</Box>

			{isLoadingUserList ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="150px"
				>
					<CircularProgress size={18} />
				</Stack>
			) : filteredUserList.length === 0 ? (
				<Typography
					style={{
						textAlign: "center",
						padding: 10,
						fontSize: "13px",
						color: "rgba(0, 0, 0, 0.6)",
					}}
				>
					No Results Found
				</Typography>
			) : (
				filteredUserList?.map((user) => (
					<MenuItem
						key={user.value}
						value={user.value}
						style={{
							minHeight: "40px",
						}}
						autoFocus={false}
					>
						<Stack direction="row" spacing={2} alignItems="center">
							<img
								src={UserImage}
								alt="contact_image"
								height="24px"
								width="24px"
							/>

							<Typography fontSize={13}>{user.name}</Typography>
						</Stack>
					</MenuItem>
				))
			)}
		</Select>
	);
}
