import { useReducer } from "react";

export const fieldActionTypes = {
	createMode: "CREATE_MODE",
	fieldNameInEditMode: "FIELD_NAME_IN_EDIT_MODE",
	resetFieldNameError: "RESET_FIELD_ERROR",
	fieldValueChange: "FIELD_VALUE_CHANGE",
	fieldNameChanged: "FIELD_NAME_CHANGE",
	AddViewChange: "ADD_VIEW_CHANGE",
	requiredFieldChanged: "REQUIRED_Field_CHANGE",
	fieldTypeChange: "FIELD_TYPE_CHANGE",
	resetFieldNameInEditMode: "RESET_FIELD_NAME_IN_EDIT_MODE",
	selectChoicesChanged: "SELECT_CHOICES_CHANGED",
	hasSelectChoiceChanged: "ON_SELECT_CHOICE_CHANGE",
	initializeUnSavedFieldData: "INITIALIZED_UNSAVED_FIELD_DATA",
	OnFieldNameLimitExceeded: "ON_FIELD_NAME_LIMIT_EXCEEDED",
};

export const fieldReducer = (state, { type, payload }) => {
	switch (type) {
		case fieldActionTypes.fieldNameInEditMode:
			return {
				...state,
				isFieldNameInEditMode: true,
			};
		case fieldActionTypes.OnFieldNameLimitExceeded:
			return {
				...state,
				isFieldNameLimitExceeded: true,
			};
		case fieldActionTypes.resetFieldNameError:
			return {
				...state,
				isFieldNameLimitExceeded: false,
			};
		case fieldActionTypes.initializeUnSavedFieldData:
			return {
				...state,
				unSavedFieldData: {
					...state.unSavedFieldData,
					fieldName: payload.fieldName,
					isAddView: payload.isAddView,
					isRequired: payload.isRequired,
				},
			};
		case fieldActionTypes.fieldNameChanged:
			return {
				...state,
				unSavedFieldData: {
					...state.unSavedFieldData,
					fieldName: payload,
				},
			};
		case fieldActionTypes.AddViewChange:
			return {
				...state,
				unSavedFieldData: {
					...state.unSavedFieldData,
					isAddView: payload,
				},
			};
		case fieldActionTypes.requiredFieldChanged:
			return {
				...state,
				unSavedFieldData: {
					...state.unSavedFieldData,
					isRequired: payload,
				},
			};
		case fieldActionTypes.fieldValueChange:
			return {
				...state,
				isFieldValueChanged: payload,
			};
		case fieldActionTypes.hasSelectChoiceChanged:
			return {
				...state,
				isSelectChoiceChanged: payload,
			};
		case fieldActionTypes.fieldTypeChange:
			return {
				...state,
				unSavedFieldData: {
					...state.unSavedFieldData,
					fieldType: payload,
				},
			};
		case fieldActionTypes.selectChoicesChanged:
			return {
				...state,
				unSavedFieldData: {
					...state.unSavedFieldData,
					selectChoices: [...payload],
				},
			};
		case fieldActionTypes.resetFieldNameInEditMode:
			return {
				...state,
				isFieldNameInEditMode: false,
			};
		default:
			state;
	}
};

export const useFieldReducer = (field) => {
	const initialFieldState = {
		field: field,
		unSavedFieldData: {},
		isFieldNameInEditMode: false,
		fieldInCreateMode: !!field.isFieldNotSaved,
		isSelectChoiceChanged: false,
		isFieldValueChanged: false,
		isFieldNameLimitExceeded: false,
	};
	const [fieldState, fieldStateDispatcher] = useReducer(
		fieldReducer,
		initialFieldState
	);

	const initializeUnSavedFieldData = (fieldName, isAddView, isRequired) => {
		fieldStateDispatcher({
			type: fieldActionTypes.initializeUnSavedFieldData,
			payload: { fieldName, isAddView, isRequired },
		});
	};

	const onFieldNameInEditMode = () => {
		fieldStateDispatcher({ type: fieldActionTypes.fieldNameInEditMode });
	};

	const resetFieldNameInEditMode = () => {
		fieldStateDispatcher({
			type: fieldActionTypes.resetFieldNameInEditMode,
		});
	};

	const onFieldNameValueChange = (fieldName) => {
		fieldStateDispatcher({
			type: fieldActionTypes.fieldNameChanged,
			payload: fieldName,
		});
	};

	const onAddViewChange = (isAddView) => {
		fieldStateDispatcher({
			type: fieldActionTypes.AddViewChange,
			payload: isAddView,
		});
	};

	const onRequiredFieldChange = (isRequired) => {
		fieldStateDispatcher({
			type: fieldActionTypes.requiredFieldChanged,
			payload: isRequired,
		});
	};

	const onFieldTypeChange = (fieldType) => {
		fieldStateDispatcher({
			type: fieldActionTypes.fieldTypeChange,
			payload: fieldType,
		});
	};

	const onFieldValueChange = (isFieldValueChanged) => {
		fieldStateDispatcher({
			type: fieldActionTypes.fieldValueChange,
			payload: isFieldValueChanged,
		});
	};

	const hasSelectChoiceChanged = (isSelectChoiceChanged) => {
		fieldStateDispatcher({
			type: fieldActionTypes.hasSelectChoiceChanged,
			payload: isSelectChoiceChanged,
		});
	};

	const onSelectChoicesChanged = (selectChoices) => {
		fieldStateDispatcher({
			type: fieldActionTypes.selectChoicesChanged,
			payload: selectChoices,
		});
	};

	const OnFieldNameLimitExceeded = () => {
		fieldStateDispatcher({
			type: fieldActionTypes.OnFieldNameLimitExceeded,
		});
	};

	const resetFieldNameError = () => {
		fieldStateDispatcher({
			type: fieldActionTypes.resetFieldNameError,
		});
	};

	return {
		fieldState,
		onFieldNameInEditMode,
		resetFieldNameInEditMode,
		resetFieldNameError,
		onFieldNameValueChange,
		onAddViewChange,
		onFieldTypeChange,
		onRequiredFieldChange,
		onFieldValueChange,
		hasSelectChoiceChanged,
		onSelectChoicesChanged,
		initializeUnSavedFieldData,
		OnFieldNameLimitExceeded,
	};
};
