import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import WebFormPreview from "../WebFormBuilder/WebFormPreview";
import Customize from "../WebFormBuilder/WebFormEditor/Customize";
import { useWebFormDeatils } from "../../../../hooks/services/webform";

export default function EditWebForm() {
	const menuBarRef = useRef();
	let { webformId } = useParams();

	const [menuBarRowEndingPosition, setMenuBarRowEndingPosition] = useState(0);
	const [webFormData, setWebFormData] = useState({});
	const [type, setType] = useState("");
	const hasWebFormDataBeenSet = useRef(null);

	const {
		status: webFormDetailsStatus,
		data: webFormDetails,
		isLoading,
	} = useWebFormDeatils(webformId);

	useEffect(() => {
		if (webFormDetailsStatus === "success") {
			if (webFormDetails && !hasWebFormDataBeenSet.current) {
				setWebFormData(webFormDetails);
				hasWebFormDataBeenSet.current = true;
			}
		}
	}, [webFormDetailsStatus, webFormDetails]);

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarRowEndingPosition(
				menuBarRef.current?.getBoundingClientRect().top
			);
		}
	}, [menuBarRef]);

	return (
		<React.Fragment>
			<Box
				ref={menuBarRef}
				height={`calc(100vh - ${menuBarRowEndingPosition}px)`}
			>
				{isLoading || !(Object.keys(webFormData).length > 0) ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						p={1}
						height="100%"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Stack direction="row" height="100%">
						<Box width="70%" overflow="auto">
							<Customize
								currentFormData={webFormData}
								updateFormData={setWebFormData}
								isEditMode={true}
								toggleSubmitOptions={setType}
							/>
						</Box>
						<Divider orientation="vertical" flexItem />
						<Box width="30%" overflow="auto">
							<WebFormPreview
								formToPreview={webFormData}
								type={type}
							/>
						</Box>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
