import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import InfoIcon from "../../../../../assets/icons/info";
import ContactCardList from "./ContactCardList";
import {
	useMergeDuplicateContact,
	useReviewContactDuplicateList,
} from "../../../../../hooks/services/contact/contactDuplicates";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

const styles = {
	contentName: {
		fontSize: 14,
		fontWeight: 500,
	},
	subText: {
		fontSize: 13,
	},
	text: {
		fontSize: 14,
	},
};

export default function ReviewDuplicates(props) {
	const { primaryContactId, onClose } = props;
	const theme = useTheme();
	const [selectedContact, setSelectedContact] = useState({});

	const errorNotificationTitle = "Unable to merge";

	const {
		isLoading: isLoadingDuplicateRecords,
		data: duplicateRecords,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useReviewContactDuplicateList(primaryContactId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const hasDuplicateRecords =
		duplicateRecords &&
		duplicateRecords?.pages?.length > 0 &&
		duplicateRecords?.pages[0]?.duplicates &&
		duplicateRecords?.pages[0]?.duplicates?.length > 0;

	const mergeContactMutation = useMergeDuplicateContact();

	const handleMergeContact = () => {
		if (selectedContact?.id) {
			let requestData = getMergeRequestData();
			mergeContactMutation.mutate(requestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							duplicateRecords?.pages?.length > 0 &&
							duplicateRecords?.pages[0]?.totalRecords === 2
								? `${
										duplicateRecords?.pages[0]
											?.totalRecords - 1
									} contact have been merged successfully`
								: `${
										duplicateRecords?.pages[0]
											?.totalRecords - 1
									} contacts have been merged successfully`,
					});
					onClose();
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						title: errorNotificationTitle,
						message: errorMessage
							? errorMessage
							: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	const getMergeRequestData = () => {
		let requestData = {
			primaryContactId: selectedContact.id,
			duplicateContactIds: [],
		};

		if (duplicateRecords?.pages?.length > 0) {
			for (let page of duplicateRecords.pages) {
				if (page.duplicates?.length > 0) {
					for (let contactData of page.duplicates) {
						if (contactData.id !== selectedContact.id) {
							requestData.duplicateContactIds.push(
								contactData.id
							);
						}
					}
				}
			}
		}
		return requestData;
	};

	const handleSelectContact = (contact) => {
		setSelectedContact(contact);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
					>
						<Typography fontWeight={600}>
							Review Possible Duplicates
						</Typography>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				{isLoadingDuplicateRecords ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box
							m={2}
							p={2}
							style={{
								backgroundColor: "#E7F7F0",
								borderRadius: "8px",
							}}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<IconButton>
									{InfoIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
								<Stack>
									<Typography style={styles.text}>
										We check for exact matches in these
										fields:
									</Typography>
									<Typography style={styles.text}>
										Emails, Work email, Work, Mobile, Other
										phone numbers
									</Typography>
								</Stack>
							</Stack>
						</Box>

						<Box ml={2.5} pt={1}>
							<Typography style={styles.subText} fontWeight={500}>
								Which contact would you like to retain?
							</Typography>
						</Box>

						<Stack px={2} py={1} spacing={1}>
							{hasDuplicateRecords &&
								duplicateRecords?.pages.map((page) =>
									page.duplicates?.map((contactData) => (
										<Box key={contactData.id}>
											<ContactCardList
												contactData={contactData}
												isSelected={
													selectedContact?.id ===
													contactData.id
												}
												handleSelectContact={
													handleSelectContact
												}
											/>
										</Box>
									))
								)}

							<Box ref={lastRowRef}>
								{!isLoadingDuplicateRecords && isFetching && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								)}
							</Box>
						</Stack>
					</Box>
				)}

				{!isLoadingDuplicateRecords && (
					<>
						<Divider />

						<Box p={2}>
							<Button
								variant="contained"
								disableElevation
								onClick={handleMergeContact}
								style={{
									color: selectedContact?.id
										? "#ffff"
										: "rgba(255, 255, 255, 0.6)",
								}}
							>
								Merge
							</Button>
						</Box>
					</>
				)}
			</Box>
		</React.Fragment>
	);
}
