import React, { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import { default as twozoLogo } from "../../../assets/images/logo/twozo-full.png";
import { useForm } from "react-hook-form";
import { signIn } from "../../../api/auth/authApi";
import { useMutation } from "@tanstack/react-query";
import { emailPattern } from "../../../utils/validation";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import CustomHomeComponent from "../../Elements/CustomHomeComponent";
import { useAuth } from "../../../hooks/auth";
import { redirectRefQueryParamName } from "../../../utils/Auth";

export default function Login() {
	const { isUserAuthenticated, navigateToHomeBasedOnPermission } = useAuth();

	const [queryParams] = useSearchParams();

	if (isUserAuthenticated()) {
		if (queryParams.get(redirectRefQueryParamName)) {
			const redirectRef = queryParams.get(redirectRefQueryParamName);
			return <Navigate to={redirectRef}></Navigate>;
		}

		return navigateToHomeBasedOnPermission();
	}

	return <LoginPage />;
}

function LoginPage() {
	const theme = useTheme();
	const navigate = useNavigate();

	const [isPasswordHidden, setIsPasswordHidden] = useState(false);

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm();

	const onSubmit = (data) => {
		signInMutation.mutate(data);
	};

	const { reloadAuth } = useAuthContext();

	const signInMutation = useMutation(
		async (signInData) => signIn(signInData),
		{
			onSuccess: () => {
				reloadAuth();
			},
			onError: (error) => {
				setError("password", {
					type: "custom",
					message: error.message,
				});
			},
		}
	);

	const handleClickShowPassword = () => setIsPasswordHidden((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handlePasswordFieldEnterKeyPress = (event) => {
		if (event.key === "Enter") {
			handleSubmit(onSubmit)();
		}
		return;
	};

	return (
		<React.Fragment>
			<CustomHomeComponent>
				<Stack spacing={4} p={5} pb={3}>
					<Box>
						<img
							alt="twozo"
							src={twozoLogo}
							width="108px"
							height="34px"
						/>

						<Stack spacing={2}>
							<Typography fontSize={18} fontWeight={600}>
								Welcome Back!
							</Typography>

							<Stack spacing={0.5}>
								<Typography
									fontSize={13}
									fontWeight={500}
									color={
										!!errors.email &&
										theme.palette.error.main
									}
								>
									Work Email Id
								</Typography>

								<TextField
									placeholder="Enter your work email id"
									{...register("email", {
										required:
											"Please enter your email address",
										pattern: {
											value: emailPattern,
											message:
												"Please enter a valid work email id",
										},
									})}
									onChange={(event) => {
										setValue("email", event.target.value); //set the value to the react-hook-form, if the field is auto-filled by the browser, react-form does not know it
										clearErrors("email"); // Clear email validation error
									}}
									FormHelperTextProps={{
										sx: {
											marginLeft: 0,
											fontSize: 13,
										},
									}}
									error={!!errors.email}
									helperText={
										!!errors.email && errors.email?.message
									}
								/>
							</Stack>

							<Stack spacing={0.5}>
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="space-between"
								>
									<Typography
										fontSize={13}
										fontWeight={500}
										color={
											!!errors.password &&
											theme.palette.error.main
										}
									>
										Password
									</Typography>

									<Link
										underline="none"
										component="button"
										onClick={() =>
											navigate("/forgot-password")
										}
									>
										<Typography
											fontSize={13}
											fontWeight={500}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											Forgot Password?
										</Typography>
									</Link>
								</Stack>

								<TextField
									placeholder="Enter password"
									type={
										isPasswordHidden ? "text" : "password"
									}
									FormHelperTextProps={{
										sx: {
											marginLeft: 0,
											fontSize: 13,
										},
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowPassword
													}
													onMouseDown={
														handleMouseDownPassword
													}
													edge="end"
												>
													{isPasswordHidden
														? EyeOffIcon(
																20,
																20,
																"#999999"
															)
														: EyeIcon(
																20,
																20,
																"#999999"
															)}
												</IconButton>
											</InputAdornment>
										),
									}}
									{...register("password", {
										required: "Please enter your password",
									})}
									onChange={(event) => {
										setValue(
											"password",
											event.target.value
										); //set the value to the react-hook-form, if the field is auto-filled by the browser, react-form does not know it
										clearErrors("password"); // Clear email validation error
									}}
									error={!!errors.password}
									helperText={
										!!errors.password &&
										errors.password?.message
									}
									onKeyDown={handlePasswordFieldEnterKeyPress}
								/>
							</Stack>

							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								<Checkbox />

								<Typography fontSize={13} fontWeight={500}>
									Remember Me
								</Typography>
							</Stack>

							<Box py={1}>
								<Button
									variant="contained"
									onClick={handleSubmit(onSubmit)}
									disableElevation
									disabled={signInMutation.isLoading}
								>
									Sign In
								</Button>
							</Box>
						</Stack>
					</Box>
				</Stack>

				<Stack
					px={2.5}
					py={2}
					style={{
						borderTop: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					direction="row"
					spacing={0.5}
				>
					<Typography fontSize={13} fontWeight={500}>
						Don’t have Twozo account?
					</Typography>

					<Typography
						fontSize={13}
						fontWeight={500}
						style={{ cursor: "pointer" }}
						color={theme.palette.secondary.contrastText}
						onClick={() => navigate("/signup")}
					>
						Sign Up
					</Typography>
				</Stack>
			</CustomHomeComponent>
		</React.Fragment>
	);
}
