import React from "react";

export default function FillClose(width = 24, height = 24, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			color={color}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width={width}
				height={height}
				transform="translate(1 1)"
				fill="white"
			/>
			<circle cx="9.00004" cy="8.99992" r="8.66667" fill="#EB5454" />
			<path
				d="M5 5L13 13"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M13 5L5 13"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
}
