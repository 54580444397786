import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getDealParticipantsDataKey,
	getDealParticipantsDropdownKey,
} from "../../../utils/queryKeys";
import {
	addParticipant,
	deleteParticipant,
	getDealParticipantDropdownList,
	getDealParticipantsData,
} from "../../../api/deal/dealApi";
import { relatedContactsPageSize } from "../../../utils/queryConstants/summary/relatedContacts";

const relatedContactsSelect = (relatedContacts) => {
	return relatedContacts?.pages?.flatMap((page) => page?.list);
};

const useDealRelatedContacts = (dealId) => {
	return useInfiniteQuery({
		queryKey: getDealParticipantsDataKey(dealId),
		queryFn: ({ pageParam }) => {
			return getDealParticipantsData(dealId, {
				start: pageParam
					? (pageParam - 1) * relatedContactsPageSize + 1
					: 1,
				limit: relatedContactsPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: relatedContactsSelect,
	});
};

const useInvalidateDealParticipantsData = (dealId) => {
	let queryClient = useQueryClient();
	const dealParticipantsDataKey = getDealParticipantsDataKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealParticipantsDataKey);
	};
};

//Add Participant
const useAddParticipantMutation = (dealId) => {
	let invalidateParticipantsData = useInvalidateDealParticipantsData(dealId);
	let invalidateParticipantsDropdownList =
		useInvalidateParticipantsDropdownList(dealId);
	return useMutation(addParticipant, {
		onSuccess: () => {
			invalidateParticipantsData();
			invalidateParticipantsDropdownList();
		},
	});
};

//Delete Participant
const useDeleteParticipantMutation = (dealId) => {
	let invalidateParticipantsData = useInvalidateDealParticipantsData(dealId);
	let invalidateParticipantsDropdownList =
		useInvalidateParticipantsDropdownList(dealId);
	return useMutation(deleteParticipant, {
		onSuccess: () => {
			invalidateParticipantsData();
			invalidateParticipantsDropdownList();
		},
	});
};

// participants dropdown list
const useParticipantsDropdownList = (dealId) => {
	const participantsDropdownKey = getDealParticipantsDropdownKey(dealId);
	return useQuery(
		participantsDropdownKey,
		() => getDealParticipantDropdownList(dealId),
		{
			enabled: !!dealId,
			select: (data) => data?.options,
		}
	);
};

const useInvalidateParticipantsDropdownList = (dealId) => {
	let queryClient = useQueryClient();
	const participantsDropdownKey = getDealParticipantsDropdownKey(dealId);
	return () => {
		queryClient.invalidateQueries(participantsDropdownKey);
	};
};

export {
	useDealRelatedContacts,
	useInvalidateDealParticipantsData,
	useDeleteParticipantMutation,
	useAddParticipantMutation,
	useParticipantsDropdownList,
	useInvalidateParticipantsDropdownList,
};
