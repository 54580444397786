import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { TableCellText } from "../../../../styles/twozo";
import Menu from "../../Menu";

export default function Phone(props) {
	const { data } = props;
	const [phoneMenuElement, setPhoneMenuElement] = useState(null);
	const openPhoneMenu = Boolean(phoneMenuElement);

	const OpenPhoneMenu = (event) => {
		event.stopPropagation();
		setPhoneMenuElement(event.currentTarget);
	};

	const ClosePhoneMenu = (event) => {
		event.stopPropagation();
		setPhoneMenuElement(null);
	};

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="400px"
				anchorEl={phoneMenuElement}
				open={openPhoneMenu}
				onClose={(e) => ClosePhoneMenu(e)}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px",
				}}
			>
				<Box>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={3}
						pl={3}
						pr={2}
						py={1.5}
					>
						<TableCellText>
							{data?.countryCode} {data?.phoneNumber}
						</TableCellText>

						<Typography fontSize={12} color="rgba(0, 0, 0, 0.6)">
							{data?.phoneType}
						</Typography>
					</Stack>
				</Box>
			</Menu>

			<Box onClick={(e) => OpenPhoneMenu(e)}>
				<TableCellText>
					{data?.countryCode} {data?.phoneNumber}
				</TableCellText>
			</Box>
		</React.Fragment>
	);
}
