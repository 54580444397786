const intervalList = [
	{
		id: 1,
		name: "Month",
	},
	{
		id: 2,
		name: "Week",
	},
	{
		id: 3,
		name: "Quarter",
	},
];

export default intervalList;
