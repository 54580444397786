import React from "react";

function SingleContact(width, height, color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 16 16"
			style={{ fill: "none" }}
		>
			<path
				d="M7.65721 7.24671C6.07055 7.19337 4.81055 5.89337 4.81055 4.29337C4.81055 2.66004 6.13055 1.33337 7.77055 1.33337C9.40388 1.33337 10.7305 2.66004 10.7305 4.29337C10.7239 5.89337 9.46388 7.19337 7.87721 7.24671C7.81055 7.24004 7.73055 7.24004 7.65721 7.24671Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.54398 13.6201C6.37732 14.8467 9.38399 14.8467 11.2173 13.6201C12.8307 12.5401 12.8307 10.7801 11.2173 9.70675C9.39065 8.48675 6.38398 8.48675 4.54398 9.70675C2.93065 10.7867 2.93065 12.5467 4.54398 13.6201Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default SingleContact;
