import { getRootKey } from "../rootKey";

const searchKey = "search";

// search query key
const getSearchKey = () => {
	return [...getRootKey(), searchKey];
};

// query keys
export const searchedAndViewedKey = [
	...getSearchKey(),
	"recentlySearchedAndViewed",
];
