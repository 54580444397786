import React from "react";
import { styles } from "../../../styles";
import { Stack, Typography } from "@mui/material";

export default function CustomField(props) {
	const { highlightText, customFields } = props;

	return (
		<React.Fragment>
			<Stack flexDirection="row" alignItems="center" width="100%">
				<Typography style={styles.subText} pr={1} noWrap>
					CustomField: {highlightText(customFields.name)}
				</Typography>

				{customFields?.count && (
					<Typography style={styles.moreContent} width="20%">
						+{customFields?.count} more matches
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
