import React from "react";

export default function MailScheduled(
	width = "25",
	height = "24",
	color = "#000"
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 25 24"
		>
			<path
				d="M22 11.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H14"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.13 11.125C21.1538 11.9775 19.8887 12.5 18.5 12.5C15.4612 12.5 13 10.0388 13 7C13 5.2675 13.7975 3.71376 15.0625 2.71001C16.0112 1.95376 17.2075 1.5 18.5 1.5C21.5388 1.5 24 3.96125 24 7C24 8.65 23.2713 10.1212 22.13 11.125Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.25 5V7.35988L19.5 8.10988"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7 10L10.13 12.5C11.16 13.32 12.85 13.32 13.88 12.5L15.06 11.56"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
