import React, { useMemo } from "react";
import { TableCellText, Tooltip } from "../../../../styles/twozo";
import { Stack, useTheme } from "@mui/material";

export default function Tags(props) {
	const { data } = props;
	const theme = useTheme();

	const tags = useMemo(() => {
		return data?.map((tag) => tag?.name).join(", ");
	}, [data]);

	if (!data) {
		return "-";
	}

	return (
		<React.Fragment>
			<Stack direction="row" spacing={0.5} alignItems="center">
				<TableCellText
					py={0.3}
					px={1}
					style={{
						backgroundColor: theme.palette.secondary.main,
						width: "fit-content",
						borderRadius: "6px",
					}}
				>
					{data?.[0]?.name}
				</TableCellText>

				<Tooltip title={tags} placement="top">
					<TableCellText color={theme.palette.primary.main}>
						{data.length > 1 && `+${data.length - 1}`}
					</TableCellText>
				</Tooltip>
			</Stack>
		</React.Fragment>
	);
}
