import { Box, Typography, useTheme } from "@mui/material";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../styles/twozo";
import { useMemo, useState } from "react";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";
import PhoneFieldEditForm from "../../../../FieldEditFormComponents/PhoneFieldEditForm";
import FieldEdit from "../../../../FieldEdit";

const fieldEditStyles = {
	fieldEditContainer: {
		width: "400px",
	},
	fieldEditForm: {
		width: "310px",
	},
};

export default function PhoneComponent(params) {
	const { summaryField, subFields } = params;

	const theme = useTheme();
	const classes = twozoStyles();

	// Summary Context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const phoneNumberField = getSubFieldObject("PHONE_NUMBER");
	const [openToolTip, setOpenToolTip] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const modifiedPhoneValue = useMemo(() => {
		if (summaryField.value) {
			const { PHONE_NUMBER, PHONE_TYPE } = summaryField.value;

			return {
				[PHONE_NUMBER.id]: PHONE_NUMBER?.value,
				[PHONE_TYPE.id]: PHONE_TYPE?.value,
				id: entityId,
			};
		}
		return {};
	}, [entityId, summaryField?.value]);

	const getPhoneRequestData = (phoneFieldValue) => {
		let phoneNumber = phoneFieldValue[phoneNumberField?.id];
		return {
			[summaryField.fieldId]: phoneNumber
				? {
						...phoneFieldValue,
					}
				: null,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (phoneFieldValue) => {
		if (summaryField.value) {
			const { PHONE_NUMBER, PHONE_TYPE } = summaryField.value;
			return (
				phoneFieldValue[PHONE_NUMBER?.id] !== PHONE_NUMBER?.value ||
				(phoneFieldValue[PHONE_TYPE?.id] !== PHONE_TYPE?.value &&
					!!phoneFieldValue[PHONE_TYPE?.id])
			);
		} else {
			return !!phoneFieldValue[phoneNumberField?.id];
		}
	};

	const handleSavePhoneField = (phoneFieldValue, onClose) => {
		if (hasValueOrIsChanged(phoneFieldValue)) {
			const requestData = getPhoneRequestData(phoneFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const getPhoneFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<Tooltip
						open={openToolTip}
						placement="top"
						title={summaryField.value?.PHONE_NUMBER?.value || ""}
					>
						<SummaryFieldName
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{summaryField.value?.PHONE_NUMBER?.value}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Typography
						width="150px"
						sx={{
							"&:hover": {
								borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
							},
						}}
						fontSize="14px"
						fontWeight={500}
						style={{
							color: theme.palette.secondary.contrastText,
							opacity: "0.4",
						}}
					>
						Click to Add
					</Typography>
				)}
			</Box>
		);
	};

	return (
		<>
			<FieldEdit
				field={summaryField}
				fieldLabel={getPhoneFieldLabel}
				styles={fieldEditStyles}
			>
				<PhoneFieldEditForm
					subFields={subFields}
					onSave={handleSavePhoneField}
					value={modifiedPhoneValue}
					field={summaryField}
				/>
			</FieldEdit>
		</>
	);
}
