import React from "react";

export default function Custom(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 32 32"
			style={{ fill: color }}
		>
			<path d="M13 7.333c0-.794.23-1.148.436-1.326.234-.202.628-.341 1.23-.341h8c.602 0 .997.139 1.23.341.206.178.436.532.436 1.326V10c0 .794-.23 1.148-.436 1.326-.234.202-.628.341-1.23.341h-8c-.602 0-.997-.139-1.23-.341C13.23 11.148 13 10.794 13 10V7.333zm-2 .334H2.667a1 1 0 000 2H11V10c0 1.206.37 2.185 1.13 2.841.733.632 1.672.826 2.536.826h8c.865 0 1.803-.194 2.536-.826.761-.656 1.13-1.634 1.13-2.841v-.333h3a1 1 0 000-2h-3v-.333c0-1.206-.37-2.185-1.13-2.841-.733-.632-1.672-.826-2.536-.826h-8c-.865 0-1.803.194-2.536.826-.761.656-1.13 1.634-1.13 2.841v.333zM2.667 22.333a1 1 0 000 2h3v.333c0 1.206.37 2.185 1.13 2.841.733.632 1.672.826 2.536.826h8c.865 0 1.803-.194 2.536-.826.761-.656 1.13-1.634 1.13-2.841v-.333h8.333a1 1 0 000-2h-8.333V22c0-1.206-.37-2.185-1.13-2.841-.733-.632-1.672-.826-2.536-.826h-8c-.865 0-1.803.194-2.536.826-.761.656-1.13 1.634-1.13 2.841v.333h-3zM19 22v2.667c0 .794-.23 1.148-.436 1.326-.234.202-.628.341-1.23.341h-8c-.602 0-.997-.139-1.23-.341-.206-.178-.436-.532-.436-1.326V22c0-.794.23-1.148.436-1.326.234-.202.628-.341 1.23-.341h8c.602 0 .997.139 1.23.341.206.178.436.532.436 1.326z"></path>
		</svg>
	);
}
