const accountTimezones = [
	{
		value: 1,
		name: "(UTC+00:00) Africa/Abidjan",
	},
	{
		value: 2,
		name: "(UTC+00:00) Africa/Accra",
	},
	{
		value: 3,
		name: "(UTC+03:00) Africa/Addis_Ababa",
	},
	{
		value: 4,
		name: "(UTC+01:00) Africa/Algiers",
	},
	{
		value: 5,
		name: "(UTC+03:00) Africa/Asmara",
	},
	{
		value: 6,
		name: "(UTC+03:00) Africa/Asmera",
	},
	{
		value: 7,
		name: "(UTC+00:00) Africa/Bamako",
	},
	{
		value: 8,
		name: "(UTC+01:00) Africa/Bangui",
	},
	{
		value: 9,
		name: "(UTC+00:00) Africa/Banjul",
	},
	{
		value: 10,
		name: "(UTC+00:00) Africa/Bissau",
	},
	{
		value: 11,
		name: "(UTC+02:00) Africa/Blantyre",
	},
	{
		value: 12,
		name: "(UTC+01:00) Africa/Brazzaville",
	},
	{
		value: 13,
		name: "(UTC+02:00) Africa/Bujumbura",
	},
	{
		value: 14,
		name: "(UTC+02:00) Africa/Cairo",
	},
	{
		value: 15,
		name: "(UTC+00:00) Africa/Casablanca",
	},
	{
		value: 16,
		name: "(UTC+01:00) Africa/Ceuta",
	},
	{
		value: 17,
		name: "(UTC+00:00) Africa/Conakry",
	},
	{
		value: 18,
		name: "(UTC+00:00) Africa/Dakar",
	},
	{
		value: 19,
		name: "(UTC+03:00) Africa/Dar_es_Salaam",
	},
	{
		value: 20,
		name: "(UTC+03:00) Africa/Djibouti",
	},
	{
		value: 21,
		name: "(UTC+01:00) Africa/Douala",
	},
	{
		value: 22,
		name: "(UTC+00:00) Africa/El_Aaiun",
	},
	{
		value: 23,
		name: "(UTC+00:00) Africa/Freetown",
	},
	{
		value: 24,
		name: "(UTC+02:00) Africa/Gaborone",
	},
	{
		value: 25,
		name: "(UTC+02:00) Africa/Harare",
	},
	{
		value: 26,
		name: "(UTC+02:00) Africa/Johannesburg",
	},
	{
		value: 27,
		name: "(UTC+02:00) Africa/Juba",
	},
	{
		value: 28,
		name: "(UTC+03:00) Africa/Kampala",
	},
	{
		value: 29,
		name: "(UTC+02:00) Africa/Khartoum",
	},
	{
		value: 30,
		name: "(UTC+02:00) Africa/Kigali",
	},
	{
		value: 31,
		name: "(UTC+01:00) Africa/Kinshasa",
	},
	{
		value: 32,
		name: "(UTC+01:00) Africa/Lagos",
	},
	{
		value: 33,
		name: "(UTC+01:00) Africa/Libreville",
	},
	{
		value: 34,
		name: "(UTC+00:00) Africa/Lome",
	},
	{
		value: 35,
		name: "(UTC+01:00) Africa/Luanda",
	},
	{
		value: 36,
		name: "(UTC+02:00) Africa/Lubumbashi",
	},
	{
		value: 37,
		name: "(UTC+02:00) Africa/Lusaka",
	},
	{
		value: 38,
		name: "(UTC+01:00) Africa/Malabo",
	},
	{
		value: 39,
		name: "(UTC+02:00) Africa/Maputo",
	},
	{
		value: 40,
		name: "(UTC+02:00) Africa/Maseru",
	},
	{
		value: 41,
		name: "(UTC+02:00) Africa/Mbabane",
	},
	{
		value: 42,
		name: "(UTC+03:00) Africa/Mogadishu",
	},
	{
		value: 43,
		name: "(UTC+00:00) Africa/Monrovia",
	},
	{
		value: 44,
		name: "(UTC+03:00) Africa/Nairobi",
	},
	{
		value: 45,
		name: "(UTC+01:00) Africa/Ndjamena",
	},
	{
		value: 46,
		name: "(UTC+01:00) Africa/Niamey",
	},
	{
		value: 47,
		name: "(UTC+00:00) Africa/Nouakchott",
	},
	{
		value: 48,
		name: "(UTC+00:00) Africa/Ouagadougou",
	},
	{
		value: 49,
		name: "(UTC+01:00) Africa/Porto-Novo",
	},
	{
		value: 50,
		name: "(UTC+00:00) Africa/Sao_Tome",
	},
	{
		value: 51,
		name: "(UTC+00:00) Africa/Timbuktu",
	},
	{
		value: 52,
		name: "(UTC+02:00) Africa/Tripoli",
	},
	{
		value: 53,
		name: "(UTC+01:00) Africa/Tunis",
	},
	{
		value: 54,
		name: "(UTC+02:00) Africa/Windhoek",
	},
	{
		value: 55,
		name: "(UTC-09:00) America/Adak",
	},
	{
		value: 56,
		name: "(UTC-09:00) America/Anchorage",
	},
	{
		value: 57,
		name: "(UTC-04:00) America/Anguilla",
	},
	{
		value: 58,
		name: "(UTC-04:00) America/Antigua",
	},
	{
		value: 59,
		name: "(UTC-03:00) America/Araguaina",
	},
	{
		value: 60,
		name: "(UTC-03:00) America/Argentina/Buenos_Aires",
	},
	{
		value: 61,
		name: "(UTC-03:00) America/Argentina/Catamarca",
	},
	{
		value: 62,
		name: "(UTC-03:00) America/Argentina/ComodRivadavia",
	},
	{
		value: 63,
		name: "(UTC-03:00) America/Argentina/Cordoba",
	},
	{
		value: 64,
		name: "(UTC-03:00) America/Argentina/Jujuy",
	},
	{
		value: 65,
		name: "(UTC-03:00) America/Argentina/La_Rioja",
	},
	{
		value: 66,
		name: "(UTC-03:00) America/Argentina/Mendoza",
	},
	{
		value: 67,
		name: "(UTC-03:00) America/Argentina/Rio_Gallegos",
	},
	{
		value: 68,
		name: "(UTC-03:00) America/Argentina/Salta",
	},
	{
		value: 69,
		name: "(UTC-03:00) America/Argentina/San_Juan",
	},
	{
		value: 70,
		name: "(UTC-03:00) America/Argentina/San_Luis",
	},
	{
		value: 71,
		name: "(UTC-03:00) America/Argentina/Tucuman",
	},
	{
		value: 72,
		name: "(UTC-03:00) America/Argentina/Ushuaia",
	},
	{
		value: 73,
		name: "(UTC-04:00) America/Aruba",
	},
	{
		value: 74,
		name: "(UTC-04:00) America/Asuncion",
	},
	{
		value: 75,
		name: "(UTC-05:00) America/Atikokan",
	},
	{
		value: 76,
		name: "(UTC-10:00) America/Atka",
	},
	{
		value: 77,
		name: "(UTC-03:00) America/Bahia",
	},
	{
		value: 78,
		name: "(UTC-06:00) America/Bahia_Banderas",
	},
	{
		value: 79,
		name: "(UTC-04:00) America/Barbados",
	},
	{
		value: 80,
		name: "(UTC-03:00) America/Belem",
	},
	{
		value: 81,
		name: "(UTC-06:00) America/Belize",
	},
	{
		value: 82,
		name: "(UTC-04:00) America/Blanc-Sablon",
	},
	{
		value: 83,
		name: "(UTC-04:00) America/Boa_Vista",
	},
	{
		value: 84,
		name: "(UTC-05:00) America/Bogota",
	},
	{
		value: 85,
		name: "(UTC-07:00) America/Boise",
	},
	{
		value: 86,
		name: "(UTC-03:00) America/Buenos_Aires",
	},
	{
		value: 87,
		name: "(UTC-07:00) America/Cambridge_Bay",
	},
	{
		value: 88,
		name: "(UTC-04:00) America/Campo_Grande",
	},
	{
		value: 89,
		name: "(UTC-05:00) America/Cancun",
	},
	{
		value: 90,
		name: "(UTC-04:00) America/Caracas",
	},
	{
		value: 91,
		name: "(UTC-03:00) America/Catamarca",
	},
	{
		value: 92,
		name: "(UTC-03:00) America/Cayenne",
	},
	{
		value: 93,
		name: "(UTC-05:00) America/Cayman",
	},
	{
		value: 94,
		name: "(UTC-06:00) America/Chicago",
	},
	{
		value: 95,
		name: "(UTC-06:00) America/Chihuahua",
	},
	{
		value: 96,
		name: "(UTC-07:00) America/Ciudad_Juarez",
	},
	{
		value: 97,
		name: "(UTC-05:00) America/Coral_Harbour",
	},
	{
		value: 98,
		name: "(UTC-03:00) America/Cordoba",
	},
	{
		value: 99,
		name: "(UTC-06:00) America/Costa_Rica",
	},
	{
		value: 100,
		name: "(UTC-07:00) America/Creston",
	},
	{
		value: 101,
		name: "(UTC-04:00) America/Cuiaba",
	},
	{
		value: 102,
		name: "(UTC-04:00) America/Curacao",
	},
	{
		value: 103,
		name: "(UTC+00:00) America/Danmarkshavn",
	},
	{
		value: 104,
		name: "(UTC-07:00) America/Dawson",
	},
	{
		value: 105,
		name: "(UTC-07:00) America/Dawson_Creek",
	},
	{
		value: 106,
		name: "(UTC-07:00) America/Denver",
	},
	{
		value: 107,
		name: "(UTC-05:00) America/Detroit",
	},
	{
		value: 108,
		name: "(UTC-04:00) America/Dominica",
	},
	{
		value: 109,
		name: "(UTC-07:00) America/Edmonton",
	},
	{
		value: 110,
		name: "(UTC-05:00) America/Eirunepe",
	},
	{
		value: 111,
		name: "(UTC-06:00) America/El_Salvador",
	},
	{
		value: 112,
		name: "(UTC-08:00) America/Ensenada",
	},
	{
		value: 113,
		name: "(UTC-07:00) America/Fort_Nelson",
	},
	{
		value: 114,
		name: "(UTC-05:00) America/Fort_Wayne",
	},
	{
		value: 115,
		name: "(UTC-03:00) America/Fortaleza",
	},
	{
		value: 116,
		name: "(UTC-04:00) America/Glace_Bay",
	},
	{
		value: 117,
		name: "(UTC-02:00) America/Godthab",
	},
	{
		value: 118,
		name: "(UTC-04:00) America/Goose_Bay",
	},
	{
		value: 119,
		name: "(UTC-05:00) America/Grand_Turk",
	},
	{
		value: 120,
		name: "(UTC-04:00) America/Grenada",
	},
	{
		value: 121,
		name: "(UTC-04:00) America/Guadeloupe",
	},
	{
		value: 122,
		name: "(UTC-06:00) America/Guatemala",
	},
	{
		value: 123,
		name: "(UTC-05:00) America/Guayaquil",
	},
	{
		value: 124,
		name: "(UTC-04:00) America/Guyana",
	},
	{
		value: 125,
		name: "(UTC-04:00) America/Halifax",
	},
	{
		value: 126,
		name: "(UTC-05:00) America/Havana",
	},
	{
		value: 127,
		name: "(UTC-07:00) America/Hermosillo",
	},
	{
		value: 128,
		name: "(UTC-05:00) America/Indiana/Indianapolis",
	},
	{
		value: 129,
		name: "(UTC-06:00) America/Indiana/Knox",
	},
	{
		value: 130,
		name: "(UTC-05:00) America/Indiana/Marengo",
	},
	{
		value: 131,
		name: "(UTC-05:00) America/Indiana/Petersburg",
	},
	{
		value: 132,
		name: "(UTC-06:00) America/Indiana/Tell_City",
	},
	{
		value: 133,
		name: "(UTC-05:00) America/Indiana/Vevay",
	},
	{
		value: 134,
		name: "(UTC-05:00) America/Indiana/Vincennes",
	},
	{
		value: 135,
		name: "(UTC-05:00) America/Indiana/Winamac",
	},
	{
		value: 136,
		name: "(UTC-05:00) America/Indianapolis",
	},
	{
		value: 137,
		name: "(UTC-07:00) America/Inuvik",
	},
	{
		value: 138,
		name: "(UTC-05:00) America/Iqaluit",
	},
	{
		value: 139,
		name: "(UTC-05:00) America/Jamaica",
	},
	{
		value: 140,
		name: "(UTC-03:00) America/Jujuy",
	},
	{
		value: 141,
		name: "(UTC-09:00) America/Juneau",
	},
	{
		value: 142,
		name: "(UTC-05:00) America/Kentucky/Louisville",
	},
	{
		value: 143,
		name: "(UTC-05:00) America/Kentucky/Monticello",
	},
	{
		value: 144,
		name: "(UTC-06:00) America/Knox_IN",
	},
	{
		value: 145,
		name: "(UTC-04:00) America/Kralendijk",
	},
	{
		value: 146,
		name: "(UTC-04:00) America/La_Paz",
	},
	{
		value: 147,
		name: "(UTC-05:00) America/Lima",
	},
	{
		value: 148,
		name: "(UTC-08:00) America/Los_Angeles",
	},
	{
		value: 149,
		name: "(UTC-05:00) America/Louisville",
	},
	{
		value: 150,
		name: "(UTC-04:00) America/Lower_Princes",
	},
	{
		value: 151,
		name: "(UTC-03:00) America/Maceio",
	},
	{
		value: 152,
		name: "(UTC-06:00) America/Managua",
	},
	{
		value: 153,
		name: "(UTC-04:00) America/Manaus",
	},
	{
		value: 154,
		name: "(UTC-04:00) America/Marigot",
	},
	{
		value: 155,
		name: "(UTC-04:00) America/Martinique",
	},
	{
		value: 156,
		name: "(UTC-06:00) America/Matamoros",
	},
	{
		value: 157,
		name: "(UTC-07:00) America/Mazatlan",
	},
	{
		value: 158,
		name: "(UTC-03:00) America/Mendoza",
	},
	{
		value: 159,
		name: "(UTC-06:00) America/Menominee",
	},
	{
		value: 160,
		name: "(UTC-06:00) America/Merida",
	},
	{
		value: 161,
		name: "(UTC-09:00) America/Metlakatla",
	},
	{
		value: 162,
		name: "(UTC-06:00) America/Mexico_City",
	},
	{
		value: 163,
		name: "(UTC-03:00) America/Miquelon",
	},
	{
		value: 164,
		name: "(UTC-04:00) America/Moncton",
	},
	{
		value: 165,
		name: "(UTC-06:00) America/Monterrey",
	},
	{
		value: 166,
		name: "(UTC-03:00) America/Montevideo",
	},
	{
		value: 167,
		name: "(UTC-05:00) America/Montreal",
	},
	{
		value: 168,
		name: "(UTC-04:00) America/Montserrat",
	},
	{
		value: 169,
		name: "(UTC-05:00) America/Nassau",
	},
	{
		value: 170,
		name: "(UTC-05:00) America/New_York",
	},
	{
		value: 171,
		name: "(UTC-05:00) America/Nipigon",
	},
	{
		value: 172,
		name: "(UTC-09:00) America/Nome",
	},
	{
		value: 173,
		name: "(UTC-02:00) America/Noronha",
	},
	{
		value: 174,
		name: "(UTC-06:00) America/North_Dakota/Beulah",
	},
	{
		value: 175,
		name: "(UTC-06:00) America/North_Dakota/Center",
	},
	{
		value: 176,
		name: "(UTC-06:00) America/North_Dakota/New_Salem",
	},
	{
		value: 177,
		name: "(UTC-02:00) America/Nuuk",
	},
	{
		value: 178,
		name: "(UTC-06:00) America/Ojinaga",
	},
	{
		value: 179,
		name: "(UTC-05:00) America/Panama",
	},
	{
		value: 180,
		name: "(UTC-05:00) America/Pangnirtung",
	},
	{
		value: 181,
		name: "(UTC-03:00) America/Paramaribo",
	},
	{
		value: 182,
		name: "(UTC-07:00) America/Phoenix",
	},
	{
		value: 183,
		name: "(UTC-05:00) America/Port-au-Prince",
	},
	{
		value: 184,
		name: "(UTC-04:00) America/Port_of_Spain",
	},
	{
		value: 185,
		name: "(UTC-05:00) America/Porto_Acre",
	},
	{
		value: 186,
		name: "(UTC-04:00) America/Porto_Velho",
	},
	{
		value: 187,
		name: "(UTC-04:00) America/Puerto_Rico",
	},
	{
		value: 188,
		name: "(UTC-03:00) America/Punta_Arenas",
	},
	{
		value: 189,
		name: "(UTC-06:00) America/Rainy_River",
	},
	{
		value: 190,
		name: "(UTC-06:00) America/Rankin_Inlet",
	},
	{
		value: 191,
		name: "(UTC-03:00) America/Recife",
	},
	{
		value: 192,
		name: "(UTC-06:00) America/Regina",
	},
	{
		value: 193,
		name: "(UTC-06:00) America/Resolute",
	},
	{
		value: 194,
		name: "(UTC-05:00) America/Rio_Branco",
	},
	{
		value: 195,
		name: "(UTC-03:00) America/Rosario",
	},
	{
		value: 196,
		name: "(UTC-08:00) America/Santa_Isabel",
	},
	{
		value: 197,
		name: "(UTC-03:00) America/Santarem",
	},
	{
		value: 198,
		name: "(UTC-04:00) America/Santiago",
	},
	{
		value: 199,
		name: "(UTC-04:00) America/Santo_Domingo",
	},
	{
		value: 200,
		name: "(UTC-03:00) America/Sao_Paulo",
	},
	{
		value: 201,
		name: "(UTC-01:00) America/Scoresbysund",
	},
	{
		value: 202,
		name: "(UTC-07:00) America/Shiprock",
	},
	{
		value: 203,
		name: "(UTC-09:00) America/Sitka",
	},
	{
		value: 204,
		name: "(UTC-04:00) America/St_Barthelemy",
	},
	{
		value: 205,
		name: "(UTC-03:30) America/St_Johns",
	},
	{
		value: 206,
		name: "(UTC-04:00) America/St_Kitts",
	},
	{
		value: 207,
		name: "(UTC-04:00) America/St_Lucia",
	},
	{
		value: 208,
		name: "(UTC-04:00) America/St_Thomas",
	},
	{
		value: 209,
		name: "(UTC-04:00) America/St_Vincent",
	},
	{
		value: 210,
		name: "(UTC-06:00) America/Swift_Current",
	},
	{
		value: 211,
		name: "(UTC-06:00) America/Tegucigalpa",
	},
	{
		value: 212,
		name: "(UTC-04:00) America/Thule",
	},
	{
		value: 213,
		name: "(UTC-05:00) America/Thunder_Bay",
	},
	{
		value: 214,
		name: "(UTC-08:00) America/Tijuana",
	},
	{
		value: 215,
		name: "(UTC-05:00) America/Toronto",
	},
	{
		value: 216,
		name: "(UTC-04:00) America/Tortola",
	},
	{
		value: 217,
		name: "(UTC-08:00) America/Vancouver",
	},
	{
		value: 218,
		name: "(UTC-04:00) America/Virgin",
	},
	{
		value: 219,
		name: "(UTC-07:00) America/Whitehorse",
	},
	{
		value: 220,
		name: "(UTC-06:00) America/Winnipeg",
	},
	{
		value: 221,
		name: "(UTC-09:00) America/Yakutat",
	},
	{
		value: 222,
		name: "(UTC-07:00) America/Yellowknife",
	},
	{
		value: 223,
		name: "(UTC+11:00) Antarctica/Casey",
	},
	{
		value: 224,
		name: "(UTC+07:00) Antarctica/Davis",
	},
	{
		value: 225,
		name: "(UTC+10:00) Antarctica/DumontDUrville",
	},
	{
		value: 226,
		name: "(UTC+10:00) Antarctica/Macquarie",
	},
	{
		value: 227,
		name: "(UTC+05:00) Antarctica/Mawson",
	},
	{
		value: 228,
		name: "(UTC+12:00) Antarctica/McMurdo",
	},
	{
		value: 229,
		name: "(UTC-03:00) Antarctica/Palmer",
	},
	{
		value: 230,
		name: "(UTC-03:00) Antarctica/Rothera",
	},
	{
		value: 231,
		name: "(UTC+12:00) Antarctica/South_Pole",
	},
	{
		value: 232,
		name: "(UTC+03:00) Antarctica/Syowa",
	},
	{
		value: 233,
		name: "(UTC+00:00) Antarctica/Troll",
	},
	{
		value: 234,
		name: "(UTC+06:00) Antarctica/Vostok",
	},
	{
		value: 235,
		name: "(UTC+01:00) Arctic/Longyearbyen",
	},
	{
		value: 236,
		name: "(UTC+03:00) Asia/Aden",
	},
	{
		value: 237,
		name: "(UTC+06:00) Asia/Almaty",
	},
	{
		value: 238,
		name: "(UTC+03:00) Asia/Amman",
	},
	{
		value: 239,
		name: "(UTC+12:00) Asia/Anadyr",
	},
	{
		value: 240,
		name: "(UTC+05:00) Asia/Aqtau",
	},
	{
		value: 241,
		name: "(UTC+05:00) Asia/Aqtobe",
	},
	{
		value: 242,
		name: "(UTC+05:00) Asia/Ashgabat",
	},
	{
		value: 243,
		name: "(UTC+05:00) Asia/Ashkhabad",
	},
	{
		value: 244,
		name: "(UTC+05:00) Asia/Atyrau",
	},
	{
		value: 245,
		name: "(UTC+03:00) Asia/Baghdad",
	},
	{
		value: 246,
		name: "(UTC+03:00) Asia/Bahrain",
	},
	{
		value: 247,
		name: "(UTC+04:00) Asia/Baku",
	},
	{
		value: 248,
		name: "(UTC+07:00) Asia/Bangkok",
	},
	{
		value: 249,
		name: "(UTC+07:00) Asia/Barnaul",
	},
	{
		value: 250,
		name: "(UTC+02:00) Asia/Beirut",
	},
	{
		value: 251,
		name: "(UTC+06:00) Asia/Bishkek",
	},
	{
		value: 252,
		name: "(UTC+08:00) Asia/Brunei",
	},
	{
		value: 253,
		name: "(UTC+05:30) Asia/Calcutta",
	},
	{
		value: 254,
		name: "(UTC+09:00) Asia/Chita",
	},
	{
		value: 255,
		name: "(UTC+08:00) Asia/Choibalsan",
	},
	{
		value: 256,
		name: "(UTC+08:00) Asia/Chongqing",
	},
	{
		value: 257,
		name: "(UTC+08:00) Asia/Chungking",
	},
	{
		value: 258,
		name: "(UTC+05:30) Asia/Colombo",
	},
	{
		value: 259,
		name: "(UTC+06:00) Asia/Dacca",
	},
	{
		value: 260,
		name: "(UTC+03:00) Asia/Damascus",
	},
	{
		value: 261,
		name: "(UTC+06:00) Asia/Dhaka",
	},
	{
		value: 262,
		name: "(UTC+09:00) Asia/Dili",
	},
	{
		value: 263,
		name: "(UTC+04:00) Asia/Dubai",
	},
	{
		value: 264,
		name: "(UTC+05:00) Asia/Dushanbe",
	},
	{
		value: 265,
		name: "(UTC+02:00) Asia/Famagusta",
	},
	{
		value: 266,
		name: "(UTC+02:00) Asia/Gaza",
	},
	{
		value: 267,
		name: "(UTC+08:00) Asia/Harbin",
	},
	{
		value: 268,
		name: "(UTC+02:00) Asia/Hebron",
	},
	{
		value: 269,
		name: "(UTC+07:00) Asia/Ho_Chi_Minh",
	},
	{
		value: 270,
		name: "(UTC+08:00) Asia/Hong_Kong",
	},
	{
		value: 271,
		name: "(UTC+07:00) Asia/Hovd",
	},
	{
		value: 272,
		name: "(UTC+08:00) Asia/Irkutsk",
	},
	{
		value: 273,
		name: "(UTC+03:00) Asia/Istanbul",
	},
	{
		value: 274,
		name: "(UTC+07:00) Asia/Jakarta",
	},
	{
		value: 275,
		name: "(UTC+09:00) Asia/Jayapura",
	},
	{
		value: 276,
		name: "(UTC+02:00) Asia/Jerusalem",
	},
	{
		value: 277,
		name: "(UTC+04:30) Asia/Kabul",
	},
	{
		value: 278,
		name: "(UTC+12:00) Asia/Kamchatka",
	},
	{
		value: 279,
		name: "(UTC+05:00) Asia/Karachi",
	},
	{
		value: 280,
		name: "(UTC+06:00) Asia/Kashgar",
	},
	{
		value: 281,
		name: "(UTC+05:45) Asia/Kathmandu",
	},
	{
		value: 282,
		name: "(UTC+05:45) Asia/Katmandu",
	},
	{
		value: 283,
		name: "(UTC+09:00) Asia/Khandyga",
	},
	{
		value: 284,
		name: "(UTC+05:30) Asia/Kolkata",
	},
	{
		value: 285,
		name: "(UTC+07:00) Asia/Krasnoyarsk",
	},
	{
		value: 286,
		name: "(UTC+08:00) Asia/Kuala_Lumpur",
	},
	{
		value: 287,
		name: "(UTC+08:00) Asia/Kuching",
	},
	{
		value: 288,
		name: "(UTC+03:00) Asia/Kuwait",
	},
	{
		value: 289,
		name: "(UTC+08:00) Asia/Macao",
	},
	{
		value: 290,
		name: "(UTC+08:00) Asia/Macau",
	},
	{
		value: 291,
		name: "(UTC+11:00) Asia/Magadan",
	},
	{
		value: 292,
		name: "(UTC+08:00) Asia/Makassar",
	},
	{
		value: 293,
		name: "(UTC+08:00) Asia/Manila",
	},
	{
		value: 294,
		name: "(UTC+04:00) Asia/Muscat",
	},
	{
		value: 295,
		name: "(UTC+02:00) Asia/Nicosia",
	},
	{
		value: 296,
		name: "(UTC+07:00) Asia/Novokuznetsk",
	},
	{
		value: 297,
		name: "(UTC+07:00) Asia/Novosibirsk",
	},
	{
		value: 298,
		name: "(UTC+06:00) Asia/Omsk",
	},
	{
		value: 299,
		name: "(UTC+05:00) Asia/Oral",
	},
	{
		value: 300,
		name: "(UTC+07:00) Asia/Phnom_Penh",
	},
	{
		value: 301,
		name: "(UTC+07:00) Asia/Pontianak",
	},
	{
		value: 302,
		name: "(UTC+09:00) Asia/Pyongyang",
	},
	{
		value: 303,
		name: "(UTC+03:00) Asia/Qatar",
	},
	{
		value: 304,
		name: "(UTC+06:00) Asia/Qostanay",
	},
	{
		value: 305,
		name: "(UTC+05:00) Asia/Qyzylorda",
	},
	{
		value: 306,
		name: "(UTC+06:30) Asia/Rangoon",
	},
	{
		value: 307,
		name: "(UTC+03:00) Asia/Riyadh",
	},
	{
		value: 308,
		name: "(UTC+07:00) Asia/Saigon",
	},
	{
		value: 309,
		name: "(UTC+11:00) Asia/Sakhalin",
	},
	{
		value: 310,
		name: "(UTC+05:00) Asia/Samarkand",
	},
	{
		value: 311,
		name: "(UTC+09:00) Asia/Seoul",
	},
	{
		value: 312,
		name: "(UTC+08:00) Asia/Shanghai",
	},
	{
		value: 313,
		name: "(UTC+08:00) Asia/Singapore",
	},
	{
		value: 314,
		name: "(UTC+11:00) Asia/Srednekolymsk",
	},
	{
		value: 315,
		name: "(UTC+08:00) Asia/Taipei",
	},
	{
		value: 316,
		name: "(UTC+05:00) Asia/Tashkent",
	},
	{
		value: 317,
		name: "(UTC+04:00) Asia/Tbilisi",
	},
	{
		value: 318,
		name: "(UTC+03:30) Asia/Tehran",
	},
	{
		value: 319,
		name: "(UTC+02:00) Asia/Tel_Aviv",
	},
	{
		value: 320,
		name: "(UTC+06:00) Asia/Thimbu",
	},
	{
		value: 321,
		name: "(UTC+06:00) Asia/Thimphu",
	},
	{
		value: 322,
		name: "(UTC+09:00) Asia/Tokyo",
	},
	{
		value: 323,
		name: "(UTC+07:00) Asia/Tomsk",
	},
	{
		value: 324,
		name: "(UTC+08:00) Asia/Ujung_Pandang",
	},
	{
		value: 325,
		name: "(UTC+08:00) Asia/Ulaanbaatar",
	},
	{
		value: 326,
		name: "(UTC+08:00) Asia/Ulan_Bator",
	},
	{
		value: 327,
		name: "(UTC+06:00) Asia/Urumqi",
	},
	{
		value: 328,
		name: "(UTC+10:00) Asia/Ust-Nera",
	},
	{
		value: 329,
		name: "(UTC+07:00) Asia/Vientiane",
	},
	{
		value: 330,
		name: "(UTC+10:00) Asia/Vladivostok",
	},
	{
		value: 331,
		name: "(UTC+09:00) Asia/Yakutsk",
	},
	{
		value: 332,
		name: "(UTC+06:30) Asia/Yangon",
	},
	{
		value: 333,
		name: "(UTC+05:00) Asia/Yekaterinburg",
	},
	{
		value: 334,
		name: "(UTC+04:00) Asia/Yerevan",
	},
	{
		value: 335,
		name: "(UTC-01:00) Atlantic/Azores",
	},
	{
		value: 336,
		name: "(UTC-04:00) Atlantic/Bermuda",
	},
	{
		value: 337,
		name: "(UTC+00:00) Atlantic/Canary",
	},
	{
		value: 338,
		name: "(UTC-01:00) Atlantic/Cape_Verde",
	},
	{
		value: 339,
		name: "(UTC+00:00) Atlantic/Faeroe",
	},
	{
		value: 340,
		name: "(UTC+00:00) Atlantic/Faroe",
	},
	{
		value: 341,
		name: "(UTC+01:00) Atlantic/Jan_Mayen",
	},
	{
		value: 342,
		name: "(UTC+00:00) Atlantic/Madeira",
	},
	{
		value: 343,
		name: "(UTC+00:00) Atlantic/Reykjavik",
	},
	{
		value: 344,
		name: "(UTC-02:00) Atlantic/South_Georgia",
	},
	{
		value: 345,
		name: "(UTC+00:00) Atlantic/St_Helena",
	},
	{
		value: 346,
		name: "(UTC-03:00) Atlantic/Stanley",
	},
	{
		value: 347,
		name: "(UTC+10:00) Australia/ACT",
	},
	{
		value: 348,
		name: "(UTC+09:30) Australia/Adelaide",
	},
	{
		value: 349,
		name: "(UTC+10:00) Australia/Brisbane",
	},
	{
		value: 350,
		name: "(UTC+09:30) Australia/Broken_Hill",
	},
	{
		value: 351,
		name: "(UTC+10:00) Australia/Canberra",
	},
	{
		value: 352,
		name: "(UTC+10:00) Australia/Currie",
	},
	{
		value: 353,
		name: "(UTC+09:30) Australia/Darwin",
	},
	{
		value: 354,
		name: "(UTC+08:45) Australia/Eucla",
	},
	{
		value: 355,
		name: "(UTC+10:00) Australia/Hobart",
	},
	{
		value: 356,
		name: "(UTC+10:30) Australia/LHI",
	},
	{
		value: 357,
		name: "(UTC+10:00) Australia/Lindeman",
	},
	{
		value: 358,
		name: "(UTC+10:30) Australia/Lord_Howe",
	},
	{
		value: 359,
		name: "(UTC+10:00) Australia/Melbourne",
	},
	{
		value: 360,
		name: "(UTC+10:00) Australia/NSW",
	},
	{
		value: 361,
		name: "(UTC+09:30) Australia/North",
	},
	{
		value: 362,
		name: "(UTC+08:00) Australia/Perth",
	},
	{
		value: 363,
		name: "(UTC+10:00) Australia/Queensland",
	},
	{
		value: 364,
		name: "(UTC+09:30) Australia/South",
	},
	{
		value: 365,
		name: "(UTC+10:00) Australia/Sydney",
	},
	{
		value: 366,
		name: "(UTC+10:00) Australia/Tasmania",
	},
	{
		value: 367,
		name: "(UTC+10:00) Australia/Victoria",
	},
	{
		value: 368,
		name: "(UTC+08:00) Australia/West",
	},
	{
		value: 369,
		name: "(UTC+09:30) Australia/Yancowinna",
	},
	{
		value: 370,
		name: "(UTC-05:00) Brazil/Acre",
	},
	{
		value: 371,
		name: "(UTC-02:00) Brazil/DeNoronha",
	},
	{
		value: 372,
		name: "(UTC-03:00) Brazil/East",
	},
	{
		value: 373,
		name: "(UTC-04:00) Brazil/West",
	},
	{
		value: 374,
		name: "(UTC+01:00) CET",
	},
	{
		value: 375,
		name: "(UTC-06:00) CST6CDT",
	},
	{
		value: 376,
		name: "(UTC-04:00) Canada/Atlantic",
	},
	{
		value: 377,
		name: "(UTC-06:00) Canada/Central",
	},
	{
		value: 378,
		name: "(UTC-05:00) Canada/Eastern",
	},
	{
		value: 379,
		name: "(UTC-07:00) Canada/Mountain",
	},
	{
		value: 380,
		name: "(UTC-03:30) Canada/Newfoundland",
	},
	{
		value: 381,
		name: "(UTC-08:00) Canada/Pacific",
	},
	{
		value: 382,
		name: "(UTC-06:00) Canada/Saskatchewan",
	},
	{
		value: 383,
		name: "(UTC-07:00) Canada/Yukon",
	},
	{
		value: 384,
		name: "(UTC-04:00) Chile/Continental",
	},
	{
		value: 385,
		name: "(UTC-06:00) Chile/EasterIsland",
	},
	{
		value: 386,
		name: "(UTC-05:00) Cuba",
	},
	{
		value: 387,
		name: "(UTC+02:00) EET",
	},
	{
		value: 388,
		name: "(UTC-05:00) EST5EDT",
	},
	{
		value: 389,
		name: "(UTC+02:00) Egypt",
	},
	{
		value: 390,
		name: "(UTC+00:00) Eire",
	},
	{
		value: 391,
		name: "(UTC+00:00) Etc/GMT",
	},
	{
		value: 392,
		name: "(UTC+00:00) Etc/GMT+0",
	},
	{
		value: 393,
		name: "(UTC-01:00) Etc/GMT+1",
	},
	{
		value: 394,
		name: "(UTC-10:00) Etc/GMT+10",
	},
	{
		value: 395,
		name: "(UTC-11:00) Etc/GMT+11",
	},
	{
		value: 396,
		name: "(UTC-12:00) Etc/GMT+12",
	},
	{
		value: 397,
		name: "(UTC-02:00) Etc/GMT+2",
	},
	{
		value: 398,
		name: "(UTC-03:00) Etc/GMT+3",
	},
	{
		value: 399,
		name: "(UTC-04:00) Etc/GMT+4",
	},
	{
		value: 400,
		name: "(UTC-05:00) Etc/GMT+5",
	},
	{
		value: 401,
		name: "(UTC-06:00) Etc/GMT+6",
	},
	{
		value: 402,
		name: "(UTC-07:00) Etc/GMT+7",
	},
	{
		value: 403,
		name: "(UTC-08:00) Etc/GMT+8",
	},
	{
		value: 404,
		name: "(UTC-09:00) Etc/GMT+9",
	},
	{
		value: 405,
		name: "(UTC+00:00) Etc/GMT-0",
	},
	{
		value: 406,
		name: "(UTC+01:00) Etc/GMT-1",
	},
	{
		value: 407,
		name: "(UTC+10:00) Etc/GMT-10",
	},
	{
		value: 408,
		name: "(UTC+11:00) Etc/GMT-11",
	},
	{
		value: 409,
		name: "(UTC+12:00) Etc/GMT-12",
	},
	{
		value: 410,
		name: "(UTC+13:00) Etc/GMT-13",
	},
	{
		value: 411,
		name: "(UTC+14:00) Etc/GMT-14",
	},
	{
		value: 412,
		name: "(UTC+02:00) Etc/GMT-2",
	},
	{
		value: 413,
		name: "(UTC+03:00) Etc/GMT-3",
	},
	{
		value: 414,
		name: "(UTC+04:00) Etc/GMT-4",
	},
	{
		value: 415,
		name: "(UTC+05:00) Etc/GMT-5",
	},
	{
		value: 416,
		name: "(UTC+06:00) Etc/GMT-6",
	},
	{
		value: 417,
		name: "(UTC+07:00) Etc/GMT-7",
	},
	{
		value: 418,
		name: "(UTC+08:00) Etc/GMT-8",
	},
	{
		value: 419,
		name: "(UTC+09:00) Etc/GMT-9",
	},
	{
		value: 420,
		name: "(UTC+00:00) Etc/GMT0",
	},
	{
		value: 421,
		name: "(UTC+00:00) Etc/Greenwich",
	},
	{
		value: 422,
		name: "(UTC+00:00) Etc/UCT",
	},
	{
		value: 423,
		name: "(UTC+00:00) Etc/UTC",
	},
	{
		value: 424,
		name: "(UTC+00:00) Etc/Universal",
	},
	{
		value: 425,
		name: "(UTC+00:00) Etc/Zulu",
	},
	{
		value: 426,
		name: "(UTC+01:00) Europe/Amsterdam",
	},
	{
		value: 427,
		name: "(UTC+01:00) Europe/Andorra",
	},
	{
		value: 428,
		name: "(UTC+04:00) Europe/Astrakhan",
	},
	{
		value: 429,
		name: "(UTC+02:00) Europe/Athens",
	},
	{
		value: 430,
		name: "(UTC+00:00) Europe/Belfast",
	},
	{
		value: 431,
		name: "(UTC+01:00) Europe/Belgrade",
	},
	{
		value: 432,
		name: "(UTC+01:00) Europe/Berlin",
	},
	{
		value: 433,
		name: "(UTC+01:00) Europe/Bratislava",
	},
	{
		value: 434,
		name: "(UTC+01:00) Europe/Brussels",
	},
	{
		value: 435,
		name: "(UTC+02:00) Europe/Bucharest",
	},
	{
		value: 436,
		name: "(UTC+01:00) Europe/Budapest",
	},
	{
		value: 437,
		name: "(UTC+01:00) Europe/Busingen",
	},
	{
		value: 438,
		name: "(UTC+02:00) Europe/Chisinau",
	},
	{
		value: 439,
		name: "(UTC+01:00) Europe/Copenhagen",
	},
	{
		value: 440,
		name: "(UTC+00:00) Europe/Dublin",
	},
	{
		value: 441,
		name: "(UTC+01:00) Europe/Gibraltar",
	},
	{
		value: 442,
		name: "(UTC+00:00) Europe/Guernsey",
	},
	{
		value: 443,
		name: "(UTC+02:00) Europe/Helsinki",
	},
	{
		value: 444,
		name: "(UTC+00:00) Europe/Isle_of_Man",
	},
	{
		value: 445,
		name: "(UTC+03:00) Europe/Istanbul",
	},
	{
		value: 446,
		name: "(UTC+00:00) Europe/Jersey",
	},
	{
		value: 447,
		name: "(UTC+02:00) Europe/Kaliningrad",
	},
	{
		value: 448,
		name: "(UTC+02:00) Europe/Kiev",
	},
	{
		value: 449,
		name: "(UTC+03:00) Europe/Kirov",
	},
	{
		value: 450,
		name: "(UTC+02:00) Europe/Kyiv",
	},
	{
		value: 451,
		name: "(UTC+00:00) Europe/Lisbon",
	},
	{
		value: 452,
		name: "(UTC+01:00) Europe/Ljubljana",
	},
	{
		value: 453,
		name: "(UTC+00:00) Europe/London",
	},
	{
		value: 454,
		name: "(UTC+01:00) Europe/Luxembourg",
	},
	{
		value: 455,
		name: "(UTC+01:00) Europe/Madrid",
	},
	{
		value: 456,
		name: "(UTC+01:00) Europe/Malta",
	},
	{
		value: 457,
		name: "(UTC+02:00) Europe/Mariehamn",
	},
	{
		value: 458,
		name: "(UTC+03:00) Europe/Minsk",
	},
	{
		value: 459,
		name: "(UTC+01:00) Europe/Monaco",
	},
	{
		value: 460,
		name: "(UTC+03:00) Europe/Moscow",
	},
	{
		value: 461,
		name: "(UTC+02:00) Europe/Nicosia",
	},
	{
		value: 462,
		name: "(UTC+01:00) Europe/Oslo",
	},
	{
		value: 463,
		name: "(UTC+01:00) Europe/Paris",
	},
	{
		value: 464,
		name: "(UTC+01:00) Europe/Podgorica",
	},
	{
		value: 465,
		name: "(UTC+01:00) Europe/Prague",
	},
	{
		value: 466,
		name: "(UTC+02:00) Europe/Riga",
	},
	{
		value: 467,
		name: "(UTC+01:00) Europe/Rome",
	},
	{
		value: 468,
		name: "(UTC+04:00) Europe/Samara",
	},
	{
		value: 469,
		name: "(UTC+01:00) Europe/San_Marino",
	},
	{
		value: 470,
		name: "(UTC+01:00) Europe/Sarajevo",
	},
	{
		value: 471,
		name: "(UTC+04:00) Europe/Saratov",
	},
	{
		value: 472,
		name: "(UTC+03:00) Europe/Simferopol",
	},
	{
		value: 473,
		name: "(UTC+01:00) Europe/Skopje",
	},
	{
		value: 474,
		name: "(UTC+02:00) Europe/Sofia",
	},
	{
		value: 475,
		name: "(UTC+01:00) Europe/Stockholm",
	},
	{
		value: 476,
		name: "(UTC+02:00) Europe/Tallinn",
	},
	{
		value: 477,
		name: "(UTC+01:00) Europe/Tirane",
	},
	{
		value: 478,
		name: "(UTC+02:00) Europe/Tiraspol",
	},
	{
		value: 479,
		name: "(UTC+04:00) Europe/Ulyanovsk",
	},
	{
		value: 480,
		name: "(UTC+02:00) Europe/Uzhgorod",
	},
	{
		value: 481,
		name: "(UTC+01:00) Europe/Vaduz",
	},
	{
		value: 482,
		name: "(UTC+01:00) Europe/Vatican",
	},
	{
		value: 483,
		name: "(UTC+01:00) Europe/Vienna",
	},
	{
		value: 484,
		name: "(UTC+02:00) Europe/Vilnius",
	},
	{
		value: 485,
		name: "(UTC+03:00) Europe/Volgograd",
	},
	{
		value: 486,
		name: "(UTC+01:00) Europe/Warsaw",
	},
	{
		value: 487,
		name: "(UTC+01:00) Europe/Zagreb",
	},
	{
		value: 488,
		name: "(UTC+02:00) Europe/Zaporozhye",
	},
	{
		value: 489,
		name: "(UTC+01:00) Europe/Zurich",
	},
	{
		value: 490,
		name: "(UTC+00:00) GB",
	},
	{
		value: 491,
		name: "(UTC+00:00) GB-Eire",
	},
	{
		value: 492,
		name: "(UTC+00:00) GMT",
	},
	{
		value: 493,
		name: "(UTC+00:00) GMT0",
	},
	{
		value: 494,
		name: "(UTC+00:00) Greenwich",
	},
	{
		value: 495,
		name: "(UTC+08:00) Hongkong",
	},
	{
		value: 496,
		name: "(UTC+00:00) Iceland",
	},
	{
		value: 497,
		name: "(UTC+03:00) Indian/Antananarivo",
	},
	{
		value: 498,
		name: "(UTC+06:00) Indian/Chagos",
	},
	{
		value: 499,
		name: "(UTC+07:00) Indian/Christmas",
	},
	{
		value: 500,
		name: "(UTC+06:30) Indian/Cocos",
	},
	{
		value: 501,
		name: "(UTC+03:00) Indian/Comoro",
	},
	{
		value: 502,
		name: "(UTC+05:00) Indian/Kerguelen",
	},
	{
		value: 503,
		name: "(UTC+04:00) Indian/Mahe",
	},
	{
		value: 504,
		name: "(UTC+05:00) Indian/Maldives",
	},
	{
		value: 505,
		name: "(UTC+04:00) Indian/Mauritius",
	},
	{
		value: 506,
		name: "(UTC+03:00) Indian/Mayotte",
	},
	{
		value: 507,
		name: "(UTC+04:00) Indian/Reunion",
	},
	{
		value: 508,
		name: "(UTC+03:30) Iran",
	},
	{
		value: 509,
		name: "(UTC+02:00) Israel",
	},
	{
		value: 510,
		name: "(UTC-05:00) Jamaica",
	},
	{
		value: 511,
		name: "(UTC+09:00) Japan",
	},
	{
		value: 512,
		name: "(UTC+12:00) Kwajalein",
	},
	{
		value: 513,
		name: "(UTC+02:00) Libya",
	},
	{
		value: 514,
		name: "(UTC+01:00) MET",
	},
	{
		value: 515,
		name: "(UTC-07:00) MST7MDT",
	},
	{
		value: 516,
		name: "(UTC-08:00) Mexico/BajaNorte",
	},
	{
		value: 517,
		name: "(UTC-07:00) Mexico/BajaSur",
	},
	{
		value: 518,
		name: "(UTC-06:00) Mexico/General",
	},
	{
		value: 519,
		name: "(UTC+12:00) NZ",
	},
	{
		value: 520,
		name: "(UTC+12:45) NZ-CHAT",
	},
	{
		value: 521,
		name: "(UTC-07:00) Navajo",
	},
	{
		value: 522,
		name: "(UTC+08:00) PRC",
	},
	{
		value: 523,
		name: "(UTC-08:00) PST8PDT",
	},
	{
		value: 524,
		name: "(UTC+13:00) Pacific/Apia",
	},
	{
		value: 525,
		name: "(UTC+12:00) Pacific/Auckland",
	},
	{
		value: 526,
		name: "(UTC+11:00) Pacific/Bougainville",
	},
	{
		value: 527,
		name: "(UTC+12:45) Pacific/Chatham",
	},
	{
		value: 528,
		name: "(UTC+10:00) Pacific/Chuuk",
	},
	{
		value: 529,
		name: "(UTC-06:00) Pacific/Easter",
	},
	{
		value: 530,
		name: "(UTC+11:00) Pacific/Efate",
	},
	{
		value: 531,
		name: "(UTC+13:00) Pacific/Enderbury",
	},
	{
		value: 532,
		name: "(UTC+13:00) Pacific/Fakaofo",
	},
	{
		value: 533,
		name: "(UTC+12:00) Pacific/Fiji",
	},
	{
		value: 534,
		name: "(UTC+12:00) Pacific/Funafuti",
	},
	{
		value: 535,
		name: "(UTC-06:00) Pacific/Galapagos",
	},
	{
		value: 536,
		name: "(UTC-09:00) Pacific/Gambier",
	},
	{
		value: 537,
		name: "(UTC+11:00) Pacific/Guadalcanal",
	},
	{
		value: 538,
		name: "(UTC+10:00) Pacific/Guam",
	},
	{
		value: 539,
		name: "(UTC-10:00) Pacific/Honolulu",
	},
	{
		value: 540,
		name: "(UTC-10:00) Pacific/Johnston",
	},
	{
		value: 541,
		name: "(UTC+13:00) Pacific/Kanton",
	},
	{
		value: 542,
		name: "(UTC+14:00) Pacific/Kiritimati",
	},
	{
		value: 543,
		name: "(UTC+11:00) Pacific/Kosrae",
	},
	{
		value: 544,
		name: "(UTC+12:00) Pacific/Kwajalein",
	},
	{
		value: 545,
		name: "(UTC+12:00) Pacific/Majuro",
	},
	{
		value: 546,
		name: "(UTC-9:30) Pacific/Marquesas",
	},
	{
		value: 547,
		name: "(UTC-11:00) Pacific/Midway",
	},
	{
		value: 548,
		name: "(UTC+12:00) Pacific/Nauru",
	},
	{
		value: 549,
		name: "(UTC-11:00) Pacific/Niue",
	},
	{
		value: 550,
		name: "(UTC+11:00) Pacific/Norfolk",
	},
	{
		value: 551,
		name: "(UTC+11:00) Pacific/Noumea",
	},
	{
		value: 552,
		name: "(UTC-11:00) Pacific/Pago_Pago",
	},
	{
		value: 553,
		name: "(UTC+09:00) Pacific/Palau",
	},
	{
		value: 554,
		name: "(UTC-08:00) Pacific/Pitcairn",
	},
	{
		value: 555,
		name: "(UTC+11:00) Pacific/Pohnpei",
	},
	{
		value: 556,
		name: "(UTC+11:00) Pacific/Ponape",
	},
	{
		value: 557,
		name: "(UTC+10:00) Pacific/Port_Moresby",
	},
	{
		value: 558,
		name: "(UTC-10:00) Pacific/Rarotonga",
	},
	{
		value: 559,
		name: "(UTC+10:00) Pacific/Saipan",
	},
	{
		value: 560,
		name: "(UTC-11:00) Pacific/Samoa",
	},
	{
		value: 561,
		name: "(UTC-10:00) Pacific/Tahiti",
	},
	{
		value: 562,
		name: "(UTC+12:00) Pacific/Tarawa",
	},
	{
		value: 563,
		name: "(UTC+13:00) Pacific/Tongatapu",
	},
	{
		value: 564,
		name: "(UTC+10:00) Pacific/Truk",
	},
	{
		value: 565,
		name: "(UTC+12:00) Pacific/Wake",
	},
	{
		value: 566,
		name: "(UTC+12:00) Pacific/Wallis",
	},
	{
		value: 567,
		name: "(UTC+10:00) Pacific/Yap",
	},
	{
		value: 568,
		name: "(UTC+01:00) Poland",
	},
	{
		value: 569,
		name: "(UTC+00:00) Portugal",
	},
	{
		value: 570,
		name: "(UTC+09:00) ROK",
	},
	{
		value: 571,
		name: "(UTC+08:00) Singapore",
	},
	{
		value: 572,
		name: "(UTC-04:00) SystemV/AST4",
	},
	{
		value: 573,
		name: "(UTC-04:00) SystemV/AST4ADT",
	},
	{
		value: 574,
		name: "(UTC-06:00) SystemV/CST6",
	},
	{
		value: 575,
		name: "(UTC-06:00) SystemV/CST6CDT",
	},
	{
		value: 576,
		name: "(UTC-05:00) SystemV/EST5",
	},
	{
		value: 577,
		name: "(UTC-05:00) SystemV/EST5EDT",
	},
	{
		value: 578,
		name: "(UTC-10:00) SystemV/HST10",
	},
	{
		value: 579,
		name: "(UTC-07:00) SystemV/MST7",
	},
	{
		value: 580,
		name: "(UTC-07:00) SystemV/MST7MDT",
	},
	{
		value: 581,
		name: "(UTC-08:00) SystemV/PST8",
	},
	{
		value: 582,
		name: "(UTC-08:00) SystemV/PST8PDT",
	},
	{
		value: 583,
		name: "(UTC-09:00) SystemV/YST9",
	},
	{
		value: 584,
		name: "(UTC-09:00) SystemV/YST9YDT",
	},
	{
		value: 585,
		name: "(UTC+03:00) Turkey",
	},
	{
		value: 586,
		name: "(UTC+00:00) UCT",
	},
	{
		value: 587,
		name: "(UTC-09:00) US/Alaska",
	},
	{
		value: 588,
		name: "(UTC-10:00) US/Aleutian",
	},
	{
		value: 589,
		name: "(UTC-07:00) US/Arizona",
	},
	{
		value: 590,
		name: "(UTC-06:00) US/Central",
	},
	{
		value: 591,
		name: "(UTC-05:00) US/East-Indiana",
	},
	{
		value: 592,
		name: "(UTC-05:00) US/Eastern",
	},
	{
		value: 593,
		name: "(UTC-10:00) US/Hawaii",
	},
	{
		value: 594,
		name: "(UTC-06:00) US/Indiana-Starke",
	},
	{
		value: 595,
		name: "(UTC-05:00) US/Michigan",
	},
	{
		value: 596,
		name: "(UTC-07:00) US/Mountain",
	},
	{
		value: 597,
		name: "(UTC-08:00) US/Pacific",
	},
	{
		value: 598,
		name: "(UTC-11:00) US/Samoa",
	},
	{
		value: 599,
		name: "(UTC+00:00) UTC",
	},
	{
		value: 600,
		name: "(UTC+00:00) Universal",
	},
	{
		value: 601,
		name: "(UTC+03:00) W-SU",
	},
	{
		value: 602,
		name: "(UTC+00:00) WET",
	},
	{
		value: 603,
		name: "(UTC+00:00) Zulu",
	},
	{
		value: 604,
		name: "(UTC-05:00) EST",
	},
	{
		value: 605,
		name: "(UTC-10:00) HST",
	},
	{
		value: 606,
		name: "(UTC-07:00) MST",
	},
	{
		value: 607,
		name: "(UTC+09:30) ACT",
	},
	{
		value: 608,
		name: "(UTC+10:00) AET",
	},
	{
		value: 609,
		name: "(UTC-03:00) AGT",
	},
	{
		value: 610,
		name: "(UTC+02:00) ART",
	},
	{
		value: 611,
		name: "(UTC-09:00) AST",
	},
	{
		value: 612,
		name: "(UTC-03:00) BET",
	},
	{
		value: 613,
		name: "(UTC+06:00) BST",
	},
	{
		value: 614,
		name: "(UTC+02:00) CAT",
	},
	{
		value: 615,
		name: "(UTC-03:30) CNT",
	},
	{
		value: 616,
		name: "(UTC-06:00) CST",
	},
	{
		value: 617,
		name: "(UTC+08:00) CTT",
	},
	{
		value: 618,
		name: "(UTC+03:00) EAT",
	},
	{
		value: 619,
		name: "(UTC+01:00) ECT",
	},
	{
		value: 620,
		name: "(UTC-05:00) IET",
	},
	{
		value: 621,
		name: "(UTC+05:30) IST",
	},
	{
		value: 622,
		name: "(UTC+09:00) JST",
	},
	{
		value: 623,
		name: "(UTC+13:00) MIT",
	},
	{
		value: 624,
		name: "(UTC+04:00) NET",
	},
	{
		value: 625,
		name: "(UTC+12:00) NST",
	},
	{
		value: 626,
		name: "(UTC+05:00) PLT",
	},
	{
		value: 627,
		name: "(UTC-07:00) PNT",
	},
	{
		value: 628,
		name: "(UTC-04:00) PRT",
	},
	{
		value: 629,
		name: "(UTC-08:00) PST",
	},
	{
		value: 630,
		name: "(UTC+11:00) SST",
	},
	{
		value: 631,
		name: "(UTC+07:00) VST",
	},
];

export default accountTimezones;
