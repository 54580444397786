import { assertError } from "../apiHelper";
import { getNoteUrl } from "../apiConfig";
import { apiClient } from "..";

export const createNote = async ({ note, source, entityId }) => {
	let requestData = getCreateNoteRequest(note, source, entityId);
	return await postNotesManagementApi(requestData);
};

export const getNotes = async (sourceName, entityId, pageParams) => {
	let requestData = getNotesRequest(sourceName, entityId, pageParams);
	return await postNotesManagementApi(requestData);
};

export const updateNote = async ({ note, source, entityId, id }) => {
	let requestData = getUpdateNoteRequest(note, source, entityId, id);
	return await postNotesManagementApi(requestData);
};

export const deleteNote = async ({ id }) => {
	let requestData = getDeleteNoteRequest(id);
	return await postNotesManagementApi(requestData);
};

const getNotesRequest = (sourceName, entityId, pageParams) => {
	return {
		type: "getNotes",
		data: {
			source: sourceName,
			entityId: entityId,
			...pageParams,
		},
	};
};

const getCreateNoteRequest = (note, source, entityId) => {
	return {
		type: "create",
		data: {
			note: note,
			source: source,
			entityId: entityId,
		},
	};
};

const getUpdateNoteRequest = (note, source, entityId, id) => {
	return {
		type: "update",
		data: {
			note: note,
			source: source,
			entityId: entityId,
			id: id,
		},
	};
};

const getDeleteNoteRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const postNotesManagementApi = async (requestData) => {
	let notesUrl = getNotesManagementUrl();
	let response = await apiClient.post(notesUrl, requestData);
	assertError(response, notesUrl);
	return response.data.data;
};

const getNotesManagementUrl = () => {
	return getNoteUrl() + "/mgmt";
};
