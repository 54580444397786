import React from "react";

function Probability(width = "14", height = "14", color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.08789 9.625V10.85"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.0033 12.8333V12.2499C10.0033 11.6083 9.47826 11.0833 8.83659 11.0833H5.33659C4.69492 11.0833 4.16992 11.6083 4.16992 12.2499V12.8333H10.0033Z"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path
				d="M3.58789 12.8333H10.5879"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.91602 5.24996V3.49996C2.91602 2.21079 3.96018 1.16663 5.24935 1.16663H8.74935C10.0385 1.16663 11.0827 2.21079 11.0827 3.49996V5.24996C11.0827 7.50746 9.25685 9.33329 6.99935 9.33329C4.74185 9.33329 2.91602 7.50746 2.91602 5.24996Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.06445 6.09581C1.53945 5.51248 1.18945 4.81248 1.18945 3.99581C1.18945 3.17914 1.83112 2.53748 2.64779 2.53748H3.02695C2.91029 2.80581 2.85195 3.10331 2.85195 3.41248V5.16248C2.85195 5.74581 2.97445 6.29414 3.19029 6.79581C2.75279 6.65581 2.36779 6.39914 2.06445 6.09581Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.9344 6.09581C12.4594 5.51248 12.8094 4.81248 12.8094 3.99581C12.8094 3.17914 12.1678 2.53748 11.3511 2.53748H10.9719C11.0886 2.80581 11.1469 3.10331 11.1469 3.41248V5.16248C11.1469 5.74581 11.0244 6.29414 10.8086 6.79581C11.2461 6.65581 11.6311 6.39914 11.9344 6.09581Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Probability;
