import { Box, IconButton, useTheme } from "@mui/material";
import { default as EyeIcon } from "../../../../../../../assets/icons/eyeOff";
import React from "react";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../../utils/notification/notificationMessages";
import { useUpdateField } from "../../../../../../../hooks/services/dataFields";
import { useFieldContext } from "../../../FieldContext";

export default function FieldHideButton(props) {
	const { field, moduleName } = props;
	const theme = useTheme();

	const { shouldShowFieldActions } = useFieldContext(field);

	// mutation call
	const updateFieldMutation = useUpdateField(moduleName);

	const handleFieldVisibility = () => {
		let requestData = getFieldVisibilityRequestData();

		updateFieldMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const getFieldVisibilityRequestData = () => {
		let isHidden = true;
		let requestData = {
			...field,
			config: {
				isHidden,
			},
		};

		return requestData;
	};

	return (
		<React.Fragment>
			<Box>
				{shouldShowFieldActions && (
					<IconButton onClick={handleFieldVisibility}>
						{EyeIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				)}
			</Box>
		</React.Fragment>
	);
}
