import React from "react";
import { Box, Stack, Typography } from "@mui/material";

function DuplicateNotFound() {
	return (
		<React.Fragment>
			<Box style={{ height: "60vh" }}>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Typography>No Duplicates Found</Typography>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

export default DuplicateNotFound;
