import React, { useMemo, useState } from "react";
import { Box, Checkbox, Stack, Typography, useTheme } from "@mui/material";
import { default as CustomIcon } from "../../../../assets/icons/custom";
import { default as DragIcon } from "../../../../assets/icons/drag";
import Menu from "../../Menu";
import MenuSearchBar from "../../MenuSearchBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const reorder = (item, startIndex, endIndex) => {
	const result = Array.from(item);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export default function AddOrRemoveColumns({
	tableStructure,
	updateSelectedColumn,
	setTableStructure,
}) {
	const [searchInput, setSearchInput] = useState("");
	const theme = useTheme();
	const [columnSettingsElement, setContactSettingsElement] = useState(null);
	const isAddOrRemoveColumnMenuOpen = Boolean(columnSettingsElement);
	const [hoveredColumn, setHoveredColumn] = useState(null);

	const getDragProps = (provided) => {
		return searchInput ? {} : provided.dragHandleProps;
	};

	const OpenTableHeaderMenu = (event) => {
		setContactSettingsElement(event.currentTarget);
		setSearchInput("");
	};

	const filteredTableStructure = useMemo(() => {
		if (searchInput !== "") {
			const result = tableStructure.columns?.filter((tableStructure) => {
				return tableStructure.displayName
					.toLowerCase()
					.startsWith(searchInput.toLowerCase());
			});
			return result;
		} else {
			return tableStructure.columns;
		}
	}, [searchInput, tableStructure]);

	const CloseTableHeaderMenu = () => {
		setContactSettingsElement(null);
	};

	const onMouseOverColumnInList = (id) => setHoveredColumn(id);
	const onMouseOutColumnInList = () => setHoveredColumn(null);

	const handleFilteredField = (event) => {
		const { value } = event.target;
		setSearchInput(value ?? "");
	};

	const onTableHeaderDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		let updatedColumnsStructure = reorder(
			tableStructure.columns,
			result.source.index,
			result.destination.index
		);
		updateSelectedColumn(updatedColumnsStructure);
		setTableStructure((tableStructure) => ({
			...tableStructure,
			columns: [...updatedColumnsStructure],
		}));
	};

	const toggleColumnVisibility = (column) => {
		let columns = tableStructure.columns;
		let updatedColumnIndex = columns.indexOf(column);

		if (columns?.[updatedColumnIndex]) {
			columns[updatedColumnIndex].hidden =
				!columns[updatedColumnIndex].hidden;
		}

		updateSelectedColumn(columns);

		setTableStructure((tableStructure) => ({
			...tableStructure,
			columns: [...columns],
		}));
	};

	return (
		<>
			<Stack
				p={1.3}
				width="60px"
				sx={{
					position: "absolute",
					right: 0,
					zIndex: 2,
					backgroundColor: "#fff",
					direction: "row",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
				}}
				onClick={OpenTableHeaderMenu}
			>
				{CustomIcon(20, 20, theme.palette.primary.main)}
			</Stack>
			<Box>
				<Menu
					anchorEl={columnSettingsElement}
					open={isAddOrRemoveColumnMenuOpen}
					onClose={CloseTableHeaderMenu}
					style={{
						marginTop: "3px",
					}}
					sx={{
						"& .MuiMenu-list": {
							paddingTop: "0px",
						},
					}}
				>
					<Box p={0.5}>
						<MenuSearchBar
							value={searchInput}
							onChange={handleFilteredField}
						/>
					</Box>
					<Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
						<DragDropContext onDragEnd={onTableHeaderDragEnd}>
							<Droppable droppableId="tableHead_items">
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{filteredTableStructure &&
										filteredTableStructure.length > 0 ? (
											filteredTableStructure.map(
												(column, index) => (
													<Draggable
														draggableId={
															column.name
														}
														index={index}
														key={column.name}
													>
														{(provided) => (
															<Box
																ref={
																	provided.innerRef
																}
																{...provided.draggableProps}
															>
																<Box
																	pl={1}
																	pr={2}
																	style={{
																		backgroundColor:
																			hoveredColumn ===
																			column.name
																				? "#F4F5F5"
																				: "#fff",
																	}}
																	onMouseOver={() =>
																		onMouseOverColumnInList(
																			column.name
																		)
																	}
																	onMouseOut={() =>
																		onMouseOutColumnInList()
																	}
																>
																	<Stack
																		direction="row"
																		alignItems="center"
																		justifyContent="space-between"
																		minHeight="40px"
																	>
																		<Stack
																			direction="row"
																			alignItems="center"
																			spacing={
																				1
																			}
																		>
																			<Checkbox
																				checked={
																					!column.hidden ||
																					column.isMandatoryField
																				}
																				onClick={() =>
																					toggleColumnVisibility(
																						column
																					)
																				}
																				disabled={
																					column.isMandatoryField
																				}
																			/>
																			<Box>
																				<Typography
																					fontSize={
																						13
																					}
																				>
																					{
																						column.displayName
																					}
																				</Typography>
																			</Box>
																		</Stack>

																		<Box
																			{...getDragProps(
																				provided
																			)}
																		>
																			{hoveredColumn ===
																			column.name ? (
																				<Stack
																					style={{
																						cursor: "pointer",
																						display:
																							searchInput
																								? "none"
																								: "block",
																					}}
																					spacing={
																						2
																					}
																				>
																					{DragIcon(
																						24,
																						24,
																						"grey"
																					)}
																				</Stack>
																			) : (
																				<Box
																					height={
																						24
																					}
																					width={
																						24
																					}
																				/>
																			)}
																		</Box>
																	</Stack>
																</Box>
															</Box>
														)}
													</Draggable>
												)
											)
										) : (
											<Typography
												style={{
													textAlign: "center",
													padding: 10,
													fontSize: "13px",
													opacity: 0.6,
												}}
											>
												{" "}
												No Results Found{" "}
											</Typography>
										)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</Box>
				</Menu>
			</Box>
		</>
	);
}
