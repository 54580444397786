import React, { useState } from "react";
import {
	Divider,
	IconButton,
	MenuItem,
	MenuList,
	Stack,
	Typography,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { DwarfButton } from "../../../../styles/twozo";
import Menu from "../../../Elements/Menu";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useDeleteForever,
	useMarkAsDone,
	useDiscardDraftsMail,
	useUpdateMailState,
	useUpdateScheduledMail,
} from "../../../../hooks/services/mail";
import DeleteDialog from "../../../Elements/DeleteDialog";
import ConfirmDialog from "../../../Elements/ConfirmDialog";

export default function SelectedMenu(props) {
	const {
		mailCount,
		mailListName,
		clearSelectedMail,
		selectedMailIds,
		menuConfig,
	} = props;

	const [mailStateMenuElement, setMailStateMenuElement] = useState(null);
	const isMailSateMenuOpened = Boolean(mailStateMenuElement);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showCancelSentDialog, setShowCancelSentDialog] = useState(false);

	const selectedMailCount = selectedMailIds?.length;
	const [isDeleteForeverDialogOpened, setIsDeleteForeverDialogOpened] =
		useState(false);

	const updateMailStateMutation = useUpdateMailState(mailListName);
	const updateScheduledMailMutation = useUpdateScheduledMail();
	const deleteForeverMutation = useDeleteForever();
	const markAsDoneMutation = useMarkAsDone();
	const discardDraftsMutation = useDiscardDraftsMail();

	const successNotificationTitle = "Success!";

	const mailActions = {
		read: {
			payload: { isRead: true },
			singularSuccessMessage: notificationMessage.mailRead,
			pluralSuccessMessage: `${selectedMailCount} conversations have been marked as read.`,
		},
		unRead: {
			payload: { isRead: false },
			singularSuccessMessage: notificationMessage.mailUnread,
			pluralSuccessMessage: `${selectedMailCount} conversations have been marked as unread.`,
		},
		starred: {
			payload: { isStarred: true },
			singularSuccessMessage: notificationMessage.mailStarred,
			pluralSuccessMessage: `${selectedMailCount} conversations have been marked as starred.`,
		},
		unStarred: {
			payload: { isStarred: false },
			singularSuccessMessage: notificationMessage.mailUnstarred,
			pluralSuccessMessage: `${selectedMailCount} conversations have been marked as unstarred.`,
		},
		archived: {
			payload: { isArchived: true },
			singularSuccessMessage: notificationMessage.mailArchived,
			pluralSuccessMessage: `${selectedMailCount} conversations have been archived.`,
		},
		unArchived: {
			payload: { isArchived: false },
			singularSuccessMessage: notificationMessage.mailUnarchived,
			pluralSuccessMessage: `${selectedMailCount} conversations have been unarchived.`,
		},
		active: {
			payload: { isActive: true },
			singularSuccessMessage: notificationMessage.mailActive,
			pluralSuccessMessage: `${selectedMailCount} conversation have been restored successfully.`,
		},
		unActive: {
			payload: { isActive: false },
			singularSuccessMessage: notificationMessage.mailUnactive,
			pluralSuccessMessage: `${selectedMailCount} conversations moved to trash successfully.`,
		},
		deleteForever: {
			singularSuccessMessage: notificationMessage.mailDeleteForever,
			pluralSuccessMessage: `${selectedMailCount} conversations have been deleted permanently.`,
		},
		markAsDone: {
			singularSuccessMessage: notificationMessage.mailMarkAsDone,
			pluralSuccessMessage: `${selectedMailCount} conversations marked as done successfully.`,
		},
		discardDraft: {
			singularSuccessMessage: notificationMessage.discardDraft,
			pluralSuccessMessage: `${selectedMailCount} drafts have been deleted successfully.`,
		},
	};

	const openMailStateMenu = (event) => {
		setMailStateMenuElement(event.currentTarget);
	};

	const closeMailStateMenu = () => {
		setMailStateMenuElement(null);
	};

	const handleUpdateMailState = (mailAction) => {
		const updatedMailStateRequest = {
			ids: selectedMailIds,
			...mailAction.payload,
		};

		updateMailStateMutation.mutate(updatedMailStateRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						selectedMailCount === 1
							? mailAction.singularSuccessMessage
							: mailAction.pluralSuccessMessage,
				});
				setMailStateMenuElement(null);
				clearSelectedMail();
				closeDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const updateScheduledMailState = () => {
		let updateScheduledMailRequest = {
			mailIds: selectedMailIds,
			isCancelled: true,
		};
		updateScheduledMailMutation.mutate(updateScheduledMailRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: `${selectedMailIds.length} conversations saved as draft.`,
				});
				clearSelectedMail();
				closeCancelSentDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleDeleteForever = () => {
		deleteForeverMutation.mutate(selectedMailIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						selectedMailCount === 1
							? mailActions.deleteForever.singularSuccessMessage
							: mailActions.deleteForever.pluralSuccessMessage,
				});
				clearSelectedMail();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleMarkAsDone = () => {
		markAsDoneMutation.mutate(selectedMailIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						selectedMailCount === 1
							? mailActions.markAsDone.singularSuccessMessage
							: mailActions.markAsDone.pluralSuccessMessage,
				});
				clearSelectedMail();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openDeleteForeverDialog = () => {
		setIsDeleteForeverDialogOpened(true);
	};

	const closeDeleteForeverDialog = () => {
		setIsDeleteForeverDialogOpened(false);
	};

	const handleDiscardDrafts = () => {
		discardDraftsMutation.mutate(selectedMailIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					title: successNotificationTitle,
					message:
						selectedMailCount === 1
							? mailActions.discardDraft.singularSuccessMessage
							: mailActions.discardDraft.pluralSuccessMessage,
				});
				clearSelectedMail();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openDeleteDialog = () => {
		setShowDeleteDialog(true);
	};

	const closeDeleteDialog = () => {
		setShowDeleteDialog(false);
	};

	const openCancelSentDialog = () => {
		setShowCancelSentDialog(true);
	};

	const closeCancelSentDialog = () => {
		setShowCancelSentDialog(false);
	};

	const selectedMenuOptions = [
		{
			name: "Mark As Done",
			action: handleMarkAsDone,
			isEnabled: !!menuConfig?.isMarkAsDoneEnabled,
		},
		{
			name: "Remove Star",
			action: () => handleUpdateMailState(mailActions.unStarred),
			isEnabled: !!menuConfig?.isRemoveStarEnabled,
		},
		{
			name: "Archive",
			action: () => handleUpdateMailState(mailActions.archived),
			isEnabled: !!menuConfig?.isArchiveEnabled,
		},
		{
			name: "Unarchive",
			action: () => handleUpdateMailState(mailActions.unArchived),
			isEnabled: !!menuConfig?.isUnArchiveEnabled,
		},
		{
			name: "Mark As",
			action: openMailStateMenu,
			isEnabled: !!menuConfig?.isMarkAsEnabled,
		},
		{
			name: "Discard Drafts",
			action: handleDiscardDrafts,
			isEnabled: !!menuConfig.isDiscardDraftsEnabled,
		},
		{
			name: "Cancel Send",
			action: openCancelSentDialog,
			isEnabled: !!menuConfig?.isCanceSendEnabled,
		},
		{
			name: "Delete",
			action: openDeleteDialog,
			isEnabled: !!menuConfig?.isDeleteEnabled,
		},
		{
			name: "Delete forever",
			action: openDeleteForeverDialog,
			isEnabled: !!menuConfig?.isDeleteForeverEnabled,
		},
		{
			name: "Restore",
			action: () => handleUpdateMailState(mailActions.active),
			isEnabled: !!menuConfig?.isRestoreEnabled,
		},
	];

	const getSelectedMenuOptions = () => {
		return selectedMenuOptions.filter((menuOption) => menuOption.isEnabled);
	};

	return (
		<React.Fragment>
			{/* Delete Forever Dialog*/}
			<DeleteDialog
				title="Are you sure you want to delete this conversations permanently?"
				subtitle="Once you've done this, you can’t undo it."
				open={isDeleteForeverDialogOpened}
				onCancel={closeDeleteForeverDialog}
				onDelete={handleDeleteForever}
				disableDeleteButton={deleteForeverMutation.isLoading}
				confirmButtonText="Delete Forever"
			></DeleteDialog>

			{/* Delete Dialog */}
			<DeleteDialog
				title="Are you sure you want to delete this conversation?"
				subtitle="Deleting it will move the conversation to the trash folder."
				open={showDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={() => handleUpdateMailState(mailActions.unActive)}
				disableDeleteButton={updateMailStateMutation.isLoading}
			></DeleteDialog>

			{/* Cancel Sent Dialog */}
			<ConfirmDialog
				title="Are you sure you want to cancel the sending and move the scheduled conversation to drafts?"
				subtitle="This action will cancel the scheduled mail and the conversation will be moved to the draft."
				open={showCancelSentDialog}
				onCancel={closeCancelSentDialog}
				onConfirm={updateScheduledMailState}
				disableConfirmButton={updateScheduledMailMutation.isLoading}
				confirmButtonText="Cancel and Move to drafts"
				cancelButtonText="Keep as is"
			></ConfirmDialog>

			<Menu
				width="150px"
				anchorEl={mailStateMenuElement}
				open={isMailSateMenuOpened}
				onClose={closeMailStateMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{
					marginTop: "4px",
				}}
			>
				<MenuList sx={{ padding: "0px" }}>
					<MenuItem
						style={{ minWidth: "40px" }}
						onClick={() => handleUpdateMailState(mailActions.read)}
					>
						<Typography fontSize={13}>Read</Typography>
					</MenuItem>

					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() =>
							handleUpdateMailState(mailActions.unRead)
						}
					>
						<Typography fontSize={13}>Unread</Typography>
					</MenuItem>

					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() =>
							handleUpdateMailState(mailActions.starred)
						}
					>
						<Typography fontSize={13}>Starred</Typography>
					</MenuItem>

					<MenuItem
						style={{ minHeight: "40px" }}
						onClick={() =>
							handleUpdateMailState(mailActions.unStarred)
						}
					>
						<Typography fontSize={13}>Unstarred</Typography>
					</MenuItem>
				</MenuList>
			</Menu>

			<Stack direction="row" alignItems="center" spacing={2}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize={14} fontWeight={500}>
						{selectedMailCount} Selected
						<Typography
							component="span"
							style={{
								fontSize: "14px",
								fontWeight: 500,
								paddingLeft: "4px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							of {mailCount}
						</Typography>
					</Typography>

					<IconButton
						onClick={clearSelectedMail}
						size="small"
						style={{ padding: 0, paddingTop: "1px" }}
					>
						{CloseIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
					</IconButton>
				</Stack>

				<Divider orientation="vertical" style={{ height: "20px" }} />

				{getSelectedMenuOptions().map((menuOption, index) => (
					<DwarfButton
						key={index}
						variant="contained"
						color="secondary"
						disableElevation
						onClick={menuOption.action}
					>
						{menuOption.name}
					</DwarfButton>
				))}

				<Divider orientation="vertical" style={{ height: "20px" }} />
			</Stack>
		</React.Fragment>
	);
}
