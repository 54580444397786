import { getRootKey } from "../rootKey";

const tagsKey = "tags";

export const getTagsKey = () => {
	return [...getRootKey(), tagsKey];
};

// query Keys

export const tagsListKey = [...getTagsKey(), "recordTagsList"];

export const emailTemplateTagsListKey = [
	...getTagsKey(),
	"emailTemplateTagsList",
];

export const emailTemplateTagsDropdownKey = [
	...getTagsKey(),
	"emailTemplateTagsDropdownList",
];
