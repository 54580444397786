import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DateRangeBaseComponent from "./DateRangeBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function DateRangeComponent({
	field,
	subField,
	inputRef,
	...rest
}) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, value },
					fieldState: { error },
				}) => (
					<DateRangeBaseComponent
						placeholder={field?.config?.placeHolder}
						{...rest}
						onChange={onChange}
						value={value}
						subField={subField}
						field={field}
						inputRef={inputRef}
						error={error}
						clearErrors={clearErrors}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
