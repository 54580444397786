import React from "react";
import EmailList from "../EmailList";
import { mailListName } from "../../../utils/EmailUtils/mailListName";

export default function Starred() {
	const menuConfig = {
		isRemoveStarEnabled: true,
		isArchiveEnabled: true,
		isMarkAsEnabled: true,
		isDeleteEnabled: true,
	};

	const filterConfig = {
		isUnreadFilterEnabled: true,
		isTrackingFilterEnabled: true,
		isLinkingFilterEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Starred"
				mailListName={mailListName.STARRED}
				filterConfig={filterConfig}
				menuConfig={menuConfig}
			/>
		</React.Fragment>
	);
}
