import React, { useState } from "react";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as ReplyIcon } from "../../../../assets/icons/reply";
import { default as ForwardIcon } from "../../../../assets/icons/forward";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as RestoreIcon } from "../../../../assets/icons/reload";
import Menu from "../../../Elements/Menu";
import {
	useDeleteForever,
	useUpdateMailState,
} from "../../../../hooks/services/mail";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";
import DeleteDialog from "../../../Elements/DeleteDialog";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import EmailViewer from "../EmailViewer";
import { twozoStyles } from "../../../../styles/twozo";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function EmailTableRowMenu(props) {
	const {
		anchorEl,
		isTableRowMenuOpened,
		closeTableRowMenu,
		selectedMailData,
		menuConfig,
		menuSpecificProps,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isDeleteForeverDialogOpened, setIsDeleteForeverDialogOpened] =
		useState(false);
	const [isMailViewerOpened, setIsMailViewerOpened] = useState(false);
	const [emailViewerMode, setEmailViewerMode] = useState({});

	const { isUserAllowedFor } = useAuth();

	const deleteForeverMutation = useDeleteForever(
		menuSpecificProps?.mailListName
	);
	const updateMailStateMutation = useUpdateMailState(
		menuSpecificProps?.mailListName
	);

	const openDeleteDialog = () => {
		setShowDeleteDialog(true);
		closeTableRowMenu();
	};

	const closeDeleteDialog = () => {
		setShowDeleteDialog(false);
	};

	const handleDeleteMail = () => {
		const updatedMailStateRequest = {
			ids: [selectedMailData?.id],
			isActive: false,
		};
		updateMailStateMutation.mutate(updatedMailStateRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.mailUnactive,
				});
				closeDeleteDialog();
				closeTableRowMenu();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleDeleteForever = () => {
		deleteForeverMutation.mutate([selectedMailData?.id], {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.mailDeleteForever,
				});
				closeDeleteForeverDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleRestoreMail = () => {
		const updatedMailStateRequest = {
			ids: [selectedMailData?.id],
			isActive: true,
		};
		updateMailStateMutation.mutate(updatedMailStateRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.mailActive,
				});
				closeTableRowMenu();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openDeleteForeverDialog = () => {
		setIsDeleteForeverDialogOpened(true);
		closeTableRowMenu();
	};

	const closeDeleteForeverDialog = () => {
		setIsDeleteForeverDialogOpened(false);
	};

	const openMailViewer = () => {
		setIsMailViewerOpened(true);
		closeTableRowMenu();
	};

	const closeMailViewer = () => {
		setIsMailViewerOpened(false);
		setEmailViewerMode({});
	};

	const openForwardMailViewer = () => {
		setEmailViewerMode({ forward: true });
		openMailViewer();
	};

	const openReplyMailViewer = () => {
		setEmailViewerMode({ reply: true });
		openMailViewer();
	};

	const tableRowMenuOptions = [
		{
			id: 1,
			name: "Reply To",
			icon: ReplyIcon,
			action: openReplyMailViewer,
			isEnabled:
				!!menuConfig?.isReplyEnabled &&
				isUserAllowedFor(PERMISSIONS.email.individualEmailLimit),
		},
		{
			id: 2,
			name: "Forward",
			icon: ForwardIcon,
			action: openForwardMailViewer,
			isEnabled:
				!!menuConfig?.isForwardEnabled &&
				isUserAllowedFor(PERMISSIONS.email.individualEmailLimit),
		},
		{
			id: 3,
			name: "Delete",
			icon: DeleteIcon,
			action: openDeleteDialog,
			isEnabled: !!menuConfig?.isDeleteEnabled,
		},
		{
			id: 4,
			name: "Restore",
			icon: RestoreIcon,
			action: handleRestoreMail,
			isEnabled: !!menuConfig?.isRestoreEnabled,
		},
		{
			id: 5,
			name: "Delete Forever",
			icon: DeleteIcon,
			action: openDeleteForeverDialog,
			menuTextColor: theme.palette.error.main,
			isEnabled: !!menuConfig?.isDeleteForeverEnabled,
		},
	];

	const draftMenuOptions = [
		{
			id: 1,
			name: "Delete",
			icon: DeleteIcon,
			action: openDeleteDialog,
		},
	];

	const getTableRowMenuOptions = () => {
		if (selectedMailData?.hasDraft && !menuSpecificProps?.hasTrashMail) {
			return draftMenuOptions;
		} else {
			return tableRowMenuOptions.filter(
				(menuOption) => menuOption.isEnabled
			);
		}
	};

	return (
		<React.Fragment>
			{/* Delete dialog */}
			<DeleteDialog
				title="Are you sure you want to delete this conversation?"
				subtitle="Deleting it will move the conversation to the trash folder."
				open={showDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteMail}
				disableDeleteButton={updateMailStateMutation.isLoading}
			></DeleteDialog>

			{/* Delete Forever Dialog*/}
			<DeleteDialog
				title="Are you sure you want to delete this conversations permanently?"
				subtitle="Once you've done this, you can’t undo it."
				open={isDeleteForeverDialogOpened}
				onCancel={closeDeleteForeverDialog}
				onDelete={handleDeleteForever}
				disableDeleteButton={deleteForeverMutation.isLoading}
				confirmButtonText="Delete Forever"
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				BackdropProps={{ invisible: true }}
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMailViewerOpened}
				onOpen={openMailViewer}
				onClose={closeMailViewer}
				disableSwipeToOpen
			>
				<Box className={classes.mailViewContainer}>
					<Box className={classes.mailViewCard}>
						<EmailViewer
							mailListName={menuSpecificProps?.mailListName}
							onClose={closeMailViewer}
							mailId={selectedMailData?.id}
							isTrash={menuSpecificProps?.hasTrashMail}
							viewerMode={emailViewerMode}
						/>
					</Box>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={anchorEl}
				open={isTableRowMenuOpened}
				onClose={closeTableRowMenu}
				sx={{
					"& .MuiMenu-list": {
						padding: "4px 0px",
					},
				}}
			>
				{getTableRowMenuOptions()?.map((menu) => (
					<MenuItem
						key={menu.id}
						style={{
							height: "40px",
						}}
						onClick={menu.action}
					>
						<Stack direction="row" spacing={2}>
							<Box display="flex">
								{menu.icon(
									20,
									20,
									menu?.menuTextColor
										? menu.menuTextColor
										: theme.palette.secondary.contrastText
								)}
							</Box>

							<Typography
								fontSize={13}
								fontWeight={500}
								color={
									menu?.menuTextColor
										? menu.menuTextColor
										: theme.palette.secondary.contrastText
								}
							>
								{menu.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>
		</React.Fragment>
	);
}
