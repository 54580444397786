import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getDealTimelineKey } from "../../../../utils/queryKeys";
import { getDealTimelineData } from "../../../../api/timeline/timelineAPI";
import { timelinePageSize } from "../../../../utils/queryConstants/summary/timeline";

const useDealTimelineData = (dealId, timelineIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getDealTimelineKey(dealId, timelineIdsToFilter),
		queryFn: ({ pageParam }) => {
			return getDealTimelineData(dealId, timelineIdsToFilter, {
				start: pageParam ? (pageParam - 1) * timelinePageSize + 1 : 1,
				limit: timelinePageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		refetchOnWindowFocus: false,
	});
};

const useInvalidateDealTimelineData = (dealId) => {
	let queryClient = useQueryClient();
	const dealTimelineKey = getDealTimelineKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealTimelineKey);
	};
};

export { useDealTimelineData, useInvalidateDealTimelineData };
