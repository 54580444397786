import { apiClient } from "../..";
import { getProductsUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getProductPriceList = async (id) => {
	let requestData = getProductPriceListRequest(id);
	return await postProductManagementApi(requestData);
};

export const getPriceTagDetails = async (
	productId,
	currencyValue,
	isTaxInclusive
) => {
	let requestData = getPriceTagDetailsRequest(
		productId,
		currencyValue,
		isTaxInclusive
	);
	return await postProductManagementApi(requestData);
};

export const updateProductPrice = async (data) => {
	let requestData = getUpdateProductPriceRequest(data);
	return await postProductManagementApi(requestData);
};

export const deleteProductPrice = async (id) => {
	let requestData = getDeleteProductPriceRequest(id);
	return await postProductManagementApi(requestData);
};

const postProductManagementApi = async (requestData) => {
	let productsManagementUrl = getProductManagementUrl();
	let response = await apiClient.post(productsManagementUrl, requestData);

	assertError(response, productsManagementUrl);

	return response.data.data;
};

const getProductPriceListRequest = (id) => {
	return {
		type: "getProductPriceTags",
		data: {
			productId: id,
		},
	};
};

const getPriceTagDetailsRequest = (
	productId,
	currencyValue,
	isTaxInclusive
) => {
	return {
		type: "getPriceTag",
		data: {
			productId: productId,
			currency: currencyValue,
			taxInclusive: isTaxInclusive,
		},
	};
};

const getUpdateProductPriceRequest = (data) => {
	return {
		type: "updatePriceTags",
		data: data,
	};
};

const getDeleteProductPriceRequest = (id) => {
	return {
		type: "deletePriceTag",
		data: {
			id: id,
		},
	};
};

const getProductManagementUrl = () => {
	return getProductsUrl() + "/mgmt";
};
