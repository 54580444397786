import { apiClient } from "..";
import { getActivityTypeUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getActivityTypes = async (isActive) => {
	let requestData = getActivityTypesRequestData(isActive);
	return await postActivityTypeManagement(requestData);
};

export const createActivityType = async (data) => {
	let requestData = getCreateActivityTypeRequestData(data);
	return await postActivityTypeManagement(requestData);
};

export const updateActivityType = async (data) => {
	let requestData = getUpdateActivityTypeRequestData(data);
	return await postActivityTypeManagement(requestData);
};

export const deleteActivityType = async (id) => {
	let requestData = getDeleteActivityTypeRequestData(id);
	return await postActivityTypeManagement(requestData);
};

export const updateActivityTypesData = async (updatedOrderIds) => {
	let requestData = getUpdateActivityTypesRequestData(updatedOrderIds);
	return await postActivityTypeManagement(requestData);
};

export const updateActivityTypeStatus = async ({ typeId, isActive }) => {
	let requestData = updateActivityTypeStatusRequest(typeId, isActive);
	return await postActivityTypeManagement(requestData);
};

const getCreateActivityTypeRequestData = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getActivityTypesRequestData = (isActive) => {
	return {
		type: "list",
		data: {
			isActive: isActive,
		},
	};
};

const getUpdateActivityTypeRequestData = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getDeleteActivityTypeRequestData = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getUpdateActivityTypesRequestData = (updatedOrderIds) => {
	return {
		type: "updateOrder",
		data: {
			ids: updatedOrderIds,
		},
	};
};

const updateActivityTypeStatusRequest = (typeId, isActive) => {
	return {
		type: "updateStatus",
		data: {
			typeId: typeId,
			isActive: isActive,
		},
	};
};

const postActivityTypeManagement = async (requestData) => {
	let activityTypeManagementUrl = getActivityTypeManagementUrl();
	let response = await apiClient.post(activityTypeManagementUrl, requestData);
	assertError(response, activityTypeManagementUrl);

	return response.data.data;
};

const getActivityTypeManagementUrl = () => {
	return getActivityTypeUrl() + "/mgmt";
};
