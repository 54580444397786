import React from "react";

function RoundCheckBoxOff(width, height, color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				opacity="0.2"
				d="M18.3334 10C18.3334 5.41667 14.5834 1.66667 10 1.66667C5.41669 1.66667 1.66669 5.41667 1.66669 10C1.66669 14.5833 5.41669 18.3333 10 18.3333C14.5834 18.3333 18.3334 14.5833 18.3334 10Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default RoundCheckBoxOff;
