import React, { useState, useCallback, useRef } from "react";
import { useSummaryContext } from "../../Summary/SummaryContext";
import GroupFieldEdit from "../../Summary/GroupFieldEdit";
import Menu from "../Menu";
import FieldEditMenu from "../FieldEditMenu";
import FieldEditActions from "../FieldEditActions";
import { Box, Stack, useTheme } from "@mui/material";

export default function FieldEdit({ children, field, fieldLabel, styles }) {
	const theme = useTheme();
	const childFormRef = useRef(null);
	// summary context
	const {
		updateSummaryField,
		source,
		entityId,
		hasEditPermission,
		isSaving,
	} = useSummaryContext();

	// field edit menu
	const [menuElement, setMenuElement] = useState(null);
	const openEditMenu = Boolean(menuElement);

	// group field edit menu
	const [groupFieldMenuElement, setGroupFieldMenuElement] = useState(null);
	const isGroupFieldMenuOpen = Boolean(groupFieldMenuElement);

	const [error, setError] = useState(null);

	const onFieldClicked = (event) => {
		if (field.config.readOnly || !hasEditPermission) {
			return;
		}

		if (field.config.isGroupedField) {
			openGroupFieldMenu(event);
		} else {
			openEditComponent(event);
		}
	};

	const openEditComponent = (event) => {
		setMenuElement(event.currentTarget);
	};

	const closeEditComponent = () => {
		setMenuElement(null);
		setError(null);
	};

	const openGroupFieldMenu = (event) => {
		setGroupFieldMenuElement(event.currentTarget);
	};

	const closeGroupFieldMenu = () => {
		setGroupFieldMenuElement(null);
	};

	const handleError = useCallback((newError) => {
		setError(newError);
	}, []);

	const handleSave = useCallback(() => {
		if (childFormRef?.current && childFormRef?.current?.submitForm) {
			childFormRef.current.submitForm();
		}
	}, []);

	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				...child.props,
				onClose: closeEditComponent,
				onError: handleError,
				ref: childFormRef,
			});
		}
		return child;
	});

	return (
		<>
			{fieldLabel(onFieldClicked)}

			{/* Individual Field Edit Menu */}
			<FieldEditMenu
				open={openEditMenu}
				anchorEl={menuElement}
				onClose={closeEditComponent}
				disableBackdropClick={true}
			>
				<Stack
					flexDirection="row"
					style={{
						width: "371px",
						...styles?.fieldEditContainer,
					}}
				>
					<Box
						style={{
							width: "300px",
							backgroundColor: "#fff",
							zIndex: 1001,
							borderRadius: "8px",
							border: `1px solid ${
								error
									? theme.palette.error.main
									: theme.palette.primary.main
							}`,
							...styles?.fieldEditForm,
						}}
					>
						{childrenWithProps}
					</Box>

					<FieldEditActions
						onClose={closeEditComponent}
						error={error}
						onSave={handleSave}
						isSaving={isSaving}
					/>
				</Stack>
			</FieldEditMenu>

			{/* Group Field Edit Menu */}
			<Menu
				open={isGroupFieldMenuOpen}
				anchorEl={groupFieldMenuElement}
				onClose={closeGroupFieldMenu}
				sx={{
					"& .MuiMenu-list": {
						paddingTop: "0px",
					},
				}}
			>
				{isGroupFieldMenuOpen ? (
					<GroupFieldEdit
						onClose={closeGroupFieldMenu}
						fieldId={field.fieldId}
						entityId={entityId}
						onSave={updateSummaryField}
						isSaving={isSaving}
						moduleName={source}
					/>
				) : null}
			</Menu>
		</>
	);
}
