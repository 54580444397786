import React, { useState } from "react";
import {
	useCloneEmailTemplate,
	useDeleteEmailTemplate,
	useTemplateData,
} from "../../../../hooks/services/mail/emailTemplate";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CopyIcon } from "../../../../assets/icons/copy";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { Markup } from "interweave";
import DOMPurify from "dompurify";
import DeleteDialog from "../../../Elements/DeleteDialog";
import EditTemplate from "../EditTemplate";
import { twozoStyles } from "../../../../styles/twozo";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";

export default function TemplateViewer(props) {
	const { templateId, templateFolderId, onCloseTemplateViewer } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const domPurify = DOMPurify();

	const [isTemplateEditDrawerOpened, setIsTemplateEditDrawerOpened] =
		useState(false);
	const [showTemplateDeleteDialog, setShowTemplateDeleteDialog] =
		useState(false);

	const successNotificationTitle = "Success!";

	domPurify.addHook("afterSanitizeAttributes", function (node) {
		if (node.tagName === "A") {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noreferrer noopener");
		}
	});

	const { data: templateData, isLoading: isLoadingTemplateData } =
		useTemplateData(templateId);

	const cloneEmailTemplateMutation = useCloneEmailTemplate(templateFolderId);
	const deleteEmailTemplateMutation =
		useDeleteEmailTemplate(templateFolderId);

	const getTemplateContent = (templateContent) => {
		const bodyContent = domPurify.sanitize(templateContent);
		return bodyContent;
	};

	const openTemplateEditDrawer = () => {
		setIsTemplateEditDrawerOpened(true);
	};

	const closeTemplateEditDrawer = () => {
		setIsTemplateEditDrawerOpened(false);
	};

	const openTemplateDeleteDialog = () => {
		setShowTemplateDeleteDialog(true);
	};

	const closeTemplateDeleteDialog = () => {
		setShowTemplateDeleteDialog(false);
	};

	const handleCloneEmailTemplate = () => {
		cloneEmailTemplateMutation.mutate(templateId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.emailTemplateCloned,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleDeleteEmailTemplate = () => {
		deleteEmailTemplateMutation.mutate(templateId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.emailTemplateDeleted,
				});
				closeTemplateDeleteDialog();
				onCloseTemplateViewer();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{/* Edit template drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isTemplateEditDrawerOpened}
				onOpen={openTemplateEditDrawer}
				onClose={closeTemplateEditDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
				hideBackdrop
			>
				<Box className={classes.addDialogContainer}>
					<EditTemplate
						onCloseEditTemplateDrawer={closeTemplateEditDrawer}
						templateId={templateId}
						templateFolderId={templateFolderId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/* Delete template dialog */}
			<DeleteDialog
				title={`Are you sure you want to delete template - ${templateData?.name}?`}
				subtitle="This will delete the template with all the metrics associated with it"
				open={showTemplateDeleteDialog}
				onCancel={closeTemplateDeleteDialog}
				onDelete={handleDeleteEmailTemplate}
				disableDeleteButton={deleteEmailTemplateMutation.isLoading}
			></DeleteDialog>

			{isLoadingTemplateData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
							p={2}
						>
							<Typography fontWeight={600}>
								{templateData?.name}
							</Typography>

							<Stack direction="row" spacing={2}>
								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={handleCloneEmailTemplate}
								>
									{CopyIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={openTemplateEditDrawer}
								>
									{EditIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>

								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={openTemplateDeleteDialog}
								>
									{DeleteIcon(
										20,
										20,
										theme.palette.error.main
									)}
								</IconButton>

								<Divider orientation="vertical" flexItem />

								<IconButton
									onClick={onCloseTemplateViewer}
									size="small"
									style={{ padding: 0 }}
								>
									{CloseIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>
						</Stack>

						<Divider />

						<Stack direction="row" spacing={2} px={2} py={1.5}>
							<Typography fontSize={14}>Sub:</Typography>

							<Typography fontSize={14}>
								{templateData?.subject
									? templateData.subject
									: "(No Subject)"}
							</Typography>
						</Stack>
					</Box>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box p={1.5}>
							<Markup
								content={getTemplateContent(
									templateData?.content
								)}
							/>
						</Box>
					</Box>

					{templateData?.tags ? (
						<Box>
							<Divider />

							<Box p={3}>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1.5}
								>
									{templateData?.tags?.map((tag) => (
										<Box
											key={tag.id}
											textAlign="center"
											borderRadius={2}
											bgcolor="rgba(51, 188, 126, 0.12)"
											px={0.6}
											py={0.5}
										>
											<Typography fontSize={14}>
												{tag.name}
											</Typography>
										</Box>
									))}
								</Stack>
							</Box>
						</Box>
					) : null}
				</Box>
			)}
		</React.Fragment>
	);
}
