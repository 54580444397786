import React from "react";
import twozoLogo from "../../assets/images/logo/twozo-full.png";
import googlePlay from "../../assets/images/socialMedia/googlePlay.png";
import appStore from "../../assets/images/socialMedia/appStore.png";
import facebook from "../../assets/images/socialMedia/facebook.png";
import twitter from "../../assets/images/socialMedia/twitter.png";
import linkedin from "../../assets/images/socialMedia/linkedin.png";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";

export default function PrivacyPolicy() {
	const theme = useTheme();

	const linkedinUrl =
		"https://www.linkedin.com/company/82331514/admin/feed/posts/";
	const privacyPolicyData = [
		{
			title: "What we collect:",
			subTitle: "When you use Twozo, we may collect",
			body: [
				{ content: "Your email details (like name, email address)." },
				{ content: "Usage data for app functionality." },
			],
		},
		{
			title: "How we use your information:",
			subTitle: "We use this data to",
			body: [
				{ content: "Connect your email to Twozo for seamless use." },
				{ content: "Sync your calendar for better activity planning." },
				{ content: "Manage your contacts within Twozo." },
			],
		},
		{
			title: "Keeping Your data safe:",
			body: [
				{
					content:
						"Your security matters. We take steps to protect your information from unauthorized access.",
				},
			],
		},
		{
			title: "Third-Party links:",
			body: [
				{
					content:
						"Twozo may have links to other websites. They have their own privacy policies, and we're not responsible for how they handle data.",
				},
			],
		},
		{
			title: "Changes to this policy:",
			body: [
				{
					content:
						"We might update this policy. Any changes will be posted here.",
				},
			],
		},
		{
			title: "Questions?",
			body: [
				{
					content: "If you have concerns, reach out to us at ",
					link: "crmnext@thelaunchclub.in",
					url: "mailto:crmnext@thelaunchclub.in",
				},
				{ content: "Last updated [9 Dec 2023]." },
			],
		},
	];

	const styles = {
		socialMediaLogoContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "50px",
			width: "20px",
			height: "20px",
			cursor: "pointer",
			opacity: 0.6,
		},
	};

	return (
		<React.Fragment>
			<Box
				style={{
					minHeight: "100vh",
					backgroundColor: "#FAFAFA",
					padding: "40px",
				}}
			>
				<Box
					p={5}
					style={{
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "100%",
					}}
				>
					<Stack spacing={3}>
						<img
							alt="twozo"
							src={twozoLogo}
							style={{ width: "148px", height: "47px" }}
						/>

						<Typography fontSize={32} fontWeight={600}>
							Privacy Policy
						</Typography>

						<Stack spacing={0.5}>
							<Typography
								fontSize={18}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Welcome to Twozo CRM!
							</Typography>

							<Typography fontSize={16}>
								This policy explains how we handle your
								information.
							</Typography>
						</Stack>
					</Stack>

					<Stack spacing={3} pt={4}>
						{privacyPolicyData.map((privacyPolicy, index) => (
							<Stack spacing={0.5} key={index}>
								<Typography
									fontSize={18}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									{privacyPolicy.title}
								</Typography>

								{privacyPolicy.subTitle ? (
									<Typography fontSize={16}>
										{privacyPolicy.subTitle}:
									</Typography>
								) : null}

								{privacyPolicy.body.map((bodyData, index) => (
									<Typography
										key={index}
										pl={0.6}
										fontSize={16}
										sx={{
											"::before": {
												content:
													// eslint-disable-next-line quotes
													'"\\2022"' /* Unicode bullet character */,
												marginRight: 1,
											},
										}}
									>
										{bodyData.content}
										<Typography
											component="a"
											href={bodyData.url}
											fontSize={16}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{bodyData.link}
										</Typography>
									</Typography>
								))}
							</Stack>
						))}
					</Stack>
				</Box>

				<Stack alignItems="center" pt={5}>
					<Stack spacing={1}>
						<Stack direction="row" spacing={3}>
							<img
								alt="googlePlay"
								src={googlePlay}
								style={{ height: "22px", width: "72px" }}
							/>

							<img
								alt="appStore"
								src={appStore}
								style={{ height: "22px", width: "72px" }}
							/>
						</Stack>

						<Typography
							fontSize={10}
							textAlign="center"
							style={{ opacity: 0.6 }}
						>
							Twozo Inc.
						</Typography>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-around"
						>
							<Box
								style={{
									...styles.socialMediaLogoContainer,
									backgroundColor: "#4267B2",
								}}
							>
								<img
									src={facebook}
									alt="facebook"
									width="13px"
									height="13px"
								/>
							</Box>

							<Box
								style={{
									...styles.socialMediaLogoContainer,
									backgroundColor: "#2DAAE1",
								}}
							>
								<img
									src={twitter}
									alt="twitter"
									width="16px"
									height="16px"
								/>
							</Box>

							<Link href={linkedinUrl} target="_blank">
								<Box
									style={{
										...styles.socialMediaLogoContainer,
										backgroundColor: "#225982",
									}}
								>
									<img
										src={linkedin}
										alt="linkedin"
										width="13px"
										height="13px"
									/>
								</Box>
							</Link>
						</Stack>
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
