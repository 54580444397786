import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import NavigationBar from "./NavigationBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Mail() {
	const drawerWidth = 250;
	const navigate = useNavigate();
	const location = useLocation();
	const ref = useRef(null);
	const [topBarHeight, setTopBarHeight] = useState(0);

	useEffect(() => {
		setTopBarHeight(ref.current.getBoundingClientRect().top);
	}, [ref]);

	useEffect(() => {
		if (location.pathname === "/mail") {
			navigate("inbox");
		}
	}, [location, navigate]);

	return (
		<React.Fragment>
			<Box
				ref={ref}
				sx={{
					display: "flex",
					height: `calc(100vh - ${topBarHeight}px)`,
				}}
			>
				<Box sx={{ width: drawerWidth, overflowY: "auto" }}>
					<NavigationBar />
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						width: `calc(100% - ${drawerWidth}px)`,
						overflowY: "auto",
					}}
				>
					<Box
						style={{
							height: "100%",
						}}
						p={1}
					>
						<Box
							style={{
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								height: "100%",
							}}
						>
							<Outlet />
						</Box>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
