import React from "react";
import { Box, Typography } from "@mui/material";
import BarChart from "./BarChart";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
	Colors,
} from "chart.js";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import HalfDoughnutChart from "./HalfDoughnutChart";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
	Colors
);

ChartJS.defaults.font.family = "Lexend";

export default function Charts(props) {
	const { title, type } = props;

	return (
		<React.Fragment>
			<Box
				style={{
					padding: "8px",
					borderRadius: "6px",
					backgroundColor: "#fff",
					height: "380px",
				}}
			>
				<Typography p={1} pb={2}>
					{title}
				</Typography>

				{(function () {
					switch (type) {
						case "bar":
							return <BarChart />;
						case "line":
							return <LineChart />;
						case "pie":
							return <PieChart />;
						case "half_doughnut":
							return <HalfDoughnutChart />;
						default:
							return null;
					}
				})()}
			</Box>
		</React.Fragment>
	);
}
