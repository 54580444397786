import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function FourNotFour() {
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Box style={{ height: "calc(100vh - 300px)" }}>
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100%"
				>
					<Typography fontSize={200}>404</Typography>

					<Typography fontSize={50}>Page not found</Typography>

					<Button onClick={() => navigate(-1)}>
						Go Back to Previous page
					</Button>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
