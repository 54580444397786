import React from "react";
import { Avatar, Box, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CompanyImage from "../../../assets/images/company/companyProfile.png";

export default function CompanyCard(props) {
	const { companyDetails } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<React.Fragment>
			<Box px={2} py={1} sx={{ cursor: "pointer" }}>
				<Stack direction="row" spacing={2} alignItems="center">
					<Avatar
						alt="contact"
						src={CompanyImage}
						sx={{ width: 45, height: 45 }}
					/>

					<Stack>
						<Box
							onClick={() =>
								navigate(
									`/companies/${companyDetails?.id?.toString()}`
								)
							}
						>
							<Typography
								fontSize={18}
								fontWeight={600}
								color={theme.palette.secondary.contrastText}
							>
								{companyDetails?.name}
							</Typography>
						</Box>

						{companyDetails?.website ? (
							<Box>
								<Typography
									fontSize={14}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									{companyDetails?.website}
								</Typography>
							</Box>
						) : null}
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
