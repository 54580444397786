import React from "react";

export default function Mail(
	width = "24",
	height = "24",
	color = "#000",
	opacity = 1
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: `${color}` }}
			viewBox="0 0 32 32"
			opacity={opacity}
		>
			<path d="M5 12c0-1.909.54-3.109 1.33-3.842C7.133 7.412 8.353 7 10 7h12c1.647 0 2.866.412 3.67 1.158C26.46 8.891 27 10.091 27 12v8.4c0 1.909-.541 3.109-1.33 3.842-.803.746-2.023 1.158-3.67 1.158H10c-1.647 0-2.866-.412-3.67-1.158C5.54 23.508 5 22.309 5 20.4V12zm5-7c-1.953 0-3.734.488-5.03 1.692C3.66 7.908 3 9.709 3 12v8.4c0 2.291.659 4.091 1.97 5.308C6.267 26.912 8.047 27.4 10 27.4h12c1.953 0 3.734-.488 5.03-1.692C28.34 24.492 29 22.691 29 20.4V12c0-2.291-.659-4.091-1.97-5.308C25.733 5.488 23.953 5 22 5H10zm12.624 8.381a1 1 0 00-1.248-1.562l-3.755 2.999c-.871.693-2.381.693-3.253 0l-3.743-2.999a1 1 0 00-1.251 1.561l3.746 3.002c1.601 1.274 4.145 1.274 5.746 0l3.757-3.001z"></path>
		</svg>
	);
}
