import {
	Box,
	Divider,
	Grid,
	LinearProgress,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import { twozoStyles } from "../../../../../styles/twozo";
import SummaryFieldLoader from "../SummaryFieldLoader";

export default function DealSummaryLoader(props) {
	const { moduleName } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const menuBarRef = useRef(null);
	const [menuBarEndingPosition, setMenuBarEndingPosition] = useState(0);

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarEndingPosition(
				menuBarRef.current.getBoundingClientRect().top
			);
		}
	}, [menuBarRef]);

	return (
		<React.Fragment>
			<Box
				height={`calc(100vh - ${menuBarEndingPosition}px)`}
				ref={menuBarRef}
			>
				<Box height="100%">
					<Box height="44%">
						<Box sx={{ width: "100%" }}>
							<LinearProgress />
						</Box>

						<Box className={classes.menuBar}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								height="100%"
							>
								<Stack
									direction="row"
									spacing={1.5}
									alignItems="center"
								>
									<Box>
										<Typography
											fontWeight={600}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{moduleName}
										</Typography>
									</Box>

									<Box
										style={{ transform: "rotate(-90deg)" }}
									>
										{DropDownIcon(16, 16)}
									</Box>
								</Stack>
							</Stack>
						</Box>

						<Box px={3}>
							<Stack
								direction="row"
								alignItems="center"
								spacing={3}
							>
								<Skeleton
									variant="rectangular"
									sx={{ borderRadius: "8px" }}
									width="90px"
									height="39px"
								/>

								<Stack spacing={1}>
									<Skeleton width="112px" height="18px" />
									<Skeleton width="180px" height="18px" />
								</Stack>
							</Stack>
						</Box>

						<Box pt={2.5} px={1}>
							<Skeleton
								variant="rectangular"
								sx={{ borderRadius: "8px" }}
								width="100%"
								height="91px"
							/>
						</Box>

						<Box
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Box
								style={{
									overflowX: "hidden",
								}}
							>
								<Stack direction="row" spacing={2} p={3}>
									<SummaryFieldLoader />
								</Stack>
							</Box>

							<Box
								component={"button"}
								style={{
									backgroundColor: "#fff",
									cursor: "pointer",
									border: "none",
								}}
								pl={2}
								pr={1}
							>
								<Box style={{ transform: "rotate(-90deg)" }}>
									{DropDownIcon(20, 20, "#666")}
								</Box>
							</Box>
						</Box>

						<Box px={2}>
							<Divider />
						</Box>
					</Box>

					<Box px={1} height="56%">
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing={1}
							style={{ height: "100%" }}
						>
							<Grid item xs={12} md={9}>
								<Box className={classes.detailsContainer}></Box>
							</Grid>

							<Grid item xs={12} md={3}>
								<Stack spacing={1} height="100%">
									<Box
										className={classes.detailsContainer}
										height="40%"
									></Box>

									<Box
										className={classes.detailsContainer}
										height="25%"
									></Box>

									<Box
										className={classes.detailsContainer}
										height="15%"
									></Box>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
