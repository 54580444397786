import EmailTemplates from "../EmailTemplates";
import { EmailTemplateProvider } from "../EmailTemplates/TemplateContext";

export default function EmailTemplateLanding() {
	return (
		<EmailTemplateProvider>
			<EmailTemplates />
		</EmailTemplateProvider>
	);
}
