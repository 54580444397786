const connectorList = [
	{
		name: "AND",
		value: 1,
	},
	{
		name: "OR",
		value: 2,
	},
];

export default connectorList;
