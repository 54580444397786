import { getDropdownKeyByFieldName, getTableKey } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";
import { sourceType } from "../tableNames";

export const getProductKey = () => {
	return [...getRootKey(), sourceType.PRODUCT]; // Return the product query key
};

// query keys

// query key methods
export const getProductTableKey = () => {
	return getTableKey(sourceType.PRODUCT);
};

export const getProductKeyById = (productId) => {
	return [...getProductKey(), String(productId)];
};

export const getProductDataKey = (productId) => {
	return [...getProductKeyById(productId), "details"];
};

export const getProductSummaryKey = (productId) => {
	return [...getProductKeyById(productId), "summary"];
};

export const getProductSummaryViewKey = (productId, hideEmptyFields) => {
	return [...getProductKeyById(productId), "summary", hideEmptyFields];
};

export const getProductPriceListKey = (productId) => {
	return [...getProductKeyById(productId), "priceTag"];
};

export const getProductPriceTagsDetailsKey = (
	productId,
	currencyValue,
	isTaxInclusive
) => {
	return [
		...getProductKeyById(productId),
		"priceTag",
		currencyValue,
		isTaxInclusive,
	]; // Return the query key dependsOn currrency value
};

export const getProductDropdownKey = () => {
	return [...getDropdownKeyByFieldName("productList")];
};

export const getProductVariationKey = (productId) => {
	return [...getProductKeyById(productId), "variation"];
};

export const getProductVariationListKey = (productId) => {
	return [...getProductKeyById(productId), "variationList"];
};

export const getProductDealListKey = (productId, stateIds) => {
	if (Array.isArray(stateIds) && stateIds.length > 0) {
		return [...getProductKeyById(productId), "product-deal-list", stateIds];
	}
	return [...getProductKeyById(productId), "product-deal-list"];
};
