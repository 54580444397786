const elementList = [
	{
		name: "Subheader",
		value: 1,
	},
	{
		name: "Text",
		value: 2,
	},
];

export default elementList;
