import React from "react";
import Field from "../Field";

export default function Row({ field, subFields, source, summaryFieldId }) {
	const isMultipleInputRow = field.length > 1;
	const getMultipleFieldRow = () => {
		return (
			<React.Fragment>
				<Field
					isHalfField={true}
					field={field[0]}
					subField={subFields[field[0].type]}
					source={source}
					summaryFieldId={summaryFieldId}
				></Field>
				<Field
					isHalfField={true}
					field={field[1]}
					subField={subFields[field[1].type]}
					source={source}
					summaryFieldId={summaryFieldId}
				></Field>
			</React.Fragment>
		);
	};

	const getSingleRowInputField = () => {
		if (Array.isArray(field)) {
			return renderFirstFieldInArray();
		}

		return renderSingleFieldObject();
	};

	const renderFirstFieldInArray = () => {
		return (
			<Field
				field={field[0]}
				subField={subFields[field[0].type]}
				source={source}
				summaryFieldId={summaryFieldId}
			/>
		);
	};

	const renderSingleFieldObject = () => {
		return (
			<Field
				field={field}
				subField={subFields[field.type]}
				source={source}
				summaryFieldId={summaryFieldId}
			/>
		);
	};

	return (
		<React.Fragment>
			{field
				? isMultipleInputRow
					? getMultipleFieldRow()
					: getSingleRowInputField()
				: null}
		</React.Fragment>
	);
}
