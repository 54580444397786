import { assertError } from "../apiHelper";
import { getTableData } from "../table/tableApi";
import { getActivityUrl, getDealUrl } from "../apiConfig";
import { apiClient } from "..";
import postFieldsApi from "../fields/postFieldsApi";
import { modules } from "../../utils/common/ModulesName";

export const createDeal = async (data) => {
	let requestData = getCreateDealRequest(data);
	return await postDealManagementApi(requestData);
};

export const getDealData = async (id) => {
	let requestData = getDealDataRequest(id);
	return await postDealManagementApi(requestData);
};

export const updateExpectedCloseDate = async ({
	dealId,
	expectedCloseDate,
}) => {
	let requestData = getUpdateExpectedCloseDateRequest(
		dealId,
		expectedCloseDate
	);
	return await postDealManagementApi(requestData);
};

export const getDealSummaryData = async (id, hideEmptyFields) => {
	let requestData = getDealSummaryRequest(id, hideEmptyFields);
	return await postDealManagementApi(requestData);
};

export const getDealSwipeableSummaryData = async (id) => {
	let requestData = getDealSwipeableSummaryRequest(id);
	return await postDealManagementApi(requestData);
};

export const updateDealSummary = async (summaryData) => {
	let requestData = updateDealSummaryRequest(summaryData);
	return await postDealManagementApi(requestData);
};

export const getDealsByStage = async (stageId, criteria) => {
	let requestData = getDealsByStageRequest(stageId, criteria);
	return await postDealManagementApi(requestData);
};

export const updateDealStage = async (stageUpdateRequestData) => {
	let requestData = getUpdateDealStageRequest(stageUpdateRequestData);
	return await postDealManagementApi(requestData);
};

export const updateDealPipeline = async ({ dealId, pipelineId }) => {
	let requestData = getUpdateDealPipelineRequest(dealId, pipelineId);
	return await postDealManagementApi(requestData);
};

export const updateDeal = async (data) => {
	let requestData = updateDealFieldRequest(data);
	return await postDealManagementApi(requestData);
};

export const deleteMultipleDeals = async (dealsToDelete) => {
	let requestData = getDeleteMultipleDealRequest(dealsToDelete);
	return await postDealManagementApi(requestData);
};

export const getDealRelatedEntitiesData = async (dealId) => {
	let requestData = getDealRelatedEntitiesRequest(dealId);
	return await postDealManagementApi(requestData);
};

export const getContactsByDeals = async (dealIds) => {
	let requestData = getContactsByDealsRequest(dealIds);
	return await postDealManagementApi(requestData);
};

export const getLinkedProductsToDeal = async (dealId) => {
	let requestData = getLinkedProductsToDealRequest(dealId);
	return await postDealProductManagementApi(requestData);
};

export const assignOwnerToDeals = async ({ dealIds, userId }) => {
	let requestData = assignOwnerRequest(dealIds, userId);
	return await postDealManagementApi(requestData);
};

export const addProductsToDeal = async ({
	dealId,
	currency,
	items,
	taxInclusive,
}) => {
	let requestData = getAddProductsToDealRequest(
		dealId,
		currency,
		items,
		taxInclusive
	);
	return await postDealProductManagementApi(requestData);
};

export const getDealProgressViewData = async (dealId) => {
	let requestData = getDealProgressViewRequest(dealId);
	return await postDealManagementApi(requestData);
};

export const deleteDeal = async (id) => {
	let requestData = getDeleteDealRequest(id);
	return await postDealManagementApi(requestData);
};

export const getDealParticipantDropdownList = async (dealId) => {
	let requestData = getDealParticipantDropdownListRequest(dealId);
	return await postFieldsApi(requestData);
};

export const updateDealStateWithoutPipelineId = async ({
	id,
	dealClosedOn,
	state,
	wonReason,
	lostReason,
}) => {
	let requestData = getUpdateDealStateRequest(
		id,
		dealClosedOn,
		state,
		wonReason,
		lostReason
	);
	return await postDealManagementApi(requestData);
};

export const getDealOverviewData = async (id) => {
	let requestData = getDealOverviewRequest(id);
	return await postDealManagementApi(requestData);
};

export const getDealParticipantsData = async (dealId, pageParam) => {
	let requestData = getDealParticipantsRequest(dealId, pageParam);
	return await postDealParticipantsManagementApi(requestData);
};

export const addParticipant = async ({ dealId, contactId }) => {
	let requestData = getAddParticipantRequest(dealId, contactId);
	return await postDealParticipantsManagementApi(requestData);
};

export const deleteParticipant = async (participantId) => {
	let requestData = getDeleteParticipantRequest(participantId);
	return await postDealParticipantsManagementApi(requestData);
};

export const getDealList = async (searchValue) => {
	let requestData = getDealListRequest(searchValue);
	return await postFieldsApi(requestData);
};

export const getUpcomingActivitiesToDeal = async (dealId) => {
	let requestData = getUpcomingActivitiesRequest(dealId);
	return await postActivityManagementApi(requestData);
};

export const partialUpdateDeal = async (data) => {
	let requestData = partialUpdateDealRequest(data);
	return await postDealManagementApi(requestData);
};

const partialUpdateDealRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getDealOverviewRequest = (id) => {
	return {
		type: "dealOverview",
		data: {
			id: id,
		},
	};
};

const getCreateDealRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getDealSwipeableSummaryRequest = (id) => {
	return {
		type: "getDealSummary",
		data: {
			id: id,
		},
	};
};

const getUpdateExpectedCloseDateRequest = (dealId, expectedCloseDate) => {
	return {
		type: "updateExpectedCloseDate",
		data: {
			id: dealId,
			expectedCloseDate: expectedCloseDate,
		},
	};
};

const getDealDataRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const updateDealSummaryRequest = (summaryData) => {
	return {
		type: "update",
		data: summaryData,
	};
};

const getDealSummaryRequest = (id, hideEmptyFields) => {
	return {
		type: "summary",
		data: {
			id: id,
			isHidden: hideEmptyFields,
		},
	};
};

const getDealsByStageRequest = (stageId, criteria) => {
	return {
		type: "getDealsByStage",
		data: {
			pipelineStageId: stageId,
			needSummary: true,
			...criteria,
		},
	};
};

const updateDealFieldRequest = (data) => {
	return {
		type: "pBulkUpdate",
		data: {
			list: data,
		},
	};
};

const getDeleteMultipleDealRequest = (dealsToDelete) => {
	return {
		type: "bDelete",
		data: {
			ids: dealsToDelete,
		},
	};
};

const getUpdateDealStageRequest = (stageUpdateRequestData) => {
	return {
		type: "updateDealStage",
		data: stageUpdateRequestData,
	};
};

const getUpdateDealPipelineRequest = (dealId, pipelineId) => {
	return {
		type: "updatePipeline",
		data: {
			id: dealId,
			pipelineId: pipelineId,
		},
	};
};

const getDealProgressViewRequest = (dealId) => {
	return {
		type: "getDealProgress",
		data: {
			id: dealId,
		},
	};
};

const getDealParticipantsRequest = (dealId, pageParam) => {
	return {
		type: "getParticipantsByDeal",
		data: {
			id: dealId,
			...pageParam,
		},
	};
};

const getDealRelatedEntitiesRequest = (dealId) => {
	return {
		type: "relatedEntities",
		data: {
			id: dealId,
		},
	};
};

const getContactsByDealsRequest = (dealIds) => {
	return {
		type: "getContactsByDeals",
		data: {
			ids: dealIds,
		},
	};
};

const getLinkedProductsToDealRequest = (dealId) => {
	return {
		type: "getLinkedProducts",
		data: {
			dealId: dealId,
			isPartial: false,
		},
	};
};

const getUpdateDealStateRequest = (
	id,
	dealClosedOn,
	state,
	wonReason,
	lostReason
) => {
	return {
		type: "updateDealState",
		data: {
			id: id,
			state: state,
			dealClosedOn: dealClosedOn,
			wonReason: wonReason,
			lostReason: lostReason,
		},
	};
};

const getAddParticipantRequest = (dealId, contactId) => {
	return {
		type: "create",
		data: {
			dealId: dealId,
			contactId: contactId,
		},
	};
};

const getDeleteParticipantRequest = (participantId) => {
	return {
		type: "delete",
		data: {
			id: participantId,
		},
	};
};

const getDeleteDealRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getDealParticipantDropdownListRequest = (dealId) => {
	return {
		type: "dropdownData",
		data: {
			source: modules.DEAL,
			name: "nonParticipantList",
			dealId: dealId,
		},
	};
};

const getAddProductsToDealRequest = (dealId, currency, items, taxInclusive) => {
	return {
		type: "update",
		data: {
			dealId: dealId,
			currency: currency,
			items: items,
			taxInclusive: taxInclusive,
		},
	};
};

const getDealListRequest = (searchValue = "") => {
	return {
		type: "dropdownData",
		data: {
			name: "dealList",
			criteria: {
				type: "startsWith",
				value: searchValue,
			},
		},
	};
};

const assignOwnerRequest = (dealIds, userId) => {
	return {
		type: "bAssignOwner",
		data: {
			ids: dealIds,
			userId: userId,
		},
	};
};

const getUpcomingActivitiesRequest = (dealId) => {
	return {
		type: "upcomingActivities",
		data: {
			dealId: dealId,
		},
	};
};

const postDealManagementApi = async (requestData) => {
	let dealManagementUrl = getDealManagementUrl();
	let response = await apiClient.post(dealManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postDealParticipantsManagementApi = async (requestData) => {
	let dealParticipantsUrl = getDealParticipantsUrl();
	let response = await apiClient.post(dealParticipantsUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postDealProductManagementApi = async (requestData) => {
	let dealProductManagementUrl = getDealProductManagementUrl();
	let response = await apiClient.post(dealProductManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postActivityManagementApi = async (requestData) => {
	let activityManagementUrl = getActivityManagementUrl();
	let response = await apiClient.post(activityManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

export const getDealTableData = async (fieldData) => {
	let listUrl = getDealListUrl();
	let config = {
		url: listUrl,
		provider: "DEAL_LIST",
		fields: parseSelectFieldsForApi(fieldData),
	};

	let response = await getTableData(config);
	return response;
};

const parseSelectFieldsForApi = () => {
	let fields = ["id", "title", "stage", "value", "expectedCloseDate"];
	return fields;
};

const getDealManagementUrl = () => {
	return getDealUrl() + "/mgmt";
};

const getDealProductManagementUrl = () => {
	return getDealUrl() + "/product/mgmt";
};

const getDealListUrl = () => {
	return getDealUrl() + "/list";
};

const getDealParticipantsUrl = () => {
	return getDealUrl() + "/participant/mgmt";
};

const getActivityManagementUrl = () => {
	return getActivityUrl() + "/mgmt";
};
