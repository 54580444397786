import { apiClient } from "../..";
import { getDealUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getDealFieldList = async () => {
	let requestData = getDealFieldListRequest();
	return await postDealMergeManagementApi(requestData);
};

export const getMergeDealDetails = async (dealId) => {
	let requestData = getMergeDealDetailsRequest(dealId);
	return await postDealMergeManagementApi(requestData);
};

export const getDealMergePreviewData = async (
	primaryDealId,
	secondaryDealId
) => {
	let requestData = getDealMergePreviewRequest(
		primaryDealId,
		secondaryDealId
	);
	return await postDealMergeManagementApi(requestData);
};

export const mergeDeal = async ({ primaryDealId, secondaryDealId }) => {
	let requestData = getDealMergeRequest(primaryDealId, secondaryDealId);
	return await postDealMergeManagementApi(requestData);
};

const getDealFieldListRequest = () => {
	return {
		type: "getDealFields",
		data: {},
	};
};

const getMergeDealDetailsRequest = (dealId) => {
	return {
		type: "getDeal",
		data: {
			id: dealId,
		},
	};
};

const getDealMergePreviewRequest = (primaryDealId, secondaryDealId) => {
	return {
		type: "preview",
		data: {
			primaryId: primaryDealId,
			secondaryId: secondaryDealId,
		},
	};
};

const getDealMergeRequest = (primaryDealId, secondaryDealId) => {
	return {
		type: "merge",
		data: {
			primaryId: primaryDealId,
			secondaryId: secondaryDealId,
		},
	};
};

const postDealMergeManagementApi = async (requestData) => {
	let dealMergeManagementUrl = getDealMergeManagementUrl();
	let response = await apiClient.post(dealMergeManagementUrl, requestData);
	assertError(response, dealMergeManagementUrl);
	return response.data.data;
};

const getDealMergeManagementUrl = () => {
	return getDealUrl() + "/merge/mgmt";
};
