const timeZoneList = [
	{
		name: "(GMT-11:00) American Samoa",
		zoneId: "Pacific/Pago_Pago",
	},
	{
		name: "(GMT-11:00) International Date Line West/Midway Island",
		zoneId: "Pacific/Midway",
	},
	{
		name: "(GMT-10:00) Hawaii",
		zoneId: "Pacific/Honolulu",
	},
	{
		name: "(GMT-08:00) Alaska",
		zoneId: "America/Anchorage",
	},
	{
		name: "(GMT-07:00) Pacific Time (US & Canada)",
		zoneId: "America/Los_Angeles",
	},
	{
		name: "(GMT-07:00) Tijuana",
		zoneId: "America/Tijuana",
	},
	{
		name: "(GMT-07:00) Arizona",
		zoneId: "America/Phoenix",
	},
	{
		name: "(GMT-07:00) Mazatlan",
		zoneId: "America/Mazatlan",
	},
	{
		name: "(GMT-06:00) Mountain Time (US & Canada)",
		zoneId: "America/Denver",
	},
	{
		name: "(GMT-06:00) Central America",
		zoneId: "America/Guatemala",
	},
	{
		name: "(GMT-05:00) Central Time (US & Canada)",
		zoneId: "America/Chicago",
	},
	{
		name: "(GMT-06:00) Chihuahua",
		zoneId: "America/Chihuahua",
	},
	{
		name: "(GMT-06:00) Guadalajara/Mexico City",
		zoneId: "America/Mexico_City",
	},
	{
		name: "(GMT-06:00) Monterrey",
		zoneId: "America/Monterrey",
	},
	{
		name: "(GMT-06:00) Saskatchewan",
		zoneId: "America/Regina",
	},
	{
		name: "(GMT-05:00) Bogota",
		zoneId: "America/Bogota",
	},
	{
		name: "(GMT-04:00) Eastern Time (US & Canada)",
		zoneId: "America/New_York",
	},
	{
		name: "(GMT-04:00) Indiana (East)",
		zoneId: "America/Indiana/Indianapolis",
	},
	{
		name: "(GMT-05:00) Lima",
		zoneId: "America/Lima",
	},
	{
		name: "(GMT-05:00) Quito",
		zoneId: "America/Guayaquil",
	},
	{
		name: "(GMT-03:00) Atlantic Time (Canada)",
		zoneId: "America/Halifax",
	},
	{
		name: "(GMT-04:00) Caracas",
		zoneId: "America/Caracas",
	},
	{
		name: "(GMT-04:00) Georgetown",
		zoneId: "America/Guyana",
	},
	{
		name: "(GMT-04:00) La Paz",
		zoneId: "America/La_Paz",
	},
	{
		name: "(GMT-04:00) Santiago",
		zoneId: "America/Santiago",
	},
	{
		name: "(GMT-02:30) Newfoundland",
		zoneId: "America/St_Johns",
	},
	{
		name: "(GMT-03:00) Brasilia",
		zoneId: "America/Sao_Paulo",
	},
	{
		name: "(GMT-03:00) Buenos Aires",
		zoneId: "America/Argentina/Buenos_Aires",
	},
	{
		name: "(GMT-03:00) Montevideo",
		zoneId: "America/Montevideo",
	},
	{
		name: "(GMT-02:00) Greenland",
		zoneId: "America/Godthab",
	},
	{
		name: "(GMT-02:00) Mid-Atlantic",
		zoneId: "Atlantic/South_Georgia",
	},
	{
		name: "(GMT+00:00) Azores",
		zoneId: "Atlantic/Azores",
	},
	{
		name: "(GMT-01:00) Cape Verde Is.",
		zoneId: "Atlantic/Cape_Verde",
	},
	{
		name: "(GMT+00:00) Casablanca",
		zoneId: "Africa/Casablanca",
	},
	{
		name: "(GMT+01:00) Dublin",
		zoneId: "Europe/Dublin",
	},
	{
		name: "(GMT+01:00) London/Edinburgh",
		zoneId: "Europe/London",
	},
	{
		name: "(GMT+01:00) Lisbon",
		zoneId: "Europe/Lisbon",
	},
	{
		name: "(GMT+00:00) Monrovia",
		zoneId: "Africa/Monrovia",
	},
	{
		name: "(GMT+00:00) UTC",
		zoneId: "UTC",
	},
	{
		name: "(GMT+02:00) Amsterdam",
		zoneId: "Europe/Amsterdam",
	},
	{
		name: "(GMT+02:00) Belgrade",
		zoneId: "Europe/Belgrade",
	},
	{
		name: "(GMT+02:00) Berlin",
		zoneId: "Europe/Berlin",
	},
	{
		name: "(GMT+02:00) Bern",
		zoneId: "Europe/Zurich",
	},
	{
		name: "(GMT+02:00) Bratislava",
		zoneId: "Europe/Bratislava",
	},
	{
		name: "(GMT+02:00) Brussels",
		zoneId: "Europe/Brussels",
	},
	{
		name: "(GMT+02:00) Budapest",
		zoneId: "Europe/Budapest",
	},
	{
		name: "(GMT+02:00) Copenhagen",
		zoneId: "Europe/Copenhagen",
	},
	{
		name: "(GMT+02:00) Ljubljana",
		zoneId: "Europe/Ljubljana",
	},
	{
		name: "(GMT+02:00) Madrid4",
		zoneId: "Europe/Madrid",
	},
	{
		name: "(GMT+02:00) Paris",
		zoneId: "Europe/Paris",
	},
	{
		name: "(GMT+02:00) Prague",
		zoneId: "Europe/Prague",
	},
	{
		name: "(GMT+02:00) Rome",
		zoneId: "Europe/Rome",
	},
	{
		name: "(GMT+02:00) Sarajevo",
		zoneId: "Europe/Sarajevo",
	},
	{
		name: "(GMT+02:00) Skopje",
		zoneId: "Europe/Skopje",
	},
	{
		name: "(GMT+02:00) Stockholm",
		zoneId: "Europe/Stockholm",
	},
	{
		name: "(GMT+02:00) Vienna",
		zoneId: "Europe/Vienna",
	},
	{
		name: "(GMT+02:00) Warsaw",
		zoneId: "Europe/Warsaw",
	},
	{
		name: "(GMT+01:00) West Central Africa",
		zoneId: "Africa/Lagos",
	},
	{
		name: "(GMT+02:00) Zagreb",
		zoneId: "Europe/Zagreb",
	},
	{
		name: "(GMT+03:00) Athens",
		zoneId: "Europe/Athens",
	},
	{
		name: "(GMT+03:00) Bucharest",
		zoneId: "Europe/Bucharest",
	},
	{
		name: "(GMT+02:00) Cairo",
		zoneId: "Africa/Cairo",
	},
	{
		name: "(GMT+02:00) Harare",
		zoneId: "Africa/Harare",
	},
	{
		name: "(GMT+03:00) Helsinki",
		zoneId: "Europe/Helsinki",
	},
	{
		name: "(GMT+03:00) Jerusalem",
		zoneId: "Asia/Jerusalem",
	},
	{
		name: "(GMT+02:00) Kaliningrad",
		zoneId: "Europe/Kaliningrad",
	},
	{
		name: "(GMT+03:00) Kyiv",
		zoneId: "Europe/Kiev",
	},
	{
		name: "(GMT+02:00) Pretoria",
		zoneId: "Africa/Johannesburg",
	},
	{
		name: "(GMT+03:00) Riga",
		zoneId: "Europe/Riga",
	},
	{
		name: "(GMT+03:00) Sofia",
		zoneId: "Europe/Sofia",
	},
	{
		name: "(GMT+03:00) Tallinn",
		zoneId: "Europe/Tallinn",
	},
	{
		name: "(GMT+03:00) Vilnius",
		zoneId: "Europe/Vilnius",
	},
	{
		name: "(GMT+03:00) Baghdad",
		zoneId: "Asia/Baghdad",
	},
	{
		name: "(GMT+03:00) Istanbul",
		zoneId: "Europe/Istanbul",
	},
	{
		name: "(GMT+03:00) Kuwait",
		zoneId: "Asia/Kuwait",
	},
	{
		name: "(GMT+03:00) Minsk",
		zoneId: "Europe/Minsk",
	},
	{
		name: "(GMT+03:00) Moscow",
		zoneId: "Europe/Moscow",
	},
	{
		name: "(GMT+03:00) Nairobi",
		zoneId: "Africa/Nairobi",
	},
	{
		name: "(GMT+03:00) Riyadh",
		zoneId: "Asia/Riyadh",
	},
	{
		name: "(GMT+03:00) St.Petersburg",
		zoneId: "Europe/Moscow",
	},
	{
		name: "(GMT+03:00) Volgograd",
		zoneId: "Europe/Volgograd",
	},
	{
		name: "(GMT+03:30) Tehran",
		zoneId: "Asia/Tehran",
	},
	{
		name: "(GMT+04:00) Abu Dhabi",
		zoneId: "Asia/Dubai",
	},
	{
		name: "(GMT+04:00) Baku",
		zoneId: "Asia/Baku",
	},
	{
		name: "(GMT+04:00) Muscat",
		zoneId: "Asia/Muscat",
	},
	{
		name: "(GMT+04:00) Samara",
		zoneId: "Europe/Samara",
	},
	{
		name: "(GMT+04:00) Tbilisi",
		zoneId: "Asia/Tbilisi",
	},
	{
		name: "(GMT+04:00) Yerevan",
		zoneId: "Asia/Yerevan",
	},
	{
		name: "(GMT+04:30) Kabul",
		zoneId: "Asia/Kabul",
	},
	{
		name: "(GMT+05:00) Ekaterinburg",
		zoneId: "Asia/Yekaterinburg",
	},
	{
		name: "(GMT+05:00) Karachi/Islamabad",
		zoneId: "Asia/Karachi",
	},
	{
		name: "(GMT+05:00) Tashkent",
		zoneId: "Asia/Tashkent",
	},
	{
		name: "(GMT+05:30) New Delhi/Kolkata/Mumbai/Chennai",
		zoneId: "Asia/Calcutta",
	},
	{
		name: "(GMT+05:30) Sri Jayawardenepura",
		zoneId: "Asia/Colombo",
	},
	{
		name: "(GMT+05:45) Kathmandu",
		zoneId: "Asia/Kathmandu",
	},
	{
		name: "(GMT+06:00) Almaty",
		zoneId: "Asia/Almaty",
	},
	{
		name: "(GMT+06:00) Astana",
		zoneId: "Asia/Almaty",
	},
	{
		name: "(GMT+06:00) Dhaka",
		zoneId: "Asia/Dhaka",
	},
	{
		name: "(GMT+06:00) Urumqi",
		zoneId: "Asia/Urumqi",
	},
	{
		name: "(GMT+06:30) Rangoon",
		zoneId: "Asia/Yangon",
	},
	{
		name: "(GMT+07:00) Bangkok",
		zoneId: "Asia/Bangkok",
	},
	{
		name: "(GMT+07:00) Hanoi",
		zoneId: "Asia/Ho_Chi_Minh",
	},
	{
		name: "(GMT+07:00) Jakarta",
		zoneId: "Asia/Jakarta",
	},
	{
		name: "(GMT+07:00) Krasnoyarsk",
		zoneId: "Asia/Krasnoyarsk",
	},
	{
		name: "(GMT+07:00) Novosibirsk",
		zoneId: "Asia/Novosibirsk",
	},
	{
		name: "(GMT+08:00) Beijing/Chongqing",
		zoneId: "Asia/Shanghai",
	},
	{
		name: "(GMT+08:00) Hong Kong",
		zoneId: "Asia/Hong_Kong",
	},
	{
		name: "(GMT+08:00) Irkutsk",
		zoneId: "Asia/Irkutsk",
	},
	{
		name: "(GMT+08:00) Kuala Lumpur",
		zoneId: "Asia/Kuala_Lumpur",
	},
	{
		name: "(GMT+08:00) Perth",
		zoneId: "Australia/Perth",
	},
	{
		name: "(GMT+08:00) Singapore",
		zoneId: "Asia/Singapore",
	},
	{
		name: "(GMT+08:00) Taipei",
		zoneId: "Asia/Taipei",
	},
	{
		name: "(GMT+08:00) Ulaanbaatar",
		zoneId: "Asia/Ulaanbaatar",
	},
	{
		name: "(GMT+09:00) Tokyo/Osaka/Sapporo",
		zoneId: "Asia/Tokyo",
	},
	{
		name: "(GMT+09:00) Seoul",
		zoneId: "Asia/Seoul",
	},
	{
		name: "(GMT+09:00) Yakutsk",
		zoneId: "Asia/Yakutsk",
	},
	{
		name: "(GMT+09:30) Adelaide",
		zoneId: "Australia/Adelaide",
	},
	{
		name: "(GMT+09:30) Darwin",
		zoneId: "Australia/Darwin",
	},
	{
		name: "(GMT+10:00) Brisbane",
		zoneId: "Australia/Brisbane",
	},
	{
		name: "(GMT+10:00) Canberra",
		zoneId: "Australia/Sydney",
	},
	{
		name: "(GMT+10:00) Guam",
		zoneId: "Pacific/Guam",
	},
	{
		name: "(GMT+10:00) Hobart",
		zoneId: "Australia/Hobart",
	},
	{
		name: "(GMT+10:00) Melbourne",
		zoneId: "Australia/Melbourne",
	},
	{
		name: "(GMT+10:00) Port Moresby",
		zoneId: "Pacific/Port_Moresby",
	},
	{
		name: "(GMT+10:00) Sydney",
		zoneId: "Australia/Sydney",
	},
	{
		name: "(GMT+10:00) Vladivostok",
		zoneId: "Asia/Vladivostok",
	},
	{
		name: "(GMT+11:00) Magadan",
		zoneId: "Asia/Magadan",
	},
	{
		name: "(GMT+11:00) New Caledonia",
		zoneId: "Pacific/Noumea",
	},
	{
		name: "(GMT+11:00) Solomon Is.",
		zoneId: "Pacific/Guadalcanal",
	},
	{
		name: "(GMT+11:00) Srednekolymsk",
		zoneId: "Asia/Srednekolymsk",
	},
	{
		name: "(GMT+12:00) Auckland",
		zoneId: "Pacific/Auckland",
	},
	{
		name: "(GMT+12:00) Fiji",
		zoneId: "Pacific/Fiji",
	},
	{
		name: "(GMT+12:00) Kamchatka",
		zoneId: "Asia/Kamchatka",
	},
	{
		name: "(GMT+12:00) Marshall Is.",
		zoneId: "Pacific/Majuro",
	},
	{
		name: "(GMT+12:00) Wellington",
		zoneId: "Pacific/Auckland",
	},
	{
		name: "(GMT+12:45) Chatham Is.",
		zoneId: "Pacific/Chatham",
	},
	{
		name: "(GMT+13:00) Nuku'alofa",
		zoneId: "Pacific/Tongatapu",
	},
	{
		name: "(GMT+13:00) Samoa",
		zoneId: "Pacific/Apia",
	},
	{
		name: "(GMT+13:00) Tokelau Is.",
		zoneId: "Pacific/Fakaofo",
	},
];

export default timeZoneList;
