import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import TimeRangeFieldBaseComponent from "./TimeRangeFieldBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

const textFieldStyle = {
	width: "100%",
};

export default function TimeRangeFieldComponent({ field, subFields }) {
	const {
		control,
		formState: { errors },
		clearErrors,
	} = useFormContext();

	const [timeRangeValue, setTimeRangeValue] = useState({});

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromField = getSubFieldObject("FROM");
	const toField = getSubFieldObject("TO");

	const handleTimeRangeValueChange = (newTimeRangeValue, onChange) => {
		setTimeRangeValue({ ...newTimeRangeValue });
		let timeRangeValueObjectWithId = {};
		timeRangeValueObjectWithId[fromField.id] = newTimeRangeValue.from;
		timeRangeValueObjectWithId[toField.id] = newTimeRangeValue.to;

		let getTimeRangeValue = () => {
			if (
				timeRangeValueObjectWithId[fromField.id] &&
				timeRangeValueObjectWithId[toField.id]
			) {
				return timeRangeValueObjectWithId;
			}

			return "";
		};
		onChange(getTimeRangeValue());
	};

	const onClearError = () => {
		clearErrors(field.id + "");
	};

	return (
		<React.Fragment>
			<Stack direction="column" spacing={0.5} width="100%">
				<Controller
					name={field.id + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...renderField } }) => (
						<TimeRangeFieldBaseComponent
							{...renderField}
							value={timeRangeValue}
							field={field}
							onChange={(data) =>
								handleTimeRangeValueChange(data, onChange)
							}
							textFieldStyle={textFieldStyle}
							onClearError={onClearError}
							error={errors[field.id]}
						/>
					)}
					defaultValue=""
				></Controller>
			</Stack>
		</React.Fragment>
	);
}
