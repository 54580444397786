import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getUserListKey,
	getUserDataKey,
	getUserTableDataWithSortKey,
	getUserSummaryKey,
	getUserAuditLogKey,
	getUserToDoAcitivityKey,
	getUserCompletedActivityKey,
	getUserDetailsKey,
	getUserTableDataKey,
} from "../../../../utils/queryKeys/userManagement/user";
import {
	activateUser,
	addUser,
	deactivateUser,
	getAuditlog,
	getUserData,
	getUserDetailsList,
	getUserList,
	getUserSummaryData,
	getUserTableData,
	transferUserRecords,
	updateUser,
	userPartialBulkUpdate,
	userReinvite,
} from "../../../../api/userManagement/user/userApi";
import { getUserActivities } from "../../../../api/activity/activityApi";
import {
	completedActivityListPageSize,
	todoActivityListPageSize,
} from "../../../../utils/queryConstants/summary/activity";
import { auditLogPageSize } from "../../../../utils/queryConstants/userManagement/User";
import { userListPageSize } from "../../../../utils/queryConstants/userManagement/User";

const useUserTableData = (stateId, searchValue, fieldToSort, enabled) => {
	return useInfiniteQuery({
		queryKey: getUserTableDataWithSortKey(
			stateId,
			searchValue,
			fieldToSort
		),
		queryFn: ({ pageParam }) => {
			return getUserTableData(stateId, searchValue, fieldToSort, {
				start: pageParam ? (pageParam - 1) * userListPageSize + 1 : 1,
				limit: userListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: enabled,
	});
};

const useUserDetailsList = () => {
	let userDetailsListKey = getUserDetailsKey();
	return useQuery(userDetailsListKey, () => getUserDetailsList(), {
		select: (data) => data.users,
	});
};

const useUserList = () => {
	return useQuery(getUserListKey(), () => getUserList(), {
		select: (userList) => userList?.options,
	});
};

const useUserData = (id) => {
	return useQuery(getUserDataKey(id), () => getUserData(id));
};

const useUserSummaryData = (id) => {
	return useQuery(getUserSummaryKey(id), () => getUserSummaryData(id));
};

const useAuditlogData = (id) => {
	return useInfiniteQuery({
		queryKey: getUserAuditLogKey(id),
		queryFn: ({ pageParam }) => {
			return getAuditlog(id, {
				start: pageParam ? (pageParam - 1) * auditLogPageSize + 1 : 1,
				limit: auditLogPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
	});
};

const activitiesSelect = (todoActivityData) => {
	return todoActivityData.pages.flatMap((page) => page?.activities);
};

const useUserToDoActivities = (id) => {
	return useInfiniteQuery({
		queryKey: getUserToDoAcitivityKey(id),
		queryFn: ({ pageParam }) => {
			return getUserActivities(id, false, {
				start: pageParam
					? (pageParam - 1) * todoActivityListPageSize + 1
					: 1,
				limit: todoActivityListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

const useUserCompletedActivties = (id) => {
	return useInfiniteQuery({
		queryKey: getUserCompletedActivityKey(id),
		queryFn: ({ pageParam }) => {
			return getUserActivities(id, true, {
				start: pageParam
					? (pageParam - 1) * completedActivityListPageSize + 1
					: 1,
				limit: completedActivityListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

const useInvalidateUserList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getUserTableDataKey());
	};
};

const useInvalidateUserDropdownList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getUserListKey());
	};
};

const useUserActivateMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(activateUser, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useAddUserMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	return useMutation(addUser, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useUpdateUserMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	return useMutation(updateUser, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useUserPartialBulkUpdate = () => {
	let invalidateUserList = useInvalidateUserList();
	return useMutation(userPartialBulkUpdate, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useTransferUserRecordMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(transferUserRecords, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useDeactivateUserMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(deactivateUser, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useUserReinviteMutation = () => {
	return useMutation(userReinvite);
};

export {
	useUserTableData,
	useUserList,
	useAddUserMutation,
	useUserData,
	useUpdateUserMutation,
	useUserSummaryData,
	useAuditlogData,
	useUserToDoActivities,
	useUserCompletedActivties,
	useUserPartialBulkUpdate,
	useUserDetailsList,
	useTransferUserRecordMutation,
	useDeactivateUserMutation,
	useUserReinviteMutation,
	useUserActivateMutation,
};
