import { Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";

export default function Address(props) {
	const { highlightText, address } = props;

	return (
		<React.Fragment>
			<Typography width="85%" style={styles.subText} noWrap>
				Address: {highlightText(address)}
			</Typography>
		</React.Fragment>
	);
}
