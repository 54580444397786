import React from "react";
import AddForm from "../../Elements/AddForm";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import { useCreateMutation } from "../../../hooks/services/contact";
import { useContactFieldPermissions } from "../../../hooks/modules/auth/contact";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import { useModuleName } from "../../../hooks/modules";

export default function AddContact(props) {
	const {
		onClose,
		setIsDuplicateCheckEnabled,
		checkContactDuplicates,
		formData,
	} = props;

	const { getModuleName } = useModuleName();
	const moduleName = modules.CONTACT;
	const formHeaderLabel = `Add ${getModuleName(moduleName)}`;

	const createMutation = useCreateMutation();
	const contactFieldPermissions = useContactFieldPermissions();

	const { isUserAllowedFor } = useAuth();
	const hasMergeRecordPermission = isUserAllowedFor(
		PERMISSIONS.contact.mergeRecords
	);

	const createNewContact = (formSubmitData, shouldCloseForm) => {
		removeFieldsWithEmptyValues(formSubmitData);
		createMutation.mutate(formSubmitData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactAdded,
				});
				shouldCloseForm && onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={contactFieldPermissions}>
				<AddForm
					moduleName={moduleName}
					formData={formData}
					onClose={onClose}
					setIsDuplicateCheckEnabled={setIsDuplicateCheckEnabled}
					showCheckDuplicates={hasMergeRecordPermission}
					isSaving={createMutation.isLoading}
					onSave={createNewContact}
					onDuplicateCheck={checkContactDuplicates}
					formHeaderLabel={formHeaderLabel}
				></AddForm>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
