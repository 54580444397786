import React, { useState } from "react";
import {
	default as MuiAutocomplete,
	createFilterOptions,
} from "@mui/material/Autocomplete";
import {
	Box,
	Chip,
	InputAdornment,
	Paper,
	Popper,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../../../assets/icons/close";
import { default as AddIcon } from "../../../../../../../../assets/icons/add";
import { default as ContactIcon } from "../../../../../../../../assets/icons/contact";
import { default as ContactImage } from "../../../../../../../../assets/images/contact/unknownContact.png";
import { useAuth } from "../../../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../../../utils/Auth";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

export default function RelatedContactInput(props) {
	const { onChange, onDelete, error, clearErrors, placeHolder, ...other } =
		props;
	const theme = useTheme();

	const [inputValue, setInputValue] = useState("");

	const { isUserAllowedFor } = useAuth();
	const isAllowToAddNewOption = isUserAllowedFor(PERMISSIONS.contact.create);

	const CustomPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					display: inputValue ? "block" : "none",
				}}
			/>
		);
	};

	const filteredValues = createFilterOptions({
		stringify: (option) => option.name,
	});

	const filterOptions = (options, params) => {
		const { inputValue } = params;
		const filteredOption = filteredValues(options, params);
		const isExisting = options.some((option) => inputValue === option.name);

		if (inputValue !== "" && !isExisting && isAllowToAddNewOption) {
			filteredOption.push({
				name: inputValue,
				isNewOption: true,
			});
		}
		return filteredOption;
	};

	const handleInputChange = (event) => {
		const { value } = event.target;
		if (value.trim()) {
			setInputValue(value);
		} else {
			setInputValue("");
		}
		clearErrors();
	};

	const onChangeContactValues = (_, values) => {
		onChange(values);
		setInputValue("");
	};

	const renderOption = (option) => {
		if (!option) {
			return (
				<Typography
					fontSize={13}
					fontWeight={400}
					style={{ opacity: 0.6 }}
				>
					No Results Found
				</Typography>
			);
		}

		if (isAllowToAddNewOption && option.isNewOption) {
			return (
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
				>
					<Stack direction="row" spacing={2} alignItems="center">
						<img
							src={ContactImage}
							alt="contact_image"
							height="24px"
							width="24px"
						/>

						<Typography
							fontSize={13}
							fontWeight={500}
							color={theme.palette.primary.main}
						>
							{option?.name}
						</Typography>
					</Stack>

					<Box display="flex">
						{AddIcon(20, 20, theme.palette.primary.main)}
					</Box>
				</Stack>
			);
		}

		return (
			<Stack direction="row" spacing={2} alignItems="center">
				<img
					src={ContactImage}
					alt="contact_image"
					height="24px"
					width="24px"
				/>

				<Typography fontSize={13}>{option?.name}</Typography>
			</Stack>
		);
	};

	return (
		<MuiAutocomplete
			{...other}
			size="small"
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			forcePopupIcon={false}
			multiple
			style={{
				width: "100%",
			}}
			disableClearable
			onChange={onChangeContactValues}
			filterSelectedOptions
			getOptionLabel={(option) => option.name}
			renderTags={(value) =>
				value.map((option, index) => (
					<Chip
						key={index}
						size="small"
						label={
							<Typography fontSize={14} color="#000">
								{option.name}
							</Typography>
						}
						color="secondary"
						deleteIcon={CloseIcon(16, 16, "#000")}
						onDelete={() => onDelete(index)}
						sx={{
							borderRadius: "8px",
							marginRight: "4px",
						}}
					/>
				))
			}
			renderOption={(props, option) => (
				<Box
					key={option?.name}
					{...props}
					style={{
						minHeight: "40px",
						width: "100%",
					}}
				>
					{renderOption(option)}
				</Box>
			)}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={placeHolder || "Enter"}
					value={inputValue}
					onChange={handleInputChange}
					onBlur={() => setInputValue("")}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								<InputAdornment
									position="start"
									style={{
										paddingLeft: "8px",
									}}
								>
									{ContactIcon(
										20,
										20,
										error
											? theme.palette.error.main
											: "#666666"
									)}
								</InputAdornment>
								{params.InputProps.startAdornment}
							</>
						),
					}}
					inputProps={{
						...params.inputProps,
						style: {
							fontSize: "14px",
						},
					}}
					error={!!error}
				/>
			)}
		/>
	);
}
