import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";

export default function Sms(props) {
	const { permissions, isEditable } = props;

	const { permissionState, updateSmsList, initializeSmsListForPermission } =
		usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeSmsListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		const updatedSmsList = permissionState.smsList.map((list) => {
			if (list.id === id) {
				const updatedList = {
					...list,
					access: event.target.checked,
				};

				return updatedList;
			} else {
				return list;
			}
		});
		updateSmsList(updatedSmsList);
	};

	const handleInputChange = (event, id) => {
		const updatedSmsList = permissionState.smsList.map((list) => {
			if (list.id === id) {
				const updatedList = {
					...list,
					permissions: {
						...list.permissions,
						value:
							event.target.value !== ""
								? parseInt(event.target.value, 10)
								: event.target.value,
					},
				};

				return updatedList;
			} else {
				return list;
			}
		});
		updateSmsList(updatedSmsList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="SMS"
				lists={permissionState.smsList}
				handleAccessChange={handleAccessChange}
				isEditable={isEditable}
				handleInputChange={handleInputChange}
			/>
		</React.Fragment>
	);
}
