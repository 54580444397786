import React from "react";
import { Typography } from "@mui/material";

export default function EmailOpened(props) {
	const { notificationData, textColor = "#000" } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				component="samp"
				style={{ fontSize: "13px", color: textColor, opacity: "0.6" }}
			>
				“
				{notificationData.data.subject
					? notificationData.data.subject
					: "(No Subject)"}
				”
			</Typography>
		</React.Fragment>
	);
}
