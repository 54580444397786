import { adminAccessIds, permissionTypes, type } from "..";

export const getAdminAccessConfigForPermission = (permissions) => {
	return [
		{
			id: adminAccessIds.accessAdminSettings,
			name: "Access Admin Settings",
			access: permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageSalesActivities,
			name: "Manage Sales Activities ",
			access: permissions.manageSalesActivities.access,
			accessDisable: !permissions.accessAdminSettings.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.manageSalesActivities.view,
						disabled: !permissions.manageSalesActivities.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.manageSalesActivities.create,
						disabled: !permissions.manageSalesActivities.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.manageSalesActivities.edit,
						disabled: !permissions.manageSalesActivities.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.manageSalesActivities.delete,
						disabled: !permissions.manageSalesActivities.access,
					},
				],
			},
		},
		{
			id: adminAccessIds.manageSalesForecastCategories,
			name: "Manage Sales Forecast Categories",
			access: permissions.manageSalesForecastCategories.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageWorkFlows,
			name: "Manage Workflows",
			access: permissions.manageWorkFlows.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageTerritories,
			name: "Manage Territories",
			access: permissions.manageTerritories.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageUsers,
			name: "Manage Users",
			access: permissions.manageUsers.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageAllTags,
			name: "Manage All Tags",
			access: permissions.manageAllTags.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.exportAllData,
			name: "Export All Data",
			access: permissions.exportAllData.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.migrateFromACrm,
			name: "Migrate From a CRM",
			access: permissions.migrateFromACrm.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageRoles,
			name: "Manage Roles",
			access: permissions.manageRoles.access,
			accessDisable: !permissions.accessAdminSettings.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.manageRoles.view,
						disabled: !permissions.manageRoles.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.manageRoles.create,
						disabled: !permissions.manageRoles.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.manageRoles.edit,
						disabled: !permissions.manageRoles.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.manageRoles.delete,
						disabled: !permissions.manageRoles.access,
					},
				],
			},
		},
		{
			id: adminAccessIds.manageIntegrationsForChat,
			name: "Manage Integrations For Chat",
			access: permissions.manageIntegrationsForChat.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.manageConversationalChannels,
			name: "Manage Conversational Channels",
			access: permissions.manageConversationalChannels.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
		{
			id: adminAccessIds.managePhoneSettings,
			name: "Manage Phone Settings",
			access: permissions.managePhoneSettings.access,
			accessDisable: !permissions.accessAdminSettings.access,
		},
	];
};
