import React from "react";

function ThumbsUp(width = 24, height = 24, color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
		>
			<path
				d="M7.31247 18.702L10.5416 21.102C10.9583 21.502 11.8958 21.702 12.5208 21.702H16.4791C17.7291 21.702 19.0833 20.802 19.3958 19.602L21.8958 12.302C22.4166 10.902 21.4791 9.70202 19.9166 9.70202H15.75C15.125 9.70202 14.6041 9.20202 14.7083 8.50202L15.2291 5.30202C15.4375 4.40202 14.8125 3.40202 13.875 3.10202C13.0416 2.80202 12 3.20202 11.5833 3.80202L7.31247 9.90202"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path
				d="M2 8.90208C2 7.50208 2.625 7.00208 4.08333 7.00208H5.125C6.58333 7.00208 7.20833 7.50208 7.20833 8.90208V18.7021C7.20833 20.1021 6.58333 20.6021 5.125 20.6021H4.08333C2.625 20.6021 2 20.1021 2 18.7021V8.90208Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ThumbsUp;
