import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useCompanyList } from "../../../../../../../hooks/services/company";
import { default as CompanyIcon } from "../../../../../../../assets/icons/company";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import {
	Autocomplete,
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
} from "@mui/material";
import { useAuth } from "../../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../../utils/Auth";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

export function CompanyBaseComponent(props) {
	const {
		field,
		error,
		clearErrors,
		onChange,
		value,
		inputRef,
		isIconNeeded,
		onChangeFieldValueLabel,
	} = props;

	const theme = useTheme();
	const { isUserAllowedFor } = useAuth();

	const [companyInputValue, setCompanyInputValue] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");

	const isCompanyListEnabled = isUserAllowedFor(PERMISSIONS.company.view);
	const { data: companies } = useCompanyList(isCompanyListEnabled);

	const CustomPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					display: companyInputValue ? "block" : "none",
				}}
			/>
		);
	};

	useEffect(() => {
		if (value) {
			let selectedCompany = companies?.find(
				(company) => company?.value === value
			);
			setSelectedCompany(selectedCompany);
		}
	}, [companies, value]);

	const updateCompanyValue = (companyValue) => {
		setSelectedCompany(companyValue ?? "");
		onChange(companyValue?.value ?? "");
		onChangeFieldValueLabel
			? onChangeFieldValueLabel({
					[field?.id]: companyValue?.name ?? "",
				})
			: null;
	};

	const handleSelectCompany = (_, selectedCompany) => {
		updateCompanyValue(selectedCompany);
	};

	const handleCompanyInputChange = (event) => {
		const { value } = event.target;
		setCompanyInputValue(value);
		clearErrors(field.id);
	};

	const removeCompanyValue = () => {
		setCompanyInputValue("");
		updateCompanyValue();
	};

	return (
		<React.Fragment>
			<Autocomplete
				size="small"
				PopperComponent={CustomPopper}
				PaperComponent={CustomPaper}
				filterSelectedOptions
				sx={{ width: "100%" }}
				readOnly={!!selectedCompany}
				noOptionsText="No Results Found"
				options={companies || []}
				forcePopupIcon={false}
				onChange={handleSelectCompany}
				value={selectedCompany || null}
				renderOption={(props, option) => (
					<Box
						component="li"
						key={option.value}
						{...props}
						style={{ minHeight: "40px" }}
					>
						<Typography fontSize={13} key={option.value}>
							{option.name}
						</Typography>
					</Box>
				)}
				getOptionLabel={(option) => {
					if (option.name) {
						return option.name;
					}
					return option;
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={field?.placeHolder || "Enter"}
						value={companyInputValue}
						onChange={handleCompanyInputChange}
						onBlur={() => setCompanyInputValue("")}
						inputRef={inputRef}
						inputProps={{
							...params.inputProps,
							style: {
								fontSize: "14px",
								paddingLeft: 0,
							},
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment
									position="start"
									style={{
										paddingLeft: isIconNeeded
											? "8px"
											: "0px",
									}}
								>
									{isIconNeeded
										? CompanyIcon(
												20,
												20,
												error
													? theme.palette.error.main
													: "#666666"
											)
										: null}
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									{!!selectedCompany && (
										<IconButton
											onClick={removeCompanyValue}
										>
											{CloseIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
						sx={{
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px",
							},
						}}
						error={!!error}
					/>
				)}
			/>
		</React.Fragment>
	);
}
