import React from "react";

function Reopen(width = 20, height = 20, color = "#33BC7E") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.0579 15.2584H7.39128C5.09128 15.2584 3.22461 13.3917 3.22461 11.0917C3.22461 8.79172 5.09128 6.92505 7.39128 6.92505H16.5579"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.6406 9.00837L16.774 6.87503L14.6406 4.7417"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Reopen;
