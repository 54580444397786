import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
} from "@mui/material";
import ToggleButtonGroup from "../../../../../Elements/ToggleButtonGroup";
import {
	usePlaceHolderFields,
	usePlaceHolderSources,
} from "../../../../../../hooks/services/mail/emailTemplate";
import { getIconByName } from "../../../../../../utils/icon";
import { modules } from "../../../../../../utils/common/ModulesName";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";

export default function PlaceHoldersMenu(props) {
	const { closePlaceHolderMenu, onSelectPlaceHolder } = props;

	const [sourceType, setSourceType] = useState(modules.CONTACT);
	const [searchValue, setSearchValue] = useState("");

	const { data: placeHolderSources } = usePlaceHolderSources();
	const { data: placeHolderFields, isLoading: isPlaceHoldersFieldsLoading } =
		usePlaceHolderFields(sourceType);

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSourceType(type);
		}
	};

	const filteredFields = useMemo(() => {
		if (placeHolderFields?.fields?.length > 0) {
			if (searchValue) {
				return placeHolderFields.fields.filter((field) => {
					return field.displayName
						.toLowerCase()
						.startsWith(searchValue.toLowerCase());
				});
			}
			return placeHolderFields.fields;
		}
		return [];
	}, [placeHolderFields, searchValue]);

	const handleSearchField = (event) => {
		const { value } = event.target;
		setSearchValue(value.trim());
	};

	const renderEmptyView = () => {
		return (
			<Stack
				justifyContent="center"
				alignItems="center"
				height="150px"
				width="100%"
			>
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					No results found
				</Typography>
			</Stack>
		);
	};

	const onClose = () => {
		setSearchValue("");
		closePlaceHolderMenu();
		setSourceType(modules.CONTACT);
	};

	const onSelectfield = (selectedField) => {
		onSelectPlaceHolder(selectedField, sourceType);
		onClose();
	};

	return (
		<React.Fragment>
			<Stack height="350px">
				<Box>
					<Box p={1.5}>
						<Typography fontSize={13} fontWeight={500}>
							Placeholders
						</Typography>
					</Box>

					<Box style={{ textAlign: "center" }}>
						<ToggleButtonGroup
							value={sourceType}
							onChange={handleToggleButtonGroup}
						>
							{placeHolderSources?.map((source) => (
								<ToggleButton
									value={source.sourceName}
									key={source.sourceId}
								>
									{getIconByName(
										source.sourceName.toLowerCase()
									)(
										20,
										20,
										sourceType === source.sourceName
											? "#fff"
											: "#666666"
									)}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Box>

					<Box p={1.5} pb={1} style={{ textAlign: "center" }}>
						<MenuSearchBar
							value={searchValue}
							onChange={handleSearchField}
						/>
					</Box>
				</Box>

				<Box sx={{ overflowY: "auto" }}>
					{isPlaceHoldersFieldsLoading ? (
						<Stack
							justifyContent="center"
							alignItems="center"
							height="200px"
							width="100%"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : filteredFields?.length > 0 ? (
						filteredFields.map((field) => (
							<MenuItem
								key={field.id}
								style={{
									minHeight: "40px",
								}}
								onClick={() => onSelectfield(field)}
							>
								<Typography fontSize={13}>
									{field.displayName}
								</Typography>
							</MenuItem>
						))
					) : (
						renderEmptyView()
					)}
				</Box>
			</Stack>
		</React.Fragment>
	);
}
