import { useQueryClient } from "@tanstack/react-query";
import { getRootKey } from "../../../utils/queryKeys/rootKey";

const useInvalidateApp = () => {
	let queryClient = useQueryClient();
	let rootKey = getRootKey();
	return () => {
		queryClient.invalidateQueries(rootKey);
	};
};

export { useInvalidateApp };
