import { getRootKey } from "../rootKey";

const dataFieldsKey = "dataFields";

// data field query key
const getDataFieldsKey = () => {
	return [...getRootKey(), dataFieldsKey];
};

// query keys
export const fieldTypeKey = [...getDataFieldsKey(), "fieldType"];

// query key methods
export const getDropdownFieldKey = (sourceId) => {
	return [...getDataFieldsKey(), String(sourceId), "fieldDropdown"];
};

export const getAllFieldListSettingsTypeKey = (moduleName) => {
	return [...getDataFieldsKey(), moduleName, "fieldList"];
};

export const getFieldListSettingTypeKey = (
	moduleName,
	needGroupView = false
) => {
	return [...getDataFieldsKey(), moduleName, "fieldList", needGroupView];
};
