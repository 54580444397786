import React from "react";
import EmailList from "../EmailList";
import { mailListName } from "../../../utils/EmailUtils/mailListName";

export default function Sent() {
	const menuConfig = {
		isArchiveEnabled: true,
		isDeleteEnabled: true,
	};

	const filterConfig = {
		isTrackingFilterEnabled: true,
		isLinkingFilterEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Sent"
				mailListName={mailListName.SENT}
				menuConfig={menuConfig}
				filterConfig={filterConfig}
			/>
		</React.Fragment>
	);
}
