import React from "react";
import AddTemplate from "../AddTemplate";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { twozoStyles } from "../../../../styles/twozo";
import {
	useTemplateData,
	useUpdateEmailTemplate,
} from "../../../../hooks/services/mail/emailTemplate";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { CircularProgress, Stack } from "@mui/material";
import Nodes from "../../EmailComposer/EmailComposerUI/Nodes";

export default function EditTemplate(props) {
	const { onCloseEditTemplateDrawer, templateId, templateFolderId } = props;
	const classes = twozoStyles();

	const { data: templateData, isLoading: isLoadingTemplateData } =
		useTemplateData(templateId);
	const updateEmailTemplateMutation = useUpdateEmailTemplate(
		templateFolderId,
		templateId
	);

	const onUpdateEmailTemplate = (templateFormData) => {
		let updatedTemplateData = {
			...templateFormData,
			id: templateId,
			folderId: templateFolderId,
		};
		updateEmailTemplateMutation.mutate(updatedTemplateData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.emailTemplateUpdated,
				});
				onCloseEditTemplateDrawer();
			},
		});
	};

	return (
		<React.Fragment>
			<LexicalComposer
				initialConfig={{
					theme: {
						paragraph: classes.email_paragraph,
						text: {
							bold: classes.email_font_bold,
							italic: classes.email_italic,
							underline: classes.email_underline,
							strikethrough: classes.email_strikethrough,
						},
						quote: classes.email_quote,
					},
					nodes: [...Nodes],
					onError(error) {
						throw error;
					},
				}}
			>
				{isLoadingTemplateData ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<AddTemplate
						onCloseEmailTemplateDrawer={onCloseEditTemplateDrawer}
						onSaveEmailTemplate={onUpdateEmailTemplate}
						templateFormData={templateData}
						isSaving={updateEmailTemplateMutation.isLoading}
						isEditMode={true}
					/>
				)}
			</LexicalComposer>
		</React.Fragment>
	);
}
