import React, { useState } from "react";
import {
	Box,
	Divider,
	MenuItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../../../assets/icons/dropDownCentered";
import { twozoStyles } from "../../../../../../../styles/twozo";
import Menu from "../../../../../../Elements/Menu";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";

export default function Style(props) {
	const { currentFormData, updateFormData } = props;
	const classes = twozoStyles();
	const theme = useTheme();

	const alignments = getDropdownDataByName("ALIGNMENTS");
	const fieldAlignments = getDropdownDataByName("FIELD_ALIGNMENTS");
	const fonts = getDropdownDataByName("FONT_STYLE");

	const [formStyle, setFormStyle] = useState(currentFormData.style.formStyle);
	const [fieldStyle, setFieldStyle] = useState(
		currentFormData.style.fieldStyle
	);
	const [submitButtonStyle, setSubmitButtonStyle] = useState(
		currentFormData.style.submitButtonStyle
	);
	const [isAlignmentHovered, setIsAlignmentHovered] = useState(null);
	const [isSubmitAlignmentHovered, setIsSubmitAlignmentHovered] =
		useState(null);
	const [alignmnetMenu, setAlignmentMenu] = useState(null);
	const isAlignmentMenuOpened = Boolean(alignmnetMenu);

	const [fieldAlignmentMenu, setFieldAlignmetMenu] = useState(null);
	const isFieldAlignmentMenuOpened = Boolean(fieldAlignmentMenu);

	const [isFontStyleHovered, setIsFontStyleHovered] = useState(null);
	const [fontMenu, setFontMenu] = useState(null);
	const isFontMenuOpened = Boolean(fontMenu);

	const [isHeaderFontSizeInEditMode, setIsHeaderFontSizeInEditMode] =
		useState(false);
	const [isSubHeaderFontSizeInEditMode, setIsSubHeaderFontSizeInEditMode] =
		useState(false);
	const [isInputFontSizeInEditMode, setIsInputFontSizeInEditMode] =
		useState(false);
	const [isLabelFontSizeInEditMode, setIsLabelFontSizeInEditMode] =
		useState(false);

	const [isHeaderFontSizeError, setIsHeaderFontSizeError] = useState(false);
	const [isSubHeaderFontSizeError, setIsSubHeaderFontSizeError] =
		useState(false);
	const [isFieldLabelFontSizeError, setIsFieldLabelFontSizeError] =
		useState(false);
	const [isFieldInputFontSizeError, setIsFieldInputFontSizeError] =
		useState(false);

	const valueRange = {
		minValue: 11,
		maxValue: 24,
	};

	const isWithInValidRange = (value) => {
		return (
			parseInt(value) >= valueRange.minValue &&
			parseInt(value) <= valueRange.maxValue
		);
	};

	const handleOnMouseOverAlignment = (event) => {
		setIsAlignmentHovered(event.currentTarget);
	};

	const handleOnMouseOutAlignment = () => {
		setIsAlignmentHovered(null);
	};

	const handleOnMouseOverSubmitAlignment = (event) => {
		setIsSubmitAlignmentHovered(event.currentTarget);
	};

	const handleOnMouseOutSubmitAlignment = () => {
		setIsSubmitAlignmentHovered(null);
	};

	const handleAlignmentMenu = (event) => {
		setAlignmentMenu(event.currentTarget);
	};

	const handleFieldAlignmentMenu = (event) => {
		setFieldAlignmetMenu(event.currentTarget);
	};

	const CloseAlignmentMenu = () => {
		setAlignmentMenu(null);
	};

	const handleChangeAlignment = (id) => {
		const updatedSubmitButtonAlignment = {
			...submitButtonStyle,
			alignmentId: id,
		};
		updateFormData({
			...currentFormData,
			style: {
				...currentFormData.style,
				submitButtonStyle: updatedSubmitButtonAlignment,
			},
		});
		setSubmitButtonStyle(updatedSubmitButtonAlignment);
		setAlignmentMenu(null);
	};

	const handleChangeFieldAlignment = (id) => {
		const updateFormdAlignment = { ...formStyle, alignmentId: id };
		updateFormData({
			...currentFormData,
			style: {
				...currentFormData.style,
				formStyle: updateFormdAlignment,
			},
		});
		setFormStyle(updateFormdAlignment);
		setFieldAlignmetMenu(null);
	};

	const CloseFieldAlignmentMenu = () => {
		setFieldAlignmetMenu(null);
	};

	const handleFontMenu = (event) => {
		setFontMenu(event.currentTarget);
	};

	const onMouseOverFontStyle = (event) => {
		setIsFontStyleHovered(event.currentTarget);
	};

	const onMouseOutFontStyle = () => {
		setIsFontStyleHovered(null);
	};

	const handleChangeFont = (id) => {
		setFormStyle({ ...formStyle, fontId: id });
		updateFormData({
			...currentFormData,
			style: {
				...currentFormData.style,
				formStyle: { ...formStyle, fontId: id },
			},
		});
		setFontMenu(null);
	};

	const CloseFontMenu = () => {
		setFontMenu(null);
	};

	const handleClickHeaderFontSize = () => {
		setIsHeaderFontSizeInEditMode(true);
	};

	const handleClickSubHeaderFontSize = () => {
		setIsSubHeaderFontSizeInEditMode(true);
	};

	const handleClickLabelFontSize = () => {
		setIsLabelFontSizeInEditMode(true);
	};

	const handleClickInputFontSize = () => {
		setIsInputFontSizeInEditMode(true);
	};

	const getAlignmentValue = (id) => {
		const alignmentValue = alignments.find(
			(alignmentItem) => alignmentItem.value === id
		);
		return alignmentValue.name;
	};

	const getFieldAlignmentValue = (id) => {
		const fieldAlignmentValue = fieldAlignments.find(
			(fieldAlignmentItem) => fieldAlignmentItem.value === id
		);
		return fieldAlignmentValue.name;
	};

	const getFontStyleValue = (id) => {
		const fontStyleValue = fonts.find(
			(fontStyle) => fontStyle.value === id
		);
		return fontStyleValue.name;
	};

	const handleBlurFormHeaderFontSize = () => {
		setIsHeaderFontSizeInEditMode(false);
	};

	const handleChangeFormHeaderFontSize = (event) => {
		const { value } = event.target;
		if (isWithInValidRange(value)) {
			const updatedFormHeaderFontSize = {
				...formStyle,
				header: {
					...formStyle.header,
					fontSize: parseInt(value),
				},
			};
			setFormStyle(updatedFormHeaderFontSize);
			updateFormData({
				...currentFormData,
				style: {
					...currentFormData.style,
					formStyle: updatedFormHeaderFontSize,
				},
			});
			setIsHeaderFontSizeError(false);
		} else {
			setIsHeaderFontSizeError(true);
		}
	};

	//Form Sub Header
	const handleBlurFormSubHeaderFontSize = () => {
		setIsSubHeaderFontSizeInEditMode(false);
	};

	const handleChangeFormSubHeaderFontSize = (event) => {
		const { value } = event.target;
		if (isWithInValidRange(value)) {
			const updatedFormSubHeaderFontSize = {
				...formStyle,
				subHeader: {
					...formStyle.subHeader,
					fontSize: parseInt(value),
				},
			};
			setFormStyle(updatedFormSubHeaderFontSize);
			updateFormData({
				...currentFormData,
				style: {
					...currentFormData.style,
					formStyle: updatedFormSubHeaderFontSize,
				},
			});
			setIsSubHeaderFontSizeError(false);
		} else {
			setIsSubHeaderFontSizeError(true);
		}
	};

	//Field lable Font Size
	const handleBlurFormLabelFontSize = () => {
		setIsLabelFontSizeInEditMode(false);
	};

	const handleChangeFormLabelFontSize = (event) => {
		const { value } = event.target;
		if (isWithInValidRange(value)) {
			const updatedLableFontSize = {
				...fieldStyle,
				label: {
					...fieldStyle.label,
					fontSize: parseInt(value),
				},
			};
			setFieldStyle(updatedLableFontSize);
			updateFormData({
				...currentFormData,
				style: {
					...currentFormData.style,
					fieldStyle: updatedLableFontSize,
				},
			});
			setIsFieldLabelFontSizeError(false);
		} else {
			setIsFieldLabelFontSizeError(true);
		}
	};

	//Input Fontsize
	const handleBlurInputFontSize = () => {
		setIsInputFontSizeInEditMode(false);
	};

	const handleChangeInputFontSize = (event) => {
		const { value } = event.target;
		if (isWithInValidRange(value)) {
			const updatedInputFontSize = {
				...fieldStyle,
				input: {
					...fieldStyle.input,
					fontSize: parseInt(value),
				},
			};
			setFieldStyle(updatedInputFontSize);
			updateFormData({
				...currentFormData,
				style: {
					...currentFormData.style,
					fieldStyle: updatedInputFontSize,
				},
			});
			setIsFieldInputFontSizeError(false);
		} else {
			setIsFieldInputFontSizeError(true);
		}
	};

	const styles = {
		headerStyle: {
			fontSize: 13,
			fontWeight: 500,
		},
		contentHeader: {
			fontSize: 13,
			fontWeight: 500,
		},
		textContent: {
			fontSize: 14,
		},
		colorPalatte: {
			height: "18px",
			width: "18px",
			borderRadius: "4px",
			border: "1px solid #E7F7F0",
		},
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={alignmnetMenu}
				open={isAlignmentMenuOpened}
				onClose={CloseAlignmentMenu}
				minWidth="152px"
			>
				{alignments.map((alignmentItem) => (
					<MenuItem
						key={alignmentItem.value}
						onClick={() =>
							handleChangeAlignment(alignmentItem.value)
						}
					>
						<Typography fontSize={13}>
							{alignmentItem.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				anchorEl={fieldAlignmentMenu}
				open={isFieldAlignmentMenuOpened}
				onClose={CloseFieldAlignmentMenu}
				minWidth="152px"
			>
				{fieldAlignments.map((alignmentItem) => (
					<MenuItem
						key={alignmentItem.value}
						onClick={() =>
							handleChangeFieldAlignment(alignmentItem.value)
						}
					>
						<Typography fontSize={13}>
							{alignmentItem.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				anchorEl={fontMenu}
				open={isFontMenuOpened}
				onClose={CloseFontMenu}
				sx={{ maxHeight: "300px" }}
			>
				{fonts.map((font) => (
					<MenuItem
						key={font.value}
						onClick={() => handleChangeFont(font.value)}
					>
						<Typography fontSize={13}>{font.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Box py={3}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
				>
					<Box width="88%">
						<Stack spacing={3}>
							<Stack spacing={0.5}>
								<Typography style={styles.headerStyle} pl={1.5}>
									Form
								</Typography>
								<Box className={classes.detailsContainer}>
									<Stack direction="row" alignItems="center">
										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											p={1}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Background Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														{
															formStyle.backgroundColor
														}
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor={
															formStyle.backgroundColor
														}
													></Box>
												</Stack>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="200px"
											spacing={0.5}
										>
											<Box
												borderBottom={
													isAlignmentHovered
														? "1px solid rgba(0, 0, 0, 0.1)"
														: "1px solid white"
												}
											>
												<Typography
													style={styles.contentHeader}
												>
													Alignment
												</Typography>
												<Stack
													sx={{ cursor: "pointer" }}
													direction="row"
													alignItems="center"
													spacing={0.5}
													onMouseOver={(event) =>
														handleOnMouseOverAlignment(
															event
														)
													}
													onMouseOut={() =>
														handleOnMouseOutAlignment()
													}
													onClick={(event) =>
														handleFieldAlignmentMenu(
															event
														)
													}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														{getFieldAlignmentValue(
															formStyle.alignmentId
														)}
													</Typography>
													<Box display="flex">
														{DropDownIcon(
															16,
															16,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												</Stack>
											</Box>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack px={2} py={1} minWidth="200px">
											<Box
												borderBottom={
													isFontStyleHovered
														? "1px solid rgba(0, 0, 0, 0.1)"
														: "1px solid white"
												}
											>
												<Typography
													style={styles.contentHeader}
												>
													Font
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
													onMouseOver={(event) =>
														onMouseOverFontStyle(
															event
														)
													}
													onMouseOut={
														onMouseOutFontStyle
													}
													onClick={handleFontMenu}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														{getFontStyleValue(
															formStyle.fontId
														)}
													</Typography>
													<Box
														display="flex"
														alignItems="center"
													>
														{DropDownIcon(
															16,
															16,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												</Stack>
											</Box>
										</Stack>
									</Stack>

									<Stack direction="row" alignItems="center">
										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											p={1}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Header Text Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														{
															formStyle.header
																.textColor
														}
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor={
															formStyle.header
																.textColor
														}
													></Box>
												</Stack>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="200px"
											spacing={0.5}
										>
											<Typography
												style={styles.contentHeader}
											>
												Header Font Size
											</Typography>
											<Stack>
												{!isHeaderFontSizeInEditMode ? (
													<Typography
														onClick={
															handleClickHeaderFontSize
														}
														style={
															styles.textContent
														}
													>
														{
															formStyle.header
																.fontSize
														}
														px
													</Typography>
												) : (
													<TextField
														defaultValue={
															formStyle.header
																.fontSize
														}
														variant="standard"
														autoFocus
														onBlur={
															handleBlurFormHeaderFontSize
														}
														onChange={
															handleChangeFormHeaderFontSize
														}
														inputProps={{
															maxLength: 2,
															style: {
																padding: 0,
															},
														}}
														InputProps={{
															style: {
																fontSize: 14,
															},
														}}
														error={
															isHeaderFontSizeError
														}
														helperText={
															isHeaderFontSizeError
																? `Enter a number between ${valueRange.minValue} to ${valueRange.maxValue}`
																: ""
														}
													/>
												)}
											</Stack>
										</Stack>
									</Stack>

									<Stack direction="row" alignItems="center">
										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											p={1}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Sub Header Text Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														{
															formStyle.subHeader
																.textColor
														}
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor={
															formStyle.subHeader
																.textColor
														}
													></Box>
												</Stack>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="200px"
											spacing={0.5}
										>
											<Typography
												style={styles.contentHeader}
											>
												Sub Header Font Size
											</Typography>
											<Stack>
												{!isSubHeaderFontSizeInEditMode ? (
													<Typography
														onClick={
															handleClickSubHeaderFontSize
														}
														style={
															styles.textContent
														}
													>
														{
															formStyle.subHeader
																.fontSize
														}
														px
													</Typography>
												) : (
													<TextField
														defaultValue={
															formStyle.subHeader
																.fontSize
														}
														variant="standard"
														autoFocus
														onBlur={
															handleBlurFormSubHeaderFontSize
														}
														onChange={
															handleChangeFormSubHeaderFontSize
														}
														inputProps={{
															maxLength: 2,
															style: {
																padding: 0,
															},
														}}
														InputProps={{
															style: {
																fontSize: 14,
															},
														}}
														error={
															isSubHeaderFontSizeError
														}
														helperText={
															isSubHeaderFontSizeError
																? `Enter a number between ${valueRange.minValue} to ${valueRange.maxValue}`
																: ""
														}
													/>
												)}
											</Stack>
										</Stack>
									</Stack>
								</Box>
							</Stack>

							<Stack spacing={0.5}>
								<Typography style={styles.headerStyle} pl={1}>
									Fields
								</Typography>
								<Box className={classes.detailsContainer}>
									<Stack direction="row" alignItems="center">
										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											p={1}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Lable Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														#000000
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor="#000000"
													></Box>
												</Stack>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="200px"
											spacing={0.5}
										>
											<Typography
												style={styles.contentHeader}
											>
												Lable Font Size
											</Typography>
											<Stack>
												{!isLabelFontSizeInEditMode ? (
													<Typography
														onClick={
															handleClickLabelFontSize
														}
														style={
															styles.textContent
														}
													>
														{
															fieldStyle.label
																.fontSize
														}
														px
													</Typography>
												) : (
													<TextField
														defaultValue={
															fieldStyle.label
																.fontSize
														}
														variant="standard"
														autoFocus
														onBlur={
															handleBlurFormLabelFontSize
														}
														onChange={
															handleChangeFormLabelFontSize
														}
														inputProps={{
															maxLength: 2,
															style: {
																padding: 0,
															},
														}}
														InputProps={{
															style: {
																fontSize: 14,
															},
														}}
														error={
															isFieldLabelFontSizeError
														}
														helperText={
															isFieldLabelFontSizeError
																? `Enter a number between ${valueRange.minValue} to ${valueRange.maxValue}`
																: ""
														}
													/>
												)}
											</Stack>
										</Stack>
									</Stack>

									<Stack direction="row" alignItems="center">
										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											p={1}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Input Text Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														#000000
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor="#000000"
													></Box>
												</Stack>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="250px"
											spacing={0.5}
										>
											<Typography
												style={styles.contentHeader}
											>
												Input Font Size
											</Typography>
											<Stack>
												{!isInputFontSizeInEditMode ? (
													<Typography
														onClick={
															handleClickInputFontSize
														}
														style={
															styles.textContent
														}
													>
														{
															fieldStyle.input
																.fontSize
														}
														px
													</Typography>
												) : (
													<TextField
														defaultValue={
															fieldStyle.input
																.fontSize
														}
														variant="standard"
														autoFocus
														onBlur={
															handleBlurInputFontSize
														}
														onChange={
															handleChangeInputFontSize
														}
														inputProps={{
															style: {
																padding: 0,
															},
														}}
														InputProps={{
															style: {
																fontSize: 14,
															},
														}}
														error={
															isFieldInputFontSizeError
														}
														helperText={
															isFieldInputFontSizeError
																? `Enter a number between ${valueRange.minValue} to ${valueRange.maxValue}`
																: ""
														}
													/>
												)}
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											pl={2}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Input Background Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														#ffffff
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor="#ffffff"
													></Box>
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								</Box>
							</Stack>

							<Stack spacing={0.5}>
								<Typography style={styles.headerStyle} pl={1}>
									Submit Button
								</Typography>
								<Box className={classes.detailsContainer}>
									<Stack direction="row" alignItems="center">
										<Stack
											direction="row"
											alignItems="center"
											minWidth="200px"
											p={1}
										>
											<Stack spacing={0.5}>
												<Typography
													style={styles.contentHeader}
												>
													Background Color
												</Typography>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														#33BC7E
													</Typography>
													<Box
														style={
															styles.colorPalatte
														}
														backgroundColor={
															theme.palette
																.primary.main
														}
													></Box>
												</Stack>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="200px"
											spacing={0.5}
										>
											<Typography
												style={styles.contentHeader}
											>
												Text Color
											</Typography>
											<Stack
												direction="row"
												alignItems="center"
												spacing={0.5}
											>
												<Typography
													style={styles.textContent}
												>
													#ffffff
												</Typography>
												<Box
													style={styles.colorPalatte}
													backgroundColor="#ffffff"
												></Box>
											</Stack>
										</Stack>

										<Stack
											direction="row"
											alignItems="center"
										>
											<Divider
												sx={{ height: "25px" }}
												orientation="vertical"
												flexItem
											/>
										</Stack>

										<Stack
											px={2}
											py={1}
											minWidth="200px"
											spacing={0.5}
										>
											<Box
												borderBottom={
													isSubmitAlignmentHovered
														? "1px solid rgba(0, 0, 0, 0.1)"
														: "1px solid white"
												}
											>
												<Typography
													style={styles.contentHeader}
												>
													Alignment
												</Typography>
												<Stack
													sx={{ cursor: "pointer" }}
													direction="row"
													alignItems="center"
													spacing={0.5}
													onMouseOver={(event) =>
														handleOnMouseOverSubmitAlignment(
															event
														)
													}
													onMouseOut={() =>
														handleOnMouseOutSubmitAlignment()
													}
													onClick={(event) =>
														handleAlignmentMenu(
															event
														)
													}
												>
													<Typography
														style={
															styles.textContent
														}
													>
														{getAlignmentValue(
															submitButtonStyle.alignmentId
														)}
													</Typography>
													<Box display="flex">
														{DropDownIcon(
															16,
															16,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												</Stack>
											</Box>
										</Stack>
									</Stack>
								</Box>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
