import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createWebform,
	deleteWebForm,
	getTemplateList,
	getWebFormData,
	getWebFormDropdownOptionsById,
	getWebformList,
	updateWebFormData,
	updateWebFormList,
} from "../../../api/webform/webformApi";
import {
	formFieldsKey,
	getDropdownKeyByFieldId,
	getWebFormListKeyActiveStatus,
	getWebFormDetailsKey,
	getWebFormListKey,
	templateListKey,
} from "../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../api/fields/config/configApi";

const useWebFormListData = (isActive) => {
	const webFormListKey = getWebFormListKeyActiveStatus(isActive);
	return useQuery(webFormListKey, () => getWebformList(isActive), {
		select: (webFormData) => webFormData.webForms,
	});
};

const useWebFormDeatils = (webFormId) => {
	const webFormDetailsKey = getWebFormDetailsKey(webFormId);
	return useQuery(webFormDetailsKey, () => getWebFormData(webFormId));
};

const useCreateMutation = () => {
	let invalidateWebFormList = useInvalidateWebFormList();
	return useMutation(createWebform, {
		onSuccess: () => {
			invalidateWebFormList();
		},
	});
};

const useUpdateMutation = () => {
	let invalidateWebFormList = useInvalidateWebFormList();
	return useMutation(updateWebFormData, {
		onSuccess: () => {
			invalidateWebFormList();
		},
	});
};

const useFieldList = () => {
	return useQuery(
		formFieldsKey,
		() => getFieldDropdownData({ name: "formFields" }),
		{
			staleTime: 3000,
			select: (data) => data.options,
		}
	);
};

const useTemplateList = () => {
	return useQuery(templateListKey, () => getTemplateList(), {
		staleTime: 3000,
	});
};

const useWebFormDropdownList = (fieldId) => {
	const dropdownKey = getDropdownKeyByFieldId(fieldId);
	return useQuery(dropdownKey, () => getWebFormDropdownOptionsById(fieldId), {
		select: (data) => data.options,
	});
};

const useInvalidateWebFormList = () => {
	let queryClient = useQueryClient();
	let webFormListKey = getWebFormListKey();
	return () => {
		queryClient.invalidateQueries(webFormListKey);
	};
};

const useDeleteMutation = () => {
	let invalidateWebFormList = useInvalidateWebFormList();
	return useMutation(deleteWebForm, {
		onSuccess: () => {
			invalidateWebFormList();
		},
	});
};

const useUpdateWebFormListMutation = () => {
	let invalidateWebFormList = useInvalidateWebFormList();
	return useMutation(updateWebFormList, {
		onSuccess: () => {
			invalidateWebFormList();
		},
	});
};

export {
	useDeleteMutation,
	useUpdateWebFormListMutation,
	useWebFormListData,
	useWebFormDeatils,
	useFieldList,
	useTemplateList,
	useWebFormDropdownList,
	useCreateMutation,
	useUpdateMutation,
};
