// mail list name to get the mail list
export const mailListName = {
	AWAITING_RESPONSE: "awaiting",

	ARCHIVED: "archived",

	DRAFT: "draft",

	INBOX: "inbound",

	SCHEDULED: "scheduled",

	SENT: "outbound",

	STARRED: "starred",

	TRASHED: "trash",

	BULK_METRICS: "bulk/metrics",

	BULK_DRAFT: "bulk/draft",

	BULK_SCHEDULED: "bulk/scheduled",

	BOUNCES: "bounce",

	OPENS: "tracker/open",

	CLICKS: "tracker/click",
};
