import React, { useEffect, useState } from "react";
import EmailManagementSyncDialog from "../../../../Elements/EmailManagementSyncDialog";
import ContactSyncSettings from "./ContactSyncSettings";
import { useContactSyncSettingsData } from "../../../../../hooks/services/contactSync";
import { Box, CircularProgress, Stack } from "@mui/material";
import ContactSyncList from "./ContactSyncList";
import { contactSyncState } from "../../../../../utils/contactSyncUtils";
import { emailsSyncTypes } from "../../../../../utils/emailsSyncUtils";
import { useAuth } from "../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../utils/Auth";

export default function ContactSync() {
	// query call
	const {
		data: contactSyncSettingsData,
		isLoading: isContactSyncLoading,
		status: contactSyncStatus,
	} = useContactSyncSettingsData();
	const { isUserAllowedFor } = useAuth();

	const [syncState, setSyncState] = useState("");

	const tooltipText = "You don't have access to contact sync";

	useEffect(() => {
		if (contactSyncStatus === "success") {
			if (contactSyncSettingsData) {
				setSyncState(contactSyncSettingsData.state);
			}
		}
	}, [contactSyncSettingsData, contactSyncStatus]);

	const updateSyncState = (updatedSyncState) => {
		setSyncState(updatedSyncState);
	};

	const isContactSyncButtonDisabled = !isUserAllowedFor(
		PERMISSIONS.contact.view
	);

	return (
		<React.Fragment>
			{isContactSyncLoading ? (
				<Stack
					height="60vh"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Stack>
			) : (
				<Box>
					<Box hidden={contactSyncState.syncDisabled !== syncState}>
						<EmailManagementSyncDialog
							emailsSyncType={emailsSyncTypes.contact}
							tooltipText={tooltipText}
							isSyncButtonDisabled={isContactSyncButtonDisabled}
						/>
					</Box>

					<Box
						hidden={
							contactSyncState.syncBridgeEstablished !== syncState
						}
					>
						<ContactSyncSettings
							contactSyncSettingsData={
								contactSyncSettingsData?.syncSettings
							}
							updateSyncState={updateSyncState}
							state={contactSyncSettingsData.state}
						/>
					</Box>

					<Box hidden={contactSyncState.syncEnabled !== syncState}>
						<ContactSyncList
							contactSyncSettingsData={contactSyncSettingsData}
							updateSyncState={updateSyncState}
						/>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
