import {
	Autocomplete,
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/auth";
import {
	useCallback,
	useEffect,
	useState,
	useImperativeHandle,
	forwardRef,
} from "react";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useCompanyList } from "../../../../hooks/services/company";
import CloseIcon from "../../../../assets/icons/close";
import { handleFieldValidation } from "../../../../utils/fieldValidation";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const CompanyFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		// permission:-
		const { isUserAllowedFor } = useAuth();
		const isCompanyListEnabled = isUserAllowedFor(PERMISSIONS.company.view);

		// query call
		const { data: companies } = useCompanyList(isCompanyListEnabled);

		const [companyInputValue, setCompanyInputValue] = useState("");
		const [selectedCompany, setSelectedCompany] = useState("");

		const CustomPaper = function (props) {
			return (
				<Paper
					{...props}
					style={{
						...props.style,
						borderRadius: "8px",
						boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
						display: companyInputValue ? "block" : "none",
					}}
				/>
			);
		};

		useEffect(() => {
			if (value && !!companies) {
				let selectedCompany = companies?.find(
					(company) => company?.value === value
				);
				setSelectedCompany(selectedCompany);
			}
		}, [value, companies]);

		const handleSelectCompany = (_, selectedCompany, onChange) => {
			setSelectedCompany(selectedCompany);
			onChange(selectedCompany?.value);
			clearErrors(fieldId + "");
			onError(null);
		};

		const handleCompanyInputChange = (event) => {
			const { value } = event.target;
			setCompanyInputValue(value);
		};

		const removeCompanyValue = (onChange) => {
			onChange("");
			setSelectedCompany("");
			setCompanyInputValue("");
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<Autocomplete
							{...fieldData}
							size="small"
							PopperComponent={CustomPopper}
							PaperComponent={CustomPaper}
							filterSelectedOptions
							readOnly={!!selectedCompany}
							noOptionsText="No results found"
							options={companies || []}
							forcePopupIcon={false}
							onChange={(event, value) =>
								handleSelectCompany(event, value, onChange)
							}
							value={selectedCompany}
							renderOption={(props, option) => (
								<Box
									component="li"
									key={option.value}
									{...props}
									style={{ minHeight: "40px" }}
								>
									<Typography
										fontSize={13}
										key={option.value}
									>
										{option.name}
									</Typography>
								</Box>
							)}
							getOptionLabel={(option) => {
								if (option.name) {
									return option.name;
								}
								return option;
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={field?.placeHolder || "Enter"}
									value={companyInputValue}
									onChange={handleCompanyInputChange}
									inputProps={{
										...params.inputProps,
										style: {
											fontSize: "14px",
											paddingLeft: 0,
										},
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<InputAdornment position="end">
												{!!selectedCompany && (
													<IconButton
														onClick={() =>
															removeCompanyValue(
																onChange
															)
														}
													>
														{CloseIcon(
															20,
															20,
															theme.palette
																.primary.main
														)}
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											borderRadius: "8px",
										},
										paddingLeft: "10px",
										...textFieldSx,
									}}
								/>
							)}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

CompanyFieldEditForm.displayName = "CompanyFieldEditForm";

export default CompanyFieldEditForm;
