import React from "react";
import { Typography } from "@mui/material";

export default function ProductEnabled(props) {
	const { textColor = "#000" } = props;
	return (
		<React.Fragment>
			<Typography
				fontSize={13}
				style={{ color: textColor, opacity: "0.6" }}
				color="rgba(0, 0, 0, 0.6)"
			>
				Products are now accessible, check your left nav bar.
			</Typography>
		</React.Fragment>
	);
}
