import { getRootKey } from "../../rootKey";

const accountKey = "account";

//return admin settings account key
const getAccountKey = () => {
	return [...getRootKey(), accountKey];
};

// query key
export const accountAdminDetailsKey = [...getAccountKey(), "adminDetails"];
