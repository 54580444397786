import React from "react";
import { Bar } from "react-chartjs-2";

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "bottom",
			display: false,
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			title: {
				display: true,
				text: "Sales Owners",
				padding: {
					top: 12,
				},
			},
			grid: {
				display: false,
			},
		},
		y: {
			title: {
				display: true,
				text: "Total Contacts",
				padding: {
					bottom: 12,
				},
			},
			grid: {
				display: false,
			},
			ticks: {
				stepSize: 20,
			},
		},
	},
};

const labels = [
	"Jordyn Vaccaro",
	"Adison Vaccaro",
	"Madelyn Westerv",
	"Allison Korsgaard",
];

export const data = {
	labels,
	datasets: [
		{
			label: "Contact Count",
			data: [78, 84, 63, 100],
			backgroundColor: [
				"rgb(211, 114, 225)",
				"rgb(244, 190, 56)",
				"rgb(63, 255, 163)",
				"rgb(233, 139, 201)",
			],
			barThickness: 50,
		},
	],
};

export default function BarChart() {
	return (
		<React.Fragment>
			<Bar options={options} data={data} />
		</React.Fragment>
	);
}
