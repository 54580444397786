import { TextField, Typography, useTheme } from "@mui/material";
import React from "react";

export default function TextComponent({ formProperty, formStyle, ...rest }) {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Typography
				pl={1}
				fontSize={13}
				fontWeight={500}
				style={formStyle.labelColor}
			>
				{formProperty.label}
				{formProperty.config.isRequired && (
					<span style={{ color: theme.palette.error.main }}> *</span>
				)}
			</Typography>

			<TextField
				sx={{
					"& .MuiOutlinedInput-root": {
						bgcolor: formStyle.lableInput.backgroundColor,
						color: formStyle.lableInput.color,
					},
				}}
				inputProps={{
					style: {
						fontSize: formStyle.lableInput.fontSize,
						textAlign: formStyle.lableInput.textAlign,
					},
				}}
				{...rest}
			/>
		</React.Fragment>
	);
}
