import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SalesOwnerBaseComponent from "./SalesOwnerBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import { Box } from "@mui/material";
import { useFieldPermissionContext } from "../../../../FieldPermissionContext";

export default function SalesOwnerComponent({ field, inputRef }) {
	const {
		control,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	const fieldPermission = useFieldPermissionContext();
	const isOwnerFieldEnabled = fieldPermission?.owner?.enabled;

	return (
		<React.Fragment>
			<Box
				style={{
					width: "100%",
					pointerEvents: isOwnerFieldEnabled ? "auto" : "none",
					opacity: isOwnerFieldEnabled ? "1" : "0.6",
				}}
			>
				<Controller
					control={control}
					name={field.id + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, value } }) => (
						<SalesOwnerBaseComponent
							onChange={onChange}
							value={value}
							field={field}
							inputRef={inputRef}
							clearErrors={clearErrors}
							errors={errors}
						/>
					)}
				></Controller>
			</Box>
		</React.Fragment>
	);
}
