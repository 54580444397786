import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Stack,
	Typography,
	ToggleButton,
	Checkbox,
	useTheme,
	alpha,
	MenuItem,
} from "@mui/material";

import { default as DropDownIcon } from "../../../../../assets/icons/dropDown";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import Dialog from "../../../../Elements/Dialog";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import Menu from "../../../../Elements/Menu";
import { useUserTeamTerritoryList } from "../../../../../hooks/services/userManagement";
import {
	useTemplateFolderSettingsData,
	useUpdateFolderVisibility,
} from "../../../../../hooks/services/mail/emailTemplateFolder";
import SelectedView from "./SelectedView";

const visibilityId = {
	justMe: 1,
	everyone: 2,
	selected: 3,
};

const optionsType = {
	user: "USER",
	team: "TEAM",
	territory: "TERRITORY",
};

export default function VisibilityDialog(props) {
	const {
		onCloseVisibilityDialog,
		isVisibilityDialogOpened,
		templateFolder,
	} = props;
	const theme = useTheme();

	const visibilityList = getDropdownDataByName("VISIBILITY_LIST");
	const permissionList = getDropdownDataByName("PERMISSION_LIST");

	const [permissionMenuElement, setPermissionMenuElement] = useState(null);
	const isPermissionMenuOpened = Boolean(permissionMenuElement);
	const [selectedPermission, setSelectedPermission] = useState(
		permissionList[0]
	);
	const [selectedVisibilityTypeId, setSelectedVisibilityTypeId] = useState(
		visibilityList[0].value
	);
	const [selectedUserTeamTerritoryValue, setSelectedUserTeamTerritoryValue] =
		useState([]);
	const [isPreventShareFilter, setIsPreventShareFilter] = useState(true);

	// user team territory list
	const { data: userTeamTerritoryList } = useUserTeamTerritoryList();

	const { data: folderSettingsData, status: folderSettingsDataStatus } =
		useTemplateFolderSettingsData(templateFolder.id);
	const updateVisibilityMutation = useUpdateFolderVisibility(
		templateFolder.id
	);

	useEffect(() => {
		if (folderSettingsDataStatus === "success" && folderSettingsData) {
			const getInitialSelectedValues = (initialValues) => {
				return userTeamTerritoryList?.filter((userTeamTerritory) =>
					initialValues?.some(
						(initialValue) =>
							initialValue.id === userTeamTerritory.value
					)
				);
			};

			const getPermissionById = (accessType) => {
				const initialPermission = permissionList.find(
					(permissionData) => permissionData.value === accessType
				);
				return initialPermission;
			};

			if (folderSettingsData.visibleTo === visibilityId.justMe) {
				setSelectedVisibilityTypeId(visibilityId.justMe);
				setSelectedUserTeamTerritoryValue([]);
				setIsPreventShareFilter(true);
			} else if (folderSettingsData.visibleTo === visibilityId.everyone) {
				setSelectedVisibilityTypeId(visibilityId.everyone);
				setIsPreventShareFilter(folderSettingsData.preventShare);
				setSelectedPermission(
					getPermissionById(folderSettingsData.accessType)
				);
				setSelectedUserTeamTerritoryValue([]);
			} else if (folderSettingsData.visibleTo === visibilityId.selected) {
				setSelectedVisibilityTypeId(visibilityId.selected);
				setIsPreventShareFilter(folderSettingsData.preventShare);
				setSelectedPermission(
					getPermissionById(folderSettingsData.accessType)
				);
				let initialUsers =
					getInitialSelectedValues(folderSettingsData.users) || [];
				let initialTeams =
					getInitialSelectedValues(folderSettingsData.teams) || [];
				let initialTerritory =
					getInitialSelectedValues(folderSettingsData.territories) ||
					[];

				setSelectedUserTeamTerritoryValue([
					...initialUsers,
					...initialTeams,
					...initialTerritory,
				]);
			}
		}
	}, [
		folderSettingsData,
		folderSettingsDataStatus,
		isVisibilityDialogOpened,
	]);

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setSelectedVisibilityTypeId(type);
		}
	};

	const openPermissionMenu = (event) => {
		setPermissionMenuElement(event.currentTarget);
	};

	const closePermissionMenu = () => {
		setPermissionMenuElement(null);
	};

	const handleSelectPermission = (permission) => {
		setSelectedPermission(permission);
		closePermissionMenu();
	};

	const handlePreventShareFilter = () => {
		setIsPreventShareFilter(
			(isPreventShareFilter) => !isPreventShareFilter
		);
	};

	const onUserTeamTerritoryChange = (values) => {
		setSelectedUserTeamTerritoryValue(values);
	};

	const displayAccessTypeMenu = () => {
		return selectedVisibilityTypeId !== visibilityId.justMe;
	};

	const displaySelectedTable = () => {
		return selectedVisibilityTypeId === visibilityId.selected;
	};

	const displaySharedFilter = () => {
		return selectedVisibilityTypeId !== visibilityId.justMe;
	};

	const getSelectedUserList = () => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.user
			);
		}
		return [];
	};

	const getSelectedTeamList = () => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.team
			);
		}
		return [];
	};

	const getSelectedTerritoryList = () => {
		if (selectedUserTeamTerritoryValue.length > 0) {
			return selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.type === optionsType.territory
			);
		}
		return [];
	};

	const onClose = () => {
		setSelectedUserTeamTerritoryValue([]);
		setSelectedPermission(permissionList[0]);
		setSelectedVisibilityTypeId(visibilityList[0].value);
		setIsPreventShareFilter(true);
		onCloseVisibilityDialog();
	};

	const getVisibilityRequestData = () => {
		let requestData = {
			id: templateFolder?.id,
		};

		if (selectedVisibilityTypeId === visibilityId.justMe) {
			requestData.visibleTo = visibilityId.justMe;
		} else if (selectedVisibilityTypeId === visibilityId.everyone) {
			requestData.visibleTo = visibilityId.everyone;
			requestData.accessType = selectedPermission.value;
			requestData.preventShare = isPreventShareFilter;
		} else if (selectedVisibilityTypeId === visibilityId.selected) {
			requestData.visibleTo = visibilityId.selected;
			requestData.accessType = selectedPermission.value;
			requestData.preventShare = isPreventShareFilter;

			if (getSelectedUserList().length > 0) {
				requestData.users = getSelectedUserList().map(
					(user) => user.value
				);
			}
			if (getSelectedTeamList().length > 0) {
				requestData.teams = getSelectedTeamList().map(
					(team) => team.value
				);
			}
			if (getSelectedTerritoryList().length > 0) {
				requestData.territories = getSelectedTerritoryList().map(
					(territory) => territory.value
				);
			}

			requestData.isShared = isPreventShareFilter;
		}
		return requestData;
	};

	const onSaveFolderVisibility = () => {
		let visibilityRequestData = getVisibilityRequestData();
		updateVisibilityMutation.mutate(visibilityRequestData, {
			onSuccess: () => {
				onClose();
			},
		});
	};

	return (
		<React.Fragment>
			{/* Permission Menu */}
			<Menu
				minWidth="150px"
				anchorEl={permissionMenuElement}
				open={isPermissionMenuOpened}
				onClose={closePermissionMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{permissionList.map((access) => (
					<MenuItem
						key={access.value}
						onClick={() => handleSelectPermission(access)}
						style={{
							height: "40px",
							backgroundColor:
								access.value === selectedPermission.value
									? alpha(theme.palette.primary.main, 0.1)
									: "transparent",
						}}
					>
						<Typography fontSize={13} fontWeight={400}>
							{access.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Dialog
				open={isVisibilityDialogOpened}
				title={`${templateFolder?.name} - Visibility`}
			>
				<Box p={2}>
					<Typography fontSize={13} fontWeight={500} padding="5px">
						Visible To
					</Typography>

					<Stack direction="row" spacing={3}>
						<Box>
							<ToggleButtonGroup
								value={selectedVisibilityTypeId}
								onChange={handleToggleButtonGroup}
							>
								{visibilityList.map((VisibilityData) => (
									<ToggleButton
										key={VisibilityData.value}
										value={VisibilityData.value}
									>
										{VisibilityData.name}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Box>

						{displayAccessTypeMenu() ? (
							<Button
								variant="contained"
								endIcon={DropDownIcon(
									16,
									16,
									theme.palette.primary.main
								)}
								color="secondary"
								disableElevation
								onClick={openPermissionMenu}
							>
								{selectedPermission?.name}
							</Button>
						) : null}
					</Stack>

					{displaySelectedTable() ? (
						<SelectedView
							userTeamTerritoryList={userTeamTerritoryList}
							selectedUserTeamTerritoryValue={
								selectedUserTeamTerritoryValue
							}
							onUserTeamTerritoryChange={
								onUserTeamTerritoryChange
							}
						/>
					) : null}

					{displaySharedFilter() ? (
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							pt={4}
						>
							<Checkbox
								checked={isPreventShareFilter}
								onChange={handlePreventShareFilter}
							/>

							<Typography fontSize={14} fontWeight={400}>
								Prevent recepients from sharing this view with
								others
							</Typography>
						</Stack>
					) : null}

					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
						style={{ padding: "24px 12px 12px 12px" }}
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
							disabled={updateVisibilityMutation.isLoading}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							disableElevation
							sx={{ width: "112px" }}
							onClick={onSaveFolderVisibility}
							disabled={updateVisibilityMutation.isLoading}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
