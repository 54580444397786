import React from "react";
import ViewEmail from "../../../Mail/ViewEmail";
import { useSingleConversationData } from "../../../../hooks/services/mail";
import { Stack } from "@mui/system";
import { CircularProgress } from "@mui/material";

export default function ConversationViewer(props) {
	const { onClose, conversationData, sourceName, entityId, viewerMode } =
		props;

	const {
		data: singleConversationData,
		isLoading: isLoadingConversationData,
	} = useSingleConversationData(conversationData?.id);

	return (
		<React.Fragment>
			{isLoadingConversationData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<ViewEmail
					mailData={{
						emails: [
							{
								...singleConversationData,
								messageCategory:
									conversationData?.data?.messageCategory,
							},
						],
					}}
					onClose={onClose}
					sourceName={sourceName}
					entityId={entityId}
					hiddenMailInfo={true}
					viewerMode={viewerMode}
				/>
			)}
		</React.Fragment>
	);
}
