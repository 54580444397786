import { Checkbox, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function CheckboxComponent(params) {
	const { field } = params;

	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		if (field && Array.isArray(field.values)) {
			setIsSelected(field.values[0].value);
		}
	}, [field]);

	const handleChangeValue = (event) => {
		const { checked } = event.target;
		setIsSelected(checked);

		field["values"] = [
			{
				value: checked,
			},
		];
	};

	return (
		<>
			<Stack flexDirection="row">
				<Checkbox checked={isSelected} onChange={handleChangeValue} />

				<Typography fontSize={14} fontWeight={500}>
					{field?.displayName || ""}
				</Typography>
			</Stack>
		</>
	);
}
