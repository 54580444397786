import { useCallback, useRef } from "react";

export const useLastRowRef = (fetchNextPage, hasNextPage, isFetching) => {
	const observer = useRef(null);
	return useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasNextPage && !isFetching) {
					fetchNextPage();
				}
			});

			if (node) observer.current.observe(node);
		},
		[fetchNextPage, hasNextPage, isFetching]
	);
};
