import React from "react";

function DropDownRight(
	width = "24",
	height = "24",
	color = "#000",
	opacity = 1
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 16 16"
			style={{ fill: "none" }}
			opacity={opacity}
		>
			<path
				d="M7.2998 3.38662L11.6465 7.99995L7.2998 12.6133"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default DropDownRight;
