import React from "react";
import { TextField } from "@mui/material";

export default function PhoneComponent(props) {
	const { style = {}, field = {}, onChange, hasError, ...rest } = props;

	const isValidPhoneNumber = (phoneNumber) => {
		const minLimit = 7;
		const maxLimit = 17;

		if (phoneNumber) {
			return (
				phoneNumber?.toString()?.length >= minLimit &&
				phoneNumber?.toString()?.length <= maxLimit
			);
		}
		return false;
	};

	const handleChange = (e) => {
		const { value } = e.target;
		if (!field.values || !Array.isArray(field.values)) {
			field.values = [{ value: "" }];
		}
		if (isValidPhoneNumber(value)) {
			field.values[0].value = value;
			if (onChange) {
				onChange(field.values);
			}
		} else {
			field.values[0].value = "";
			if (onChange) {
				onChange([]);
			}
		}
	};

	return (
		<React.Fragment>
			<TextField
				{...rest}
				placeholder="-Enter-"
				defaultValue={
					field.values && field.values.length > 0
						? field.values[0].value
						: ""
				}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
						{
							"-webkit-appearance": "none",
						},
					...style,
				}}
				onChange={handleChange}
				error={hasError}
				fullWidth
			/>
		</React.Fragment>
	);
}
