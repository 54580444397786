import React, { useEffect } from "react";
import { useState } from "react";
import { MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";

export default function PhoneBaseComponent({
	onChange,
	value,
	subField,
	id,
	field,
	inputRef,
	error,
	clearErrors,
	typeSx,
	valueSx,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const phoneTypeOptions = getDropdownDataByName("PHONE_TYPE");

	const phoneTypeField = getSubFieldObject("PHONE_TYPE");
	const phoneNumberField = getSubFieldObject("PHONE_NUMBER");

	const [phone, setPhone] = useState({
		[phoneNumberField.id]: "",
		[phoneTypeField.id]: phoneTypeOptions[0].value,
	});

	useEffect(() => {
		if (value) {
			setPhone(value);
		}
	}, [value]);

	const handlePhoneFieldChange = (event, subFieldId) => {
		let { value } = event.target;
		let modifiedPhoneValue = { ...phone };
		modifiedPhoneValue[subFieldId] = value;
		let updatedPhoneValue = modifiedPhoneValue[phoneNumberField.id]
			? modifiedPhoneValue
			: "";

		setPhone(modifiedPhoneValue);
		onChange(updatedPhoneValue);
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<Stack direction="row" alignItems="center" width="100%">
				<TextField
					placeholder={phoneNumberField.placeHolder || "9876543210"}
					type="number"
					id={id}
					sx={{
						width: "80%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "8px 0px 0px 8px",
						},
						"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
							{
								"-webkit-appearance": "none",
							},
						...valueSx,
					}}
					name="mobile"
					onChange={(event) =>
						handlePhoneFieldChange(event, phoneNumberField.id)
					}
					value={phone[phoneNumberField.id]}
					inputRef={inputRef}
					error={!!error}
				/>

				<Select
					sx={{
						width: "20%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "0px 8px 8px 0px",
							marginLeft: "-1px",
						},
						...typeSx,
					}}
					name="mobileType"
					onChange={(event) =>
						handlePhoneFieldChange(event, phoneTypeField.id)
					}
					value={phone[phoneTypeField.id]}
					error={!!error}
				>
					{phoneTypeOptions.map((phoneType) => (
						<MenuItem key={phoneType.value} value={phoneType.value}>
							<Typography fontSize={13}>
								{phoneType.name}
							</Typography>
						</MenuItem>
					))}
				</Select>
			</Stack>
		</React.Fragment>
	);
}
