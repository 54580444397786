import React from "react";
import twozoLogo from "../../assets/images/logo/twozo-full.png";
import googlePlay from "../../assets/images/socialMedia/googlePlay.png";
import appStore from "../../assets/images/socialMedia/appStore.png";
import facebook from "../../assets/images/socialMedia/facebook.png";
import twitter from "../../assets/images/socialMedia/twitter.png";
import linkedin from "../../assets/images/socialMedia/linkedin.png";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";

export default function TermOfService() {
	const theme = useTheme();

	const linkedinUrl =
		"https://www.linkedin.com/company/82331514/admin/feed/posts/";
	const termsOfServiceData = [
		{
			title: "Acceptance of terms",
			body: "By using Twozo CRM, you agree to these terms. If you do not agree, please refrain from using our service.",
		},
		{
			title: "Use of service",
			body: "Twozo CRM is intended for business use. You agree not to misuse, disrupt, or interfere with the app's functionality.",
		},
		{
			title: "User responsibilities",
			body: "Users are responsible for the accuracy of the information they input. Do not use Twozo CRM for any illegal or unauthorized purpose.",
		},
		{
			title: "Data and privacy",
			body: "We value your data privacy. Our practices for collecting, using, and safeguarding data are outlined in our",
			link: "Privacy Policy",
			url: "/privacy-policy",
		},
		{
			title: "Intellectual property",
			body: "Twozo CRM's content, design, and features are protected by intellectual property laws. Reproduction or distribution without permission is prohibited.",
		},
		{
			title: "Limitation of liability",
			body: "While we strive for accuracy and reliability, we are not liable for any damages resulting from the use or inability to use Twozo CRM.",
		},
		{
			title: "Changes to terms",
			body: "We may update these terms. Continued use implies acceptance of the updated terms.",
		},
		{
			title: "Termination",
			body: "We reserve the right to terminate or suspend accounts if terms are violated.",
		},
		{
			title: "Contact us",
			body: "For any inquiries or concerns regarding these terms or our service, please contact us at",
			link: "crmnext@thelaunchclub.in",
			url: "mailto:crmnext@thelaunchclub.in",
		},
	];

	const styles = {
		socialMediaLogoContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "50px",
			width: "20px",
			height: "20px",
			cursor: "pointer",
			opacity: 0.6,
		},
	};

	return (
		<React.Fragment>
			<Box
				style={{
					minHeight: "100vh",
					backgroundColor: "#FAFAFA",
					padding: "40px",
				}}
			>
				<Box
					p={5}
					style={{
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "100%",
					}}
				>
					<Stack spacing={3}>
						<img
							alt="twozo"
							src={twozoLogo}
							style={{ width: "148px", height: "47px" }}
						/>

						<Typography fontSize={32} fontWeight={600}>
							Terms of Service for Twozo CRM
						</Typography>

						<Stack spacing={0.5}>
							<Typography
								fontSize={18}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Welcome to Twozo CRM!
							</Typography>

							<Typography fontSize={16}>
								These terms outline the rules for using our
								Customer Relationship Management application.
							</Typography>
						</Stack>
					</Stack>

					<Stack spacing={3} pt={4}>
						{termsOfServiceData.map((service, index) => (
							<Stack spacing={0.5} key={index}>
								<Typography
									fontSize={18}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									{service.title}:
								</Typography>

								<Typography
									pl={1}
									fontSize={16}
									sx={{
										"::before": {
											content:
												// eslint-disable-next-line quotes
												'"\\2022"' /* Unicode bullet character */,
											marginRight: 1,
										},
									}}
								>
									{service.body}
									<Typography
										pl={0.6}
										component="a"
										href={service.url}
										fontSize={16}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{service.link}
									</Typography>
								</Typography>
							</Stack>
						))}
					</Stack>
				</Box>

				<Stack alignItems="center" pt={5}>
					<Stack spacing={1}>
						<Stack direction="row" spacing={3}>
							<img
								alt="googlePlay"
								src={googlePlay}
								style={{ height: "22px", width: "72px" }}
							/>

							<img
								alt="appStore"
								src={appStore}
								style={{ height: "22px", width: "72px" }}
							/>
						</Stack>

						<Typography
							fontSize={10}
							textAlign="center"
							style={{ opacity: 0.6 }}
						>
							Twozo Inc.
						</Typography>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-around"
						>
							<Box
								style={{
									...styles.socialMediaLogoContainer,
									backgroundColor: "#4267B2",
								}}
							>
								<img
									src={facebook}
									alt="facebook"
									width="13px"
									height="13px"
								/>
							</Box>

							<Box
								style={{
									...styles.socialMediaLogoContainer,
									backgroundColor: "#2DAAE1",
								}}
							>
								<img
									src={twitter}
									alt="twitter"
									width="16px"
									height="16px"
								/>
							</Box>

							<Link href={linkedinUrl} target="_blank">
								<Box
									style={{
										...styles.socialMediaLogoContainer,
										backgroundColor: "#225982",
									}}
								>
									<img
										src={linkedin}
										alt="linkedin"
										width="13px"
										height="13px"
									/>
								</Box>
							</Link>
						</Stack>
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
