import { useRef, useState } from "react";
import {
	Box,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import UnKnownContactImage from "../../../../assets/images/contact/unknownContact.png";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import TooltipComponent from "../../../Elements/TooltipComponent";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import Menu from "../../../Elements/Menu";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { useDeleteNote } from "../../../../hooks/services/summary/notes/notes";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useTruncatedElement } from "../../../../hooks/layout";
import { TextButton } from "../../../../styles/twozo";

export default function ShortNoteItem(props) {
	const { noteData, sourceName, entityId, handleEditNote } = props;
	const theme = useTheme();
	const noteContentRef = useRef();

	const [hoveredNote, setHoveredNote] = useState(false);
	const [openNoteMenuElement, setOpenNoteMenuElement] = useState(null);
	const isNoteMenuOpened = Boolean(openNoteMenuElement);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isShowingMore, setIsShowingMore] = useState(false);

	const deleteMutation = useDeleteNote(sourceName, entityId);
	const { isTruncated } = useTruncatedElement(noteContentRef);

	const onMouseOverNote = () => {
		setHoveredNote(true);
	};

	const onMouseOutNote = () => {
		if (!isNoteMenuOpened) {
			setHoveredNote(false);
		}
	};

	const openNoteMenu = (event) => {
		setOpenNoteMenuElement(event.currentTarget);
	};

	const closeNoteMenu = () => {
		setOpenNoteMenuElement(null);
		setHoveredNote(false);
	};

	const handleEditClick = () => {
		closeNoteMenu();
		handleEditNote(noteData);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		closeNoteMenu();
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteNote = () => {
		let deleteNoteRequest = { id: noteData?.id };
		deleteMutation.mutate(deleteNoteRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.notesDeleted,
				});
				closeDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const toggleSeeMoreButton = () => {
		setIsShowingMore((isShowingMore) => !isShowingMore);
	};

	return (
		<Box style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
			<Menu
				minWidth="100px"
				anchorEl={openNoteMenuElement}
				open={isNoteMenuOpened}
				onClose={closeNoteMenu}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				sx={{ transform: "translateX(40px)" }}
				style={{
					marginTop: "2px",
				}}
			>
				<MenuItem onClick={handleEditClick}>
					<Typography
						sx={{ fontWeight: 500, fontSize: 13 }}
						color={theme.palette.secondary.contrastText}
					>
						Edit
					</Typography>
				</MenuItem>
				<MenuItem onClick={openDeleteDialog}>
					<Typography
						sx={{ fontWeight: 500, fontSize: 13 }}
						color={theme.palette.secondary.contrastText}
					>
						Delete
					</Typography>
				</MenuItem>
			</Menu>

			{/* Delete Dialog */}
			<DeleteDialog
				open={isDeleteDialogOpened}
				title="Are you sure you want to delete this note?"
				subtitle="This action cannot be undone."
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteNote}
				disableDeleteButton={deleteMutation.isLoading}
			/>

			<Stack
				direction="row"
				spacing={1}
				onMouseOver={() => onMouseOverNote(noteData.id)}
				onMouseOut={onMouseOutNote}
				sx={{
					backgroundColor: hoveredNote ? "#F4F5F5" : "transparent",
				}}
				px={2}
				py={1.5}
			>
				<img
					src={UnKnownContactImage}
					style={{ marginTop: ".5px" }}
					alt="profile"
					width="23px"
					height="23px"
				/>

				<Stack width="100%">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Stack direction="row" spacing={1} alignItems="center">
							<TooltipComponent
								title={noteData?.owner?.name}
								placement="top"
								verticalDisplacement={-3}
								width="259px"
							>
								<Typography
									fontSize={13}
									maxWidth="100px"
									noWrap
									sx={{
										cursor: "pointer",
									}}
								>
									{noteData?.owner?.name}
								</Typography>
							</TooltipComponent>

							<Typography fontSize={13}>&#8226;</Typography>

							<Typography
								fontSize={13}
								color={"rgba(0, 0, 0, 0.6)"}
								minWidth={125}
							>
								{getDateMonthAndYearFormat(
									noteData?.updatedTime
								)}
								,{" "}
								{getHourAndMinuteFormat(noteData?.updatedTime)}
							</Typography>
						</Stack>

						<Box
							display="flex"
							sx={{ height: "23px", width: "23px" }}
						>
							{hoveredNote && (
								<IconButton
									onClick={(event) =>
										openNoteMenu(event, noteData)
									}
									size="small"
									sx={{
										backgroundColor: isNoteMenuOpened
											? theme.palette.secondary.main
											: null,
										"&:hover": {
											backgroundColor:
												theme.palette.secondary.main,
										},
									}}
								>
									{MoreIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</IconButton>
							)}
						</Box>
					</Stack>

					<Stack spacing={1}>
						<Box
							ref={noteContentRef}
							style={{
								maxHeight: isShowingMore ? "none" : "60px",
								overflow: "hidden",
							}}
						>
							<Typography
								component="pre"
								fontSize={13}
								whiteSpace="break-spaces"
								sx={{
									wordBreak: "break-word",
								}}
							>
								{noteData?.note}
							</Typography>
						</Box>

						{isTruncated ? (
							<TextButton
								size="small"
								sx={{
									width: "85px",
									fontWeight: 500,
									"& .MuiButton-endIcon": {
										marginLeft: "4px",
									},
								}}
								endIcon={
									<Box
										display="flex"
										style={{
											transform: isShowingMore
												? "rotate(180deg)"
												: "rotate(0deg)",
										}}
									>
										{DropDownIcon(
											16,
											16,
											theme.palette.primary.main
										)}
									</Box>
								}
								onClick={toggleSeeMoreButton}
							>
								{isShowingMore ? "See Less" : "See More"}
							</TextButton>
						) : null}
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
}
