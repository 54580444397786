import React from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CopyIcon } from "../../../../../../assets/icons/copy";
import { useNavigate } from "react-router-dom";

export default function Publish(props) {
	const { webformUrl, goPreviousStep } = props;

	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100%",
					width: "100%",
				}}
			>
				<Box
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
						minHeight: "0px",
					}}
				>
					<Stack spacing={3} pt={3}>
						<Stack spacing={0.5}>
							<Stack alignItems="center">
								<Typography
									pl={1}
									width="60%"
									fontSize={13}
									fontWeight={500}
								>
									Share as Link
								</Typography>
							</Stack>
							<Stack alignItems="center">
								<Box
									width="60%"
									sx={{
										border: "1px solid rgba(0, 0, 0, 0.1)",
										borderRadius: "8px",
										p: 2,
									}}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<Typography
											sx={{ cursor: "pointer" }}
											fontSize={14}
										>
											{webformUrl}
										</Typography>
										<Stack sx={{ cursor: "pointer" }}>
											{CopyIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</Stack>
									</Stack>
								</Box>
							</Stack>
						</Stack>

						<Stack spacing={0.5}>
							<Stack alignItems="center">
								<Typography
									pl={1}
									width="60%"
									fontSize={13}
									fontWeight={500}
								>
									Embed the form on your website
								</Typography>
							</Stack>
							<Stack alignItems="center">
								<Box
									width="60%"
									sx={{
										border: "1px solid rgba(0, 0, 0, 0.1)",
										borderRadius: "8px",
										p: 2,
									}}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<Typography
											sx={{ cursor: "pointer" }}
											fontSize={14}
										>
											{`<iframe width="560" height="315" src=${webformUrl} frameborder="0"></iframe>`}
										</Typography>
										<Stack sx={{ cursor: "pointer" }}>
											{CopyIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</Stack>
									</Stack>
								</Box>
							</Stack>
						</Stack>
					</Stack>
				</Box>

				<Divider />
				<Box>
					<Stack direction="row" justifyContent="space-between" p={2}>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={goPreviousStep}
						>
							Previous
						</Button>

						<Button
							variant="contained"
							disableElevation
							onClick={() => navigate("/settings/webform")}
						>
							Close
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
