import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	TableCellText,
	TableHeaderLabel,
	Tooltip,
	twozoStyles,
} from "../../../../styles/twozo";
import UnKnownImage from "../../../../assets/images/contact/unknownContact.png";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as RenameIcon } from "../../../../assets/icons/edit";
import { default as PermissionIcon } from "../../../../assets/icons/custom";
import { default as AssignUserIcon } from "../../../../assets/icons/profileAdd";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import AssignUser from "./AssignUser";
import Menu from "../../../Elements/Menu";
import RenameRole from "./RenameRole";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import CreateRole from "./CreateRole";
import FullScreenDialog from "../../../Elements/FullScreenDialog";
import PermissionDialog from "./PermissionDialog";
import {
	useDeleteRole,
	useRolesUserList,
} from "../../../../hooks/services/userManagement/roles";
import { getDayMonthDateAndTimeFormat } from "../../../../utils/DateUtils";
import { useUserManagementContext } from "../UserManagementContext";
import { PERMISSIONS } from "../../../../utils/Auth";
import Can from "../../../Auth/Can";
import { PermissionProvider } from "./PermissionContext";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { permissionDialogTypes } from "../../../../utils/PermissionUtils/PermissionDialog";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function Roles(props) {
	const {
		isCreateRoleDialogOpened,
		setIsCreateRoleDialogOpened,
		openRoleDialog,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const deleteRoleMutation = useDeleteRole();
	const tableRef = useRef();

	const [tableStartingPosition, setTableStartingPosition] = useState(0);

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	const styles = {
		noPadding: {
			px: 0,
		},
	};

	const [permissionType, setPermissionType] = useState("");
	//Roles Menu
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [hoveredRowId, setHoveredRowId] = useState(null);
	const [selectedRole, setSelectedRole] = useState("");

	const [rolesMenuElement, setRolesMenuElement] = useState(null);
	const isRolesMenuOpened = Boolean(rolesMenuElement);

	const [isRenameRole, setIsRenameRole] = useState(false);
	const [isDeletable, setIsDeletable] = useState(false);

	const onMouseOverTableRow = (role) => {
		setTableRowHovered(role.id);
		setIsRenameRole(role.isRenamable);
		setIsDeletable(role.isDeletable);
	};
	const onMouseOutTableRow = () => setTableRowHovered(null);

	//User Menu
	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);
	const [hoveredUserId, setHoveredUserId] = useState(null);
	const [rolesUserId, setRolesUserId] = useState("");

	//Assign User
	const [openAssignUserDrawer, setOpenAssignUserDrawer] = useState(false);

	//Rename Role
	const [isRenameRoleDialogOpened, setIsRenameRoleDialogOpened] =
		useState(false);

	const {
		listData: rolesListData,
		isLoadingListData: isLoadingRolesListData,
		handleSort: handleSort,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useUserManagementContext();
	const { data: usersList, isLoading } = useRolesUserList(rolesUserId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const openRolesMenu = (event, role) => {
		setRolesMenuElement(event.currentTarget);
		setHoveredRowId(role.id);
		setSelectedRole(role);
	};

	const closeRolesMenu = () => {
		setRolesMenuElement(null);
		setHoveredRowId(null);
	};

	const openUserMenu = (event, id) => {
		setRolesUserId(id);
		setUserMenuElement(event.currentTarget);
	};

	const closeUserMenu = () => {
		setUserMenuElement(null);
		setRolesUserId("");
	};

	const openAssignUserDialog = () => {
		setPermissionType(permissionDialogTypes.AssignedUsers);
		openPermissionDialog();
	};

	const toggleAssignUserDrawer = () => {
		setRolesMenuElement(null);
		setHoveredRowId(null);
		setOpenAssignUserDrawer(
			(openAssignUserDrawer) => !openAssignUserDrawer
		);
	};

	const openRenameRoleDialog = () => {
		setIsRenameRoleDialogOpened(
			(isRenameRoleDialogOpened) => !isRenameRoleDialogOpened
		);
		setRolesMenuElement(null);
		setHoveredRowId(null);
	};

	const closeRenameRoleDialog = () => {
		setIsRenameRoleDialogOpened(
			(isRenameRoleDialogOpened) => !isRenameRoleDialogOpened
		);
		setSelectedRole("");
	};

	//Permission Dialog
	const [isPermissionDialogOpened, setIsPermissionDialogOpened] =
		useState(false);
	const [selectedAccess, setSelectedAccess] = useState("");

	const openPermissionDialog = () => {
		setRolesMenuElement(null);
		setHoveredRowId(null);
		setIsPermissionDialogOpened(true);
		closeUserMenu();
	};

	const closeRoleDialog = () => {
		setIsCreateRoleDialogOpened(false);
	};

	const closePermissionDialog = () => {
		setIsPermissionDialogOpened(false);
		setPermissionType("");
	};

	const handleAssignUser = (_, role) => {
		setSelectedRole(role);
	};

	const onNextClick = (roleIdToClone, roleName, access) => {
		setSelectedRole({
			name: roleName,
			id: roleIdToClone,
			isCreateRole: true,
		});
		setSelectedAccess(access);
		setIsCreateRoleDialogOpened(false);
		setIsPermissionDialogOpened(true);
	};

	const sortTypes = {
		ascending: "asc",
		decending: "desc",
	};

	const [tableHeader, setTableHeader] = useState([
		{
			id: 1,
			displayName: "Roles",
			name: "name",
			config: {
				width: "30%",
			},
		},
		{
			id: 2,
			displayName: "Users",
			name: "users",
			config: {
				width: "20%",
			},
		},
		{
			id: 3,
			displayName: "Created by",
			name: "createdBy",
			config: {
				width: "25%",
			},
		},
		{
			id: 4,
			displayName: "Updated by",
			name: "updatedBy",
			config: {
				width: "20%",
			},
		},
		{
			id: 5,
			displayName: "",
			config: {
				width: "5%",
			},
		},
	]);

	const isActionColumn = (index, tableHeader) => {
		return index === tableHeader.length - 1;
	};
	const maxUserLimit = 5;
	const isShowMoreButtonVisible = usersList?.length > maxUserLimit;

	const toggleSort = (sortIndex) => {
		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}

		handleSort(sortRequest);

		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((column, index) => {
				if (index === sortIndex) {
					column.sort =
						column.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					column.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	//Role Delete
	const handleDeleteRole = () => {
		setRolesMenuElement(null);
		setHoveredRowId("");
		deleteRoleMutation.mutate(hoveredRowId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.roleDeleted,
				});
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isCreateRoleDialogOpened}
				onOpen={openRoleDialog}
				onClose={closeRoleDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<CreateRole
						closeRoleDialog={closeRoleDialog}
						onNextClick={onNextClick}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<PermissionProvider>
				<FullScreenDialog
					open={isPermissionDialogOpened && !isCreateRoleDialogOpened}
					onClose={closePermissionDialog}
					title={selectedRole?.name}
				>
					<PermissionDialog
						selectedRole={selectedRole?.id}
						isCreateRole={selectedRole?.isCreateRole}
						roleName={selectedRole?.name}
						defaultSelectedAccess={selectedAccess}
						isEditable={selectedRole?.isRenamable}
						onClose={closePermissionDialog}
						defaultPermissionType={permissionType}
					/>
				</FullScreenDialog>
			</PermissionProvider>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isRenameRoleDialogOpened}
				onOpen={openRenameRoleDialog}
				onClose={closeRenameRoleDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<RenameRole
						onClose={closeRenameRoleDialog}
						selectedRole={selectedRole}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAssignUserDrawer}
				onOpen={toggleAssignUserDrawer}
				onClose={toggleAssignUserDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<AssignUser
						onClose={toggleAssignUserDrawer}
						selectedRole={selectedRole}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={rolesMenuElement}
				open={isRolesMenuOpened}
				onClose={closeRolesMenu}
				style={{ marginTop: "8px" }}
			>
				<MenuItem
					sx={{ pl: 1, py: 0.5 }}
					onClick={openPermissionDialog}
				>
					<IconButton>
						{PermissionIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
					<Typography
						fontSize={13}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
						pl={1}
					>
						Manage Permissions
					</Typography>
				</MenuItem>

				<MenuItem
					sx={{ pl: 1, py: 0.5 }}
					onClick={toggleAssignUserDrawer}
				>
					<IconButton>
						{AssignUserIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
					<Typography
						fontSize={13}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
						pl={1}
					>
						Assign User
					</Typography>
				</MenuItem>

				<Can permission={PERMISSIONS.role.edit}>
					{isRenameRole && (
						<MenuItem
							sx={{ pl: 1, py: 0.5 }}
							onClick={() => openRenameRoleDialog()}
						>
							<IconButton>
								{RenameIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
								pl={1}
							>
								Rename Role
							</Typography>
						</MenuItem>
					)}
				</Can>

				<Can permission={PERMISSIONS.role.delete}>
					{isDeletable && (
						<MenuItem
							sx={{ pl: 1, py: 0.5 }}
							onClick={() => handleDeleteRole()}
						>
							<IconButton>
								{DeleteIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
								pl={1}
							>
								Delete
							</Typography>
						</MenuItem>
					)}
				</Can>
			</Menu>

			{/* userMenu */}
			<Menu
				minWidth="200px"
				anchorEl={userMenuElement}
				open={isUserMenuOpened}
				onClose={closeUserMenu}
			>
				{isLoading ? (
					<Stack
						minWidth="300px"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					usersList?.map(
						(user, index) =>
							index < 5 && (
								<Stack
									key={user.id}
									minWidth="300px"
									minHeight="40px"
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									px={2}
									onMouseOver={() =>
										setHoveredUserId(user.id)
									}
									onMouseOut={() => setHoveredUserId(null)}
									backgroundColor={
										user.id === hoveredUserId
											? "#F4F5F5"
											: "transparent"
									}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
									>
										<img
											src={UnKnownImage}
											alt="img"
											width={20}
											height={20}
										/>
										<Typography fontSize={13} pl={1}>
											{user.name}
										</Typography>
									</Stack>
								</Stack>
							)
					)
				)}
				{isShowMoreButtonVisible && (
					<Typography
						sx={{ cursor: "pointer" }}
						fontSize={13}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
						px={2}
						py={1.5}
						onClick={openAssignUserDialog}
					>
						Show More
					</Typography>
				)}
			</Menu>

			<Box pl={1} pb={1.5}>
				<Typography color="rgba(0, 0, 0, 0.6)" fontSize={14}>
					Assign Roles and permissions to different set of users from
					here.
				</Typography>
			</Box>

			<Box
				ref={tableRef}
				sx={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					height: `calc(100vh - ${tableStartingPosition + 8}px)`,
					overflowY: "auto",
				}}
			>
				<TableContainer sx={{ maxHeight: "100%" }}>
					<Table stickyHeader sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow>
								{tableHeader.map((header, index) => (
									<TableCell
										key={header.id}
										width={header.config.width}
										sx={
											header.id === 2
												? styles.noPadding
												: {}
										}
										onClick={() => toggleSort(index)}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												{header.displayName}
											</TableHeaderLabel>
											<Box display="flex">
												{!isActionColumn(
													index,
													tableHeader
												) ? (
													header.sort ===
													sortTypes.ascending ? (
														<Box
															style={{
																transform:
																	"rotate(180deg)",
															}}
															pt={0.5}
														>
															{DropDownIcon(
																13,
																13,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>
													) : (
														DropDownIcon(
															13,
															13,
															"rgba(0, 0, 0, 0.6)"
														)
													)
												) : (
													""
												)}
											</Box>
										</Stack>
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{isLoadingRolesListData ? (
								<TableRow
									style={{
										height: "60vh",
									}}
								>
									<TableCell
										colSpan={5}
										sx={{ borderBottom: 0 }}
									>
										<Stack
											height="100%"
											justifyContent="center"
											alignItems="center"
											width="100%"
										>
											<CircularProgress />
										</Stack>
									</TableCell>
								</TableRow>
							) : (
								rolesListData?.pages?.map((page) =>
									page?.roles?.map((role) => (
										<TableRow
											key={role.id}
											onMouseOver={() =>
												onMouseOverTableRow(role)
											}
											onMouseOut={onMouseOutTableRow}
										>
											<TableCell>
												<TableCellText>
													{role.name}
												</TableCellText>
											</TableCell>

											<TableCell
												sx={{ px: 0 }}
												onClick={
													role.usersCount > 0
														? (event) =>
																openUserMenu(
																	event,
																	role.id
																)
														: toggleAssignUserDrawer
												}
											>
												<TableCellText
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
													onClick={(event) =>
														handleAssignUser(
															event,
															role
														)
													}
												>
													{role.usersCount
														? `${role.usersCount} User`
														: "+ Assign User"}
												</TableCellText>
											</TableCell>

											<TableCell>
												{!!role.createdBy && (
													<Tooltip
														title={getDayMonthDateAndTimeFormat(
															role.createdTime
														)}
														placement="right"
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
															width="fit-content"
														>
															<img
																src={
																	UnKnownImage
																}
																alt="img"
																width={26}
																height={26}
															/>

															<TableCellText>
																{
																	role
																		.createdBy
																		.name
																}
															</TableCellText>
														</Stack>
													</Tooltip>
												)}
											</TableCell>

											<TableCell>
												{!!role.updatedBy && (
													<Tooltip
														title={getDayMonthDateAndTimeFormat(
															role.updatedTime
														)}
														placement="right"
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
															width="fit-content"
														>
															<img
																src={
																	UnKnownImage
																}
																alt="img"
																width={26}
																height={26}
															/>

															<TableCellText>
																{
																	role
																		.updatedBy
																		.name
																}
															</TableCellText>
														</Stack>
													</Tooltip>
												)}
											</TableCell>

											<TableCell sx={{ px: 0, py: 0 }}>
												<Stack alignItems="center">
													<Box
														hidden={
															hoveredRowId !==
																role.id &&
															tableRowHovered !==
																role.id
														}
														style={{
															width: "fit-content",
															border: `1px solid ${theme.palette.primary.main}`,
															borderRadius: "8px",
															padding: "2px 8px",
														}}
													>
														<IconButton
															size="small"
															onClick={(event) =>
																openRolesMenu(
																	event,
																	role
																)
															}
														>
															{MoreIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													</Box>
												</Stack>
											</TableCell>
										</TableRow>
									))
								)
							)}
							<TableRow>
								<TableCell
									colSpan={8}
									sx={{ borderBottom: "none" }}
								>
									<Box ref={lastRowRef}>
										{!isLoadingRolesListData &&
											isFetching && (
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="center"
													spacing={1}
													py={2}
												>
													<CircularProgress
														size={18}
													/>

													<Typography fontSize={12}>
														Loading More Data
													</Typography>
												</Stack>
											)}
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</React.Fragment>
	);
}
