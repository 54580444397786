import React, { useEffect, useState } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import DealAction from "../DealAction";
import Dialog from "../../Elements/Dialog";
import ClosedWon from "../Summary/ClosedWon";
import ClosedLost from "../Summary/ClosedLost";
import KanbanViewStageLoader from "../../Elements/Loader/KanbanViewLoader/KanbanViewStageLoader";
import {
	useDeleteMutation,
	useUpdateStageWithDragAndDropMutation,
} from "../../../hooks/services/deal";
import DeleteDialog from "../../Elements/DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { useStagesByPipeline } from "../../../hooks/services/deal/pipeline";
import StageColumn from "./StageColumn";

const dealActions = {
	WON: "wonAction",
	LOST: "lostAction",
	DELETE: "deleteAction",
};

export default function KanbanView(props) {
	const {
		menuBarRef,
		selectedPipelineId,
		isSwipeableFilterOpened,
		criteria,
	} = props;
	const theme = useTheme();

	const [showDealAction, setShowDealAction] = useState(false);
	const [menuBarEndingPosition, setMenuBarEndingPosition] = useState(0);
	const [isClosedWonDialogOpened, setIsClosedWonDialogOpened] =
		useState(false);
	const [isClosedLostDialogOpened, setIsClosedLostDialogOpened] =
		useState(false);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [dragAndDropDetails, setDragAndDropDetails] = useState({});

	const { data: pipelineStages, isLoading: isLoadingPipelineStages } =
		useStagesByPipeline(selectedPipelineId);

	const updateStageMuation = useUpdateStageWithDragAndDropMutation(criteria);
	const deleteMutation = useDeleteMutation();

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarEndingPosition(
				menuBarRef?.current?.getBoundingClientRect().bottom
			);
		}
	}, [menuBarRef]);

	const onBeforeCapture = () => {
		setShowDealAction(true);
	};

	const getDealPipelineStage = (source, destination) => {
		if (dealActions.WON === destination.droppableId) {
			return pipelineStages?.[pipelineStages?.length - 2];
		} else if (dealActions.LOST === destination.droppableId) {
			return pipelineStages?.[pipelineStages?.length - 1];
		} else if (dealActions.DELETE === destination.droppableId) {
			return pipelineStages?.[source?.droppableId];
		} else {
			return pipelineStages?.[destination?.droppableId];
		}
	};

	const isWonStage = (droppableId) => {
		return (
			parseInt(droppableId) + 1 === pipelineStages?.length - 1 ||
			dealActions.WON === droppableId
		);
	};

	const isLostStage = (droppableId) => {
		return (
			parseInt(droppableId) + 1 === pipelineStages?.length ||
			dealActions.LOST === droppableId
		);
	};

	const onDragEnd = (result) => {
		const { destination, draggableId, source } = result;
		setShowDealAction(false);
		if (source?.droppableId !== destination?.droppableId) {
			if (isLostStage(destination?.droppableId)) {
				setIsClosedLostDialogOpened(true);
				setDragAndDropDetails({
					dealId: draggableId,
					sourceStageId: pipelineStages?.[source.droppableId]?.id,
					sourceIndex: source.index,
					destinationIndex: destination.index,
					destinationStageId:
						dealActions.LOST === destination?.droppableId
							? getDealPipelineStage(source, destination)?.id
							: pipelineStages?.[destination.droppableId]?.id,
				});
			} else if (isWonStage(destination?.droppableId)) {
				setIsClosedWonDialogOpened(true);
				setDragAndDropDetails({
					dealId: draggableId,
					sourceStageId: pipelineStages?.[source.droppableId]?.id,
					sourceIndex: source.index,
					destinationIndex: destination.index,
					destinationStageId:
						dealActions.WON === destination?.droppableId
							? getDealPipelineStage(source, destination)?.id
							: pipelineStages?.[destination.droppableId]?.id,
				});
			} else if (dealActions.DELETE === destination?.droppableId) {
				openDeleteDialog();
				setDragAndDropDetails({
					...dragAndDropDetails,
					dealId: draggableId,
				});
			} else {
				let requestData = {};
				requestData.id = draggableId;
				requestData.pipelineStageId =
					pipelineStages?.[destination.droppableId]?.id;
				requestData.pipelineId = selectedPipelineId;
				requestData.source = pipelineStages?.[source.droppableId]?.id;
				requestData.sourceIndex = source.index;
				requestData.destination =
					pipelineStages?.[destination.droppableId]?.id;
				requestData.destinationIndex = destination.index;
				updateStageMuation.mutate(requestData, {
					onSuccess: () => {
						setIsClosedLostDialogOpened(false);
						setIsClosedWonDialogOpened(false);
					},
				});
			}
		}
	};

	const handleUpdateDealState = (requestData) => {
		let requestDataWithDragDropDetails = {
			...requestData,
			source: dragAndDropDetails.sourceStageId,
			destination: dragAndDropDetails.destinationStageId,
			sourceIndex: dragAndDropDetails.sourceIndex,
			destinationIndex: dragAndDropDetails.destinationIndex,
		};

		updateStageMuation.mutate(requestDataWithDragDropDetails, {
			onSuccess: () => {
				setIsClosedLostDialogOpened(false);
				setIsClosedWonDialogOpened(false);
			},
			onError: (e) => {
				console.error(e);
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
				setIsClosedLostDialogOpened(false);
				setIsClosedWonDialogOpened(false);
			},
		});
	};

	const CloseClosedWonDialog = () => {
		setIsClosedWonDialogOpened(false);
	};

	const CloseClosedLostDialog = () => {
		setIsClosedLostDialogOpened(false);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const onDeleteDeal = () => {
		deleteMutation.mutate(dragAndDropDetails?.dealId, {
			onSuccess: () => {
				closeDeleteDialog();
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealDeleted,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title={"Are you sure you want to delete this deal?"}
				subtitle="It will delete the deal and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days."
				confirmButtonText="Delete"
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={onDeleteDeal}
			></DeleteDialog>

			<Dialog open={isClosedWonDialogOpened} title="Closed Won">
				<ClosedWon
					dealId={dragAndDropDetails?.dealId}
					pipelineId={selectedPipelineId}
					pipelineStageId={dragAndDropDetails?.destinationStageId}
					handleDealWon={handleUpdateDealState}
					closeWonDialog={CloseClosedWonDialog}
				/>
			</Dialog>

			<Dialog open={isClosedLostDialogOpened} title="Closed Lost">
				<ClosedLost
					dealId={dragAndDropDetails?.dealId}
					pipelineId={selectedPipelineId}
					pipelineStageId={dragAndDropDetails?.destinationStageId}
					handleDealClose={handleUpdateDealState}
					closeLostDialog={CloseClosedLostDialog}
				/>
			</Dialog>

			{isLoadingPipelineStages ? (
				<Box
					sx={{
						backgroundColor: "#F4F5F5",
						overflow: "hidden",
					}}
					style={{
						marginLeft: isSwipeableFilterOpened ? "250px" : null,
					}}
				>
					<Stack direction="row">
						<KanbanViewStageLoader
							menuBarEndingPosition={menuBarEndingPosition}
						/>
					</Stack>
				</Box>
			) : (
				<Box
					sx={{ backgroundColor: "#F4F5F5" }}
					style={{
						marginLeft: isSwipeableFilterOpened ? "250px" : null,
						transition: isSwipeableFilterOpened
							? theme.transitions.create("margin", {
									easing: theme.transitions.easing.easeOut,
									duration:
										theme.transitions.duration
											.enteringScreen,
								})
							: theme.transitions.create("margin", {
									easing: theme.transitions.easing.sharp,
									duration:
										theme.transitions.duration
											.leavingScreen,
								}),
						height: `calc(100vh - ${menuBarEndingPosition}px)`,
					}}
				>
					<Box
						style={{
							backgroundColor: "#F4F5F5",
							overflowY: "hidden",
							height: "100%",
						}}
					>
						<DragDropContext
							onBeforeCapture={onBeforeCapture}
							onDragEnd={onDragEnd}
						>
							<Stack direction="row" height="100%">
								{Array.isArray(pipelineStages)
									? pipelineStages.map((stage, index) => (
											<StageColumn
												key={stage.id}
												stageIndex={index}
												stage={stage}
												criteria={criteria}
											/>
										))
									: null}
							</Stack>

							<Box
								style={{
									display: "flex",
									justifyContent: "center",
									opacity: showDealAction ? 1 : 0,
									position: "fixed",
									bottom: 10,
									zIndex: 3333,
									pointerEvents: showDealAction
										? "all"
										: "none",
									width: "100%",
								}}
							>
								<DealAction />
							</Box>
						</DragDropContext>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
