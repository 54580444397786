import { getRootKey } from "../rootKey";

const emailSyncKey = "emailSync";

const getEmailSyncKey = () => {
	return [...getRootKey(), emailSyncKey]; // return email sync key
};

// query key methods:-
export const getEmailSyncSettingsKey = () => {
	return [...getEmailSyncKey(), "emailSyncSettings"];
};

export const getEmailSyncStatusKey = (id) => {
	return [...getEmailSyncKey(), String(id), "emailSyncStatus"];
};
