import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import PriceTagsBaseComponent from "./PriceTagsBaseComponent";

export default function PriceTagsComponent({ field, subField }) {
	const { control } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={{
					required: field.config.required
						? field.fieldName + " is required"
						: false,
				}}
				render={({
					field: { onChange, value },
					fieldState: { error },
				}) => (
					<PriceTagsBaseComponent
						onChange={onChange}
						value={value}
						subField={subField}
						field={field}
						error={error}
					/>
				)}
				defaultValue={null}
			></Controller>
		</React.Fragment>
	);
}
