import { Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";
import { Controller } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { useTagDropdownList } from "../../../../hooks/services/tags";
import TagsInput from "../../AddForm/Field/FieldComponents/TagsComponent/TagsBaseComponent/TagsInput";

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const TagFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		// query call:-
		const { data: tagsList } = useTagDropdownList();

		const {
			handleSubmit,
			formState: { errors },
			control,
			clearErrors,
		} = useForm({ values: { [fieldId]: value } });

		const [selectedTags, setSelectedTags] = useState([]);

		const getTagNameById = (tagId) => {
			let tagData = tagsList?.find((tag) => tag?.value === tagId);
			if (tagData) {
				return tagData.name;
			}
		};

		useEffect(() => {
			if (Array.isArray(value)) {
				const getSelectedTag = (tagData) => {
					if (tagData?.name) {
						return tagData?.name;
					} else if (tagData?.id) {
						return getTagNameById(tagData.id);
					} else {
						return tagData?.tag;
					}
				};

				let formattedTagValues = [];

				for (let tagData of value) {
					let selectedTag = getSelectedTag(tagData);
					if (selectedTag) {
						formattedTagValues.push(selectedTag);
					}
				}
				setSelectedTags(formattedTagValues);
			}
		}, [value, tagsList]);

		const handleSelectTags = (values, onChange) => {
			setSelectedTags(values);
			let tagsForRequest = [];
			for (let tag of values) {
				let tagId = getTagIdByName(tag);
				if (tagId) {
					tagsForRequest.push({ id: tagId });
				} else {
					tagsForRequest.push({ tag: tag });
				}
			}
			onChange(tagsForRequest);
			clearErrors(fieldId + "");
			onError(null);
		};

		const getTagIdByName = (tagName) => {
			let tagData = tagsList?.find((tag) => tag?.name === tagName);
			if (tagData) {
				return tagData.value;
			}
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<TagsInput
							{...fieldData}
							isTagAdditionAllowed={false}
							tagsList={tagsList || []}
							selectedTags={selectedTags}
							handleSelectTags={(data) =>
								handleSelectTags(data, onChange)
							}
							onClearErrors={() =>
								clearErrors(field.fieldId + "")
							}
							placeHolder={field?.placeHolder}
							textFieldSx={textFieldSx}
						/>
					)}
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

TagFieldEditForm.displayName = "TagFieldEditForm";

export default TagFieldEditForm;
