import React, { useEffect, useState } from "react";
import {
	Box,
	MenuItem,
	Stack,
	TextField,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import Field from "./Field";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import Menu from "../../../../../../Elements/Menu";
import ToggleButtonGroup from "../../../../../../Elements/ToggleButtonGroup";
import MenuSearchBar from "../../../../../../Elements/MenuSearchBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";
import { useFieldList } from "../../../../../../../hooks/services/webform";

const Fields = (props) => {
	const { handleFieldCreate, availableFields } = props;
	const isField = true;

	return (
		<React.Fragment>
			<Box>
				<Box px={0.5} py={1}>
					<MenuSearchBar />
				</Box>
				<Stack>
					{availableFields.map((field) => (
						<MenuItem
							key={field.fieldId}
							onClick={() => handleFieldCreate(field, isField)}
						>
							<Typography fontSize={13}>{field.name}</Typography>
						</MenuItem>
					))}
				</Stack>
			</Box>
		</React.Fragment>
	);
};

const Elements = (props) => {
	const { handleFieldCreate } = props;

	const elementList = getDropdownDataByName("ELEMENT");

	return (
		<React.Fragment>
			<Box pt={1}>
				{elementList.map((element) => (
					<MenuItem
						key={element.value}
						onClick={() => handleFieldCreate(element)}
					>
						<Typography fontSize={13}>{element.name}</Typography>
					</MenuItem>
				))}
			</Box>
		</React.Fragment>
	);
};

export default function FormEditor(props) {
	const theme = useTheme();
	const { currentFormData, updateFormData, onResetFormError } = props;

	const [fieldMenuElement, setFieldMenuElement] = useState(null);
	const isFieldMenuOpened = Boolean(fieldMenuElement);
	const [type, setType] = useState("fields");
	const [isFormNameEdited, setIsFormNameEdited] = useState(false);
	const [isDescriptionEdited, setIsDescriptionEdited] = useState(false);
	const [isSubmitButtonValueEdited, setIsSubmitButtonValueEdited] =
		useState(false);
	const [formFields, setFormFields] = useState([
		...currentFormData.formProperties,
	]);
	const [hoveredFieldId, setHoveredFieldId] = useState(null);
	const [isSubmitFieldHovered, setIsSubmitFieldHovered] = useState(null);
	const [fieldIndex, setFieldIndex] = useState(null);
	const [availableFields, setAvailableFields] = useState([]);
	const [isFormNameIsEmpty, setIsFormNameIsEmpty] = useState(false);
	const [isSubmitButtonNameIsEmpty, setIsSubmitButtonNameIsEmpty] =
		useState(false);

	const onMouseOverSubmitField = (event) => {
		setIsSubmitFieldHovered(event.currentTarget);
		setHoveredFieldId(null);
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setType(type);
		}
	};

	useEffect(() => {
		if (isFormNameIsEmpty || isSubmitButtonNameIsEmpty) {
			onResetFormError(true);
		} else {
			onResetFormError(false);
		}
	}, [isFormNameIsEmpty, isSubmitButtonNameIsEmpty, onResetFormError]);

	const styles = {
		headerStyle: {
			fontSize: 13,
			fontWeight: 500,
		},
		contentStyle: {
			fontSize: 14,
		},
		formNameBorderStyle: {
			border: isFormNameIsEmpty
				? `1px solid ${theme.palette.error.main}`
				: "1px solid #E6E6E6",
			borderRadius: "8px 8px 0px 0px",
		},
		descriptionBorderStyle: {
			border: "1px solid #E6E6E6",
			borderRadius: "0px 0px 8px 8px",
			borderTop: "0px",
		},
		submitButtonBorderStyle: {
			border: isSubmitButtonNameIsEmpty
				? `1px solid ${theme.palette.error.main}`
				: "1px solid #E6E6E6",
			borderRadius: "8px",
		},
	};

	const openFieldMenu = (event, item, index) => {
		setFieldMenuElement(event.currentTarget);
		setHoveredFieldId(item?.fieldId);
		setFieldIndex(index);
	};

	const openFieldMenuNearSubmitButton = (event) => {
		setFieldMenuElement(event.currentTarget);
		setFieldIndex(formFields.length);
	};

	const CloseFieldMenu = () => {
		setFieldMenuElement(null);
	};

	const handleFormNameEdit = () => {
		setIsFormNameEdited(true);
	};

	const handleBlurFormName = () => {
		setIsFormNameEdited(false);
	};

	const handleChangeFormName = (event) => {
		const { value } = event.target;
		if (value === "") {
			setIsFormNameIsEmpty(true);
		} else {
			setIsFormNameIsEmpty(false);
		}
		const updatedFormName = { ...currentFormData, formName: value };
		updateFormData(updatedFormName);
	};

	const handleDescription = () => {
		setIsDescriptionEdited(true);
	};

	const handleBlurDescription = () => {
		setIsDescriptionEdited(false);
	};

	const handleChangeFormDescription = (event) => {
		const { value } = event.target;
		const updatedFormDescription = {
			...currentFormData,
			description: value,
		};
		updateFormData(updatedFormDescription);
	};

	const handleSubmitButton = () => {
		setIsSubmitButtonValueEdited(true);
	};

	const handleBlurSubmitButtonName = () => {
		setIsSubmitButtonValueEdited(false);
	};

	const handleSubmitButtonName = (event) => {
		const { value } = event.target;
		if (value === "") {
			setIsSubmitButtonNameIsEmpty(true);
		} else {
			setIsSubmitButtonNameIsEmpty(false);
		}
		const updatedSubmitButtonName = {
			...currentFormData,
			submitButtonName: value,
		};
		updateFormData(updatedSubmitButtonName);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const rearrangeOnDrag = (updatedData) => {
			let index = 0;
			while (index < updatedData.length) {
				let updatedOrderValue = index + 1;
				updatedData[index].config.order = updatedOrderValue;
				index++;
			}
			return updatedData;
		};

		const updatedData = reorder(
			currentFormData.formProperties,
			result.source.index,
			result.destination.index
		);
		const orderOnDrag = rearrangeOnDrag(updatedData);
		updateFormData({ ...currentFormData, formProperties: orderOnDrag });
		setFormFields([...orderOnDrag]);
	};

	const handleFieldCreate = (field, isField) => {
		let newField = {};

		if (isField) {
			newField = {
				type: "FIELD",
				fieldType: {
					displayName: field.fieldType.displayName,
					name: field.fieldType.name,
				},
				fieldId: field.fieldId,
				isFieldNotSaved: true,
				label: field.name,
				config: {
					isRequired: false,
					order: "",
				},
			};
		} else {
			newField = {
				type: "ELEMENT",
				name: field.name,
				config: {
					order: "",
				},
				fieldId: "field_" + (formFields.length + 1),
				isFieldNotSaved: true,
				label: "",
			};
		}

		setFormFields((formFields) => {
			const updatedFields = [...formFields];
			updatedFields.splice(fieldIndex, 0, newField);
			return updatedFields;
		});

		setFieldMenuElement(null);

		const fieldListValue = fieldListData.filter(
			(option) =>
				!formFields?.some((field) => field.label === option.name)
		);
		setAvailableFields(fieldListValue);
	};

	const onMouseOverField = (id) => {
		setHoveredFieldId(id);
		setIsSubmitFieldHovered(null);
	};

	//form fields data
	const { status: fieldListStatus, data: fieldListData } = useFieldList();

	useEffect(() => {
		if (fieldListStatus === "success") {
			if (fieldListData) {
				const fieldListValue = fieldListData.filter(
					(option) =>
						!formFields?.some(
							(field) => field.fieldId === option.fieldId
						)
				);
				setAvailableFields(fieldListValue);
			}
		}
	}, [fieldListStatus, fieldListData, formFields]);

	//To delete a field
	const handleDeleteField = (index) => {
		const newField = [...formFields];
		newField.splice(index, 1);
		setFormFields(newField);
		updateFormData({ ...currentFormData, formProperties: newField });
	};

	//To update a field
	const handleFieldUpdate = (fieldName, isFieldRequired, index) => {
		const updateFieldDetails = [...formFields];
		updateFieldDetails[index] = {
			...updateFieldDetails[index],
			label: fieldName,
			config: {
				...updateFieldDetails[index].config,
				isRequired: isFieldRequired,
			},
		};
		setFormFields(updateFieldDetails);
		updateFormData({
			...currentFormData,
			formProperties: updateFieldDetails,
		});
	};

	//To add a field
	const handleFieldAddition = (fieldName, isFieldRequired, index) => {
		const updatedFieldData = [...formFields];
		updatedFieldData[index] = {
			...updatedFieldData[index],
			label: fieldName,
			config: {
				...updatedFieldData[index].config,
				isRequired: isFieldRequired,
				order: index + 1,
			},
		};
		//To update the order of the field
		updatedFieldData.forEach(
			(field, index) => (field.config.order = index + 1)
		);
		updateFormData({
			...currentFormData,
			formProperties: updatedFieldData,
		});
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="323px"
				anchorEl={fieldMenuElement}
				open={isFieldMenuOpened}
				onClose={CloseFieldMenu}
				PaperProps={{
					style: { maxHeight: "400px" },
				}}
			>
				<Stack justifyContent="flex-start" alignItems="stretch">
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
					>
						<Box>
							<ToggleButtonGroup
								value={type}
								onChange={handleToggleButtonGroup}
							>
								<ToggleButton value="fields">
									Fields
								</ToggleButton>
								<ToggleButton value="elements">
									Elements
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
					</Stack>

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: "0px",
						}}
					>
						{(function () {
							switch (type) {
								case "fields":
									return (
										<Fields
											handleFieldCreate={
												handleFieldCreate
											}
											availableFields={availableFields}
										/>
									);
								case "elements":
									return (
										<Elements
											handleFieldCreate={
												handleFieldCreate
											}
										/>
									);
								default:
									return null;
							}
						})()}
					</Box>
				</Stack>
			</Menu>

			<Stack>
				<Stack
					justifyContent="center"
					alignItems="center"
					position="relative"
					py={2.5}
				>
					<Stack width="95%" spacing={4}>
						<Stack>
							<Stack
								style={styles.formNameBorderStyle}
								px={2}
								py={1.5}
								onMouseOver={() => setHoveredFieldId(null)}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={0.5}
								>
									<Typography style={styles.headerStyle}>
										Form Name
									</Typography>
									<Typography
										color={theme.palette.error.main}
									>
										*
									</Typography>
								</Stack>
								{!isFormNameEdited ? (
									<Box
										style={{ cursor: "pointer" }}
										onClick={handleFormNameEdit}
									>
										{currentFormData.formName ? (
											<Typography
												style={styles.contentStyle}
											>
												{currentFormData.formName}
											</Typography>
										) : (
											<Typography
												sx={{
													fontSize: 14,
													opacity: 0.6,
												}}
											>
												Enter Form Name Here
											</Typography>
										)}
									</Box>
								) : (
									<TextField
										variant="standard"
										value={currentFormData.formName}
										autoFocus
										onBlur={handleBlurFormName}
										onChange={handleChangeFormName}
										sx={{
											width: "25%",
										}}
										inputProps={{
											style: {
												padding: 0,
												fontSize: 14,
											},
										}}
									/>
								)}
							</Stack>

							<Stack
								px={2}
								py={1.5}
								style={styles.descriptionBorderStyle}
							>
								<Typography style={styles.headerStyle}>
									Description
								</Typography>
								{!isDescriptionEdited ? (
									<Box
										style={{ cursor: "pointer" }}
										onClick={handleDescription}
									>
										{currentFormData.description ? (
											<Typography
												style={styles.contentStyle}
											>
												{currentFormData.description}
											</Typography>
										) : (
											<Typography
												sx={{
													fontSize: 14,
													opacity: 0.6,
												}}
											>
												Enter Description Here
											</Typography>
										)}
									</Box>
								) : (
									<TextField
										variant="standard"
										value={currentFormData.description}
										autoFocus
										onBlur={handleBlurDescription}
										onChange={handleChangeFormDescription}
										sx={{ width: "45%" }}
										inputProps={{
											style: {
												padding: 0,
												fontSize: 14,
											},
										}}
									/>
								)}
							</Stack>
						</Stack>

						<Stack>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="field-list">
									{(provided) => (
										<Stack
											ref={provided.innerRef}
											{...provided.droppableProps}
											spacing={3.5}
										>
											{formFields?.map(
												(formProperty, index) => (
													<Draggable
														draggableId={index.toString()}
														index={index}
														key={
															formProperty.fieldId
														}
													>
														{(provided) => (
															<Box
																ref={
																	provided.innerRef
																}
																{...provided.draggableProps}
																sx={{
																	marginBottom:
																		"8px",
																}}
																onMouseOver={() =>
																	onMouseOverField(
																		formProperty.fieldId
																	)
																}
																// onMouseLeave={() => isSubmitFieldHovered(null)}
															>
																{hoveredFieldId ===
																	formProperty.fieldId && (
																	<Stack
																		width="80%"
																		alignItems="flex-end"
																		position="absolute"
																		zIndex={
																			1
																		}
																		marginTop={
																			-4.3
																		}
																	>
																		<Stack
																			sx={{
																				borderRadius:
																					"100%",
																				p: 1,
																				border: "1px solid #E6E6E6",
																				backgroundColor:
																					theme
																						.palette
																						.secondary
																						.main,
																				boxShadow:
																					"0px 2px 4px rgba(0, 0, 0, 0.08)",
																				cursor: "pointer",
																			}}
																			onClick={(
																				event
																			) =>
																				openFieldMenu(
																					event,
																					formProperty,
																					index
																				)
																			}
																		>
																			{AddIcon(
																				18,
																				18,
																				theme
																					.palette
																					.primary
																					.main
																			)}
																		</Stack>
																	</Stack>
																)}
																<Field
																	formProperty={
																		formProperty
																	}
																	index={
																		index
																	}
																	dragHandleProps={
																		provided.dragHandleProps
																	}
																	isField={
																		formProperty.type ===
																		"FIELD"
																	}
																	handleDeleteField={
																		handleDeleteField
																	}
																	handleFieldUpdate={
																		handleFieldUpdate
																	}
																	handleFieldAddition={
																		handleFieldAddition
																	}
																	fieldListData={
																		fieldListData
																	}
																/>
															</Box>
														)}
													</Draggable>
												)
											)}
											{provided.placeholder}
										</Stack>
									)}
								</Droppable>
							</DragDropContext>
						</Stack>

						<Stack
							px={2}
							py={1.5}
							style={styles.submitButtonBorderStyle}
							onMouseOver={(event) =>
								onMouseOverSubmitField(event)
							}
						>
							{isSubmitFieldHovered && (
								<Stack
									width="80%"
									alignItems="flex-end"
									position="absolute"
									zIndex={1}
									marginTop={-5.9}
									marginLeft={-2}
								>
									<Stack
										sx={{
											borderRadius: "100%",
											p: 1,
											border: "1px solid #E6E6E6",
											backgroundColor:
												theme.palette.secondary.main,
											boxShadow:
												"0px 2px 4px rgba(0, 0, 0, 0.08)",
											cursor: "pointer",
										}}
										onClick={(event) =>
											openFieldMenuNearSubmitButton(event)
										}
									>
										{AddIcon(
											18,
											18,
											theme.palette.primary.main
										)}
									</Stack>
								</Stack>
							)}

							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								<Typography style={styles.headerStyle}>
									Submit Button Name
								</Typography>
								<Typography color={theme.palette.error.main}>
									*
								</Typography>
							</Stack>
							{!isSubmitButtonValueEdited ? (
								<Box
									style={{ cursor: "pointer" }}
									onClick={handleSubmitButton}
								>
									{currentFormData.submitButtonName ? (
										<Typography style={styles.contentStyle}>
											{currentFormData.submitButtonName}
										</Typography>
									) : (
										<Typography
											sx={{ fontSize: 14, opacity: 0.6 }}
										>
											Enter submit button name here
										</Typography>
									)}
								</Box>
							) : (
								<TextField
									variant="standard"
									value={currentFormData.submitButtonName}
									autoFocus
									onBlur={handleBlurSubmitButtonName}
									onChange={(event) =>
										handleSubmitButtonName(event)
									}
									sx={{ width: "25%" }}
									inputProps={{
										style: {
											padding: 0,
											fontSize: 14,
										},
									}}
								/>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
