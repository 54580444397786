import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useUpdateBaseCurrencyMutation } from "../../../../hooks/services/currency";
import { default as InfoIcon } from "../../../../assets/icons/info";
import { Tooltip } from "../../../../styles/twozo";
import ConfirmDialog from "../../../Elements/ConfirmDialog";

export default function CurrencyDecimal(props) {
	const { selectedDecimalId, updateDecimalId, currencyData } = props;
	const [isDecimalDialogOpen, setIsDecimalDialogOpen] = useState(false);
	const updateBaseCurrency = useUpdateBaseCurrencyMutation();

	const decimalList = [
		{
			id: 1,
			value: "No Decimals",
		},
		{
			id: 2,
			value: 1,
		},
		{
			id: 3,
			value: 2,
		},
		{
			id: 4,
			value: 3,
		},
		{
			id: 5,
			value: 4,
		},
	];

	const handleCloseDecimalDialog = () => {
		updateDecimalId(currencyData?.decimalPoint.id);
		setIsDecimalDialogOpen(false);
	};

	const handleSelectDecimal = (event) => {
		const { value } = event.target;
		updateDecimalId(value);
		setIsDecimalDialogOpen(true);
	};

	const handleUpdateCurrencyDecimal = (selectedDecimalId) => {
		const request = { decimalPointId: selectedDecimalId };
		updateBaseCurrency.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.currencyDecimalUpdated,
				});
				setIsDecimalDialogOpen(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={isDecimalDialogOpen}
				title="Are you sure you want to change the currency decimal for your company?"
				onCancel={handleCloseDecimalDialog}
				onConfirm={() => handleUpdateCurrencyDecimal(selectedDecimalId)}
				confirmButtonText="Yes"
				cancelButtonText="No"
				confirmButtonColor="primary"
			/>

			<Box py={1}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography fontSize={16} fontWeight={600}>
						Currency Decimal
					</Typography>

					<Tooltip
						title={
							<Typography fontSize={14} fontWeight={400}>
								Decimal places for your currency values can be
								chosen. Remember that this setting just impacts
								how the values are displayed; it does not change
								the value.
							</Typography>
						}
						placement="bottom"
						PopperProps={{
							modifiers: [
								{
									name: "offset",
									options: {
										offset: [120, -8],
									},
								},
							],
						}}
					>
						<Box display="flex">
							<IconButton
								size="small"
								sx={{
									padding: 0,
								}}
							>
								{InfoIcon(20, 20, "#000")}
							</IconButton>
						</Box>
					</Tooltip>
				</Stack>

				<Box pt={1} pb={2}>
					<Typography fontSize={13} fontWeight={500} pl={1} pb={0.5}>
						Select the no. of decimal places
					</Typography>

					<Select
						onChange={handleSelectDecimal}
						value={selectedDecimalId}
						sx={{ width: "528px" }}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 290,
								},
							},
						}}
						renderValue={(selectedNumber) => {
							let selectedValue = decimalList.find((number) => {
								return number.id === selectedNumber;
							});

							return selectedValue?.value;
						}}
					>
						{decimalList?.map((decimalValue) => (
							<MenuItem
								key={decimalValue.id}
								value={decimalValue.id}
								style={{
									fontSize: "13px",
									fontWeight: 400,
									height: "40px",
								}}
							>
								{decimalValue.value}
							</MenuItem>
						))}
					</Select>
				</Box>
			</Box>

			<Divider />
		</React.Fragment>
	);
}
