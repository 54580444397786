import React, { useEffect, useRef, useState } from "react";
import { twozoStyles } from "../../styles/twozo";
import { Box } from "@mui/material";
import ListView from "./ListView";
import { useSearchParams } from "react-router-dom";
import CalendarView from "./CalendarView";
import ActivitySummary from "./ActivitySummary";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import { activityView } from "../../utils/ActivitiesUtils";
import { FilterDataProvider } from "../Elements/Table/Filter/Context/FilterDataContext";

export default function Activities() {
	const classes = twozoStyles();
	const activityRef = useRef();
	const [queryParams, setQueryParams] = useSearchParams();

	const [elementStatingPosition, setElementStatingPosition] = useState(64);
	const [view, setView] = useState(
		queryParams.get("view") || activityView.list
	);
	const [isActivitySummaryOpened, setIsActivitySummaryOpened] =
		useState(false);
	const [activityId, setActivityId] = useState(null);

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			setView(type);
			setQueryParams({ view: type }, { replace: true });
		}
	};

	const toggleSwipeableActivitySummaryDialog = (id) => {
		setActivityId(id);
		setIsActivitySummaryOpened(
			(isActivitySummaryOpened) => !isActivitySummaryOpened
		);
	};

	useEffect(() => {
		if (activityRef.current) {
			setElementStatingPosition(
				activityRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						backgroundColor: "transparent",
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				open={isActivitySummaryOpened}
				onOpen={toggleSwipeableActivitySummaryDialog}
				onClose={toggleSwipeableActivitySummaryDialog}
				disableSwipeToOpen
				hideBackdrop
			>
				<Box className={classes.addDialogContainer}>
					<ActivitySummary
						onClose={toggleSwipeableActivitySummaryDialog}
						activityId={activityId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box
				ref={activityRef}
				style={{
					height: `calc(100% - ${elementStatingPosition + 1}px)`,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					{(function () {
						switch (view) {
							case activityView.list:
								return (
									<ListView
										openActivitySummaryDialog={
											toggleSwipeableActivitySummaryDialog
										}
										handleViewToggleButtonGroup={
											handleViewToggleButtonGroup
										}
									/>
								);
							case activityView.calendar:
								return (
									<FilterDataProvider>
										<CalendarView
											openActivitySummaryDialog={
												toggleSwipeableActivitySummaryDialog
											}
											handleViewToggleButtonGroup={
												handleViewToggleButtonGroup
											}
										/>
									</FilterDataProvider>
								);
							default:
								return null;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}
