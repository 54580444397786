import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Can from "../../../../../Auth/Can";
import { PERMISSIONS } from "../../../../../../utils/Auth";
import CanNot from "../../../../../Auth/CanNot";

export default function RenderCompanyOnboardFieldValue(props) {
	const { fieldValue } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Can permission={PERMISSIONS.company.view}>
				<Box
					onClick={() =>
						navigate(`/companies/${fieldValue?.id?.toString()}`)
					}
					sx={{ cursor: "pointer" }}
				>
					<Typography
						fontSize={14}
						fontWeight={500}
						color={theme.palette.primary.main}
					>
						{fieldValue?.name}
					</Typography>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.company.view}>-</CanNot>
		</React.Fragment>
	);
}
