const userFields = [
	{
		id: 1,
		name: "Reporting Manager",
		isReportingManger: true,
	},
	{
		id: 2,
		name: "Pipeline",
		isPipeline: true,
	},
	{
		id: 3,
		name: "Role",
		isRole: true,
	},
];

export default userFields;
