import { getRootKey } from "../../../rootKey";

const permissionKey = "permission";

export const getPermissionKey = () => {
	return [...getRootKey(), permissionKey];
};

export const getDefaultRolePermissionKey = (id) => {
	return [...getPermissionKey(), id];
};
