import React, { useEffect, useMemo, useState } from "react";
import { alpha, Box, Button, Stack, ToggleButton } from "@mui/material";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import Permissions from "./Permissions";
import FieldList from "./FieldList";
import AssignedUserList from "./AssignedUserList";
import { usePermissionContext } from "../PermissionContext";
import { getPermissionSetRequest } from "../../../../../utils/PermissionUtils";
import {
	useAssginedUserDetails,
	useCreateRole,
	useUpdateRoleDetails,
} from "../../../../../hooks/services/userManagement/roles";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { permissionDialogTypes } from "../../../../../utils/PermissionUtils/PermissionDialog";
import Can from "../../../../Auth/Can";
import { PERMISSIONS } from "../../../../../utils/Auth";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function PermissionDialog(props) {
	const {
		selectedRole,
		isCreateRole,
		roleName,
		defaultSelectedAccess,
		isEditable,
		onClose,
		defaultPermissionType,
	} = props;
	const [type, setType] = useState("");
	const [assignedUserDetails, setAssignedUserDetails] = useState([]);
	const { permissionState } = usePermissionContext();
	const updateMutation = useUpdateRoleDetails(selectedRole);
	const createMutation = useCreateRole();

	const {
		data: userData,
		isLoading: isUserDataLoading,
		status: userDataStatus,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useAssginedUserDetails(selectedRole, !isCreateRole);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const isAsignUserDetailsLoading = !isCreateRole && isUserDataLoading;

	useEffect(() => {
		if (userDataStatus === "success") {
			updateAssignUserDetails(userData || []);
		}
	}, [userDataStatus, userData]);

	useEffect(() => {
		setType(
			defaultPermissionType
				? defaultPermissionType
				: permissionDialogTypes.Permissions
		);
	}, [defaultPermissionType]);

	const updateAssignUserDetails = (userDetails) => {
		setAssignedUserDetails(userDetails);
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setType(type);
		}
	};

	const assignedUserIds = useMemo(() => {
		if (assignedUserDetails.length > 0) {
			return assignedUserDetails?.map((user) => user?.id);
		} else return [];
	}, [assignedUserDetails]);

	const updateRole = () => {
		const request = getPermissionSetRequest(
			isCreateRole,
			roleName,
			selectedRole,
			permissionState,
			assignedUserIds
		);
		updateMutation.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.permissionUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const createRole = () => {
		const request = getPermissionSetRequest(
			isCreateRole,
			roleName,
			selectedRole,
			permissionState,
			assignedUserIds
		);
		createMutation.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.roleCreated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const updateRoleDetails = () => {
		if (isCreateRole) {
			createRole();
		} else {
			updateRole();
		}
	};

	const dialogBoxStyle = {
		height: "100%",
		width: "100%",
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box px={2} pt={2} pb={1}>
					<ToggleButtonGroup
						value={type}
						onChange={handleToggleButtonGroup}
					>
						<ToggleButton value={permissionDialogTypes.Permissions}>
							Permissions
						</ToggleButton>
						<ToggleButton value={permissionDialogTypes.Fields}>
							Fields
						</ToggleButton>
						<ToggleButton
							value={permissionDialogTypes.AssignedUsers}
						>
							Assigned Users
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>

				<Box
					px={2}
					py={1}
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
						minHeight: "0px",
					}}
				>
					<Box
						hidden={type !== permissionDialogTypes.Permissions}
						style={{
							...dialogBoxStyle,
						}}
					>
						<Permissions
							roleId={selectedRole}
							isEditable={isCreateRole ? true : isEditable}
							isCreateRole={isCreateRole}
							defaultSelectedAccess={defaultSelectedAccess}
						/>
					</Box>
					<Box
						hidden={type !== permissionDialogTypes.Fields}
						style={{ ...dialogBoxStyle }}
					>
						<FieldList />
					</Box>
					<Box
						hidden={type !== permissionDialogTypes.AssignedUsers}
						style={{ ...dialogBoxStyle }}
					>
						<AssignedUserList
							assignedUserDetails={assignedUserDetails}
							updateAssignUserDetails={updateAssignUserDetails}
							isUserDataLoading={isAsignUserDetailsLoading}
							isFetching={isFetching}
							lastRowRef={lastRowRef}
						/>
					</Box>
				</Box>

				<Box>
					<Stack
						direction="row"
						justifyContent="flex-end"
						pt={1}
						pb={3}
						pr={4}
						sx={{
							height: "68px",
						}}
					>
						<Can permission={PERMISSIONS.role.edit}>
							<Button
								variant="contained"
								disableElevation
								disabled={
									createMutation.isLoading ||
									updateMutation.isLoading
								}
								sx={{
									"&.Mui-disabled": {
										backgroundColor: "rgba(51, 188, 126)",
										color: alpha("#FFFFFF", 0.6),
									},
								}}
								onClick={() => updateRoleDetails()}
							>
								Save
							</Button>
						</Can>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
