import { useInfiniteQuery } from "@tanstack/react-query";
import {
	getContactCompletedActivityKey,
	getContactToDoAcitivityKey,
} from "../../../../utils/queryKeys";
import { getActivitiesData } from "../../../../api/activity/activityApi";
import { modules } from "../../../../utils/common/ModulesName";
import {
	completedActivityListPageSize,
	todoActivityListPageSize,
} from "../../../../utils/queryConstants/summary/activity";

let contactSource = modules.CONTACT;

const activitiesSelect = (todoActivityData) => {
	return todoActivityData.pages.flatMap((page) => page?.activities);
};

const useContactToDoActivities = (contactId, activityTypeIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getContactToDoAcitivityKey(
			contactId,
			activityTypeIdsToFilter
		),
		queryFn: ({ pageParam }) => {
			return getActivitiesData(
				contactId,
				false,
				contactSource,
				activityTypeIdsToFilter,
				{
					start: pageParam
						? (pageParam - 1) * todoActivityListPageSize + 1
						: 1,
					limit: todoActivityListPageSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

const useContactCompletedActivities = (contactId, activityTypeIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getContactCompletedActivityKey(
			contactId,
			activityTypeIdsToFilter
		),
		queryFn: ({ pageParam }) => {
			return getActivitiesData(
				contactId,
				true,
				contactSource,
				activityTypeIdsToFilter,
				{
					start: pageParam
						? (pageParam - 1) * completedActivityListPageSize + 1
						: 1,
					limit: completedActivityListPageSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

export { useContactToDoActivities, useContactCompletedActivities };
