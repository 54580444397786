import React from "react";

export default function Forward(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M14.9284 5L20 10.07L14.9284 15.14"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.40993 19.5701V13.0701C4.40993 11.4132 5.75308 10.0701 7.40993 10.0701H19.8298"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
