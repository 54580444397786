import { Box, Divider } from "@mui/material";
import React from "react";
import SummaryCard from "./SummaryCard";

export default function SummaryCardView(props) {
	const { summaryFields, summarySubFields } = props;
	const firstRow = 0;
	const secondRow = 1;
	const isDividerVisible = (index) => {
		return index < summaryFields[firstRow].length - 1;
	};
	return (
		<React.Fragment>
			{summaryFields?.length > 0
				? summaryFields[firstRow].map((summaryFirstField, index) => (
						<Box key={index} sx={{ display: "flex" }}>
							<Box minWidth="284px" height="48px">
								<table>
									<SummaryCard
										summaryFirstField={summaryFirstField}
										summarySecondField={
											summaryFields.length > 1
												? summaryFields[secondRow][
														index
													]
												: []
										}
										summarySubFields={summarySubFields}
									/>
								</table>
							</Box>
							{isDividerVisible(index) ? (
								<Divider
									orientation="vertical"
									flexItem
									sx={{ marginLeft: "16px" }}
								/>
							) : null}
						</Box>
					))
				: null}
		</React.Fragment>
	);
}
