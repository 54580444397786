import { getProductsUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import { apiClient } from "../..";

export const bulkCreateVariation = async (data) => {
	let requestData = getBulkCreateVariationRequest(data);
	return await postVariationManagementApi(requestData);
};

export const createVariation = async (data) => {
	let requestData = getCreateVariationRequest(data);
	return await postVariationManagementApi(requestData);
};

export const getVariationListData = async (id) => {
	let requestData = getVariationListRequest(id);
	return await postVariationManagementApi(requestData);
};

export const getVariationPriceList = async (id) => {
	let requestData = getVariationPriceListRequest(id);
	return await postProductManagementApi(requestData);
};

export const updatePriceTag = async (data) => {
	let requestData = getVariationPriceUpdateRequest(data);
	return await postVariationManagementApi(requestData);
};

export const partialUpdateVariation = async (data) => {
	let requestData = getPartialUpdateRequest(data);
	return await postVariationManagementApi(requestData);
};

export const deletePriceTag = async (id) => {
	let requestData = getVariationDeletePriceRequest(id);
	return await postProductManagementApi(requestData);
};

export const deleteVariation = async (id) => {
	let requestData = getVariationDeleteRequest(id);
	return await postVariationManagementApi(requestData);
};

const postVariationManagementApi = async (requestData) => {
	let variationManagementUrl = getVariationManagementUrl();
	let response = await apiClient.post(variationManagementUrl, requestData);
	assertError(response, variationManagementUrl);
	return response.data.data;
};

const postProductManagementApi = async (requestData) => {
	let productManagementUrl = getProductManagementUrl();
	let response = await apiClient.post(productManagementUrl, requestData);
	assertError(response, productManagementUrl);
	return response.data.data;
};

const getBulkCreateVariationRequest = (data) => {
	return {
		type: "bCreate",
		data: {
			list: data,
		},
	};
};

const getCreateVariationRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getVariationListRequest = (id) => {
	return {
		type: "list",
		data: {
			productId: id,
		},
	};
};

const getVariationPriceListRequest = (id) => {
	return {
		type: "getVariationPriceTags",
		data: {
			variationId: id,
		},
	};
};

const getVariationPriceUpdateRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getPartialUpdateRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getVariationDeletePriceRequest = (id) => {
	return {
		type: "deletePriceTag",
		data: {
			id: id,
		},
	};
};

const getVariationDeleteRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getVariationManagementUrl = () => {
	return getProductsUrl() + "/variation/mgmt";
};

const getProductManagementUrl = () => {
	return getProductsUrl() + "/mgmt";
};
