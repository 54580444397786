import React from "react";

function TrimmedContentIcon(width = "19", height = "9", color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 19 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="19" height="9" rx="4.5" fill="#D9D9D9" />
			<rect
				opacity="0.4"
				x="3"
				y="3"
				width="3"
				height="3"
				rx="1.5"
				fill={color}
			/>
			<rect
				opacity="0.4"
				x="8"
				y="3"
				width="3"
				height="3"
				rx="1.5"
				fill={color}
			/>
			<rect
				opacity="0.4"
				x="13"
				y="3"
				width="3"
				height="3"
				rx="1.5"
				fill={color}
			/>
		</svg>
	);
}

export default TrimmedContentIcon;
