import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Dialog from "../../../../Elements/Dialog";
import AddActivity from "./AddEditActivityForm";

export default function AddEditAcitivityDialog(props) {
	const {
		openAddEditActivityTypeDialog,
		handleCloseAddEditActivityTypeDialog,
		isAddActivityMode,
		activityTypeData,
	} = props;

	const [title, setTitle] = useState("");
	const addActivityTypeTitle = "Add Activity Type";
	const editActivityTypeTitle = "Edit Activity Type";

	useEffect(() => {
		if (isAddActivityMode) {
			setTitle(addActivityTypeTitle);
		} else {
			setTitle(editActivityTypeTitle);
		}
	}, [isAddActivityMode]);

	return (
		<React.Fragment>
			<Dialog
				open={openAddEditActivityTypeDialog}
				onClose={handleCloseAddEditActivityTypeDialog}
				title={title}
			>
				<Box p={2}>
					<AddActivity
						activityTypeData={activityTypeData}
						handleCloseAddEditActivityTypeDialog={
							handleCloseAddEditActivityTypeDialog
						}
						isAddActivityMode={isAddActivityMode}
					/>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
