import React, { useState, useEffect, useRef } from "react";
import { Box, Paper, Stack } from "@mui/material";
import { default as DragIcon } from "../../../../../assets/icons/drag";
import { useFieldListContext } from "../../FieldListContext";
import FieldName from "../FieldElements/FieldName";
import { useFieldContext } from "../FieldContext";
import FieldView from "../FieldElements/FieldView";
import FieldType from "../FieldElements/FieldType";
import FieldGroupedButtons from "../FieldElements/FieldGrouppedButtons";
import MultiselectChoices from "./MultiSelectFieldChoices";
import { useFieldDropdown } from "../../../../../hooks/services/dataFields";

export default function MultiSelectField(props) {
	const {
		field,
		fieldTypeOption,
		clearCreatedField,
		moduleName,
		changeFieldType,
	} = props;

	const initializeUnSavedFieldDataRef = useRef(false);

	// field and field list context
	const { fieldListState, updateEditModeOnFieldEdit, resetUpdateMode } =
		useFieldListContext();

	const {
		fieldState,
		shouldShowFieldActions,
		isDependableField,
		onFieldNameValueChange,
		onAddViewChange,
		onRequiredFieldChange,
		onFieldValueChange,
		onSelectChoicesChanged,
		hasSelectChoiceChanged,
		initializeUnSavedFieldData,
	} = useFieldContext(field);

	const [fieldHovered, setFieldHovered] = useState(false);
	const [multiselectChoices, setMultiselectChoices] = useState([]);

	const onMouseEnterField = () => setFieldHovered(true);
	const onMouseLeaveField = () => setFieldHovered(false);

	const isMultiselectFieldTypeEnabled = !field.isFieldNotSaved;
	const { status, data: multiselectFieldTypeData } = useFieldDropdown(
		field.id,
		isMultiselectFieldTypeEnabled
	);

	useEffect(() => {
		if (status === "success") {
			if (multiselectFieldTypeData) {
				let multiSelectOptions = multiselectFieldTypeData?.map(
					(choice) => ({ ...choice })
				);
				updateMultiselectChoices(multiSelectOptions);
			}
		}
	}, [status, multiselectFieldTypeData]);

	useEffect(() => {
		// The first time the field data comes, it's stored in field context unsavedFieldData.

		if (field && !initializeUnSavedFieldDataRef.current) {
			initializeUnSavedFieldData(
				field.fieldName,
				!!field.config.addView,
				!!field.config.required
			);
			initializeUnSavedFieldDataRef.current = true;
		}
	}, [field]);

	useEffect(() => {
		if (
			!!fieldState.unSavedFieldData.isRequired ||
			!!fieldState.unSavedFieldData.isAddView ||
			!!fieldState.unSavedFieldData.fieldName
		) {
			if (
				fieldState.unSavedFieldData.isRequired !==
					!!field.config.required ||
				fieldState.unSavedFieldData.isAddView !==
					!!field.config.addView ||
				fieldState.unSavedFieldData.fieldName !== field.fieldName
			) {
				onFieldValueChange(true);
			} else {
				onFieldValueChange(false);
			}
		}
	}, [
		field,
		fieldState.unSavedFieldData.isRequired,
		fieldState.unSavedFieldData.isAddView,
		fieldState.unSavedFieldData.fieldName,
	]);

	useEffect(() => {
		if (
			Array.isArray(multiselectFieldTypeData) &&
			multiselectChoices.length > 0
		) {
			if (multiselectChoices.length !== multiselectFieldTypeData.length) {
				hasSelectChoiceChanged(true);
			} else {
				hasSelectChoiceChanged(false);

				for (
					let index = 0;
					index < multiselectChoices.length;
					index++
				) {
					let changedChoiceName = multiselectChoices[index].name;
					let choiceName = multiselectFieldTypeData[index].name;
					let changedChoiceOrder = multiselectChoices[index].order;
					let choiceOrder = multiselectFieldTypeData[index].order;
					let changedDefaultChoice =
						multiselectChoices[index]?.isDefault;
					let defaultChoice =
						multiselectFieldTypeData[index]?.isDefault;

					if (
						changedChoiceName !== choiceName ||
						choiceOrder !== changedChoiceOrder ||
						defaultChoice !== changedDefaultChoice
					) {
						hasSelectChoiceChanged(true);
					}
				}
			}
		}
	}, [multiselectChoices, multiselectFieldTypeData]);

	useEffect(() => {
		if (
			fieldState.isFieldValueChanged ||
			fieldState.isSelectChoiceChanged ||
			fieldState.isFieldNameInEditMode
		) {
			updateEditModeOnFieldEdit();
		} else {
			resetUpdateMode();
		}
	}, [
		fieldState.isFieldValueChanged,
		fieldState.isSelectChoiceChanged,
		fieldState.isFieldNameInEditMode,
	]);

	const handleDeleteClick = () => {
		props.handleOpenDeleteDialog(field);
	};

	const hasCreationError = () => {
		return fieldState.fieldInCreateMode && fieldListState.hasCreationError;
	};

	const hasUpdateError = () => {
		return (
			fieldState.isFieldNameInEditMode && fieldListState.hasUpdateError
		);
	};

	const isFieldEditingRestricted = () => {
		return (
			(fieldListState.isAnyFieldInEditMode &&
				!fieldState.isFieldNameInEditMode &&
				!fieldState.isFieldValueChanged &&
				!fieldState.isSelectChoiceChanged) ||
			(fieldListState.isAnyFieldInCreateMode &&
				!fieldState.fieldInCreateMode)
		);
	};

	const onCancelField = () => {
		onFieldNameValueChange(field.fieldName);
		onAddViewChange(!!field.config.addView);
		onRequiredFieldChange(!!field.config.required);
		let multiSelectOptions = multiselectFieldTypeData?.map((choice) => ({
			...choice,
		}));
		updateMultiselectChoices(multiSelectOptions);
	};

	const onClosedMultiselectChoiceMenu = () => {
		setFieldHovered(false);
	};

	const updateMultiselectChoices = (choices) => {
		setMultiselectChoices(choices);
		onSelectChoicesChanged(choices);
	};

	return (
		<>
			<Box>
				<Paper
					elevation={0}
					onMouseEnter={onMouseEnterField}
					onMouseLeave={onMouseLeaveField}
					sx={{
						height: "56px",
						opacity: isFieldEditingRestricted() ? 0.6 : 1,
						border: (theme) =>
							!isDependableField &&
							`1px solid ${
								hasCreationError() || hasUpdateError()
									? theme.palette.error.main
									: theme.palette.divider
							}`,
						borderRadius: (theme) => theme.spacing(1),
						padding: (theme) => theme.spacing(1),
						boxShadow: fieldHovered
							? "0px 2px 4px rgba(0, 0, 0, 0.1)"
							: "none",
						cursor: "pointer",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						px={1}
						height="100%"
					>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={2}
							width="80%"
						>
							<Box
								width="25px"
								display="flex"
								{...props.dragHandleProps}
							>
								{shouldShowFieldActions &&
									DragIcon(
										24,
										24,
										"grey",
										field.config.isImmovable && 0.2
									)}
							</Box>

							<Stack
								direction="row"
								width="50%"
								spacing={1.5}
								alignItems="center"
							>
								<FieldName field={field} />

								<FieldType
									field={field}
									fieldTypeOption={fieldTypeOption}
									changeFieldType={changeFieldType}
								/>

								<MultiselectChoices
									field={field}
									onClosedMultiselectChoiceMenu={
										onClosedMultiselectChoiceMenu
									}
									isFieldEditingRestricted={isFieldEditingRestricted()}
									multiselectChoices={multiselectChoices}
									updateMultiselectChoices={
										updateMultiselectChoices
									}
								/>
							</Stack>

							<FieldView
								field={field}
								isFieldEditingRestricted={isFieldEditingRestricted()}
							/>
						</Stack>

						<FieldGroupedButtons
							field={field}
							moduleName={moduleName}
							fieldHovered={fieldHovered}
							onCancelField={onCancelField}
							handleDeleteClick={handleDeleteClick}
							clearCreatedField={clearCreatedField}
						/>
					</Stack>
				</Paper>
			</Box>
		</>
	);
}
