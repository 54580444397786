import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldId } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";
import { useEffect, useState } from "react";
import MultiSelectBaseComponent from "../MultiSelectComponent/MultiSelectBaseComponent";

const getSelectTagsList = (data) => {
	return (
		data?.options?.map((data) => {
			return { value: { id: data.id || data.value, name: data.name } };
		}) || []
	);
};

export default function TagsComponent(params) {
	const { field, onChange, hasError, style } = params;

	const [selectedTags, setSelectedTags] = useState([]);

	const { data: tagsList } = useQuery(
		getDropdownKeyByFieldId(field.fieldId),
		() => getFieldDropdownData({ name: field.dropdown.name }),
		{
			enabled: !!field?.fieldId,
			select: getSelectTagsList,
		}
	);

	const handleChangeTags = (tags) => {
		setSelectedTags(tags);
		field["values"] = tags;
		if (onChange) {
			onChange(tags);
		}
	};

	useEffect(() => {
		if (
			Array.isArray(field?.values) &&
			tagsList &&
			Array.isArray(tagsList)
		) {
			let selectedTagsIds = field.values.map((tag) =>
				tag.value?.id?.toString()
			);
			let selectedTagsList = tagsList.filter((tag) =>
				selectedTagsIds.includes(tag.value?.id?.toString())
			);
			setSelectedTags(selectedTagsList);
		}
	}, [field, tagsList]);

	return (
		<>
			<MultiSelectBaseComponent
				style={style}
				hasError={hasError}
				selectedValues={selectedTags}
				options={tagsList || []}
				onFieldChange={handleChangeTags}
			/>
		</>
	);
}
