import React, { useMemo, useState } from "react";
import { Typography, Box, MenuItem, Stack, useTheme } from "@mui/material";
import profileImage from "../../../../../../assets/images/contact/unknownContact.png";
import MenuSearchBar from "../../../../MenuSearchBar";
import { useFilterDataContext } from "../../Context/FilterDataContext";

export default function SalesOwnerList(props) {
	const {
		salesOwnerList,
		closeFavouriteMenu,
		updateCurrentlyAppliedFilterId,
		appliedFilterId,
	} = props;
	const theme = useTheme();

	const {
		filterListByCondition,
		deactivateEditMode,
		updateFilterCount,
		updateFilterCondition,
		updateActiveFilterName,
		updateActiveFilterDetails,
		updateActiveFilterConditions,
	} = useFilterDataContext();

	const [searchInput, setSearchInput] = useState("");

	const handleSearchedSalesOwner = (event) => {
		const { value } = event.target;
		setSearchInput(value);
	};

	const getFilteredOptionsList = useMemo(() => {
		if (searchInput.trim()) {
			return salesOwnerList?.filter((owner) =>
				owner.name.toLowerCase().startsWith(searchInput.toLowerCase())
			);
		}
		return salesOwnerList;
	}, [searchInput, salesOwnerList]);

	const applyFilterFromList = (filter) => {
		filterListByCondition({ filterId: filter.id });
		updateActiveFilterName(filter.name);
		updateCurrentlyAppliedFilterId(filter.id);
		updateFilterCondition([]);
		updateActiveFilterConditions([]);
		updateActiveFilterDetails({});
		closeFavouriteMenu();
		deactivateEditMode();
		updateFilterCount(0);
	};

	return (
		<Box>
			{
				<Stack>
					<Box px={0.5} pb={1.5}>
						<MenuSearchBar
							value={searchInput}
							onChange={handleSearchedSalesOwner}
						/>
					</Box>

					{getFilteredOptionsList.length >= 1 ? (
						getFilteredOptionsList?.map((owner) => (
							<MenuItem
								key={owner.id}
								style={{
									height: "40px",
									display: "flex",
									alignItems: "center",
									backgroundColor:
										appliedFilterId === owner.id
											? theme.palette.secondary.main
											: "transparent",
								}}
							>
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									<img
										src={profileImage}
										alt="img"
										width={20}
										height={20}
									/>

									<Stack width="140px">
										<Typography
											noWrap
											fontSize={13}
											onClick={() =>
												applyFilterFromList(owner)
											}
										>
											{owner.name}
										</Typography>
									</Stack>
								</Stack>
							</MenuItem>
						))
					) : (
						<Typography
							style={{
								textAlign: "center",
								padding: 10,
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							No Results Found
						</Typography>
					)}
				</Stack>
			}
		</Box>
	);
}
