import React from "react";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as FileUploadCompletedIcon } from "../../../.././assets/icons/filesUploadDone";
import { useImportStatusList } from "../../../.././hooks/services/import";

export default function ImportFinished(props) {
	const { importedFileId, setStep, onClose, moduleName } = props;
	const theme = useTheme();

	const { data: importStatusData, isLoading: isLoadingImportStatus } =
		useImportStatusList(importedFileId);

	return (
		<React.Fragment>
			{isLoadingImportStatus || !importStatusData?.isDone ? (
				<Stack
					justifyContent="center"
					alignItems="center"
					height="80vh"
				>
					<CircularProgress />
				</Stack>
			) : (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100%"
				>
					<Box pb={1}>
						{FileUploadCompletedIcon(
							38,
							38,
							theme.palette.primary.main
						)}
					</Box>

					<Typography fontWeight={500}>Import Finished!</Typography>

					<Typography fontSize="14px" color="rgba(0, 0, 0, 0.6)">
						{importStatusData?.inserted
							? `${importStatusData?.inserted} Items added`
							: "0 Items added"}
						,&nbsp;
						{importStatusData?.merged
							? `${importStatusData?.merged} Merged and`
							: "0 Merged and"}
						&nbsp;
						{importStatusData?.updated
							? `${importStatusData?.updated} updated`
							: "0 updated"}
						,&nbsp;
						{importStatusData?.skipped
							? `${importStatusData?.skipped} Skipped`
							: "0 Skipped"}
						.
						<span
							style={{
								color: theme.palette.secondary.contrastText,
								cursor: "pointer",
							}}
						>
							&nbsp; Learn More
						</span>
					</Typography>

					<Stack direction="row" spacing={1} pt={3}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => setStep(0)}
							disableElevation
						>
							Import More
						</Button>

						<Button
							variant="contained"
							onClick={onClose}
							disableElevation
						>
							Go to {moduleName}
						</Button>
					</Stack>
				</Stack>
			)}
		</React.Fragment>
	);
}
