import React, { useState } from "react";
import { Box, Stack, Typography, useTheme, Popover } from "@mui/material";
import { Tooltip } from "../../../../../styles/twozo";
import { default as CallIcon } from "../../../../../assets/icons/call";
import { default as MailIcon } from "../../../../../assets/icons/mail";
import { default as UnKnownImage } from "../../../../../assets/images/contact/unknownContact.png";

export default function MostActiveUsersProgressBar(props) {
	const { mostActiveUsers } = props;
	const theme = useTheme();
	const [hoveredActiveUserId, setHoveredActiveUserId] = useState(null);
	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);

	const onMouseEnterUserProgressBar = (event, id) => {
		setUserMenuElement(event.currentTarget);
		setHoveredActiveUserId(id);
	};

	const onMouseLeaveUserProgressBar = () => {
		setUserMenuElement(null);
		setHoveredActiveUserId(null);
	};

	return (
		<React.Fragment>
			<Popover
				sx={{
					pointerEvents: "none",
					transform: "translateY(45px)",
				}}
				open={isUserMenuOpened}
				anchorEl={userMenuElement}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						boxShadow: "0px 4px 24px 0px #00000014",
					},
				}}
				onClose={onMouseLeaveUserProgressBar}
			>
				{mostActiveUsers?.map((activeUser) => (
					<Box key={activeUser.user.id} minWidth="320px">
						{activeUser?.user.id === hoveredActiveUserId && (
							<Box px={2} py={2}>
								<Stack direction="row" spacing={2}>
									<Box height="42px" width="42px">
										<img
											src={
												activeUser?.user.image
													? activeUser.user.image
													: UnKnownImage
											}
											alt={activeUser.user.name}
											style={{
												width: "100%",
												height: "100%",
											}}
										/>
									</Box>

									<Stack>
										<Typography
											fontSize={18}
											fontWeight={600}
											color={
												theme.palette.secondary
													.contrastText
											}
											noWrap
										>
											{activeUser?.user.name}
										</Typography>

										{!!activeUser?.user.phone && (
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
												pt={0.5}
											>
												{CallIcon(
													16,
													16,
													theme.palette.primary.main
												)}

												<Typography
													fontSize={14}
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													{activeUser.user.phone}
												</Typography>
											</Stack>
										)}
										{!!activeUser?.user.email && (
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												{MailIcon(
													16,
													16,
													theme.palette.primary.main
												)}

												<Typography
													fontSize={14}
													color={
														theme.palette.secondary
															.contrastText
													}
													noWrap
												>
													{activeUser.user.email}
												</Typography>
											</Stack>
										)}
									</Stack>
								</Stack>
							</Box>
						)}
					</Box>
				))}
			</Popover>
			<Box
				style={{
					display: "flex",
					flexDirection: "row",
					height: "44px",
					borderRadius: "10px",
					overflow: "hidden",
				}}
			>
				{mostActiveUsers?.map((activeUser, index) => (
					<Tooltip
						title={`${activeUser.count} Activities ${
							activeUser.count ? "•" : ""
						} ${activeUser.percentage}%`}
						key={index}
						position="start"
					>
						<Box
							style={{
								height: "100%",
								width: `${activeUser.percentage}%`,
								backgroundColor: `rgba(74, 234, 234, ${
									(mostActiveUsers.length - index) /
									mostActiveUsers.length
								})`,
								display: "flex",
								alignItems: "center",
								paddingLeft: "16px",
								cursor: "pointer",
							}}
							onMouseEnter={
								activeUser.user
									? (event) =>
											onMouseEnterUserProgressBar(
												event,
												activeUser?.user.id
											)
									: onMouseLeaveUserProgressBar
							}
							onMouseLeave={onMouseLeaveUserProgressBar}
						>
							<Box
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "50px",
									width: 28,
									height: 28,
								}}
							>
								<img
									src={
										activeUser?.user.image
											? activeUser.user.image
											: UnKnownImage
									}
									alt={activeUser?.user.name}
									style={{
										width: "100%",
										height: "100%",
										borderRadius: "50%",
									}}
								/>
							</Box>
						</Box>
					</Tooltip>
				))}
			</Box>
		</React.Fragment>
	);
}
