import React from "react";
import ManageDuplicates from "../../Elements/ManageDuplicates";

export default function ManageDuplicatesContact() {
	return (
		<React.Fragment>
			<ManageDuplicates
				moduleName="All Contacts"
				navigateURL="/contacts"
			/>
		</React.Fragment>
	);
}
