import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createPipeline,
	deletePipeline,
	deletePipelineStage,
	getPipelineListData,
	getStagesByPipeline,
	updateDefaultPipeline,
	updatePipeline,
	updatePipelineOrder,
	updatePipelineStage,
	updatePipelineStageOrder,
} from "../../../../api/pipeline/pipelineApi";
import {
	getStagesByPipelineKey,
	pipelineListKey,
} from "../../../../utils/queryKeys";

const useInvalidatePipelineList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(pipelineListKey);
	};
};

const usePipelineList = () => {
	return useQuery(pipelineListKey, () => getPipelineListData(), {
		select: (data) => data.list,
	});
};

const useStagesByPipeline = (pipelineId) => {
	const stagesByPipelineKey = getStagesByPipelineKey(pipelineId);
	return useQuery(
		stagesByPipelineKey,
		() => getStagesByPipeline(pipelineId),
		{
			enabled: Boolean(pipelineId),
			select: (data) => data.data,
		}
	);
};

// Create Pipeline
const useCreatePipeline = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(createPipeline, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Update Pipeline
const useUpdatePipeline = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(updatePipeline, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Delete Pipeline
const useDeletePipeline = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(deletePipeline, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Update Default Pipeline
const useDefaultPipeline = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(updateDefaultPipeline, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Update Pipeline Order
const useUpdatePipelineOrder = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(updatePipelineOrder, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Update Pipeline Stage Order
const useUpdatePipelineStageOrder = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(updatePipelineStageOrder, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Update PipelineStage
const useUpdatePipelineStage = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(updatePipelineStage, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

// Delete Pipeline Stage
const useDeletePipelineStage = () => {
	let invalidatePipelineList = useInvalidatePipelineList();
	return useMutation(deletePipelineStage, {
		onSuccess: () => {
			invalidatePipelineList();
		},
	});
};

export {
	useCreatePipeline,
	useUpdatePipeline,
	useDeletePipeline,
	useDefaultPipeline,
	usePipelineList,
	useUpdatePipelineOrder,
	useUpdatePipelineStage,
	useDeletePipelineStage,
	useUpdatePipelineStageOrder,
	useStagesByPipeline,
};
