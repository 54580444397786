import React from "react";
import {
	Box,
	Radio,
	Stack,
	Typography,
	useTheme,
	Divider,
} from "@mui/material";
import contactImage from "../../../../assets/images/contact/unknownContact.png";
import { default as RadioOffIcon } from "../../../../assets/icons/radioOff";
import { default as RadioTickedIcon } from "../../../../assets/icons/radioTicked";
import { Tooltip } from "../../../../styles/twozo";

const styles = {
	subText: {
		fontSize: 13,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: 13,
		fontWeight: 500,
	},
};

const DetailsCard = ({ duplicates, theme }) => {
	const supplementaryEmails = duplicates?.emails?.slice(1);
	const supplementaryPhones = duplicates?.emails?.slice(1);

	const tooltipEmailList = (
		<React.Fragment>
			{supplementaryEmails &&
				supplementaryEmails.map((email, index) => (
					<div key={index}>{email}</div>
				))}
		</React.Fragment>
	);

	const tooltipPhoneList = (
		<React.Fragment>
			{supplementaryPhones &&
				supplementaryPhones.map((phone, index) => (
					<div key={index}>{phone}</div>
				))}
		</React.Fragment>
	);

	return (
		<Box minWidth="280px">
			<table>
				<tbody>
					<tr>
						<td>
							<Typography fontSize={14} pr={3}>
								Email :
							</Typography>
						</td>
						<td>
							<Stack direction="row">
								{duplicates?.emails?.[0] ? (
									<Typography fontSize={14}>
										{duplicates.emails[0]}
									</Typography>
								) : (
									"-"
								)}

								{duplicates?.emails &&
									duplicates?.emails.length > 1 && (
										<Tooltip
											title={tooltipEmailList}
											placement="top"
										>
											<Typography
												fontSize={14}
												color={
													theme.palette.primary.main
												}
											>
												&nbsp; +
												{duplicates?.emails.length - 1}
											</Typography>
										</Tooltip>
									)}
							</Stack>
						</td>
					</tr>
					<tr>
						<td>
							<Typography fontSize={14} pr={3}>
								Phone :
							</Typography>
						</td>
						<td>
							<Stack direction="row">
								{duplicates?.phones?.[0] ? (
									<Typography fontSize={14}>
										{duplicates.phones[0]}
									</Typography>
								) : (
									"-"
								)}

								{duplicates?.phones &&
									duplicates?.phones.length > 1 && (
										<Tooltip
											title={tooltipPhoneList}
											placement="top"
										>
											<Typography
												fontSize={14}
												color={
													theme.palette.primary.main
												}
											>
												&nbsp; +
												{duplicates?.phones.length - 1}
											</Typography>
										</Tooltip>
									)}
							</Stack>
						</td>
					</tr>
				</tbody>
			</table>
		</Box>
	);
};

function DuplicatesFound(props) {
	const { duplicateList, handleSelectContact, contactId } = props;
	const theme = useTheme();

	const hasDuplicateRecords =
		duplicateList &&
		duplicateList?.pages?.length > 0 &&
		duplicateList?.pages[0]?.duplicates &&
		duplicateList?.pages[0]?.duplicates?.length > 0;

	return (
		<React.Fragment>
			<Box>
				<Box p={1} ml={2.5}>
					<Typography fontSize={13}>
						{duplicateList?.pages[0]?.totalRecords} &nbsp;
						{duplicateList?.pages[0]?.totalRecords === 1
							? "Duplicate Found"
							: "Duplicates Found"}
					</Typography>
				</Box>

				{hasDuplicateRecords &&
					duplicateList?.pages.map((page) =>
						page.duplicates?.map((duplicateData) => (
							<Box
								key={duplicateData.id}
								sx={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									ml: "16px",
									mr: "16px",
									mb: "8px",
									cursor: "pointer",
								}}
								onClick={() =>
									handleSelectContact(duplicateData.id)
								}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={2}
									p={2}
									boxShadow="inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
								>
									<Stack>
										<img
											src={contactImage}
											alt="contact_image"
											height="48px"
											width="48px"
										/>
									</Stack>
									<Stack>
										<Stack>
											<Typography
												fontWeight={500}
												fontSize={14}
												color={
													theme.palette.secondary
														.contrastText
												}
											>
												{duplicateData.fullName}
											</Typography>
										</Stack>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											{duplicateData.company ? (
												<Typography
													style={styles.subText}
												>
													{duplicateData.company}
												</Typography>
											) : null}

											{duplicateData.company &&
											duplicateData.primaryEmail ? (
												<Typography
													style={styles.subText}
												>
													&#8226;
												</Typography>
											) : null}

											{duplicateData.primaryEmail ? (
												<Typography
													style={styles.subText}
												>
													{duplicateData.primaryEmail}
												</Typography>
											) : null}

											{(duplicateData.company &&
												duplicateData.primaryPhone) ||
											(duplicateData.primaryEmail &&
												duplicateData.primaryPhone) ? (
												<Typography
													style={styles.subText}
												>
													&#8226;
												</Typography>
											) : null}

											{duplicateData.primaryPhone ? (
												<Typography
													style={styles.subText}
												>
													{duplicateData.primaryPhone}
												</Typography>
											) : null}
										</Stack>
									</Stack>
									<Stack flexGrow={1} />
									<Stack>
										<Radio
											icon={RadioOffIcon(
												22,
												22,
												theme.palette.primary.main
											)}
											checkedIcon={RadioTickedIcon(
												22,
												22,
												theme.palette.primary.main
											)}
											value={duplicateData.id}
											checked={
												contactId === duplicateData.id
											}
										/>
									</Stack>
								</Stack>

								<Stack
									direction="row"
									alignItems="center"
									p={2}
								>
									<Stack pr={2}>
										<Typography
											style={styles.text}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{duplicateData.matches.totalMatches}{" "}
											{duplicateData.matches
												.totalMatches === 1
												? "Match"
												: "Matches"}
										</Typography>
									</Stack>
									<Stack direction="row" spacing={2}>
										<Divider
											orientation="vertical"
											flexItem
										/>
										<DetailsCard
											duplicates={duplicateData?.matches}
											theme={theme}
										/>
									</Stack>
								</Stack>
							</Box>
						))
					)}
			</Box>
		</React.Fragment>
	);
}

export default DuplicatesFound;
