import React from "react";
import AddForm from "../AddForm";
import { useUserData } from "../../../../../hooks/services/userManagement/user";

export default function EditUser(props) {
	const { onClose, userId, isEditable } = props;
	const { data: userData, isLoading: isUserDataLoading } =
		useUserData(userId);

	const getformatedData = (data) => {
		let formData = { ...data };

		formData.reportingManagerId = formData["reportingManagerId"]?.id;
		formData.pipelineId = formData["pipeline"]?.id;
		formData.roleId = formData["role"]?.id;

		if (formData["teams"]) {
			let formatTeamsData = [];
			for (let teams of formData["teams"]) {
				formatTeamsData.push(teams?.id);
			}
			formData.teamIds = formatTeamsData;
		}

		delete formData.pipeline;
		delete formData.role;
		delete formData.teams;
		delete formData.createdTime;
		delete formData.locale;
		delete formData.orgId;
		delete formData.stateId;

		return formData;
	};

	return (
		<React.Fragment>
			<AddForm
				isEditMode={true}
				onClose={onClose}
				userData={getformatedData(userData)}
				isUserDataLoading={isUserDataLoading}
				isEditable={isEditable}
			/>
		</React.Fragment>
	);
}
