import React from "react";
import {
	Button,
	IconButton,
	Stack,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import DateRangePicker from "../../../../Elements/DateRangePicker";

export default function DateRange(props) {
	const {
		handleDateRangeValueChange,
		handleSave,
		handleMoveBackToPrevious,
		disableSaveButton,
	} = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<Stack px={2} py={1} spacing={2}>
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
					onClick={handleMoveBackToPrevious}
					sx={{ cursor: "pointer" }}
				>
					<IconButton
						disableRipple
						style={{
							transform: "rotate(90deg)",
							padding: 0,
						}}
					>
						{DropDownIcon(16, 16, "rgba(0,0,0,0.6)")}
					</IconButton>

					<Typography fontSize={13} fontWeight={400}>
						Between
					</Typography>
				</Stack>

				<Stack width="350px">
					<DateRangePicker onChange={handleDateRangeValueChange} />
				</Stack>

				<Button
					variant="contained"
					color="secondary"
					disableElevation
					disabled={disableSaveButton}
					sx={{
						width: "fit-content",
						"&.Mui-disabled": {
							backgroundColor: theme.palette.secondary.main,
							color: alpha(
								theme.palette.secondary.contrastText,
								0.6
							),
						},
					}}
					onClick={handleSave}
				>
					Save
				</Button>
			</Stack>
		</React.Fragment>
	);
}
