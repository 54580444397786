import React from "react";
import Nodes from "../../../../../Mail/EmailComposer/EmailComposerUI/Nodes";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import EmailSignatureInput from "./EmailSignatureInput";
import { twozoStyles } from "../../../../../../styles/twozo";

export default function EmailSignature(props) {
	const { emailSignatureInputChange, initialSignatureContent } = props;
	const classes = twozoStyles();

	return (
		<React.Fragment>
			<LexicalComposer
				initialConfig={{
					theme: {
						paragraph: classes.email_paragraph,
						text: {
							bold: classes.email_font_bold,
							italic: classes.email_italic,
							underline: classes.email_underline,
							strikethrough: classes.email_strikethrough,
						},
						quote: classes.email_quote,
					},
					nodes: [...Nodes],
					onError(error) {
						throw error;
					},
				}}
			>
				<EmailSignatureInput
					emailSignatureInputChange={emailSignatureInputChange}
					initialSignatureContent={initialSignatureContent}
				/>
			</LexicalComposer>
		</React.Fragment>
	);
}
