import { getRootKey } from "../../rootKey";

const userKey = "users";

// user query key
export const getUserKey = () => {
	return [...getRootKey(), userKey];
};

// query key methods
export const getUserKeyById = (id) => {
	return [...getUserKey(), String(id)];
};

export const getUserTableDataKey = () => {
	return [...getUserKey(), "userTableData"];
};

export const getUserTableDataWithSortKey = (
	stateId = "",
	searchValue = "",
	fieldToSort = {}
) => {
	return [...getUserTableDataKey(), stateId, searchValue, fieldToSort];
};

export const getUserListKey = () => {
	return [...getUserKey(), "userList"];
};

export const getUserDataKey = (id) => {
	return [...getUserKeyById(id), "userDetails"];
};

export const getUserSummaryKey = (id) => {
	return [...getUserKeyById(id), "userSummary"];
};

export const getUserToDoAcitivityKey = (id) => {
	return [...getUserKeyById(id), "todoActivities"];
};

export const getUserCompletedActivityKey = (id) => {
	return [...getUserKeyById(id), "completedActivities"];
};

export const getUserAuditLogKey = (id) => {
	return [...getUserKeyById(id), "auditLog"];
};

export const getUserDetailsKey = () => {
	return [...getUserKey(), "allUserDeatils"];
};
