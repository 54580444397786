import { Typography } from "@mui/material";
import React from "react";
import { getDateMonthAndYearFormat } from "../../../../../../utils/DateUtils";

export default function RenderDateFieldValue(props) {
	const { fieldValue } = props;

	return (
		<React.Fragment>
			<Typography fontSize={14}>
				{fieldValue ? getDateMonthAndYearFormat(fieldValue) : null}
			</Typography>
		</React.Fragment>
	);
}
