import { Box, Stack, TextField, Typography, useTheme } from "@mui/material";
import CompanyFieldComponent from "./FieldComponents/CompanyFieldComponent";
import ContactFieldComponent from "./FieldComponents/ContactFieldComponent";
import DateFieldComponent from "./FieldComponents/DateFieldComponent";
import DateRangeFieldComponent from "./FieldComponents/DateRangeFieldComponent";
import MonetaryFieldComponent from "./FieldComponents/MonetaryFieldComponent";
import TimeRangeFieldComponent from "./FieldComponents/TimeRangeFieldComponent";
import DateTimeRangeFieldComponent from "./FieldComponents/DateTimeRangeFieldComponent";
import { useFormContext } from "react-hook-form";
import TimeFieldComponent from "./FieldComponents/TimeFieldComponent";
import TextComponent from "../../AddForm/Field/FieldComponents/TextComponent";
import TerritoryComponent from "../../AddForm/Field/FieldComponents/TerritoryComponent";
import MultiSelectComponent from "../../AddForm/Field/FieldComponents/MultiSelectComponent";
import SalesOwnerComponent from "../../AddForm/Field/FieldComponents/SalesOwnerComponent";
import PhoneFieldComponent from "./FieldComponents/PhoneFieldComponent";
import EmailFieldComponent from "./FieldComponents/EmailFieldComponent";
import DropdownComponent from "../../AddForm/Field/FieldComponents/DropdownComponent";
import TextAreaComponent from "../../AddForm/Field/FieldComponents/TextAreaComponent";
import NumberInputComponent from "../../AddForm/Field/FieldComponents/NumberInputComponent";

const fieldComponents = {
	OWNER: SalesOwnerComponent,
	TIME: TimeFieldComponent,
	DATE: DateFieldComponent,
	CONTACT: ContactFieldComponent,
	CONTACT_ONBOARD: ContactFieldComponent,
	COMPANY: CompanyFieldComponent,
	COMPANY_ONBOARD: CompanyFieldComponent,
	SINGLE_STRING: TextComponent,
	PHONES: PhoneFieldComponent,
	PHONE: PhoneFieldComponent,
	EMAIL: EmailFieldComponent,
	EMAILS: EmailFieldComponent,
	MONETARY: MonetaryFieldComponent,
	SINGLE_NUMBER: NumberInputComponent,
	TEXT: TextAreaComponent,
	TERRITORY: TerritoryComponent,
	DROPDOWN: DropdownComponent,
	MULTI_SELECT: MultiSelectComponent,
	DATE_RANGE: DateRangeFieldComponent,
	TIME_RANGE: TimeRangeFieldComponent,
	DATE_TIME_RANGE: DateTimeRangeFieldComponent,
};

const FieldNotImplemented = () => {
	return (
		<Box sx={{ cursor: "not-allowed", opacity: 0.7 }} width="100%">
			<TextField
				placeholder="Enter Value"
				sx={{ pointerEvents: "none", width: "100%" }}
			/>
		</Box>
	);
};

export default function Field(params) {
	const { field, subFields } = params;
	const theme = useTheme();

	const FieldComponent = fieldComponents[field?.fieldType];
	const {
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<Stack direction="column" spacing={0.5} width="100%">
				{FieldComponent ? (
					<FieldComponent
						field={field}
						subFields={subFields[field?.fieldType]}
					/>
				) : (
					<FieldNotImplemented />
				)}

				{errors[field.id] ? (
					<Typography
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[field.id]?.message}
					</Typography>
				) : null}
			</Stack>
		</>
	);
}
