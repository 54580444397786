import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { default as AddIcon } from "../../../../../assets/icons/add";

export default function OrganizationEmails() {
	return (
		<React.Fragment>
			<Stack
				alignItems="center"
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				mx={1}
				p={3}
				spacing={3}
			>
				<Box
					style={{
						width: "50%",
					}}
				>
					<Typography>
						Here, you can add your organization’s various
						departmental emails to allow your team to communicate
						with users through them. These email addresses will show
						up in the “From” and “Reply To” fields when you compose
						emails in CRM. Before you can use these email addresses,
						you will first need to verify them.
					</Typography>
				</Box>

				<Box>
					<Button
						variant="contained"
						startIcon={AddIcon(20, 20, "#fff")}
						disableElevation
					>
						Add Email Address
					</Button>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
