import React from "react";
import { useCompanyRelatedContacts } from "../../../../hooks/services/company";
import RelatedContacts from "../../../Summary/RelatedContacts";
import { Box, Typography } from "@mui/material";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function CompanyRelatedContacts(props) {
	const { companyId } = props;

	const {
		data: relatedContacts,
		isLoading: isLoadingRelatedContacts,
		isFetching: isFetchingRelatedContacts,
		fetchNextPage: fetchNextPage,
		hasNextPage: hasNextPage,
	} = useCompanyRelatedContacts(companyId);

	const relatedContactsLastRowRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingRelatedContacts
	);

	return (
		<React.Fragment>
			<Box p={1}>
				<Typography fontSize={14} fontWeight={600} pb={1.5}>
					Related Contacts
				</Typography>

				<RelatedContacts
					relatedContacts={relatedContacts}
					relatedContactsLastRowRef={relatedContactsLastRowRef}
					isLoadingRelatedContacts={isLoadingRelatedContacts}
					isFetchingRelatedContacts={isFetchingRelatedContacts}
					isCloseIconVisible={true}
					isAddParticipantsVisible={false}
				/>
			</Box>
		</React.Fragment>
	);
}
