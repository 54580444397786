import React, { useState } from "react";
import {
	Box,
	Checkbox,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import Autocomplete from "../../../../../../Elements/Autocomplete";
import { urlPattern } from "../../../../../../../utils/validation";

export default function SubmitOptions(props) {
	const { currentFormData, updateFormData } = props;

	const theme = useTheme();

	const getFormattedEmailRecipients = () => {
		const updatedEmailFormat =
			currentFormData.onSubmit.emailNotification.recipients.map(
				(recipient) => {
					return {
						value: recipient.id,
						name: recipient.value,
					};
				}
			);
		return updatedEmailFormat;
	};

	const [submitDetails, setSubmitDetails] = useState({
		...currentFormData.onSubmit,
	});
	const [isThankyouMessageEdited, setIsThankyouMessageEdited] =
		useState(false);
	const [isLinkEdited, setIsLinkEdited] = useState(false);
	const [emailRecipients, setEmailRecipients] = useState(
		getFormattedEmailRecipients()
	);
	const [isValidUrl, setIsValidUrl] = useState(true);

	const handleThankyouTextField = () => {
		setIsThankyouMessageEdited(true);
	};

	const handleThankyouStatus = (event) => {
		const updatedThankyouStatus = {
			...submitDetails,
			thankYouMessage: {
				...submitDetails.thankYouMessage,
				isEnabled: event.target.checked,
			},
		};
		setSubmitDetails(updatedThankyouStatus);
		updateFormData({ ...currentFormData, onSubmit: updatedThankyouStatus });
	};

	const handleChangeThankyouMessage = (event) => {
		const { value } = event.target;
		const updatedThankyouMessage = {
			...submitDetails,
			thankYouMessage: {
				...submitDetails.thankYouMessage,
				message: value,
			},
		};
		setSubmitDetails(updatedThankyouMessage);
		updateFormData({
			...currentFormData,
			onSubmit: updatedThankyouMessage,
		});
	};

	const handleLinkTextField = () => {
		setIsLinkEdited(true);
	};

	const handleUrlEnable = (event) => {
		const updatedUrlStatus = {
			...submitDetails,
			redirectUrl: {
				...submitDetails.redirectUrl,
				isEnabled: event.target.checked,
			},
		};
		setSubmitDetails(updatedUrlStatus);
		updateFormData({ ...currentFormData, onSubmit: updatedUrlStatus });
	};

	const validateUrl = (value) => {
		const url = urlPattern;
		return url.test(value);
	};

	const handleChangeLink = (event) => {
		const { value } = event.target;
		setIsValidUrl(validateUrl(value));
		const updatedUrl = {
			...submitDetails,
			redirectUrl: {
				...submitDetails.redirectUrl,
				url: value,
			},
		};
		setSubmitDetails(updatedUrl);
		updateFormData({ ...currentFormData, onSubmit: updatedUrl });
	};

	const handleEmailEnable = (event) => {
		const updatedEmailStatus = {
			...submitDetails,
			emailNotification: {
				...submitDetails.emailNotification,
				isEnabled: event.target.checked,
			},
		};
		setSubmitDetails(updatedEmailStatus);
		updateFormData({ ...currentFormData, onSubmit: updatedEmailStatus });
	};

	const handleEmailValue = (_, values) => {
		for (let index = 0; index < values.length; index++) {
			if (
				index === values.length - 1 &&
				!(values[index].value || values[index].name)
			) {
				values[index] = { name: values[index] };
			}
		}
		setEmailRecipients(values);

		const replaceWithOriginalFormat = (data) => {
			const oldFormatEmailValue = data.map((dataValue) => {
				const resultObject = { value: dataValue.name };
				if (dataValue.value) {
					resultObject.id = dataValue.value;
				}
				return resultObject;
			});
			return oldFormatEmailValue;
		};
		const updatedEmailValue = {
			...submitDetails,
			emailNotification: {
				...submitDetails.emailNotification,
				recipients: replaceWithOriginalFormat(values),
			},
		};
		setSubmitDetails(updatedEmailValue);
		updateFormData({ ...currentFormData, onSubmit: updatedEmailValue });
	};

	const handleContactEnable = (event) => {
		const updatedContactStatus = {
			...submitDetails,
			isContactCreationEnabled: event.target.checked,
		};
		setSubmitDetails(updatedContactStatus);
		updateFormData({ ...currentFormData, onSubmit: updatedContactStatus });
	};

	const handleDealEnable = (event) => {
		const updatedDealStatus = {
			...submitDetails,
			isDealCreationWithContactEnabled: event.target.checked,
		};
		setSubmitDetails(updatedDealStatus);
		updateFormData({ ...currentFormData, onSubmit: updatedDealStatus });
	};

	const styles = {
		boxContent: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			width: "88%",
			padding: "16px",
			gap: "18px",
			borderRadius: "8px",
			cursor: "pointer",
			border: "1px solid rgba(0, 0, 0, 0.1)",
			"&:hover": {
				borderColor: theme.palette.primary.main,
			},
		},
	};

	return (
		<React.Fragment>
			<Box p={2.5}>
				<Box>
					<Stack alignItems="center">
						<Stack width="88%">
							<Typography fontSize={13} fontWeight={500} pl={1}>
								What are all should happen after the form
								submission
							</Typography>
						</Stack>
					</Stack>

					<Stack pt={1} alignItems="center" spacing={1}>
						<Stack sx={styles.boxContent}>
							<Checkbox
								checked={
									submitDetails?.thankYouMessage?.isEnabled
								}
								onChange={handleThankyouStatus}
							/>
							<Stack
								width="100%"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={14}>
									Show Thank You Message
								</Typography>
								<Stack width="50%">
									<TextField
										variant="standard"
										onBlur={() =>
											setIsThankyouMessageEdited(true)
										}
										onClick={handleThankyouTextField}
										onChange={handleChangeThankyouMessage}
										value={
											submitDetails?.thankYouMessage
												?.message
										}
										inputProps={{
											style: {
												padding: 0,
												width: "100%",
												borderBottom:
													isThankyouMessageEdited &&
													"1px solid rgba(0, 0, 0, 0.2)",
											},
										}}
										InputProps={{
											style: { fontSize: 14 },
											disableUnderline: true,
										}}
										placeholder="Enter the Thankyou message"
									/>
								</Stack>
							</Stack>
						</Stack>

						<Stack sx={styles.boxContent}>
							<Checkbox
								checked={submitDetails?.redirectUrl?.isEnabled}
								onChange={handleUrlEnable}
							/>
							<Stack
								width="100%"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={14}>
									Redirect to another page
								</Typography>
								<Stack width="50%">
									<TextField
										variant="standard"
										onClick={handleLinkTextField}
										onBlur={() => setIsLinkEdited(false)}
										onChange={handleChangeLink}
										value={
											submitDetails?.redirectUrl?.url ||
											""
										}
										inputProps={{
											style: {
												padding: 0,
												width: "100%",
												borderBottom:
													isLinkEdited &&
													"1px solid rgba(0, 0, 0, 0.2)",
											},
										}}
										InputProps={{
											style: { fontSize: 14 },
											disableUnderline: true,
										}}
										placeholder="Enter Url"
										error={!isValidUrl}
										helperText={
											!isValidUrl
												? "Invalid URL format"
												: ""
										}
									/>
								</Stack>
							</Stack>
						</Stack>

						<Stack sx={styles.boxContent}>
							<Checkbox
								checked={
									submitDetails.emailNotification.isEnabled
								}
								onChange={handleEmailEnable}
							/>
							<Stack
								width="100%"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={14}>
									Send email notification
								</Typography>
								<Stack width="50%">
									<Autocomplete
										variant="standard"
										value={emailRecipients || []}
										options={[]}
										getOptionLabel={(option) =>
											option.value
										}
										onChange={(_, value) =>
											handleEmailValue(_, value)
										}
										placeholder="Enter Email Id"
									/>
								</Stack>
							</Stack>
						</Stack>

						<Stack sx={styles.boxContent}>
							<Checkbox
								checked={
									submitDetails?.isContactCreationEnabled
								}
								onChange={handleContactEnable}
							/>
							<Stack
								width="100%"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={14}>
									Create new contact
								</Typography>
							</Stack>
						</Stack>

						<Stack sx={styles.boxContent}>
							<Checkbox
								checked={
									submitDetails?.isDealCreationWithContactEnabled
								}
								onChange={handleDealEnable}
							/>
							<Stack
								width="100%"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={14}>
									Create a new deal with the contact
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
