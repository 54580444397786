import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DealIcon } from "../../../../../assets/icons/deals";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as TimeIcon } from "../../../../../assets/icons/time";
import { default as AddIcon } from "../../../../../assets/icons/add";
import DealMenu from "./DealMenu";
import {
	getDateAndMonthFormat,
	getDateDifference,
} from "../../../../../utils/DateUtils";
import { useUnlinkConversation } from "../../../../../hooks/services/conversations";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { modules } from "../../../../../utils/common/ModulesName";
import { TextButton, twozoStyles } from "../../../../../styles/twozo";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import AddActivity from "../../../../Summary/Activities/AddActivity";
import TooltipComponent from "../../../../Elements/TooltipComponent";
import { useNavigate } from "react-router-dom";

export default function RelatedDeal(props) {
	const { relatedContactsAndDeal, threadId, mailListName } = props;

	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const [dealMenuElement, setDealMenuElement] = useState(null);
	const isDealMenuOpened = Boolean(dealMenuElement);
	const [isAddActivityDrawerOpened, setIsAddActivityDrawerOpened] =
		useState(false);

	const dealId = relatedContactsAndDeal?.deal?.id;
	const unlinkConversationMutation = useUnlinkConversation(
		modules.DEAL,
		dealId,
		threadId,
		mailListName
	);

	const openDealMenu = (event) => {
		setDealMenuElement(event.currentTarget);
	};

	const closeDealMenu = () => {
		setDealMenuElement(null);
	};

	const openAddActivityDrawer = () => {
		setIsAddActivityDrawerOpened(true);
	};

	const closeAddActivityDrawer = () => {
		setIsAddActivityDrawerOpened(false);
	};

	const handleUnlinkDeal = () => {
		let unlinkConversationRequest = {
			threadId: threadId,
			entityId: dealId,
			source: modules.DEAL,
		};

		unlinkConversationMutation.mutate(unlinkConversationRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.unlinkToDeal,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getExpectedCloseDate = (closeDateUnixTimestamp) => {
		let formattedDate = getDateAndMonthFormat(closeDateUnixTimestamp);
		const currentDate = new Date().valueOf();

		return `${formattedDate} (${getDateDifference(
			closeDateUnixTimestamp,
			true,
			false
		)} ${currentDate > closeDateUnixTimestamp ? "overdue" : ""})`;
	};

	const navigateToDeal = () => {
		navigate(`/deals/${dealId?.toString()}`);
	};

	return (
		<React.Fragment>
			{/* Deal Menu */}
			<DealMenu
				dealMenuElement={dealMenuElement}
				isDealMenuOpened={isDealMenuOpened}
				closeDealMenu={closeDealMenu}
				threadId={threadId}
			/>

			{/* Add Activity Drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDrawerOpened}
				onOpen={openAddActivityDrawer}
				onClose={closeAddActivityDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={closeAddActivityDrawer}
						dealId={dealId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<Typography fontSize={13} fontWeight={500} pl={0.5} pb={1}>
					Related Deal
				</Typography>

				{relatedContactsAndDeal?.isDealLinkingEnabled ? (
					<Box
						style={{
							backgroundColor: "#fff",
							border: `1px solid ${theme.palette.divider}`,
							borderRadius: "8px",
						}}
					>
						<Box p={2}>
							<Stack
								direction="row"
								alignItem="center"
								justifyContent="space-between"
							>
								<TooltipComponent
									title={relatedContactsAndDeal?.deal?.title}
									placement="top"
								>
									<Box onClick={navigateToDeal}>
										<Typography
											fontSize={14}
											fontWeight={500}
											color={theme.palette.primary.main}
											maxWidth="250px"
											noWrap
											sx={{
												cursor: "pointer",
											}}
										>
											{
												relatedContactsAndDeal?.deal
													?.title
											}
										</Typography>
									</Box>
								</TooltipComponent>

								<IconButton
									size="small"
									style={{ padding: 0 }}
									onClick={handleUnlinkDeal}
								>
									{CloseIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>

							<TooltipComponent
								title={
									relatedContactsAndDeal?.deal?.pipelineStage
								}
								placement="top"
							>
								<Typography
									fontSize={12}
									color="rgba(0, 0, 0, 0.6)"
									maxWidth="280px"
									noWrap
									sx={{
										cursor: "pointer",
									}}
								>
									{
										relatedContactsAndDeal?.deal
											?.pipelineStage
									}
								</Typography>
							</TooltipComponent>

							<Stack
								direction="row"
								alignItem="center"
								justifyContent="space-between"
								pt={1}
							>
								{relatedContactsAndDeal?.deal
									?.expectedCloseDate ? (
									<Stack
										direction="row"
										alignItem="center"
										spacing={0.5}
									>
										<Box display="flex" alignItems="center">
											{TimeIcon(
												16,
												16,
												relatedContactsAndDeal?.deal
													?.isOverdue
													? theme.palette.error.main
													: "#000"
											)}
										</Box>

										<Typography
											style={{
												color: relatedContactsAndDeal
													?.deal?.isOverdue
													? theme.palette.error.main
													: "#000",
												fontSize: 13,
												fontWeight: 500,
											}}
										>
											{getExpectedCloseDate(
												relatedContactsAndDeal?.deal
													?.expectedCloseDate
											)}
										</Typography>
									</Stack>
								) : null}

								<Typography fontSize={13} fontWeight={500}>
									{
										relatedContactsAndDeal?.deal?.dealValue
											?.symbol
									}{" "}
									{
										relatedContactsAndDeal?.deal?.dealValue
											?.value
									}
								</Typography>
							</Stack>
						</Box>

						<Divider />

						<Box py={1} px={2}>
							<TextButton
								size="small"
								startIcon={AddIcon(
									20,
									20,
									theme.palette.secondary.contrastText
								)}
								onClick={openAddActivityDrawer}
							>
								Add Activity
							</TextButton>
						</Box>
					</Box>
				) : (
					<Box
						style={{
							backgroundColor: "#fff",
							border: `1px solid ${theme.palette.divider}`,
							borderRadius: "8px",
						}}
						p={2}
					>
						<Button
							variant="outlined"
							startIcon={DealIcon(
								20,
								20,
								theme.palette.primary.main
							)}
							onClick={openDealMenu}
							style={{
								backgroundColor: isDealMenuOpened
									? theme.palette.secondary.main
									: "transparent",
							}}
						>
							Link to a Deal
						</Button>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
