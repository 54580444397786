import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { modulesListKey } from "../../../utils/queryKeys";
import {
	getModulesList,
	renameModule,
} from "../../../api/settings/settingsApi";
import { useInvalidateFilterSourceList } from "./table/filter";

const useModuleList = () => {
	return useQuery(modulesListKey, () => getModulesList(), {
		staleTime: Infinity,
		select: (data) => data.list,
	});
};

const useInvalidateModuleList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(modulesListKey);
	};
};

const useRenameModules = () => {
	let invalidateModuleList = useInvalidateModuleList();
	let invalidateFilterSourceList = useInvalidateFilterSourceList();
	return useMutation(renameModule, {
		onSuccess: () => {
			invalidateModuleList();
			invalidateFilterSourceList();
		},
	});
};

export { useModuleList, useInvalidateModuleList, useRenameModules };
