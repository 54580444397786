import React, { useState } from "react";
import { getFormattedDateMonthYearAndTime } from "../../../../../utils/DateUtils";
import { default as SendIcon } from "../../../../../assets/icons/send";
import { Box, Stack, Typography, Checkbox, useTheme } from "@mui/material";
import MetricView from "../MetricView";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../../styles/twozo";

const styles = {
	headerText: {
		fontSize: "13px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: "14px",
	},
};

export default function MetricsListRow(props) {
	const { metricData } = props;
	const classes = twozoStyles();
	const theme = useTheme();

	const [openedMetricsId, setOpenedMetricsId] = useState("");
	const [isMetricViewOpened, setIsMetricViewOpened] = useState(false);

	const openMetricView = () => {
		setOpenedMetricsId(metricData?.id);
		setIsMetricViewOpened(true);
	};

	const closeMetricView = () => {
		setOpenedMetricsId("");
		setIsMetricViewOpened(false);
	};

	return (
		<React.Fragment>
			{/*Metrics Viewer*/}
			<CustomSwipeableDrawer
				anchor="right"
				BackdropProps={{ invisible: true }}
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMetricViewOpened}
				onOpen={openMetricView}
				onClose={closeMetricView}
				disableSwipeToOpen
			>
				<Box
					className={classes.mailViewContainer}
					style={{ width: "65vw" }}
				>
					<Box className={classes.mailViewCard}>
						<MetricView
							onClose={closeMetricView}
							metricData={metricData}
						/>
					</Box>
				</Box>
			</CustomSwipeableDrawer>

			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				style={{
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					cursor: "pointer",
					backgroundColor:
						openedMetricsId === metricData?.id
							? theme.palette.secondary.main
							: "transparent",
				}}
				px={2}
				py={1}
				onClick={openMetricView}
			>
				<Stack
					direction="row"
					alignItems="center"
					spacing={1.5}
					width="25%"
				>
					<Box>
						<Checkbox disabled />
					</Box>

					<Box>
						<Typography style={styles.text}>
							{metricData.subject || "(no subject)"}
						</Typography>

						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
						>
							{SendIcon(14, 14, "rgba(0, 0, 0, 0.6)")}

							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								{metricData.totalRecipients} Contacts
							</Typography>
						</Stack>
					</Box>
				</Stack>

				<Stack direction="row" width="60%" pl={0.5}>
					<Box width="20%">
						<Typography style={styles.text}>
							{metricData.sent}
						</Typography>
					</Box>

					<Box width="20%">
						<Typography style={styles.text}>
							{metricData.opened}
						</Typography>
					</Box>

					<Box width="20%">
						<Typography style={styles.text}>
							{metricData.clicked}
						</Typography>
					</Box>

					<Box width="20%">
						<Typography style={styles.text}>
							{metricData.replied}
						</Typography>
					</Box>

					<Box width="20%">
						<Typography style={styles.text}>
							{metricData.bounced}
						</Typography>
					</Box>

					<Box width="20%">
						<Typography style={styles.text}>
							{metricData.unSubscribed}
						</Typography>
					</Box>
				</Stack>

				<Box
					style={{
						width: "15%",
						textAlign: "right",
					}}
				>
					<Typography style={styles.text} color="rgba(0, 0, 0, 0.6)">
						{getFormattedDateMonthYearAndTime(
							metricData.createdTime
						)}
					</Typography>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
