import React, { useEffect, useState } from "react";
import TimeRange from "../../../../../TimeRangePicker";

export default function TimeRangeBaseComponent({
	onChange,
	value,
	subField,
	id,
	error,
	inputRef,
	clearErrors,
}) {
	const [timeRangeValue, setTimeRangeValue] = useState({});

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const fromField = getSubFieldObject("FROM");
	const toField = getSubFieldObject("TO");

	useEffect(() => {
		if (value) {
			let timeRangeValueObj = {};
			timeRangeValueObj.from = value[fromField.id];
			timeRangeValueObj.to = value[toField.id];
			setTimeRangeValue(timeRangeValueObj);
		}
	}, [value, fromField, toField]);

	const handleTimeRangeValueChange = (newTimeRangeValue) => {
		setTimeRangeValue({ ...newTimeRangeValue });
		let timeRangeValueObjectWithId = {};
		timeRangeValueObjectWithId[fromField.id] = newTimeRangeValue.from;
		timeRangeValueObjectWithId[toField.id] = newTimeRangeValue.to;

		let getTimeRangeValue = () => {
			if (
				timeRangeValueObjectWithId[fromField.id] &&
				timeRangeValueObjectWithId[toField.id]
			) {
				return timeRangeValueObjectWithId;
			}

			return "";
		};
		onChange(getTimeRangeValue());
		clearErrors(id);
	};

	return (
		<React.Fragment>
			<TimeRange
				value={timeRangeValue}
				onChange={handleTimeRangeValueChange}
				id={id}
				error={error}
				inputRef={inputRef}
			/>
		</React.Fragment>
	);
}
