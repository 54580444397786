import React from "react";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as DoubleArrowIcon } from "../../../../assets/icons/doubleArrow";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownCentered";
import dayjs from "dayjs";
import { useDealContext } from "../../DealContext";
import { Tooltip } from "../../../../styles/twozo";

export default function DateSwitchingButton() {
	const theme = useTheme();

	const { updateStartDate, startDate, interval, dealColumn } =
		useDealContext();

	const getUpdatedTimePeriod = (columns, timePeriod, operator) => {
		if (operator === "subract") {
			return startDate.subtract(columns, timePeriod);
		} else if (operator === "add") {
			return startDate.add(columns, timePeriod);
		}
	};

	const updateStartDateBasedOnColumns = (interval, columns, operator) => {
		let formattedStartDate;
		switch (interval) {
			case "Month":
				formattedStartDate = getUpdatedTimePeriod(
					columns,
					"month",
					operator
				);
				updateStartDate(formattedStartDate);
				break;
			case "Week":
				formattedStartDate = getUpdatedTimePeriod(
					columns,
					"week",
					operator
				);
				updateStartDate(formattedStartDate);
				break;
			case "Quater":
				formattedStartDate = getUpdatedTimePeriod(
					columns * 3,
					"month",
					operator
				);
				updateStartDate(formattedStartDate);
				break;
		}
	};

	const handlePreviousDateBasedOnColumn = () => {
		if (dealColumn.name === "3 Columns") {
			updateStartDateBasedOnColumns(interval.name, 3, "subract");
		} else if (dealColumn.name === "4 Columns") {
			updateStartDateBasedOnColumns(interval.name, 4, "subract");
		} else {
			updateStartDateBasedOnColumns(interval.name, 5, "subract");
		}
	};

	const handlePreviousDate = () => {
		updateStartDateBasedOnColumns(interval.name, 1, "subract");
	};

	const handleCurrentDate = () => {
		let currentDate = dayjs();
		updateStartDate(currentDate);
	};

	const handleNextDate = () => {
		updateStartDateBasedOnColumns(interval.name, 1, "add");
	};

	const handleNextDateBasedOnColumns = () => {
		if (dealColumn.name === "3 Columns") {
			updateStartDateBasedOnColumns(interval.name, 3, "add");
		} else if (dealColumn.name === "4 Columns") {
			updateStartDateBasedOnColumns(interval.name, 4, "add");
		} else {
			updateStartDateBasedOnColumns(interval.name, 5, "add");
		}
	};

	const iconButtonHoveringStyle = {
		"&:hover": {
			backgroundColor: theme.palette.secondary.main,
		},
		borderRadius: "3px",
	};

	const getColumn = () => {
		if (dealColumn.name === "3 Columns") {
			return 3;
		} else if (dealColumn.name === "4 Columns") {
			return 4;
		} else {
			return 5;
		}
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="center"
				border={`1px solid ${theme.palette.secondary.main}`}
				borderRadius="8px"
				px={1.5}
				py={0.5}
			>
				<Tooltip
					title={`Jump Back ${getColumn()} ${interval.name}s`}
					placement="top"
				>
					<IconButton
						onClick={handlePreviousDateBasedOnColumn}
						sx={iconButtonHoveringStyle}
						disableRipple
					>
						{DoubleArrowIcon(16, 16)}
					</IconButton>
				</Tooltip>

				<Tooltip title={`Previous ${interval.name}`} placement="top">
					<IconButton
						sx={{
							transform: "rotate(90deg)",
							...iconButtonHoveringStyle,
						}}
						disableRipple
						onClick={handlePreviousDate}
					>
						{DropdownIcon(
							16,
							16,
							theme.palette.secondary.contrastText
						)}
					</IconButton>
				</Tooltip>

				<Typography
					sx={{
						color: theme.palette.secondary.contrastText,
						fontSize: 12,
						fontWeight: 500,
						cursor: "pointer",
						px: 1,
						py: 0.8,
						...iconButtonHoveringStyle,
					}}
					onClick={handleCurrentDate}
				>
					Today
				</Typography>

				<Tooltip title={`Next ${interval.name}`} placement="top">
					<IconButton
						sx={{
							transform: "rotate(-90deg)",
							...iconButtonHoveringStyle,
						}}
						disableRipple
						onClick={handleNextDate}
					>
						{DropdownIcon(
							16,
							16,
							theme.palette.secondary.contrastText
						)}
					</IconButton>
				</Tooltip>

				<Tooltip
					title={`Jump Forward ${getColumn()} ${interval.name}s`}
					placement="top"
				>
					<IconButton
						sx={{
							transform: "rotate(180deg)",
							...iconButtonHoveringStyle,
						}}
						disableRipple
						onClick={handleNextDateBasedOnColumns}
					>
						{DoubleArrowIcon(16, 16)}
					</IconButton>
				</Tooltip>
			</Stack>
		</React.Fragment>
	);
}
