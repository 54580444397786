import React from "react";

export default function Expent(width = "20", height = "20", color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path
					d="M5.33333 14.6673L14.6667 5.33399M5.33333 14.6673V10.0007M5.33333 14.6673H10M14.6667 5.33399V10.0007M14.6667 5.33399H10"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
			</g>
		</svg>
	);
}
