import { permissionTypes, phoneIds, type } from "..";

export const getPhoneConfigForPermission = (permissions) => {
	return [
		{
			id: phoneIds.accessToPhone,
			name: "Access to Phone",
			access: permissions.accessToPhone.access,
		},
		{
			id: phoneIds.allowUsersToMakePhoneCalls,
			name: "Allow Users to Make Phone Calls",
			access: permissions.allowUsersToMakePhoneCalls.access,
			accessDisable: !permissions.accessToPhone.access,
		},
		{
			id: phoneIds.callLogs,
			name: "Call Logs",
			access: permissions.callLogs.access,
			accessDisable: !permissions.accessToPhone.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: permissionTypes.view,
						name: "View",
						value: permissions.callLogs.view,
						disabled: !permissions.callLogs.access,
					},
					{
						id: permissionTypes.create,
						name: "Create",
						value: permissions.callLogs.create,
						disabled: !permissions.callLogs.access,
					},
					{
						id: permissionTypes.edit,
						name: "Edit",
						value: permissions.callLogs.edit,
						disabled: !permissions.callLogs.access,
					},
					{
						id: permissionTypes.delete,
						name: "Delete",
						value: permissions.callLogs.delete,
						disabled: !permissions.callLogs.access,
					},
				],
			},
		},
	];
};
