import { useReducer } from "react";

const searchActions = {
	open: "OPEN_SEARCHBAR",
	close: "CLOSE_SEARCHBAR",
};

const searchReducer = (state, action) => {
	switch (action.type) {
		case searchActions.open:
			return {
				...state,
				isSearchbarOpen: true,
			};
		case searchActions.close:
			return {
				...state,
				isSearchbarOpen: false,
			};
		default:
			state;
	}
};

const initialSearchState = {
	isSearchbarOpen: false,
};

const useSearchReducer = () => {
	const [searchState, searchStateDispatcher] = useReducer(
		searchReducer,
		initialSearchState
	);

	const openSearchBar = () => {
		searchStateDispatcher({ type: searchActions.open });
	};

	const closeSearchBar = () => {
		searchStateDispatcher({ type: searchActions.close });
	};

	return {
		searchState,
		openSearchBar,
		closeSearchBar,
	};
};

export { useSearchReducer };
