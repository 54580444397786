import React, { useState } from "react";
import { Box, Button, Divider, Stack, ToggleButton } from "@mui/material";
import ToggleButtonGroup from "../../../../../Elements/ToggleButtonGroup";
import FormEditor from "./FormEditor";
import SubmitOptions from "./SubmitOptions";
import Style from "./Style";
import { webFormCustomizeOptions } from "../../../../../../utils/WebFormUtils";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import { useUpdateMutation } from "../../../../../../hooks/services/webform";

export default function Customize(props) {
	const {
		currentFormData,
		toggleSubmitOptions,
		updateFormData,
		goNextStep,
		goPreviousStep,
		isEditMode,
		isCreateMutationLoading,
	} = props;

	const navigate = useNavigate();
	const [type, setType] = useState(webFormCustomizeOptions.formEditor);
	const [isToggleAllowed, setIsToggleAllowed] = useState(false);

	const updateMutation = useUpdateMutation();

	const handleToggleButtonGroup = (_, type) => {
		if (!isToggleAllowed) {
			if (type) {
				setType(type);
				toggleSubmitOptions(type);
			}
		}
	};

	const onResetFormError = (isAllowed) => {
		setIsToggleAllowed(isAllowed);
	};

	const updateWebForm = () => {
		let updatedCurrentFormData = { ...currentFormData };
		delete updatedCurrentFormData.url;
		updateMutation.mutate(updatedCurrentFormData, {
			onSuccess: () => {
				navigate("/settings/webform");
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.webFormUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					pt={2.5}
				>
					<Box>
						<ToggleButtonGroup
							value={type}
							onChange={handleToggleButtonGroup}
						>
							<ToggleButton
								value={webFormCustomizeOptions.formEditor}
							>
								Form Editor
							</ToggleButton>
							<ToggleButton
								value={webFormCustomizeOptions.submitOptions}
							>
								Submit Options
							</ToggleButton>
							<ToggleButton value={webFormCustomizeOptions.style}>
								Style
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
				</Stack>

				<Box
					sx={{
						flex: "1 1 auto",
						overflowY: "auto",
						minHeight: "0px",
					}}
				>
					{(function () {
						switch (type) {
							case "formEditor":
								return (
									<FormEditor
										currentFormData={currentFormData}
										updateFormData={updateFormData}
										onResetFormError={onResetFormError}
									/>
								);
							case "submitOptions":
								return (
									<SubmitOptions
										currentFormData={currentFormData}
										updateFormData={updateFormData}
									/>
								);
							case "style":
								return (
									<Style
										currentFormData={currentFormData}
										updateFormData={updateFormData}
									/>
								);
							default:
								return null;
						}
					})()}
				</Box>

				<Divider />
				<Box>
					{!isEditMode ? (
						<Stack
							direction="row"
							justifyContent="space-between"
							p={2}
						>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={goPreviousStep}
								disabled={isCreateMutationLoading}
							>
								Previous
							</Button>

							<Button
								variant="contained"
								disableElevation
								onClick={goNextStep}
								disabled={isCreateMutationLoading}
							>
								Save & Next
							</Button>
						</Stack>
					) : null}
				</Box>

				<Box>
					{isEditMode ? (
						<Stack
							direction="row"
							alignItems="center"
							spacing={2}
							justifyContent="flex-end"
							p={2}
						>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={() => navigate("/settings/webform")}
								disabled={updateMutation.isLoading}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								disableElevation
								onClick={updateWebForm}
								disabled={updateMutation.isLoading}
							>
								Update
							</Button>
						</Stack>
					) : null}
				</Box>
			</Box>
		</React.Fragment>
	);
}
