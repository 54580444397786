import TextComponent from "../TextComponent";

export default function NumberComponent(params) {
	const { ...rest } = params;

	return (
		<>
			<TextComponent {...rest} type="number"></TextComponent>
		</>
	);
}
