import React, { useState } from "react";
import {
	Box,
	Divider,
	Stack,
	Step,
	StepLabel,
	stepLabelClasses,
	Stepper,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { default as ArrowIcon } from "../../.././assets/icons/dropDownRight";
import Upload from "./Upload";
import Mapping from "./Mapping";
import PreviewAndFinish from "./PreviewAndFinish";
import ImportFinished from "./ImportFinished";

const steps = ["Upload File", "Mapping", "Preview & Finish"];

const StyledStepLabel = styled(StepLabel)(() => ({
	[`& .${stepLabelClasses.label}`]: {
		color: "#666666",
		fontSize: "15px",
		fontWeight: 600,
		[`&.${stepLabelClasses.active}`]: {
			color: "#000",
			fontWeight: 600,
		},
		[`&.${stepLabelClasses.completed}`]: {
			color: "#666666",
			fontWeight: 600,
		},
	},
}));

const StepIcon = (props) => {
	const theme = useTheme();
	const { active, completed } = props;

	return (
		<Box
			style={{
				width: "16px",
				height: "16px",
				backgroundColor: completed
					? theme.palette.primary.main
					: "#fff",
				border: active
					? `3px solid ${theme.palette.primary.main}`
					: completed
						? "3px solid #fff"
						: "1px solid #666",
				borderRadius: "50px",
			}}
		></Box>
	);
};

export default function Import(props) {
	const {
		activeStep,
		handleActiveStep,
		importedFileStateId,
		onClose,
		moduleName,
	} = props;
	const theme = useTheme();
	const [updatedMappingId, setUpdatedMappingId] = useState(null);
	const [importedFileId, setImportedFileId] = useState(null);
	const [uploadedFileId, setUploadedFileId] = useState(null);

	const goNextStep = () => {
		let updatedActiveStep = activeStep + 1;
		handleActiveStep(updatedActiveStep);
	};

	const goPreviousStep = () => {
		let updatedActiveStep = activeStep - 1;
		handleActiveStep(updatedActiveStep);
	};

	const setStep = (step) => {
		if (step < 4) {
			handleActiveStep(step);
		}
	};

	const assignIdOnFileUpload = (uploadedFileId) => {
		setUploadedFileId(uploadedFileId);
	};

	const assignIdOnUpdateFieldsMapping = (updatedFieldsMappingId) => {
		setUpdatedMappingId(updatedFieldsMappingId);
	};

	const assignIdOnImport = (importedFileId) => {
		setImportedFileId(importedFileId);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box hidden={activeStep > 2}>
					<Stack justifyContent="center" alignItems="center" p={2}>
						<Box>
							<Stepper
								activeStep={activeStep}
								connector={
									<Box px={1} pt={0.2}>
										{" "}
										{ArrowIcon(14, 14, "#666666")}{" "}
									</Box>
								}
							>
								{steps.map((label) => (
									<Step
										key={label}
										sx={{
											color: "red",
										}}
									>
										<StyledStepLabel
											StepIconComponent={StepIcon}
										>
											{label}
										</StyledStepLabel>
									</Step>
								))}
							</Stepper>
						</Box>

						{activeStep === 0 && (
							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								You can import contact, company, deals,
								activities, and notes separately, or you can
								speed things up by grouping them as single
								upload file.{" "}
								<span
									style={{
										color: theme.palette.secondary
											.contrastText,
										cursor: "pointer",
									}}
								>
									Learn More
								</span>
							</Typography>
						)}
					</Stack>

					<Divider />
				</Box>

				<Box
					p={1}
					style={{
						flex: "1 1 auto",
						minHeight: "0px",
					}}
				>
					{(function () {
						switch (activeStep) {
							case 0:
								return (
									<Upload
										goNextStep={goNextStep}
										assignIdOnFileUpload={
											assignIdOnFileUpload
										}
									/>
								);
							case 1:
								return (
									<Mapping
										goNextStep={goNextStep}
										goPreviousStep={goPreviousStep}
										uploadedFileId={uploadedFileId}
										assignIdOnUpdateFieldsMapping={
											assignIdOnUpdateFieldsMapping
										}
										importedFileStateId={
											importedFileStateId
										}
									/>
								);
							case 2:
								return (
									<PreviewAndFinish
										goNextStep={goNextStep}
										goPreviousStep={goPreviousStep}
										updatedMappingId={updatedMappingId}
										assignIdOnImport={assignIdOnImport}
										importedFileStateId={
											importedFileStateId
										}
									/>
								);
							case 3:
								return (
									<ImportFinished
										setStep={setStep}
										onClose={onClose}
										moduleName={moduleName}
										importedFileId={importedFileId}
									/>
								);
							default:
								return null;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}
