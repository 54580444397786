import React from "react";

export default function Home(width = 16, height = 16) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.6">
				<path
					d="M2.42065 4.69336C1.82065 5.16003 1.33398 6.15336 1.33398 6.90669V11.8467C1.33398 13.3934 2.59398 14.66 4.14065 14.66H11.8607C13.4073 14.66 14.6673 13.3934 14.6673 11.8534V7.00003C14.6673 6.19336 14.1273 5.16003 13.4673 4.70003L9.34732 1.81336C8.41398 1.16003 6.91398 1.19336 6.01398 1.89336L2.42065 4.69336Z"
					stroke="black"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8 11.9933V9.32666"
					stroke="black"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
