import postFieldsApi from "../postFieldsApi";

export const getFormFields = async (source, view) => {
	let request = getFormFieldsRequest(source, view);
	let response = await postFieldsApi(request);
	return response;
};

export const getGroupedFormFields = async ({ source, id }) => {
	let request = getGroupedFormFieldsRequest(source, id);
	return await postFieldsApi(request);
};

export const getUpdateFieldList = async (source) => {
	let request = getUpdateFieldListRequest(source);
	return await postFieldsApi(request);
};

export const getGroupedFormFieldsData = async (source, entityId, fieldId) => {
	let request = getGroupedFormFieldsDataRequest(source, entityId, fieldId);
	return await postFieldsApi(request);
};

const getFormFieldsRequest = (source, view) => {
	return {
		type: "formRender",
		data: {
			source: source,
			view: view,
		},
	};
};

const getGroupedFormFieldsRequest = (source, id) => {
	return {
		type: "getDependableFields",
		data: {
			source: source,
			id: id,
		},
	};
};

const getUpdateFieldListRequest = (source) => {
	return {
		type: "updateFieldList",
		data: {
			source: source,
		},
	};
};

const getGroupedFormFieldsDataRequest = (source, entityId, fieldId) => {
	return {
		type: "getDependableFieldData",
		data: {
			source: source,
			entityId: entityId,
			fieldId: fieldId,
		},
	};
};
