import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getCompanyTimelineKey } from "../../../../utils/queryKeys";
import { getCompanyTimelineData } from "../../../../api/timeline/timelineAPI";
import { timelinePageSize } from "../../../../utils/queryConstants/summary/timeline";

const useCompanyTimelineData = (companyId, timelineIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getCompanyTimelineKey(companyId, timelineIdsToFilter),
		queryFn: ({ pageParam }) => {
			return getCompanyTimelineData(companyId, timelineIdsToFilter, {
				start: pageParam ? (pageParam - 1) * timelinePageSize + 1 : 1,
				limit: timelinePageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		refetchOnWindowFocus: false,
	});
};

//Invalidate Company Timeline
const useInvalidateCompanyTimeline = (companyId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCompanyTimelineKey(companyId));
	};
};

export { useCompanyTimelineData, useInvalidateCompanyTimeline };
