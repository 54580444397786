import { useEffect, useMemo, useState } from "react";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import { emailPattern } from "../../../../../utils/validation";
import AutocompleteBaseComponent from "../AutocompleteBaseComponent";

export default function MultipleEmailComponent(params) {
	const { field, metadata, onChange, hasError, style, placeholder } = params;

	const { data: stepOptions } = useStepOptions(metadata, field);
	const [selectedEmails, setSelectedEmails] = useState([]);

	const isValidEmail = (option) => {
		if (option.isNewOption) {
			return emailPattern.test(option.value.name);
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (Array.isArray(field?.values)) {
			let fieldValue = field?.values.map((field) => {
				if (field.value?.name) {
					return field;
				}
				return {
					...field,
					value: {
						name: field.value,
					},
				};
			});

			setSelectedEmails(fieldValue);
		}
	}, [field]);

	const handleChangeEmails = (emails) => {
		const filteredEmails = emails?.filter((email) => {
			if (email.isNewOption) {
				return isValidEmail(email);
			}
			return email;
		});
		setSelectedEmails(emails);

		field["values"] = filteredEmails;
		if (onChange) {
			onChange(filteredEmails);
		}
	};

	const stepOptionFields = useMemo(() => {
		if (Array.isArray(stepOptions)) {
			return stepOptions.flatMap((stepOption) =>
				stepOption.fields.map((field) => ({
					value: { id: field.id, name: field.name },
					dependsOn: stepOption.dependsOn,
				}))
			);
		}
		return [];
	}, [stepOptions]);

	return (
		<AutocompleteBaseComponent
			style={style}
			placeholder={placeholder || "Enter"}
			hasError={hasError}
			values={selectedEmails}
			options={stepOptionFields}
			isValidOption={isValidEmail}
			onChange={handleChangeEmails}
			isNewOption={true}
		/>
	);
}
