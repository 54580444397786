import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Tooltip } from "../../../../../styles/twozo";

export default function ActivityType(props) {
	const theme = useTheme();
	const [hovered, setHovered] = useState(false);

	return (
		<React.Fragment>
			<Tooltip title={props.name}>
				<Box
					component={"button"}
					style={{
						backgroundColor: props.selected
							? theme.palette.primary.main
							: "#fff",
						border:
							props.selected || hovered
								? `1px solid ${theme.palette.primary.main}`
								: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "42px",
						width: "42px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
					}}
					onMouseOver={() => setHovered(true)}
					onMouseOut={() => setHovered(false)}
					onClick={() => props.selectType(props.id)}
				>
					{props.icon(
						24,
						24,
						props.selected
							? "#fff"
							: hovered
								? theme.palette.primary.main
								: "#000"
					)}
				</Box>
			</Tooltip>
		</React.Fragment>
	);
}
