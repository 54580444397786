import React from "react";
import Conversations from "../../../Summary/Conversations";
import { modules } from "../../../../utils/common/ModulesName";

export default function DealConversations(props) {
	const { dealId } = props;

	return (
		<React.Fragment>
			<Conversations sourceName={modules.DEAL} entityId={dealId} />
		</React.Fragment>
	);
}
