import React from "react";
import ViewEmail from "../../../Mail/ViewEmail";
import { useMailData } from "../../../../hooks/services/mail";
import { CircularProgress, Stack } from "@mui/material";

export default function EmailViewer(props) {
	const { mailListName, onClose, mailId, isTrash, viewerMode } = props;

	const { data: mailData, isLoading: isLoadingMailData } = useMailData(
		mailId,
		isTrash
	);

	return (
		<React.Fragment>
			{isLoadingMailData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<ViewEmail
					mailData={mailData}
					onClose={onClose}
					disableViewActions={isTrash}
					mailListName={mailListName}
					threadId={mailId}
					viewerMode={viewerMode}
				/>
			)}
		</React.Fragment>
	);
}
