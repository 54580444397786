import { Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { Controller } from "react-hook-form";
import Autocomplete from "../../Autocomplete";
import { useFieldDropdownList } from "../../../../hooks/services/common/field";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import React, { useCallback, forwardRef, useImperativeHandle } from "react";

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const MultiSelectFieldEditComponent = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		// query call:-
		const { data: options } = useFieldDropdownList(fieldId);

		const {
			handleSubmit,
			formState: { errors },
			control,
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });
		const [multiSelectValue, setMultiSelectValue] = useState([]);

		const handleChange = (selectedOption, onChange) => {
			const selectedValue =
				Array.isArray(selectedOption) &&
				selectedOption.map((data) => data.value);
			const value = selectedValue.length > 0 ? selectedValue : "";
			onChange(value);
			clearErrors(fieldId + "");
			onError(null);
			setMultiSelectValue(selectedOption);
		};

		useEffect(() => {
			if (Array.isArray(value) && Array.isArray(options)) {
				const selectedValues = options.filter((option) =>
					value.includes(option.value)
				);
				setMultiSelectValue(selectedValues);
			}
		}, [options, value]);

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<Autocomplete
							{...fieldData}
							value={multiSelectValue}
							textFieldSx={textFieldSx}
							options={options || []}
							onChange={(_, selectedOption) =>
								handleChange(selectedOption, onChange)
							}
							getOptionLabel={(option) => option.name}
							placeholder="Select"
							freeSolo={false}
							noOptionsText="No results found"
							readOnly={true}
						/>
					)}
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

MultiSelectFieldEditComponent.displayName = "MultiSelectFieldEditComponent";

export default MultiSelectFieldEditComponent;
