import { apiClient } from "../..";
import { getContactDuplicateUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getContactDuplicateList = async (pageParams) => {
	let requestData = getContactDuplicateRequestData(pageParams);
	return await postContactDuplicateManagementApi(requestData);
};

export const getReviewDuplicateData = async (id, pageParams) => {
	let requestData = getReviewDuplicateRequestData(id, pageParams);
	return await postContactDuplicateManagementApi(requestData);
};

export const mergeContactData = async (data) => {
	let requestData = getMergeContactRequestData(data);
	return await postContactDuplicateManagementApi(requestData);
};

export const dismissDulpicateContact = async ({ id, fieldName, value }) => {
	let requestData = getDismissDuplicateContactRequest(id, fieldName, value);
	return await postContactDuplicateManagementApi(requestData);
};

const getContactDuplicateRequestData = (pageParams) => {
	return {
		type: "list",
		data: {
			...pageParams,
		},
	};
};

const getReviewDuplicateRequestData = (id, pageParams) => {
	return {
		type: "review",
		data: {
			contactId: id,
			...pageParams,
		},
	};
};

const getMergeContactRequestData = (data) => {
	return {
		type: "merge",
		data: data,
	};
};

const getDismissDuplicateContactRequest = (id, fieldName, value) => {
	return {
		type: "dismiss",
		data: {
			id: id,
			fieldName: fieldName,
			value: value,
		},
	};
};

const postContactDuplicateManagementApi = async (requestData) => {
	let contactDuplicateManagementUrl = getContactDuplicateManagementUrl();
	let response = await apiClient.post(
		contactDuplicateManagementUrl,
		requestData
	);
	assertError(response);
	return response.data.data;
};

const getContactDuplicateManagementUrl = () => {
	return getContactDuplicateUrl() + "/mgmt";
};
