import React, { useState, useEffect } from "react";
import { useTagDropdownList } from "../../../../../../../hooks/services/tags";
import TagsInput from "./TagsInput";

export function TagsBaseComponent(props) {
	const { inputRef, field, error, clearErrors, onChange, value } = props;

	const [selectedTags, setSelectedTags] = useState([]);

	const { data: tagsList } = useTagDropdownList();

	const getTagNameById = (tagId) => {
		let tagData = tagsList?.find((tag) => tag?.value === tagId);
		if (tagData) {
			return tagData.name;
		}
	};

	useEffect(() => {
		if (Array.isArray(value)) {
			const getSelectedTag = (tagData) => {
				if (tagData?.name) {
					return tagData?.name;
				} else if (tagData?.id) {
					return getTagNameById(tagData.id);
				} else {
					return tagData?.tag;
				}
			};

			let formattedTagValues = [];

			for (let tagData of value) {
				let selectedTag = getSelectedTag(tagData);
				if (selectedTag) {
					formattedTagValues.push(selectedTag);
				}
			}
			setSelectedTags(formattedTagValues);
		}
	}, [value, tagsList]);

	const getTagIdByName = (tagName) => {
		let tagData = tagsList?.find((tag) => tag?.name === tagName);
		if (tagData) {
			return tagData.value;
		}
	};

	const handleSelectTags = (values) => {
		setSelectedTags(values);
		let tagsForRequest = [];
		for (let tag of values) {
			let tagId = getTagIdByName(tag);
			if (tagId) {
				tagsForRequest.push({ id: tagId });
			} else {
				tagsForRequest.push({ tag: tag });
			}
		}
		onChange(tagsForRequest);
	};

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<TagsInput
				inputRef={inputRef}
				tagsList={tagsList || []}
				selectedTags={selectedTags}
				handleSelectTags={handleSelectTags}
				onClearErrors={onClearErrors}
				error={error}
				placeHolder={field?.placeHolder}
			/>
		</React.Fragment>
	);
}
