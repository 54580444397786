import React from "react";
import { InputAdornment, TextField, useTheme } from "@mui/material";
import { default as SearchIcon } from "../../../assets/icons/globalSearch";

export default function SearchBar({ borderRadius, ...other }) {
	const theme = useTheme();

	return (
		<React.Fragment>
			<TextField
				placeholder="Search"
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							style={{
								paddingLeft: "8px",
								paddingRight: "8px",
							}}
						>
							{SearchIcon(18, 18, "rgba(0, 0, 0, 0.6)")}
						</InputAdornment>
					),
				}}
				sx={{
					"& .MuiInputBase-root": {
						backgroundColor: "#ECEEEE",
						borderRadius: borderRadius,
					},
					input: {
						"&::placeholder": {
							color: "black",
							opacity: 0.6,
						},
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderRadius: borderRadius,
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							border: "none",
						},
						"&:hover fieldset": {
							border: "none",
						},
						"&.Mui-focused fieldset": {
							border: "none",
						},
					},
					zIndex: theme.zIndex.drawer + 2,
				}}
				autoComplete="off"
				fullWidth
				{...other}
			/>
		</React.Fragment>
	);
}
