import React from "react";
import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import Dialog from "../../Dialog";

export default function CancelExportDialog(props) {
	const { open, onCancel } = props;

	return (
		<React.Fragment>
			<Dialog open={open} title="Export">
				<Stack p={3} spacing={1.5}>
					<Typography fontSize={13} fontWeight={500}>
						Exporting... (0/1)
					</Typography>

					<LinearProgress
						variant="determinate"
						value={25}
						style={{
							borderRadius: "8px",
							height: "6px",
						}}
					/>

					<Typography
						fontSize={13}
						fontWeight={400}
						color="rgba(0, 0, 0, 0.6)"
					>
						The download link will be available once the export
						process has been completed.
					</Typography>

					<Stack direction="row" justifyContent="flex-end">
						<Button
							variant="contained"
							color="secondary"
							onClick={onCancel}
							disableElevation
						>
							Cancel
						</Button>
					</Stack>
				</Stack>
			</Dialog>
		</React.Fragment>
	);
}
