import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	startCalendarSync,
	getCalendarSyncSettingsData,
	getCalendarSyncStatus,
	stopCalendarSync,
} from "../../../api/calendarSync/calendarSyncApi";
import {
	calendarSyncSettingsDataKey,
	getCalendarSyncStatusKey,
} from "../../../utils/queryKeys";

// query call
const useCalendarSyncSettingsData = () => {
	return useQuery(
		calendarSyncSettingsDataKey,
		() => getCalendarSyncSettingsData(),
		{
			select: (syncedData) => syncedData.syncSettings,
		}
	);
};

// This hook is called only when the sync state is "syncing" and it refetches the query
const useCalendarSyncStatus = (id, isSyncStatusEnabled) => {
	let calendarSyncStatusKey = getCalendarSyncStatusKey(id);
	return useQuery(calendarSyncStatusKey, () => getCalendarSyncStatus(id), {
		enabled: isSyncStatusEnabled,
		select: (syncState) => syncState?.state,
		refetchInterval: () => syncStatusRefetchInterval(),
	});
};

// At regular intervals (every 1000 milliseconds) while the sync state is "syncing".
const syncStatusRefetchInterval = () => {
	return 1000;
};

// query invalidation call
const useInvalidateSyncSettingsData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(calendarSyncSettingsDataKey);
	};
};

// mutation call
const useStartCalendarSync = () => {
	return useMutation(startCalendarSync);
};

const useStopCalendarSync = () => {
	let invalidateSyncSettingsData = useInvalidateSyncSettingsData();
	return useMutation(stopCalendarSync, {
		onSuccess: () => {
			invalidateSyncSettingsData();
		},
	});
};

export {
	useStartCalendarSync,
	useStopCalendarSync,
	useCalendarSyncStatus,
	useCalendarSyncSettingsData,
};
