import { getRootKey } from "../rootKey";

const webFormKey = "webForm";

export const getWebFormKey = () => {
	return [...getRootKey(), webFormKey];
};

//query keys

export const getWebFormListKey = () => {
	return [...getWebFormKey(), "WebFormList"];
};

export const getWebFormListKeyActiveStatus = (isActive) => {
	return [...getWebFormListKey(), isActive];
};

export const templateListKey = [...getWebFormKey(), "templateList"];

export const formFieldsKey = [...getWebFormKey(), "formFields"];

//query key methods

export const getWebFormKeyById = (webFormId) => {
	return [...getWebFormKey(), String(webFormId)];
};

export const getWebFormDetailsKey = (webFormId) => {
	return [...getWebFormKeyById(webFormId), "details"];
};
