import React, { useState } from "react";
import {
	Box,
	Divider,
	Stack,
	Step,
	StepLabel,
	Stepper,
	stepLabelClasses,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { default as ArrowIcon } from "../../../../../assets/icons/dropDownRight";
import Create from "./Create";
import Customize from "./Customize";
import Publish from "./Publish";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useCreateMutation } from "../../../../../hooks/services/webform";

const StyledStepLabel = styled(StepLabel)(() => ({
	[`& .${stepLabelClasses.label}`]: {
		color: "#666666",
		fontSize: "15px",
		fontWeight: 600,
		[`&.${stepLabelClasses.active}`]: {
			color: "#000",
			fontWeight: 600,
		},
		[`&.${stepLabelClasses.completed}`]: {
			color: "#666666",
			fontWeight: 600,
		},
	},
}));

const StepIcon = (props) => {
	const theme = useTheme();
	const { active, completed } = props;

	return (
		<Box
			style={{
				width: "16px",
				height: "16px",
				backgroundColor: completed
					? theme.palette.primary.main
					: "#fff",
				border: active
					? `3px solid ${theme.palette.primary.main}`
					: completed
						? "3px solid #fff"
						: "1px solid #666",
				borderRadius: "50px",
			}}
		></Box>
	);
};

export default function WebFormEditor(props) {
	const { activeStep, setActiveStep, toggleSubmitOptions, setFormToPreview } =
		props;

	const steps = ["Create", "Customize", "Publish"];
	const [currentFormData, setCurrentFormData] = useState({});
	const [webformUrl, setWebFormUrl] = useState("");
	const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] =
		useState(false);

	const createMutation = useCreateMutation();

	const goNextStep = () => {
		setActiveStep((activeStep) => activeStep + 1);
	};

	const goPreviousStep = () => {
		setActiveStep((activeStep) => activeStep - 1);
	};

	//To update the Preview Form and Form Editor
	const updateFormData = (templateData) => {
		setFormToPreview(templateData);
		setCurrentFormData(templateData);
	};

	const deepCopyTemplate = (template) => {
		return JSON.parse(JSON.stringify(template));
	};

	//To move from create to customize
	const goNextToCustomize = (event, template) => {
		event.stopPropagation();
		const copyOfTemplate = deepCopyTemplate(template);
		updateFormData(copyOfTemplate);
		goNextStep();
	};

	//To remove id from the data, that wanted to pass as request
	const removeIdFromTemplateData = (template) => {
		const id = "id";
		if (Object.prototype.hasOwnProperty.call(template, id))
			delete template[id];
		if (Object.prototype.hasOwnProperty.call(template.onSubmit, id))
			delete template.onSubmit[id];
		if (Object.prototype.hasOwnProperty.call(template.style.fieldStyle, id))
			delete template.style.fieldStyle[id];
		if (Object.prototype.hasOwnProperty.call(template.style.formStyle, id))
			delete template.style.formStyle[id];
		if (
			Object.prototype.hasOwnProperty.call(
				template.style.submitButtonStyle,
				id
			)
		)
			delete template.style.submitButtonStyle[id];

		return template;
	};

	//To go next to publish from customize
	const goNextToPublish = () => {
		const templateCopy = deepCopyTemplate(currentFormData);
		const templateWithoutId = removeIdFromTemplateData(templateCopy);
		let requestData = getCreateRequestData(templateWithoutId);
		createMutation.mutate(requestData, {
			onSuccess: (data) => {
				setWebFormUrl(data.createdRecords[0].link);
				goNextStep();
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.webFormAdded,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const getCreateRequestData = (template) => {
		let requestData = Object.assign(template, {
			ownerId: 1,
			languageId: 1,
			templateId: currentFormData.id,
			isActive: true,
		});
		return requestData;
	};

	//To go back to create from customize
	const goBackToCreate = () => {
		setIsConfirmationDialogOpened(true);
	};

	const handleCancel = () => {
		setIsConfirmationDialogOpened(false);
	};

	const handleDeleteConfirm = () => {
		goPreviousStep();
		updateFormData();
		setIsConfirmationDialogOpened(false);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title="Are you sure you want to go to the previous page?"
				subtitle="Leaving this page will discard your unsaved changes. Do you want to leave or keep editing?"
				open={isConfirmationDialogOpened}
				onCancel={handleCancel}
				onDelete={handleDeleteConfirm}
				confirmButtonText="Leave page"
				cancelButtonText="Keep editing"
			></DeleteDialog>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box hidden={activeStep > 2}>
					<Stack
						justifyContent="center"
						alignItems="center"
						height="68px"
					>
						<Box>
							<Stepper
								activeStep={activeStep}
								connector={
									<Box px={1} pt={0.2}>
										{ArrowIcon(14, 14, "#666666")}
									</Box>
								}
							>
								{steps.map((label) => (
									<Step key={label}>
										<StyledStepLabel
											StepIconComponent={StepIcon}
										>
											{label}
										</StyledStepLabel>
									</Step>
								))}
							</Stepper>
						</Box>
					</Stack>

					<Divider />
				</Box>

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: "0px",
					}}
				>
					{(function () {
						switch (activeStep) {
							case 0:
								return (
									<Create
										currentFormData={currentFormData}
										updateFormData={updateFormData}
										goNextStep={goNextToCustomize}
									/>
								);
							case 1:
								return (
									<Customize
										currentFormData={currentFormData}
										toggleSubmitOptions={
											toggleSubmitOptions
										}
										updateFormData={updateFormData}
										goNextStep={goNextToPublish}
										goPreviousStep={goBackToCreate}
										isCreateMutationLoading={
											createMutation.isLoading
										}
									/>
								);
							case 2:
								return (
									<Publish
										goPreviousStep={goPreviousStep}
										webformUrl={webformUrl}
									/>
								);
							default:
								return null;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}
