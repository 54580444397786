const timeFormatList = [
	{
		name: "12 Hours",
		value: 1,
	},
	{
		name: "24 Hours",
		value: 2,
	},
];

export default timeFormatList;
