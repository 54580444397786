import { Button, MenuItem, Typography, useTheme } from "@mui/material";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDownCentered";
import Menu from "../../../../../Elements/Menu";
import { useState } from "react";

const statuses = [
	{
		id: -1,
		name: "All",
	},
	{
		id: 1,
		name: "Success",
	},
	{
		id: 2,
		name: "Failure",
	},
];

export default function StatusFilter(params) {
	const { handleUpdateFilter } = params;

	const theme = useTheme();

	const [statusMenuElement, setStatusMenuElement] = useState(null);
	const isStatusMenuOpened = Boolean(statusMenuElement);
	const [selectedStatus, setSelectedStatus] = useState(null);

	const openStatusMenu = (event) => {
		setStatusMenuElement(event.currentTarget);
	};

	const closeStatusMenu = () => {
		setStatusMenuElement(null);
	};

	const changeStatus = (status) => {
		setSelectedStatus(status.name);
		handleUpdateFilter({ value: status.id });
		closeStatusMenu();
	};

	return (
		<>
			<Menu
				minWidth="200px"
				anchorEl={statusMenuElement}
				open={isStatusMenuOpened}
				onClose={closeStatusMenu}
				style={{ marginTop: "8px" }}
			>
				{statuses.map((status) => (
					<MenuItem
						key={status.id}
						style={{
							minHeight: "40px",
						}}
						selected={selectedStatus === status.name}
						onClick={() => changeStatus(status)}
					>
						<Typography fontSize={13}>{status.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Button
				variant="contained"
				endIcon={DropDownIcon(
					16,
					16,
					theme.palette.secondary.contrastText
				)}
				color="secondary"
				disableElevation
				onClick={openStatusMenu}
			>
				{selectedStatus ? selectedStatus : "Status"}
			</Button>
		</>
	);
}
