import React, { useState } from "react";
import {
	Box,
	ClickAwayListener,
	Collapse,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { default as ContactImage } from "../../../assets/images/contact/unknownContact.png";
import { default as MailOpenIcon } from "../../../assets/icons/mailOpenSimple";

export default function MailSearch() {
	const theme = useTheme();
	const [searchKeyword, setSearchKeyword] = useState("");
	const [openSearchResult, setOpenSearchResult] = useState(false);
	const resultEmails = [
		{
			id: 1,
			email: "kate.jane@crm.com",
			image: "",
		},
		{
			id: 2,
			email: "cuber.kate@crm.com",
			image: "",
		},
		{
			id: 3,
			email: "katey.soby@crm.com",
			image: "",
		},
		{
			id: 4,
			email: "kateesh@crm.com",
			image: "",
		},
		{
			id: 5,
			email: "jim.kater@crm.com",
			image: "",
		},
	];

	const results = [
		{
			id: "1",
			time: "21 Jun 2023, 09.22 AM",
			email: "kadin.lubin@thelaunchclub.com",
			keyword:
				"Welcome Kate, We’re excited to welcome you to our special program held on 21 Jun 2023, 09.25AM",
		},
		{
			id: "2",
			time: "21 Jun 2023, 09.22 AM",
			email: "kadin.lubin@thelaunchclub.com",
			keyword:
				"Welcome Kate, We’re excited to welcome you to our special program held on 21 Jun 2023, 09.25AM",
		},
		{
			id: "3",
			time: "21 Jun 2023, 09.22 AM",
			email: "kadin.lubin@thelaunchclub.com",
			keyword:
				"Welcome Kate, We’re excited to welcome you to our special program held on 21 Jun 2023, 09.25AM",
		},
		{
			id: "4",
			time: "21 Jun 2023, 09.22 AM",
			email: "kadin.lubin@thelaunchclub.com",
			keyword:
				"Welcome Kate, We’re excited to welcome you to our special program held on 21 Jun 2023, 09.25AM",
		},
		{
			id: "5",
			time: "21 Jun 2023, 09.22 AM",
			email: "kadin.lubin@thelaunchclub.com",
			keyword:
				"Welcome Kate, We’re excited to welcome you to our special program held on 21 Jun 2023, 09.25AM",
		},
	];

	const searchKeywordChanged = (keyword) => {
		setSearchKeyword(keyword);
		if (keyword.length > 0) {
			openSearchResultComponent();
		} else {
			closeSearchResultComponent();
		}
	};

	const openSearchResultComponent = () => {
		setOpenSearchResult(true);
	};
	const closeSearchResultComponent = () => {
		setOpenSearchResult(false);
	};

	return (
		<React.Fragment>
			<ClickAwayListener onClickAway={closeSearchResultComponent}>
				<Box width="300px">
					<MenuSearchBar
						value={searchKeyword}
						onChange={(event) =>
							searchKeywordChanged(event.target.value)
						}
						onFocus={() => {
							searchKeyword.length > 0 &&
								openSearchResultComponent();
						}}
					/>

					<Collapse
						in={openSearchResult}
						timeout={200}
						style={{
							position: "absolute",
							width: "500px",
							right: "4px",
							zIndex: theme.zIndex.drawer + 1,
						}}
						unmountOnExit
					>
						<Box
							style={{
								backgroundColor: "#fff",
								maxHeight: "60vh",
								borderRadius: "6px",
								marginTop: "4px",
								boxShadow:
									"0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
							}}
						>
							<Stack
								direction="row"
								p={1.5}
								gap="8px"
								useFlexGap
								flexWrap="wrap"
							>
								{resultEmails.map((resultEmail) => (
									<Stack
										key={resultEmail.id}
										direction="row"
										spacing="6px"
										style={{
											padding: "6px",
											borderRadius: "8px",
											cursor: "pointer",
											backgroundColor:
												theme.palette.secondary.main,
										}}
									>
										<img
											src={ContactImage}
											width="22px"
											alt="contact"
										/>
										<Typography fontSize="14px">
											{resultEmail.email}
										</Typography>
									</Stack>
								))}
							</Stack>

							<Box>
								{results.map((result) => (
									<MenuItem
										key={result.id}
										style={{ padding: "16px" }}
										onClick={() => {}}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={2}
											pl={0.5}
											width="100%"
										>
											<Box>
												{MailOpenIcon(
													20,
													20,
													"rgba(0, 0, 0, 0.3)"
												)}
											</Box>

											<Box width="90%">
												<Typography
													fontSize={14}
													noWrap
												>
													{result.keyword}
												</Typography>

												<Typography
													fontSize={13}
													color="rgba(0, 0, 0, 0.6)"
												>
													{result.email}&nbsp; •
													&nbsp;{result.time}
												</Typography>
											</Box>
										</Stack>
									</MenuItem>
								))}
								<MenuItem
									style={{
										padding: "10px",
										paddingLeft: "56px",
										borderTop: `1px solid ${theme.palette.divider}`,
									}}
									onClick={() => {}}
								>
									<Typography
										fontSize={13}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										Show More
									</Typography>
								</MenuItem>
							</Box>
						</Box>
					</Collapse>
				</Box>
			</ClickAwayListener>
		</React.Fragment>
	);
}
