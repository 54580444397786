import DateRangePicker from "../../DateRangePicker";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { Typography, useTheme } from "@mui/material";
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";

const toTextFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const fromTextFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderRight: "1px solid rgba(0, 0, 0, 0.12)",
			borderTop: "none",
			borderBottom: "none",
			borderLeft: "none",
		},
		"&:hover fieldset": {
			borderRight: "1px solid rgba(0, 0, 0, 0.12)",
			borderTop: "none",
			borderBottom: "none",
			borderLeft: "none",
		},
		"&.Mui-focused fieldset": {
			borderRight: "1px solid rgba(0, 0, 0, 0.12)",
			borderTop: "none",
			borderBottom: "none",
			borderLeft: "none",
		},
	},
};

const DateRangeFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose, subFields }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const getSubFieldObject = (subFieldName) => {
			let subFieldObj;
			if (!!subFields && Array.isArray(subFields)) {
				subFields.forEach((field) => {
					if (field.name === subFieldName) {
						subFieldObj = field;
					}
				});
			}

			return subFieldObj;
		};

		const fromDateField = getSubFieldObject("FROM");
		const toDateField = getSubFieldObject("TO");

		const [dateRangeValue, setDateRangeValue] = useState({});

		useEffect(() => {
			if (value) {
				let dateRangeValueObj = {};
				dateRangeValueObj.from = value[fromDateField.id];
				dateRangeValueObj.to = value[toDateField.id];
				setDateRangeValue(dateRangeValueObj);
			}
		}, [value, fromDateField, toDateField]);

		const handleDateRangeValueChange = (newDateRangeValue, onChange) => {
			setDateRangeValue({ ...newDateRangeValue });
			let dateRangeValueObjectWithId = {};
			dateRangeValueObjectWithId[fromDateField.id] =
				newDateRangeValue.from;
			dateRangeValueObjectWithId[toDateField.id] = newDateRangeValue.to;

			let getDateRangeValue = () => {
				if (
					dateRangeValueObjectWithId[fromDateField.id] &&
					dateRangeValueObjectWithId[toDateField.id]
				) {
					return dateRangeValueObjectWithId;
				}

				return "";
			};

			onChange(getDateRangeValue());
			clearErrors(field.id);
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<DateRangePicker
							{...fieldData}
							value={dateRangeValue}
							fromTextFieldSx={fromTextFieldSx}
							toTextFieldSx={toTextFieldSx}
							textFieldSx={toTextFieldSx}
							onChange={(event) =>
								handleDateRangeValueChange(event, onChange)
							}
						></DateRangePicker>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

DateRangeFieldEditForm.displayName = "DateRangeFieldEditForm";

export default DateRangeFieldEditForm;
