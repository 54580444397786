import { Stack, Box, Typography, useTheme, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Menu from "../../../../../Elements/Menu";

export default function RenderTagsFieldValue(props) {
	const { fieldValue } = props;
	const theme = useTheme();

	const [tagsMenuElement, setTagsMenuElement] = useState(null);
	const isTagsMenuOpened = Boolean(tagsMenuElement);

	const openTagsMenu = (event) => {
		setTagsMenuElement(event.currentTarget);
	};

	const closeTagsMenu = () => {
		setTagsMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="240px"
				anchorEl={tagsMenuElement}
				open={isTagsMenuOpened}
				onClose={closeTagsMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{
					marginTop: "4px",
				}}
				menuListProps={{
					padding: "4px 0px",
				}}
			>
				{fieldValue?.map((tagData) => (
					<MenuItem
						key={tagData.id}
						sx={{
							height: "40px",
						}}
					>
						<Typography fontSize={14}>{tagData.tag}</Typography>
					</MenuItem>
				))}
			</Menu>

			{fieldValue?.length > 0 ? (
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
					justifyContent="center"
				>
					<Box
						textAlign="center"
						borderRadius={2}
						bgcolor="rgba(51, 188, 126, 0.12)"
						px={1}
						py={0.5}
					>
						<Typography fontSize={14}>
							{fieldValue?.[0]?.tag}
						</Typography>
					</Box>
					{fieldValue?.length > 1 && (
						<Box
							display="flex"
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={500}
							sx={{ cursor: "pointer" }}
							onClick={openTagsMenu}
						>
							+{fieldValue?.length - 1}
						</Box>
					)}
				</Stack>
			) : null}
		</React.Fragment>
	);
}
