import React from "react";

export default function Location(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 13.4299C10.2769 13.4299 8.88 12.0331 8.88 10.3099C8.88 8.58681 10.2769 7.18994 12 7.18994C13.7231 7.18994 15.12 8.58681 15.12 10.3099C15.12 12.0331 13.7231 13.4299 12 13.4299Z"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path
				d="M20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39001 20.54C5.63001 17.88 2.47001 13.57 3.62001 8.49C5.59001 -0.169998 18.42 -0.159997 20.38 8.5Z"
				stroke={color}
				strokeWidth="1.5"
			/>
		</svg>
	);
}
