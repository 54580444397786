import React, { useState } from "react";
import { Stack } from "@mui/material";
import DueDateFilter from "./HistoryFilter/DueDateFilter";
import StatusFilter from "./HistoryFilter/StatusFilter";
import WorkflowNameFilterDrawer from "./HistoryFilter/WorkflowNameFilterDrawer";

const filterList = [
	{
		filterGroup: 1,
		value: -1,
	},
	{
		filterGroup: 2,
		value: -1,
	},
	{
		filterGroup: 3,
		value: -1,
	},
];

export default function HistoryMenu(params) {
	const { listContainerRef, updateFilter, updateFilterMenuOpen } = params;

	const [filtersList, setFiltersList] = useState(filterList);

	const handleUpdateFilter = (filterGroup, filterValue) => {
		let clonedFilters = [...filtersList];
		let updatedFilter = clonedFilters.map((filter) => {
			if (filter.filterGroup === filterGroup) {
				return { filterGroup, ...filterValue };
			}
			return { ...filter };
		});
		setFiltersList(updatedFilter);
		updateFilter(updatedFilter);
	};

	return (
		<React.Fragment>
			<Stack direction="row" justifyContent="space-between" width="100%">
				<Stack direction="row" spacing={2}>
					<DueDateFilter
						handleUpdateFilter={(filterValues) =>
							handleUpdateFilter(2, filterValues)
						}
					/>

					<StatusFilter
						handleUpdateFilter={(filterValues) =>
							handleUpdateFilter(3, filterValues)
						}
					/>
				</Stack>

				<WorkflowNameFilterDrawer
					updateFilter={updateFilter}
					listContainerRef={listContainerRef}
					updateFilterMenuOpen={updateFilterMenuOpen}
					handleUpdateFilter={(filterValues) =>
						handleUpdateFilter(1, filterValues)
					}
				/>
			</Stack>
		</React.Fragment>
	);
}
