import React from "react";

export default function Settings(width = 20, height = 20, color = "white") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 32 32"
			style={{ fill: `${color}` }}
		>
			<path d="M5.64 1.667c-1.147 0-2.209.209-2.967.922-.773.727-1.006 1.765-1.006 2.878v21.067c0 1.113.233 2.151 1.006 2.878.758.713 1.82.922 2.967.922h5.387c1.147 0 2.209-.209 2.967-.922.773-.727 1.006-1.765 1.006-2.878V5.467c0-1.113-.233-2.151-1.006-2.878-.758-.713-1.82-.922-2.967-.922H5.64zm-1.973 3.8c0-.887.193-1.249.377-1.422.199-.187.624-.378 1.596-.378h5.387c.973 0 1.397.191 1.596.378.184.173.377.535.377 1.422v21.067c0 .887-.193 1.249-.377 1.422-.199.187-.624.378-1.596.378H5.64c-.973 0-1.397-.191-1.596-.378-.184-.173-.377-.535-.377-1.422V5.467zm17.306-3.8c-1.147 0-2.209.209-2.967.922C17.233 3.316 17 4.354 17 5.467V17.2c0 1.113.233 2.151 1.006 2.878.758.713 1.82.922 2.967.922h5.387c1.147 0 2.209-.209 2.967-.922.773-.727 1.006-1.765 1.006-2.878V5.467c0-1.113-.233-2.151-1.006-2.878-.758-.713-1.82-.922-2.967-.922h-5.387zM19 5.467c0-.887.193-1.249.377-1.422.199-.187.623-.378 1.596-.378h5.387c.973 0 1.397.191 1.596.378.184.173.377.535.377 1.422V17.2c0 .887-.193 1.249-.377 1.422-.199.187-.623.378-1.596.378h-5.387c-.973 0-1.397-.191-1.596-.378-.184-.173-.377-.535-.377-1.422V5.467z"></path>
		</svg>
	);
}
