import { useState } from "react";
import {
	Button,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import { default as DropDownRight } from "../../../../../../assets/icons/dropDownRight";
import DateRange from "../../../../../Activities/ActivityFilters/ActivityDueDate/DateRange";
import { getDateMonthAndYearFormat } from "../../../../../../utils/DateUtils";
import Menu from "../../../../../Elements/Menu";

const dueDates = [
	{
		id: -1,
		name: "All",
		isRange: false,
	},
	{
		id: 1,
		name: "Today",
		isRange: false,
	},
	{
		id: 2,
		name: "Yesterday",
		isRange: false,
	},
	{
		id: 3,
		name: "This Week",
		isRange: false,
	},
	{
		id: 4,
		name: "Last Week",
		isRange: false,
	},
	{
		id: 5,
		name: "Between",
		isRange: true,
	},
];

const betweenFilterId = 5;

export default function DueDateFilter(params) {
	const { handleUpdateFilter } = params;

	const theme = useTheme();
	const [dueDateMenuElement, setDueDateMenuElement] = useState(null);
	const isDueDateMenuOpened = Boolean(dueDateMenuElement);
	const [selectedDueDate, setSelectedDueDate] = useState({});
	const [dateRange, setDateRange] = useState("");
	const [dueDateList, setDueDateList] = useState(dueDates);

	const [selectedDate, setSelectedDate] = useState({});
	const openDueDateMenu = (event) => {
		setDueDateMenuElement(event.currentTarget);
	};

	const closeDueDateMenu = () => {
		setDueDateMenuElement(null);
	};

	const handleMoveBackToPrevious = () => {
		setSelectedDueDate({});
		setDateRange("");
	};

	const handleDateRangeValueChange = (newDateRangeValue) => {
		setSelectedDate(newDateRangeValue);
	};

	const handleRemoveDueDate = (event) => {
		event.stopPropagation();
		handleUpdateFilter({ value: -1 });
		resetDueDateList(dateRange);
		setSelectedDueDate({});
		closeDueDateMenu();
	};

	const onDueDateSelect = (selectedDueDate, dueDate, dateRange) => {
		if (
			!(
				selectedDueDate.id === dueDate.id ||
				(dueDate.isRange && dateRange)
			)
		) {
			return () => handleSelectDueDate(dueDate);
		}
	};

	const handleSelectDueDate = (dueDate) => {
		setSelectedDueDate(dueDate);
		resetDueDateList(dateRange);
		if (dueDate.id !== betweenFilterId) {
			handleUpdateFilter({ value: dueDate.id });
		}

		if (!dueDate.isRange) {
			setDueDateMenuElement(null);
		}
	};

	const resetDueDateList = (dateRange) => {
		if (dateRange) {
			//Remove the selected date and replace it with between
			const modifiedDueDateList = dueDateList.map((dueDate) => {
				if (dueDate.isRange) {
					return { ...dueDate, name: "Between" };
				} else {
					return dueDate;
				}
			});

			setDueDateList(modifiedDueDateList);
			setDateRange("");
			setSelectedDate({});
		}
	};

	const handleSave = () => {
		const startDate = getDateMonthAndYearFormat(selectedDate.from);
		const endDate = getDateMonthAndYearFormat(selectedDate.to);
		setDateRange(`${startDate} - ${endDate}`);
		setDueDateMenuElement(null);
		setSelectedDueDate({});

		//Update the selected date instead of between in the menu
		const modifiedDueDateList = dueDateList.map((dueDate) => {
			if (dueDate.isRange) {
				return { ...dueDate, name: `${startDate} - ${endDate}` };
			} else {
				return dueDate;
			}
		});

		let filterValue = {
			value: betweenFilterId,
			from: selectedDate.from,
			to: selectedDate.to,
		};
		handleUpdateFilter(filterValue);
		setDueDateList(modifiedDueDateList);
	};

	return (
		<>
			<Menu
				minWidth="200px"
				anchorEl={dueDateMenuElement}
				open={isDueDateMenuOpened}
				onClose={closeDueDateMenu}
				style={{ marginTop: "8px" }}
			>
				{selectedDueDate?.isRange ? (
					<DateRange
						handleDateRangeValueChange={handleDateRangeValueChange}
						handleSave={handleSave}
						handleMoveBackToPrevious={handleMoveBackToPrevious}
					/>
				) : (
					dueDateList.map((dueDate) => (
						<MenuItem
							key={dueDate.id}
							style={{
								minHeight: "40px",
							}}
							selected={
								selectedDueDate.id === dueDate.id ||
								!!(dueDate.isRange && dateRange)
							}
							onClick={onDueDateSelect(
								selectedDueDate,
								dueDate,
								dateRange
							)}
						>
							<Stack
								minWidth="202px"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={13}>
									{dueDate.name}
								</Typography>

								{(selectedDueDate.id === dueDate.id ||
									(dueDate.isRange && dateRange)) && (
									<IconButton
										disableRipple
										style={{ padding: 0 }}
										onClick={(event) =>
											handleRemoveDueDate(event)
										}
									>
										{CloseIcon(20, 20, "rgba(0,0,0,0.6)")}
									</IconButton>
								)}

								{dueDate.isRange && !dateRange && (
									<IconButton
										disableRipple
										style={{ padding: 0 }}
									>
										{DropDownRight(
											16,
											16,
											"rgba(0,0,0,0.6)"
										)}
									</IconButton>
								)}
							</Stack>
						</MenuItem>
					))
				)}
			</Menu>

			<Button
				variant="contained"
				endIcon={DropDownIcon(
					16,
					16,
					theme.palette.secondary.contrastText
				)}
				color="secondary"
				disableElevation
				onClick={openDueDateMenu}
			>
				{selectedDueDate.id && !selectedDueDate.isRange
					? selectedDueDate.name
					: dateRange
						? dateRange
						: "Due Date"}
			</Button>
		</>
	);
}
