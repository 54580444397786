import React from "react";

function Flag(width = "14", height = "14", color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.00391 1.16663V12.8333"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.00391 2.33325H9.53724C11.1122 2.33325 11.4622 3.20825 10.3539 4.31659L9.65391 5.01659C9.18724 5.48325 9.18724 6.24158 9.65391 6.64992L10.3539 7.34992C11.4622 8.45825 11.0539 9.33325 9.53724 9.33325H3.00391"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Flag;
