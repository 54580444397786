import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	dealFieldListKey,
	getDealKeyById,
	getDealMergePreviewDataKey,
	getMergeDealDetailsKey,
} from "../../../../utils/queryKeys";
import {
	getMergeDealDetails,
	getDealFieldList,
	getDealMergePreviewData,
	mergeDeal,
} from "../../../../api/deal/dealMerge/dealMergeApi";

const useDealFieldList = () => {
	return useQuery(dealFieldListKey, () => getDealFieldList(), {
		select: (data) => data?.fields,
	});
};

const useMergeDealDetails = (dealId) => {
	let dealDataKey = getMergeDealDetailsKey(dealId);
	return useQuery(dealDataKey, () => getMergeDealDetails(dealId), {
		enabled: !!dealId,
	});
};

const useDealMergePreviewData = (primaryDealId, secondaryDealId) => {
	let dealMergePreviewDataKey = getDealMergePreviewDataKey(
		primaryDealId,
		secondaryDealId
	);
	return useQuery(
		dealMergePreviewDataKey,
		() => getDealMergePreviewData(primaryDealId, secondaryDealId),
		{
			select: (data) => data?.preview,
		}
	);
};

const useInvalidateDealDetails = (dealId) => {
	let queryClient = useQueryClient();
	let dealDetailsKey = getDealKeyById(dealId);
	return () => {
		queryClient.invalidateQueries(dealDetailsKey);
	};
};

const useDealMerge = (dealId) => {
	let invalidateDealDetails = useInvalidateDealDetails(dealId);
	return useMutation(mergeDeal, {
		onSuccess: () => {
			invalidateDealDetails();
		},
	});
};

export {
	useDealFieldList,
	useMergeDealDetails,
	useDealMergePreviewData,
	useDealMerge,
};
