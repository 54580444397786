import React, { useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { TableCellText, TableHeaderLabel } from "../../../../styles/twozo";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import DeleteDialog from "../../../Elements/DeleteDialog";
import AddEmailOrManageUserDialog from "../AddEmailOrManageUserDialog";

export default function TeamInboxEmailList() {
	const theme = useTheme();
	const [hoveredRowId, setHoveredRowId] = useState(null);
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
	const [selectedEmailAddress, setSelectedEmailAddress] = useState({});
	const [isManageUserDialogOpen, setIsManageUserDialogOpen] = useState(false);

	const onMouseOverTableRow = (id) => setHoveredRowId(id);
	const onMouseOutTableRow = () => setHoveredRowId(null);

	const openDeleteDialog = (emailAddress) => {
		setSelectedEmailAddress(emailAddress);
		setIsOpenDeleteDialog(true);
	};

	const closeDeleteDialog = () => {
		setIsOpenDeleteDialog(false);
	};

	const openManageUserDialog = (emailAddress) => {
		setSelectedEmailAddress(emailAddress);
		setIsManageUserDialogOpen(true);
	};
	const closeManageUserDialog = () => {
		setIsManageUserDialogOpen(false);
	};

	const emailDetails = [
		{
			id: 1,
			emailAddress: "justynarotich851@tin.biz",
			verificationStatus: "Verified",
			autoForwardingStatus: "3 months ago",
			isVerified: true,
			isAutoForwarding: true,
		},
		{
			id: 2,
			emailAddress: "klaus_ivanov373@zonnet.net",
			verificationStatus: "Send Verification Mail",
			autoForwardingStatus: "NA",
			isVerified: false,
			isAutoForwarding: false,
		},
		{
			id: 3,
			emailAddress: "kamil.parker419@virgilio.org",
			verificationStatus: "Verified",
			autoForwardingStatus: "4 days ago",
			isVerified: true,
			isAutoForwarding: true,
		},
	];

	return (
		<React.Fragment>
			<AddEmailOrManageUserDialog
				title="Manage Users"
				open={isManageUserDialogOpen}
				onClose={closeManageUserDialog}
				emailAddress={selectedEmailAddress}
				isManageUser={true}
			/>

			<DeleteDialog
				title={`Are you sure you want to delete Team Inbox Email "${selectedEmailAddress}" ?`}
				open={isOpenDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={closeDeleteDialog}
			/>

			<TableContainer>
				<Table sx={{ minWidth: 650 }} size="small">
					<TableHead>
						<TableRow>
							<TableCell width="35%">
								<TableHeaderLabel>
									Email Address
								</TableHeaderLabel>
							</TableCell>

							<TableCell width="25%">
								<TableHeaderLabel>
									Verification Status
								</TableHeaderLabel>
							</TableCell>

							<TableCell width="20%">
								<TableHeaderLabel>
									Auto-forwarding status
								</TableHeaderLabel>
							</TableCell>

							<TableCell width="20%"></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{emailDetails.map((email) => (
							<TableRow
								key={email.id}
								onMouseOver={() =>
									onMouseOverTableRow(email.id)
								}
								onMouseOut={() => onMouseOutTableRow()}
							>
								<TableCell width="35%">
									<TableCellText>
										{email.emailAddress}
									</TableCellText>
								</TableCell>

								<TableCell width="25%">
									<TableCellText
										style={{
											color:
												!email.isVerified &&
												theme.palette.secondary
													.contrastText,
										}}
									>
										{email.verificationStatus}
									</TableCellText>
								</TableCell>

								<TableCell width="20%">
									<TableCellText
										style={{
											color:
												!email.isAutoForwarding &&
												"rgba(0, 0, 0, 0.6)",
										}}
									>
										{email.autoForwardingStatus}
									</TableCellText>
								</TableCell>

								<TableCell width="20%" sx={{ py: 0 }}>
									{hoveredRowId === email.id ? (
										<Box
											style={{
												height: "37px",
												border: `1px solid ${theme.palette.primary.main}`,
												borderRadius: "8px",
											}}
											py={1}
										>
											<Stack
												direction="row"
												justifyContent="center"
												alignItems="center"
												style={{ height: "100%" }}
												spacing={1.5}
											>
												<Typography
													fontSize={14}
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
													onClick={() =>
														openManageUserDialog(
															email.emailAddress
														)
													}
												>
													Manage Users
												</Typography>

												<Divider orientation="vertical" />

												<IconButton
													onClick={() =>
														openDeleteDialog(
															email.emailAddress
														)
													}
												>
													{DeleteIcon(
														20,
														20,
														theme.palette.primary
															.main
													)}
												</IconButton>
											</Stack>
										</Box>
									) : null}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
}
