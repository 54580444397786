import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { emailSettingsDataKey } from "../../../../utils/queryKeys/email";
import {
	getEmailSettingsData,
	updateEmailSettingsData,
} from "../../../../api/email/emailSettings";

const useEmailSettingsData = () => {
	return useQuery(emailSettingsDataKey, () => getEmailSettingsData(), {
		select: (data) => data.emailSettings,
	});
};

const useInvalidateEmailSettingsData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(emailSettingsDataKey);
	};
};

const useUpdateEmailSettingsData = () => {
	let invalidateEmailSettingsData = useInvalidateEmailSettingsData();
	return useMutation(updateEmailSettingsData, {
		onSuccess: () => {
			invalidateEmailSettingsData();
		},
	});
};

export { useEmailSettingsData, useUpdateEmailSettingsData };
