import React from "react";

export default function Quotes(width = 24, height = 24, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.6">
				<path
					d="M10.8 7.76636C9.77996 7.76636 9.07996 6.99301 9.07996 6.04635V3.89966C9.07996 2.95299 9.77996 2.17969 10.8 2.17969H12.9466C13.8933 2.17969 14.6666 2.95299 14.6666 3.89966V7.76636L10.8 7.76636Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.6667 7.76563C14.6667 11.799 13.9134 12.4656 11.6467 13.8123"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.91333 7.76636L3.04666 7.76636C2.02666 7.76636 1.32666 6.99301 1.32666 6.04635L1.32666 3.89966C1.32666 2.95299 2.02666 2.17969 3.04666 2.17969H5.19999C6.14666 2.17969 6.91999 2.95299 6.91999 3.89966L6.91999 7.76636"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.91343 7.76563C6.91343 11.799 6.1601 12.4656 3.89343 13.8123"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
