import { Box } from "@mui/material";
import React from "react";
import FullScreenDialog from "../FullScreenDialog";
import ImportModule from "../../ImportModule/index";

export default function ImportModuleDialog(props) {
	const { open, onClose, moduleName, disableBackdropClick } = props;

	const closeCustomDrawer = (_, reason) => {
		if (disableBackdropClick) {
			if (reason !== "backdropClick") {
				onClose();
			}
		} else {
			onClose();
		}
	};

	return (
		<React.Fragment>
			<Box overflow="auto">
				<FullScreenDialog
					open={open}
					onClose={closeCustomDrawer}
					hideDialogTitle={true}
				>
					<ImportModule
						onClose={closeCustomDrawer}
						moduleName={moduleName}
					/>
				</FullScreenDialog>
			</Box>
		</React.Fragment>
	);
}
