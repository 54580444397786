import React, { useState } from "react";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as MoreIcon } from "../../../../assets/icons/more";
import {
	Box,
	Button,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../../Elements/Menu";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as CopyIcon } from "../../../../assets/icons/copy";
import { default as ExportIcon } from "../../../../assets/icons/export";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useDeleteCompanyMutation } from "../../../../hooks/services/company";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import CloneCompany from "../CloneCompany";
import { twozoStyles } from "../../../../styles/twozo";
import ExportDialog from "../../../Elements/Export/ExportDialog";
import CancelExportDialog from "../../../Elements/Export/CancelDialog";
import { useAuth } from "../../../../hooks/auth";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useModuleName } from "../../../../hooks/modules";
import { modules } from "../../../../utils/common/ModulesName";

export default function SummaryMenu(props) {
	const { openEditDialog, onAddDealClick, companyId } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();
	const { isUserAllowedFor } = useAuth();
	const deleteMutation = useDeleteCompanyMutation();

	const [companySummaryMenuElement, setCompanySummaryMenuElement] =
		useState(null);
	const isCompanySummaryMenuOpened = Boolean(companySummaryMenuElement);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isExportDialogOpened, setIsExportDialogOpened] = useState(false);
	const [isCancelExportingDialogOpened, setIsCancelExportingDialogOpened] =
		useState(false);
	const [isCloneCompanyDialogOpened, setIsCloneCompanyDialogOpened] =
		useState(false);

	const { getModuleName } = useModuleName();

	const openCompanySummaryMenu = (event) => {
		setCompanySummaryMenuElement(event.currentTarget);
	};

	const closeCompanySummaryMenu = () => {
		setCompanySummaryMenuElement(null);
	};

	const handleEditCompany = () => {
		closeCompanySummaryMenu();
		openEditDialog();
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		setCompanySummaryMenuElement(null);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteCompany = () => {
		deleteMutation.mutate(companyId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.companyDeleted,
				});
				navigate("/companies");
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const toggleCloneCompanyDialog = () => {
		setIsCloneCompanyDialogOpened(
			(isCloneCompanyDialogOpened) => !isCloneCompanyDialogOpened
		);
	};

	const handleCloneCompany = () => {
		toggleCloneCompanyDialog();
		closeCompanySummaryMenu();
	};

	const openExportDialog = () => {
		setIsExportDialogOpened(true);
		closeCompanySummaryMenu();
	};

	const closeExportDialog = () => {
		setIsExportDialogOpened(false);
	};

	const openCancelDialog = () => {
		setIsCancelExportingDialogOpened(true);
		closeExportDialog();
	};

	const cancelExporting = () => {
		setIsExportDialogOpened(false);
		setIsCancelExportingDialogOpened(false);
	};

	const exportFile = () => {
		openCancelDialog();
	};

	const companyMenu = [
		{
			id: "edit",
			name: "Edit",
			icon: EditIcon,
			action: handleEditCompany,
			permission: isUserAllowedFor(PERMISSIONS.company.edit),
		},
		{
			id: "clone",
			name: "Clone",
			icon: CopyIcon,
			action: handleCloneCompany,
			permission: isUserAllowedFor(PERMISSIONS.company.cloneRecords),
		},
		{
			id: "delete",
			name: "Delete",
			icon: DeleteIcon,
			action: openDeleteDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.delete),
		},
		{
			id: "export",
			name: "Export",
			icon: ExportIcon,
			action: openExportDialog,
			permission: isUserAllowedFor(PERMISSIONS.company.exportRecords),
		},
	];

	const getCompanyMenu = () => {
		let filteredCompanyMenu = companyMenu.filter(
			(menuOption) => menuOption.permission
		);
		return filteredCompanyMenu;
	};

	let moduleName = getModuleName(modules.COMPANY).toLowerCase();

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteCompany}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isCloneCompanyDialogOpened}
				onOpen={toggleCloneCompanyDialog}
				onClose={toggleCloneCompanyDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<CloneCompany
						onClose={toggleCloneCompanyDialog}
						companyId={companyId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ExportDialog
				open={isExportDialogOpened}
				onClose={closeExportDialog}
				exportFile={exportFile}
			/>

			<CancelExportDialog
				open={isCancelExportingDialogOpened}
				onCancel={cancelExporting}
			/>

			<Menu
				minWidth="200px"
				anchorEl={companySummaryMenuElement}
				open={isCompanySummaryMenuOpened}
				onClose={closeCompanySummaryMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "8px",
				}}
			>
				{getCompanyMenu()?.map(
					(menuItem) =>
						menuItem.permission && (
							<MenuItem
								key={menuItem.id}
								style={{ height: "40px" }}
								onClick={() => menuItem.action()}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1.5}
								>
									{menuItem.icon(
										"20",
										"20",
										theme.palette.primary.main
									)}
									<Typography
										fontSize={13}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{menuItem.name}
									</Typography>
								</Stack>
							</MenuItem>
						)
				)}
			</Menu>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				height="100%"
			>
				<Can permission={PERMISSIONS.deal.create}>
					<Button
						variant="contained"
						startIcon={AddIcon(20, 20, "#fff")}
						disableElevation
						onClick={onAddDealClick}
					>
						Deal
					</Button>
				</Can>

				{getCompanyMenu()?.length > 0 && (
					<IconButton size="small" onClick={openCompanySummaryMenu}>
						{MoreIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				)}
			</Stack>
		</React.Fragment>
	);
}
