import {
	ButtonBase,
	Grid,
	Stack,
	TextField,
	Typography,
	Select,
	MenuItem,
	Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { hexToRgba } from "../../../../../../../styles/twozo";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { default as DropdownIcon } from "../../../../../../../assets/icons/dropDown";
import AddDealProduct from "../../../../../../Deals/AddNewDeals/AddDealProduct";
import { useTheme } from "@emotion/react";
import { useCurrencies } from "../../../../../../../hooks/settings/currency";
import MenuSearchBar from "../../../../../MenuSearchBar";
import { PERMISSIONS } from "../../../../../../../utils/Auth";
import { useAuth } from "../../../../../../../hooks/auth";

export default function ProductBaseComponent({
	field,
	onProductInputChange,
	subField,
	value,
	inputRef,
	error,
	clearErrors,
}) {
	const theme = useTheme();

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const productCurrencyField = getSubFieldObject("CURRENCY");
	const productValueField = getSubFieldObject("VALUE");
	const productField = getSubFieldObject("PRODUCT");

	const [productWithDealValue, setProductWithDealValue] = useState({
		[productCurrencyField?.id]: "",
		[productValueField.id]: 0,
		[productField.id]: {
			items: [
				{
					product: null,
					price: null,
					quantity: null,
					tax: null,
					discount: null,
				},
			],
			taxInclusive: true,
		},
		isManuallyAdded: false,
	});
	const [dealValue, setDealValue] = useState({
		[productCurrencyField?.id]: "",
		[productValueField?.id]: 0,
		isManuallyAdded: true,
	});
	const [isManuallyAdded, setIsManuallyAdded] = useState(true);
	const [searchedCurrencyValue, setSearchedCurrencyValue] = useState("");

	const { activeCurrencyList, getCurrencyDataById, baseCurrency } =
		useCurrencies(searchedCurrencyValue);
	const disabledCurrecyInput = activeCurrencyList?.length === 1;
	const isProductInputEnabled = field?.config?.isProductEnabled;
	const relatedFieldId = field?.relatedField?.id;

	const { isUserAllowedFor } = useAuth();

	useEffect(() => {
		if (baseCurrency) {
			setDealValue({
				...dealValue,
				[productCurrencyField?.id]: value?.[productCurrencyField?.id]
					? value[productCurrencyField?.id]
					: baseCurrency?.id,
			});
			setProductWithDealValue({
				...productWithDealValue,
				[productCurrencyField?.id]: value?.[productCurrencyField?.id]
					? value[productCurrencyField?.id]
					: baseCurrency?.id,
			});
		}
	}, [baseCurrency, value]);

	useEffect(() => {
		if (!!value && Object.keys(value).length > 0) {
			if (value.isManuallyAdded) {
				setDealValue(value);
				setIsManuallyAdded(true);
			} else {
				setProductWithDealValue(value);
				setIsManuallyAdded(
					isUserAllowedFor(PERMISSIONS.product.view) ? false : true
				);
			}
		}
	}, [value]);

	const styles = {
		formButtonBase: {
			height: "42px",
			borderRadius: "0px 8px 8px 0px",
			border: `1px solid ${
				error ? theme.palette.error.main : "rgba(0, 0, 0, 0.1)"
			}`,
			marginLeft: "-1px",
			backgroundColor: field?.config?.isProductEnabled
				? theme.palette.secondary.main
				: hexToRgba(theme.palette.secondary.main, 0.6),
			color: field?.config?.isProductEnabled
				? theme.palette.secondary.contrastText
				: hexToRgba(theme.palette.secondary.contrastText, 0.6),
		},
	};

	const toggleAddProducts = () => {
		if (isProductInputEnabled) {
			const isManuallyAddedModified = !isManuallyAdded;
			setIsManuallyAdded(isManuallyAddedModified);
			const selectedDealValue = getSelectedDealValue(
				isManuallyAddedModified
			);
			onProductInputChange(selectedDealValue);
		}
	};

	const getSelectedDealValue = (isManuallyAddedModified) => {
		if (isManuallyAddedModified) {
			return dealValue;
		} else {
			const updatedProductWithDealValue = { ...productWithDealValue };
			if (updatedProductWithDealValue[productValueField?.id]) {
				return updatedProductWithDealValue;
			}

			delete updatedProductWithDealValue[productField.id];
			return updatedProductWithDealValue;
		}
	};

	const handleInputChange = (event) => {
		const { value } = event.target;
		const modifiedDealValue = {
			...dealValue,
			[productValueField?.id]: value,
			isManuallyAdded: true,
		};

		setDealValue(modifiedDealValue);
		onProductInputChange(modifiedDealValue);
		clearErrors(field.id);
	};

	const handleChangeDealValueWithProduct = (
		product,
		totalProductAmount,
		selectedProducts
	) => {
		const modifiedProductWithDealValue = {
			...productWithDealValue,
			[productField.id]: product,
			[productValueField.id]: totalProductAmount,
			isManuallyAdded: false,
		};

		if (!!selectedProducts && selectedProducts.length > 0) {
			setProductWithDealValue(modifiedProductWithDealValue);
			onProductInputChange(modifiedProductWithDealValue);
			clearErrors(field.id);
		}
	};

	const handleCurrencyChange = (event, subFieldId) => {
		let { value } = event.target;

		if (isManuallyAdded) {
			let modifiedDealValue = { ...dealValue, [subFieldId]: value };
			setDealValue(modifiedDealValue);
			onProductInputChange(modifiedDealValue);
		} else {
			let modifiedProductWithDealValue = {
				...productWithDealValue,
				[subFieldId]: value,
			};
			setProductWithDealValue(modifiedProductWithDealValue);
			onProductInputChange(modifiedProductWithDealValue);
		}
		clearErrors(field.id);
	};

	const handleSearchCurrency = (event) => {
		const { value } = event.target;
		setSearchedCurrencyValue(value);
	};

	const getSelectedCurrency = (selectedCurrencyId) => {
		let selectedCurrency = getCurrencyDataById(selectedCurrencyId);
		return selectedCurrency;
	};

	const onOpenCurrencyMenu = () => {
		setSearchedCurrencyValue("");
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const isAddProductButtonVisible = () => {
		return (
			isUserAllowedFor(PERMISSIONS.product.create) &&
			isProductInputEnabled
		);
	};

	return (
		<React.Fragment>
			<Stack spacing={2}>
				<Grid item xs={12}>
					<Stack spacing={0.5}>
						<Stack direction="row">
							<Select
								disabled={disabledCurrecyInput}
								value={
									isManuallyAdded
										? dealValue?.[productCurrencyField?.id]
										: productWithDealValue?.[
												productCurrencyField?.id
											]
								}
								IconComponent={
									disabledCurrecyInput
										? null
										: (props) => (
												<Box {...props}>
													{DropdownIcon(
														16,
														16,
														"#000",
														0.6
													)}
												</Box>
											)
								}
								sx={{
									width: "15%",
									"& .MuiOutlinedInput-notchedOutline": {
										borderRadius: "8px 0px 0px 8px",
										marginRight: "-0.5px",
										opacity: disabledCurrecyInput ? 0.6 : 1,
									},
									"& .MuiSelect-select": {
										padding: disabledCurrecyInput
											? "0px !important"
											: "16.5px 14px",
										textAlign: "center",
									},
								}}
								onChange={(event) =>
									handleCurrencyChange(
										event,
										productCurrencyField.id
									)
								}
								onOpen={onOpenCurrencyMenu}
								renderValue={(selectedCurrencyId) =>
									getSelectedCurrency(selectedCurrencyId)
										?.code
								}
								MenuProps={{
									autoFocus: false,
									PaperProps: {
										style: {
											maxHeight: "240px",
											width: "300px",
											marginLeft: "100px",
										},
									},
								}}
								error={!!error}
							>
								<Box
									p={0.5}
									marginTop="-8px"
									sx={{
										position: "sticky",
										top: 0,
										zIndex: 1,
										backgroundColor: "#fff",
									}}
								>
									<MenuSearchBar
										value={searchedCurrencyValue}
										onChange={handleSearchCurrency}
									/>
								</Box>
								{activeCurrencyList?.length > 0
									? activeCurrencyList.map(
											(currency, index) => (
												<MenuItem
													key={index}
													value={currency.id}
													sx={{
														height: "40px",
													}}
												>
													<Stack
														direction="row"
														justifyContent="space-between"
														width="100%"
													>
														<Typography fontSize="14px">
															{currency.name}
														</Typography>
														<Typography fontSize="14px">
															{currency.code}
														</Typography>
													</Stack>
												</MenuItem>
											)
										)
									: renderEmptyView()}
							</Select>

							<TextField
								inputRef={inputRef}
								value={
									isManuallyAdded
										? dealValue?.[productValueField?.id]
										: productWithDealValue?.[
												productValueField?.id
											]
								}
								disabled={!isManuallyAdded}
								placeholder={
									productValueField?.placeHolder || "0.00"
								}
								sx={{
									width: isAddProductButtonVisible()
										? "60%"
										: "100%",
									"& .MuiOutlinedInput-notchedOutline": {
										borderRadius:
											isAddProductButtonVisible()
												? "0px"
												: "0px 8px 8px 0px",
										borderColor:
											!!error && theme.palette.error.main,
									},
									"& .MuiInputBase-root.Mui-disabled": {
										"& > fieldset": {
											borderColor:
												!!error &&
												theme.palette.error.main,
										},
									},
									"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
										{
											"-webkit-appearance": "none",
										},
								}}
								onChange={handleInputChange}
								type="number"
								error={!!error}
							/>

							{isAddProductButtonVisible() && (
								<ButtonBase
									style={{
										...styles.formButtonBase,
										width: "25%",
									}}
									onClick={toggleAddProducts}
								>
									{isManuallyAdded ? (
										<>
											{AddIcon(
												20,
												20,
												isProductInputEnabled
													? theme.palette.primary.main
													: hexToRgba(
															theme.palette
																.primary.main,
															0.6
														)
											)}
											<Typography
												fontSize={13}
												fontWeight={500}
												pl={0.5}
											>
												Add Products
											</Typography>
										</>
									) : (
										<Typography
											fontSize={13}
											fontWeight={500}
											pl={0.5}
										>
											Enter Manually
										</Typography>
									)}
								</ButtonBase>
							)}
						</Stack>
					</Stack>
				</Grid>

				{!isManuallyAdded && (
					<Grid item xs={12}>
						<AddDealProduct
							relatedFieldId={relatedFieldId}
							productField={productField}
							productWithDealValue={productWithDealValue}
							handleChangeDealValueWithProduct={
								handleChangeDealValueWithProduct
							}
							currencyValue={getSelectedCurrency(
								productWithDealValue?.[productCurrencyField.id]
							)}
						/>
					</Grid>
				)}
			</Stack>
		</React.Fragment>
	);
}
