const languages = [
	{
		value: 1,
		name: "Arabic (عربي)",
	},
	{
		value: 2,
		name: "Catalan (Català)",
	},
	{
		value: 3,
		name: "Czech (Čeština)",
	},
	{
		value: 4,
		name: "Chinese Simplified (简体中文)",
	},
	{
		value: 5,
		name: "Chinese Traditional (繁體中文)",
	},
	{
		value: 6,
		name: "Danish (Dansk)",
	},
	{
		value: 7,
		name: "Dutch (Nederlands)",
	},
	{
		value: 8,
		name: "English (United Kingdom)",
	},
	{
		value: 9,
		name: "English (United States)",
	},
	{
		value: 10,
		name: "Estonian (Eesti keel)",
	},
	{
		value: 11,
		name: "Finnish (Suomi)",
	},
	{
		value: 12,
		name: "French (Français)",
	},
	{
		value: 13,
		name: "German (Deutsch)",
	},
	{
		value: 14,
		name: "Hebrew (עִברִית)",
	},
	{
		value: 15,
		name: "Hungarian (Magyar)",
	},
	{
		value: 16,
		name: "Indonesian (Bahasa Indonesia)",
	},
	{
		value: 17,
		name: "Italian (Italiano)",
	},
	{
		value: 18,
		name: "Japanese (日本語)",
	},
	{
		value: 19,
		name: "Korean (한국어)",
	},
	{
		value: 20,
		name: "Latvian (Latviešu)",
	},
	{
		value: 21,
		name: "Norwegian (Norsk)",
	},
	{
		value: 22,
		name: "Polish (Polski)",
	},
	{
		value: 23,
		name: "Portuguese Brazil (Português)",
	},
	{
		value: 24,
		name: "Portuguese Portugal (Português)",
	},
	{
		value: 25,
		name: "Romanian (Română)",
	},
	{
		value: 26,
		name: "Russian (русский)",
	},
	{
		value: 27,
		name: "Slovak (Slovenčina)",
	},
	{
		value: 28,
		name: "Slovenian (Slovenčina)",
	},
	{
		value: 29,
		name: "Spanish Latin America (Español)",
	},
	{
		value: 30,
		name: "Spanish Spain (Español)",
	},
	{
		value: 31,
		name: "Swedish (Svenska)",
	},
	{
		value: 32,
		name: "Thai (ภาษาไทย)",
	},
	{
		value: 33,
		name: "Turkish (Türkçe)",
	},
	{
		value: 34,
		name: "Ukrainian (Українська)",
	},
	{
		value: 35,
		name: "Vietnamese (Tiếng Việt)",
	},
];

export default languages;
