import React, { useEffect } from "react";
import TimeLine from "../../../Summary/Timeline";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useCompanyTimelineData } from "../../../../hooks/services/company/summary/timeline";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function CompanyTimeline(props) {
	const { companyId, timelineIdsToFilter } = props;

	const {
		isLoading: isLoadingTimelineDetails,
		data: companyTimelineList,
		status: companyTimelineStatus,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useCompanyTimelineData(companyId, timelineIdsToFilter);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		if (companyTimelineStatus === "error") {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	}, [companyTimelineStatus]);

	return (
		<React.Fragment>
			<TimeLine
				lastRowRef={lastRowRef}
				timelineList={companyTimelineList}
				isLoadingTimelineDetails={isLoadingTimelineDetails}
				isFetchingMoreData={!isLoadingTimelineDetails && isFetching}
			/>
		</React.Fragment>
	);
}
