import React from "react";

export default function ProfileAdd(
	width = "20",
	height = "20",
	color = "#000"
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 14 19"
			fill="none"
		>
			<path
				d="M12.4166 16.25H9.08325"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.75 17.9168V14.5835"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.8582 9.05841C4.87487 8.99175 3.29984 7.36675 3.29984 5.36675C3.29153 3.32508 4.94987 1.66675 6.99153 1.66675C9.0332 1.66675 10.6915 3.32508 10.6915 5.36675C10.6915 7.36675 9.1082 8.99175 7.1332 9.05841C7.04987 9.05008 6.94987 9.05008 6.8582 9.05841Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.9917 18.1751C5.47503 18.1751 3.9667 17.7917 2.8167 17.0251C0.800033 15.6751 0.800033 13.4751 2.8167 12.1334C5.10837 10.6001 8.8667 10.6001 11.1584 12.1334"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
