import { Typography, useTheme } from "@mui/material";
import SingleDatePicker from "../../../../../../../../Elements/SingleDatePicker";
import React from "react";

export default function DateComponent(props) {
	const { formProperty, formStyle } = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<Typography
				pl={1}
				fontSize={13}
				fontWeight={500}
				style={formStyle.labelColor}
			>
				{formProperty.label}
				{formProperty.config.isRequired && (
					<span style={{ color: theme.palette.error.main }}> *</span>
				)}
			</Typography>

			<SingleDatePicker
				sx={{
					"& .MuiOutlinedInput-root": formStyle.lableInput,
					"& .MuiOutlinedInput-input": {
						textAlign: formStyle.lableInput.textAlign,
					},
				}}
				position={
					formStyle.lableInput.textAlign === "right" ? "end" : "start"
				}
			/>
		</React.Fragment>
	);
}
