import React from "react";
import Conversations from "../../../Summary/Conversations";
import { modules } from "../../../../utils/common/ModulesName";

export default function ContactConversations(props) {
	const { contactId } = props;

	return (
		<React.Fragment>
			<Conversations sourceName={modules.CONTACT} entityId={contactId} />
		</React.Fragment>
	);
}
