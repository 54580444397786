import { apiClient } from "..";
import { getCalendarSyncUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getCalendarSyncSettingsData = async () => {
	let requestData = getCalendarSyncSettingsDataRequest();
	let response = postCalendarSycSettingsApi(requestData);
	return await response;
};

export const startCalendarSync = async (data) => {
	let requestData = getStartCalendarSyncRequest(data);
	let response = postCalendarSycSettingsApi(requestData);
	return await response;
};

export const stopCalendarSync = async (id) => {
	let requestData = getStopCalendarSyncRequest(id);
	let response = postCalendarSycSettingsApi(requestData);
	return await response;
};

export const getCalendarSyncStatus = async (id) => {
	let requestData = getCalendarSyncStatusRequest(id);
	let response = postCalendarSycSettingsApi(requestData);
	return await response;
};

const getCalendarSyncStatusRequest = (id) => {
	return {
		type: "checkSyncStatus",
		data: {
			id: id,
		},
	};
};

const getStopCalendarSyncRequest = (id) => {
	return {
		type: "stopSync",
		data: {
			id: id,
		},
	};
};

const getStartCalendarSyncRequest = (data) => {
	return {
		type: "startSync",
		data: data,
	};
};

const getCalendarSyncSettingsDataRequest = () => {
	return {
		type: "get",
		data: {},
	};
};

const postCalendarSycSettingsApi = async (requestData) => {
	let calendarSyncSettingsUrl = getCalendarSyncSettingsUrl();
	let response = await apiClient.post(calendarSyncSettingsUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getCalendarSyncSettingsUrl = () => {
	return getCalendarSyncUrl() + "/settings";
};
