import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SingleDatePicker from "../../../../SingleDatePicker";
import dayjs from "dayjs";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function DateComponent({ field, inputRef, ...rest }) {
	const { control, clearErrors } = useFormContext();

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const handleDateChange = (event, onChange) => {
		let selectedDate = event.valueOf() ? event.valueOf() : "";
		let modifiedEvent = {
			...event,
			target: {
				...event.target,
				value: selectedDate,
			},
		};

		onChange(modifiedEvent);
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, value, ...renderField },
					fieldState: { error },
				}) => (
					<SingleDatePicker
						placeholder={field?.placeHolder}
						{...renderField}
						{...rest}
						id={field.id + ""}
						onChange={(event) => handleDateChange(event, onChange)}
						value={getParsedValue(value)}
						inputRef={inputRef}
						error={error}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
