import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
import { getIconByName } from "../../../../../utils/icon";
import { getMonthDateAndTimeFormat } from "../../../../../utils/DateUtils";
import Note from "../Fields/Note";

export default function Activity(props) {
	const { matchedActivities, highlightText, details, displayLinkedModule } =
		props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				width="100%"
			>
				<Stack
					direction="row"
					alignItems="center"
					spacing={3}
					width="80%"
				>
					<Box alignItems="start">
						<IconButton
							style={{
								padding: 0,
							}}
						>
							{getIconByName(
								matchedActivities.activityType.name.toLowerCase()
							)(20, 20, "rgba(0, 0, 0, 0.6)")}
						</IconButton>
					</Box>

					<Box width="85%">
						<Typography fontSize={14} noWrap>
							{highlightText(matchedActivities.name)}
						</Typography>

						<Typography
							style={{
								...styles.subText,
								color:
									matchedActivities.isOverdue &&
									theme.palette.error.main,
							}}
						>
							{getMonthDateAndTimeFormat(details.endTime)}
						</Typography>

						<Typography style={styles.subText} noWrap>
							{matchedActivities.notes && (
								<Note
									highlightText={highlightText}
									notes={matchedActivities.notes}
								/>
							)}
						</Typography>
					</Box>
				</Stack>

				<Box>{displayLinkedModule()}</Box>
			</Stack>
		</React.Fragment>
	);
}
