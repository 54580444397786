import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import FilterBaseComponent from "../FilterBaseComponent";

export default function TriggerFilter({
	styles,
	criteria,
	handleUpdateFilter,
}) {
	const triggerList = getDropdownDataByName("WORKFLOW_TRIGGER_LIST");

	return (
		<>
			<FilterBaseComponent
				styles={styles}
				criteria={criteria}
				filterName="Triggers"
				handleUpdateFilter={handleUpdateFilter}
				filterValuesList={triggerList}
			/>
		</>
	);
}
