import React, { useEffect, useState } from "react";
import {
	Stack,
	Typography,
	TextField,
	Divider,
	Box,
	MenuItem,
	ToggleButton,
	Button,
	IconButton,
	useTheme,
	alpha,
} from "@mui/material";
import { default as closeIcon } from "../../../../../assets/icons/close";
import Menu from "../../../Menu";
import ToggleButtonGroup from "../../../ToggleButtonGroup";
import { removeFieldsWithEmptyValues } from "../../../../../utils/common";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import FilterFields from "./filterFields";
import SaveViewAsDialog from "../SaveViewAsDialog";
import {
	useComparator,
	useFilterData,
	useFilterFieldList,
	useSourceList,
} from "../../../../../hooks/services/common/table/filter";
import { getFilterFieldListData } from "../../../../../api/filter/filterApi";
import {
	filterConnectorsList,
	filterFieldNames,
} from "../../../../../utils/FilterUtills";
import { useFilterSaveViewContext } from "../Context/FilterSaveViewContext";
import { useFilterDataContext } from "../Context/FilterDataContext";

export default function SwipeableFilter(props) {
	const { moduleName, hasShareViewPermissions } = props;
	const theme = useTheme();

	const {
		filterState,
		filterListByCondition,
		removeAppliedFilter,
		deactivateEditMode,
		updateFilterCount,
		updateFilterCondition,
		activateEditMode,
		closeSwipeableFilter,
		updateActiveFilterDetails,
		updateActiveFilterConditions,
	} = useFilterDataContext();

	const {
		saveViewState,
		showSaveViewAsButton,
		hideSaveViewAsButton,
		handleCloseSaveViewAsDialog,
	} = useFilterSaveViewContext();

	//Filter SourceList
	const { data: filterSourceList } = useSourceList();
	const sourceId =
		filterSourceList?.length >= 1
			? filterSourceList?.find((module) => module.name === moduleName)?.id
			: null;

	//filterConnector
	const filterConnectors = getDropdownDataByName("CONNECTOR_LIST");
	const [connector, setConnector] = useState(filterConnectors[0].name);

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			setConnector(type);
		}
	};

	//Source dropdown
	const [filterSourceListMenuElement, setFilterSourceListMenuElement] =
		useState(null);
	const isOpenSourceMenu = Boolean(filterSourceListMenuElement);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const [isFilterModuleSelected, setIsFilterModuleSelected] = useState(false);
	const [selectedSource, setSelectedSource] = useState({}); //pass the source id to get fieldList

	const openSourceMenu = (event) => {
		setFilterSourceListMenuElement(event.currentTarget);
	};

	const closeSourceMenu = () => {
		setFilterSourceListMenuElement(null);
	};

	const updateSelectedIndex = (index) => {
		setSelectedIndex(index);
	};

	const updateSelectedSource = (source) => {
		setSelectedSource(source);
	};

	const handleSelectSource = (data) => {
		updateFilterCondition([
			...filterState.filterData,
			{
				sourceId: {
					fieldName: filterFieldNames.sourceId,
					value: {
						id: data.id,
						name: data.alternateName,
					},
				},
				connectorId: {
					fieldName: filterFieldNames.connectorId,
					value: {
						id: connector === filterConnectorsList.AND ? 1 : 2,
						name:
							connector === filterConnectorsList.AND
								? filterConnectorsList.AND
								: filterConnectorsList.OR,
					},
				},
				comparatorId: {
					fieldName: filterFieldNames.comparatorId,
					value: {
						id: "",
						name: "",
					},
				},
				fieldId: {
					fieldName: filterFieldNames.fieldId,
					value: {
						id: "",
						name: "",
					},
				},
				optionId: {
					fieldName: filterFieldNames.optionId,
					value: {
						id: "",
						name: "",
					},
				},
				additionalOption: {
					fieldName: filterFieldNames.additionalOption,
					displayName: "",
					value: {
						name: "",
						entityId: "",
					},
				},
				valueEntityId: {
					fieldName: filterFieldNames.valueEntityId,
					value: "",
				},
				value: {
					fieldName: filterFieldNames.value,
					value: "",
				},
				fieldData: {}, // to handle the UI structure
				additionalOptions: {}, // to handle the case which have both value dropdown and additional options
				selectedCurrency: {}, // to display currency code in UI
			},
		]);

		setIsFilterModuleSelected(true);
		setFilterSourceListMenuElement(null);
		setSelectedSource(data);
	};

	//Filter FieldList
	const [selectedField, setSelectedField] = useState({});

	const updateSelectedFieldDetails = (fieldDetails) => {
		setSelectedField(fieldDetails);
	};

	const { data: fieldList } = useFilterFieldList(selectedSource.id);

	//Comparator
	const { data: comparator } = useComparator(selectedField?.fieldType?.id);

	let comparatorDefaultOption = comparator?.find(
		(comparatorOption) => comparatorOption.isDefault
	);

	useEffect(() => {
		if (comparatorDefaultOption) {
			const selectedFieldData = {
				...filterState.filterData[selectedIndex],
				comparatorId: {
					...filterState.filterData[selectedIndex].comparatorId,
					value: {
						...filterState.filterData[selectedIndex].comparatorId
							.value,
						id: comparatorDefaultOption?.id,
						name: comparatorDefaultOption?.name,
					},
				},
			};

			const updatedFilterData = [...filterState.filterData];
			updatedFilterData[selectedIndex] = selectedFieldData;
			updateFilterCondition(updatedFilterData);
		}
	}, [comparatorDefaultOption, selectedField]);

	const getApplyFilterRequestData = () => {
		let requestData = {};
		requestData.criteriaList = [];

		for (let index = 0; index < filterState.filterData.length; index++) {
			const requestCriteriaListData = { field: {} };
			const inputValue = {};

			for (const filterDataElementKey in filterState.filterData[index]) {
				if (
					Object.hasOwnProperty.call(
						filterState.filterData[index],
						filterDataElementKey
					)
				) {
					const formElement =
						filterState.filterData[index][filterDataElementKey];
					const fieldName = formElement.fieldName;

					if (fieldName === filterFieldNames.valueEntityId) {
						inputValue[fieldName] = formElement.value;
					}

					if (fieldName === filterFieldNames.value) {
						inputValue[fieldName] = formElement.value;
						inputValue.id =
							formElement.id && formElement.value
								? formElement.id
								: "";
					}

					if (
						(fieldName === filterFieldNames.fieldId &&
							formElement.value.id !== "") ||
						fieldName === filterFieldNames.optionId
					) {
						requestCriteriaListData.field[fieldName] =
							formElement.value.id;
					}

					if (
						fieldName === filterFieldNames.additionalOption &&
						formElement.value.name !== ""
					) {
						requestCriteriaListData.field[fieldName] =
							formElement.value;
					}

					if (fieldName === filterFieldNames.sourceId) {
						requestCriteriaListData[fieldName] =
							formElement.value.id;
						requestCriteriaListData.id = formElement.id
							? formElement.id
							: "";
					}

					if (
						fieldName === filterFieldNames.comparatorId ||
						fieldName === filterFieldNames.connectorId
					) {
						requestCriteriaListData[fieldName] =
							formElement.value.id;
					}
				}
			}

			removeFieldsWithEmptyValues(inputValue);
			if (Object.keys(inputValue).length !== 0) {
				requestCriteriaListData.values = [inputValue];
			}

			removeFieldsWithEmptyValues(requestCriteriaListData.field);
			removeFieldsWithEmptyValues(requestCriteriaListData);
			requestData.criteriaList.push(requestCriteriaListData);
			removeFieldsWithEmptyValues(requestData);
		}

		return requestData;
	};

	const applyFilter = () => {
		updateFilterCount(filterState.filterData.length);
		let requestData = getApplyFilterRequestData();
		filterListByCondition({ criteria: requestData.criteriaList });
		showSaveViewAsButton();
	};

	//Get filter by Id
	const { status: applyFilterFetchStatus, data: applyFilterApiData } =
		useFilterData(filterState.appliedFilterId);

	useEffect(() => {
		if (applyFilterFetchStatus === "success") {
			if (applyFilterApiData) {
				updateActiveFilterDetails(applyFilterApiData.filter);
				updateActiveFilterConditions(
					applyFilterApiData.filter.criteriaList
				);
			}
		}
	}, [applyFilterFetchStatus, applyFilterApiData]);

	const [updatedFilterData, setUpdatedFilterData] = useState([]);

	useEffect(() => {
		const getFieldDetails = async () => {
			try {
				const filterData = [];

				for (const data of filterState.activeFilterCondition) {
					const fieldList = await getFilterFieldListData(
						data.source.id
					);
					const fieldData = fieldList.options.find(
						(field) => field.name === data.field.name
					);

					if (fieldData) {
						filterData.push({ ...data, fieldDetails: fieldData });
					} else {
						filterData.push(data);
					}
				}

				setUpdatedFilterData(filterData);
			} catch (error) {
				console.error(error);
			}
		};

		getFieldDetails();
	}, [filterState.activeFilterCondition]);

	useEffect(() => {
		if (
			updatedFilterData.length >= 1 &&
			updatedFilterData?.[0].fieldDetails
		) {
			const activeFilterData = updatedFilterData.map((data) => ({
				sourceId: {
					fieldName: filterFieldNames.sourceId,
					id: data.id ? data.id : "",
					value: {
						id: data?.source.id,
						name: data?.source.name,
					},
				},
				connectorId: {
					fieldName: filterFieldNames.connectorId,
					value: {
						id: data?.connector.id,
						name: data?.connector.name,
					},
				},
				comparatorId: {
					fieldName: filterFieldNames.comparatorId,
					value: {
						id: data?.comparator.id,
						name: data?.comparator.name,
					},
				},
				fieldId: {
					fieldName: filterFieldNames.fieldId,
					value: {
						id: data?.field.fieldId ? data?.field.fieldId : "",
						name: data?.field.fieldId ? data?.field.name : "",
					},
				},
				optionId: {
					fieldName: filterFieldNames.optionId,
					value: {
						id: data?.field.optionId ? data?.field.optionId : "",
						name: data?.field.optionId ? data?.field.name : "",
					},
				},
				additionalOption: {
					fieldName: filterFieldNames.additionalOption,
					displayName: data?.field?.additionalOption
						? data?.field?.additionalOption.displayName
						: "",
					value: {
						name: data?.field?.additionalOption
							? data?.field?.additionalOption.name
							: "",
						entityId: data?.field?.additionalOption
							? data?.field?.additionalOption.entityId
							: "",
						id: data?.field?.additionalOption
							? data?.field?.additionalOption.id
							: "",
					},
				},
				valueEntityId: {
					fieldName: filterFieldNames.valueEntityId,
					value: data.values
						? data?.values[0]?.valueEntityId
							? data?.values[0]?.valueEntityId
							: ""
						: "",
				},
				value: {
					fieldName: filterFieldNames.value,
					id: data.values ? data?.values[0]?.id : "",
					value: data.values ? data?.values[0]?.value : "",
				},
				fieldData: data.fieldDetails,
				additionalOptions: {},
				selectedCurrency: {},
			}));
			updateFilterCondition(activeFilterData);
			updateFilterCount(updatedFilterData.length);
		}
	}, [updatedFilterData]);

	useEffect(() => {
		if (
			filterState.activeFilterCondition.length >= 1 &&
			filterState.activeFilterCondition[selectedIndex]
		) {
			if (
				(filterState.filterData[selectedIndex]?.fieldId?.value.name ||
					filterState.filterData[selectedIndex]?.optionId?.value
						.name) ===
					filterState.activeFilterCondition[selectedIndex]?.field
						.name &&
				filterState.filterData[selectedIndex]?.comparatorId.value
					.name ===
					filterState.activeFilterCondition[selectedIndex]?.comparator
						.name &&
				(filterState.activeFilterCondition[selectedIndex]?.values
					? filterState.filterData[selectedIndex]?.value?.value ===
						filterState.activeFilterCondition[selectedIndex]
							?.values?.[0]?.value
					: "")
			) {
				if (
					filterState.activeFilterCondition[selectedIndex]?.field
						?.additionalOption
				) {
					if (
						filterState.filterData[selectedIndex]?.additionalOption
							.displayName ===
						filterState.activeFilterCondition[selectedIndex]?.field
							?.additionalOption.displayName
					) {
						deactivateEditMode();
					}
				} else {
					deactivateEditMode();
				}
			}
		}
	}, [
		filterState.filterData,
		selectedIndex,
		filterState.activeFilterCondition,
	]);

	//clear condition
	const clearFilterCondition = () => {
		hideSaveViewAsButton();
		removeAppliedFilter();
	};

	return (
		<React.Fragment>
			<SaveViewAsDialog
				open={saveViewState.isSaveViewAsDialogOpened}
				onClose={handleCloseSaveViewAsDialog}
				hideSaveViewAsButton={hideSaveViewAsButton}
				sourceId={sourceId}
				hasShareViewPermissions={hasShareViewPermissions}
			/>

			<Menu
				minWidth="250px"
				anchorEl={filterSourceListMenuElement}
				open={isOpenSourceMenu}
				onClose={closeSourceMenu}
				style={{ transform: "translateX(-10px)" }}
			>
				{filterSourceList?.map((data) => (
					<MenuItem
						key={data.id}
						style={{ height: "40px" }}
						onClick={() => handleSelectSource(data)}
					>
						<Typography fontSize={13}>
							{data.alternateName}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Box
				style={{
					height: "100%",
					width: "250px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box pt={1.5} pb={1} px={1}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-around"
					>
						<IconButton
							style={{ cursor: "pointer" }}
							onClick={closeSwipeableFilter}
						>
							{closeIcon(20, 20, theme.palette.primary.main)}
						</IconButton>

						<Stack>
							<Typography
								fontSize={14}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								Filter{" "}
								{filterState.filterData.length >= 1
									? `(${filterState.filterData.length})`
									: ""}
							</Typography>
							<TextField
								onClick={openSourceMenu}
								variant="standard"
								placeholder="Select Column..."
								inputProps={{
									readOnly: true,
									style: {
										fontSize: "14px",
									},
								}}
								InputProps={{ disableUnderline: true }}
							/>
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
					}}
				>
					{(isFilterModuleSelected ||
						filterState.activeFilterCondition.length >= 1) && (
						<Stack>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-around"
								p={1}
							>
								{filterState.filterData.length >= 1 && (
									<ToggleButtonGroup
										value={connector}
										onChange={handleViewToggleButtonGroup}
									>
										{filterConnectors.map((data) => (
											<ToggleButton
												key={data.value}
												value={data.name}
											>
												{data.name}
											</ToggleButton>
										))}
									</ToggleButtonGroup>
								)}
							</Stack>

							<Stack alignItems="center">
								{connector === "AND" || connector === "OR" ? (
									<FilterFields
										connector={connector}
										fieldList={fieldList}
										filterData={filterState.filterData}
										updateFilterCondition={
											updateFilterCondition
										}
										selectedField={selectedField}
										updateSelectedFieldDetails={
											updateSelectedFieldDetails
										}
										comparator={comparator}
										selectedIndex={selectedIndex}
										updateSelectedSource={
											updateSelectedSource
										}
										updateSelectedIndex={
											updateSelectedIndex
										}
										activateEditMode={activateEditMode}
									/>
								) : null}
							</Stack>
						</Stack>
					)}
				</Box>

				<Divider />

				<Box
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						backgroundColor: "#FFF",
						padding: "15px 0px",
					}}
				>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						disabled={filterState.filterData.length === 0}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: theme.palette.secondary.main,
								color: alpha(
									theme.palette.secondary.contrastText,
									0.6
								),
							},
						}}
						onClick={clearFilterCondition}
					>
						Clear All
					</Button>

					<Button
						variant="contained"
						disableElevation
						disabled={
							!filterState.isEdited ||
							filterState.filterData.length === 0
						}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: "rgba(51, 188, 126)",
								color: alpha("#FFFFFF", 0.6),
							},
							cursor: !filterState.isEdited
								? "not-allowed"
								: "pointer",
						}}
						onClick={applyFilter}
					>
						Apply
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
