import { styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { default as DropDownRight } from "../../../assets/icons/dropDownRight";
import { useTheme } from "@mui/material";

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={DropDownRight(18, 18, useTheme().palette.primary.main)}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));
