import { Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import Menu from "../../../../Menu";
import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import { MultiplePhoneFieldForm } from "../../../../FieldEditFormComponents/MultiplePhoneFieldForm";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";
import { SummaryFieldName } from "../../../../../../styles/twozo";

export default function MultiplePhoneComponent(props) {
	const { summaryField, subFields } = props;

	// Summary Context:-
	const { updateSummaryField, entityId, hasEditPermission, isSaving } =
		useSummaryContext();

	const theme = useTheme();
	const [phoneMenuElement, setPhoneMenuElement] = useState(null);
	const isPhoneMenuOpen = Boolean(phoneMenuElement);

	const phoneNumber = "PHONE_NUMBER";
	const phoneTypeOptions = getDropdownDataByName("PHONE_TYPE");

	const openPhoneMenu = (event) => {
		if (!hasEditPermission) {
			return;
		}
		setPhoneMenuElement(event.currentTarget);
	};

	const closePhoneMenu = () => {
		setPhoneMenuElement(null);
	};

	const getPhoneFieldLabel = () => {
		let primaryPhoneNumber = summaryField?.value?.find(
			(row) => row.isPrimary
		)?.[phoneNumber]?.value;
		if (primaryPhoneNumber) {
			return (
				<Typography
					onClick={openPhoneMenu}
					fontSize={14}
					fontWeight={500}
				>
					{primaryPhoneNumber}{" "}
					{summaryField?.value?.length > 1 && (
						<span
							style={{
								color: theme.palette.primary.main,
								fontSize: 14,
							}}
						>
							+{summaryField?.value?.length - 1}
						</span>
					)}
				</Typography>
			);
		}
		return summaryField.config.readOnly ? (
			<SummaryFieldName style={{ opacity: 0.6 }}>-</SummaryFieldName>
		) : (
			<Typography
				width="150px"
				sx={{
					"&:hover": {
						borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
					},
				}}
				fontSize="14px"
				fontWeight={500}
				style={{
					color: theme.palette.secondary.contrastText,
					opacity: "0.4",
				}}
				onClick={openPhoneMenu}
			>
				Click to Add
			</Typography>
		);
	};

	const modifiedPhoneStructure = useMemo(() => {
		const { fieldId, value } = summaryField;

		if (!value) {
			return { id: entityId };
		}
		const convertedData = value?.map((item) => {
			const { PHONE_NUMBER, PHONE_TYPE, isPrimary = false, id } = item;

			let defaultPhoneType = phoneTypeOptions.find((phoneTypeOption) => {
				return PHONE_TYPE?.value === phoneTypeOption?.value;
			});

			return {
				[PHONE_NUMBER.id]: PHONE_NUMBER.value,
				[PHONE_TYPE.id]: defaultPhoneType?.value,
				isPrimary: isPrimary,
				id: id,
			};
		});

		return {
			[fieldId]: convertedData,
			id: entityId,
		};
	}, [summaryField]);

	const handleSave = (phonesData) => {
		updateSummaryField(phonesData, closePhoneMenu);
	};

	return (
		<React.Fragment>
			{getPhoneFieldLabel()}

			<Menu
				open={isPhoneMenuOpen}
				anchorEl={phoneMenuElement}
				onClose={closePhoneMenu}
				sx={{ marginTop: "3px", minWidth: "400px" }}
			>
				<MultiplePhoneFieldForm
					subFields={subFields}
					onClose={closePhoneMenu}
					onSave={handleSave}
					field={summaryField}
					isSaving={isSaving}
					value={modifiedPhoneStructure}
				/>
			</Menu>
		</React.Fragment>
	);
}
