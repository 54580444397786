import { useInfiniteQuery } from "@tanstack/react-query";
import {
	getCompanyCompletedActivityKey,
	getCompanyToDoAcitivityKey,
} from "../../../../utils/queryKeys";
import { getActivitiesData } from "../../../../api/activity/activityApi";
import { modules } from "../../../../utils/common/ModulesName";
import {
	completedActivityListPageSize,
	todoActivityListPageSize,
} from "../../../../utils/queryConstants/summary/activity";

let companySource = modules.COMPANY;

const activitiesSelect = (todoActivityData) => {
	return todoActivityData.pages.flatMap((page) => page?.activities);
};

const useCompanyToDoActivities = (companyId, activityTypeIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getCompanyToDoAcitivityKey(
			companyId,
			activityTypeIdsToFilter
		),
		queryFn: ({ pageParam }) => {
			return getActivitiesData(
				companyId,
				false,
				companySource,
				activityTypeIdsToFilter,
				{
					start: pageParam
						? (pageParam - 1) * todoActivityListPageSize + 1
						: 1,
					limit: todoActivityListPageSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

const useCompanyCompletedActivities = (companyId, activityTypeIdsToFilter) => {
	return useInfiniteQuery({
		queryKey: getCompanyCompletedActivityKey(
			companyId,
			activityTypeIdsToFilter
		),
		queryFn: ({ pageParam }) => {
			return getActivitiesData(
				companyId,
				true,
				companySource,
				activityTypeIdsToFilter,
				{
					start: pageParam
						? (pageParam - 1) * completedActivityListPageSize + 1
						: 1,
					limit: completedActivityListPageSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: activitiesSelect,
	});
};

export { useCompanyToDoActivities, useCompanyCompletedActivities };
