import React from "react";

export default function Forecast(width = 16, height = 16, color = "white") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 16 16"
			style={{ fill: "none" }}
		>
			<path
				d="M12.0608 11.3333C13.3275 11.3333 13.9941 10.6666 13.9941 9.39992V1.33325H1.99414V9.39992C2.00081 10.6666 2.66748 11.3333 3.93414 11.3333H12.0608Z"
				stroke={color}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.33398 1.33325H14.6673"
				stroke={color}
				strokeWidth="0.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.33398 14.6666L8.00065 13.3333V11.3333"
				stroke={color}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6667 14.6666L8 13.3333"
				stroke={color}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 7.33325L7.1 5.57992C7.26666 5.43992 7.48666 5.47992 7.6 5.66659L8.4 6.99992C8.51334 7.18659 8.73334 7.21992 8.9 7.08659L11 5.33325"
				stroke={color}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
