import React, { useEffect, useMemo, useState } from "react";
import {
	alpha,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";
import { default as CopyIcon } from "../../../../../assets/icons/copy";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { Tooltip } from "../../../../../styles/twozo";
import EmailSignature from "./EmailSignature";
import {
	useEmailSettingsData,
	useUpdateEmailSettingsData,
} from "../../../../../hooks/services/mail/emailSettings";
import { enqueueSnackbar } from "notistack";
import { useFieldDropdown } from "../../../../../hooks/services/dataFields";
import { domainPattern, emailPattern } from "../../../../../utils/validation";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { removeFieldsWithEmptyValues } from "../../../../../utils/common";
import { PERMISSIONS } from "../../../../../utils/Auth";
import Can from "../../../../Auth/Can";

export default function EmailSettings() {
	const theme = useTheme();

	const [emailSettingsData, setEmailSettingsData] = useState({});
	const [blockedAddressInputValue, setBlockedAddressInputValue] =
		useState("");
	const [emailSignatureContent, setEmailSignatureContent] = useState("");
	const [isBlockedAddressInputError, setIsBlockedAddressInputError] =
		useState(false);
	const [isCopiedTooltipOpened, setIsCopiedTooltipOpened] = useState(false);

	const {
		data: emailSettingsApiData,
		isLoading: isEmailSettingsDataLoading,
	} = useEmailSettingsData();
	const updateEmailSettingsMutation = useUpdateEmailSettingsData();

	const lifeCycleStageFieldId =
		emailSettingsApiData?.emailLinkerSettings?.lifeCycleStage?.fieldId;
	let isLifeCycleStageFieldEnabled = Boolean(lifeCycleStageFieldId);

	const { data: lifeCycleStageList } = useFieldDropdown(
		lifeCycleStageFieldId,
		isLifeCycleStageFieldEnabled
	);

	const emailSettingsConfig = {
		emailLinkerSettings: {
			settingName: "emailLinkerSettings",
			settingTypes: {
				isContactCreationEnabled: "isContactCreationEnabled",
				isContactLinkingEnabled: "isContactLinkingEnabled",
				isDealLinkingEnabled: "isDealLinkingEnabled",
			},
		},
		emailSignature: {
			settingName: "emailSignature",
		},
		emailTrackerSettings: {
			settingName: "emailTrackerSettings",
			settingTypes: {
				isEmailTrackerEnabled: "isEmailTrackerEnabled",
				isLinkTrackerEnabled: "isLinkTrackerEnabled",
			},
		},
	};

	useEffect(() => {
		if (emailSettingsApiData) {
			const formatedEmailSettingsConfig = {
				...emailSettingsApiData,
				[emailSettingsConfig.emailLinkerSettings.settingName]: {
					...emailSettingsApiData[
						emailSettingsConfig.emailLinkerSettings.settingName
					],
					lifecycleStageId:
						emailSettingsApiData[
							emailSettingsConfig.emailLinkerSettings.settingName
						]?.lifeCycleStage?.id,
				},
			};
			delete formatedEmailSettingsConfig[
				emailSettingsConfig.emailLinkerSettings.settingName
			]?.lifeCycleStage;

			setEmailSettingsData(formatedEmailSettingsConfig);
		}
	}, [emailSettingsApiData]);

	const lifeCycleStageId =
		emailSettingsData?.emailLinkerSettings?.lifecycleStageId;

	const lifeCycleStageName = useMemo(() => {
		if (!lifeCycleStageId) {
			return "";
		}
		const selectedLifeCycleStage = lifeCycleStageList?.find(
			(lifeCycleStage) => lifeCycleStage.value === lifeCycleStageId
		);
		return selectedLifeCycleStage ? selectedLifeCycleStage.name : "";
	}, [lifeCycleStageId, lifeCycleStageList]);

	const handleEmailSettingsConfigChange = (
		event,
		settingName,
		settingType
	) => {
		const { checked } = event.target;

		setEmailSettingsData((emailSettingsData) => {
			return {
				...emailSettingsData,
				[settingName]: {
					...emailSettingsData[settingName],
					[settingType]: checked,
				},
			};
		});
	};

	const handleLifeCycleStageChange = (event) => {
		const { value } = event.target;
		setEmailSettingsData((emailSettingsData) => {
			return {
				...emailSettingsData,
				[emailSettingsConfig.emailLinkerSettings.settingName]: {
					...emailSettingsData[
						emailSettingsConfig.emailLinkerSettings.settingName
					],
					lifecycleStageId: value,
				},
			};
		});
	};

	const blockedAddressInputChange = (event) => {
		const { value } = event.target;
		setBlockedAddressInputValue(value.trimStart());
		setIsBlockedAddressInputError(false);
	};

	const isValidEmailAddress = () => {
		return (
			emailPattern.test(blockedAddressInputValue) ||
			domainPattern.test(blockedAddressInputValue)
		);
	};

	const handleAddBlockedAddress = () => {
		if (isValidEmailAddress()) {
			let blockedEmailAddress = emailSettingsData?.blockedAddress || [];
			setEmailSettingsData((emailSettingsData) => {
				return {
					...emailSettingsData,
					blockedAddress: [
						...blockedEmailAddress,
						blockedAddressInputValue,
					],
				};
			});
			setBlockedAddressInputValue("");
		} else {
			setIsBlockedAddressInputError(true);
		}
	};

	const handleRemoveBlockedAddress = (removedIndex) => {
		let removedEmailAddress = emailSettingsData?.blockedAddress?.filter(
			(_, index) => removedIndex !== index
		);
		setEmailSettingsData((emailSettingsData) => {
			return {
				...emailSettingsData,
				blockedAddress: removedEmailAddress,
			};
		});
	};

	const handleCopyEmailAddress = () => {
		window.navigator.clipboard.writeText(
			emailSettingsData?.forwardToAddress
		);
		setIsCopiedTooltipOpened(true);
	};

	const emailSignatureInputChange = (signatureContent) => {
		setEmailSignatureContent(signatureContent);
	};

	const onSaveEmailSettingsConfig = () => {
		const updatedSettingsData = {
			...emailSettingsData,
			emailSignature: emailSignatureContent,
		};
		removeFieldsWithEmptyValues(updatedSettingsData);
		updateEmailSettingsMutation.mutate(updatedSettingsData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.updateEmailSettings,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{isEmailSettingsDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
				>
					<CircularProgress />
				</Stack>
			) : (
				<Stack
					style={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
					spacing={3}
					m={1}
					mt={0}
					px={2}
					py={3}
				>
					<Stack spacing={1.5}>
						<Typography fontWeight={600}>Email Tracking</Typography>

						<FormControlLabel
							control={
								<Checkbox
									style={{ padding: "0px" }}
									checked={
										!!emailSettingsData
											?.emailTrackerSettings
											?.isEmailTrackerEnabled
									}
								/>
							}
							label={
								<Typography
									width="fit-content"
									fontSize={16}
									fontWeight={400}
									pl={2}
								>
									Track Opens
								</Typography>
							}
							style={{
								width: "fit-content",
								marginTop: "16px",
							}}
							onChange={(event) =>
								handleEmailSettingsConfigChange(
									event,
									emailSettingsConfig.emailTrackerSettings
										.settingName,
									emailSettingsConfig.emailTrackerSettings
										.settingTypes.isEmailTrackerEnabled
								)
							}
						/>

						<FormControlLabel
							control={
								<Checkbox
									style={{ padding: "0px" }}
									checked={
										!!emailSettingsData
											?.emailTrackerSettings
											?.isLinkTrackerEnabled
									}
								/>
							}
							label={
								<Typography
									width="fit-content"
									fontSize={16}
									fontWeight={400}
									pl={2}
								>
									Track Clicks
								</Typography>
							}
							style={{
								width: "fit-content",
							}}
							onChange={(event) =>
								handleEmailSettingsConfigChange(
									event,
									emailSettingsConfig.emailTrackerSettings
										.settingName,
									emailSettingsConfig.emailTrackerSettings
										.settingTypes.isLinkTrackerEnabled
								)
							}
						/>
					</Stack>

					<Divider />

					<Stack spacing={1.5}>
						<Typography fontWeight={600}>Email Linking</Typography>

						<Typography fontSize={13} fontWeight={500} pt={0.5}>
							Link Emails to
						</Typography>

						<Can permission={PERMISSIONS.deal.view}>
							<FormControlLabel
								control={
									<Checkbox
										style={{ padding: "0px" }}
										checked={
											!!emailSettingsData
												?.emailLinkerSettings
												?.isDealLinkingEnabled
										}
									/>
								}
								label={
									<Typography
										width="fit-content"
										fontSize={16}
										fontWeight={400}
										pl={2}
									>
										Deals
									</Typography>
								}
								style={{
									width: "fit-content",
								}}
								onChange={(event) =>
									handleEmailSettingsConfigChange(
										event,
										emailSettingsConfig.emailLinkerSettings
											.settingName,
										emailSettingsConfig.emailLinkerSettings
											.settingTypes.isDealLinkingEnabled
									)
								}
							/>
						</Can>

						<Can permission={PERMISSIONS.contact.view}>
							<FormControlLabel
								control={
									<Checkbox
										style={{ padding: "0px" }}
										checked={
											!!emailSettingsData
												?.emailLinkerSettings
												?.isContactLinkingEnabled
										}
									/>
								}
								label={
									<Typography
										width="fit-content"
										fontSize={16}
										fontWeight={400}
										pl={2}
									>
										Contacts
									</Typography>
								}
								style={{
									width: "fit-content",
								}}
								onChange={(event) =>
									handleEmailSettingsConfigChange(
										event,
										emailSettingsConfig.emailLinkerSettings
											.settingName,
										emailSettingsConfig.emailLinkerSettings
											.settingTypes
											.isContactLinkingEnabled
									)
								}
							/>
						</Can>

						<Can permission={PERMISSIONS.contact.create}>
							<Stack direction="row" spacing={2}>
								<Checkbox
									style={{ padding: "0px" }}
									checked={
										!!emailSettingsData?.emailLinkerSettings
											?.isContactCreationEnabled
									}
									onChange={(event) =>
										handleEmailSettingsConfigChange(
											event,
											emailSettingsConfig
												.emailLinkerSettings
												.settingName,
											emailSettingsConfig
												.emailLinkerSettings
												.settingTypes
												.isContactCreationEnabled
										)
									}
								/>

								<Stack direction="row" spacing={1}>
									<Typography>
										Auto create contact with stage
									</Typography>

									<Box display="flex">
										<Select
											size="small"
											variant="standard"
											onChange={
												handleLifeCycleStageChange
											}
											value={
												emailSettingsData
													?.emailLinkerSettings
													?.lifecycleStageId || ""
											}
											renderValue={() => (
												<Typography
													fontSize={16}
													fontWeight={500}
													color={
														theme.palette.primary
													}
												>
													{lifeCycleStageName}
												</Typography>
											)}
											IconComponent={(props) => (
												<Box {...props}>
													{DropdownIcon(
														16,
														16,
														theme.palette.primary
															.main
													)}
												</Box>
											)}
											sx={{
												height: "25px",
												pt: "5px",
												color: theme.palette.primary
													.main,
												"& .MuiSelect-icon": {
													right: "4px",
													opacity: 1,
													top: "5px",
												},
												"& .MuiSelect-iconOpen": {
													top: "0px",
												},
											}}
											disableUnderline
											displayEmpty
										>
											{lifeCycleStageList?.map(
												(lifeCycleStageOption) => (
													<MenuItem
														key={
															lifeCycleStageOption?.value
														}
														value={
															lifeCycleStageOption?.value
														}
														style={{
															height: "40px",
														}}
													>
														<Typography
															fontSize={14}
														>
															{
																lifeCycleStageOption?.name
															}
														</Typography>
													</MenuItem>
												)
											)}
										</Select>
									</Box>

									<Typography>from emails</Typography>
								</Stack>
							</Stack>
						</Can>
					</Stack>

					<Divider />

					<Stack spacing={2}>
						<Typography fontWeight={600}>
							Forward your emails
						</Typography>

						<Stack spacing={1.5}>
							<Typography color="rgba(0, 0, 0, 0.6)">
								Copy and paste the address to the cc or bcc when
								sending emails or forward emails to this address
								to automatically sync them.
							</Typography>

							<Stack direction="row" width="570px">
								<TextField
									value={
										emailSettingsData?.forwardToAddress ||
										""
									}
									sx={{
										width: "90%",
										"& .MuiOutlinedInput-notchedOutline": {
											borderRadius: "8px 0px 0px 8px",
										},
									}}
									InputProps={{
										readOnly: true,
									}}
								/>

								<Tooltip
									open={isCopiedTooltipOpened}
									title="Copied"
									placement="top"
								>
									<Button
										style={{
											border: "1px solid rgba(0, 0, 0, 0.1)",
											borderRadius: "0px 8px 8px 0px",
											height: "42px",
											width: "10%",
											marginLeft: "-1px",
										}}
										variant="contained"
										color="secondary"
										disableElevation
										onMouseOut={() =>
											setIsCopiedTooltipOpened(false)
										}
										onClick={handleCopyEmailAddress}
									>
										{CopyIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Button>
								</Tooltip>
							</Stack>
						</Stack>
					</Stack>

					<Divider />

					<Stack spacing={2}>
						<Typography fontWeight={600}>
							Email Signature
						</Typography>

						<Box width="570px">
							<EmailSignature
								emailSignatureInputChange={
									emailSignatureInputChange
								}
								initialSignatureContent={
									emailSettingsData?.emailSignature
								}
							/>
						</Box>
					</Stack>

					<Divider />

					<Stack spacing={2}>
						<Typography fontWeight={600}>
							Blocked Addresses
						</Typography>

						<Stack spacing={1.5}>
							<Typography>
								Add email addresses that you don’t want to
								receive emails from. You can also block all
								addresses with a certain domain by using the
								following format:
								<span style={{ fontWeight: 500 }}>
									{" "}
									*@example.com
								</span>
							</Typography>

							<Stack direction="row" spacing={1.5}>
								<TextField
									placeholder="Enter email address"
									sx={{
										width: "530px",
										borderRadius: "8px",
									}}
									value={blockedAddressInputValue}
									onChange={blockedAddressInputChange}
									error={isBlockedAddressInputError}
									helperText={
										isBlockedAddressInputError
											? "Invalid email address are not allowed"
											: ""
									}
									FormHelperTextProps={{
										sx: {
											fontSize: 13,
											ml: "6px",
										},
									}}
								/>

								<Button
									variant="contained"
									disableElevation
									onClick={handleAddBlockedAddress}
									disabled={!blockedAddressInputValue}
									style={{
										marginTop: "3px",
									}}
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												"rgba(51, 188, 126)",
											color: alpha("#FFFFFF", 0.6),
										},
									}}
								>
									Add
								</Button>
							</Stack>
						</Stack>

						{emailSettingsData?.blockedAddress?.length > 0 ? (
							<Stack direction="row" spacing={1} flexWrap="wrap">
								{emailSettingsData.blockedAddress.map(
									(emailAddress, index) => (
										<Box
											display="flex"
											pb={0.5}
											key={index}
										>
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="center"
												spacing={0.5}
												bgcolor={
													theme.palette.secondary.main
												}
												style={{
													padding: "4px 8px",
													borderRadius: "8px",
													cursor: "pointer",
												}}
											>
												<Typography fontSize={14}>
													{emailAddress}
												</Typography>

												<IconButton
													sx={{ padding: "0px" }}
													onClick={() =>
														handleRemoveBlockedAddress(
															index
														)
													}
												>
													{CloseIcon(
														16,
														16,
														"#000",
														0.6
													)}
												</IconButton>
											</Stack>
										</Box>
									)
								)}
							</Stack>
						) : null}
					</Stack>

					<Divider />

					<Box>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onSaveEmailSettingsConfig}
							disabled={updateEmailSettingsMutation.isLoading}
							sx={{
								"&.Mui-disabled": {
									backgroundColor:
										theme.palette.secondary.main,
									color: alpha(
										theme.palette.secondary.contrastText,
										0.6
									),
								},
							}}
						>
							Save
						</Button>
					</Box>
				</Stack>
			)}
		</React.Fragment>
	);
}
