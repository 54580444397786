const dateFormatList = [
	{
		name: "Relative Date",
		value: 1,
	},
	{
		name: "Month Date Year",
		value: 2,
	},
	{
		name: "Day Month Year",
		value: 3,
	},
];

export default dateFormatList;
