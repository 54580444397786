import React, { forwardRef } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { default as DateIcon } from "../../../assets/icons/date";
import { useTheme } from "@mui/material";

/**
 * Refer mui Date Picker documents for arguments and customization
 * https://mui.com/x/react-date-pickers/base-concepts/
 * https://mui.com/x/react-date-pickers/date-picker/
 **/

const SingleDatePicker = forwardRef((props, ref) => {
	const {
		slots,
		slotProps,
		popperProps,
		textFieldProps,
		id,
		inputRef,
		position = "start",
		error,
		removeBorder = false,
		placeholder,
		textFieldSx,
		...other
	} = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<DatePicker
				ref={ref}
				format="DD/MM/YYYY"
				slots={{
					openPickerIcon: () =>
						DateIcon(
							20,
							20,
							error
								? theme.palette.error.main
								: removeBorder
									? theme.palette.primary.main
									: "#666666"
						),
					...slots,
				}}
				slotProps={{
					inputAdornment: {
						position: position,
					},
					popper: {
						sx: {
							"& .MuiPickersDay-root": {
								"&.Mui-selected": {
									backgroundColor: theme.palette.primary.main,
									borderRadius: "8px",
									"&:hover": {
										border: `1px solid ${theme.palette.secondary.main}`,
										color: "#fff",
									},
								},
							},
							"& .MuiPickersDay-dayWithMargin": {
								borderRadius: "8px",
								fontSize: "14px",
								fontWeight: 500,
								"&:hover": {
									border: `1px solid ${theme.palette.primary.main}`,
									color: theme.palette.primary.main,
								},
							},
						},
						...popperProps,
					},
					textField: {
						inputProps: {
							style: {
								fontSize: "14px",
							},
						},
						placeholder: placeholder || "DD/MM/YYYY",
						id: id,
						error: !!error,
						inputRef: inputRef,
						...textFieldSx,
						...textFieldProps,
					},
					...slotProps,
				}}
				{...other}
			/>
		</React.Fragment>
	);
});

SingleDatePicker.displayName = "SingleDatePicker";

export default SingleDatePicker;
