export const summaryViews = {
	overview: "overview",

	timeline: "timeline",

	conversations: "conversations",

	activities: "activities",

	notes: "notes",

	files: "files",

	followers: "followers",
};
