import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getIconByName } from "../../../../utils";
import { Stack, useTheme } from "@mui/material";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";

export default function ActivityTitle(props) {
	const { data, rowState } = props;
	const theme = useTheme();

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Stack direction="row" spacing={1} alignItems="center">
				{getIconByName(data.iconName)(
					18,
					18,
					getStatusProperties(rowState, theme).iconColor
				)}

				<TableCellText
					color={getStatusProperties(rowState, theme).textColor}
					style={{
						textDecorationLine:
							getStatusProperties(rowState).textDecorationLine,
					}}
					noWrap
				>
					{data.activityTitle}
				</TableCellText>
			</Stack>
		</React.Fragment>
	);
}
