import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SingleTimePicker from "../../../../SingleTimePicker";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function TimeComponent({
	field,
	_subField,
	_isHalfField,
	inputRef,
	...rest
}) {
	const { control, clearErrors } = useFormContext();

	const onClearError = () => {
		clearErrors(field.id + "");
	};

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { ...renderField },
					fieldState: { error },
				}) => (
					<SingleTimePicker
						placeholder={field?.config?.placeHolder}
						{...renderField}
						{...rest}
						id={field.id + ""}
						error={error}
						inputRef={inputRef}
						onClearError={onClearError}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
