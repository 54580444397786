import React from "react";
import { Box, Typography } from "@mui/material";

export default function End(props) {
	const { styles } = props;

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.12)",
					borderRadius: "8px",
				}}
			>
				<Box py={1.5} px={3}>
					<Typography style={styles.title} color="rgba(0, 0, 0, 0.6)">
						End
					</Typography>
				</Box>
			</Box>
		</React.Fragment>
	);
}
