import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { default as ContactIcon } from "../../../../../../../assets/icons/contact";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import {
	Autocomplete,
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
} from "@mui/material";
import { useContactList } from "../../../../../../../hooks/services/contact";
import { useAuth } from "../../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../../utils/Auth";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

export function ContactBaseComponent(props) {
	const {
		field,
		clearErrors,
		onChange,
		value,
		inputRef,
		error,
		isIconNeeded,
		onChangeFieldValueLabel,
	} = props;

	const theme = useTheme();

	const [contactInputValue, setContactInputValue] = useState("");
	const [selectedContact, setSelectedContact] = useState("");

	const { isUserAllowedFor } = useAuth();
	const isContactListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);

	const { data: contacts } = useContactList(isContactListEnabled);

	const CustomPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					display: contactInputValue ? "block" : "none",
				}}
			/>
		);
	};

	useEffect(() => {
		if (value && isUserAllowedFor(PERMISSIONS.contact.view)) {
			let selectedContact = contacts?.find(
				(contact) => contact?.value === value
			);
			setSelectedContact(selectedContact?.name);
		}
	}, [value, contacts, isUserAllowedFor]);

	const updateContactValue = (contactValue) => {
		setSelectedContact(contactValue ?? "");
		onChangeFieldValueLabel
			? onChangeFieldValueLabel({
					[field?.id]: contactValue?.name ?? "",
				})
			: null;
		onChange(contactValue?.value ?? "");
	};

	const handleSelectContact = (_, selectedContact) => {
		updateContactValue(selectedContact);
	};

	const handleContactInputChange = (event) => {
		const { value } = event.target;
		setContactInputValue(value);
		clearErrors(field.id);
	};

	const removeContactValue = () => {
		updateContactValue();
		setContactInputValue("");
	};

	return (
		<React.Fragment>
			<Autocomplete
				size="small"
				PopperComponent={CustomPopper}
				PaperComponent={CustomPaper}
				filterSelectedOptions
				readOnly={!!selectedContact}
				noOptionsText="No Results Found"
				options={contacts || []}
				forcePopupIcon={false}
				onChange={handleSelectContact}
				value={selectedContact || null}
				sx={{ width: "100%" }}
				renderOption={(props, option) => (
					<Box
						component="li"
						key={option.value}
						{...props}
						style={{ minHeight: "40px" }}
					>
						<Typography fontSize={13} key={option.value}>
							{option.name}
						</Typography>
					</Box>
				)}
				getOptionLabel={(option) => {
					if (option.name) {
						return option.name;
					}
					return option;
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={field?.placeHolder || "Enter"}
						value={contactInputValue}
						onChange={handleContactInputChange}
						onBlur={() => setContactInputValue("")}
						inputProps={{
							...params.inputProps,
							style: {
								fontSize: "14px",
								paddingLeft: 0,
							},
						}}
						inputRef={inputRef}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment
									position="start"
									style={{
										paddingLeft: isIconNeeded
											? "8px"
											: "0px",
									}}
								>
									{isIconNeeded
										? ContactIcon(
												20,
												20,
												error
													? theme.palette.error.main
													: "#666666"
											)
										: ""}
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									{!!selectedContact && (
										<IconButton
											onClick={removeContactValue}
										>
											{CloseIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
						sx={{
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px",
							},
						}}
						error={!!error}
					/>
				)}
			/>
		</React.Fragment>
	);
}
