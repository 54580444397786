import { Box, Divider, Skeleton } from "@mui/material";
import React from "react";

export default function SummaryFieldLoader() {
	const summaryCardCount = 10;

	return (
		<React.Fragment>
			{Array.from({ length: summaryCardCount }, (_, index) => (
				<React.Fragment key={index}>
					<Box minWidth="300px">
						<table>
							<tbody>
								<tr>
									<td>
										<Skeleton width="240px" height="18px" />
									</td>
								</tr>
								<tr>
									<td>
										<Skeleton width="180px" height="18px" />
									</td>
								</tr>
							</tbody>
						</table>
					</Box>

					<Divider orientation="vertical" flexItem />
				</React.Fragment>
			))}
		</React.Fragment>
	);
}
