import { Box, Skeleton, Stack } from "@mui/material";

export default function DealCardLoader(props) {
	const { width = "304px", height = "108px" } = props;
	const styles = {
		dealCard: {
			width: width,
			height: height,
			backgroundColor: "#FFF",
			borderRadius: "8px",
			padding: "16px",
		},
	};

	return (
		<Box style={styles.dealCard}>
			<Stack spacing={1}>
				<Skeleton width="118px" height="18px" />
				<Skeleton width="188px" height="18px" />

				<Stack direction="row" spacing={1}>
					<Skeleton variant="circular" width="26px" height="26px" />
					<Skeleton variant="circular" width="26px" height="26px" />
				</Stack>
			</Stack>
		</Box>
	);
}
