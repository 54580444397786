import React from "react";

function Filter(width = "24", height = "24", color = "#33BC7E") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 32 32"
			style={{ fill: color }}
		>
			<path d="M7.107 3.68c-.896 0-1.693.734-1.693 1.827V8.6c0 .217.08.576.264.987.181.403.421.763.656.999l.018.018 5.12 5.387a.821.821 0 01.048.055c.35.427.668.991.9 1.571.23.577.406 1.247.406 1.889v7.133c0 1.319 1.513 2.137 2.64 1.447l1.894-1.222c.134-.081.328-.284.494-.603.163-.314.239-.626.239-.835a1 1 0 012 0c0 .604-.191 1.232-.464 1.758-.269.517-.677 1.049-1.217 1.382l-1.886 1.217c-2.366 1.463-5.699-.146-5.699-3.144v-7.133c0-.305-.091-.714-.264-1.147a4.21 4.21 0 00-.569-1.019L4.91 11.991c-.44-.443-.803-1.018-1.057-1.585-.252-.562-.439-1.21-.439-1.806V5.507c0-2.134 1.63-3.827 3.693-3.827h17.787a3.703 3.703 0 013.693 3.693v2.96c0 .73-.224 1.482-.514 2.117-.291.638-.69 1.248-1.126 1.684a.999.999 0 11-1.414-1.414c.231-.231.505-.627.721-1.1.217-.475.333-.936.333-1.286v-2.96c0-.928-.766-1.693-1.693-1.693H7.107zm14.32 10.813a3.267 3.267 0 100 6.535 3.267 3.267 0 000-6.535zM16.16 17.76a5.267 5.267 0 119.628 2.954c.027.022.054.046.08.072l1.333 1.333a.999.999 0 11-1.414 1.414L24.454 22.2a.896.896 0 01-.072-.08 5.267 5.267 0 01-8.221-4.361z"></path>
		</svg>
	);
}

export default Filter;
