import { apiClient } from "..";
import { getContactSyncUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getContactSyncSettingsData = async () => {
	let requestData = getContactSyncSettingsDataRequest();
	let response = postContactSycSettingsApi(requestData);
	return await response;
};

export const startContactSync = async (data) => {
	let requestData = getStartContactSyncRequest(data);
	let response = postContactSycSettingsApi(requestData);
	return await response;
};

export const stopContactSync = async (id) => {
	let requestData = getStopContactSyncRequest(id);
	let response = postContactSycSettingsApi(requestData);
	return await response;
};

export const getContactSyncStatus = async (id) => {
	let requestData = getContactSyncStatusRequest(id);
	let response = postContactSycSettingsApi(requestData);
	return await response;
};

const getContactSyncStatusRequest = (id) => {
	return {
		type: "checkSyncStatus",
		data: {
			id: id,
		},
	};
};

const getStopContactSyncRequest = () => {
	return {
		type: "stopSync",
	};
};

const getStartContactSyncRequest = (data) => {
	return {
		type: "sync",
		data: data,
	};
};

const getContactSyncSettingsDataRequest = () => {
	return {
		type: "getSettingsConfig",
	};
};

const postContactSycSettingsApi = async (requestData) => {
	let ContactSyncSettingsUrl = getContactSyncSettingsUrl();
	let response = await apiClient.post(ContactSyncSettingsUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getContactSyncSettingsUrl = () => {
	return getContactSyncUrl() + "/settings";
};
