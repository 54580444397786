import React from "react";

export default function CRM(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M1 7.42348C1 6.20176 2.38238 5.49248 3.37481 6.20499V6.20499C3.76728 6.48677 4 6.94033 4 7.42348V16.3678C4 17.4061 2.97047 18.1304 1.9932 17.7796V17.7796C1.3974 17.5657 1 17.0008 1 16.3678V7.42348Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23 7.42348C23 6.20176 21.6176 5.49248 20.6252 6.20499V6.20499C20.2327 6.48677 20 6.94033 20 7.42348V16.3678C20 17.4061 21.0295 18.1304 22.0068 17.7796V17.7796C22.6026 17.5657 23 17.0008 23 16.3678V7.42348Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20 8.5L14.6973 6.98494C13.6497 6.68563 12.5222 6.9778 11.7518 7.74819L10 9.5V9.5C10 10.6046 10.8954 11.5 12 11.5H12.2719C12.7454 11.5 13.2071 11.648 13.5925 11.9232L20 16.5V8.5Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 8.5L4 7V16.5L11.4517 18.9839C12.4171 19.3057 13.4798 19.1162 14.2744 18.4804L18 15.5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 17.5L12.5 16"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
