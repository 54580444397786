import { getRootKey } from "../rootKey";

const currencyKey = "currency";

export const getCurrencyKey = () => {
	return [...getRootKey(), currencyKey];
};

export const getCurrencyDataKey = () => {
	return [...getCurrencyKey(), "currencyData"];
};

export const getCurrencyListKey = () => {
	return [...getCurrencyKey(), "currencyList"];
};
