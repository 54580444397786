import { getWorkFlowUrl } from "../apiConfig";
import { apiClient } from "../index";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";

export const getTemplateData = async (templateId) => {
	let requestData = getTemplateRequestData(templateId);
	return await postWorkflowTemplateManagementApi(requestData);
};

export const getWorkflowData = async (workflowId) => {
	let requestData = getWorkflowRequestData(workflowId);
	return await postWorkflowManagementApi(requestData);
};

export const getAllTemplateData = async () => {
	let requestData = getAllTemplateRequestData();
	return await postWorkflowTemplateManagementApi(requestData);
};

export const createWorkflow = async (workflowData) => {
	let requestData = getCreateWorkflowRequestData(workflowData);
	return await postWorkflowManagementApi(requestData);
};

export const getCriteriaFieldsData = async (triggerSource, triggerEvent) => {
	let requestData = getCriteriaFieldsRequestData(triggerSource, triggerEvent);
	return await postWorkflowManagementApi(requestData);
};

export const getActionData = async (triggerSource) => {
	let requestData = getActionRequestData(triggerSource);
	return await postWorkflowActionManagementApi(requestData);
};

export const getWorkflowList = async (
	criteria,
	searchText,
	sortData,
	pageParams
) => {
	let requestData = getWorkflowListRequestData(
		criteria,
		searchText,
		sortData,
		pageParams
	);
	return await postWorkflowListApi(requestData);
};

export const updateWorkflow = async (workflowData) => {
	let requestData = getUpdateWorkflowRequestData(workflowData);
	return await postWorkflowManagementApi(requestData);
};

export const deleteWorkflow = async (workflowId) => {
	let requestData = getDeleteWorkflowRequest(workflowId);
	return await postWorkflowManagementApi(requestData);
};

export const getAutomationFilterList = async () => {
	let requestData = getAutomationFilterListRequest();
	return await postWorkflowListApi(requestData);
};

export const getWorkflowFilterUserList = async () => {
	let requestData = getWorkflowFilterUserListRequest();
	return await postFieldsApi(requestData);
};

export const getWorkflowNamesList = async () => {
	let requestData = getWorkflowNamesListRequest();
	return await postFieldsApi(requestData);
};

export const getWorkflowActionsList = async () => {
	let requestData = getWorkflowFilterActionsListRequest();
	return await postWorkflowActionManagementApi(requestData);
};

export const updateWorkflowStatus = async ({ workflowId, isActive }) => {
	let requestData = getWorkflowStatusRequest(workflowId, isActive);
	return await postWorkflowManagementApi(requestData);
};

export const getActionFieldsData = async (
	triggerSource,
	actionSource,
	actionEvent
) => {
	let requestData = getActionFieldsRequestData(
		triggerSource,
		actionSource,
		actionEvent
	);
	return await postWorkflowActionManagementApi(requestData);
};

export const getWorkflowHistoryFilter = async () => {
	let requestData = getWorkflowHistoryFilterRequest();
	return await postWorkflowHistoryListAPI(requestData);
};

export const getActionStepOptionData = async (
	triggerSource,
	fieldId,
	actions
) => {
	let requestData = getActionStepOptionRequestData(
		triggerSource,
		fieldId,
		actions
	);
	return await postWorkflowActionManagementApi(requestData);
};

export const getWorkflowHistoryList = async (criteria, sort, pageParams) => {
	let requestData = getWorkflowHistoryListRequest(criteria, sort, pageParams);
	return await postWorkflowHistoryListAPI(requestData);
};

export const getWorkflowHistoryData = async (historyId) => {
	let requestData = getWorkflowHistoryDataRequest(historyId);
	return await postWorkflowHistoryManagementAPI(requestData);
};

const getWorkflowHistoryListRequest = (criteria, sort, pageParams) => {
	return {
		type: "list",
		data: {
			criteria: criteria,
			sort: sort,
			...pageParams,
		},
	};
};

const getWorkflowHistoryDataRequest = (historyId) => {
	return {
		type: "get",
		data: {
			id: historyId,
		},
	};
};

const getAutomationFilterListRequest = () => {
	return {
		type: "getFilters",
		data: {},
	};
};

const getWorkflowHistoryFilterRequest = () => {
	return {
		type: "getFilters",
		data: {},
	};
};

const getWorkflowFilterUserListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "workflowFilterUserList",
		},
	};
};

const getWorkflowNamesListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "workflowList",
		},
	};
};

const getWorkflowStatusRequest = (workflowId, isActive) => {
	return {
		type: "updateStatus",
		data: {
			id: workflowId,
			isActive: isActive,
		},
	};
};

const getWorkflowFilterActionsListRequest = () => {
	return {
		type: "getFilterActions",
		data: {},
	};
};

const getUpdateWorkflowRequestData = (workflowData) => {
	return {
		type: "update",
		data: workflowData,
	};
};

const getDeleteWorkflowRequest = (workflowId) => {
	return {
		type: "delete",
		data: {
			id: workflowId,
		},
	};
};

const getCreateWorkflowRequestData = (workflowData) => {
	return {
		type: "create",
		data: workflowData,
	};
};

const getCriteriaFieldsRequestData = (triggerSource, triggerEvent) => {
	return {
		type: "criteriaFieldList",
		data: {
			trigger: {
				source: triggerSource,
				event: triggerEvent,
			},
		},
	};
};

const getTemplateRequestData = (templateId) => {
	return {
		type: "get",
		data: {
			id: templateId,
		},
	};
};

const getWorkflowRequestData = (workflowId) => {
	return {
		type: "get",
		data: {
			id: workflowId,
		},
	};
};

const getAllTemplateRequestData = () => {
	return {
		type: "getAll",
		data: {},
	};
};

const getActionRequestData = (triggerSource) => {
	return {
		type: "getActions",
		data: {
			triggerSource: triggerSource,
		},
	};
};

const getActionFieldsRequestData = (
	triggerSource,
	actionSource,
	actionEvent
) => {
	return {
		type: "getActionFields",
		data: {
			triggerSource: triggerSource,
			action: {
				source: actionSource,
				event: actionEvent,
			},
		},
	};
};

const getActionStepOptionRequestData = (triggerSource, fieldId, actions) => {
	return {
		type: "getStepOptions",
		data: {
			triggerSource: triggerSource,
			fieldId: fieldId,
			actions: actions,
		},
	};
};

const getWorkflowListRequestData = (
	criteria,
	searchText,
	sortData,
	pageParams
) => {
	return {
		type: "list",
		data: {
			criteria: criteria,
			searchText: searchText,
			sort: sortData,
			...pageParams,
		},
	};
};

const postWorkflowTemplateManagementApi = async (requestData) => {
	let workflowTemplateManagementUrl = getWorkflowTemplateManagementUrl();
	return postWorkflowAPI(workflowTemplateManagementUrl, requestData);
};

const postWorkflowActionManagementApi = async (requestData) => {
	let workflowActionManagementUrl = getWorkflowActionManagementUrl();
	return postWorkflowAPI(workflowActionManagementUrl, requestData);
};

const postWorkflowManagementApi = async (requestData) => {
	let workflowManagementUrl = getWorkflowManagementUrl();
	return postWorkflowAPI(workflowManagementUrl, requestData);
};

const postWorkflowListApi = async (requestData) => {
	let workflowListUrl = getWorkflowListUrl();
	return postWorkflowAPI(workflowListUrl, requestData);
};

const postWorkflowHistoryListAPI = async (requestData) => {
	let workflowHistoryUrl = getWorkflowHistoryListUrl();
	return postWorkflowAPI(workflowHistoryUrl, requestData);
};

const postWorkflowHistoryManagementAPI = async (requestData) => {
	let workflowHistoryUrl = getWorkflowHistoryManagementUrl();
	return postWorkflowAPI(workflowHistoryUrl, requestData);
};

const postWorkflowAPI = async (requestUrl, requestData) => {
	let response = await apiClient.post(requestUrl, requestData);
	assertError(response, requestUrl);

	return response.data.data;
};

const getWorkflowTemplateManagementUrl = () => {
	return getWorkFlowUrl() + "/template/mgmt";
};

const getWorkflowActionManagementUrl = () => {
	return getWorkFlowUrl() + "/action/mgmt";
};

const getWorkflowManagementUrl = () => {
	return getWorkFlowUrl() + "/mgmt";
};

const getWorkflowHistoryListUrl = () => {
	return getWorkFlowUrl() + "/execution/history/list";
};

const getWorkflowHistoryManagementUrl = () => {
	return getWorkFlowUrl() + "/execution/history/mgmt";
};

const getWorkflowListUrl = () => {
	return getWorkFlowUrl() + "/list";
};
