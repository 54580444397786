const emailType = [
	{
		value: 1,
		name: "Work",
	},
	{
		value: 2,
		name: "Personal",
	},
	{
		value: 3,
		name: "Home",
	},
	{
		value: 4,
		name: "Other",
	},
];

export default emailType;
