import { Stack } from "@mui/material";
import UnAuthorized from "../UnAuthorized";

export default function UnAuthorizedRoutePage() {
	return (
		<Stack height="80vh">
			<UnAuthorized />
		</Stack>
	);
}
