import React, { useState } from "react";
import { Box, Stack, Switch, Typography, useTheme } from "@mui/material";
import { default as DragIcon } from "../../../../../assets/icons/drag";
import { getIconByName } from "../../../../../utils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useUpdateActivityTypeStatus } from "../../../../../hooks/services/activityType";
import { PERMISSIONS } from "../../../../../utils/Auth";
import { useAuth } from "../../../../../hooks/auth";

export default function ActivityType(props) {
	const { activityType, isActive, handleOpenEditActivityTypeDialog } = props;
	const theme = useTheme();
	const updateActivityTypeStatusMutation = useUpdateActivityTypeStatus();

	const [activityTypeHovered, setActivityTypeHovered] = useState(false);
	const [isEnabled, setIsEnabled] = useState(isActive);

	const { isUserAllowedFor } = useAuth(PERMISSIONS.activityType.edit);

	const getUpdateStatusRequest = (status) => {
		let requestData = {
			typeId: activityType.id,
			isActive: status,
		};
		return requestData;
	};

	const updateActivityTypeStatus = (status) => {
		const requestData = getUpdateStatusRequest(status);
		updateActivityTypeStatusMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success",
					variant: isActive
						? notificationVariants.error
						: notificationVariants.success,
					message: isActive
						? notificationMessage.activityTypeDisabled
						: notificationMessage.activityTypeEnabled,
				});
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleEnableDisableActivityType = (event) => {
		const { checked } = event.target;
		setIsEnabled(checked);
		updateActivityTypeStatus(checked);
	};

	const displaySwitchButton = () => {
		return (
			isUserAllowedFor(PERMISSIONS.activityType.delete) &&
			isUserAllowedFor(PERMISSIONS.activityType.edit)
		);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					boxShadow: activityTypeHovered
						? "0px 2px 4px rgba(0, 0, 0, 0.1)"
						: "none",
					cursor: "pointer",
				}}
				p={2}
				onMouseOver={() => setActivityTypeHovered(true)}
				onMouseOut={() => setActivityTypeHovered(false)}
				onClick={handleOpenEditActivityTypeDialog}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Stack direction="row" spacing={2.5}>
						<Box display="flex" {...props.dragHandleProps}>
							{DragIcon(
								24,
								24,
								"#000",
								isUserAllowedFor(PERMISSIONS.activityType.edit)
									? 0.6
									: 0.2
							)}
						</Box>
						<Stack direction="row" alignItems="center" spacing={2}>
							{getIconByName(activityType.icon.name)(
								24,
								24,
								theme.palette.primary.main
							)}

							<Typography>{activityType.name}</Typography>
						</Stack>
					</Stack>

					{displaySwitchButton() ? (
						<Box onClick={(event) => event.stopPropagation()}>
							{activityTypeHovered && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={3}
									pr={2}
								>
									<Switch
										checked={isEnabled}
										onChange={
											handleEnableDisableActivityType
										}
									/>
								</Stack>
							)}
						</Box>
					) : null}
				</Stack>
			</Box>
		</React.Fragment>
	);
}
