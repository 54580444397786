import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import {
	deleteFile,
	getFiles,
	uploadFiles,
} from "../../../../api/file/fileApi";
import { getFileListKey } from "../../../../utils/queryKeys/summary/files";
import { filePageSize } from "../../../../utils/queryConstants/summary/files";
import { modules } from "../../../../utils/common/ModulesName";
import { useInvalidateTimeline } from "../timeline";
import { useInvalidateDealSummaryData } from "../../deal";

const fileListSelect = (files) => {
	return files.pages.flatMap((page) =>
		page?.files?.length > 0 ? page?.files : []
	);
};

const useFileList = (sourceName, entityId) => {
	return useInfiniteQuery({
		queryKey: getFileListKey(sourceName, entityId),
		queryFn: ({ pageParam }) => {
			return getFiles(sourceName, entityId, {
				start: pageParam ? (pageParam - 1) * filePageSize + 1 : 1,
				limit: filePageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: fileListSelect,
	});
};

const useInvalidateFileList = (sourceName, entityId) => {
	let queryClient = useQueryClient();
	let fileListKey = getFileListKey(sourceName, entityId);
	return () => {
		queryClient.invalidateQueries(fileListKey);
	};
};

const useUploadFiles = (sourceName, entityId) => {
	let invalidateFileList = useInvalidateFileList(sourceName, entityId);
	let invalidateTimeline = useInvalidateTimeline(sourceName, entityId);
	let invalidateDealSummary = useInvalidateDealSummaryData(entityId);
	return useMutation(uploadFiles, {
		onSuccess: () => {
			invalidateFileList();
			invalidateTimeline();
			if (sourceName === modules.DEAL) {
				invalidateDealSummary();
			}
		},
	});
};

const useDeleteFile = (sourceName, entityId) => {
	let invalidateFileList = useInvalidateFileList(sourceName, entityId);
	let invalidateTimeline = useInvalidateTimeline(sourceName, entityId);
	let invalidateDealSummary = useInvalidateDealSummaryData(entityId);
	return useMutation(deleteFile, {
		onSuccess: () => {
			invalidateFileList();
			invalidateTimeline();
			if (sourceName === modules.DEAL) {
				invalidateDealSummary();
			}
		},
	});
};

export { useFileList, useInvalidateFileList, useUploadFiles, useDeleteFile };
