import React from "react";
import ShortNotes from "../../../Summary/ShortNotes";
import { modules } from "../../../../utils/common/ModulesName";

export default function CompanyNotes({ companyId }) {
	return (
		<React.Fragment>
			<ShortNotes source={modules.COMPANY} entityId={companyId} />
		</React.Fragment>
	);
}
