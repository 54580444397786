import { smsIds, type } from "..";

export const getSmsConfigForPermission = (permissions) => {
	return [
		{
			id: smsIds.bulkSmsLimit,
			name: "Bulk SMS Limit",
			access: permissions.bulkSmsLimit.access,
			permissions: {
				type: type.input,
				id: "permittedLimit",
				value: permissions.bulkSmsLimit.permittedLimit
					? permissions.bulkSmsLimit.permittedLimit
					: 100,
				placeHolder: "SMS Per Day",
				noDataMessage: "Cannot send bulk SMS",
			},
		},
		{
			id: smsIds.individualSmsLimit,
			name: "Individual SMS Limit",
			access: permissions.individualSmsLimit.access,
			permissions: {
				type: type.input,
				id: "permittedLimit",
				value: permissions.individualSmsLimit.permittedLimit
					? permissions.bulkSmsLimit.permittedLimit
					: 50,
				placeHolder: "SMS Per Day",
				noDataMessage: "Cannot send SMS",
			},
		},
	];
};
