import React from "react";
import { Box } from "@mui/material";
import ImportField from "../ImportField";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../.././utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../.././utils/notification/notificationMessages";
import { useCreateField } from "../../../../../.././hooks/services/import";

export default function ImportFieldList(props) {
	const {
		newFieldsCreated,
		updateNewFieldsCreated,
		fieldTypeData,
		sourceType,
	} = props;

	const createMutation = useCreateField(sourceType);

	const handleCreateNewField = (newField) => {
		createMutation.mutate(newField, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldCreated,
				});
				updateNewFieldsCreated([]);
			},
			onError: (error) => {
				let parsedError = JSON.parse(error?.message);
				let errorMessage = parsedError?.failedRecords?.[0]?.error;

				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{newFieldsCreated
				?.map((field, index) => (
					<Box key={index} height="40px" pt={1} pl={2} pr={1}>
						<ImportField
							field={field}
							index={index}
							fieldTypeData={fieldTypeData}
							newFieldsCreated={newFieldsCreated}
							handleCreateNewField={handleCreateNewField}
							updateNewFieldsCreated={updateNewFieldsCreated}
						/>
					</Box>
				))
				.reverse()}
		</React.Fragment>
	);
}
