import { getMailUrl } from "../apiConfig";
import { apiClient } from "..";
import { assertError } from "../apiHelper";

export const syncEmailAccount = async (data) => {
	let requestData = getEmailSyncRequest(data);
	return await postEmailSyncManagementApi(requestData);
};

export const getEmailSyncSettingsData = async () => {
	let requestData = getConnectedEmailRequest();
	return await postEmailSyncManagementApi(requestData);
};

export const disconnectEmail = async (data) => {
	let requestData = getDisconnectEmailRequest(data);
	return await postEmailSyncManagementApi(requestData);
};

const postEmailSyncManagementApi = async (requestData) => {
	let emailUrl = getEmailSyncUrl();
	let response = await apiClient.post(emailUrl, requestData);
	assertError(response);
	return response.data.data;
};

export const getEmailSyncStatus = async (id) => {
	let requestData = getEmailSyncStatusRequest(id);
	let response = postEmailSyncManagementApi(requestData);
	return await response;
};

const getEmailSyncStatusRequest = (id) => {
	return {
		type: "checkSyncStatus",
		data: {
			id: id,
		},
	};
};

const getEmailSyncRequest = (data) => {
	return {
		type: "sync",
		data: data,
	};
};

const getConnectedEmailRequest = () => {
	return {
		type: "get",
		data: {},
	};
};

const getDisconnectEmailRequest = (data) => {
	return {
		type: "disconnect",
		data: data,
	};
};

export const getEmailSyncUrl = () => {
	return getMailUrl() + "/sync/settings";
};
