import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./topBar";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import NavigationBar from "./navigationBar";
import Welcome from "./welcome";
import { SearchProvider } from "../Search/SearchContext/SearchContextProvider";
import ReminderNotification from "./ReminderNotification";

export default function Layout() {
	const drawerWidth = 60;

	return (
		<React.Fragment>
			<SearchProvider>
				<Welcome />
				<ReminderNotification />
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<TopBar drawerWidth={drawerWidth} />
					<Box component="nav" sx={{ width: drawerWidth }}>
						<NavigationBar drawerWidth={drawerWidth} />
					</Box>
					<Box
						component="main"
						sx={{
							flexGrow: 1,
							width: `calc(100% - ${drawerWidth}px)`,
							minHeight: "100vh",
						}}
					>
						<Toolbar />
						<Outlet />
					</Box>
				</Box>
			</SearchProvider>
		</React.Fragment>
	);
}
