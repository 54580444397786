import { useMemo } from "react";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { modules } from "../../../../utils/common/ModulesName";
import SummaryProfileEditForm from "../../../Elements/SummaryProfileEditForm";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { usePartialUpdateContact } from "../../../../hooks/services/contact";

export default function ContactSummaryProfileEdit(params) {
	const { profile, onClose, contactId } = params;

	// mutation call :-
	const updateMutation = usePartialUpdateContact(contactId);

	const modifiedProfileData = useMemo(() => {
		const {
			company,
			designation,
			facebook,
			firstName,
			lastName,
			linkedIn,
		} = profile;

		return {
			[firstName?.fieldId]: firstName?.value,
			[lastName?.fieldId]: lastName?.value,
			[company?.fieldId]: company?.value?.id,
			[designation?.fieldId]: designation?.value,
			[facebook?.fieldId]: facebook?.value,
			[linkedIn?.fieldId]: linkedIn?.value,
			id: contactId,
		};
	}, [profile, contactId]);

	const handleUpdateSummaryProfile = (profileData) => {
		removeFieldsWithEmptyValues(profileData);
		updateMutation.mutate(profileData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<>
			<SummaryProfileEditForm
				onClose={onClose}
				moduleName={modules.CONTACT}
				values={modifiedProfileData}
				onSave={handleUpdateSummaryProfile}
				isSaving={updateMutation.isLoading}
			/>
		</>
	);
}
