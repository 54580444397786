import React from "react";
import { Stack, useTheme } from "@mui/material";
import { TableCellText, Tooltip } from "../../../../styles/twozo";

export default function MultiSelect(props) {
	const { data } = props;
	const theme = useTheme();

	if (!data) {
		return null;
	}

	const options = data.map((item) => item.option).join(", ");

	return (
		<React.Fragment>
			<Stack direction="row" spacing={0.5} alignItems="center">
				<TableCellText
					py={0.3}
					px={1}
					style={{
						backgroundColor: theme.palette.secondary.main,
						width: "fit-content",
						borderRadius: "6px",
					}}
				>
					{data?.[0]?.option}
				</TableCellText>

				{data?.[1] ? (
					<TableCellText
						py={0.3}
						px={1}
						style={{
							backgroundColor: theme.palette.secondary.main,
							width: "fit-content",
							borderRadius: "6px",
						}}
					>
						{data?.[1]?.option}
					</TableCellText>
				) : null}

				<Tooltip title={options} placement="top">
					<TableCellText color={theme.palette.primary.main}>
						{data.length > 2 && `+${data.length - 2}`}
					</TableCellText>
				</Tooltip>
			</Stack>
		</React.Fragment>
	);
}
