import { default as ContactIcon } from "../assets/icons/contact";
import { default as CallIcon } from "../assets/icons/call";
import { default as FavouriteIcon } from "../assets/icons/favouriteOff";
import { default as MailIcon } from "../assets/icons/mail";
import { default as MonitorIcon } from "../assets/icons/monitor";
import { default as BookIcon } from "../assets/icons/book";

export const resources = [
	{
		icon: BookIcon,
		text: "Knowledge Base",
	},
	{
		icon: CallIcon,
		text: "Talk to us",
	},
	{
		icon: MonitorIcon,
		text: "Webinar",
	},
	{
		icon: ContactIcon,
		text: "Community",
	},
	{
		icon: MailIcon,
		text: "Support@diamond.in",
	},
	{
		icon: FavouriteIcon,
		text: "What's New?",
	},
];
