import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import { permissionTypes } from "../../../../../../../utils/PermissionUtils";

export default function LeadGeneration(props) {
	const { permissions, isEditable } = props;

	const {
		permissionState,
		updateLeadGenerationList,
		initializeLeadGenerationListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeLeadGenerationListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		const updatedLeadGenerationList =
			permissionState.leadGenerationList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						access: event.target.checked,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) => {
									return {
										...permission,
										value: event.target.checked,
										disabled: !event.target.checked,
									};
								}
							),
						},
					};
				} else {
					return list;
				}
			});
		updateLeadGenerationList(updatedLeadGenerationList);
	};

	const onChange = (event, selectedPermission, id) => {
		if (selectedPermission.id === permissionTypes.view) {
			const updatedLeadGenerationList =
				permissionState.leadGenerationList.map((list) => {
					if (list.id === id) {
						return {
							...list,
							access: event.target.checked,
							permissions: {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => {
										return {
											...permission,
											value: event.target.checked,
											disabled: !event.target.checked,
										};
									}
								),
							},
						};
					} else {
						return list;
					}
				});
			updateLeadGenerationList(updatedLeadGenerationList);
		} else {
			const updatedLeadGenerationList =
				permissionState.leadGenerationList.map((list) => {
					if (list.id === id) {
						return {
							...list,
							permissions: {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) =>
										permission.id === selectedPermission.id
											? {
													...permission,
													value: event.target.checked,
												}
											: permission
								),
							},
						};
					} else {
						return list;
					}
				});
			updateLeadGenerationList(updatedLeadGenerationList);
		}
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Lead Generation"
				lists={permissionState.leadGenerationList}
				onChange={onChange}
				handleAccessChange={handleAccessChange}
				isEditable={isEditable}
			/>
		</React.Fragment>
	);
}
