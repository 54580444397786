import { apiClient } from "../..";
import { getMailUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import postFieldsApi from "../../fields/postFieldsApi";

export const createEmailTemplateTags = async (tagName) => {
	let requestData = getCreateEmailTemplateTagsRequest(tagName);
	return await postEmailTemplateTagsManagementUrl(requestData);
};

export const updateEmailTemplateTags = async ({ tagName, tagId }) => {
	let requestData = getUpdateEmailTemplateTagsRequest(tagName, tagId);
	return await postEmailTemplateTagsManagementUrl(requestData);
};

export const getEmailTemplateTagsList = async (pageParam) => {
	let requestData = getEmailTemplateTagsListRequest(pageParam);
	return await postEmailTemplateTagsManagementUrl(requestData);
};

export const deleteEmailTemplateTags = async (id) => {
	let requestData = getDeleteEmailTemplateTagsRequest(id);
	return await postEmailTemplateTagsManagementUrl(requestData);
};

export const getEmailTemplateTagsDropdownList = async () => {
	let requestData = getEmailTemplateTagsDropdownRequest();
	return await postFieldsApi(requestData);
};

const getCreateEmailTemplateTagsRequest = (tagName) => {
	return {
		type: "create",
		data: {
			name: tagName,
		},
	};
};

const getUpdateEmailTemplateTagsRequest = (tagName, tagId) => {
	return {
		type: "update",
		data: {
			name: tagName,
			id: tagId,
		},
	};
};

const getEmailTemplateTagsListRequest = (pageParam) => {
	return {
		type: "list",
		data: {
			...pageParam,
		},
	};
};

const getDeleteEmailTemplateTagsRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getEmailTemplateTagsDropdownRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "templateTagList",
		},
	};
};

const postEmailTemplateTagsManagementUrl = async (requestData) => {
	let emailTemplateTagsManagementUrl = getEmailTemplateTagsManagementUrl();
	let response = await apiClient.post(
		emailTemplateTagsManagementUrl,
		requestData
	);
	assertError(response, emailTemplateTagsManagementUrl);
	return response.data.data;
};

const getEmailTemplateTagsManagementUrl = () => {
	return getMailUrl() + "/template/tag/mgmt";
};
