import { Typography } from "@mui/material";
import React from "react";

export default function Element({ formProperty, formStyle }) {
	return (
		<React.Fragment>
			<Typography
				style={
					formProperty.name === "Text"
						? formStyle.headerStyle
						: formStyle.subHeaderStyle
				}
			>
				{formProperty.label}
			</Typography>
		</React.Fragment>
	);
}
