import { tagActionTypes } from "../../../../utils/tagActionTypes";

export const tagReducer = (state, action) => {
	switch (action.type) {
		case tagActionTypes.createTag:
			return {
				...state,
				isCreateMode: true,
			};
		case tagActionTypes.creationError:
			return {
				...state,
				hasCreationError: true,
			};
		case tagActionTypes.resetCreateActions:
			return {
				...state,
				isCreateMode: false,
				hasCreationError: false,
			};
		case tagActionTypes.updateTag:
			return {
				...state,
				isUpdateMode: true,
			};
		case tagActionTypes.updateError:
			return {
				...state,
				hasUpdateError: true,
			};
		case tagActionTypes.resetUpdateActions:
			return {
				...state,
				isUpdateMode: false,
				hasUpdateError: false,
			};
		default:
			state;
	}
};
