import React from "react";
import { Box } from "@mui/material";
import FileList from "./FileList";

export default function Files(props) {
	const { sourceName, entityId } = props;

	return (
		<React.Fragment>
			<Box>
				<FileList sourceName={sourceName} entityId={entityId} />
			</Box>
		</React.Fragment>
	);
}
