import { Typography, useTheme } from "@mui/material";
import SingleDatePicker from "../../../Elements/SingleDatePicker";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { forwardRef, useCallback, useImperativeHandle } from "react";

const textFieldProps = {
	style: {
		width: "300px",
	},
};

const textFieldSx = {
	sx: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none",
			},
			"&:hover fieldset": {
				border: "none",
			},
			"&.Mui-focused fieldset": {
				border: "none",
			},
		},
	},
};

const DateFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const fieldId = field.fieldId;
		const theme = useTheme();
		const {
			control,
			handleSubmit,
			formState: { errors },
			clearErrors,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const getParsedValue = (value) => {
			return value ? dayjs(value) : null;
		};

		const handleDateChange = (event, onChange) => {
			let selectedDate = event.valueOf() ? event.valueOf() : "";
			let modifiedEvent = {
				...event,
				target: {
					...event.target,
					value: selectedDate,
				},
			};

			onChange(modifiedEvent);
			clearErrors(fieldId);
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, value, ...fieldData } }) => (
						<SingleDatePicker
							removeBorder={true}
							onChange={(event) =>
								handleDateChange(event, onChange)
							}
							value={getParsedValue(value)}
							textFieldProps={textFieldProps}
							textFieldSx={textFieldSx}
							error={errors[fieldId]}
							{...fieldData}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

DateFieldEditForm.displayName = "DateFieldEditForm";

export default DateFieldEditForm;
