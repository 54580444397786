import React from "react";
import { TextField } from "@mui/material";
import { emailPattern } from "../../../../../utils/validation";

export default function EmailComponent(props) {
	const { style = {}, field = {}, onChange, hasError, ...rest } = props;

	const isValidEmail = (emailValue) => {
		if (emailValue) {
			return emailPattern.test(emailValue);
		}
		return false;
	};

	const handleChange = (e) => {
		const { value } = e.target;
		if (!field.values || !Array.isArray(field.values)) {
			field.values = [{ value: "" }];
		}
		if (isValidEmail(value)) {
			field.values[0].value = value;
			if (onChange) {
				onChange(field.values);
			}
		} else {
			field.values[0].value = "";
			if (onChange) {
				onChange([]);
			}
		}
	};

	return (
		<React.Fragment>
			<TextField
				{...rest}
				placeholder="-Enter-"
				defaultValue={
					field.values && field.values.length > 0
						? field.values[0].value
						: ""
				}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					...style,
				}}
				onChange={handleChange}
				error={hasError}
				fullWidth
			/>
		</React.Fragment>
	);
}
