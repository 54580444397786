import React from "react";
import EmailList from "../../EmailList";
import { mailListName } from "../../../../utils/EmailUtils/mailListName";

export default function Bounces() {
	const menuConfig = {
		isDeleteEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Email Tracking - Bounces"
				mailListName={mailListName.BOUNCES}
				menuConfig={menuConfig}
				hiddenBodyContent={true}
			/>
		</React.Fragment>
	);
}
