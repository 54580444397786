import React, { useMemo } from "react";
import NumberComponent from "./Field/NumberComponent";
import DateComponent from "./Field/DateComponent";
import TextComponent from "./Field/TextComponent";
import TextAreaComponent from "./Field/TextAreaComponent";
import DropdownComponent from "./Field/DropdownComponent";
import MultiSelectComponent from "./Field/MultiSelectComponent";
import Element from "./Element";

const fieldComponents = {
	SINGLE_STRING: TextComponent,
	EMAIL: TextComponent,
	TEXT: TextAreaComponent,
	SINGLE_NUMBER: NumberComponent,
	PHONE: NumberComponent,
	DATE: DateComponent,
	DROPDOWN: DropdownComponent,
	MULTI_SELECT: MultiSelectComponent,
};

const getFieldType = (id, fieldList) => {
	const fieldData = fieldList?.find((field) => field.fieldId === id);
	if (fieldData) {
		return fieldData?.fieldType?.name;
	}
};

export default function FormField({
	formProperty,
	fieldList,
	formStyle,
	isField,
}) {
	const fieldType = useMemo(
		() => getFieldType(formProperty.fieldId, fieldList),
		[formProperty, fieldList]
	);

	const Field = fieldComponents[fieldType];

	return Field ? (
		<React.Fragment>
			{isField ? (
				<Field formProperty={formProperty} formStyle={formStyle} />
			) : null}
		</React.Fragment>
	) : (
		<Element formProperty={formProperty} formStyle={formStyle} />
	);
}
