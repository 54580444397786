import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getRelatedContactsAndDealKey } from "../../../../utils/queryKeys";
import { getRelatedContactsAndDeal } from "../../../../api/email";

const useRelatedContactsAndDeal = (threadId) => {
	const relatedContactsAndDealKey = getRelatedContactsAndDealKey(threadId);
	return useQuery(
		relatedContactsAndDealKey,
		() => getRelatedContactsAndDeal(threadId),
		{
			enabled: Boolean(threadId),
		}
	);
};

const useInvalidateRelatedContactsAndDeal = (threadId) => {
	let queryClient = useQueryClient();
	const relatedContactsAndDealKey = getRelatedContactsAndDealKey(threadId);
	return () => {
		queryClient.invalidateQueries(relatedContactsAndDealKey);
	};
};

export { useRelatedContactsAndDeal, useInvalidateRelatedContactsAndDeal };
