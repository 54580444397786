import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { TableCellText, TableHeaderLabel } from "../../../styles/twozo";
import ContactImage from "../../../assets/images/contact/unknownContact.png";
import { useTheme } from "@emotion/react";

export default function Followers() {
	const theme = useTheme();

	const tableHeaders = [
		{ label: "User" },
		{ label: "Role" },
		{ label: "Permissions on this source" },
		{ label: "Updated At" },
	];
	const userList = [
		{
			id: 1,
			name: "John Doe",
			role: "Admin",
			permissions: "Can view, edit, delete",
			updatedAt: "2 days ago",
		},
		{
			id: 2,
			name: "Alice Johnson",
			role: "User",
			permissions: "Can view",
			updatedAt: "Just now",
		},
		{
			id: 3,
			name: "Alexander Maximilian Jonathaihdajs",
			role: "Business management consuldcj",
			permissions: "Can view, edit",
			updatedAt: "1 month ago",
		},
		{
			id: 4,
			name: "Emma White Lorem ipsum dolor sit amet, consectetuer adipiscing elit ",
			role: "User",
			permissions: "Can view",
			updatedAt: "2 years ago",
		},
		{
			id: 5,
			name: "Michael Green",
			role: "Admin",
			permissions: "Can view, edit, delete",
			updatedAt: "3 weeks ago",
		},
		{
			id: 6,
			name: "Sophia Black",
			role: "Guest",
			permissions: "Can view",
			updatedAt: "Just now",
		},
		{
			id: 7,
			name: "Daniel BlueLorem ipsum dolor sit amet, consectetuer adipiscing elit",
			role: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
			permissions: "Can view, edit, delete",
			updatedAt: "2 days ago",
		},
	];

	return (
		<Box>
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							{tableHeaders.map((header, index) => (
								<TableCell key={index} sx={{ width: "20%" }}>
									<Stack
										direction="row"
										alignItems="center"
										spacing={0.5}
									>
										<TableHeaderLabel>
											{header.label}
										</TableHeaderLabel>

										<Box display="flex">
											<Box pt={0.5}>
												{DropDownIcon(
													12,
													12,
													"rgba(0, 0, 0, 0.6)"
												)}
											</Box>
										</Box>
									</Stack>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{userList.map((user) => (
							<TableRow key={user.id}>
								<TableCell>
									{user.name ? (
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<img
												src={ContactImage}
												alt="managerProfile"
												width={26}
												height={26}
											/>
											<TableCellText
												noWrap
												maxWidth="225px"
												sx={{ wordBreak: "break-all" }}
											>
												{user.name}
											</TableCellText>
										</Stack>
									) : (
										<TableCellText>-</TableCellText>
									)}
								</TableCell>

								<TableCell>
									<TableCellText
										sx={{ wordBreak: "break-all" }}
										maxWidth="210px"
										fontWeight={400}
										noWrap
									>
										{user.role}
									</TableCellText>
								</TableCell>

								<TableCell>
									<TableCellText
										style={{
											color: theme.palette.primary.main,
										}}
										fontWeight={500}
									>
										{user.permissions}
									</TableCellText>
								</TableCell>

								<TableCell>
									<TableCellText>
										{user.updatedAt ? user.updatedAt : "-"}
									</TableCellText>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
