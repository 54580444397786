import { getSettingsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const getModulesList = async (data) => {
	let requestData = getModulesListRequest(data);
	return await postSettingsManagementApi(requestData);
};

export const renameModule = async (data) => {
	let requestData = getRenameModuleRequest(data);
	return await postSettingsManagementApi(requestData);
};

export const updateConfigModule = async (data) => {
	let requestData = getUpdateConfigModuleRequest(data);
	return await postSettingsManagementApi(requestData);
};

const getModulesListRequest = () => {
	return {
		type: "list",
		data: {},
	};
};

const getRenameModuleRequest = (data) => {
	return {
		type: "rename",
		data: {
			list: data,
		},
	};
};

const getUpdateConfigModuleRequest = (data) => {
	return {
		type: "updateConfig",
		data: data,
	};
};

const postSettingsManagementApi = async (requestData) => {
	let settingsManagementUrl = getSettingsManagementUrl();
	let response = await apiClient.post(settingsManagementUrl, requestData);
	assertError(response, settingsManagementUrl);
	return response.data.data;
};

const getSettingsManagementUrl = () => {
	return getSettingsUrl() + "/mgmt";
};
