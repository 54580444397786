import { useReducer } from "react";

export const formActions = {
	onChangeFieldValueLabel: "ONCHANGE_FIELD_VALUE_LABEL",
};

export const initialState = {
	fieldValueLabels: {},
};

export const formAuxDataReducer = (state, action) => {
	switch (action.type) {
		case formActions.onChangeFieldValueLabel:
			return {
				...state,
				fieldValueLabels: {
					...state.fieldValueLabels,
					...action.payload,
				},
			};
		default:
			return state;
	}
};

export const useFormAuxDataReducer = () => {
	const [auxiliaryFormData, auxiliaryFormDataDispatcher] = useReducer(
		formAuxDataReducer,
		initialState
	);

	const onChangeFieldValueLabel = (fieldValueLabel) => {
		auxiliaryFormDataDispatcher({
			type: formActions.onChangeFieldValueLabel,
			payload: fieldValueLabel,
		});
	};

	return {
		auxiliaryFormData,
		onChangeFieldValueLabel,
	};
};
