import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../../../Elements/Accordian";
import ShortNotes from "../../../Summary/ShortNotes";
import { modules } from "../../../../utils/common/ModulesName";

export default function DealNotes({ dealId }) {
	const [expanded, setExpanded] = useState(false);

	return (
		<React.Fragment>
			<Box>
				<Accordion
					expanded={expanded}
					onChange={() => setExpanded((expanded) => !expanded)}
				>
					<AccordionSummary
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Typography fontSize={14} fontWeight={600}>
							Notes
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box mx={-1}>
							<ShortNotes
								source={modules.DEAL}
								entityId={dealId}
								isSwipeableSummary={true}
							/>
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>
		</React.Fragment>
	);
}
