import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import AddressBaseComponent from "./AddressBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function AddressComponent({ field, subField, inputRef }) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, value },
					fieldState: { error },
				}) => (
					<AddressBaseComponent
						onChange={onChange}
						value={value}
						subField={subField}
						field={field}
						id={field.id + ""}
						inputRef={inputRef}
						error={error}
						clearErrors={clearErrors}
					/>
				)}
				defaultValue={null}
			></Controller>
		</React.Fragment>
	);
}
