import React from "react";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import { TableCellText } from "../../../../styles/twozo";

export default function DateTimeRange(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TableCellText sx={{ whiteSpace: "nowrap" }}>
				{getDateMonthAndYearFormat(data.from)}{" "}
				{getHourAndMinuteFormat(data.from)} -{" "}
				{getDateMonthAndYearFormat(data.to)}{" "}
				{getHourAndMinuteFormat(data.to)}
			</TableCellText>
		</React.Fragment>
	);
}
