import React from "react";

function MultipleCurrency(width = "20", height = "20", color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.2348 12.1833C16.5348 15.1917 15.1515 16.4083 12.5515 16.1583C12.1348 16.125 11.6848 16.05 11.2015 15.9333L9.80145 15.6C6.32645 14.775 5.25145 13.0583 6.06812 9.57499L6.88478 6.08332C7.05145 5.37499 7.25145 4.75832 7.50145 4.24999C8.47645 2.23332 10.1348 1.69165 12.9181 2.34999L14.3098 2.67499C17.8015 3.49165 18.8681 5.21665 18.0515 8.69999L17.2348 12.1833Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.87375 10.022C9.6603 10.8016 10.0915 11.5936 10.8309 11.7961L12.342 12.2098C12.985 12.3858 13.6571 11.9823 13.8441 11.2991C14.0444 10.5677 13.7955 10.2144 13.3675 9.91581L11.1792 8.40949C10.7512 8.11087 10.5002 7.76565 10.7026 7.02619C10.8875 6.35104 11.5617 5.93949 12.2047 6.11553L13.7158 6.52924C14.4552 6.73169 14.8864 7.52367 14.673 8.30331"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.2578 5.54871L11.2773 12.7825"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5929 17.0583L9.27626 17.4916C5.96793 18.5583 4.22626 17.6666 3.15126 14.3583L2.08459 11.0666C1.01793 7.75827 1.90126 6.00827 5.20959 4.94161L6.52626 4.50827C6.86793 4.39994 7.19293 4.30827 7.50126 4.24994C7.25126 4.75827 7.05126 5.37494 6.88459 6.08327L6.06793 9.57494C5.25126 13.0583 6.32626 14.7749 9.80126 15.5999L11.2013 15.9333C11.6846 16.0499 12.1346 16.1249 12.5513 16.1583C12.0346 16.5083 11.3846 16.7999 10.5929 17.0583Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default MultipleCurrency;
