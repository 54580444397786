import React from "react";
import { TextField } from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function TextComponent({ field, inputRef, ...rest }) {
	const onChangeInterceptor = (event, onChange) => {
		let modifiedEvent = event.target.value.trimStart();
		let value = event.target.value;
		if (rest.type === "number") {
			let parsed = parseInt(value);
			if (isNaN(parsed)) {
				parsed = "";
			}
			modifiedEvent = {
				...event,
				target: { ...event.target, value: parsed },
			};
		}

		onChange(modifiedEvent);
		clearErrors(field.id);
	};

	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, ...renderField },
					fieldState: { error },
				}) => (
					<TextField
						placeholder={field?.placeHolder || "Enter"}
						id={field.id + ""}
						{...renderField}
						{...rest}
						onChange={(event) =>
							onChangeInterceptor(event, onChange)
						}
						inputRef={inputRef}
						error={!!error}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
