import React from "react";
import Conversations from "../../../Summary/Conversations";
import { modules } from "../../../../utils/common/ModulesName";

export default function CompanyConversations(props) {
	const { companyId } = props;

	return (
		<React.Fragment>
			<Conversations sourceName={modules.COMPANY} entityId={companyId} />
		</React.Fragment>
	);
}
