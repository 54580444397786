import React from "react";
import { TableCellText } from "../../../../styles/twozo";

export default function Monetary(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TableCellText>{`${data.symbol} ${data.value}`}</TableCellText>
		</React.Fragment>
	);
}
