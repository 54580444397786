const syncDateList = [
	{
		name: "Last One Month",
		value: 1,
	},
	{
		name: "Last Three Months",
		value: 2,
	},
	{
		name: "Last Six Months",
		value: 3,
	},
];

export default syncDateList;
