import React from "react";
import AddTerritoryDialog from "../AddTerritoryDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useCreateTerritory } from "../../../../../hooks/services/userManagement/territory";

export default function CreateTerritory(props) {
	const { onClose } = props;
	const createTerritoryMutation = useCreateTerritory();

	const createTerritory = (territoryData) => {
		createTerritoryMutation.mutate(territoryData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.territoryCreated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<AddTerritoryDialog
				title="Create Territory"
				onSave={createTerritory}
				onClose={onClose}
				isSaving={createTerritoryMutation.isLoading}
			/>
		</React.Fragment>
	);
}
