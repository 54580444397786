import { Box, Checkbox, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldContext } from "../../FieldContext";

export default function FieldView(props) {
	const { field, isFieldEditingRestricted } = props;

	// field context
	const {
		fieldState,
		onAddViewChange,
		onRequiredFieldChange,
		shouldShowFieldActions,
	} = useFieldContext(field);

	const [isAddView, setIsAddView] = useState(!!field.config.addView);
	const [isFieldRequired, setIsFieldRequired] = useState(
		!!field.config.required
	);

	useEffect(() => {
		if (fieldState.unSavedFieldData) {
			if (fieldState.unSavedFieldData.isAddView !== undefined) {
				setIsAddView(!!fieldState.unSavedFieldData.isAddView);
			}
			if (fieldState.unSavedFieldData.isRequired !== undefined) {
				setIsFieldRequired(!!fieldState.unSavedFieldData.isRequired);
			}
		}
	}, [
		fieldState.unSavedFieldData.isAddView,
		fieldState.unSavedFieldData.isRequired,
	]);

	const setFieldRequiredValue = (value) => {
		if (isAddViewAndRequiredDisabled()) {
			return;
		}

		setIsFieldRequired(value);
		if (value && !isAddView) {
			setIsAddView(true);
			onAddViewChange(true);
		}
		onRequiredFieldChange(value);
	};

	const setAddViewValue = (value) => {
		if (isAddViewAndRequiredDisabled() || isFieldRequired) {
			return;
		}

		setIsAddView(value);
		onAddViewChange(value);
	};

	const isAddViewAndRequiredDisabled = () => {
		let isAutoGeneratedField = field.config.readOnly;
		let isImmutableField = field.config.immutable;
		return (
			isFieldEditingRestricted || isAutoGeneratedField || isImmutableField
		);
	};

	return (
		<React.Fragment>
			{shouldShowFieldActions && (
				<Box width="15%">
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Checkbox
							checked={!!isAddView}
							sx={{
								opacity:
									isAddViewAndRequiredDisabled() ||
									isFieldRequired
										? 0.6
										: 1,
								cursor:
									isAddViewAndRequiredDisabled() ||
									isFieldRequired
										? "not-allowed"
										: "pointer",
							}}
							onChange={(_, value) => setAddViewValue(value)}
						/>
						<Typography fontSize={14}>Add View</Typography>
					</Stack>
				</Box>
			)}

			{shouldShowFieldActions && (
				<Box width="15%">
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Checkbox
							checked={!!isFieldRequired}
							sx={{
								opacity: isAddViewAndRequiredDisabled()
									? 0.6
									: 1,
								cursor: isAddViewAndRequiredDisabled()
									? "not-allowed"
									: "pointer",
							}}
							onChange={(_, value) =>
								setFieldRequiredValue(value)
							}
						/>
						<Typography fontSize={14}>Required</Typography>
					</Stack>
				</Box>
			)}
		</React.Fragment>
	);
}
