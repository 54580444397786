import React from "react";

export default function ImportUser(width = 24, height = 24, color = "#292D32") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.5 19.5H14.5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.5 21.5V17.5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.83 10.87C9.44998 10.79 7.55994 8.84 7.55994 6.44C7.54998 3.99 9.53998 2 11.99 2C14.44 2 16.43 3.99 16.43 6.44C16.43 8.84 14.53 10.79 12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.99 21.8101C10.17 21.8101 8.36004 21.3501 6.98004 20.4301C4.56004 18.8101 4.56004 16.1701 6.98004 14.5601C9.73004 12.7201 14.24 12.7201 16.99 14.5601"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
