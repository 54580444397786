import React, { useState } from "react";
import {
	Box,
	Stack,
	TextField,
	Typography,
	createFilterOptions,
	useTheme,
	Chip,
} from "@mui/material";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import Autocomplete from "../../../../Elements/Autocomplete";

export default function TemplateTagsInput(props) {
	const { tagsList, selectedTags, handleSelectTags } = props;

	const theme = useTheme();

	const [tagInputValue, setTagInputValue] = useState("");

	const handleTagInputChange = (event) => {
		const { value } = event.target;
		setTagInputValue(value.trim() ? value : "");
	};

	const filteredValues = createFilterOptions({
		matchFrom: "start",
		stringify: (option) => option,
	});

	const filterOptions = (options, params) => {
		const { inputValue } = params;
		const filteredOption = filteredValues(options, params);
		const isExistingTagName = options?.some((option) => {
			return option.toLowerCase() === inputValue.toLowerCase();
		});

		if (inputValue !== "" && !isExistingTagName) {
			filteredOption.push(tagInputValue);
		}
		return filteredOption;
	};

	const onChangeTagsValue = (_, values) => {
		handleSelectTags(values);
		setTagInputValue("");
	};

	const isDropdownMenuOpen = () => {
		return tagInputValue.length > 0;
	};

	return (
		<React.Fragment>
			<Autocomplete
				options={tagsList?.map((tag) => tag?.name)}
				filterOptions={filterOptions}
				open={isDropdownMenuOpen()}
				value={selectedTags}
				onChange={onChangeTagsValue}
				filterSelectedOptions={false}
				renderTags={(value, getTagProps) =>
					value?.map((option, index) => {
						const { key, ...tagProps } = getTagProps({ index });
						return (
							<Chip
								key={key}
								size="small"
								label={
									<Typography fontSize={14} color="#000">
										{option}
									</Typography>
								}
								color="secondary"
								deleteIcon={CloseIcon(16, 16, "#000")}
								sx={{
									borderRadius: "8px",
								}}
								{...tagProps}
							/>
						);
					})
				}
				renderOption={(props, option) => (
					<Box
						key={option}
						{...props}
						style={{
							minHeight: "40px",
							width: "100%",
						}}
					>
						{option === tagInputValue ? (
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								width="100%"
							>
								<Typography
									fontSize="13px"
									fontWeight={500}
									color={theme.palette.primary.main}
								>
									{option}
								</Typography>

								<Box display="flex">
									{AddIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</Box>
							</Stack>
						) : (
							<Typography fontSize={14}>{option}</Typography>
						)}
					</Box>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						value={tagInputValue}
						onChange={handleTagInputChange}
						placeholder="Enter tag name to select"
						inputProps={{
							...params.inputProps,
							style: {
								fontSize: "14px",
							},
						}}
						onBlur={() => setTagInputValue("")}
					/>
				)}
			/>
		</React.Fragment>
	);
}
