import { getPipelineUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const getStagesByPipeline = async (pipelineId) => {
	let requestData = getStagesByPipelineRequest(pipelineId);
	return await postPipelineStageManagement(requestData);
};

export const getPipelineListData = async () => {
	let request = getPipelineListDataRequest();
	let response = await postPipelineManagement(request);
	return response;
};

export const createPipeline = async (data) => {
	let request = getCreatePipelineRequest(data);
	let response = await postPipelineManagement(request);
	return response;
};

export const updatePipeline = async (data) => {
	let request = getUpdatePipelineRequest(data);
	let response = await postPipelineManagement(request);
	return response;
};

export const deletePipeline = async (id) => {
	let request = deletePipelineRequest(id);
	let response = await postPipelineManagement(request);
	return response;
};

export const updatePipelineOrder = async (ids) => {
	let requestData = getPipelineOrderRequest(ids);
	return await postPipelineManagement(requestData);
};

export const updatePipelineStageOrder = async (data) => {
	let requestData = getPipelineStageOrderRequest(data);
	return await postPipelineStageManagement(requestData);
};

export const updatePipelineStage = async (data) => {
	let requestData = getPipelineStageRequest(data);
	return await postPipelineStageManagement(requestData);
};

export const deletePipelineStage = async (id) => {
	let requestData = getDeletePipelineStageRequest(id);
	return await postPipelineStageManagement(requestData);
};

export const updateDefaultPipeline = async (data) => {
	let requestData = getUpdateDefaultPipelineRequest(data);
	return await postPipelineManagement(requestData);
};

const getPipelineListDataRequest = () => {
	return {
		type: "list",
		data: {},
	};
};

const getCreatePipelineRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getUpdatePipelineRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const deletePipelineRequest = (id) => {
	return {
		type: "deletePipeline",
		data: {
			pipelineId: id,
		},
	};
};

const getPipelineOrderRequest = (ids) => {
	return {
		type: "updateOrder",
		data: {
			ids: ids,
		},
	};
};

const getPipelineStageOrderRequest = (data) => {
	return {
		type: "updateOrder",
		data: data,
	};
};

const getPipelineStageRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getDeletePipelineStageRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getUpdateDefaultPipelineRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getStagesByPipelineRequest = (pipelineId) => {
	return {
		type: "getStagesByPipeline",
		data: {
			id: pipelineId,
		},
	};
};

const postPipelineManagement = async (request) => {
	let requestUrl = getPipelineManagementUrl();
	let response = await apiClient.post(requestUrl, request);
	assertError(response);
	return response.data.data;
};

const postPipelineStageManagement = async (request) => {
	let requestUrl = getPipelineStageManagementUrl();
	let response = await apiClient.post(requestUrl, request);
	assertError(response);
	return response.data.data;
};

export const getPipelineManagementUrl = () => {
	return getPipelineUrl() + "/mgmt";
};

export const getPipelineStageManagementUrl = () => {
	return getPipelineUrl() + "/stage/mgmt";
};
