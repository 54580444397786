import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import DeleteDialog from "../../../Elements/DeleteDialog";
import {
	useFieldList,
	useDeleteField,
	useUpdateFieldListOrder,
} from "../../../../hooks/services/dataFields";
import { useModuleList } from "../../../../hooks/services/common";
import { useFieldListContext } from "../FieldListContext";
import FieldGroup from "../FieldGroup";

export default function FieldList(props) {
	const { newFieldCreated, clearCreatedField, fieldTypeOption, moduleName } =
		props;
	const needGroupView = true;

	//query call:-
	const { data: moduleListData } = useModuleList();

	// mutation call :-
	const deleteMutation = useDeleteField(moduleName);
	const updateFieldListOrder = useUpdateFieldListOrder(moduleName);

	const [fieldList, setFieldList] = useState([]);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [selectedField, setSelectedField] = useState({});

	const successNotificationTitle = "Success!";
	newFieldCreated.reverse();

	const {
		status,
		isLoading,
		error,
		data: fieldData,
	} = useFieldList(moduleName, needGroupView);

	const { fieldListState } = useFieldListContext();

	useEffect(() => {
		if (status === "success") {
			if (fieldData) {
				let fields = [];
				fieldData.forEach((field) => {
					let filteredHiddenFields = field.filter(
						(field) => !field.config.isHidden
					);
					if (filteredHiddenFields.length > 0) {
						fields.push(filteredHiddenFields);
					}
				});

				if (newFieldCreated.length > 0) {
					setFieldList([...fields, newFieldCreated]);
				} else {
					setFieldList([...fields]);
				}
			}
		}
	}, [fieldData, status, newFieldCreated]);

	const handleFieldDelete = () => {
		deleteMutation.mutate(selectedField?.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.fieldDeleted,
				});
				setIsDeleteDialogOpened(false);
				setSelectedField({});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const handleOpenDeleteDialog = (field) => {
		setIsDeleteDialogOpened(true);
		setSelectedField(field);
	};

	const handleFieldCancel = () => {
		setIsDeleteDialogOpened(false);
		setSelectedField({});
	};

	const getSourceId = () => {
		let module = moduleListData?.find(
			(module) =>
				module.sourceName.toLowerCase() === moduleName.toLowerCase()
		);
		if (module) {
			return module?.sourceId;
		}
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result) => {
		if (
			!result.destination ||
			fieldList[result.destination.index]?.[0]?.config.isImmovable
		) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const updatedData = reorder(
			fieldList,
			result.source.index,
			result.destination.index
		);
		setFieldList(updatedData);

		let orderChangedFieldIds = getOrderChangedFieldIds(updatedData);
		let sourceId = getSourceId();

		updateFieldListOrder.mutate(
			{ sourceId, ids: orderChangedFieldIds },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.fieldUpdated,
					});
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ?? notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const getOrderChangedFieldIds = (fields) => {
		let orderChangedFieldIds = fields.flatMap((fieldGroup) => {
			return fieldGroup
				.filter((field) => !field.config.isImmovable)
				.map((field) => field.id);
		});

		return orderChangedFieldIds;
	};

	const updateFieldList = (fieldType, fieldName, fieldIndex) => {
		let updatedFieldList = fieldList.map((fields, index) => {
			if (index === fieldIndex) {
				return fields.map((field) => {
					return {
						...field,
						type: fieldType,
						fieldName: fieldName,
					};
				});
			}

			return fields;
		});

		setFieldList(updatedFieldList);
	};

	const isFieldDraggable = (field) => {
		return (
			field.config.isImmovable ||
			fieldListState.isAnyFieldInEditMode ||
			fieldListState.isAnyFieldInCreateMode
		);
	};

	//TODO Loader Component to be rendered here
	if (error) return "An error has occurred: " + error;

	return (
		<React.Fragment>
			{isLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
				>
					<CircularProgress />
				</Stack>
			) : null}

			<DeleteDialog
				title={`Are you sure you want to delete the “${selectedField?.fieldName}” field?`}
				subtitle="Deleting this field deletes all data stored in this field."
				open={isDeleteDialogOpened}
				onCancel={handleFieldCancel}
				onDelete={handleFieldDelete}
				disableDeleteButton={deleteMutation.isLoading}
			/>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="field-list">
					{(provided) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{fieldList.map((field, index) => (
								<Draggable
									draggableId={field?.[0]?.id?.toString()}
									index={index}
									key={field?.[0]?.id?.toString()}
									isDragDisabled={isFieldDraggable(field[0])}
								>
									{(provided) => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											sx={{ marginBottom: "8px" }}
										>
											<FieldGroup
												field={field}
												changeFieldType={(
													fieldType,
													fieldName
												) =>
													updateFieldList(
														fieldType,
														fieldName,
														index
													)
												}
												moduleName={moduleName}
												clearCreatedField={
													clearCreatedField
												}
												dragHandleProps={
													provided.dragHandleProps
												}
												handleOpenDeleteDialog={
													handleOpenDeleteDialog
												}
												fieldTypeOption={
													fieldTypeOption
												}
											/>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</React.Fragment>
	);
}
