import React from "react";
import AddTeamDialog from "../AddTeamDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useCreateTeam } from "../../../../../hooks/services/userManagement/teams";

export default function CreateTeam(props) {
	const { onClose } = props;
	const createTeamMutation = useCreateTeam();

	const createTeam = (teamData) => {
		createTeamMutation.mutate(teamData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.teamCreated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<AddTeamDialog
				title="Create Team"
				onClose={onClose}
				onSave={createTeam}
				isSaving={createTeamMutation.isLoading}
			/>
		</React.Fragment>
	);
}
