import { useWorkflowActionsList } from "../../../../../../hooks/services/workflow";
import FilterBaseComponent from "../FilterBaseComponent";

export default function ActionFilter({ styles, criteria, handleUpdateFilter }) {
	const { data: actionsList } = useWorkflowActionsList();

	return (
		<>
			<FilterBaseComponent
				styles={styles}
				criteria={criteria}
				handleUpdateFilter={handleUpdateFilter}
				filterValuesList={actionsList || []}
				filterName="Actions"
			/>
		</>
	);
}
