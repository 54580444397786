const countryCodeList = [
	{ code: "+971", name: "United Arab Emirates", value: 1 },
	{
		code: "+93",
		name: "Afghanistan",
		value: 2,
	},
	{
		code: "+355",
		name: "Albania",
		value: 3,
	},
	{
		code: "+374",
		name: "Armenia",
		value: 4,
	},
	{
		code: "+244",
		name: "Angola",
		value: 5,
	},
	{
		code: "+61",
		name: "Australia",
		value: 6,
	},
	{
		code: "+686",
		name: "Kiribati",
		value: 7,
	},
	{
		code: "+674",
		name: "Nauru",
		value: 8,
	},
	{
		code: "+688",
		name: "Tuvalu",
		value: 9,
	},
	{
		code: "+994",
		name: "Azerbaijan",
		value: 10,
	},
	{
		code: "+387",
		name: "Bosnia and Herzegovina",
		value: 11,
	},
	{
		code: "+1",
		name: "Barbados",
		value: 12,
	},
	{
		code: "+880",
		name: "Bangladesh",
		value: 13,
	},
	{
		code: "+359",
		name: "Bulgaria",
		value: 14,
	},
	{
		code: "+973",
		name: "Bahrain",
		value: 15,
	},
	{
		code: "+257",
		name: "Burundi",
		value: 16,
	},
	{
		code: "+673",
		name: "Brunei",
		value: 17,
	},
	{
		code: "+591",
		name: "Bolivia",
		value: 18,
	},
	{
		code: "+55",
		name: "Brazil",
		value: 19,
	},
	{
		code: "+1",
		name: "Bahamas",
		value: 20,
	},
	{
		code: "+975",
		name: "Bhutan",
		value: 21,
	},
	{
		code: "+267",
		name: "Botswana",
		value: 22,
	},
	{
		code: "+375",
		name: "Belarus",
		value: 23,
	},
	{
		code: "+501",
		name: "Belize",
		value: 24,
	},
	{
		code: "+1",
		name: "Canada",
		value: 25,
	},
	{
		code: "+243",
		name: "Democratic Republic of Congo",
		value: 26,
	},
	{
		code: "+423",
		name: "Liechtenstein",
		value: 27,
	},
	{
		code: "+56",
		name: "Chile",
		value: 28,
	},
	{
		code: "+86",
		name: "China",
		value: 29,
	},
	{
		code: "+57",
		name: "Colombia",
		value: 30,
	},
	{
		code: "+506",
		name: "CRC Costa Rica",
		value: 31,
	},
	{
		code: "+53",
		name: "Cuba",
		value: 32,
	},
	{
		code: "+238",
		name: "Cape Verde",
		value: 33,
	},
	{
		code: "+420",
		name: "Czech Republic",
		value: 34,
	},
	{
		code: "+253",
		name: "Djibouti",
		value: 35,
	},
	{
		code: "+45",
		name: "Denmark",
		value: 36,
	},
	{
		code: "+1",
		name: "Dominican Republic",
		value: 37,
	},
	{
		code: "+213",
		name: "Algeria",
		value: 38,
	},
	{
		code: "+20",
		name: "Egypt",
		value: 39,
	},
	{
		code: "+291",
		name: "Eritrea",
		value: 40,
	},
	{
		code: "+251",
		name: "Ethiopia",
		value: 41,
	},
	{
		code: "+376",
		name: "Andorra",
		value: 42,
	},
	{
		code: "+43",
		name: "Austria",
		value: 43,
	},
	{
		code: "+32",
		name: "Belgium",
		value: 44,
	},
	{
		code: "+357",
		name: "Cyprus",
		value: 45,
	},
	{
		code: "+372",
		name: "Estonia",
		value: 46,
	},
	{
		code: "+358",
		name: "Finland",
		value: 47,
	},
	{
		code: "+33",
		name: "France",
		value: 48,
	},
	{
		code: "+49",
		name: "Germany",
		value: 49,
	},
	{
		code: "+30",
		name: "Greece",
		value: 50,
	},
	{
		code: "+353",
		name: "Ireland",
		value: 51,
	},
	{
		code: "+39",
		name: "Italy",
		value: 52,
	},
	{
		code: "+383",
		name: "Kosovo",
		value: 53,
	},
	{
		code: "+371",
		name: "Latvia",
		value: 54,
	},
	{
		code: "+370",
		name: "Lithuania",
		value: 55,
	},
	{
		code: "+352",
		name: "Luxembourg",
		value: 56,
	},
	{
		code: "+356",
		name: "Malta",
		value: 57,
	},
	{
		code: "+377",
		name: "Monaco",
		value: 58,
	},
	{
		code: "+382",
		name: "Montenegro",
		value: 59,
	},
	{
		code: "+31",
		name: "Netherlands",
		value: 60,
	},
	{
		code: "+351",
		name: "Portugal",
		value: 61,
	},
	{
		code: "+378",
		name: "San Marino",
		value: 62,
	},
	{
		code: "+421",
		name: "Slovakia",
		value: 63,
	},
	{
		code: "+386",
		name: "Slovenia",
		value: 64,
	},
	{
		code: "+34",
		name: "Spain",
		value: 65,
	},
	{
		code: "+379",
		name: "Vatican City",
		value: 66,
	},
	{
		code: "+679",
		name: "Fiji",
		value: 67,
	},
	{
		code: "+44",
		name: "United Kingdom",
		value: 68,
	},
	{
		code: "+995",
		name: "Georgia",
		value: 69,
	},
	{
		code: "+233",
		name: "Ghana",
		value: 70,
	},
	{
		code: "+220",
		name: "Gambia",
		value: 71,
	},
	{
		code: "+224",
		name: "Guinea",
		value: 72,
	},
	{
		code: "+502",
		name: "Guatemala",
		value: 73,
	},
	{
		code: "+592",
		name: "Guyana",
		value: 74,
	},
	{
		code: "+504",
		name: "Honduras",
		value: 75,
	},
	{
		code: "+385",
		name: "Croatia",
		value: 76,
	},
	{
		code: "+509",
		name: "Haiti",
		value: 77,
	},
	{
		code: "+36",
		name: "Hungary",
		value: 78,
	},
	{
		code: "+62",
		name: "Indonesia",
		value: 79,
	},
	{
		code: "+972",
		name: "Israel",
		value: 80,
	},
	{
		code: "+970",
		name: "Palestine",
		value: 81,
	},
	{
		code: "+91",
		name: "India",
		value: 82,
	},
	{
		code: "+964",
		name: "Iraq",
		value: 83,
	},
	{
		code: "+98",
		name: "Iran",
		value: 84,
	},
	{
		code: "+354",
		name: "Iceland",
		value: 85,
	},
	{
		code: "+1",
		name: "Jamaica",
		value: 86,
	},
	{
		code: "+962",
		name: "Jordan",
		value: 87,
	},
	{
		code: "+81",
		name: "Japan",
		value: 88,
	},
	{
		code: "+254",
		name: "Kenya",
		value: 89,
	},
	{
		code: "+996",
		name: "Kyrgyzstan",
		value: 90,
	},
	{
		code: "+855",
		name: "Cambodia",
		value: 91,
	},
	{
		code: "+269",
		name: "Comoros",
		value: 92,
	},
	{
		code: "+850",
		name: "Korea, North",
		value: 93,
	},
	{
		code: "+82",
		name: "Korea, South",
		value: 94,
	},
	{
		code: "+965",
		name: "Kuwait",
		value: 95,
	},
	{
		code: "+7",
		name: "Kazakhstan",
		value: 96,
	},
	{
		code: "+856",
		name: "Laos",
		value: 97,
	},
	{
		code: "+961",
		name: "Lebanon",
		value: 98,
	},
	{
		code: "+94",
		name: "Sri Lanka",
		value: 99,
	},
	{
		code: "+231",
		name: "Liberia",
		value: 100,
	},
	{
		code: "+266",
		name: "Lesotho",
		value: 101,
	},
	{
		code: "+218",
		name: "Libya",
		value: 102,
	},
	{
		code: "+212",
		name: "Morocco",
		value: 103,
	},
	{
		code: "+373",
		name: "Moldova",
		value: 104,
	},
	{
		code: "+261",
		name: "Madagascar",
		value: 105,
	},
	{
		code: "+389",
		name: "North Macedonia",
		value: 106,
	},
	{
		code: "+95",
		name: "Myanmar",
		value: 107,
	},
	{
		code: "+976",
		name: "Mongolia",
		value: 108,
	},
	{
		code: "+222",
		name: "Mauritania",
		value: 109,
	},
	{
		code: "+230",
		name: "Mauritius",
		value: 110,
	},
	{
		code: "+960",
		name: "Maldives",
		value: 111,
	},
	{
		code: "+265",
		name: "Malawi",
		value: 112,
	},
	{
		code: "+52",
		name: "Mexico",
		value: 113,
	},
	{
		code: "+60",
		name: "Malaysia",
		value: 114,
	},
	{
		code: "+258",
		name: "Mozambique",
		value: 115,
	},
	{
		code: "+264",
		name: "namibia",
		value: 116,
	},
	{
		code: "+234",
		name: "Nigeria",
		value: 117,
	},
	{
		code: "+505",
		name: "Nicaragua",
		value: 118,
	},
	{
		code: "+47",
		name: "Norway",
		value: 119,
	},
	{
		code: "+977",
		name: "Nepal",
		value: 120,
	},
	{
		code: "+64",
		name: "New Zealand",
		value: 121,
	},
	{
		code: "+968",
		name: "Oman",
		value: 122,
	},
	{
		code: "+507",
		name: "panama",
		value: 123,
	},
	{
		code: "+51",
		name: "Peru",
		value: 124,
	},
	{
		code: "+675",
		name: "Papua New Guinea",
		value: 125,
	},
	{
		code: "+63",
		name: "Philippines",
		value: 126,
	},
	{
		code: "+92",
		name: "Pakistan",
		value: 127,
	},
	{
		code: "+48",
		name: "Poland",
		value: 128,
	},
	{
		code: "+595",
		name: "Paraguay",
		value: 129,
	},
	{
		code: "+974",
		name: "Qatar",
		value: 130,
	},
	{
		code: "+40",
		name: "Romania",
		value: 131,
	},
	{
		code: "+381",
		name: "Serbia",
		value: 132,
	},
	{
		code: "+7",
		name: "Russia",
		value: 133,
	},
	{
		code: "+250",
		name: "Rwanda",
		value: 134,
	},
	{
		code: "+966",
		name: "Saudi Arabia",
		value: 135,
	},
	{
		code: "+677",
		name: "Solomon Islands",
		value: 136,
	},
	{
		code: "+248",
		name: "Seychelles",
		value: 137,
	},
	{
		code: "+249",
		name: "sudan",
		value: 138,
	},
	{
		code: "+46",
		name: "Sweden",
		value: 139,
	},
	{
		code: "+65",
		name: "Singapore",
		value: 140,
	},
	{
		code: "+232",
		name: "Sierra Leone",
		value: 141,
	},
	{
		code: "+252",
		name: "Somalia",
		value: 142,
	},
	{
		code: "+597",
		name: "Suriname",
		value: 143,
	},
	{
		code: "+211",
		name: "South Sudan",
		value: 144,
	},
	{
		code: "+239",
		name: "Sao Tome And Principe",
		value: 145,
	},
	{
		code: "+963",
		name: "Syria",
		value: 146,
	},
	{
		code: "+268",
		name: "Eswatini",
		value: 147,
	},
	{
		code: "+66",
		name: "Thailand",
		value: 148,
	},
	{
		code: "+992",
		name: "Tajikistan",
		value: 149,
	},
	{
		code: "+993",
		name: "Turkmenistan",
		value: 150,
	},
	{
		code: "+216",
		name: "Tunisia",
		value: 151,
	},
	{
		code: "+676",
		name: "Tonga",
		value: 152,
	},
	{
		code: "+90",
		name: "Turkey",
		value: 153,
	},
	{
		code: "+1",
		name: "TrinvalueadAndTobago",
		value: 154,
	},
	{
		code: "+886",
		name: "Taiwan",
		value: 155,
	},
	{
		code: "+255",
		name: "Tanzania",
		value: 156,
	},
	{
		code: "+380",
		name: "Ukraine",
		value: 157,
	},
	{
		code: "+256",
		name: "Uganda",
		value: 158,
	},
	{
		code: "+670",
		name: "East Timor",
		value: 159,
	},
	{
		code: "+593",
		name: "Ecuador",
		value: 160,
	},
	{
		code: "+503",
		name: "El Salvador",
		value: 161,
	},
	{
		code: "+692",
		name: "Marshall Islands",
		value: 162,
	},
	{
		code: "+691",
		name: "Micronesia",
		value: 163,
	},
	{
		code: "+680",
		name: "Palau",
		value: 164,
	},
	{
		code: "+1",
		name: "United States",
		value: 165,
	},
	{
		code: "+231",
		name: "Zimbabwe",
		value: 166,
	},
	{
		code: "+598",
		name: "Uruguay",
		value: 167,
	},
	{
		code: "+998",
		name: "Uzbekistan",
		value: 168,
	},
	{
		code: "+58",
		name: "Venezuela",
		value: 169,
	},
	{
		code: "+84",
		name: "Vietnam",
		value: 170,
	},
	{
		code: "+678",
		name: "Vanuatu",
		value: 171,
	},
	{
		code: "+685",
		name: "Samoa",
		value: 172,
	},
	{
		code: "+237",
		name: "Cameroon",
		value: 173,
	},
	{
		code: "+236",
		name: "Central African Republic",
		value: 174,
	},
	{
		code: "+235",
		name: "Chad",
		value: 175,
	},
	{
		code: "+240",
		name: "Equatorial Guinea",
		value: 176,
	},
	{
		code: "+241",
		name: "Gabon",
		value: 177,
	},
	{
		code: "+242",
		name: "Republic Of The Congo",
		value: 178,
	},
	{
		code: "+1",
		name: "Antigua And Barbuda",
		value: 179,
	},
	{
		code: "+1",
		name: "Dominica",
		value: 180,
	},
	{
		code: "+1",
		name: "Grenada",
		value: 181,
	},
	{
		code: "+1",
		name: "Saint Kitts And Nevis",
		value: 182,
	},
	{
		code: "+1",
		name: "Saint Lucia",
		value: 183,
	},
	{
		code: "+1",
		name: "Saint Vincent And The Grenadines",
		value: 184,
	},
	{
		code: "+229",
		name: "benin",
		value: 185,
	},
	{
		code: "+226",
		name: "Burkina Faso",
		value: 186,
	},
	{
		code: "+245",
		name: "guineaBissau",
		value: 187,
	},
	{
		code: "+225",
		name: "Ivory Coast",
		value: 188,
	},
	{
		code: "+223",
		name: "Mali",
		value: 189,
	},
	{
		code: "+227",
		name: "niger",
		value: 190,
	},
	{
		code: "+21",
		name: "senegal",
		value: 191,
	},
	{
		code: "228",
		name: "Togo",
		value: 192,
	},
	{
		code: "+967",
		name: "Yemen",
		value: 193,
	},
	{
		code: "+27",
		name: "South Africa",
		value: 194,
	},
	{
		code: "+260",
		name: "Zambia",
		value: 195,
	},
];

export default countryCodeList;
