import { Box, IconButton, Stack, Typography } from "@mui/material";
import { default as CompanyIcon } from "../../../../assets/icons/company";
import React from "react";
import { styles } from "../../styles";

export default function CompanyMenu(props) {
	const { companyDetails } = props;

	return (
		<React.Fragment>
			<Box minWidth="320px">
				<Stack direction="row" p={2} spacing={4} alignItems="center">
					<Box display="flex">
						<IconButton size="small">
							{CompanyIcon(25, 25, "#000", 0.6)}
						</IconButton>
					</Box>

					<Box>
						<Typography style={styles.cardTitle}>
							{companyDetails?.name}
						</Typography>

						<Typography style={styles.text}>
							{companyDetails?.website}
						</Typography>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
