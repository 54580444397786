import React, { useState, useEffect, useCallback } from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	TableCellText,
	TableHeaderLabel,
	TextButton,
	Tooltip,
} from "../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as TickIcon } from "../../../../assets/icons/tick";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as LeftArrowIcon } from "../../../../assets/icons/leftArrow";
import {
	deleteProductPrice,
	getProductPriceList,
	updateProductPrice,
} from "../../../../api/product/prices/pricesApi";
import CurrencyMenu from "../../../Elements/CurrencyMenu";
import {
	getProductDataKey,
	getProductPriceListKey,
} from "../../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useCurrencies } from "../../../../hooks/settings/currency";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../../hooks/auth";

export default function Prices(props) {
	const { productId } = props;
	const theme = useTheme();
	const queryClient = useQueryClient();

	const [hoveredTableRowId, setHoveredTableRowId] = useState(null);
	const [priceList, setPriceList] = useState([]);
	const [newPriceData, setNewPriceData] = useState([]);

	const [editedRowId, setEditedRowId] = useState(null);
	const [editedRowData, setEditedRowData] = useState({});
	const [isPriceChanged, setIsPriceChanged] = useState(false);
	const [isCurrencyChanged, setIsCurrencyChanged] = useState(false);
	const [selectedTableCellId, setSelectedTableCellId] = useState(null);
	const [isAddNewButtonEnabled, setIsAddNewButtonEnabled] = useState(true);
	const [isAddPriceTooltipOpened, setIsAddPriceTooltipOpened] =
		useState(false);

	const { activeCurrencyList, getCurrencyDataById } = useCurrencies();

	const [selectedCurrencies, setSelectedCurrencies] = useState([]);

	const productPriceListKey = getProductPriceListKey(productId);
	const productDataKey = getProductDataKey(productId);
	const successNotificationTitle = "Success!";

	const { isUserAllowedFor } = useAuth();

	const {
		status: productPriceApiStatus,
		isLoading: isLoadingPriceData,
		data: productPriceApiData,
	} = useQuery(productPriceListKey, () => getProductPriceList(productId), {
		staleTime: 60000,
		enabled: !!productId,
	});

	useEffect(() => {
		if (productPriceApiStatus === "success") {
			if (Array.isArray(productPriceApiData?.list)) {
				setPriceList([...productPriceApiData.list, ...newPriceData]);
			}
		}
	}, [productPriceApiData, productPriceApiStatus, newPriceData]);

	useEffect(() => {
		let currenciesFromPriceData = [];

		for (let index = 0; index < priceList.length; index++) {
			currenciesFromPriceData.push(priceList[index].currency.currencyId);
		}

		setSelectedCurrencies(currenciesFromPriceData);
	}, [priceList]);

	const getCurrencyOptionsForPriceTag = (priceData) => {
		return activeCurrencyList?.filter(
			(currency) =>
				currency.id === priceData.currency.currencyId ||
				!selectedCurrencies.includes(currency.id)
		);
	};

	const onMouseOverPriceTableRow = (id) => !!id && setHoveredTableRowId(id);
	const onMouseOutPriceTableRow = () => setHoveredTableRowId(null);

	const handleEditPrice = (priceData, index) => {
		setEditedRowId(priceData.id);
		setSelectedTableCellId(`currency_${index}`);

		setEditedRowData({ ...priceData });
	};

	const noCurrencyToAddPrice = () => {
		return activeCurrencyList?.length === priceList.length;
	};

	const isPriceAdditionRestricted = () => {
		return !isAddNewButtonEnabled || editedRowId || noCurrencyToAddPrice();
	};

	const handleAddPrice = () => {
		if (isPriceAdditionRestricted()) {
			return;
		}

		setNewPriceData((newPriceData) => {
			return [...newPriceData, getAddPrice()];
		});
		setSelectedTableCellId(`currency_${priceList.length}`);
		setIsAddNewButtonEnabled(false);
	};

	const getAddPrice = () => {
		return {
			currency: {
				currencyId: "",
			},
			price: "",
			tax: "",
			discount: "",
			isPriceNotSaved: true,
		};
	};

	const handlePriceInputChange = (event, index) => {
		const { name: fieldName, value } = event.target;
		priceList[index][fieldName] = value;
		setPriceList([...priceList]);

		if (!priceList[index].isPriceNotSaved) {
			if (editedRowData[fieldName].toString() === value) {
				setIsPriceChanged(false);
			} else {
				setIsPriceChanged(true);
			}
		}
	};

	const handleTableCell = (event) => {
		setSelectedTableCellId(event.currentTarget.id);
	};

	const handleUpdatePrice = (index, priceData) => {
		let requestData = getUpdatedRequestData(priceList[index]);

		updateMutation.mutate(requestData, {
			onSuccess: () => {
				setNewPriceData((newPriceData) =>
					newPriceData.filter((data) => data !== priceData)
				);
				setIsAddNewButtonEnabled(true);
				if (!priceList[index].isPriceNotSaved) {
					setEditedRowId(null);
					setIsPriceChanged(false);
					setIsCurrencyChanged(false);
				}
				setSelectedTableCellId(null);
			},
		});
	};

	const updateMutation = useMutation(
		async (updatedPrice) => updateProductPrice(updatedPrice),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(productPriceListKey);
				queryClient.invalidateQueries(productDataKey);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.pricesUpdated,
				});
			},
		}
	);

	const getUpdatedRequestData = (priceData) => {
		let requestData = {
			productId: productId,
			priceTags: [
				{
					id: priceData.id,
					unitPrice: priceData.price,
					currency: priceData.currency.currencyId,
					tax: priceData.tax,
					discount: priceData.discount,
				},
			],
		};

		return requestData;
	};

	const handleDeleteNewPrice = (priceData) => {
		setNewPriceData((newPriceData) =>
			newPriceData.filter((data) => data !== priceData)
		);

		setSelectedCurrencies((selectedCurrencies) =>
			selectedCurrencies.filter(
				(currencyId) => currencyId !== priceData.currency.id
			)
		);
		setIsAddNewButtonEnabled(true);
	};

	const handleDeletePrice = (priceData) => {
		deleteMutation.mutate(priceData.id, {
			onSuccess: () => {
				setSelectedCurrencies((selectedCurrencies) =>
					selectedCurrencies.filter(
						(currencyId) => currencyId !== priceData.currency.id
					)
				);
			},
		});
	};

	const deleteMutation = useMutation(async (id) => deleteProductPrice(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(productPriceListKey);
			queryClient.invalidateQueries(productDataKey);
			enqueueSnackbar({
				variant: notificationVariants.error,
				title: successNotificationTitle,
				message: notificationMessage.pricesDeleted,
			});
		},
	});

	const styles = {
		editableTableCell: {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
	};

	const getCurrencyCode = useCallback(
		(currencyId) => {
			let currency = getCurrencyDataById(currencyId);

			if (currency) {
				return currency.code;
			}
		},
		[getCurrencyDataById]
	);

	const onCurrencyInputChange = (currency, index) => {
		let oldCurrencyId = priceList[index].currency.currencyId;
		let newCurrencyId = currency.id;

		//remove previous selected currency
		setSelectedCurrencies((selectedCurrencies) =>
			selectedCurrencies.filter((currency) => currency !== oldCurrencyId)
		);
		//add newly selected currency
		setSelectedCurrencies([...selectedCurrencies, newCurrencyId]);

		priceList[index].currency.currencyId = newCurrencyId;

		setPriceList([...priceList]);

		if (!priceList[index].isPriceNotSaved) {
			if (editedRowData.currency === priceList[index].currency) {
				setIsCurrencyChanged(false);
			} else {
				setIsCurrencyChanged(true);
			}
		}
	};

	const RevertEditedPrice = (index) => {
		setEditedRowId(null);
		setIsPriceChanged(false);
		setIsCurrencyChanged(false);
		setSelectedTableCellId(null);

		if (isPriceChanged || isCurrencyChanged) {
			setPriceList((priceList) => {
				priceList.splice(index, 1, editedRowData);
				return [...priceList];
			});
			productPriceApiData?.list?.splice(index, 1, editedRowData);
		}
		setEditedRowData({});
	};

	const isActionsEnable = () => {
		return (
			isUserAllowedFor(PERMISSIONS.product.edit) ||
			isUserAllowedFor(PERMISSIONS.product.delete)
		);
	};

	return (
		<React.Fragment>
			<Box>
				{isLoadingPriceData ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell width="4%"></TableCell>

									<TableCell width="10%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
											pl={2}
										>
											<TableHeaderLabel>
												Currency
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="14%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
											justifyContent="right"
										>
											<TableHeaderLabel>
												Price
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="14%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
											justifyContent="right"
										>
											<TableHeaderLabel>
												Tax %
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>
									<TableCell width="14%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
											justifyContent="right"
										>
											<TableHeaderLabel>
												Discount %
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{priceList?.map((priceData, index) => (
									<TableRow
										key={index}
										onMouseOver={() =>
											onMouseOverPriceTableRow(
												priceData.id
											)
										}
										onMouseOut={() =>
											onMouseOutPriceTableRow()
										}
									>
										<TableCell padding="checkbox">
											<Stack alignItems="center">
												{priceData.isPriceNotSaved ? (
													<Box
														display="flex"
														onClick={() =>
															handleDeleteNewPrice(
																priceData
															)
														}
													>
														{CloseIcon(
															20,
															20,
															"#000",
															"0.6"
														)}
													</Box>
												) : editedRowId ===
												  priceData.id ? (
													<Box
														display="flex"
														onClick={() =>
															RevertEditedPrice(
																index
															)
														}
													>
														{LeftArrowIcon(
															20,
															20,
															"#000",
															"0.6"
														)}
													</Box>
												) : (
													<Checkbox disabled={true} />
												)}
											</Stack>
										</TableCell>

										<TableCell
											id={`currency_${index}`}
											onClick={
												editedRowId === priceData.id ||
												priceData.isPriceNotSaved
													? (event) =>
															handleTableCell(
																event
															)
													: null
											}
											sx={{
												borderBottom:
													`currency_${index}` ===
														selectedTableCellId &&
													styles.editableTableCell,
											}}
										>
											{editedRowId === priceData.id ||
											priceData.isPriceNotSaved ? (
												<CurrencyMenu
													index={index}
													isProductDetailTable={true}
													currencyOptions={getCurrencyOptionsForPriceTag(
														priceData
													)}
													selectedCurrency={
														priceData.currency
															.currencyId
													}
													onCurrencyInputChange={
														onCurrencyInputChange
													}
												/>
											) : (
												<Box
													sx={{ paddingLeft: "16px" }}
												>
													<Typography
														sx={{
															fontSize: "14px",
															fontWeight: 400,
														}}
													>
														{getCurrencyCode(
															priceData.currency
																.currencyId
														)}
													</Typography>
												</Box>
											)}
										</TableCell>

										<TableCell
											align="right"
											id={`price_${index}`}
											onClick={
												editedRowId === priceData.id ||
												priceData.isPriceNotSaved
													? (event) =>
															handleTableCell(
																event
															)
													: null
											}
											sx={{
												borderBottom:
													`price_${index}` ===
														selectedTableCellId &&
													styles.editableTableCell,
											}}
										>
											{editedRowId === priceData.id ||
											priceData.isPriceNotSaved ? (
												<Box>
													<TextField
														variant="standard"
														placeholder="0"
														name="price"
														value={priceData.price}
														InputProps={{
															disableUnderline: true,
														}}
														onChange={(event) =>
															handlePriceInputChange(
																event,
																index
															)
														}
														inputProps={{
															style: {
																fontSize:
																	"14px",
																fontWeight: 400,
																textAlign:
																	"right",
																padding: 0,
															},
														}}
													/>
												</Box>
											) : (
												<Box>
													<TableCellText>
														{priceData.price}
													</TableCellText>
												</Box>
											)}
										</TableCell>

										<TableCell
											id={`tax_${index}`}
											align="right"
											onClick={
												editedRowId === priceData.id ||
												!!priceData.isPriceNotSaved
													? (event) =>
															handleTableCell(
																event
															)
													: null
											}
											sx={{
												borderBottom:
													`tax_${index}` ===
														selectedTableCellId &&
													styles.editableTableCell,
											}}
										>
											{editedRowId === priceData.id ||
											priceData.isPriceNotSaved ? (
												<Box>
													<TextField
														variant="standard"
														placeholder="0%"
														name="tax"
														value={priceData.tax}
														InputProps={{
															disableUnderline: true,
														}}
														onChange={(event) =>
															handlePriceInputChange(
																event,
																index
															)
														}
														inputProps={{
															style: {
																fontSize:
																	"14px",
																fontWeight: 400,
																textAlign:
																	"right",
																padding: 0,
															},
														}}
													/>
												</Box>
											) : (
												<Box>
													<TableCellText>
														{priceData.tax}
													</TableCellText>
												</Box>
											)}
										</TableCell>
										<TableCell
											align="right"
											id={`discount_${index}`}
											onClick={
												editedRowId === priceData.id ||
												!!priceData.isPriceNotSaved
													? (event) =>
															handleTableCell(
																event
															)
													: null
											}
											sx={{
												borderBottom:
													`discount_${index}` ===
														selectedTableCellId &&
													styles.editableTableCell,
											}}
										>
											{editedRowId === priceData.id ||
											priceData.isPriceNotSaved ? (
												<Box>
													<TextField
														variant="standard"
														placeholder="0%"
														name="discount"
														value={
															priceData.discount
														}
														InputProps={{
															disableUnderline: true,
														}}
														onChange={(event) =>
															handlePriceInputChange(
																event,
																index
															)
														}
														inputProps={{
															style: {
																fontSize:
																	"14px",
																fontWeight: 400,
																textAlign:
																	"right",
																padding: 0,
															},
														}}
													/>
												</Box>
											) : (
												<Box>
													<TableCellText>
														{priceData.discount}
													</TableCellText>
												</Box>
											)}
										</TableCell>

										<TableCell sx={{ py: 0, px: 1 }}>
											{isActionsEnable() ? (
												<Stack
													justifyContent="flex-end"
													direction="row"
													sx={{ width: "100%" }}
												>
													{hoveredTableRowId ===
														priceData.id &&
													!editedRowId &&
													newPriceData.length ===
														0 ? (
														<Box
															style={{
																height: "37px",
																border: `1px solid ${theme.palette.primary.main}`,
																borderRadius:
																	"8px",
															}}
															py={1}
															px={1.5}
														>
															<Stack
																direction="row"
																justifyContent="center"
																alignItems="center"
																style={{
																	height: "100%",
																}}
																spacing={1.5}
															>
																<Can
																	permission={
																		PERMISSIONS
																			.product
																			.edit
																	}
																>
																	<Box
																		display="flex"
																		onClick={() =>
																			handleEditPrice(
																				priceData,
																				index
																			)
																		}
																	>
																		{EditIcon(
																			20,
																			20,
																			theme
																				.palette
																				.primary
																				.main
																		)}
																	</Box>
																</Can>

																<Can
																	permission={
																		PERMISSIONS
																			.product
																			.edit
																	}
																>
																	<Can
																		permission={
																			PERMISSIONS
																				.product
																				.delete
																		}
																	>
																		<Divider orientation="vertical" />
																	</Can>
																</Can>

																<Can
																	permission={
																		PERMISSIONS
																			.product
																			.delete
																	}
																>
																	<Box
																		display="flex"
																		onClick={() =>
																			handleDeletePrice(
																				priceData
																			)
																		}
																	>
																		{DeleteIcon(
																			20,
																			20,
																			theme
																				.palette
																				.error
																				.main
																		)}
																	</Box>
																</Can>
															</Stack>
														</Box>
													) : null}
													{isPriceChanged ||
													isCurrencyChanged ? (
														<Box
															hidden={
																editedRowId !==
																priceData.id
															}
														>
															<Button
																variant="contained"
																color="secondary"
																onClick={() =>
																	handleUpdatePrice(
																		index
																	)
																}
																startIcon={TickIcon(
																	20,
																	20,
																	"#2EA871"
																)}
																disableElevation
															>
																Update
															</Button>
														</Box>
													) : null}
													<Box
														hidden={
															!priceData.isPriceNotSaved
														}
													>
														<Button
															style={{
																height: "28px",
																padding: "8px",
															}}
															variant="contained"
															color="secondary"
															startIcon={TickIcon(
																20,
																20,
																"#2EA871"
															)}
															disableElevation
															onClick={() =>
																handleUpdatePrice(
																	index,
																	priceData
																)
															}
														>
															Add
														</Button>
													</Box>
												</Stack>
											) : null}
										</TableCell>
									</TableRow>
								))}

								<Can permission={PERMISSIONS.product.edit}>
									<TableRow>
										<TableCell />

										<TableCell colSpan={5}>
											<TextButton
												onClick={handleAddPrice}
												sx={{
													opacity:
														isPriceAdditionRestricted()
															? 0.6
															: 1,
												}}
												startIcon={AddIcon(
													13,
													13,
													theme.palette.secondary
														.contrastText
												)}
											>
												<Tooltip
													open={
														isAddPriceTooltipOpened &&
														noCurrencyToAddPrice()
													}
													title="All Currencies already have a price"
													placement="top"
												>
													<Typography
														onMouseOver={() =>
															setIsAddPriceTooltipOpened(
																true
															)
														}
														onMouseLeave={() =>
															setIsAddPriceTooltipOpened(
																false
															)
														}
														fontSize={13}
														fontWeight={500}
														style={{
															cursor: isPriceAdditionRestricted()
																? "not-allowed"
																: "auto",
														}}
														color={
															theme.palette
																.secondary
																.contrastText
														}
													>
														Add Price
													</Typography>
												</Tooltip>
											</TextButton>
										</TableCell>
									</TableRow>
								</Can>
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Box>
		</React.Fragment>
	);
}
