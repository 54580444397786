import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Divider,
	LinearProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import { twozoStyles } from "../../../../../styles/twozo";
import SummaryFieldLoader from "../SummaryFieldLoader";

export default function ProductSummaryLoader(props) {
	const { moduleName } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const menuBarRef = useRef(null);
	const [menuBarEndingPosition, setMenuBarEndingPosition] = useState(0);

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarEndingPosition(
				menuBarRef.current.getBoundingClientRect().top
			);
		}
	}, [menuBarRef]);

	return (
		<React.Fragment>
			<Box
				height={`calc(100vh - ${menuBarEndingPosition}px)`}
				ref={menuBarRef}
			>
				<Box height="100%">
					<Box height="20%">
						<Box sx={{ width: "100%" }}>
							<LinearProgress />
						</Box>

						<Box className={classes.menuBar}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								height="100%"
							>
								<Stack
									direction="row"
									spacing={1.5}
									alignItems="center"
								>
									<Box>
										<Typography
											fontWeight={600}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{moduleName}
										</Typography>
									</Box>

									<Box
										style={{ transform: "rotate(-90deg)" }}
									>
										{DropDownIcon(16, 16)}
									</Box>
								</Stack>
							</Stack>
						</Box>

						<Box
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Box
								style={{
									overflowX: "hidden",
								}}
							>
								<Stack direction="row" spacing={2} p={2}>
									<SummaryFieldLoader />
								</Stack>
							</Box>

							<Box
								component={"button"}
								style={{
									backgroundColor: "#fff",
									cursor: "pointer",
									border: "none",
								}}
								pl={2}
								pr={1}
							>
								<Box style={{ transform: "rotate(-90deg)" }}>
									{DropDownIcon(20, 20, "#666")}
								</Box>
							</Box>
						</Box>

						<Box px={2}>
							<Divider />
						</Box>
					</Box>

					<Box px={1} pt={2} pb={1} height="80%">
						<Box className={classes.detailsContainer}></Box>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
