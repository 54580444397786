import React, { useState } from "react";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import { default as twozoLogo } from "../../../assets/images/logo/twozo-full.png";
import { useForm } from "react-hook-form";
import { Tooltip } from "../../../styles/twozo";
import { emailPattern } from "../../../utils/validation";
import CustomHomeComponent from "../../Elements/CustomHomeComponent";
import CreateCompany from "./CreateCompany";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";

export default function Signup() {
	const { isUserAuthenticated } = useAuth();

	if (isUserAuthenticated()) {
		return <Navigate to="/deals" />;
	}

	return <SignupPage />;
}

function SignupPage() {
	const theme = useTheme();
	const navigate = useNavigate();

	const { isUserAuthenticated } = useAuth();

	const [hideExtraFields, setHideExtraFields] = useState(true);
	const [isPasswordHidden, setIsPasswordHidden] = useState(false);
	const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] =
		useState(false);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		trigger,
		clearErrors,
		setValue,
	} = useForm();

	const password = watch("password");

	const passwordConstraints = [
		"Atleast 1 Lower-case",
		"Atleast 1 Upper-case",
		"Atleast 1 Number",
		"Atleast 1 Special character",
		"8-16 Characters",
	];

	const [userDetails, setUserDetails] = useState(null);

	const onSubmit = (data) => {
		setUserDetails(data);
	};

	const handleClickShowPassword = () => setIsPasswordHidden((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setIsConfirmPasswordHidden((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleMouseDownConfirmPassword = (event) => {
		event.preventDefault();
	};

	const validateAndSubmit = async () => {
		// Manually trigger the validation
		let isValid = await trigger("email");

		if (isValid) {
			setHideExtraFields(false);
		}
	};

	const resetUserDetails = () => {
		setUserDetails(null);
	};

	if (isUserAuthenticated()) {
		return <Navigate to="/deals" />;
	}

	return (
		<React.Fragment>
			<CustomHomeComponent>
				{userDetails ? (
					<CreateCompany
						userDetails={userDetails}
						resetUserDetails={resetUserDetails}
					/>
				) : (
					<Stack spacing={4} p={5} pb={3}>
						<Box>
							<img
								alt="twozo"
								src={twozoLogo}
								width="108px"
								height="34px"
							/>
						</Box>
						<Stack spacing={3}>
							<Typography fontSize={18} fontWeight={600}>
								Create your account today!
							</Typography>

							<Stack spacing={2}>
								<Stack spacing={0.5}>
									<Typography
										fontSize={13}
										fontWeight={500}
										color={
											!!errors.email &&
											theme.palette.error.main
										}
									>
										Work Email Id
									</Typography>

									<TextField
										placeholder="Enter your work email id"
										{...register("email", {
											required:
												"Please enter your email address",
											pattern: {
												value: emailPattern,
												message:
													"Invalid email address",
											},
										})}
										onChange={(event) => {
											setValue(
												"email",
												event.target.value
											); //set the value to the react-hook-form, if the field is auto-filled by the browser, react-form does not know it
											clearErrors("email"); // Clear email validation error
										}}
										FormHelperTextProps={{
											sx: {
												marginLeft: 0,
												fontSize: 13,
											},
										}}
										error={!!errors.email}
										helperText={errors.email?.message}
									/>
								</Stack>

								<Box hidden={hideExtraFields}>
									<Stack spacing={2}>
										<Stack spacing={0.5}>
											<Typography
												fontSize={13}
												fontWeight={500}
												color={
													!!errors.name &&
													theme.palette.error.main
												}
											>
												Name
											</Typography>

											<TextField
												placeholder="Enter your name"
												{...register("name", {
													required:
														"Name is required",
												})}
												onChange={() =>
													clearErrors("name")
												} // clear name validation error
												FormHelperTextProps={{
													sx: {
														marginLeft: 0,
														fontSize: 13,
													},
												}}
												error={!!errors.name}
												helperText={
													errors.name?.message
												}
											/>
										</Stack>

										<Stack spacing={0.5}>
											<Typography
												fontSize={13}
												fontWeight={500}
												color={
													!!errors.password &&
													theme.palette.error.main
												}
											>
												Password
											</Typography>
											<Tooltip
												open={isTooltipOpen}
												PopperProps={{
													modifiers: [
														{
															name: "offset",
															options: {
																offset: [
																	30, -3,
																],
															},
														},
													],
												}}
												placement="right"
												title={
													<List
														style={{
															fontSize: "14px",
															fontWeight: 300,
															minWidth: "223px",
															padding: "4px",
														}}
													>
														Password must contain:
														{passwordConstraints.map(
															(
																validation,
																index
															) => (
																<ListItem
																	key={index}
																	sx={{
																		"::before":
																			{
																				content:
																					// eslint-disable-next-line quotes
																					'"\\2022"' /* Unicode bullet character */,
																				marginRight: 1,
																			},
																		p: "0px 0px 0px 7px",
																	}}
																>
																	{validation}
																</ListItem>
															)
														)}
													</List>
												}
											>
												<TextField
													placeholder="Enter password"
													type={
														isPasswordHidden
															? "text"
															: "password"
													}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={
																		handleClickShowPassword
																	}
																	onMouseDown={
																		handleMouseDownPassword
																	}
																	edge="end"
																>
																	{isPasswordHidden
																		? EyeOffIcon(
																				20,
																				20,
																				"#999999"
																			)
																		: EyeIcon(
																				20,
																				20,
																				"#999999"
																			)}
																</IconButton>
															</InputAdornment>
														),
													}}
													{...register("password", {
														required:
															"Please enter your password",
														pattern: {
															value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=()-]).{8,20}$/,
															message:
																"Invalid password",
														},
														validate: (value) =>
															!/\s/.test(value), // removed the white space
													})}
													onFocus={() => {
														setIsTooltipOpen(true); // open tooltip on focus
													}}
													onBlur={() => {
														setIsTooltipOpen(false); // Close tooltip on blur
													}}
													onChange={(event) => {
														setValue(
															"password",
															event.target.value
														); //set the value to the react-hook-form, if the field is auto-filled by the browser, react-form does not know it
														clearErrors("password"); // clear password validation error
													}}
													FormHelperTextProps={{
														sx: {
															marginLeft: 0,
															fontSize: 13,
														},
													}}
													error={!!errors.password}
													helperText={
														errors.password?.message
													}
												/>
											</Tooltip>
										</Stack>

										<Stack spacing={0.5}>
											<Typography
												fontSize={13}
												fontWeight={500}
												color={
													!!errors.confirmPassword &&
													theme.palette.error.main
												}
											>
												Confirm Password
											</Typography>

											<TextField
												placeholder="Enter password"
												type={
													isConfirmPasswordHidden
														? "text"
														: "password"
												}
												{...register(
													"confirmPassword",
													{
														required:
															"Please enter your confirm password",
														validate: (value) => {
															if (
																value !==
																password
															) {
																return "Password does not match";
															}
														},
													}
												)}
												onChange={() =>
													clearErrors(
														"confirmPassword"
													)
												} // Clear confirm password validation error
												FormHelperTextProps={{
													sx: {
														marginLeft: 0,
														fontSize: 13,
													},
												}}
												error={!!errors.confirmPassword}
												helperText={
													errors.confirmPassword
														?.message
												}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={
																	handleClickShowConfirmPassword
																}
																onMouseDown={
																	handleMouseDownConfirmPassword
																}
																edge="end"
															>
																{isConfirmPasswordHidden
																	? EyeOffIcon(
																			20,
																			20,
																			"#999999"
																		)
																	: EyeIcon(
																			20,
																			20,
																			"#999999"
																		)}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Stack>
									</Stack>
								</Box>
							</Stack>

							<Box pb={1}>
								{hideExtraFields ? (
									<Button
										variant="contained"
										onClick={() => validateAndSubmit()}
										disableElevation
									>
										Next
									</Button>
								) : (
									<Button
										variant="contained"
										onClick={handleSubmit(onSubmit)}
										disableElevation
									>
										Create Account
									</Button>
								)}
							</Box>
						</Stack>
					</Stack>
				)}

				<Stack
					px={2.5}
					py={2}
					style={{
						borderTop: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					direction="row"
					spacing={0.5}
				>
					<Typography fontSize={13} fontWeight={500}>
						Already using Twozo?
					</Typography>

					<Typography
						fontSize={13}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
						onClick={() => navigate("/login")}
						style={{ cursor: "pointer" }}
					>
						Sign In
					</Typography>
				</Stack>
			</CustomHomeComponent>
		</React.Fragment>
	);
}
