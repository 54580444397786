import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export default function CountOverview(props) {
	const { countSummary } = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			{countSummary.length >= 1 && (
				<Stack
					direction="row"
					py={1}
					px={2}
					sx={{
						position: "fixed",
						bottom: "24px",
						right: "24px",
						backgroundColor: theme.palette.secondary.main,
						borderRadius: "8px",
					}}
				>
					{countSummary?.map((count, index) => (
						<Typography
							key={index}
							style={{
								fontSize: "14px",
								fontWeight: "500",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							{count.displayName} : {count.count}
							{index < countSummary?.length - 1 && (
								<span style={{ padding: "0px 10px" }}>
									&#8226;
								</span>
							)}
						</Typography>
					))}
				</Stack>
			)}
		</React.Fragment>
	);
}
