import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Menu from "../../../../../Elements/Menu";
import { useFieldContext } from "../../FieldContext";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";

export default function FieldType(props) {
	const { field, fieldTypeOption, changeFieldType } = props;
	const theme = useTheme();

	// field context
	const { fieldState, onFieldTypeChange, onSelectChoicesChanged } =
		useFieldContext(field);

	const [fieldType, setFieldType] = useState(field.type);
	const [fieldTypeOptionMenuElement, setFieldTypeOptionMenuElement] =
		useState(null);
	const openFieldTypeOptionMenu = Boolean(fieldTypeOptionMenuElement);
	const [fieldTypeOptionSelectedIndex, setFieldTypeOptionSelectedIndex] =
		useState(0);

	const OpenFieldTypeOptionMenu = (event) => {
		if (fieldState.fieldInCreateMode) {
			setFieldTypeOptionMenuElement(event.currentTarget);
		}
	};

	const CloseFieldTypeOptionMenu = () => {
		setFieldTypeOptionMenuElement(null);
	};

	const getFieldTypeValue = (type) => {
		const fieldType = fieldTypeOption.find(
			(option) => option.name === type
		);
		return fieldType?.displayName;
	};

	const handleFieldTypeOptionSelect = (_, index, option) => {
		setFieldTypeOptionSelectedIndex(index);
		setFieldTypeOptionMenuElement(null);
		setFieldType(option.name);
		onFieldTypeChange(option.name);
		onSelectChoicesChanged([]);
		changeFieldType(option.name, fieldState.unSavedFieldData.fieldName);
	};

	return (
		<React.Fragment>
			<Box>
				<Box
					pl={1}
					pr={fieldState.fieldInCreateMode ? 0.5 : 1}
					py={0.2}
					sx={{
						backgroundColor: (theme) =>
							theme.palette.secondary.main,
						borderRadius: "3px",
					}}
					onClick={OpenFieldTypeOptionMenu}
				>
					<Stack direction="row" spacing={0.5}>
						<Typography fontSize={12}>
							{getFieldTypeValue(fieldType)}
						</Typography>

						{fieldState.fieldInCreateMode &&
							DropDownIcon(
								18,
								18,
								theme.palette.secondary.contrastText
							)}
					</Stack>
				</Box>

				<Menu
					sx={{
						maxHeight: "443px",
						"& .MuiMenu-list": {
							paddingTop: "5px",
						},
					}}
					style={{
						marginTop: "5px",
					}}
					anchorEl={fieldTypeOptionMenuElement}
					open={openFieldTypeOptionMenu}
					onClose={CloseFieldTypeOptionMenu}
				>
					{fieldTypeOption.map(
						(option, index) =>
							!option.isSourceDependent && (
								<MenuItem
									key={option.id}
									selected={
										index === fieldTypeOptionSelectedIndex
									}
									onClick={(event) =>
										handleFieldTypeOptionSelect(
											event,
											index,
											option
										)
									}
									style={{
										minHeight: "40px",
									}}
								>
									<Typography fontSize={13}>
										{option.displayName}
									</Typography>
								</MenuItem>
							)
					)}
				</Menu>
			</Box>
		</React.Fragment>
	);
}
