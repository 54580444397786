import { FieldContext } from "..";
import { useFieldReducer } from "../FieldReducer";

export default function FieldProvider(props) {
	const { field, shouldShowFieldActions, isDependableField, children } =
		props;

	const {
		fieldState,
		onFieldNameInEditMode,
		resetFieldNameInEditMode,
		resetFieldNameError,
		onFieldNameValueChange,
		onAddViewChange,
		onRequiredFieldChange,
		onFieldTypeChange,
		onFieldValueChange,
		onSelectChoicesChanged,
		initializeUnSavedFieldData,
		OnFieldNameLimitExceeded,
		hasSelectChoiceChanged,
	} = useFieldReducer(field);

	return (
		<>
			<FieldContext.Provider
				value={{
					fieldState,
					onFieldNameInEditMode,
					resetFieldNameInEditMode,
					shouldShowFieldActions,
					isDependableField,
					resetFieldNameError,
					onFieldNameValueChange,
					onAddViewChange,
					onFieldTypeChange,
					onRequiredFieldChange,
					onFieldValueChange,
					onSelectChoicesChanged,
					initializeUnSavedFieldData,
					OnFieldNameLimitExceeded,
					hasSelectChoiceChanged,
				}}
			>
				{children}
			</FieldContext.Provider>
		</>
	);
}
