import { apiClient } from "../..";
import { getContactDuplicateUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getDuplicateContactList = async (contactDetails, pageParams) => {
	let requestData = getCheckForDuplicatesRequestData(
		contactDetails,
		pageParams
	);
	return await postContactDuplicateManagementApi(requestData);
};

export const mergeDuplicateContact = async ({ primaryContactId, contact }) => {
	let requestData = getMergeDupicateContactRequestData(
		primaryContactId,
		contact
	);
	return await postContactDuplicateManagementApi(requestData);
};

const getCheckForDuplicatesRequestData = (contactDetails, pageParams) => {
	return {
		type: "checkDuplicates",
		data: {
			...contactDetails,
			...pageParams,
		},
	};
};

const getMergeDupicateContactRequestData = (primaryContactId, contact) => {
	return {
		type: "mergeDuplicates",
		data: {
			primaryContactId: primaryContactId,
			contact: contact,
		},
	};
};

const postContactDuplicateManagementApi = async (requestData) => {
	let contactDuplicateManagementUrl = getContactDuplicateManagementUrl();
	let response = await apiClient.post(
		contactDuplicateManagementUrl,
		requestData
	);
	assertError(response);
	return response.data.data;
};

const getContactDuplicateManagementUrl = () => {
	return getContactDuplicateUrl() + "/mgmt";
};
