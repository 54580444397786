import { getRootKey } from "../rootKey";

const calendarSyncKey = "calendarSync";

// calendar sync query key:-
export const getCalendarSyncKey = () => {
	return [...getRootKey(), calendarSyncKey];
};

// query key:-
export const calendarSyncSettingsDataKey = [
	...getCalendarSyncKey(),
	"calendarSyncData",
];

// query key methods:-
export const getCalendarSyncStatusKey = (id) => {
	return [...getCalendarSyncKey(), String(id), "calendarSyncStatus"];
};
