import React, { useState } from "react";
import { TableCellText, Tooltip } from "../../../../styles/twozo";

export default function Territory(props) {
	const { data } = props;
	const [isTerritoryTooltipOpened, setIsTerritoryTooltipOpened] =
		useState(false);

	if (!data) {
		return "-";
	}

	const handleOpenTooltip = (event) => {
		setIsTerritoryTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
	};

	return (
		<React.Fragment>
			<Tooltip
				placement="top"
				open={isTerritoryTooltipOpened}
				title={data?.name}
			>
				<TableCellText
					onMouseOver={handleOpenTooltip}
					onMouseOut={() => setIsTerritoryTooltipOpened(false)}
					noWrap
					sx={{ maxWidth: "300px" }}
				>
					{data?.name}
				</TableCellText>
			</Tooltip>
		</React.Fragment>
	);
}
