import React from "react";

export default function Share(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M14.89 4.49003C18.7 3.22003 20.77 5.30003 19.51 9.11003L16.68 17.6C14.78 23.31 11.66 23.31 9.75999 17.6L8.91999 15.08L6.39999 14.24C0.689993 12.34 0.689993 9.23003 6.39999 7.32003L14.89 4.49003Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.10999 14.6501L12.69 11.0601"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
