import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../../../Elements/Accordian";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as RoundCheckBoxOffIcon } from "../../../../assets/icons/roundCheckBoxOff";
import { default as RoundCheckBoxOnIcon } from "../../../../assets/icons/roundCheckBoxOn";
import {
	Box,
	Checkbox,
	CircularProgress,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { getIconByName } from "../../../../utils";
import {
	getHourAndMinuteFormat,
	getMonthAndDateFormat,
} from "../../../../utils/DateUtils";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";
import Menu from "../../../Elements/Menu";
import DeleteDialog from "../../../Elements/DeleteDialog";
import EditActivity from "../../../Summary/Activities/EditActivity";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../styles/twozo";
import {
	useDealToDoActivities,
	useDeleteActivity,
	useUpdateActivityStatus,
} from "../../../../hooks/services/deal/summary/activities";
import { getDealToDoActivityKey } from "../../../../utils/queryKeys";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function Activities(props) {
	const { dealId } = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const [expanded, setExpanded] = useState(false);
	const [isDeleteActivityDialogOpened, setIsDeleteActivityDialogOpened] =
		useState(false);
	const [isEditActivityDialogOpened, setIsEditActivityDialogOpened] =
		useState(false);
	const [selectedActivity, setSelectedActivity] = useState({});
	const [activityMenuElement, setActivityMenuElement] = useState(null);
	const isActivityMenuOpened = Boolean(activityMenuElement);
	const [hoveredActivityId, setHoveredActivityId] = useState("");
	const successNotificationTitle = "Success!";

	const {
		isLoading: isTodoActivityDataLoading,
		status: activitiesStatus,
		data: toDoActivitiesData,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useDealToDoActivities(dealId);
	const deleteActivityMutation = useDeleteActivity(dealId);
	const updateActivityStatusMutation = useUpdateActivityStatus(dealId);

	const latRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	// Handed Error Notification
	useEffect(() => {
		if (activitiesStatus === "error" || activitiesStatus === "error") {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	}, [activitiesStatus]);

	const styles = {
		text: {
			fontSize: "14px",
			fontWeight: 500,
		},
		subText: {
			fontSize: "14px",
			fontWeight: 400,
		},
		smallText: {
			fontSize: "13px",
			fontWeight: 400,
		},
		coloredText: {
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
		},
		activityMenu: {
			fontSize: "13px",
			fontWeight: 500,
			color: theme.palette.primary.main,
		},
		ownerText: {
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.6)",
			fontWeight: 400,
		},
	};

	const updateActivityStatus = (activity) => {
		updateActivityStatusMutation.mutate(
			{ id: activity.id, isDone: !activity.isDone },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.activityUpdated,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			}
		);
	};

	const openActivityMenu = (event, activity) => {
		setActivityMenuElement(event.currentTarget);
		setSelectedActivity(activity);
	};

	const toggleEditActivityDialog = () => {
		setIsEditActivityDialogOpened(
			(isEditActivityDialogOpened) => !isEditActivityDialogOpened
		);
		setActivityMenuElement(null);
	};

	const closeActivityMenu = () => {
		setActivityMenuElement(null);
		setSelectedActivity({});
	};

	const handleOpenDeleteActivityDialog = () => {
		setIsDeleteActivityDialogOpened(true);
		setActivityMenuElement(null);
	};

	const handleDeleteActivity = () => {
		deleteActivityMutation.mutate(selectedActivity.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.activityDeleted,
				});
				setIsDeleteActivityDialogOpened(false);
				setActivityMenuElement(null);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const closeDeleteActivityDialog = () => {
		setIsDeleteActivityDialogOpened(false);
		setActivityMenuElement(null);
	};

	const openEditActivityDrawer = (activityData) => {
		setIsEditActivityDialogOpened(true);
		setSelectedActivity(activityData);
	};

	const onMouseOverActivityTitle = (activityData) => {
		setHoveredActivityId(activityData?.id);
	};

	const onMouseOutActivityTitle = () => {
		setHoveredActivityId("");
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditActivityDialogOpened}
				onOpen={toggleEditActivityDialog}
				onClose={toggleEditActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditActivity
						onClose={toggleEditActivityDialog}
						toDoActivitesKey={getDealToDoActivityKey(dealId)}
						activityId={selectedActivity?.id}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<DeleteDialog
				open={isDeleteActivityDialogOpened}
				onDelete={handleDeleteActivity}
				onCancel={closeDeleteActivityDialog}
				title={`Are You sure You Want to Delete Activity - ${selectedActivity.title}?`}
				subtitle="This will delete all the related details associated with this"
				disableDeleteButton={deleteActivityMutation.isLoading}
			/>

			<Menu
				minWidth="160px"
				anchorEl={activityMenuElement}
				open={isActivityMenuOpened}
				onClose={closeActivityMenu}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{ transform: "translateX(30px)" }}
				style={{
					marginTop: "2px",
				}}
			>
				<MenuItem
					style={{ height: "40px" }}
					onClick={toggleEditActivityDialog}
				>
					<Typography style={styles.activityMenu}>Edit</Typography>
				</MenuItem>
				<MenuItem
					style={{ height: "40px" }}
					onClick={handleOpenDeleteActivityDialog}
				>
					<Typography style={styles.activityMenu}>Delete</Typography>
				</MenuItem>
			</Menu>

			<Box>
				<Accordion
					expanded={expanded}
					onChange={() => setExpanded((expanded) => !expanded)}
				>
					<AccordionSummary
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Typography fontSize={14} fontWeight={600}>
							Activities To Do
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							paddingTop: "0px",
						}}
					>
						{toDoActivitiesData?.length > 0 ? (
							<table
								style={{
									width: "100%",
									borderCollapse: "collapse",
								}}
							>
								<tbody>
									{toDoActivitiesData?.map(
										(activity, index) => (
											<tr
												style={{
													borderBottom:
														toDoActivitiesData.length ===
														index + 1
															? "none"
															: "1px solid rgba(0, 0, 0, 0.1)",
													height: "72px",
													cursor: "pointer",
												}}
												key={index}
											>
												<td
													style={{
														padding:
															"8px 16px 10px 0px",
														width: "5%",
														alignContent:
															"flex-start",
													}}
												>
													<Checkbox
														icon={RoundCheckBoxOffIcon(
															20,
															20
														)}
														checkedIcon={RoundCheckBoxOnIcon(
															20,
															20,
															theme.palette
																.primary.main
														)}
														checked={
															activity.isDone
														}
														onClick={() =>
															updateActivityStatus(
																activity
															)
														}
													/>
												</td>

												<td
													style={{
														padding: "10px 0px",
														width: "16%",
														alignContent:
															"flex-start",
													}}
												>
													<Stack alignItems="flex-end">
														<Typography
															style={styles.text}
															color={
																activity?.isOverDue
																	? theme
																			.palette
																			.error
																			.main
																	: "#000"
															}
														>
															{getMonthAndDateFormat(
																activity.startTime
															)}
														</Typography>

														<Typography
															color={
																activity?.isOverDue
																	? theme
																			.palette
																			.error
																			.main
																	: "#000"
															}
															style={
																styles.smallText
															}
														>
															{getHourAndMinuteFormat(
																activity.startTime
															)}
														</Typography>
													</Stack>
												</td>

												<td
													style={{
														padding:
															"12px 0px 10px 0px",
														width: "13%",
														textAlign: "center",
														alignContent:
															"flex-start",
													}}
												>
													{getIconByName(
														activity.activityType
															?.icon?.name
													)(
														20,
														20,
														activity?.isOverDue
															? theme.palette
																	.error.main
															: theme.palette
																	.secondary
																	.contrastText
													)}
												</td>

												<td
													width="60%"
													style={{
														alignContent:
															"flex-start",
														padding:
															"8px 0px 10px 0px",
													}}
												>
													<Stack spacing={0.5}>
														<Box
															onClick={() =>
																openEditActivityDrawer(
																	activity
																)
															}
															onMouseOver={() =>
																onMouseOverActivityTitle(
																	activity
																)
															}
															onMouseOut={() =>
																onMouseOutActivityTitle(
																	activity
																)
															}
														>
															<Typography
																style={{
																	wordBreak:
																		"break-word",
																	...styles.text,
																	color: activity?.isOverDue
																		? theme
																				.palette
																				.error
																				.main
																		: "#000",
																	textDecoration:
																		hoveredActivityId ===
																		activity?.id
																			? "underline"
																			: "none",
																}}
																component="span"
															>
																{activity.title}{" "}
															</Typography>

															<Typography
																style={
																	styles.ownerText
																}
																component="span"
																pl={0.5}
															>
																-{" "}
																{
																	activity
																		?.assignee
																		?.name
																}
															</Typography>
														</Box>

														<Typography
															sx={{
																wordBreak:
																	"break-word",
															}}
															color={
																activity?.isOverDue
																	? theme
																			.palette
																			.error
																			.main
																	: "#000"
															}
															style={
																styles.subText
															}
														>
															{
																activity.note
																	?.note
															}
														</Typography>
													</Stack>
												</td>

												<td
													align="right"
													style={{
														padding:
															"8px 0px 10px 0px",
														alignContent:
															"flex-start",
													}}
												>
													<IconButton
														size="small"
														onClick={(event) =>
															openActivityMenu(
																event,
																activity
															)
														}
													>
														{MoreIcon(
															20,
															20,
															"#000000",
															0.6
														)}
													</IconButton>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						) : (
							<Stack alignItems="center" justifyContent="center">
								<Typography
									fontSize={13}
									color="rgba(0, 0, 0, 0.6)"
								>
									You have no upcoming activities.
								</Typography>
							</Stack>
						)}

						<Box ref={latRowRef}>
							{!isTodoActivityDataLoading && isFetching && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
									py={2}
								>
									<CircularProgress size={18} />

									<Typography fontSize={12}>
										Loading More Data
									</Typography>
								</Stack>
							)}
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>
		</React.Fragment>
	);
}
