import { createContext, useContext } from "react";
import { usePermissionReducer } from "../PermissionReducer";

const PermissionContext = createContext(null);

const usePermissionContext = () => {
	return useContext(PermissionContext);
};

const PermissionProvider = (props) => {
	const { children } = props;

	const {
		permissionState,
		updateSourceList,
		updateActionsList,
		updateActivityGoalsList,
		updateAdminAccessList,
		updateEmailList,
		updateLeadGenerationList,
		updatePhoneList,
		updateQuotasAndForecastingList,
		updateUserSettingsList,
		updateSmsList,
		initializeSourceListForPermission,
		initializeActionsListForPermission,
		initializeActivityGoalsListForPermission,
		initializeAdminAccessListForPermission,
		initializeEmailListForPermission,
		initializeLeadGenerationListForPermission,
		initializePhoneListtForPermission,
		initializeQuotasAndForecastingListForPermission,
		initializeUserSettingsListForPermission,
		initializeSmsListForPermission,
	} = usePermissionReducer();

	return (
		<PermissionContext.Provider
			value={{
				permissionState,
				updateSourceList,
				updateActionsList,
				updateActivityGoalsList,
				updateAdminAccessList,
				updateEmailList,
				updateLeadGenerationList,
				updatePhoneList,
				updateQuotasAndForecastingList,
				updateUserSettingsList,
				updateSmsList,
				initializeSourceListForPermission,
				initializeActionsListForPermission,
				initializeActivityGoalsListForPermission,
				initializeAdminAccessListForPermission,
				initializeEmailListForPermission,
				initializeLeadGenerationListForPermission,
				initializePhoneListtForPermission,
				initializeQuotasAndForecastingListForPermission,
				initializeUserSettingsListForPermission,
				initializeSmsListForPermission,
			}}
		>
			{children}
		</PermissionContext.Provider>
	);
};

export { usePermissionContext, PermissionProvider };
