import React from "react";

function DoubleArrow(width = "16", height = "16", color = "#2EA871") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.33398 12.56L2.98732 7.94671L7.33398 3.33337"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.666 12.56L8.31935 7.94671L12.666 3.33337"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default DoubleArrow;
