const workflowStatusList = [
	{
		name: "All Status",
		value: -1,
	},
	{
		name: "Active",
		value: 1,
	},
	{
		name: "Inactive",
		value: 2,
	},
];

export default workflowStatusList;
