import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import SingleCondition from "../SingleCondition";
import { DwarfButton } from "../../../../styles/twozo";
import {
	andCondition,
	maxConditionLimit,
	orCondition,
} from "../../WorkflowConfig/config";

export default function ConditionList(props) {
	const {
		readOnly,
		conditionSet,
		addCondition,
		deleteCondition,
		activityFields,
		comparatorsData,
		hideNewConditionSetButton,
		addNewConditionSet,
		updateConditionSet,
	} = props;
	const theme = useTheme();

	const handleChangeCondition = (condition) => {
		let clonedConditionSet = [...conditionSet];
		let connector =
			condition.connector === andCondition ? orCondition : andCondition;
		let updatedConditionSet = clonedConditionSet.map((condition) => {
			return { ...condition, connector: connector };
		});
		updateConditionSet(updatedConditionSet);
	};

	return (
		<React.Fragment>
			<Box>
				<Stack spacing={1.5}>
					{conditionSet.map((condition, index) => (
						<Stack
							key={"condition-set-" + index}
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							{index > 0 && (
								<Typography
									fontSize={14}
									fontWeight={500}
									color={
										readOnly
											? "#000"
											: theme.palette.secondary
													.contrastText
									}
									onClick={() =>
										handleChangeCondition(condition)
									}
									style={{
										cursor: "pointer",
									}}
								>
									{condition?.connector === andCondition
										? "AND"
										: "OR"}
								</Typography>
							)}

							<Box width="420px">
								<SingleCondition
									key={"condition-" + index}
									index={index}
									readOnly={readOnly}
									fields={activityFields}
									condition={condition}
									comparatorsData={comparatorsData}
									deleteCondition={deleteCondition}
								/>
							</Box>
						</Stack>
					))}
				</Stack>

				<Stack direction="row" spacing={1} pt={1.5}>
					{conditionSet?.length < maxConditionLimit && (
						<DwarfButton
							onClick={addCondition}
							sx={{ opacity: readOnly ? 0.6 : 1 }}
						>
							+ Add Condition
						</DwarfButton>
					)}

					{!hideNewConditionSetButton && (
						<DwarfButton
							onClick={addNewConditionSet}
							sx={{ opacity: readOnly ? 0.6 : 1 }}
						>
							+ Add New Set
						</DwarfButton>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}
