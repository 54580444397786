import { FieldListContext } from "..";
import { useFieldListReducer } from "../FieldListReducer";

export default function FieldListProvider({ children }) {
	const {
		fieldListState,
		errorOnCreate,
		errorOnUpdate,
		resetCreateMode,
		resetUpdateMode,
		updateEditModeOnFieldEdit,
		updateCreateModeOnFieldCreation,
	} = useFieldListReducer();

	return (
		<>
			<FieldListContext.Provider
				value={{
					fieldListState,
					errorOnCreate,
					errorOnUpdate,
					resetCreateMode,
					resetUpdateMode,
					updateEditModeOnFieldEdit,
					updateCreateModeOnFieldCreation,
				}}
			>
				{children}
			</FieldListContext.Provider>
		</>
	);
}
