import { createContext, useContext } from "react";
import { useEmailTemplateReducer } from "../TemplateReducer";

const EmailTemplateContext = createContext(null);

const useEmailTemplateContext = () => {
	return useContext(EmailTemplateContext);
};

const EmailTemplateProvider = (props) => {
	const { children } = props;

	const {
		emailTemplateState,
		onNewFolderCreation,
		onNewFolderCreationError,
		enableTemplateFolderEditMode,
		disableTemplateFolderEditMode,
		onFolderNameChanged,
		updateExpandedFolderIds,
	} = useEmailTemplateReducer();

	return (
		<EmailTemplateContext.Provider
			value={{
				emailTemplateState,
				onNewFolderCreation,
				onNewFolderCreationError,
				enableTemplateFolderEditMode,
				disableTemplateFolderEditMode,
				onFolderNameChanged,
				updateExpandedFolderIds,
			}}
		>
			{children}
		</EmailTemplateContext.Provider>
	);
};

export { useEmailTemplateContext, EmailTemplateProvider };
