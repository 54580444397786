import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CompanyOnBoardBaseComponent } from "./CompanyOnBoardBaseComponent/Index";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export function CompanyOnBoardComponent(props) {
	const { field, inputRef, values, onChangeFieldValueLabel } = props;
	const { control, clearErrors } = useFormContext();
	const companyId = values.companyId;

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				defaultValue={companyId || ""}
				rules={handleFieldValidation(field)}
				render={({
					field: { value, onChange },
					fieldState: { error },
				}) => (
					<CompanyOnBoardBaseComponent
						onCompanyInputChange={onChange}
						value={value}
						field={field}
						inputRef={inputRef}
						error={error}
						clearErrors={clearErrors}
						onChangeFieldValueLabel={onChangeFieldValueLabel}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}
