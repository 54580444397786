import React from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as CloseIcon } from "../../assets/icons/close";

const SuccessNotification = (props, ref) => {
	const { id, title = "Success!", message, ...other } = props;

	const theme = useTheme();
	const { closeSnackbar } = useSnackbar();

	return (
		<SnackbarContent ref={ref} role="alert" {...other}>
			<Box
				style={{
					width: "100%",
					minWidth: "400px",
					backgroundColor: theme.palette.primary.main,
					color: "#fff",
					borderRadius: "10px 0px 0px 10px",
					paddingLeft: "8px",
				}}
			>
				<Box
					style={{
						borderRadius: "10px 0px 0px 10px",
						backgroundColor: "#323441",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						style={{
							backgroundColor: "#272937",
							borderRadius: "10px 0px 0px 0px",
						}}
						py={1}
						px={2}
					>
						<Typography fontWeight={500} fontSize={14}>
							{title}
						</Typography>

						<IconButton
							size="small"
							onClick={() => closeSnackbar(id)}
							style={{ padding: 0 }}
						>
							{CloseIcon(20, 20, "#fff")}
						</IconButton>
					</Stack>

					<Box p={2}>
						<Typography fontSize={14}>{message}</Typography>
					</Box>
				</Box>
			</Box>
		</SnackbarContent>
	);
};

export default React.forwardRef(SuccessNotification);
