import { useEffect, useMemo, useState } from "react";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import AutocompleteBaseComponent from "../AutocompleteBaseComponent";

export default function MultiplePhonesComponent(params) {
	const {
		field,
		onChange,
		hasError,
		metadata,
		style = {},
		placeholder,
	} = params;

	const { data: stepOptions } = useStepOptions(metadata, field);
	const [selectedPhones, setSelectedPhones] = useState([]);

	useEffect(() => {
		if (Array.isArray(field?.values)) {
			let fieldValue = field?.values.map((field) => {
				if (field.value?.name) {
					return field;
				}
				return {
					...field,
					value: {
						name: field.value,
					},
				};
			});
			setSelectedPhones(fieldValue);
		}
	}, [field]);

	const isValidPhoneNumber = (option) => {
		const minLimit = 7;
		const maxLimit = 17;

		if (option.isNewOption) {
			return (
				option.value.name.length >= minLimit &&
				option.value.name.length <= maxLimit
			);
		} else {
			return true;
		}
	};

	const handleChangePhones = (phones) => {
		const filteredPhones = phones?.filter((phone) => {
			if (phone.isNewOption) {
				return isValidPhoneNumber(phone);
			}
			return phone;
		});

		setSelectedPhones(phones);
		field["values"] = filteredPhones;
		if (onChange) {
			onChange(filteredPhones);
		}
	};

	const stepOptionFields = useMemo(() => {
		if (Array.isArray(stepOptions)) {
			return stepOptions.flatMap((stepOption) =>
				stepOption.fields.map((field) => ({
					value: { id: field.id, name: field.name },
					dependsOn: stepOption.dependsOn,
				}))
			);
		}
		return [];
	}, [stepOptions]);

	return (
		<AutocompleteBaseComponent
			style={style}
			type="number"
			placeholder={placeholder}
			isNewOption={true}
			hasError={hasError}
			values={selectedPhones}
			options={stepOptionFields}
			isValidOption={isValidPhoneNumber}
			onChange={handleChangePhones}
		/>
	);
}
