import React from "react";
import ManageDuplicates from "../../Elements/ManageDuplicates";

export default function ManageDuplicatesCompany() {
	return (
		<React.Fragment>
			<ManageDuplicates
				moduleName="All Companies"
				navigateURL="/companies"
			/>
		</React.Fragment>
	);
}
