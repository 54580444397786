import React from "react";
import {
	Box,
	CircularProgress,
	InputAdornment,
	Radio,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DealIcon } from "../../../../../assets/icons/deals";
import DealMergeTableCell from "../DealMergeTableCell";
import { useDealMergePreviewData } from "../../../../../hooks/services/deal/dealMerge/dealMerge";

export default function Preview(props) {
	const { dealFields, primaryId, secondaryId } = props;
	const theme = useTheme();
	const { data: previewData, isLoading: isLoadingPreviewData } =
		useDealMergePreviewData(primaryId, secondaryId);

	return (
		<React.Fragment>
			{isLoadingPreviewData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="80vh"
				>
					<CircularProgress />
				</Stack>
			) : (
				<Box>
					<table
						style={{
							width: "100%",
							borderSpacing: "16px 0px",
							borderCollapse: "separate",
							tableLayout: "fixed",
						}}
						cellPadding={0}
					>
						<tbody>
							<tr style={{ paddingRight: "16px" }}>
								<td
									style={{
										borderRight: "1px solid #E0E0E0",
										width: "30%",
									}}
								/>

								<td
									style={{
										padding: "16px 0px",
										width: "40%",
									}}
								>
									<Stack direction="row">
										<Radio checked={true} />

										<TextField
											value={previewData?.title}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{DealIcon(
															18,
															18,
															"#666"
														)}
													</InputAdornment>
												),
												readOnly: true,
											}}
											fullWidth
										/>
									</Stack>
								</td>

								<td
									style={{
										padding: "16px 0px",
										width: "40%",
									}}
								/>
							</tr>

							{dealFields?.map((field, index) =>
								previewData?.[field?.key] ||
								previewData?.[field?.key] === 0 ? (
									<tr key={index} style={{ padding: "10px" }}>
										<td>
											<Box
												style={{
													height: "42px",
													display: "flex",
													alignItems: "center",
													justifyContent: "flex-end",
													borderRight:
														"1px solid #E0E0E0",
												}}
												pr={3}
											>
												<Typography
													fontSize={14}
													fontWeight={500}
												>
													{field?.name}
												</Typography>
											</Box>
										</td>
										<td>
											<Box
												style={{
													height: "42px",
													display: "flex",
													alignItems: "center",
													borderBottom:
														index ===
														dealFields?.length - 1
															? "none"
															: "1px solid #E0E0E0",
													borderRadius:
														index === 0
															? "8px 8px 0px 0px"
															: index ===
																  dealFields?.length -
																		1
																? "0px 0px 8px 8px"
																: "0px",
													backgroundColor:
														theme.palette.secondary
															.main,
												}}
												px={2}
											>
												<DealMergeTableCell
													fieldType={field?.storeType}
													fieldValue={
														previewData?.[
															field?.key
														]
													}
												/>
											</Box>
										</td>
										<td></td>
									</tr>
								) : null
							)}
						</tbody>
					</table>

					<Box
						style={{
							position: "absolute",
							bottom: "90px",
							right: "16px",
							width: "350px",
							padding: "16px",
							borderRadius: "8px",
							backgroundColor: theme.palette.secondary.main,
						}}
					>
						<Stack spacing={2.5}>
							<Typography fontSize={14}>
								All related activities, emails, files, notes,
								products, followers and related contacts are
								transferred to this deal.
							</Typography>
							<Typography fontSize={14}>
								The duplicate deal will be permanently deleted
								after 30 days.
							</Typography>
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
