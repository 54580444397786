import React from "react";

export default function Template(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
		>
			<path
				d="M20.5001 5.24465C20.5001 4.00071 19.9994 3.5 18.7555 3.5H15.5947C14.3508 3.5 13.8501 4.00071 13.8501 5.24465V8.40535C13.8501 9.64929 14.3508 10.15 15.5947 10.15H18.7555C19.9994 10.15 20.5001 9.64929 20.5001 8.40535V5.24465Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.15 5.04879C10.15 3.94586 9.64929 3.5 8.40535 3.5H5.24465C4.00071 3.5 3.5 3.94586 3.5 5.04879V8.59223C3.5 9.70297 4.00071 10.141 5.24465 10.141H8.40535C9.64929 10.1488 10.15 9.70297 10.15 8.60005V5.04879Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.15 15.5947C10.15 14.3507 9.64929 13.85 8.40535 13.85H5.24465C4.00071 13.85 3.5 14.3507 3.5 15.5947V18.7554C3.5 19.9993 4.00071 20.5 5.24465 20.5H8.40535C9.64929 20.5 10.15 19.9993 10.15 18.7554V15.5947Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.2 15.65H19.2"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M15.2 19.25H19.2"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
}
