import React from "react";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/material";

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "right",
			display: true,
		},
		title: {
			display: false,
		},
	},
};

const labels = [
	"Jordyn Vaccaro",
	"Adison Vaccaro",
	"Madelyn Westerv",
	"Allison Korsgaard",
];

export const data = {
	labels,
	datasets: [
		{
			label: "Contact Count",
			data: [78, 84, 63, 100],
			backgroundColor: [
				"rgb(211, 114, 225)",
				"rgb(244, 190, 56)",
				"rgb(63, 255, 163)",
				"rgb(233, 139, 201)",
			],
		},
	],
};

export default function PieChart() {
	return (
		<React.Fragment>
			<Box height="80%">
				<Pie options={options} data={data} />
			</Box>
		</React.Fragment>
	);
}
