import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	createEmailTemplateTags,
	deleteEmailTemplateTags,
	getEmailTemplateTagsList,
	updateEmailTemplateTags,
} from "../../../../api/tag/email";
import {
	emailTemplateTagsDropdownKey,
	emailTemplateTagsListKey,
} from "../../../../utils/queryKeys";
import { tagListPageSize } from "../../../../utils/queryConstants/tags";

const formatEmailTemplateTagList = (tagsList) => {
	if (tagsList?.pages?.length > 0) {
		const formattedEmailTemplateTags = [];
		for (let index = 0; tagsList.pages.length > index; index++) {
			let formattedTags = tagsList.pages?.[index]?.list?.flatMap(
				(tag) => {
					return {
						lastUsedTime: tag.lastUsedTime,
						createdBy: {
							name: tag.createdBy.name,
						},
						createdTime: tag.createdTime,
						tag: tag.name,
						id: tag.id,
					};
				}
			);
			formattedEmailTemplateTags.push(...formattedTags);
		}
		return formattedEmailTemplateTags;
	}
	return [];
};

const useEmailTemplateTagsList = () => {
	return useInfiniteQuery({
		queryKey: emailTemplateTagsListKey,
		queryFn: ({ pageParam }) => {
			return getEmailTemplateTagsList({
				start: pageParam ? (pageParam - 1) * tagListPageSize + 1 : 1,
				limit: tagListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: formatEmailTemplateTagList,
	});
};

const useTemplateTagsDropdownList = () => {
	return useQuery(
		emailTemplateTagsDropdownKey,
		() => getEmailTemplateTagsList(),
		{
			select: (data) => data.list,
		}
	);
};

const useInvalidateEmailTemplateTagsList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(emailTemplateTagsListKey);
	};
};

const useCreateEmailTemplateTagsMutation = () => {
	let invalidateTagsList = useInvalidateEmailTemplateTagsList();
	return useMutation(createEmailTemplateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useUpdateEmailTemplateTagsMutation = () => {
	let invalidateTagsList = useInvalidateEmailTemplateTagsList();
	return useMutation(updateEmailTemplateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useDeleteEmailTemplateTagsMutation = () => {
	let invalidateTagsList = useInvalidateEmailTemplateTagsList();
	return useMutation(deleteEmailTemplateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

export {
	useEmailTemplateTagsList,
	useCreateEmailTemplateTagsMutation,
	useDeleteEmailTemplateTagsMutation,
	useUpdateEmailTemplateTagsMutation,
	useTemplateTagsDropdownList,
};
