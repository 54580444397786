import React from "react";
import { useFieldContext } from "../../FieldContext";
import { useFieldListContext } from "../../../FieldListContext";
import { Box } from "@mui/material";
import CreateButtons from "./CreateButtons";
import UpdateButtons from "./UpdateButtons";
import EditDeleteIconButtons from "./EditDeleteIconButtons";
import FieldHideButton from "./FieldHideButton";

export default function FieldGroupedButtons(props) {
	const {
		field,
		fieldHovered,
		moduleName,
		clearCreatedField,
		handleDeleteClick,
		onCancelField,
	} = props;
	const isFieldEditable = !field.config.isDefaultField;

	// field and field list context
	const { fieldListState } = useFieldListContext();

	const { fieldState } = useFieldContext(field);

	const isEyeIconVisible = () => {
		return (
			field.config.isFieldEnabled &&
			fieldHovered &&
			!isFieldEditable &&
			!fieldListState.isAnyFieldInEditMode &&
			!fieldListState.isAnyFieldInCreateMode &&
			!fieldState.isFieldValueChanged &&
			!fieldState.isSelectChoiceChanged
		);
	};

	const isEditDeleteButtonsVisible = () => {
		return (
			!(!fieldHovered || fieldState.fieldInCreateMode) &&
			isFieldEditable &&
			!fieldState.isFieldNameInEditMode &&
			!fieldListState.isAnyFieldInEditMode &&
			!fieldListState.isAnyFieldInCreateMode &&
			!fieldState.isFieldValueChanged &&
			!fieldState.isSelectChoiceChanged
		);
	};

	const isUpdateButtonsVisible = () => {
		return (
			(fieldState.isFieldValueChanged ||
				fieldState.isFieldNameInEditMode ||
				fieldState.isSelectChoiceChanged) &&
			!fieldState.fieldInCreateMode
		);
	};

	return (
		<React.Fragment>
			<Box>
				{fieldState.fieldInCreateMode ? (
					<CreateButtons
						field={field}
						moduleName={moduleName}
						clearCreatedField={clearCreatedField}
					/>
				) : null}
			</Box>

			{isUpdateButtonsVisible() ? (
				<Box>
					<UpdateButtons
						field={field}
						moduleName={moduleName}
						onCancelField={onCancelField}
					/>
				</Box>
			) : null}

			{isEditDeleteButtonsVisible() ? (
				<Box>
					<EditDeleteIconButtons
						field={field}
						handleDeleteClick={handleDeleteClick}
					/>
				</Box>
			) : null}

			{isEyeIconVisible() ? (
				<Box>
					<FieldHideButton field={field} moduleName={moduleName} />
				</Box>
			) : null}
		</React.Fragment>
	);
}
