import React from "react";
import TextComponent from "../TextComponent";

export default function TextAreaComponent({ rows, ...rest }) {
	return (
		<TextComponent
			{...rest}
			multiline
			rows={rows ? rows : 3}
		></TextComponent>
	);
}
