import postFieldsApi from "../postFieldsApi";

export const createFieldRequestData = async (data) => {
	let requestData = createFieldListRequestData(data);
	let response = await postFieldsApi(requestData);
	return response;
};

export const getFieldsListData = async (type, needGroupView) => {
	let requestData = getFieldsListRequestData(type, needGroupView);
	let response = await postFieldsApi(requestData);
	return response;
};

export const getFieldTypeData = async () => {
	let requestData = getFieldTypeRequestData();
	let response = await postFieldsApi(requestData);
	return response;
};

export const postFieldUpdate = async (data) => {
	let requestData = getFieldUpdateRequestData(data);
	let response = await postFieldsApi(requestData);
	return response;
};

export const postBulkFieldUpdate = async (data) => {
	let requestData = getBulkFieldUpdateRequestData(data);
	let response = await postFieldsApi(requestData);
	return response;
};

export const deleteField = async (id) => {
	let requestData = getDeleteFieldRequestData(id);
	let response = await postFieldsApi(requestData);
	return response;
};

export const getFieldDropdownData = async (data) => {
	let requestData = getFieldDropdownDataRequestData(data);
	return await postFieldsApi(requestData);
};

export const updateFieldListOrder = async ({ sourceId, ids }) => {
	let requestData = getUpdateFieldListOrderRequest(sourceId, ids);
	return await postFieldsApi(requestData);
};

const getUpdateFieldListOrderRequest = (sourceId, ids) => {
	return {
		type: "updateFieldOrder",
		data: {
			sourceId: sourceId,
			ids: ids,
		},
	};
};

const getFieldsListRequestData = (type, needGroupView) => {
	return {
		type: "list",
		data: {
			source: type,
			needGroupView: needGroupView,
		},
	};
};

const createFieldListRequestData = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getFieldTypeRequestData = () => {
	return {
		type: "fieldTypes",
		data: {},
	};
};

const getFieldUpdateRequestData = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getBulkFieldUpdateRequestData = (data) => {
	return {
		type: "bUpdate",
		data: {
			list: data,
		},
	};
};

const getDeleteFieldRequestData = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getFieldDropdownDataRequestData = (data) => {
	return {
		type: "dropdownData",
		data: {
			id: data.id,
			source: data.source,
			dependentId: data.dependentId,
			sourceId: data.sourceId,
			name: data.name,
			includeEmails: data.includeEmails,
			includeEmailsAndRoles: data.includeEmailsAndRoles,
			criteria: data.criteria,
			// start: 1,
			// limit: 2,
			// criteria: {
			//     type: "startsWith",
			//     value: "k",
			// },
		},
	};
};
