import {
	Box,
	Button,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import Dialog from "../Dialog";
import { FormFieldName, Tooltip, hexToRgba } from "../../../styles/twozo";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import React, { useState } from "react";

import { default as AddIcon } from "../../../assets/icons/add";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as ArrowSwapIcon } from "../../../assets/icons/arrowSwap";
import { default as CloudConnectionIcon } from "../../../assets/icons/cloudConnection";

export default function EmailManagementSyncDialog(props) {
	const {
		emailsSyncType,
		isSyncButtonDisabled = false,
		tooltipText = "",
	} = props;
	const theme = useTheme();

	const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState("");
	const providerList = getDropdownDataByName("PROVIDER_LIST");
	const title = "Connect Email Account";

	const handleChangeProvider = (event) => {
		const { value } = event.target;
		setSelectedProvider(value);
	};

	const handleCloseContactSyncDialog = () => {
		setIsSyncDialogOpen(false);
		setSelectedProvider("");
	};

	const connectSelectedProvider = () => {
		if (selectedProvider !== "") {
			// eslint-disable-next-line no-undef
			if (process.env.NODE_ENV === "development") {
				window.location.href = `/api/oauth/${emailsSyncType}/sync/${selectedProvider}?localRedirectionRequired=true`;
			} else {
				window.location.href = `/api/oauth/${emailsSyncType}/sync/${selectedProvider}`;
			}
		}
	};

	const FeatureText = (props) => (
		<Stack
			direction="row"
			justifyContent="center"
			spacing={2}
			py={2}
			pl={3}
		>
			{props.icon(20, 20, theme.palette.primary.main)}

			<Typography fontSize={14}>{props.text}</Typography>
		</Stack>
	);

	return (
		<React.Fragment>
			<Stack alignItems="center" py={3}>
				<Stack alignItems="flex-start">
					<FeatureText
						icon={ContactIcon}
						text="All Contacts or a specific group of contacts"
					/>

					<Divider style={{ width: "110%" }} />

					<FeatureText
						icon={ArrowSwapIcon}
						text="One way or Two way sync"
					/>

					<Divider style={{ width: "110%" }} />

					<FeatureText
						icon={CloudConnectionIcon}
						text="Sync with Google, Outlook.com"
					/>
				</Stack>

				<Box pt={5}>
					<Tooltip
						title={isSyncButtonDisabled ? tooltipText : ""}
						placement="top"
					>
						<Button
							variant="contained"
							startIcon={AddIcon(
								20,
								20,
								isSyncButtonDisabled
									? alpha("#FFFFFF", 0.6)
									: "#fff"
							)}
							disableElevation
							onClick={() => setIsSyncDialogOpen(true)}
							disabled={isSyncButtonDisabled}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: "rgba(51, 188, 126)",
									color: alpha("#FFFFFF", 0.6),
								},
							}}
							style={{
								pointerEvents: "auto",
							}}
						>
							Add New Account
						</Button>
					</Tooltip>
				</Box>
			</Stack>

			<Dialog open={isSyncDialogOpen} title={title}>
				<Stack spacing={2} p={2} pb={3}>
					<Stack spacing={0.5}>
						<FormFieldName>Select Email Provider</FormFieldName>

						<Select
							value={selectedProvider || ""}
							onChange={handleChangeProvider}
							renderValue={() =>
								selectedProvider === "" ? (
									<Typography
										fontSize={14}
										color={"rgba(0, 0, 0, 0.4)"}
									>
										Select
									</Typography>
								) : (
									selectedProvider
								)
							}
							fullWidth
							displayEmpty
						>
							{providerList.map((provider) => (
								<MenuItem
									value={provider.displayName}
									key={provider.value}
								>
									<Typography fontSize={14}>
										{provider.displayName}
									</Typography>
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack
						direction="row"
						pt={1}
						spacing={2}
						justifyContent="end"
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleCloseContactSyncDialog}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={connectSelectedProvider}
							disableElevation
							style={{
								color: selectedProvider
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Connect
						</Button>
					</Stack>
				</Stack>
			</Dialog>
		</React.Fragment>
	);
}
