import { getTableKey } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";
import { sourceType } from "../tableNames";

export const getActivityKey = () => {
	return [...getRootKey(), sourceType.ACTIVITY]; // Return the activity query key
};

//query Key
export const activityDueDateKey = [...getActivityKey(), "activityDueDate"];

export const calendarViewKey = [...getActivityKey(), "calendarViewData"];

//query Key Methods
export const getActivityTableKey = () => {
	return getTableKey(sourceType.ACTIVITY);
};

export const getActivityKeyById = (activityId) => {
	return [...getActivityKey(), String(activityId)];
};

export const getActivitySummaryKey = (activityId) => {
	return [...getActivityKeyById(activityId), "summary"];
};

export const getActivityDuedateKey = (sourceId) => {
	return [...getActivityKey(), String(sourceId), "activityDueDate"];
};

export const getActivityTypeListKey = () => {
	return [...getActivityKey(), "activityType"];
};

export const getActivityTypeActiveStatusKey = (isActive) => {
	return [...getActivityTypeListKey(), isActive];
};

export const getCollaboratorListKey = (activityId) => {
	return [...getActivityKeyById(activityId), "collaboratorList"];
};

export const getActivitiesCalendarViewDataKey = (
	weekCount,
	isWeekEnd,
	criteria
) => {
	if (Array.isArray(criteria)) {
		return [...calendarViewKey, weekCount, isWeekEnd, criteria];
	}
	return [...calendarViewKey, weekCount, isWeekEnd];
};

export const getCalendarViewWeekKey = (weekCount) => {
	return [...calendarViewKey, weekCount];
};
