import { useReducer } from "react";

export const filterActions = {
	toggleSwipeableFilter: "TOGGLE_SWIPEABLE_FILTER",
	updateFilterConditions: "UPDATE_FILTER_CONDITIONS",
	applyFilter: "APPLY_FILTER",
	removeAppliedFilter: "REMOVE_APPLIED_FILTER",
	deactivateEditMode: "DEACTIVATE_EDIT_MODE",
	activateEditMode: "ACTIVATE_EDIT_MODE",
	updateFilterCount: "UPDATE_FILTER_COUNT",
	updateApplyFilterId: "UPDATE_APPLY_FILTER_ID",
	openSwipeableFilter: "OPEN_SWIPEABLE_FILTER",
	closeSwipeableFilter: "CLOSE_SWIPEABLE_FILTER",
	updateActiveFilterName: "UPDATE_ACTIVE_FILTER_NAME",
	updateActiveFilterDetails: "UPDATE_ACTIVE_FILTER_DETAILS",
	updateActiveFilterConditions: "UPDATE_ACTIVE_FILTER_CONDITION",
};

export const initialState = {
	isSwipeableFilterOpened: false,
	filterConditions: {},
	filterData: [],
	filterCount: null,
	isEdited: true,
	appliedFilterId: null,
	activeFiltername: "",
	activeFilterDetails: {},
	activeFilterCondition: [],
};

export const reducer = (state, action) => {
	switch (action.type) {
		case filterActions.toggleSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: !state.isSwipeableFilterOpened,
			};
		case filterActions.updateFilterConditions:
			return {
				...state,
				filterData: action.payload,
			};
		case filterActions.applyFilter:
			return {
				...state,
				filterConditions: action.payload,
			};
		case filterActions.removeAppliedFilter:
			return {
				...state,
				filterConditions: {},
				filterData: [],
				filterCount: null,
			};
		case filterActions.deactivateEditMode:
			return {
				...state,
				isEdited: false,
			};
		case filterActions.activateEditMode:
			return {
				...state,
				isEdited: true,
			};
		case filterActions.updateFilterCount:
			return {
				...state,
				filterCount: action.payload,
			};
		case filterActions.updateApplyFilterId:
			return {
				...state,
				appliedFilterId: action.payload,
			};
		case filterActions.openSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: true,
			};
		case filterActions.closeSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: false,
			};
		case filterActions.updateActiveFilterName:
			return {
				...state,
				activeFiltername: action.payload,
			};
		case filterActions.updateActiveFilterDetails:
			return {
				...state,
				activeFilterDetails: action.payload,
			};
		case filterActions.updateActiveFilterConditions:
			return {
				...state,
				activeFilterCondition: action.payload,
			};
		default:
			return state;
	}
};

export const useFilterDataReducer = () => {
	const [filterState, filterStateDispatcher] = useReducer(
		reducer,
		initialState
	);

	const toggleSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.toggleSwipeableFilter });
	};

	const filterListByCondition = (filterCriteria) => {
		filterStateDispatcher({
			type: filterActions.applyFilter,
			payload: filterCriteria,
		});
	};

	const removeAppliedFilter = () => {
		filterStateDispatcher({ type: filterActions.removeAppliedFilter });
	};

	const deactivateEditMode = () => {
		filterStateDispatcher({ type: filterActions.deactivateEditMode });
	};

	const updateFilterCount = (filterConditionCount) => {
		filterStateDispatcher({
			type: filterActions.updateFilterCount,
			payload: filterConditionCount,
		});
	};

	const updateAppliedFilterId = (id) => {
		filterStateDispatcher({
			type: filterActions.updateApplyFilterId,
			payload: id,
		});
	};

	const updateFilterCondition = (conditions) => {
		filterStateDispatcher({
			type: filterActions.updateFilterConditions,
			payload: conditions,
		});
	};

	const activateEditMode = () => {
		filterStateDispatcher({ type: filterActions.activateEditMode });
	};

	const openSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.openSwipeableFilter });
	};

	const closeSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.closeSwipeableFilter });
	};

	const updateActiveFilterName = (filterName) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterName,
			payload: filterName,
		});
	};

	const updateActiveFilterDetails = (filterDetails) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterDetails,
			payload: filterDetails,
		});
	};

	const updateActiveFilterConditions = (filterCondition) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterConditions,
			payload: filterCondition,
		});
	};

	return {
		filterState,
		toggleSwipeableFilter,
		filterListByCondition,
		removeAppliedFilter,
		deactivateEditMode,
		updateFilterCount,
		updateAppliedFilterId,
		updateFilterCondition,
		activateEditMode,
		openSwipeableFilter,
		closeSwipeableFilter,
		updateActiveFilterName,
		updateActiveFilterDetails,
		updateActiveFilterConditions,
	};
};
