import { Box, IconButton, Stack, useTheme } from "@mui/material";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as FilterIcon } from "../../../../assets/icons/filter";
import React, { useEffect, useState } from "react";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import AutomationFilter from "./AutomationFilter";
import { useDebouncedCallback } from "use-debounce";

export default function AutomationMenu(params) {
	const {
		listContainerRef,
		updateFilter,
		updateFilterMenuOpen,
		updateSearchText,
	} = params;
	const theme = useTheme();

	const [isFilterDrawerOpened, setIsFilterDrawerOpened] = useState(false);
	const [topBarStartingPosition, setTopBarStartingPosition] = useState(0);

	useEffect(() => {
		if (listContainerRef?.current) {
			setTopBarStartingPosition(
				listContainerRef.current.getBoundingClientRect().top
			);
		}
	}, [listContainerRef]);

	const toggleFilterDrawer = () => {
		setIsFilterDrawerOpened((isFilterDrawerOpened) => {
			updateFilterMenuOpen(!isFilterDrawerOpened);
			return !isFilterDrawerOpened;
		});
	};

	const handleSearchDebounce = useDebouncedCallback(
		(event) => {
			handleChangeSearchValue(event.target.value);
		},
		250 // delay in ms
	);

	const handleChangeSearchValue = (value) => {
		updateSearchText(value?.trimStart());
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent", zIndex: 3 },
				}}
				variant="persistent"
				open={isFilterDrawerOpened}
				onOpen={toggleFilterDrawer}
				onClose={toggleFilterDrawer}
				disableBackdropClick={true}
				sx={{
					"& .MuiDrawer-paper": {
						height: `calc(100vh - ${topBarStartingPosition + 8}px)`,
						width: "20%",
						marginTop: `${topBarStartingPosition}px`,
					},
				}}
			>
				<Box
					style={{
						backgroundColor: "#fff",
					}}
				>
					<AutomationFilter updateFilter={updateFilter} />
				</Box>
			</CustomSwipeableDrawer>

			<Stack direction="row" alignItems="center" spacing={3} pr={3}>
				<MenuSearchBar
					style={{ width: "300px" }}
					onChange={handleSearchDebounce}
				/>

				<IconButton onClick={toggleFilterDrawer}>
					{FilterIcon(20, 20, theme.palette.primary.main)}
				</IconButton>
			</Stack>
		</React.Fragment>
	);
}
