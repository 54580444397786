import { useReducer } from "react";

export const filterActions = {
	showSaveViewAsButton: "SHOW_SAVE_VIEW_AS_BUTTON",
	hideSaveViewAsButton: "HIDE_SAVE_VIEW_AS_BUTTON",
	openSaveViewAsDialog: "OPEN_SAVE_VIEW_AS_DIALOG",
	closeSaveViewAsDialog: "CLOSE_SAVE_VIEW_AS_DIALOG",
	closeSwipeableFilter: "CLOSE_SWIPEABLE_FILTER",
};

export const initialState = {
	isSaveViewAsDialogOpened: false,
	isSaveViewAsButtonVisible: false,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case filterActions.showSaveViewAsButton:
			return {
				...state,
				isSaveViewAsButtonVisible: true,
			};
		case filterActions.hideSaveViewAsButton:
			return {
				...state,
				isSaveViewAsButtonVisible: false,
			};
		case filterActions.openSaveViewAsDialog:
			return {
				...state,
				isSaveViewAsDialogOpened: true,
			};
		case filterActions.closeSaveViewAsDialog:
			return {
				...state,
				isSaveViewAsDialogOpened: false,
			};
		default:
			return state;
	}
};

export const useFilterSaveViewReducer = () => {
	const [saveViewState, saveViewStateDispatcher] = useReducer(
		reducer,
		initialState
	);

	const showSaveViewAsButton = () => {
		saveViewStateDispatcher({ type: filterActions.showSaveViewAsButton });
	};

	const hideSaveViewAsButton = () => {
		saveViewStateDispatcher({ type: filterActions.hideSaveViewAsButton });
	};

	const handleOpenSaveViewAsDialog = () => {
		saveViewStateDispatcher({ type: filterActions.openSaveViewAsDialog });
	};

	const handleCloseSaveViewAsDialog = () => {
		saveViewStateDispatcher({ type: filterActions.closeSaveViewAsDialog });
	};

	return {
		saveViewState,
		showSaveViewAsButton,
		hideSaveViewAsButton,
		handleOpenSaveViewAsDialog,
		handleCloseSaveViewAsDialog,
	};
};
