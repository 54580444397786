import React, { useState } from "react";
import {
	Radio,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { TableHeaderLabel } from "../../../../../../../styles/twozo";

export default function ContactList() {
	const [contactfieldDetails, setContactFieldDetails] = useState([
		{
			id: 1,
			name: "First Name",
			value: "editable",
		},
		{
			id: 2,
			name: "Last Name",
			value: "editable",
		},
		{
			id: 3,
			name: "Email",
			value: "editable",
		},
	]);

	const handleChangePermission = (event, index) => {
		contactfieldDetails[index].value = event.target.value;
		setContactFieldDetails([...contactfieldDetails]);
	};

	return (
		<React.Fragment>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} size="small">
					<TableHead>
						<TableRow>
							<TableCell colSpan={4}>
								<Typography fontSize={16} fontWeight={500}>
									Contacts
								</Typography>
							</TableCell>
						</TableRow>

						<TableRow style={{ width: "100%" }}>
							<TableCell style={{ width: "35%" }}>
								<TableHeaderLabel>Fields</TableHeaderLabel>
							</TableCell>

							<TableCell style={{ width: "15%" }}>
								<TableHeaderLabel>Editable</TableHeaderLabel>
							</TableCell>

							<TableCell style={{ width: "15%" }}>
								<TableHeaderLabel>Read Only</TableHeaderLabel>
							</TableCell>

							<TableCell style={{ width: "35%" }}>
								<TableHeaderLabel>Hidden</TableHeaderLabel>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{contactfieldDetails.map((field, index) => (
							<TableRow key={field.id} style={{ width: "100%" }}>
								<TableCell style={{ width: "35%" }}>
									{field.name}
								</TableCell>

								<TableCell style={{ width: "15%" }}>
									<Radio
										sx={{ padding: 0 }}
										checked={field.value === "editable"}
										onChange={(event) =>
											handleChangePermission(event, index)
										}
										value="editable"
									/>
								</TableCell>

								<TableCell style={{ width: "15%" }}>
									<Radio
										sx={{ padding: 0 }}
										checked={field.value === "readOnly"}
										onChange={(event) =>
											handleChangePermission(event, index)
										}
										value="readOnly"
									/>
								</TableCell>

								<TableCell style={{ width: "35%" }}>
									<Radio
										sx={{ padding: 0 }}
										checked={field.value === "hidden"}
										onChange={(event) =>
											handleChangePermission(event, index)
										}
										value="hidden"
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
}
