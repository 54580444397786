import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../auth";

// Company field permissions
const useCompanyFieldPermissions = () => {
	const { isUserAllowedFor } = useAuth();

	return {
		phone: {
			edit: isUserAllowedFor(PERMISSIONS.company.edit),
		},
		email: {
			edit: isUserAllowedFor(PERMISSIONS.company.edit),
		},
		owner: {
			enabled: isUserAllowedFor(PERMISSIONS.company.assignRecords),
		},
	};
};

export { useCompanyFieldPermissions };
