import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { default as AddIcon } from "../../../../../assets/icons/add";
import AddUser from "../AddUser";
import { twozoStyles } from "../../../../../styles/twozo";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";

export default function UsersMenu() {
	const classes = twozoStyles();
	const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);

	const toggleAddUserDialog = () => {
		setIsAddUserDialogOpen((isAddUserDialogOpen) => !isAddUserDialogOpen);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddUserDialogOpen}
				onOpen={toggleAddUserDialog}
				onClose={toggleAddUserDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddUser onClose={toggleAddUserDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Stack direction="row" spacing={2}>
				<Button variant="contained" color="secondary" disableElevation>
					Import Users
				</Button>

				<Button
					variant="contained"
					startIcon={AddIcon(20, 20, "#fff")}
					color="primary"
					disableElevation
					onClick={toggleAddUserDialog}
				>
					User
				</Button>
			</Stack>
		</React.Fragment>
	);
}
