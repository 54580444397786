import React from "react";
import { Typography } from "@mui/material";
import { notificationMessageComponents } from "../../../../../utils/notificationUtils";

export default function NotificationContent(props) {
	const {
		notificationData,
		onClose,
		notificationContentColor,
		isNotificationActionEnabled,
	} = props;

	const NotificationContentComponent =
		notificationMessageComponents?.[notificationData?.type]?.content;

	return (
		<React.Fragment>
			<Typography
				fontSize={13}
				fontWeight={400}
				style={{ wordBreak: "break-word" }}
			>
				<NotificationContentComponent
					notificationData={notificationData}
					onClose={onClose}
					textColor={notificationContentColor}
					isNotificationActionEnabled={isNotificationActionEnabled}
				/>
			</Typography>
		</React.Fragment>
	);
}
