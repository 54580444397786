export const styles = {
	socialMediaLogoContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50px",
		width: 20,
		height: 20,
		cursor: "pointer",
	},
	imageContainer: {
		cursor: "pointer",
	},
	cardTitle: {
		fontSize: 18,
		fontWeight: 500,
	},
	text: {
		fontSize: 14,
		fontWeight: 400,
		opacity: 0.6,
	},
	dealCardText: {
		fontSize: 14,
		fontWeight: 500,
		opacity: 0.6,
	},
	subText: {
		fontSize: 13,
		color: "rgba(0, 0, 0, 0.6)",
	},
	moreContent: {
		fontSize: 13,
		color: "rgba(0, 0, 0, 0.6)",
		fontWeight: 600,
	},
};
