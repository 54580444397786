import React, { useEffect, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldId } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";

export default function CompanyComponent(props) {
	const { field, style, onChange, hasError } = props;
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		setSelected(
			field.values && field.values.length > 0 ? field.values[0] : null
		);
	}, [field]);

	const { data: options } = useQuery(
		getDropdownKeyByFieldId(field.fieldId),
		() => getFieldDropdownData({ name: "companyList" }),
		{
			enabled: !!field?.fieldId,
			select: (data) => {
				//TODO - Get consistent value from dropdown API
				return (
					data?.options?.map((option) => {
						if (option.id) {
							option["value"] = option["id"];
						} else if (option.value) {
							option["id"] = option["value"];
						}
						return option;
					}) || []
				);
			},
		}
	);

	const onFieldChange = (value) => {
		field["values"] = [value];
		setSelected(value);
		if (onChange) {
			onChange([value]);
		}
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				selected={selected}
				options={options || []}
				onFieldChange={onFieldChange}
				style={style}
				hasError={hasError}
			/>
		</React.Fragment>
	);
}
