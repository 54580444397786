import React from "react";
import { Box } from "@mui/material";
import { Tooltip } from "../../../../../styles/twozo";
import { getIconByName } from "../../../../../utils";

export default function TopActivitiesProgressBar(props) {
	const { topActivities } = props;

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "row",
					height: "44px",
					borderRadius: "10px",
					overflow: "hidden",
				}}
			>
				{topActivities?.map((activity, index) => (
					<Tooltip
						title={`${activity.count} ${
							activity.icon
								? activity.icon.displayName
								: activity.name
						} ${"•"} ${activity.percentage}%`}
						key={index}
					>
						<Box
							style={{
								height: "100%",
								width: `${activity.percentage}%`,
								backgroundColor: `rgba(51, 122, 188, ${
									(topActivities.length - index) /
									topActivities.length
								})`,
								display: "flex",
								alignItems: "center",
								paddingLeft: "16px",
								cursor: "pointer",
							}}
						>
							{!!activity.icon &&
								getIconByName(activity.icon.name)(
									24,
									24,
									"#fff"
								)}
						</Box>
					</Tooltip>
				))}
			</Box>
		</React.Fragment>
	);
}
