import { useTheme } from "@emotion/react";
import {
	Box,
	Chip,
	InputAdornment,
	Paper,
	Popper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import AddIcon from "../../../../../assets/icons/add";
import CloseIcon from "../../../../../assets/icons/close";
import DropdownIcon from "../../../../../assets/icons/dropDownCentered";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { useState } from "react";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function AutocompleteBaseComponent(params) {
	const {
		values,
		hasError,
		style,
		onChange,
		isValidOption,
		options,
		type,
		isNewOption,
		placeholder = "Select",
	} = params;

	const theme = useTheme();
	const [searchValue, setSearchValue] = useState("");

	const filterOptions = (options, { inputValue }) => {
		const filteredOptions = options.filter((option) =>
			option?.value?.name
				?.toLowerCase()
				?.includes(inputValue?.toLowerCase())
		);

		const isExisting = options.some(
			(option) =>
				option?.value?.name?.toLowerCase() === inputValue?.toLowerCase()
		);

		if (inputValue !== "" && !isExisting && isNewOption) {
			filteredOptions.push({
				value: { name: inputValue },
				isNewOption: true,
			});
		}

		return filteredOptions;
	};

	const isOpenMenu = searchValue?.trim()?.length > 0;

	return (
		<MuiAutocomplete
			size="small"
			options={Array.isArray(options) ? options : []}
			value={values}
			open={isOpenMenu}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			isOptionEqualToValue={(option, value) => {
				return option?.value?.name === value?.value?.name;
			}}
			forcePopupIcon={false}
			multiple
			style={{ width: "100%" }}
			onChange={(e, value) => {
				onChange(value);
				setSearchValue("");
			}}
			disableClearable
			filterSelectedOptions
			getOptionLabel={(option) => option?.value?.name || ""}
			renderTags={(selected, getTagProps) =>
				Array.isArray(selected) &&
				selected.map((option, index) => (
					<Chip
						key={index}
						size="small"
						label={
							<Stack direction="row" spacing={0.5}>
								<Typography fontSize={14} color="#000">
									{option?.value?.name || ""}
								</Typography>
							</Stack>
						}
						deleteIcon={CloseIcon(16, 16, "#000")}
						color="secondary"
						sx={{
							borderRadius: "8px",
							backgroundColor:
								isValidOption &&
								!isValidOption(option) &&
								theme.palette.warning.main,
						}}
						{...getTagProps({ index })}
					/>
				))
			}
			renderOption={(props, option) => (
				<Box
					key={option?.id}
					{...props}
					style={{
						minHeight: "40px",
					}}
				>
					{option.isNewOption ? (
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width="100%"
						>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								{option?.value?.name || ""}
							</Typography>

							<Box display="flex">
								{AddIcon(20, 20, theme.palette.primary.main)}
							</Box>
						</Stack>
					) : (
						<Typography fontSize={13} fontWeight={400}>
							{option?.value?.name || ""}
						</Typography>
					)}
				</Box>
			)}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={placeholder}
					type={type ? type : "text"}
					onBlur={() => setSearchValue("")}
					onChange={(event) => setSearchValue(event.target.value)}
					sx={{
						backgroundColor: "#fff",
						borderRadius: "8px",
						"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
							{
								"-webkit-appearance": "none",
							},
						...style,
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<InputAdornment position="start">
								<Box
									style={{
										transform:
											isOpenMenu && "rotate(180deg)",
									}}
								>
									{DropdownIcon(14, 14, "rgb(0 0 0 / 54%)")}
								</Box>
							</InputAdornment>
						),
					}}
					error={hasError}
					inputProps={{
						...params.inputProps,
						style: {
							fontSize: "14px",
						},
					}}
				/>
			)}
		/>
	);
}
