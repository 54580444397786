import {
	Box,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { default as RightArrowIcon } from "../../../assets/icons/dropDownRight";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import CrmSettings from "./Settings";
import { useNavigate, useSearchParams } from "react-router-dom";
import Account from "./Account";
import PlansAndBillings from "./PlansAndBillings";

const styles = {
	text: {
		fontSize: "15px",
		fontWeight: 500,
	},
};

const accountSettings = {
	crmSettings: "settings",
	account: "account",
	plansAndBillings: "plans_and_billings",
};

export default function AccountSettings() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [viewType, setViewType] = useState(
		searchParams.get("type") || accountSettings.crmSettings
	);

	const handleChangeView = (_, type) => {
		if (type) {
			setViewType(type);
			setSearchParams({ type: type });
		}
	};

	return (
		<React.Fragment>
			<Box py={2} px={3}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography
						style={{ ...styles.text, cursor: "pointer" }}
						color={theme.palette.primary.main}
						onClick={() => navigate("/settings")}
					>
						Settings
					</Typography>

					<Box display="flex">{RightArrowIcon(16, 16)}</Box>

					<Typography style={styles.text}>
						Account Settings
					</Typography>
				</Stack>
			</Box>

			<Divider />

			<Stack px={2} pt={2} pb={1.5} direction="row" alignItems="center">
				<ToggleButtonGroup onChange={handleChangeView} value={viewType}>
					<ToggleButton value={accountSettings.crmSettings}>
						CRM Settings
					</ToggleButton>

					<ToggleButton value={accountSettings.account}>
						Accounts
					</ToggleButton>

					<ToggleButton value={accountSettings.plansAndBillings}>
						Plans and Billing
					</ToggleButton>
				</ToggleButtonGroup>
			</Stack>

			<Box>
				{(function () {
					switch (viewType) {
						case accountSettings.crmSettings:
							return <CrmSettings />;
						case accountSettings.account:
							return <Account />;
						case accountSettings.plansAndBillings:
							return <PlansAndBillings />;
						default:
							null;
					}
				})()}
			</Box>
		</React.Fragment>
	);
}
