import React from "react";

export default function Strikeout(width = 24, height = 24, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.112 21.208C10.812 21.208 9.56404 21.0173 8.36804 20.636C7.18938 20.2373 6.24471 19.7087 5.53404 19.05C5.27404 18.8073 5.14404 18.504 5.14404 18.14C5.14404 17.8973 5.21338 17.6893 5.35204 17.516C5.49071 17.3253 5.65538 17.23 5.84604 17.23C6.07138 17.23 6.31404 17.3253 6.57404 17.516C8.18604 18.764 10.0234 19.388 12.086 19.388C13.5767 19.388 14.7207 19.102 15.518 18.53C16.3154 17.958 16.714 17.1433 16.714 16.086C16.714 15.4793 16.5234 14.994 16.142 14.63C15.7607 14.2487 15.258 13.9453 14.634 13.72C14.01 13.4947 13.1694 13.2607 12.112 13.018C10.6907 12.6887 9.52071 12.342 8.60204 11.978C7.70071 11.614 6.96404 11.094 6.39204 10.418C5.83738 9.74201 5.56004 8.85801 5.56004 7.76601C5.56004 6.72601 5.83738 5.80734 6.39204 5.01001C6.96404 4.19534 7.75271 3.57134 8.75804 3.13801C9.78071 2.68734 10.942 2.46201 12.242 2.46201C13.4554 2.46201 14.582 2.65267 15.622 3.03401C16.6794 3.39801 17.5634 3.92667 18.274 4.62001C18.5514 4.89734 18.69 5.20067 18.69 5.53001C18.69 5.77267 18.6207 5.98934 18.482 6.18001C18.3434 6.35334 18.1787 6.44001 17.988 6.44001C17.7974 6.44001 17.546 6.34467 17.234 6.15401C16.3847 5.46067 15.596 4.97534 14.868 4.69801C14.14 4.42067 13.2647 4.28201 12.242 4.28201C10.8034 4.28201 9.68538 4.58534 8.88804 5.19201C8.09071 5.78134 7.69204 6.61334 7.69204 7.68801C7.69204 8.60667 8.03871 9.30001 8.73204 9.76801C9.44271 10.2187 10.526 10.6173 11.982 10.964C13.5594 11.3453 14.7987 11.7007 15.7 12.03C16.6014 12.342 17.3467 12.8187 17.936 13.46C18.5427 14.1013 18.846 14.9593 18.846 16.034C18.846 17.0567 18.56 17.958 17.988 18.738C17.4334 19.518 16.6447 20.1247 15.622 20.558C14.5994 20.9913 13.4294 21.208 12.112 21.208Z"
				fill={color}
			/>
			<path
				d="M1.75 12.25H22.25"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
