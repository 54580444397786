export default function Vector(width = 20, height = 20, color = "#000") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 12 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.11974 8.85359V13.6536C4.11974 14.7736 4.72641 15.0003 5.46641 14.1603L10.5131 8.42692C11.1331 7.72692 10.8731 7.14692 9.93308 7.14692H7.87308V2.34692C7.87308 1.22692 7.26641 1.00026 6.52641 1.84026L1.47974 7.57359C0.866409 8.28026 1.12641 8.85359 2.05974 8.85359H4.11974Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
