import React from "react";
import SingleDatePicker from "../../../../Elements/SingleDatePicker";
import dayjs from "dayjs";

export default function DateComponent(props) {
	const { field = {}, onChange, hasError } = props;

	const handleChange = (e) => {
		if (!field.values || !Array.isArray(field.values)) {
			field.values = [{ value: "" }];
		}
		field.values[0].value = e.valueOf();
		if (onChange) {
			onChange(field.values);
		}
	};

	return (
		<React.Fragment>
			<SingleDatePicker
				textFieldProps={{
					fullWidth: true,
					sx: {
						backgroundColor: "#fff",
						borderRadius: 2,
					},
				}}
				error={hasError}
				defaultValue={
					field.values && field.values.length > 0
						? dayjs(field.values[0].value)
						: null
				}
				onChange={handleChange}
			/>
		</React.Fragment>
	);
}
