import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Checkbox,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { default as FilterIcon } from "../../../assets/icons/filter";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as MailOpenedIcon } from "../../../assets/icons/mailOpenSimple";
import { default as DealIcon } from "../../../assets/icons/deals";
import { default as AttachmentIcon } from "../../../assets/icons/attachment";
import { default as ContactImage } from "../../../assets/images/contact/unknownContact.png";
import EmailFilter from "../EmailFilter";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";

export default function TeamInbox() {
	const theme = useTheme();
	const teamInboxRef = useRef(null);
	const [isEmailFilterOpened, setIsEmailFilterOpened] = useState(false);
	const [topBarHeight, setTopBarHeight] = useState(0);

	const filtersData = [
		{
			id: 1,
			filter: [{ id: 1, label: "Email" }],
		},
	];

	const mails = [
		{
			contactName: "Wirot Richter",
			contactImage: ContactImage,
			contactDescription: "Customer Support, Twozo Support",
			subject: "Upgrade",
			body: "Hi Kadin, Hope you are doing good, As we discussed, Here I’m attaching the file that contains upgraded software in it. ",
			time: "21 Jun 2023, 09.25 AM",
			opened: false,
			hasAttachment: false,
			hasDeal: false,
		},
		{
			contactName: "Prasoet Kumar",
			contactImage: ContactImage,
			contactDescription: "Customer Support",
			subject: "Hello Team! - Issue in Plan Purchase",
			body: "Hi team, I’ve purchased a plan and its not reflected in my account.",
			time: "21 Jun 2023, 09.25 AM",
			opened: true,
			hasAttachment: false,
			hasDeal: false,
		},
		{
			contactName: "Mukesh Fiala",
			contactImage: ContactImage,
			contactDescription: "Sales, Customer Support",
			subject: "Timeframe extension",
			body: "Team, the mentioned timeframe is not suitable for us",
			time: "21 Jun 2023, 09.25 AM",
			opened: true,
			hasAttachment: false,
			hasDeal: false,
		},
		{
			contactName: "Prasoet Kumar",
			contactImage: ContactImage,
			contactDescription: "Customer Support",
			subject: "Hello Team! - Issue in Contacting Sales",
			body: "Hi team, I’ve purchased a plan and its not reflected in my account.",
			time: "21 Jun 2023, 09.25 AM",
			opened: true,
			hasAttachment: false,
			hasDeal: false,
		},
		{
			contactName: "Sibongile Pietrzak",
			contactImage: ContactImage,
			isMultipleContact: true,
			contactDescription: "Sales, Customer Support",
			subject: "Help - Assistance Needed",
			body: "I’ve raised one as contact and assigned few deals on the contact. But its not displaying.",
			time: "21 Jun 2023, 09.25 AM",
			opened: true,
			hasAttachment: false,
			hasDeal: false,
		},
		{
			contactName: "Anthony Liu",
			contactImage: ContactImage,
			contactDescription: "Product Sales Team",
			subject: "Hello Team! - Issue in Deals",
			body: "Hi Team, Couldn’t update status of a deal.",
			time: "21 Jun 2023, 09.25 AM",
			opened: true,
			hasAttachment: true,
			hasDeal: false,
		},
	];

	const toggleSwipeableFilter = () => {
		setIsEmailFilterOpened((isEmailFilterOpened) => !isEmailFilterOpened);
	};

	useEffect(() => {
		if (teamInboxRef) {
			setTopBarHeight(teamInboxRef.current.getBoundingClientRect().top);
		}
	}, [teamInboxRef]);

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				sx={{
					"& .MuiDrawer-paper": {
						marginRight: "8px",
						marginTop: `${topBarHeight}px`,
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						width: "260px",
					},
				}}
				variant="persistent"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				anchor="right"
				open={isEmailFilterOpened}
				onOpen={toggleSwipeableFilter}
				onClose={toggleSwipeableFilter}
				disableBackdropClick={true}
			>
				<Box>
					<EmailFilter filtersData={filtersData} />
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
				>
					<Stack direction="row" alignItems="center">
						<Box px={2}>
							<Checkbox />
						</Box>

						<Typography fontWeight={500}>Team Inbox</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						<MenuSearchBar style={{ width: "300px" }} />

						<IconButton onClick={toggleSwipeableFilter}>
							{FilterIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Stack>

				<Box
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
					}}
					ref={teamInboxRef}
					style={{
						marginRight: isEmailFilterOpened ? "260px" : null,
						transition: isEmailFilterOpened
							? theme.transitions.create("margin", {
									easing: theme.transitions.easing.easeOut,
									duration:
										theme.transitions.duration
											.enteringScreen,
								})
							: theme.transitions.create("margin", {
									easing: theme.transitions.easing.sharp,
									duration:
										theme.transitions.duration
											.leavingScreen,
								}),
					}}
				>
					<Table
						size="small"
						style={{ width: "100%", tableLayout: "fixed" }}
					>
						<TableBody sx={{ cursor: "pointer" }}>
							{mails.map((mail, index) => (
								<TableRow
									key={index}
									sx={{
										height: "70px",
										backgroundColor: mail.opened
											? "transparent"
											: "#FAFAFA",
									}}
								>
									<TableCell
										style={{
											width: "50px",
											borderLeft: mail.opened
												? "none"
												: `4px solid ${theme.palette.primary.main}`,
											padding: mail.opened
												? "6px 16px"
												: "6px 12px",
										}}
									>
										<Checkbox />
									</TableCell>

									<TableCell
										style={{
											width: "30px",
											padding: "6px 4px",
										}}
									>
										<Box display="flex">
											{mail.opened
												? MailOpenedIcon(
														22,
														22,
														"rgba(0, 0, 0, 0.3)"
													)
												: MailIcon(
														22,
														22,
														theme.palette.primary
															.main
													)}
										</Box>
									</TableCell>

									<TableCell style={{ width: "255px" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<img
												src={mail.contactImage}
												alt="contact_image"
												height="24px"
												width="24px"
											/>

											<Stack
												direction="row"
												alignItems="center"
												spacing={0.5}
											>
												<Typography
													fontWeight={
														mail.opened ? 400 : 500
													}
													fontSize={14}
												>
													{mail.contactName}
												</Typography>
												{mail.isMultipleContact && (
													<Typography
														fontSize={13}
														fontWeight={500}
														color={
															theme.palette
																.primary.main
														}
													>
														+3
													</Typography>
												)}
											</Stack>
										</Stack>

										<Typography
											fontSize={13}
											color="rgba(0, 0, 0, 0.6)"
										>
											{mail.contactDescription}
										</Typography>
									</TableCell>

									<TableCell>
										<Typography fontSize={14} noWrap>
											{mail.subject}
										</Typography>

										<Typography
											style={{
												fontSize: "14px",
												color: "rgba(0, 0, 0, 0.6)",
											}}
											noWrap
										>
											{mail.body}
										</Typography>
									</TableCell>

									<TableCell
										style={{
											width: "35px",
											padding: "0px",
										}}
									>
										<Box hidden={!mail.hasDeal}>
											<IconButton
												style={{ padding: "2px" }}
											>
												{DealIcon(
													22,
													22,
													theme.palette.primary.main
												)}
											</IconButton>
										</Box>
									</TableCell>

									<TableCell
										style={{
											width: "35px",
											padding: "0px",
										}}
										align="right"
									>
										<Box hidden={!mail.hasAttachment}>
											<IconButton
												style={{ padding: "2px" }}
											>
												{AttachmentIcon(
													22,
													22,
													theme.palette.primary.main
												)}
											</IconButton>
										</Box>
									</TableCell>

									<TableCell
										style={{
											width: "180px",
											padding: "6px 16px",
											textAlign: "end",
										}}
									>
										<Typography
											fontSize={14}
											color={
												mail.opened
													? "rgba(0, 0, 0, 0.6)"
													: "#000"
											}
										>
											{mail.time}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</Box>
		</React.Fragment>
	);
}
