const dealState = [
	{
		name: "Open",
		value: 1,
	},
	{
		name: "Won",
		value: 2,
	},
	{
		name: "Lost",
		value: 3,
	},
];

export default dealState;
