import React from "react";

export default function FilesUploadDone(
	width = 24,
	height = 24,
	color = "#292D32"
) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18 10C15 10 14 9 14 6V2L22 10H18Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7 14.5L9 16.5L13 12.5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
