import { default as DealsIcon } from "../../../assets/icons/deals";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as ActivityIcon } from "../../../assets/icons/activity";

const workflowTriggerSourceList = [
	{
		name: "Activity",
		value: 1,
		icon: ActivityIcon,
	},
	{
		name: "Deal",
		value: 2,
		icon: DealsIcon,
	},
	{
		name: "Contact",
		value: 3,
		icon: ContactIcon,
	},
	{
		name: "Company",
		value: 4,
		icon: CompanyIcon,
	},
];

export default workflowTriggerSourceList;
