import { Typography, useTheme } from "@mui/material";
import React from "react";

export default function RenderSingleStringFieldValue(props) {
	const { fieldValue } = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			{fieldValue?.name ? (
				<Typography
					fontWeight={fieldValue?.type ? 500 : 400}
					fontSize={14}
					color={
						fieldValue?.type === "SUCCESS"
							? theme.palette.primary.main
							: fieldValue?.type === "FAILURE"
								? theme.palette.error.main
								: "#000000"
					}
				>
					{fieldValue?.name}
				</Typography>
			) : (
				<Typography fontSize={14}>{fieldValue}</Typography>
			)}
		</React.Fragment>
	);
}
