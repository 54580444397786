import React from "react";
import ShortFiles from "../../../Summary/ShortFiles";
import { modules } from "../../../../utils/common/ModulesName";

export default function DealFiles({ dealId }) {
	return (
		<React.Fragment>
			<ShortFiles entityId={dealId} sourceName={modules.DEAL} />
		</React.Fragment>
	);
}
