export const dealViews = {
	kanbanView: "kanban",

	listView: "list",

	forecastView: "forecast",
};

export const dealState = {
	open: "open",
	won: "won",
	lost: "lost",
};
