import React from "react";

export default function NotificationPending(
	width = "24",
	height = "24",
	color = "#000"
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 24 24"
		>
			<path
				d="M18.02 8.90991V11.7999C18.02 12.4099 18.28 13.3399 18.58 13.8599L19.73 15.7699C20.39 16.8699 19.86 18.2999 18.65 18.6999C14.34 20.1399 9.68997 20.1399 5.37997 18.6999C4.07997 18.2599 3.58997 16.9499 4.29997 15.7699L5.44997 13.8599C5.75997 13.3399 6.01997 12.4099 6.01997 11.7999V8.90991C6.01997 5.59991 8.70997 2.90991 12.02 2.90991C13.2962 2.90991 14.4828 3.31377 15.4581 4"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
				stroke={color}
				strokeWidth="1.5"
			/>
			<circle
				cx="16.5"
				cy="6.5"
				r="2.5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
}
