const availability = [
	{
		name: "Busy",
		value: 1,
	},
	{
		name: "Free",
		value: 2,
	},
];
export default availability;
