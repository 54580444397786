import { rawTwozoStyles } from "../../styles/twozo";

function parsJsonToCss(styles) {
	let css = [];
	for (let selector in styles) {
		let style = "." + selector + " {\n";

		for (let property in styles[selector]) {
			const parsedProperty = property
				.split(/(?=[A-Z])/)
				.map((property) => property.toLowerCase())
				.join("-");
			style +=
				"  " +
				parsedProperty +
				":" +
				styles[selector][property] +
				";\n";
		}

		style += "}";
		css.push(style);
	}
	return css.join("\n");
}

export function parseHTML(str, classes) {
	const html = document.createElement("html");
	const head = document.createElement("head");
	const style = document.createElement("style");
	const body = document.createElement("body");
	body.style.fontSize = "14px";
	body.style.fontFamily = "Lexend";
	body.innerHTML = str.trim();

	let classesMapper = {};
	let classNames = new Set();
	let nodes = parseHTMLNode(body, 1, classNames);
	let stylesJson = {};

	Object.keys(classes).forEach((key) => {
		classesMapper[classes[key]] = key;
	});

	classNames.forEach((className) => {
		stylesJson[className] = rawTwozoStyles[classesMapper[className]];
	});

	let stylesCss = parsJsonToCss(stylesJson);

	let textNode = document.createTextNode(stylesCss);
	style.appendChild(textNode);
	head.appendChild(style);
	html.appendChild(head);
	html.appendChild(nodes);
	return html.outerHTML;
}

function parseHTMLNode(node, level, classNames) {
	const className = node.getAttribute("class");
	if (className) {
		classNames.add(className);
	}

	const indentBefore = new Array(level++ + 1).join("  ");
	const indentAfter = new Array(level - 1).join("  ");
	let textNode;

	for (let i = 0; i < node.children.length; i++) {
		textNode = document.createTextNode("\n" + indentBefore);
		node.insertBefore(textNode, node.children[i]);
		parseHTMLNode(node.children[i], level, classNames);

		if (node.lastElementChild === node.children[i]) {
			textNode = document.createTextNode("\n" + indentAfter);
			node.appendChild(textNode);
		}
	}

	return node;
}
