import React from "react";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";

export default function LogCall() {
	return (
		<React.Fragment>
			<Stack p={2} spacing={2}>
				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						To/From
					</Typography>

					<Select value="1">
						<MenuItem value="1">
							<Typography fontSize={14}>Ann Dorwart</Typography>
						</MenuItem>
					</Select>
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Call Type
					</Typography>

					<FormControl>
						<RadioGroup defaultValue="outgoing" row>
							<FormControlLabel
								value="incoming"
								control={<Radio />}
								label={
									<Typography fontSize={14} pr={2}>
										Incoming
									</Typography>
								}
							/>
							<FormControlLabel
								value="outgoing"
								control={<Radio />}
								label={
									<Typography fontSize={14}>
										Outgoing
									</Typography>
								}
							/>
						</RadioGroup>
					</FormControl>
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Call Start Time
					</Typography>

					<TextField value="13 Oct 2021 03:15 PM" />
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Related To
					</Typography>

					<TextField value="Website Optimization" disabled />
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Agenda
					</Typography>

					<TextField value="Discussed on the deal finalization and navigated to plan selection" />
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Outcome
					</Typography>

					<Select value="1">
						<MenuItem value="1">
							<Typography fontSize={14}>Select</Typography>
						</MenuItem>
					</Select>
				</Stack>

				<Box textAlign="right" p={1}>
					<Button variant="contained" disableElevation>
						Save
					</Button>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
