import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as FavouriteOffIcon } from "../../../assets/icons/favouriteOff";
import { default as FavouriteOnIcon } from "../../../assets/icons/favouriteOn";
import { default as CopyIcon } from "../../../assets/icons/copy";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import { default as ReloadIcon } from "../../../assets/icons/reload";
import {
	FormFieldName,
	TableCellText,
	TableHeaderLabel,
	Tooltip,
} from "../../../styles/twozo";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import DeleteDialog from "../../Elements/DeleteDialog";
import Dialog from "../../Elements/Dialog";

export default function ReportsList() {
	const theme = useTheme();
	const navigate = useNavigate();
	let { reportName } = useParams();
	const [reportsListData] = useOutletContext();
	const inboxRef = useRef(null);
	const [topBarHeight, setTopBarHeight] = useState(0);
	const [reportListHeader, setReportListHeader] = useState("");
	const [reportsData, setReportsData] = useState([]);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [deleteReportName, setDeleteReportName] = useState("");
	const [isCloneDialogOpened, setIsCloneDialogOpened] = useState(false);
	const [cloneReportName, setCloneReportName] = useState("");

	useEffect(() => {
		if (inboxRef) {
			setTopBarHeight(inboxRef.current.getBoundingClientRect().top);
		}
	}, [inboxRef]);

	useEffect(() => {
		if (reportsListData[reportName]) {
			setReportListHeader(reportsListData[reportName]["label"]);
			setReportsData(reportsListData[reportName]["data"] || []);
		}
	}, [reportName]);

	const toggleFavourite = (index) => {
		setReportsData((reportsData) => {
			let updatedReportsData = [...reportsData];
			updatedReportsData[index]["favourite"] =
				!updatedReportsData[index]["favourite"];
			return updatedReportsData;
		});
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);

	const onMouseOutTableRow = () => setTableRowHovered(null);

	const openDeleteReportDialog = (reportName) => {
		setIsDeleteDialogOpened(true);
		setDeleteReportName(reportName);
	};

	const closeDeleteReportDialog = () => {
		setIsDeleteDialogOpened(false);
		setDeleteReportName("");
	};

	const openCloneReportDialog = (reportName) => {
		setIsCloneDialogOpened(true);
		setCloneReportName("Copy of " + reportName);
	};

	const closeCloneReportDialog = () => {
		setIsCloneDialogOpened(false);
		setCloneReportName("");
	};

	const CuratedTag = () => (
		<span
			style={{
				fontSize: "12px",
				fontWeight: 500,
				color: theme.palette.secondary.contrastText,
				backgroundColor: theme.palette.secondary.main,
				padding: "4px",
				borderRadius: "6px",
			}}
		>
			Curated
		</span>
	);

	return (
		<React.Fragment>
			<Dialog
				open={isCloneDialogOpened}
				onClose={closeCloneReportDialog}
				title="Clone Report"
			>
				<Stack spacing={2} p={2}>
					<Stack spacing={0.5}>
						<FormFieldName>Report Name</FormFieldName>
						<TextField value={cloneReportName} />
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName>Description</FormFieldName>
						<TextField minRows={2} maxRows={6} multiline />
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						spacing={2}
						px={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={closeCloneReportDialog}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={closeCloneReportDialog}
						>
							Clone
						</Button>
					</Stack>
				</Stack>
			</Dialog>

			<DeleteDialog
				title={`Are you sure you want to move this report “${deleteReportName}”to trash?`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteReportDialog}
				onDelete={closeDeleteReportDialog}
			/>

			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					px={2}
				>
					<Box>
						<Typography fontWeight={500}>
							{reportListHeader}
						</Typography>
					</Box>

					<Box width="300px">
						<MenuSearchBar />
					</Box>
				</Stack>

				<Box
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
					}}
					ref={inboxRef}
				>
					{reportsData.length > 0 ? (
						<Table
							size="small"
							style={{
								width: "100%",
								tableLayout: "fixed",
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell width="5%" />

									<TableCell width="25%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Report Name
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Created By
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Created Date
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Modified By
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Modified Date
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="10%" />
								</TableRow>
							</TableHead>
							<TableBody>
								{reportsData.map((reportData, index) => (
									<TableRow
										key={index}
										style={{ height: "42px" }}
										onClick={() =>
											navigate("" + reportData.id)
										}
										onMouseOver={() =>
											onMouseOverTableRow(reportData.id)
										}
										onMouseOut={() => onMouseOutTableRow()}
									>
										<TableCell>
											<Tooltip
												title={
													reportData.favourite
														? "Remove from favourites"
														: "Add to favourites"
												}
												placement="top"
											>
												<IconButton
													size="small"
													onClick={() =>
														toggleFavourite(index)
													}
												>
													{reportData.favourite
														? FavouriteOnIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)
														: FavouriteOffIcon(
																20,
																20,
																"rgba(0, 0, 0, 0.2)"
															)}
												</IconButton>
											</Tooltip>
										</TableCell>

										<TableCell>
											<Stack
												direction="row"
												alignItems="center"
												spacing={0.5}
											>
												<Tooltip
													title={
														reportData.reportName
													}
													placement="top"
												>
													<TableCellText noWrap>
														{reportData.reportName}
													</TableCellText>
												</Tooltip>

												{reportData.curated && (
													<CuratedTag />
												)}
											</Stack>
										</TableCell>

										<TableCell>
											<TableCellText>
												{reportData.createdBy}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{reportData.createdDate}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{reportData.modifiedBy
													? reportData.modifiedBy
													: "-"}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{reportData.modifiedDate}
											</TableCellText>
										</TableCell>

										<TableCell
											style={{ padding: "0px 16px" }}
										>
											{tableRowHovered ===
												reportData.id && (
												<Box
													style={{
														display: "flex",
														justifyContent:
															"flex-end",
													}}
												>
													<Box
														style={{
															height: "37px",
															border: `1px solid ${theme.palette.primary.main}`,
															borderRadius: "8px",
															width: "fit-content",
														}}
														p={1}
													>
														<Stack
															direction="row"
															justifyContent="center"
															alignItems="center"
															style={{
																height: "100%",
															}}
															spacing={0.5}
														>
															<IconButton
																onClick={() =>
																	openCloneReportDialog(
																		reportData.reportName
																	)
																}
															>
																{CopyIcon(
																	20,
																	20,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>

															{reportData.isDeletable && (
																<>
																	<Divider orientation="vertical" />

																	<IconButton
																		onClick={() =>
																			openDeleteReportDialog(
																				reportData.reportName
																			)
																		}
																	>
																		{DeleteIcon(
																			20,
																			20,
																			theme
																				.palette
																				.error
																				.main
																		)}
																	</IconButton>
																</>
															)}

															{reportData.isRecoverable && (
																<>
																	<Divider orientation="vertical" />

																	<IconButton
																		onClick={() =>
																			alert(
																				"vcvcv"
																			)
																		}
																	>
																		{ReloadIcon(
																			20,
																			20,
																			theme
																				.palette
																				.primary
																				.main
																		)}
																	</IconButton>
																</>
															)}
														</Stack>
													</Box>
												</Box>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80%"
							spacing={2}
						>
							<Typography
								fontSize={18}
								fontWeight={500}
								color="rgba(0, 0, 0, 0.6)"
							>
								&quot;You currently have no reports here.&quot;
							</Typography>

							<Button
								variant="contained"
								color="secondary"
								onClick={() => navigate("/reports/all-reports")}
							>
								See All Reports
							</Button>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
