import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserPermissions, signOut } from "../../../api/auth/authApi";
import { getCurrentUserPermissionsQueryKey } from "../../../utils/queryKeys/auth";

const useSignOutMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(signOut, {
		onSuccess: () => {
			queryClient.clear();
			window.location.replace("/");
		},
	});
};

const useUserPermissions = (fetchUserPermissionEnabled = true) => {
	return useQuery(
		getCurrentUserPermissionsQueryKey(),
		() => getUserPermissions(),
		{
			enabled: fetchUserPermissionEnabled,
			refetchOnWindowFocus: false,
		}
	);
};

export { useSignOutMutation, useUserPermissions };
