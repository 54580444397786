import { apiClient } from "../..";
import { getRolesUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import { getFieldDropdownData } from "../../fields/config/configApi";

export const getRolesTableData = async (fieldToSort, pageParams) => {
	let requestData = getRolesTableDataRequest(fieldToSort, pageParams);
	let respone = postRolesManagementApi(requestData);
	return await respone;
};

export const getRolesList = async () => {
	let requestData = getRolesListRequest();
	return getFieldDropdownData(requestData);
};

export const updateRole = async ({ id, name }) => {
	const requestData = getUpdateRoleRequest(id, name);
	return await postRolesManagementApi(requestData);
};

export const getRolesUserList = async (id) => {
	const requestData = getRolesUserListRequest(id);
	return await postRolesManagementApi(requestData);
};

export const linkUserToRole = async ({ roleId, usersId }) => {
	const requestData = getLinkUserToRoleRequest(roleId, usersId);
	return await postRolesManagementApi(requestData);
};

export const createRole = async (data) => {
	const requestData = getCreateRoleRequest(data);
	return await postRolesManagementApi(requestData);
};

export const getRoleDetails = async (id) => {
	const requestData = getRolesDetailsRequest(id);
	return await postRolesManagementApi(requestData);
};

export const updateRoleDetails = async (data) => {
	const requestData = getUpdateRoleDetailsRequest(data);
	return await postRolesManagementApi(requestData);
};
export const deleteRole = async (id) => {
	const requestData = getDeleteRoleRequest(id);
	return await postRolesManagementApi(requestData);
};

export const getAssignedUserList = async (id, pageParams) => {
	const requestData = getAssignedUserListRequest(id, pageParams);
	return await postRolesManagementApi(requestData);
};

const getRolesTableDataRequest = (fieldToSort, pageParams) => {
	let roleListRequestData = {
		type: "list",
		data: {
			...pageParams,
		},
	};

	if (Object.keys(fieldToSort).length > 0) {
		roleListRequestData.data.sort = fieldToSort;
	}

	return roleListRequestData;
};

const getRolesListRequest = () => {
	return {
		name: "roleList",
	};
};

const getUpdateRoleRequest = (id, name) => {
	return {
		type: "pUpdate",
		data: {
			id: id,
			name: name,
		},
	};
};

const getRolesUserListRequest = (id) => {
	return {
		type: "getPartialUsers",
		data: {
			id: id,
		},
	};
};

const getLinkUserToRoleRequest = (roleId, usersId) => {
	return {
		type: "linkUsers",
		data: {
			roleId: roleId,
			users: usersId,
		},
	};
};

const getCreateRoleRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getRolesDetailsRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const getDeleteRoleRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getUpdateRoleDetailsRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getAssignedUserListRequest = (id, pageParams) => {
	return {
		type: "getUsers",
		data: {
			id: id,
			...pageParams,
		},
	};
};

const postRolesManagementApi = async (requestData) => {
	const roleUrl = getRoleManagementUrl(requestData);
	const response = await apiClient.post(roleUrl, requestData);
	assertError(response, roleUrl);
	return response.data.data;
};

export const getRoleManagementUrl = () => {
	return getRolesUrl() + "/mgmt";
};
