const fontStyle = [
	{
		name: "Arial",
		value: 1,
	},
	{
		name: "Bebas Neue",
		value: 2,
	},
	{
		name: "Courier New",
		value: 3,
	},
	{
		name: "Georgia",
		value: 4,
	},
	{
		name: "Helvetica",
		value: 5,
	},
	{
		name: "Lato",
		value: 6,
	},
	{
		name: "Lexend",
		value: 7,
	},
	{
		name: "Merriweather",
		value: 8,
	},
	{
		name: "Montserrat",
		value: 9,
	},
	{
		name: "Open Sans",
		value: 10,
	},
	{
		name: "Oswald",
		value: 11,
	},
	{
		name: "Playfair Display",
		value: 12,
	},
	{
		name: "Poppins",
		value: 13,
	},
	{
		name: "Roboto",
		value: 14,
	},
	{
		name: "Roboto Slab",
		value: 15,
	},
	{
		name: "Times New Roman",
		value: 16,
	},
	{
		name: "Verdana",
		value: 17,
	},
];

export default fontStyle;
