import { apiClient } from "../..";
import { getMailUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getEmailSettingsData = async () => {
	let requestData = getEmailSettingsDataRequest();
	return await postEmailSettingsApi(requestData);
};

export const updateEmailSettingsData = async (updateSettingsData) => {
	let requestData = getUpdateEmailSettingsRequest(updateSettingsData);
	return await postEmailSettingsApi(requestData);
};

const getEmailSettingsDataRequest = () => {
	return {
		type: "retrieveEmailSettings",
		data: {},
	};
};

const getUpdateEmailSettingsRequest = (updateSettingsData) => {
	return {
		type: "updateEmailSettings",
		data: {
			...updateSettingsData,
		},
	};
};

const postEmailSettingsApi = async (requestData) => {
	let emailSettingsUrl = getEmailSettingsUrl();
	let response = await apiClient.post(emailSettingsUrl, requestData);
	assertError(response);
	return response.data.data;
};

const getEmailSettingsUrl = () => {
	return getMailUrl() + "/settings";
};
