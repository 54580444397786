import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import {
	useDeleteWorkflow,
	useUpdateWorkflowStatus,
} from "../../../../../hooks/services/workflow";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	Box,
	IconButton,
	Stack,
	Switch,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { TableCellText } from "../../../../../styles/twozo";
import { getDateDifference } from "../../../../../utils/DateUtils";

export default function AutomationRow(params) {
	const { workflow } = params;

	const navigate = useNavigate();
	const theme = useTheme();

	// mutation call :-
	const deleteWorkflowMutation = useDeleteWorkflow();
	const updateStatusMutation = useUpdateWorkflowStatus();

	const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
	const [tableRowHovered, setTableRowHovered] = useState(false);
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

	const onMouseOverTableRow = () => setTableRowHovered(true);
	const onMouseOutTableRow = () => setTableRowHovered(false);

	const openDeleteDialog = () => {
		setIsOpenDeleteDialog(true);
	};

	const closeDeleteDialog = () => {
		setIsOpenDeleteDialog(false);
	};

	const onDeleteClick = (event, workflowId) => {
		event.stopPropagation();
		setSelectedWorkflowId(workflowId);
		openDeleteDialog();
	};

	const handleDeleteWorkflow = () => {
		deleteWorkflowMutation.mutate(selectedWorkflowId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.workflowDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const onStatusClick = (event, workflow) => {
		event.stopPropagation();
		updateStatusMutation.mutate(
			{ workflowId: workflow.id, isActive: !workflow.isActive },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.workflowStatusUpdated,
					});
				},
			}
		);
	};

	return (
		<>
			<DeleteDialog
				title="Are you sure you want to delete this automation?"
				subtitle="You can't undo this."
				open={isOpenDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteWorkflow}
			/>

			<TableRow
				onMouseOver={() => onMouseOverTableRow(workflow.id)}
				onMouseOut={onMouseOutTableRow}
				onClick={() => navigate(`/settings/workflow/${workflow.id}`)}
			>
				<TableCell>
					<Switch
						checked={workflow.isActive}
						onClick={(event) => onStatusClick(event, workflow)}
					/>
				</TableCell>

				<TableCell>
					<Typography fontSize={14} fontWeight={500} noWrap>
						{workflow.name}
					</Typography>
				</TableCell>

				<TableCell>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Box display="flex">
							{workflow.module?.icon(
								20,
								20,
								"rgba(0, 0, 0, 0.6)"
							)}
						</Box>

						<TableCellText>{workflow.module?.name}</TableCellText>
					</Stack>
				</TableCell>

				<TableCell>{workflow.executeOn?.name}</TableCell>

				<TableCell>{workflow.actions}</TableCell>

				<TableCell>
					{getDateDifference(workflow.modifiedTime, false, false)}
				</TableCell>

				<TableCell sx={{ py: 0 }}>
					<Stack alignItems="flex-end">
						{tableRowHovered ? (
							<Box
								onClick={(event) =>
									onDeleteClick(event, workflow.id)
								}
								style={{
									width: "fit-content",
									border: `1px solid ${theme.palette.primary.main}`,
									borderRadius: "8px",
									padding: "2px 8px",
								}}
							>
								<IconButton size="small">
									{DeleteIcon(
										20,
										20,
										theme.palette.error.main
									)}
								</IconButton>
							</Box>
						) : null}
					</Stack>
				</TableCell>
			</TableRow>
		</>
	);
}
