import React, { useState } from "react";
import { MenuItem, Typography, useTheme } from "@mui/material";
import Menu from "../../Menu";
import { useNavigate } from "react-router-dom";

export default function CompanyAssigned(props) {
	const {
		notificationData,
		onClose,
		isNotificationActionEnabled = true,
	} = props;

	const theme = useTheme();
	const navigate = useNavigate();
	const [companyMenuElement, setCompanyMenuElement] = useState(null);
	const isCompanyMenuOpened = Boolean(companyMenuElement);

	const openCompanyMenu = (event) => {
		event?.stopPropagation();
		if (isNotificationActionEnabled) {
			setCompanyMenuElement(event.currentTarget);
		}
	};

	const closeCompanyMenu = (event) => {
		event?.stopPropagation();
		setCompanyMenuElement(null);
	};

	const navigateToCompany = (event, companyId) => {
		event?.stopPropagation();
		if (companyId) {
			navigate(`/companies/${companyId?.toString()}`);
			setCompanyMenuElement(null);
			onClose();
		}
	};

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={companyMenuElement}
				open={isCompanyMenuOpened}
				onClose={closeCompanyMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{notificationData?.data?.companies?.map((company) => (
					<MenuItem
						key={company?.id}
						style={{ height: "40px" }}
						onClick={(event) =>
							navigateToCompany(event, company?.id)
						}
					>
						<Typography
							fontSize={13}
							color={theme.palette.secondary.contrastText}
						>
							{company?.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Typography
				component="span"
				fontSize={13}
				color={theme.palette.secondary.contrastText}
				onClick={(event) =>
					navigateToCompany(
						event,
						notificationData?.data?.companies?.[0]?.id
					)
				}
			>
				{notificationData?.data?.companies?.[0]?.name}
			</Typography>

			{notificationData?.data?.companies?.length > 1 ? (
				<Typography
					component="span"
					fontSize={13}
					onClick={openCompanyMenu}
					color={theme.palette.secondary.contrastText}
				>
					&nbsp;
					{`+${notificationData.data.companies.length - 1}`}
				</Typography>
			) : null}
		</React.Fragment>
	);
}
