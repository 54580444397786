import React, { createContext, useContext } from "react";
import { useUserPermissions } from "../hooks/services/auth";
import { CircularProgress, Stack } from "@mui/material";

const AuthContext = createContext();

export const useAuthContext = () => {
	return useContext(AuthContext);
};

export const AuthContextProvider = (props) => {
	const { children } = props;

	const {
		isLoading: isUserPermissionLoading,
		data: userPermissions,
		isFetching: isFetchingPermissions,
		isError,
		error,
		refetch,
	} = useUserPermissions(true);

	const reloadAuth = () => {
		refetch();
	};

	const loadingPermissions =
		isUserPermissionLoading ||
		(userPermissions?.isUserNotLoggedIn && isFetchingPermissions);

	if (loadingPermissions) {
		return (
			<Stack alignItems="center" justifyContent="center" height="100vh">
				<CircularProgress size="30px" />
			</Stack>
		);
	}

	if (isError) {
		return error;
	}

	return (
		<AuthContext.Provider
			value={{
				permissions: userPermissions,
				reloadAuth: reloadAuth,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
