import {
	Box,
	Divider,
	IconButton,
	Stack,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { DwarfButton, Tooltip } from "../../../../../styles/twozo";
import ActivateDialog from "./activateDialog";
import UpdateField from "../UpdateField";

export default function SelectedMenu(props) {
	const { selected, data, setSelected, accountAdminCount } = props;
	const theme = useTheme();
	const [isActiveDialogOpen, setIsActiveDialogOpen] = useState(false);
	const [isUpdateFieldDialogOpen, setIsUpdateFieldDialogOpen] =
		useState(false);

	const accountAdmin = selected.filter(
		(selectedUser) => selectedUser.roleType === "ACCOUNT_ADMIN"
	);
	const isAllAccountAdminSelected = accountAdminCount === accountAdmin.length;

	const isUpdateFieldDisable =
		selected.filter((selectedUser) => !selectedUser.config.isEditable)
			.length > 0;

	const isActivateUserDisable =
		selected.filter((selectedUser) => !selectedUser.config.isActivatable)
			.length > 0;

	const userIdsToActivate = useMemo(
		() =>
			selected
				.filter((selectedUser) => selectedUser.config.isActivatable)
				.map((user) => user.id),
		[selected]
	);

	// selected menu title
	const activateDialogTitle = "Are you sure you want to Activate the user's?";

	const handleClearSelectedMenu = () => {
		setSelected([]);
	};

	const handleOpenActiveDialog = () => {
		setIsActiveDialogOpen(true);
	};

	const handleCloseActiveDialog = () => {
		setIsActiveDialogOpen(false);
		handleClearSelectedMenu();
	};

	const handleOpenUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpen(true);
	};

	const handleCloseUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpen(false);
	};

	return (
		<React.Fragment>
			{/* selected Menu */}
			<ActivateDialog
				open={isActiveDialogOpen}
				title={activateDialogTitle}
				onClose={handleCloseActiveDialog}
				userIds={userIdsToActivate}
			/>
			<UpdateField
				open={isUpdateFieldDialogOpen}
				onClose={handleCloseUpdateFieldDialog}
				selectedUser={selected}
				handleClearSelectedMenu={handleClearSelectedMenu}
			/>

			<Box>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack direction="row" spacing={0.5} alignItems="center">
						<Typography fontSize={14} fontWeight={500}>
							{selected.length} Selected
						</Typography>

						<Typography fontSize={14}>of {data.length}</Typography>

						<Box display="flex" onClick={handleClearSelectedMenu}>
							<IconButton>
								{CloseIcon(18, 18, "#000", 0.5)}
							</IconButton>
						</Box>
					</Stack>

					<Divider
						orientation="vertical"
						style={{ height: "20px" }}
					/>

					<Tooltip
						title={
							isActivateUserDisable ? (
								<Typography
									sx={{
										fontSize: "13px",
										fontWeight: 400,
										maxWidth: "247px",
									}}
								>
									Activation of users is not possible if
									invited users are selected, either
									individually or with other user statuses.
								</Typography>
							) : (
								""
							)
						}
						sx={{ maxWidth: "280px" }}
						placement="top"
					>
						<div>
							<DwarfButton
								variant="contained"
								color="secondary"
								disableElevation
								onClick={handleOpenActiveDialog}
								disabled={isActivateUserDisable}
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.secondary.main,
										color: alpha(
											theme.palette.secondary
												.contrastText,
											0.6
										),
										pointerEvents: "auto",
									},
								}}
							>
								Activate
							</DwarfButton>
						</div>
					</Tooltip>

					<Tooltip
						title={
							isUpdateFieldDisable ||
							isAllAccountAdminSelected ? (
								<Typography
									sx={{
										fontSize: "13px",
										fontWeight: 400,
										maxWidth: "280px",
									}}
								>
									Update field is not possible if all the
									users with Account admin roles are selected.
								</Typography>
							) : (
								""
							)
						}
						sx={{ maxWidth: "280px" }}
						placement="top-start"
					>
						<div>
							<DwarfButton
								variant="contained"
								color="secondary"
								disableElevation
								onClick={handleOpenUpdateFieldDialog}
								disabled={
									isUpdateFieldDisable ||
									isAllAccountAdminSelected
								}
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.secondary.main,
										color: alpha(
											theme.palette.secondary
												.contrastText,
											0.6
										),
										pointerEvents: "auto",
									},
								}}
							>
								Update Field
							</DwarfButton>
						</div>
					</Tooltip>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
