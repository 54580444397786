import React from "react";
import ContactComponent from "../../../../AddForm/Field/FieldComponents/ContactComponent";

export default function ContactFieldComponent({ field }) {
	return (
		<React.Fragment>
			<ContactComponent
				field={field}
				fieldSpecificProps={{
					isIconNeeded: false,
				}}
			/>
		</React.Fragment>
	);
}
