import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

export default function Home() {
	const { isUserAuthenticated } = useAuth();

	if (isUserAuthenticated()) {
		return <Navigate to="/deals" />;
	}

	return <Navigate to="/login" />;
}
