import axios from "axios";
import { getBaseUrl } from "./apiConfig";
import { setupInterceptors } from "./interceptors";

export const apiClient = axios.create({
	baseURL: getBaseUrl(),
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	},
	withCredentials: true,
});

setupInterceptors();
