import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UserManagementProvider } from "./UserManagementContext/UserManagementProvider";
import UserManagementList from "./UserManagementList";

export function UserManagement() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [viewType, setViewType] = useState(searchParams.get("type"));

	const onViewTypeChange = (viewType) => {
		setSearchParams({ type: viewType });
		setViewType(viewType);
	};

	return (
		<React.Fragment>
			<UserManagementProvider viewType={viewType}>
				<UserManagementList
					type={viewType}
					onViewTypeChange={onViewTypeChange}
				/>
			</UserManagementProvider>
		</React.Fragment>
	);
}
