import React from "react";

export default function ReplyAll(width = "24", height = "24", color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 20 20"
		>
			<path
				d="M6.725 4.16602L2.5 8.39101L6.725 12.616"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.725 4.16602L7.5 8.39101L11.725 12.616"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.1541 16.3073V11.3906C17.1541 9.73377 15.811 8.39062 14.1541 8.39062H7.6416"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
