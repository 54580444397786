import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { default as RightArrowIcon } from "../../../assets/icons/dropDownRight";
import { default as InfoIcon } from "../../../assets/icons/info";
import { default as AddIcon } from "../../../assets/icons/add";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import AddCurrency from "./AddCurrency";
import { Tooltip, twozoStyles } from "../../../styles/twozo";
import ConfirmDialog from "../../Elements/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { useCurrencyData } from "../../../hooks/services/currency";
import BaseCurrency from "./BaseCurrency";
import CurrencyDecimal from "./CurrencyDecimal";
import CurrencyList from "./CurrencyList";

const styles = {
	text: {
		fontSize: "16px",
		fontWeight: 500,
	},
	subText: {
		fontSize: "14px",
		fontWeight: 500,
	},
	heading: {
		fontSize: "16px",
		fontWeight: 600,
	},
	subHeading: {
		fontSize: "13px",
		fontWeight: 500,
	},
	contrastText: {
		fontSize: "14px",
		fontWeight: 400,
	},
	dialogText: {
		fontSize: "16px",
		fontWeight: 400,
	},
	menuText: {
		fontSize: "13px",
		fontWeight: 400,
	},
};

export default function Currency() {
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();
	const currencyRef = useRef(null);

	const [selectedDecimalId, setSelectedDecimalId] = useState("");
	const [selectedCurrency, setSelectedCurrency] = useState("");
	const [isAddCurrencyDrawerOpen, setIsAddCurrencyDrawerOpen] =
		useState(false);

	const [isAddCurrencyDialogOpen, setIsAddCurrencyDialogOpen] =
		useState(false);
	const [baseCurrencyStartingPosition, setBaseCurrencyStartingPosition] =
		useState(0);

	//Currency Data
	const { data: currencyData, status: currencyDataStatus } =
		useCurrencyData();

	useEffect(() => {
		if (currencyDataStatus == "success") {
			setSelectedCurrency(currencyData?.baseCurrency);
			setSelectedDecimalId(currencyData?.decimalPoint.id);
		}
	}, [currencyData, currencyDataStatus]);

	const toggleAddCurrencyDrawer = () => {
		setIsAddCurrencyDrawerOpen(
			(isAddCurrencyDrawerOpen) => !isAddCurrencyDrawerOpen
		);
	};

	const handleOpenAddCurrencyDialog = () => {
		if (currencyData?.currency?.list.length > 0) {
			toggleAddCurrencyDrawer();
		} else {
			setIsAddCurrencyDialogOpen(true);
		}
	};

	const handleCloseAddCurrencyDialog = () => {
		setIsAddCurrencyDialogOpen(false);
	};

	const handleOpenAddCurrencyDrawer = () => {
		handleCloseAddCurrencyDialog();
		toggleAddCurrencyDrawer();
	};

	useEffect(() => {
		if (currencyRef) {
			setBaseCurrencyStartingPosition(
				currencyRef.current.getBoundingClientRect().bottom
			);
		}
	}, [currencyRef]);

	const updateSelectedCurrencyDetails = (currency) => {
		setSelectedCurrency(currency);
	};

	const updateDecimalId = (id) => {
		setSelectedDecimalId(id);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				disableBackdropClick={true}
				disableSwipeToOpen
				open={isAddCurrencyDrawerOpen}
				onOpen={toggleAddCurrencyDrawer}
				onClose={toggleAddCurrencyDrawer}
			>
				<Box className={classes.addDialogContainer}>
					<AddCurrency
						onClose={toggleAddCurrencyDrawer}
						styles={styles}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ConfirmDialog
				open={isAddCurrencyDialogOpen}
				title="Are you sure you want to Add a new Currency?"
				subtitle={
					<Typography style={styles.dialogText}>
						When you add a currency, you won’t be able to change
						your base currency{" "}
						<span style={styles.text}>
							({selectedCurrency?.name})
						</span>
						.
					</Typography>
				}
				onCancel={handleCloseAddCurrencyDialog}
				onConfirm={handleOpenAddCurrencyDrawer}
				confirmButtonText="Yes"
				cancelButtonText="No"
				confirmButtonColor="primary"
			/>

			<Box py={2} px={3} ref={currencyRef}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography
						style={{ ...styles.text, cursor: "pointer" }}
						color={theme.palette.primary.main}
						onClick={() => navigate("/settings")}
					>
						Settings
					</Typography>

					<Box display="flex">
						<IconButton
							size="small"
							sx={{
								padding: 0,
							}}
						>
							{RightArrowIcon(16, 16)}
						</IconButton>
					</Box>

					<Typography style={styles.text}>Currency</Typography>
				</Stack>

				<Box pt={2}>
					<Typography fontSize={18} fontWeight={600}>
						Currency
					</Typography>

					<Typography
						style={{ ...styles.contrastText, opacity: 0.6 }}
					>
						Choosing the appropriate currency is significant, as it
						will impact throughout the CRM
					</Typography>
				</Box>
			</Box>

			<Divider />

			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					overflowY: "auto",
					maxHeight: `calc(100vh - ${baseCurrencyStartingPosition}px - 20px)`,
				}}
				m={1}
			>
				<Box p={2}>
					<BaseCurrency
						currencyData={currencyData}
						selectedCurrency={selectedCurrency}
						updateSelectedCurrencyDetails={
							updateSelectedCurrencyDetails
						}
					/>
				</Box>

				<Box px={2}>
					<CurrencyDecimal
						selectedDecimalId={selectedDecimalId}
						currencyData={currencyData}
						updateDecimalId={updateDecimalId}
					/>
				</Box>

				<Box px={2} pb={1} pt={3}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Typography style={styles.heading}>
									Add Currency
								</Typography>

								<Tooltip
									title={
										<Typography style={styles.contrastText}>
											Once you add other currencies, you
											won’t be able to change your base
											currency.
										</Typography>
									}
									placement="bottom"
									PopperProps={{
										modifiers: [
											{
												name: "offset",
												options: {
													offset: [120, -8],
												},
											},
										],
									}}
								>
									<Box display="flex">
										<IconButton
											size="small"
											sx={{
												padding: 0,
											}}
										>
											{InfoIcon(20, 20, "#000")}
										</IconButton>
									</Box>
								</Tooltip>
							</Stack>

							<Typography
								fontSize={13}
								fontWeight={400}
								sx={{ opacity: 0.6 }}
							>
								Add other currencies and simultaneously view
								their current conversion rate.
							</Typography>
						</Box>

						<Box>
							<Button
								variant="contained"
								disableElevation
								startIcon={AddIcon(20, 20, "#fff")}
								onClick={handleOpenAddCurrencyDialog}
							>
								Add Currency
							</Button>
						</Box>
					</Stack>
				</Box>

				<Box py={2}>
					<CurrencyList currencyData={currencyData} />
				</Box>
			</Box>
		</React.Fragment>
	);
}
