import React from "react";
import { Box, ButtonBase, Stack, useTheme } from "@mui/material";
import { default as BackArrowIcon } from "../../../assets/icons/leftArrow";
import { default as TickIcon } from "../../../assets/icons/tick";

export default function AddFormField(props) {
	const theme = useTheme();
	const { onSave, onClose, children } = props;

	const styles = {
		formButtonBaseRight: {
			height: "42px",
			width: "10%",
			borderRadius: "8px 0px 0px 8px",
			border: "1px solid rgba(0, 0, 0, 0.1)",
			marginLeft: "-1px",
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		formButtonBaseLeft: {
			height: "42px",
			width: "10%",
			borderRadius: "0px 8px 8px 0px",
			border: "1px solid rgba(0, 0, 0, 0.1)",
			marginLeft: "-1px",
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		formBoxContainer: {
			minHeight: "42px",
			width: "80%",
			border: "1px solid rgba(0, 0, 0, 0.1)",
			marginLeft: "-1px",
		},
	};

	return (
		<React.Fragment>
			<Stack direction="row">
				<ButtonBase
					style={styles.formButtonBaseRight}
					onClick={onClose}
				>
					{BackArrowIcon(20, 20, theme.palette.primary.main)}
				</ButtonBase>

				<Box style={styles.formBoxContainer}>{children}</Box>

				<ButtonBase style={styles.formButtonBaseLeft} onClick={onSave}>
					{TickIcon(20, 20, theme.palette.primary.main)}
				</ButtonBase>
			</Stack>
		</React.Fragment>
	);
}
