import { getRootKey } from "../rootKey";

const contactSyncKey = "contactSync";

// contact sync query key:-
export const getContactSyncKey = () => {
	return [...getRootKey(), contactSyncKey];
};

// query key:-
export const contactSyncSettingsDataKey = [
	...getContactSyncKey(),
	"contactSyncData",
];

// query key methods:-
export const getContactSyncStatusKey = (id) => {
	return [...getContactSyncKey(), String(id), "contactSyncStatus"];
};
