import React from "react";
import { useQuery } from "@tanstack/react-query";
import Dropdown from "../Dropdown";
import {
	getDependentDropdownKeyByFieldId,
	getDropdownKeyByFieldId,
} from "../../../../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../../../../api/fields/config/configApi";
import { useFormContext } from "react-hook-form";

export default function DynamicDropdown(props) {
	const { field, onChange, value, inputRef, values, error, clearErrors } =
		props;
	const { getValues } = useFormContext();

	const dependentedDropdownValue = values?.[field?.config?.dependsOn];

	const isDependsOnKeyAvailable = () => {
		return !!field.config?.dependsOn;
	};

	const getDropdownKey = () => {
		if (isDependsOnKeyAvailable()) {
			return getDependentDropdownKeyByFieldId(
				field?.id,
				dependentedDropdownValue
			);
		} else {
			return getDropdownKeyByFieldId(field?.id);
		}
	};

	const dropdownKey = getDropdownKey();
	const { data: options } = useQuery(
		dropdownKey,
		() => getFieldDropdownData(getDropdownRequestData()),
		{
			enabled: !!field?.id,
			select: (data) => data?.options,
		}
	);

	const getDropdownRequestData = () => {
		if (isDependsOnKeyAvailable()) {
			if (getValues(field.config.dependsOn.toString())) {
				return {
					id: field.id,
					dependentId: getValues(field.config.dependsOn.toString()),
				};
			}
		} else {
			return { id: field.id };
		}
	};

	return (
		<Dropdown
			options={options}
			onChange={onChange}
			value={value}
			inputRef={inputRef}
			field={field}
			clearErrors={clearErrors}
			error={error}
		/>
	);
}
