import React from "react";
import { Typography } from "@mui/material";

export default function RoleChanged(props) {
	const { notificationData, textColor = "#000" } = props;

	if (!notificationData) {
		return null;
	}

	return (
		<React.Fragment>
			<Typography
				style={{ fontSize: "13px", color: textColor, opacity: "0.6" }}
			>
				{`Your role is changed from ${notificationData?.data?.oldValue} to ${notificationData?.data?.newValue}.`}
			</Typography>
		</React.Fragment>
	);
}
