import React from "react";
import EmailList from "../../EmailList";
import { mailListName } from "../../../../utils/EmailUtils/mailListName";

export default function Clicks() {
	const menuConfig = {
		isDeleteEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Email Tracking - Clicks"
				mailListName={mailListName.CLICKS}
				menuConfig={menuConfig}
				hiddenBodyContent={true}
			/>
		</React.Fragment>
	);
}
