import React from "react";

function ThumbsDown(width = 24, height = 24, color = "#000") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{ fill: "none" }}
		>
			<path
				d="M16.728 6L13.4988 3.6C13.0821 3.2 12.1446 3 11.5196 3L7.5613 3C6.3113 3 4.95713 3.9 4.64463 5.1L2.14463 12.4C1.6238 13.8 2.5613 15 4.1238 15L8.29047 15C8.91547 15 9.4363 15.5 9.33213 16.2L8.8113 19.4C8.60297 20.3 9.22797 21.3 10.1655 21.6C10.9988 21.9 12.0405 21.5 12.4571 20.9L16.728 14.8"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path
				d="M22.0405 15.7999C22.0405 17.1999 21.4155 17.6999 19.9571 17.6999L18.9155 17.6999C17.4571 17.6999 16.8321 17.1999 16.8321 15.7999L16.8321 5.99994C16.8321 4.59994 17.4571 4.09994 18.9155 4.09994L19.9571 4.09994C21.4155 4.09994 22.0405 4.59994 22.0405 5.99994L22.0405 15.7999Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ThumbsDown;
