import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useOutletContext, useParams } from "react-router-dom";
import { default as ShareIcon } from "../../../assets/icons/share";
import { default as EditIcon } from "../../../assets/icons/edit";
import { default as MoreIcon } from "../../../assets/icons/more";
import { default as FavouriteOffIcon } from "../../../assets/icons/favouriteOff";
import { default as FavouriteOnIcon } from "../../../assets/icons/favouriteOn";
import Charts from "./Charts";

export default function ReportView() {
	const theme = useTheme();
	const [reportsListData] = useOutletContext();
	let { reportName, reportId } = useParams();
	const [reportData, setReportData] = useState(null);

	useEffect(() => {
		setReportData(
			reportsListData[reportName].data?.find(
				(reportData) => reportData.id.toString() === reportId
			)
		);
	}, [reportsListData, reportName, reportId]);

	const toggleFavourite = () => {
		setReportData((reportData) => {
			let updatedReportData = { ...reportData };
			updatedReportData["favourite"] = !updatedReportData["favourite"];
			return updatedReportData;
		});
	};

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					p={1.5}
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<IconButton size="small" onClick={toggleFavourite}>
							{reportData?.favourite
								? FavouriteOnIcon(
										20,
										20,
										theme.palette.primary.main
									)
								: FavouriteOffIcon(
										20,
										20,
										"rgba(0, 0, 0, 0.2)"
									)}
						</IconButton>

						<Typography fontWeight={500}>
							{reportData?.reportName}
						</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={2}>
						<Button
							variant="contained"
							color="secondary"
							startIcon={ShareIcon(
								20,
								20,
								theme.palette.primary.main
							)}
							disableElevation
						>
							Share
						</Button>

						<Button
							variant="contained"
							color="secondary"
							startIcon={EditIcon(
								20,
								20,
								theme.palette.primary.main
							)}
							disableElevation
						>
							Edit
						</Button>

						<IconButton size="small">
							{MoreIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Stack>

				<Divider />

				<Box
					style={{
						backgroundColor: "#f4f5f5",
						flex: "1 1 auto",
						minHeight: 0,
						overflow: "auto",
						padding: "16px",
					}}
				>
					<Grid container spacing={2}>
						<Grid item md={12} lg={6}>
							<Charts
								type="bar"
								title="Contacts Count vs Sales Owner"
							/>
						</Grid>

						<Grid item md={12} lg={6}>
							<Charts
								type="line"
								title="TOTAL Contacts grouped by First Contacted"
							/>
						</Grid>

						<Grid item md={12} lg={6}>
							<Charts
								type="pie"
								title="Contacts Count vs Sales Owner"
							/>
						</Grid>

						<Grid item md={12} lg={6}>
							<Charts
								type="half_doughnut"
								title="Contacts Count vs Sales Owner"
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</React.Fragment>
	);
}
