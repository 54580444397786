import { getRootKey } from "../rootKey";

// workflow key
const getWorkflowKey = () => {
	return [...getRootKey(), "workflow"];
};

// query keys
export const fieldTypeKey = [...getWorkflowKey(), "fieldType"];

export const automationFilterKey = [...getWorkflowKey(), "automationFilter"];

export const workFlowHistoryFilterKey = [...getWorkflowKey(), "historyFilter"];

export const workflowFilterUserListKey = [
	...getWorkflowKey(),
	"filterUserList",
];

export const workflowNamesListKey = [...getWorkflowKey(), "workflowNames"];

export const workflowFilterActionsListKey = [
	...getWorkflowKey(),
	"filterActionsList",
];

// query key methods
export const getComparatorKey = (fieldTypeId) => {
	return [...getWorkflowKey(), String(fieldTypeId), "Comparator"];
};

export const getCriteriaFieldsKey = (triggerSource) => {
	return [...getWorkflowKey(), String(triggerSource), "CriteriaFields"];
};

export const getActionListKey = (triggerSource) => {
	return [...getWorkflowKey(), String(triggerSource), "ActionList"];
};

export const getActionFieldsKey = (
	triggerSource,
	actionSource,
	actionEvent
) => {
	return [
		...getWorkflowKey(),
		String(triggerSource),
		String(actionSource),
		String(actionEvent),
		"ActionFields",
	];
};

export const getActionStepOptionKey = (triggerSource, fieldId, actions) => {
	return [
		...getWorkflowKey(),
		String(triggerSource),
		String(fieldId),
		JSON.stringify(actions),
		"ActionStepOptions",
	];
};

export const getWorkflowDataKey = (workflowId) => {
	return [...getWorkflowKey(), String(workflowId), "workflowAutomationData"];
};

export const getWorkflowHistoryKey = (historyId) => {
	return [...getWorkflowKey(), String(historyId), "workflowHistoryData"];
};

export const getTemplateListKey = (templateId) => {
	return [...getWorkflowKey(), String(templateId), "Template"];
};

export const getAllTemplateListKey = () => {
	return [...getWorkflowKey(), "TemplateList"];
};

export const getWorkflowListKey = (
	filterCriteria = {},
	searchText = "",
	automationSortData
) => {
	return [
		...getWorkflowKey(),
		"WorkflowList",
		filterCriteria,
		searchText,
		automationSortData,
	];
};

export const getAllWorkflowListKey = () => {
	return [...getWorkflowKey(), "WorkflowList"];
};

export const getWorkflowHistoryListKey = (filterCriteria, sort) => {
	return [...getWorkflowKey(), filterCriteria, sort, "WorkflowHistoryList"];
};
