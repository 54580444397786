import { getFieldsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export default async function postFieldsApi(requestData) {
	let fieldUrl = getFieldsUrl();
	let response = await apiClient.post(fieldUrl, requestData);
	assertError(response);
	return response.data.data;
}
