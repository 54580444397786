import { apiClient } from "..";
import { getTagsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";

export const createTags = async (tagName) => {
	let requestData = getCreateTagsRequest(tagName);
	return await postTagsManagementApi(requestData);
};

export const updateTags = async ({ tagName, tagId }) => {
	let requestData = getUpdateTagsRequest(tagName, tagId);
	return await postTagsManagementApi(requestData);
};

export const getTagsList = async (pageParam) => {
	let requestData = getTagsListRequest(pageParam);
	return await postTagsManagementApi(requestData);
};

export const deleteTags = async (id) => {
	let requestData = getDeleteTagsRequest(id);
	return await postTagsManagementApi(requestData);
};

export const getTagsDropdownData = async () => {
	let requestData = getTagsDropdownRequestData();
	return await postFieldsApi(requestData);
};

export const addTags = async ({ source, tagIds, entityIds }) => {
	let requestData = getAddTagsRequest(source, tagIds, entityIds);
	return await postTagsManagementApi(requestData);
};

export const removeTags = async ({ source, tagIds, entityIds }) => {
	let requestData = getRemoveTagsRequest(source, tagIds, entityIds);
	return await postTagsManagementApi(requestData);
};

const getCreateTagsRequest = (tagName) => {
	return {
		type: "create",
		data: {
			tag: tagName,
		},
	};
};

const getUpdateTagsRequest = (tagName, tagId) => {
	return {
		type: "update",
		data: {
			tag: tagName,
			id: tagId,
		},
	};
};

const getTagsListRequest = (pageParam) => {
	return {
		type: "list",
		data: {
			...pageParam,
		},
	};
};

const getDeleteTagsRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getTagsDropdownRequestData = () => {
	return {
		type: "dropdownData",
		data: {
			name: "tagList",
		},
	};
};

const getAddTagsRequest = (source, tagIds, entityIds) => {
	return {
		type: "mapTag",
		data: {
			source: source,
			tagIds: tagIds,
			entityIds: entityIds,
		},
	};
};

const getRemoveTagsRequest = (source, tagIds, entityIds) => {
	return {
		type: "deleteMappedTag",
		data: {
			source: source,
			tagIds: tagIds,
			entityIds: entityIds,
		},
	};
};

const postTagsManagementApi = async (requestData) => {
	let tagsManagementUrl = getTagsManagementUrl();
	let response = await apiClient.post(tagsManagementUrl, requestData);
	assertError(response, tagsManagementUrl);
	return response.data.data;
};

const getTagsManagementUrl = () => {
	return getTagsUrl() + "/mgmt";
};
