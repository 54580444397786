import { createContext, useContext } from "react";
import { useFilterDataReducer } from "../../Reducers/FilterDataReducer";

const FilterDataContext = createContext(null);

const useFilterDataContext = () => {
	return useContext(FilterDataContext);
};

const FilterDataProvider = (props) => {
	const { children } = props;

	const {
		filterState,
		toggleSwipeableFilter,
		filterListByCondition,
		removeAppliedFilter,
		deactivateEditMode,
		updateFilterCount,
		updateAppliedFilterId,
		updateFilterCondition,
		activateEditMode,
		openSwipeableFilter,
		closeSwipeableFilter,
		updateActiveFilterName,
		updateActiveFilterDetails,
		updateActiveFilterConditions,
	} = useFilterDataReducer();

	return (
		<FilterDataContext.Provider
			value={{
				filterState,
				toggleSwipeableFilter,
				filterListByCondition,
				removeAppliedFilter,
				deactivateEditMode,
				updateFilterCount,
				updateAppliedFilterId,
				updateFilterCondition,
				activateEditMode,
				openSwipeableFilter,
				closeSwipeableFilter,
				updateActiveFilterName,
				updateActiveFilterDetails,
				updateActiveFilterConditions,
			}}
		>
			{children}
		</FilterDataContext.Provider>
	);
};

export { useFilterDataContext, FilterDataProvider };
