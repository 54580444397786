import React from "react";
import ConfirmDialog from "../ConfirmDialog";

export default function DeleteDialog(props) {
	const {
		open,
		title,
		subtitle,
		onCancel,
		onDelete,
		confirmButtonText,
		cancelButtonText,
		disableDeleteButton,
	} = props;

	return (
		<React.Fragment>
			<ConfirmDialog
				open={open}
				title={title}
				subtitle={subtitle}
				onCancel={onCancel}
				onConfirm={onDelete}
				confirmButtonText={
					confirmButtonText ? confirmButtonText : "Delete"
				}
				cancelButtonText={
					cancelButtonText ? cancelButtonText : "Cancel"
				}
				disableConfirmButton={disableDeleteButton}
			></ConfirmDialog>
		</React.Fragment>
	);
}
