import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import CalendarSyncSettings from "./CalendarSyncSettings";
import EmailManagementSyncDialog from "../../../../Elements/EmailManagementSyncDialog";
import { useCalendarSyncSettingsData } from "../../../../../hooks/services/calendarSync";
import { emailsSyncTypes } from "../../../../../utils/emailsSyncUtils";

export default function CalendarSync() {
	// query call
	const { data: calendarSyncSettingsData, isLoading: isCalendarSyncLoading } =
		useCalendarSyncSettingsData();

	const [
		isConnectEmailAccountDialogOpen,
		setIsConnectEmailAccountDialogOpen,
	] = useState(false);

	const handleCloseDialog = () => {
		setIsConnectEmailAccountDialogOpen(false);
	};

	return (
		<React.Fragment>
			{isCalendarSyncLoading ? (
				<Stack
					height="60vh"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Stack>
			) : calendarSyncSettingsData?.id ? (
				<CalendarSyncSettings
					calendarSyncSettingsData={calendarSyncSettingsData}
				/>
			) : (
				<EmailManagementSyncDialog
					onClose={handleCloseDialog}
					emailsSyncType={emailsSyncTypes.calendar}
					open={isConnectEmailAccountDialogOpen}
				/>
			)}
		</React.Fragment>
	);
}
