import { getRootKey } from "../rootKey";

const filterKey = "filter";

export const getFilterKey = () => {
	return [...getRootKey(), filterKey]; // Return the filter query key
};

// query keys
export const sourceListKey = [...getFilterKey(), "sourceList"];

// query key methods

export const getFilterFieldListKey = (sourceId) => {
	return [...getFilterKey(), String(sourceId), "fieldList"];
};

export const getComparatorKey = (fieldTypeId) => {
	return [...getFilterKey(), String(fieldTypeId), "comparator"];
};

export const getAdditionalOptionKey = (fieldTypeId) => {
	return [...getFilterKey(), String(fieldTypeId), "additionalOption"];
};

export const getValueDropdownKey = (fieldId) => {
	return [...getFilterKey(), String(fieldId), "valueDropdown"];
};

export const getFilterListKey = (sourceId) => {
	return [...getFilterKey(), String(sourceId), "filterList"];
};

export const getSalesOwnerFilterListKey = (sourceId) => {
	return [...getFilterKey(), String(sourceId), "salesOwnerFilterList"];
};

export const getAppliedFilterKey = (appliedFilterId) => {
	return [...getFilterKey(), String(appliedFilterId), "appliedFilter"];
};

export const getUserTeamTerritoryListKey = () => {
	return [...getFilterKey(), "userTeamTerritoryList"];
};
