import React, { useState } from "react";
import { Box } from "@mui/material";
import AddNote from "./AddNote";
import NoteList from "./NoteList";

export default function Notes(props) {
	const { sourceName, entityId } = props;

	const [editedNoteData, setEditedNoteData] = useState({});
	const [isEditMode, setIsEditMode] = useState(false);

	const handleEditNote = (noteData) => {
		setEditedNoteData(noteData);
		setIsEditMode(true);
	};

	const onCancelNote = () => {
		setEditedNoteData({});
		setIsEditMode(false);
	};

	return (
		<React.Fragment>
			<Box>
				<AddNote
					sourceName={sourceName}
					entityId={entityId}
					isEditMode={isEditMode}
					initialNoteData={editedNoteData}
					onCancelNote={onCancelNote}
				/>
			</Box>

			<Box>
				<NoteList
					sourceName={sourceName}
					entityId={entityId}
					handleEditNote={handleEditNote}
					editedNoteId={editedNoteData?.id}
				/>
			</Box>
		</React.Fragment>
	);
}
