import { Box, Stack } from "@mui/material";

export default function CustomHomeComponent(params) {
	const { children } = params;

	return (
		<>
			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					minHeight: "100vh",
					backgroundColor: "#FAFAFA",
				}}
			>
				<Box
					style={{
						width: "450px",
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
				>
					{children}
				</Box>
			</Stack>
		</>
	);
}
