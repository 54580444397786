const visibilityList = [
	{
		name: "Just me",
		value: 1,
	},
	{
		name: "Everyone",
		value: 2,
	},
	{
		name: "Selected",
		value: 3,
	},
];

export default visibilityList;
