import { getAccountsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const signUp = async (data) => {
	let request = getCommonDataRequest(data);
	await postSignUpAPI(request);
};

export const signIn = async (data) => {
	let request = getCommonDataRequest(data);
	await postSignInAPI(request);
};

export const getUserPermissions = async () => {
	return await getCurrentUserPermission();
};

export const changePassword = async (changePasswordDetails) => {
	let requestData = getChangePasswordRequest(changePasswordDetails);
	return await postChangePasswordManagement(requestData);
};

export const getCurrentUserDetails = async () => {
	return await getCurrentUser();
};

const getCommonDataRequest = (data) => {
	return {
		data: data,
	};
};

const getChangePasswordRequest = (changePasswordDetails) => {
	return {
		data: changePasswordDetails,
	};
};

const postSignUpAPI = async (requestData) => {
	let signUpUrl = getSignUpUrl();
	let response = await apiClient.post(signUpUrl, requestData);
	assertError(response, signUpUrl);
	return response.data;
};

const postSignInAPI = async (requestData) => {
	let signInUrl = getSignInUrl();
	let response = await apiClient.post(signInUrl, requestData);
	assertError(response, signInUrl);
	return response.data.data;
};

export const signOut = async () => {
	let signOutUrl = getSignOutUrl();
	let response = await apiClient.get(signOutUrl);
	assertError(response, signOutUrl);
	return response.data;
};

// const getCurrentUser = async () => {
// 	let currentUserUrl = getCurrentUserUrl();
// 	let response = await apiClient.get(currentUserUrl);
// 	assertError(response, currentUserUrl);
// 	return response.data;
// };

const getCurrentUserPermission = async () => {
	let currentUserUrl = getCurrentUserPermissionUrl();
	let response;
	try {
		response = await apiClient.get(currentUserUrl);
	} catch (e) {
		if (e.response.status == 401) {
			return { isUserNotLoggedIn: true };
		}
	}
	assertError(response, currentUserUrl);
	return response.data.data;
};

const getCurrentUser = async () => {
	let currentUserUrl = getCurrentUserUrl();
	let response = await apiClient.get(currentUserUrl);
	assertError(response, currentUserUrl);
	return response.data;
};

const postChangePasswordManagement = async (requestData) => {
	let changePasswordUrl = getChangePasswordUrl();
	let response = await apiClient.post(changePasswordUrl, requestData);
	assertError(response, changePasswordUrl);
	return response.data.data;
};

const getAccountsCRMUrl = () => {
	return getAccountsUrl() + "/crm";
};

const getSignUpUrl = () => {
	return getAccountsCRMUrl() + "/signup";
};

const getSignInUrl = () => {
	return getAccountsCRMUrl() + "/login";
};

const getSignOutUrl = () => {
	return getAccountsCRMUrl() + "/logout";
};

const getCurrentUserUrl = () => {
	return getAccountsUrl() + "/currentUser";
};

const getCurrentUserPermissionUrl = () => {
	return getCurrentUserUrl() + "/permissions";
};

const getChangePasswordUrl = () => {
	return getAccountsUrl() + "/crm/changePassword";
};
