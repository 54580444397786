import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { twozoStyles } from "../../../../styles/twozo";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getActionFieldsKey } from "../../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getActionFieldsData } from "../../../../api/workflow/workflowApi";
import { parseHTML } from "../../../../utils/EmailUtils/parseHTML";
import { $generateHtmlFromNodes } from "@lexical/html";
import { default as CloseIcon } from "../../../../assets/icons/close";
import EmailField from "./EmailField";

export default function EmailFields(params) {
	const {
		toggleAddActivityDialog,
		triggerSourceId,
		actionSource,
		actionEvent,
		resetAction,
		formName,
		fieldMapping,
		setFieldMappings,
	} = params;

	const classes = twozoStyles();
	const theme = useTheme();

	const [editor] = useLexicalComposerContext();

	const [actionFields, setActionFields] = useState({});
	const actionFieldsKey = getActionFieldsKey(
		triggerSourceId,
		actionSource?.id,
		actionEvent
	);
	const hasFieldMapping = useCallback(() => {
		if (Object.keys(fieldMapping).length > 0) {
			return false;
		}
		return true;
	}, [fieldMapping]);

	const isActionFieldEnabled = () => {
		return !!(
			hasFieldMapping() &&
			triggerSourceId &&
			actionSource?.id &&
			actionEvent
		);
	};

	const { data: actionFieldsData } = useQuery(
		actionFieldsKey,
		() =>
			getActionFieldsData(triggerSourceId, actionSource?.id, actionEvent),
		{
			enabled: isActionFieldEnabled(),
		}
	);

	useEffect(() => {
		if (actionFieldsData && actionFieldsData.fields) {
			let deepCopyActionFields = JSON.parse(
				JSON.stringify(actionFieldsData.fields)
			);
			setActionFields(deepCopyActionFields);
		}
	}, [actionFieldsData]);

	useEffect(() => {
		if (fieldMapping && !hasFieldMapping()) {
			setActionFields(fieldMapping);
		}
	}, [fieldMapping, hasFieldMapping]);

	const handleSave = () => {
		let editorState = editor.getEditorState();
		let htmlBodyContent = editorState.read(() => {
			return parseHTML($generateHtmlFromNodes(editor), classes);
		});
		actionFields["body"]["values"] = [{ value: htmlBodyContent }];

		setFieldMappings({ ...actionFields });
	};

	const metadata = {
		triggerSourceId,
		actionSource: actionSource?.id,
		actionEvent,
	};

	const onCancelClick = () => {
		resetAction();
		setActionFields([]);
		toggleAddActivityDialog();
	};

	return (
		<Box className={classes.addDialogContainer}>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontWeight={600}>{formName}</Typography>

						<IconButton
							onClick={onCancelClick}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<EmailField
						metadata={metadata}
						actionFields={actionFields}
					/>
				</Box>

				<Box p="8px 0px 24px 24px">
					<Button
						variant="contained"
						disableElevation
						onClick={handleSave}
					>
						Save
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
