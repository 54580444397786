import React from "react";
import { CircularProgress, Stack } from "@mui/material";
import RelatedContacts from "./RelatedContacts";
import RelatedDeal from "./RelatedDeal";
import { useRelatedContactsAndDeal } from "../../../../hooks/services/mail/relatedContactsAndDeal";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function MailInfo(props) {
	const { threadId, mailListName } = props;

	const {
		data: relatedContactsAndDeal,
		isLoading: isLoadingRelatedContactsAndDeal,
	} = useRelatedContactsAndDeal(threadId);

	return (
		<React.Fragment>
			{isLoadingRelatedContactsAndDeal ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Stack spacing={2}>
					<Can permission={PERMISSIONS.contact.view}>
						<RelatedContacts
							relatedContactsAndDeal={
								relatedContactsAndDeal?.contacts
							}
							threadId={threadId}
							mailListName={mailListName}
						/>
					</Can>

					<Can permission={PERMISSIONS.deal.view}>
						<RelatedDeal
							relatedContactsAndDeal={
								relatedContactsAndDeal?.contacts
							}
							threadId={threadId}
							mailListName={mailListName}
						/>
					</Can>
				</Stack>
			)}
		</React.Fragment>
	);
}
