import React from "react";
import ColorPicker from "./colorPicker";
import Menu from "../../../../../Elements/Menu";

export default function ColorPickerMenu(props) {
	const { color, onColorChange, anchorEl, open, onClose } = props;

	return (
		<React.Fragment>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				menuListProps={{
					padding: "10px",
				}}
			>
				<ColorPicker color={color} onColorChange={onColorChange} />
			</Menu>
		</React.Fragment>
	);
}
