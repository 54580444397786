import React, { useState } from "react";
import { TableCellText } from "../../../../styles/twozo";
import { Box, Stack, useTheme } from "@mui/material";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import ProfileImage from "../../../../assets/images/contact/unknownContact.png";
import Menu from "../../Menu";

export default function Owner(props) {
	const { data, rowState } = props;
	const theme = useTheme();

	const [ownerMenuElement, setOwnerMenuElement] = useState(null);
	const isOwnerMenuOpened = Boolean(ownerMenuElement);

	const openOwnerMenu = (event) => {
		event.stopPropagation();
		setOwnerMenuElement(event.currentTarget);
	};

	const closeOwnerMenu = (event) => {
		event.stopPropagation();
		setOwnerMenuElement(null);
	};

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="240px"
				minHeight="40px"
				anchorEl={ownerMenuElement}
				open={isOwnerMenuOpened}
				onClose={(event) => closeOwnerMenu(event)}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px",
				}}
			>
				{Array.isArray(data)
					? data?.map((contact) => (
							<Stack
								key={contact.id}
								direction="row"
								alignItems="center"
								spacing={1}
								pl={3}
								pr={2}
								py={1.5}
								onClick={closeOwnerMenu}
							>
								<img
									src={ProfileImage}
									alt="img"
									width={26}
									height={26}
									style={{
										opacity:
											getStatusProperties(rowState)
												.opacity,
									}}
								/>

								<TableCellText
									color={
										getStatusProperties(rowState).textColor
									}
								>
									{contact?.name}
								</TableCellText>
							</Stack>
						))
					: null}
			</Menu>

			{Array.isArray(data) ? (
				<Box
					onClick={
						data.length > 1 ? (event) => openOwnerMenu(event) : null
					}
				>
					<Stack direction="row" spacing={1} alignItems="center">
						<img
							src={ProfileImage}
							alt="img"
							width={26}
							height={26}
							style={{
								opacity: getStatusProperties(rowState).opacity,
							}}
						/>

						<TableCellText>
							<span
								style={{
									color: getStatusProperties(rowState)
										.textColor,
								}}
							>
								{data?.[0]?.name}
							</span>
							&nbsp;
							{data.length > 1 && (
								<span
									style={{
										color: theme.palette.primary.main,
									}}
								>
									+{data.length - 1}
								</span>
							)}
						</TableCellText>
					</Stack>
				</Box>
			) : (
				<TableCellText color={getStatusProperties(rowState).textColor}>
					{data?.name}
				</TableCellText>
			)}
		</React.Fragment>
	);
}
